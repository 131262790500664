import ClientsObject from '../objects/ClientsAndPerson/clientsObject.js'
import {sendRequestGetLocal, sendRequestPostLocal} from '../backEnd/requesition.js'

const _routerGetListClientsInCloud ='/clients/getClients'
const _routerFromUpdateClientCloudClients = '/clients/updateClient'
const _routerFromAddNewCloudClients = '/clients/addClients'

export const setNewClient = async (newClient,showError) =>{
    try {
        var results = await sendRequestPostLocal(_routerFromAddNewCloudClients,newClient.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {   
        showError('ERROR AO SALVAR CLIENTE',error.message)
        return {status:404}
    }
}

export const setUpdateClient= async (updateClient,showError) =>{
    try {       
        var results = await sendRequestPostLocal(_routerFromUpdateClientCloudClients,updateClient.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO SALVAR EDIÇÃO DA CLIENTE',error.message)
        return {status:404}
    }
}


export const getListClients = async (showError) => {
    var results = []
    const clientsList = []
    try {
        results = await sendRequestGetLocal(_routerGetListClientsInCloud)
        
        if(Array.isArray(results)){
            Object.values(results).forEach(clientData =>{   
                var clientTemp = new ClientsObject()
                clientTemp.fromCloud(clientData)
                clientsList.push(clientTemp)
            })
        }else throw new Error(results.response.data.sqlMessage) 

    } catch (error) {
        showError('ERROR AO BUSCAR CLIENTES',error.message)
    }finally{
        return clientsList
    }
}