import React from 'react';
import style from '../../style/ViewMothUnic.module.css'
import ViewMoth from './ViewMoth';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import GetFloatingButton, { GetFloatingButtonWithLegend } from '../../layout/GetFloatingButton';
function ViewMothUnic({myMoth, onClose }) {
    return (
      <div className={style.overlay}>
        <div className={style.alert}>
            <ViewMoth myMoth={myMoth}/>
        </div>
        {
          window.innerWidth > 1200?
          <GetFloatingButtonWithLegend legend={'Fechar'} margiBottom={'90%'} width={'8%'} icon={faClose} color='#ff0000' margiRight={'1%'} handleFunction={onClose} />
          :
          <GetFloatingButton margiBottom={'90%'} width={55} height={55} icon={faClose} color='#ff0000' margiRight={'2%'} handleFunction={onClose} />
        }
      </div>
    )
}

export default ViewMothUnic
