import GenericProduct from "../Brands/Products/GenericProducts"
import ItemsSale from "../ItemBJ/ItemsSale"
import AccountingObjectPart from "./AccountingObjectPart"

class AccountingAllItemsSale {
    constructor() {
      this._itemsDataControllerNormalStockSales = {}
      this._itemsDataControllerGenericProductStockSales = {}
      this.itemsNormalStockSales = new AccountingObjectPart()
      this.itemsGenericStockSales = new AccountingObjectPart()
    }
  
    clearData() {
      this._itemsDataControllerNormalStockSales = {};
      this._itemsDataControllerGenericProductStockSales = {};
    }
  
    updateValues() {
      this.itemsNormalStockSales.clearData()
      this.itemsGenericStockSales.clearData()
  
      Object.values(this._itemsDataControllerNormalStockSales).forEach(value => {
        value.updateValuesItems()
        this.itemsNormalStockSales.amountItems += value.amount
        this.itemsNormalStockSales.grams += value.itemGlobal.factorItem
        this.itemsNormalStockSales.totalSaleCount++
      })
  
      Object.values(this._itemsDataControllerGenericProductStockSales).forEach(value => {
        this.itemsGenericStockSales.amountItems += value.amount
        this.itemsGenericStockSales.totalSaleCount++
      })
    }
  
    insertValuesInMapsFromSales(normalStock, products) {
      normalStock.forEach(ss => {
        const codeBar = ss.codeBar.toString();
        if (this._itemsDataControllerNormalStockSales.hasOwnProperty(codeBar)) {
            this._itemsDataControllerNormalStockSales[codeBar].amountItems += ss.amountItems
        } else {
            var item = new ItemsSale()
            item.fromOther(ss)
            this._itemsDataControllerNormalStockSales[codeBar] = item
        }
      })
  
      products.forEach(ss => {
        const idProduct = ss.idGenericProducts.toString()
        if (this._itemsDataControllerGenericProductStockSales.hasOwnProperty(idProduct)) {
            this._itemsDataControllerGenericProductStockSales[idProduct].amount += ss.amount
        } else {
            var generic = new GenericProduct()
            generic.fromOtherWithAmount(ss.amount,ss)
            this._itemsDataControllerGenericProductStockSales[idProduct] = generic
        }
      })
    }
  
    getItemsNormalStock() {
      return Object.values(this._itemsDataControllerNormalStockSales)
    }
  
    getItemsGenericProductsStock() {
      return Object.values(this._itemsDataControllerGenericProductStockSales)
    }
  }

export default AccountingAllItemsSale