import React, { useState } from 'react';
import style from "../style/StateCountry.module.css"

export const TypeFinancialDropDown = ({ initialState, disable , handlerFuncion, name}) => {
  const states = [
    "BOLETO", "CHEQUE", "DINHEIRO", "CARTÃO DEBITO", 'CARTÃO CREDITO'
  ]

  const [type, setType] = useState(initialState || "");

  const handleChange = (event) => {
    setType(event.target.value);
    handlerFuncion(event)
  }

  return (
    <div>
        <select disabled={disable} name={name} className={style.state} onChange={handleChange} value={type}>
            {states.map((status, index) => (
                <option key={index} value={status}>{status}</option>
            ))}
        </select>
    </div>
  );
}

export const SituationFinancialDropDown = ({ initialState, disable , handlerFuncion, name}) => {
    const states = [
      "EM ABERTO", "FECHADO"
    ]
  
    const [type, setType] = useState(initialState || "");
  
    const handleChange = (event) => {
      setType(event.target.value);
      handlerFuncion(event)
    }
  
    return (
      <div>
          <select disabled={disable} name={name} className={style.state} onChange={handleChange} value={type}>
              {states.map((status, index) => (
                  <option key={index} value={status}>{status}</option>
              ))}
          </select>
      </div>
    );
}

export const CategoryFinancialDropDown = ({ initialState, disable , handlerFuncion, name}) => {
    const states = [
      "DEBITOS", "CREDITOS"
    ]
  
    const [type, setType] = useState(initialState || "");
  
    const handleChange = (event) => {
      setType(event.target.value);
      handlerFuncion(event)
    }
  
    return (
      <div>
          <select disabled={disable} name={name} className={style.state} onChange={handleChange} value={type}>
              {states.map((status, index) => (
                  <option key={index} value={status}>{status}</option>
              ))}
          </select>
      </div>
    );
}

export const BacksDropDown = ({ banks,initialState, disable , handlerFuncion, name}) => {
    
  const [type, setType] = useState(initialState || "");

  const handleChange = (event) => {
    setType(event.target.value);
    handlerFuncion(event)
  }

  return (
    <div>
        <select disabled={disable} name={name} className={style.state} onChange={handleChange} value={type}>
            {banks.map((status, index) => (
                <option key={index} value={JSON.stringify(status)}>{status.bankName}</option>
            ))}
        </select>
    </div>
  );
}

export const MothsDropDown = ({ moths,initialState, disable , handlerFuncion, name}) => {
    
    const [type, setType] = useState(initialState || "");
  
    const handleChange = (event) => {
      setType(event.target.value);
      handlerFuncion(event)
    }
  
    return (
      <div>
          <select disabled={disable} name={name} className={style.state} onChange={handleChange} value={type}>
              {moths.map((status, index) => (
                  <option key={index} value={status}>{status}</option>
              ))}
          </select>
      </div>
    );
}