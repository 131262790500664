import { employeeData } from "../../backEnd/InitController"
import { getAllRepairs } from "../../controllers/CloudRepairs"
import { getSalesInPeriod, getSalesWithPaymentInPeriod } from "../../controllers/CloudSales"
import PaidSale from "./PaidSale"

class Moth{
    constructor(){
        this.idMoth = -1
        this.codeId =''
        this.codeName ='Indefinido'

        this.timeStart = new Date()
        this.timeFinished = null

        this.listOfRepairs = []
        this.salesMoth = [] //list Sales
        this.listPaid = [] //List PaidSale

        this.valueTotal = 0
        this.valueNormal   = 0
        this.valueDiscount = 0
        this.valueExtra = 0
        this.valueReceivers = 0
        this.valuePresent = 0
        this.valueTotalCancel  = 0

        this.isOpenMoth = false
    }

  getValueTotalToString(){
    return this.valueTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  getSalesToday(){
    const today = new Date()
    today.setHours(0, 0, 0, 0)
  
    const salesToday = this.salesMoth.filter(sale => {
      const saleDate = new Date(sale.detailsSale.date)
      saleDate.setHours(0, 0, 0, 0)
      return saleDate.getTime() === today.getTime()
    })

    return salesToday
  }

  getValueTotalSaleToday() {
    return this.getSalesToday().reduce((total, sale) => total + sale.totalValueIncludeAll, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  getValueTotalSaleEmployeeToday() {
    const sales = this.getSalesToday().filter(ss => ss.tempEmployee.idEmployee === employeeData().idEmployee)
    return sales.reduce((total, sale) => total + sale.totalValueIncludeAll, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  getValueExtraToString(){
    return this.valueExtra.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  getValueTotalCancelToString(){
    return this.valueTotalCancel
    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  getValuePresentToString(){
    return this.valuePresent.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  getValueReceiversToString(){
    return this.valueReceivers.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  getValueDiscountToString(){
    return this.valueDiscount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  getValueNormalToString(){
    return this.valueNormal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  fromInit(codeName,timeStart,isOpenMoth){
    this.codeName = codeName
    this.timeStart = timeStart
    this.isOpenMoth = isOpenMoth
  }

  getTimeStartToString(){
    return this.timeStart.toISOString().replace('T',' ').slice(0,19)
  }

  getTimeFinishedToString(){
    if(this.timeFinished)
      return this.timeFinished.toISOString().replace('T',' ').slice(0,19)
    return 'Em Aberto'
  }

  fromNull(){
    this.idMoth = -1;
    this.isOpenMoth = false
    this.codeName = 'Indefinido'
  }

  getSale(){
    return this.salesMoth.filter(ss=> !ss.cancelSale.isCancel && !ss.detailsSale.isPresent)
  }

  getPresents(){
    return this.salesMoth.filter(ss=> !ss.cancelSale.isCancel && ss.detailsSale.isPresent)
  }

  getCancels(){
    return this.salesMoth.filter(ss=> ss.cancelSale.isCancel)
  }

  getRepairs(){
    return this.listOfRepairs
  }


  setCloseNow(dateFinal){
    this.isOpenMoth = false
    this.timeFinished = dateFinal
  }
  
  getMapFromCloud(){
    return{
      'idMoth':this.idMoth,
      'dateStart': this.timeStart.toISOString().slice(0,10),
      'dateFinished':(this.timeFinished !== null) ? this.timeFinished.toISOString().slice(0,10): null,
      'isOpen':this.isOpenMoth,
      'nameMoth':this.codeName
    };
  }

  fromCloud(data,showError,genericProducts,listItemsBj,employeeList,clientsList){
    this.idMoth = data.idMoth
    this.isOpenMoth = data.isOpen === 1? true:false
    this.codeName = data.nameMoth
    this.timeStart = new Date(data.dateStart);
    this.timeFinished = data.dateFinished !== null? new Date(data.dateFinished) : null
    this.calculate(showError,genericProducts,listItemsBj,employeeList,clientsList)
  }

  async calculate(showError,genericProducts,listItemsBj,employeeList,clientsList) {
    this._clear()

    var dateFinished = this.timeFinished ?? new Date()
    this.listOfRepairs = await getAllRepairs(this.timeStart,showError)
    this.salesMoth = await getSalesInPeriod(this.timeStart,dateFinished,showError,genericProducts,listItemsBj,employeeList,clientsList)
    const withPayment = await getSalesWithPaymentInPeriod(this.timeStart,dateFinished,
      showError,genericProducts,listItemsBj,employeeList,clientsList)

    this.salesMoth.sort((a,b) => a.detailsSale.date.localeCompare(b.detailsSale.date))
    for (var element of this.salesMoth) {
      element.calculateSale()
      if(!element.cancelSale.isCancel) {
        if(!element.detailsSale.isPresent){
          this.valueTotal += element.totalValueIncludeAll
          this.valueNormal += element.totalValue
          this.valueDiscount += element.saleDiscount.valueDiscountLiquid
          this.valueExtra += element.extraValue
        }else{
          this.valuePresent += element.totalValueIncludeAll
        }
      }else{
        this.valueTotalCancel += element.totalValueIncludeAll
      }
    }

    for (const element of withPayment) {
      for (const e of element.controllerPayment.paymentList) {
        if(e.date >= this.timeStart.toISOString() && e.date <= dateFinished.toISOString()){
          this.listPaid.push(new PaidSale(element.getCodeSale(), e.idListOfPayment.toString(), e.date, e.value,e.description))
          this.valueReceivers += e.value     
        }
      }
    }
  }

  _clear(){
    this.listOfRepairs = []
    this.salesMoth = []
    this.listPaid = []
    this.valueTotalCancel = 0
    this.valueTotal = 0
    this.valueNormal = 0
    this.valueDiscount = 0
    this.valuePresent = 0
    this.valueExtra = 0
    this.valueReceivers = 0
  }

}


export default Moth