import React, { useState, useEffect, useRef } from 'react';
import style from "../../style/ListClients.module.css"
import { GetSmallModels } from '../../Models/GetModels.js';
import { faMagnifyingGlass,faCircleInfo, faList, faFilePdf, faLock} from '@fortawesome/free-solid-svg-icons';
import InputModels from "../../Models/InputModels.js"
import GetAmountList from '../../Models/GetAmountList.js';
import Space from '../../layout/Space.js';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage.js';
import { makeAccounting } from '../../objects/Accounting/MakeAccounting.js';
import GetNeedUpdate from './GetNeedUpdate.js';
import { GetButton } from '../../layout/ButtonsLayout.js';
import { ProductModelFromSale } from '../../Models/ProductModel.js';
import { employeeData } from '../../backEnd/InitController.js';
import { requestPDFs } from '../../controllers/CloudPdfs.js';
import { useError, useLoading } from '../../AppContext.js';
import { myStoreDataMemory } from '../../backEnd/Memory.js';

function ListProductsSold() {

  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])
  const [canViewSold] = useState(employeeData().employeePermission.permissionsContabilite[5])
  const [filtered, setFiltered] = useState(makeAccounting.accountingAllItemsSale.getItemsNormalStock())
  const [orderBy, setOrderBy] = useState('asc')
  const { setIsLoading } = useLoading()
  const { showError } = useError()
  useEffect(() => {
    handleChangeFilter()
  }, [orderBy])

  const controllerFilter = () => {
    let sortedClients = [...makeAccounting.accountingAllItemsSale.getItemsGenericProductsStock()]
    switch (orderBy) {
      case 'asc':
        sortedClients.sort((a, b) => a.nameProduct.localeCompare(b.nameProduct))
        break
      case 'desc':
        sortedClients.sort((a, b) => b.nameProduct.localeCompare(a.nameProduct))
        break
      default:
        break
    }
    return sortedClients;
  }


  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()
    resetScroll()
    if(value!= null){
      const filteredStock = data.filter(
        stock => {
          const filternName = stock.nameProduct.toLowerCase().includes(value.target.value.toLowerCase())
          const filterValue = stock.valueToSale.toString().toLowerCase().includes(value.target.value.toLowerCase());
          const filterValueCoast = stock.valueCoast.toString().toLowerCase().includes(value.target.value.toLowerCase());
          const filterCodebar = stock.codebar.toString().toLowerCase().includes(value.target.value.toLowerCase())
          return filternName || filterValue || filterValueCoast  || filterCodebar
        }
      )
      setFiltered(filteredStock)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    if(container){
      container.scrollTop = 0
      setLimit(50)
    }
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  const handleOpenPdf = async()=>{
    const map = {}
    filtered.forEach(ss=>{
      map[ss.idGenericProducts.toString()] = ss.getMapFromCloud()
    })

    setIsLoading(true)
    await requestPDFs({
      'type': 'pdfProductsSold',
      'list': map,
      'myStore': myStoreDataMemory.getMapFromCloud()
    },showError)
    setIsLoading(false)
  } 

  return (
    <div className={style.containerGeneral}>
       <div className={style.containerGlobal}>  
           {canViewSold? 
           <>{makeAccounting.isCalculated === true ? 
           <>
              <div className={style.titleMenu}>     
                  {window.innerWidth > 1200? <TitlePageResponsiveIcon subtitle={'Lista de Produtos Genéricos Vendidos no Período de Busca'}  
                  path={'São Todas os Produtos Genéricos Vendidos no Período de Busca...'} icon={faList}/>:null}
                    <Space left={5}/>
                    { window.innerWidth > 1200?
                      <>
                        <div className={style.buidWithText}>
                          <GetSmallModels title={'PRODUTOS VENDIDOS: '} value={makeAccounting.accountingAllItemsSale.itemsGenericStockSales.amountItems +' Un'} />
                          <Space left={5}/>
                          <GetSmallModels title={'MODELOS VENDIDOS: '} value={makeAccounting.accountingAllItemsSale.itemsGenericStockSales.totalSaleCount} />
                        </div>
                        <Space left={5}/>
                        <div className={style.divRow}>
                            <GetButton title={'PDF DOS PRODUTOS'} handleFunction={handleOpenPdf}  height={50} color={"#0161DD"} icon={faFilePdf}/>
                        </div>
                      </>:
                      <>
                        <div className={style.buidWithText}>
                          <GetSmallModels title={'PRODUTOS VENDIDOS: '} value={makeAccounting.accountingAllItemsSale.itemsGenericStockSales.amountItems +' Un'} />
                          <Space left={5}/>
                          <GetSmallModels title={'MODELOS VENDIDOS: '} value={makeAccounting.accountingAllItemsSale.itemsGenericStockSales.totalSaleCount} />
                          <GetButton title={'PDF DOS PRODUTOS'} handleFunction={handleOpenPdf} height={45} color={"#0161DD"} icon={faFilePdf}/>
                        </div>
                      </>
                    }
              </div>
              {window.innerWidth < 1200? <Space top={5}/>:null}
              <div className={style.buildList}>
              <div className={style.filter}>
                  <div className={style.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} 
                    type={"text"} placeholder={"Buscar Produto Vendido..."}
                    onChange={handleChangeFilter}/>
                  </div>
                  <div className={style.containerDropDown}>
                    <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                        <option value="asc">CRESCENTE</option>
                        <option value="desc">DESCRESCENTE</option>
                    </select>
                  </div>
              </div>
              <Space right={2} left={2} top={5} bottom={0}/> 
              {(filtered.length > 0) ?<div className={style.buildList}>
                  <GetAmountList amount={filtered.length} title={"Produtos Vendidos no Período de Busca..."}/>     
                <div className={style.labelData}>
                <h5>Código</h5>
                <h5>Código de Barras</h5>
              </div>
              <Space right={0} left={0} top={0} bottom={10}/> 
              </div> : null}
              <div ref={containerRef} className={style.containerList} onScroll={checkScroll}>
                  {(filtered.length === 0)?
                    <GetSmallModels title={"Não há Produtos com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo} />:
                    filtered.slice(0, limit).map((item, index) => (
                      <ProductModelFromSale data={item} />
                    ))
                  }
              </div>
              </div>
            </> :
            <div className={style.divSapce}> 
              <GetNeedUpdate/>
            </div> } </>:
               <div className={style.divSapce}>
               <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar a Lista de Produtos Vendidos'} />
               </div>}
       </div>
    </div>
   )

}

export default ListProductsSold