import { listClientsMemory, listEmployeeMemory, listItemsMemory, listProductsMemory, myMoth } from "../backEnd/Memory"
import { sendRequestGetLocal, sendRequestPostLocal } from "../backEnd/requesition"
import Moth from "../objects/Moth/Moth"

const _routerGetMothsInCloud ='/moth/getCurrentMoth'
const _routerSetNewMothInCloud ='/moth/setNewMoth'
const _routerCloseMothInCloud ='/moth/closeCurrentMoth'
const _routerGetListWithMothsInCloud ='/moth/getListMoths'

export const getMyMoth = async (showError,genericProducts,listItemsBj,employeeList,clientsList) => {
        var results = []
        results = await sendRequestGetLocal(_routerGetMothsInCloud)
        if(!results.response){
            if(results.length === 0)
                myMoth.fromNull()
            else
                myMoth.fromCloud(results[0],showError,genericProducts,listItemsBj,employeeList,clientsList)
        }else throw new Error(results.response.data.sqlMessage) 
        try {
    } catch (error) {
        showError('ERROR AO BUSCAR MÊS ATUAL',error.message)     
        return []
    }
}

export const getListMoths = async (datetime,showError) =>{
    try {

        var results = await sendRequestPostLocal(_routerGetListWithMothsInCloud,{
            'date': datetime
        })
        let list = []
        if(Array.isArray(results.data)){
            Object.values(results.data).forEach(moth =>{ 
                const newMoth = new Moth()
                newMoth.fromCloud(moth,showError,listProductsMemory,listItemsMemory,listEmployeeMemory,listClientsMemory)
                list.push(newMoth)
            })
  
            return list
        }else throw new Error(results.response.data.sqlMessage)

    } catch (error) {   
        showError('ERROR AO BUSCAR MESES',error.message)
        return {status:404}
    }
}

export const setSaveNewMoth= async (moth,showError) =>{
    try {       
        var results = await sendRequestPostLocal(_routerSetNewMothInCloud,moth.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO FINALIZAR O MÊS',error.message)
        return {status:404}
    }
}

export const setCloseMoth= async (dateFinal,showError) =>{
    try {       
        myMoth.setCloseNow(dateFinal)
        var results = await sendRequestPostLocal(_routerCloseMothInCloud,myMoth.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO FINALIZAR O MÊS',error.message)
        return {status:404}
    }
}