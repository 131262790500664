import React, { useState } from 'react';
import style from '../../style/GetAddFinance.module.css'
import { faClose, faBank, faMoneyBillTrendUp, 
     faAdd, faSignature, faCalendar, faDesktopAlt } from '@fortawesome/free-solid-svg-icons';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage';
import { GetButton, GetButtonIcon } from '../../layout/ButtonsLayout';
import InputModelsBlock from '../../layout/InputModelsBlock';
import Space from '../../layout/Space';
import Alert from '../../Models/Alert';
import { CategoryFinancialDropDown, SituationFinancialDropDown, TypeFinancialDropDown } from '../../layout/FinancialDropDown';
import { useError } from '../../AppContext';
import { setNewFinancial, setUpdateFinancial } from '../../controllers/CloudFinancial';
import { FinancialObject } from '../../objects/Financial/FinancialObject';
import Loader from '../../layout/Loader';

const getFormData = (financial) => {
    return {
        name:financial.name,
        idFinancial:financial.idFinancial,
        description:financial.description,
        date:financial.getDateFormatEdite(),
        value:financial.value === 0? '': financial.value ,
        typePayment:financial.typePayment,
        situation:financial.situation,
        category:financial.category,
        idEmployee:financial.idEmployee,
    }
}

const getFormNeed = () => {
    return {
        date:false,
        value:false,
        name:false,
        description:false,
    }
}

function GetAddFinancial({onClose,onSave,financial}) {

    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData(financial?? new FinancialObject()))
    const [needData, setNeedData] = useState(getFormNeed)
    const {showError} = useError()
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
      onClose()
    } 

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmitSave= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setAlert(true)
            return
        }

        handleSave()
    }

    const handleSave = async()=>{
        
        setLoading(true)
        let result = null
        if(financial){
            financial.fromUpdate(formData)
            result = await setUpdateFinancial(financial,showError)
        }else{
            const tempFinancial = new FinancialObject()
            tempFinancial.fromNew(formData)
            result = await setNewFinancial(tempFinancial,showError)
        }

        setLoading(false)

        if(result.status === 200){
            onSave()
            onClose()
        }
    }

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
        { !loading?  
            <>
            <div className={style.title}>
                    <TitlePageResponsiveIcon subtitle={"CADASTRAR FLUXO FINANCEIRO"}
                    children={
                        <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                    }
                    path={"Complete os Campos Abaixo"} icon={faBank}/>  
            </div>
            <div className={style.div}>
                    <div className={style.divDropDown}>
                        <SituationFinancialDropDown initialState={formData.situation.toUpperCase()} 
                        handlerFuncion={handleChange} name={'situation'}/>
                        <Space top={5}/>
                        <TypeFinancialDropDown initialState={formData.typePayment.toUpperCase()} 
                        handlerFuncion={handleChange} name={'typePayment'}/>
                        <Space top={5}/>
                        <CategoryFinancialDropDown initialState={formData.category.toUpperCase()} 
                        handlerFuncion={handleChange} name={'category'}/>
                    </div>
                    <Space top={5}/>
                    <InputModelsBlock legend={'NOME/APELIDO DO FLUXO'} onChange={handleChange} 
                        validation={needData.name} maxLength={25} value={formData.name} name={'name'} 
                        iconButton={faSignature} type={"text"} placeholder={"Nome/Apelido do Fluxo..."}/>
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'DESCRIÇÃO SIMPLES'} onChange={handleChange} 
                        validation={needData.description} maxLength={25} value={formData.description} 
                        name={'description'} iconButton={faDesktopAlt} type={"text"} 
                        placeholder={"Descrição Simples do Fluxo..."}/>  
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'DATA DE VENCIMENTO'} onChange={handleChange} 
                        validation={needData.date} maxLength={10} value={formData.date} name={'date'} 
                        iconButton={faCalendar} type={"date"} placeholder={"Data de Vencimento do Fluxo..."}/>
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'VALOR MONETÁRIO'} onChange={handleChange} 
                        validation={needData.value} maxLength={10} value={formData.value} 
                        name={'value'} iconButton={faMoneyBillTrendUp} type={"number"} 
                        placeholder={"Tipo de Conta..."}/>  
            </div>
            <div className={style.div}>
                <GetButton title={"ADCIONAR NOVO FLUXO FINANCEIRO"} color='#007bff' icon={faAdd} handleFunction={handleSubmitSave}/> 
            </div>
            {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}          
            </>:
            <div className={style.loaderCenter}>
                <Loader titleFinding={'Salvando Dados'} 
                subtitleFinding={'Aguarde...'} loading={loading}/>
            </div>
        }
        </div>
      </div>
    )
}

export default GetAddFinancial
