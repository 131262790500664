class DatabaseSettings{
    constructor(){
        this.idDatabaseSettings = 0
        this.serviceValue = ''
        this.keyCodePix = ''
        this.messageMoth = ''
        this.dayStartService= new Date()
        this.versionApp = ''
        this.idApp = ''
        this.isCanUseBrunaBJ = true
        this.idUsers = 0
    }

    fromUpdate(data){
        this.serviceValue = data.serviceValue
        this.keyCodePix = data.keyCodePix
        this.messageMoth = data.messageMoth
        this.dayStartService = new Date(data.dayStartService)
        this.versionApp =data.versionApp
        this.idApp = data.idApp
    }


    getDateToString(){
        return this.dayStartService.toISOString().replace("T",' ').slice(0,19)
    }


    fromCloud(data){
        this.idDatabaseSettings = data.idDatabaseSettings
        this.serviceValue = data.serviceValue
        this.keyCodePix = data.keyCodePix
        this.messageMoth = data.messageMoth
        this.dayStartService = new Date(data.dayStartService)
        this.idUsers = data.idUsers
        this.isCanUseBrunaBJ = data.isCanUseBrunaBJ === 1 ? true : false
        this.versionApp =data.versionApp
        this.idApp = data.idApp
    }

    getMapFromCloud(){
        return{
            'idDatabaseSettings' : this.idDatabaseSettings,
            'serviceValue' :this.serviceValue,
            'keyCodePix' : this.keyCodePix,
            'messageMoth' : this.messageMoth,
            'dayStartService' :this.dayStartService.toISOString().slice(0,10),
            'idUsers' : this.idUsers,
            'versionApp' :this.versionApp,
            'idApp':this.idApp,
            'isCanUseBrunaBJ':this.isCanUseBrunaBJ
        }
    }
}

export default DatabaseSettings