import React, {useEffect } from 'react';
import style from "../style/Alert.module.css"

const Alert = ({ message, time, type, onClose, top, right }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onClose) onClose();
    }, time||7000)
    return () => clearTimeout(timeout)
  }, [onClose])

  const alertStyle = {
    top: top || '100px', 
    right: right || '35px', 
  }

  return (
    <div className={`${style.alert} ${style[type]}`} style={alertStyle}>
      <span className={style.message}>{message}</span>
    </div>
  )
}

export default Alert;
