import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "../style/DefaultNavigatorBar.module.css";
import { faArrowRightLong, faBars, faBell, faCalculator, faClose, faDollarSign, faMagnifyingGlass, faSearch, faThumbtack, faUser } from "@fortawesome/free-solid-svg-icons";
import {
    faCartShopping, faHouseUser, faUsers, faLayerGroup
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { routerFromAccountingGlobal, routerFromClientsGlobal, routerFromFinancialGlobal, routerFromMoreOptionsGlobal, routerFromMyStoreGlobal, routerFromPerfilGlobal, routerFromSale, routerFromStockGlobal } from "../routers/routerList.js";
import InputModels from "../Models/InputModels.js";
import { mothPay, search } from "../backEnd/Memory.js";
import { GetButton, GetButtonIcon } from "./ButtonsLayout.js";
import GetNoticeMothPay from "./GetNoticeMothPay.js";
import { useShowMenu } from "../AppContext.js";

function DefaultNavigatorBar() {
    const location = useLocation()
    const navigate = useNavigate()
    const [isNeedPay,setNeedPay] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const [showMothPay,setShowMothPay] = useState(false)
    const [value, setValue] = useState([])
    const {showMenu , setShowMenu} = useShowMenu()
    const [showSearch , setshowSearch] = useState(false)

    useEffect(() => {
        setValue('')
        setSearchResults([])
        if(mothPay.isLoaded) setNeedPay(mothPay.isOverdue)
    }, [location,isNeedPay])

    const handleInputChange = (event) => {
        setValue(event.target.value)
        setSearchResults(search.findResults(event.target.value))
    }

    const handleGo = (result) => {
        setValue('')
        setSearchResults([])
        navigate(result.router)
        setshowSearch(false)
    }

    return (
        <div className={styles.naviBar}>
            {window.innerWidth <= 768? 
            <div onClick={()=>setShowMenu(!showMenu)} className={styles.menuIconOpen}>
                <FontAwesomeIcon className={styles.iconOpen} icon={faBars} /> 
            </div>
            : null}
            {window.innerWidth > 768?
            <div className={styles.containerTitleAndSearch}>
                <div className={styles.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} value={value} type={"text"}
                        placeholder={"Buscar Por Algo..."}
                        onChange={handleInputChange} />
                    {value === "" || searchResults.length === 0 ? null :
                    <div className={styles.listResults}>
                            {searchResults.map((result, index) => (
                                <div className={styles.searchResult} onClick={() => handleGo(result)}>
                                    <p>{result.pathName}</p>
                                    <FontAwesomeIcon icon={faArrowRightLong} />
                                </div>
                            ))}
                    </div>}
                </div>
            </div>:
            <>
               {showSearch?
                    <>
                        <div className={styles.inputFilterMobile}>
                            <div className={styles.inputFilterMobileInput}>
                                <div className={styles.marge}>
                                    <InputModels iconButton={faMagnifyingGlass} value={value} type={"text"}
                                        placeholder={"Buscar Por Algo..."}
                                        onChange={handleInputChange} />
                                    <div className={styles.buttonCloseSearch}>
                                        <GetButtonIcon icon={faClose} height={65} width={65} handleFunction={()=> setshowSearch(false)} color={"#ff0000"}/>
                                    </div>
                                </div>
                            </div>
                            {value === "" || searchResults.length === 0 ? null :
                            <div className={styles.listResults}>
                                    {searchResults.map((result, index) => (
                                        <div className={styles.searchResult} onClick={() => handleGo(result)}>
                                            <p>{result.pathName}</p>
                                            <FontAwesomeIcon icon={faArrowRightLong} />
                                        </div>
                                    ))}
                            </div>}
                        </div>
                    
                    </>:null}
            </>}
            <nav className={styles.navigationMenu}>
                <ul className={styles.rowForced}>
                    {window.innerWidth < 768? <div onClick={()=>setshowSearch(!showSearch)} className={styles.menuIconOpen}>
                        <FontAwesomeIcon className={styles.iconSearch} icon={faSearch} /> 
                    </div>: null}
                    <li className={styles.menuItem}>
                        <Link to={routerFromSale}>
                            <FontAwesomeIcon className={styles.menuTranstion} icon={faCartShopping} />
                            VENDER
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link to={routerFromClientsGlobal}>
                            <FontAwesomeIcon className={styles.menuTranstion} icon={faUsers} />
                            CLIENTES
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link to={routerFromMyStoreGlobal}>
                            <FontAwesomeIcon className={styles.menuTranstion} icon={faHouseUser} />
                            MINHA LOJA
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link to={routerFromStockGlobal}>
                            <FontAwesomeIcon className={styles.menuTranstion} icon={faLayerGroup} />
                            ESTOQUE
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link to={routerFromAccountingGlobal}>
                            <FontAwesomeIcon className={styles.menuTranstion} icon={faCalculator} />
                            CONTABILIDADE
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link to={routerFromFinancialGlobal}>
                            <FontAwesomeIcon className={styles.menuTranstion} icon={faDollarSign} />
                            FINANCEIRO
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link to={routerFromMoreOptionsGlobal}>
                            <FontAwesomeIcon className={styles.menuTranstion} icon={faThumbtack} />
                            MAIS OPÇOES
                        </Link>
                    </li>
                </ul>
            </nav>
            {isNeedPay ?
                <> {window.innerWidth >1200?
                <div className={styles.divPay}>
                    <GetButton title={"MENSALIDADE VENCIDA"} height={50} color='#ff0000' icon={faBell} handleFunction={()=>setShowMothPay(true)}/> 
                </div> :
                <div className={styles.divButtonPayMobile}>
                    <GetButtonIcon height={50} width={50} color='#ff0000' icon={faBell} handleFunction={()=>setShowMothPay(true)}/> 
                </div>
                }</>: null }
            {showMothPay? <GetNoticeMothPay onClose={()=>setShowMothPay(false)}/>:null}
        </div>
    );
}

export default DefaultNavigatorBar;
