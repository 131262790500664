import React, { useState, useEffect, useRef } from 'react';
import style from "../../style/ListClients.module.css"
import { GetSmallModels } from '../../Models/GetModels.js';
import { faMagnifyingGlass,faCircleInfo, faList, faLock} from '@fortawesome/free-solid-svg-icons';
import InputModels from "../../Models/InputModels.js"
import GetAmountList from '../../Models/GetAmountList.js';
import Space from '../../layout/Space.js';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage.js';
import ViewSaleController from '../Sale/ShowSale/ViewSaleController.js';
import { makeAccounting } from '../../objects/Accounting/MakeAccounting.js';
import GetNeedUpdate from './GetNeedUpdate.js';
import GetCommissions from '../../Models/GetCommissions.js';
import { employeeData } from '../../backEnd/InitController.js';

function ListofCommission() {

  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])
  const [canView] = useState(employeeData().employeePermission.permissionsContabilite[9])
  const [filtered, setFiltered] = useState(makeAccounting.accountingAllCommissions.getListComissions())
  const [orderBy, setOrderBy] = useState('asc')
  const [filter, setFilter] = useState('withSale')
  const [showViewSale, setShowViewSale] = useState(false)
  const [saleSelected, setSaleSelected] = useState(null)

  const handleOpenViewSale = (sale) =>{
    setSaleSelected(sale)
    setShowViewSale(true)
  }

  useEffect(() => {
    handleChangeFilter()
  }, [orderBy,filter])

  const controllerFilter = () => {
    let data = makeAccounting.accountingAllCommissions.getListComissions()

    switch (filter) {
      case 'withSale':
        data = data.filter(item => item.amountSale !== 0)
        break
      case 'withOutSale':
        data = data.filter(item => item.amountSale === 0)
        break
      default:
        break
    }

    switch (orderBy) {
      case 'asc':
        data.sort((a, b) => a.personOwner.genericPerson.name.toString().localeCompare(b.personOwner.genericPerson.name))
        break
      case 'desc':
        data.sort((a, b) => b.personOwner.genericPerson.name.toString().localeCompare(a.personOwner.genericPerson.name))
        break
      default:
        break
    }
    return data
    
  }

  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleFFilterByChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()

    resetScroll()
    if(value!= null){
      const filtered = data.filter(
        sale => {
          const filterName = sale.personOwner.genericPerson.name.toString().toLowerCase().includes(value.target.value.toLowerCase())
          return filterName
        }
      )
      setFiltered(filtered)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    if(container){
      container.scrollTop = 0
      setLimit(50)
    }
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }


  return (
    <div className={style.containerGeneral}> 
       <div className={style.containerGlobal}>  
           {canView? <>{makeAccounting.isCalculated === true ? 
           <>
              <div className={style.titleMenu}>     
              {window.innerWidth > 1200?<TitlePageResponsiveIcon subtitle={'Lista de Comissões Por Funcionário'}  
                  path={'São Todas as Comissões dos Funcionários Relacionadas às Respectivas Vendas de Cada um...'} icon={faList}/>:null}
                  <Space left={5}/>
                  { window.innerWidth > 1200?
                    <div className={style.buidWithText}>
                        <GetSmallModels title={'TOTAL DE FUNCIONÁRIOS: '} subtitle={'Ativos e Inativos'} value={makeAccounting.accountingAllCommissions.length}/>
                        <Space left={5}/>
                        <GetSmallModels title={'VENDAS COMISSIONADAS: '}  subtitle={'Somando Todas'} value={makeAccounting.accountingAllCommissions.valueComissionsCount}/>
                        <Space left={5}/>
                        <GetSmallModels title={'COMISSÕES: '} subtitle={'Total Final'} value={makeAccounting.accountingAllCommissions.getValueAllComissions()}/>
                    </div>:null
                  }
              </div>
              { window.innerWidth <= 1200?
                <div className={style.buidWithText}>
                        <GetSmallModels title={'TOTAL DE FUNCIONÁRIOS: '}  value={makeAccounting.accountingAllCommissions.length}/>
                        <GetSmallModels title={'VENDAS COMISSIONADAS: '}  value={makeAccounting.accountingAllCommissions.valueComissionsCount}/>
                        <GetSmallModels title={'COMISSÕES: '}  value={makeAccounting.accountingAllCommissions.getValueAllComissions()}/>
                </div>:null
              }
              {window.innerWidth <= 1200? <Space top={5}/>:null}
              <div className={style.buildList}>
              <div className={style.filter}>
                  <div className={style.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} 
                    type={"text"} placeholder={"Buscar Funcionário..."}
                    onChange={handleChangeFilter}/>
                  </div>
                  <div className={style.containerDropDown}>
                    <select className={style.dropDown} value={filter} onChange={handleFFilterByChange}>
                        <option value="withSale">COM VENDAS</option>
                        <option value="all">TODOS</option>
                        <option value="withOutSale">SEM VENDAS</option>
                    </select>
                    <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                        <option value="asc">CRESCENTE</option>
                        <option value="desc">DESCRESCENTE</option>
                    </select>
                  </div>
              </div>
              <Space right={2} left={2} top={5} bottom={0}/> 
              {(filtered.length > 0) ?<div className={style.buildList}>
                  <GetAmountList amount={filtered.length} title={"Funcionários com Vendas Comissionadas..."}/>     
                <Space right={0} left={0} top={0} bottom={10}/> 
              </div> : null}
              <div ref={containerRef} className={style.containerListForced} onScroll={checkScroll}>
                  {(filtered.length === 0)?
                    <GetSmallModels title={"Não há Futuros Recebimentos com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo} />:
                    filtered.slice(0, limit).map((dataReceiver, index) => (
                      <div>
                        <GetCommissions handlerFunction={handleOpenViewSale} comission={dataReceiver}/>
                        <Space top={50}/>
                      </div>
                    ))
                  }
              </div>
              </div>
            </> :
            <div className={style.divSapce}> 
              <GetNeedUpdate/>
            </div> }</>:
               <div className={style.divSapce}>
               <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar a Lista de Comissões'} />
               </div>}
       </div>
       {showViewSale ? <ViewSaleController handleSave={()=>{}} saleStart={saleSelected} handleClose={()=> setShowViewSale(false)}/> : null}
    </div>
   )

}

export default ListofCommission