import React from "react";
import style from "../style/RepositionsModel.module.css"
function RepositionModel({data,fucntionButton}) {
   
    return(
        <div className={(data.status === true ? style.containerWrap : style.containerWrap + ' ' + style.inactive)}>
            <div className={style.containerWrap1}>
                <button className={style.container} onClick={fucntionButton}>
                    <div className={style.containerNames}>
                        <h3>{data.nameReposition}</h3>
                        <h4>{data.getDate()}</h4>
                        <h2>{data.isGenericProduct? "PRODUTOS":"BRUNA SEMIJOIAS"}</h2>
                        <h5 className={(data.statusReposition.includes("ADIÇÃO") ? style.status : style.status + ' ' + style.desable)}>{data.statusReposition}</h5>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default RepositionModel