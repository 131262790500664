import { forwardRef, useImperativeHandle, useState } from "react";
import InputModelsBlock from "../../layout/InputModelsBlock";
import style from '../../style/GetNFeConfigureCompany.module.css'
import {  faFileImport, faFile, faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Space from "../../layout/Space";
import Alert from "../../Models/Alert";
import { TitlePageResponsiveIcon } from "../../layout/TitlePage";
import { GetButtonIcon } from "../../layout/ButtonsLayout";
import { GenericDropDown } from "../../layout/GenericDropDown";

const getFormData = (nfe) => {
    return {
        infCpl:nfe.infAdic.infCpl,
        modFrete:nfe.transp.modFrete,
    }
}

export const GetNFeOthers = forwardRef(({initialExpand,nfe}, ref) =>   {


    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData(nfe))
    const [expanded, setExpand] = useState(initialExpand?? true)

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    useImperativeHandle(ref, () => ({
        handleSubmit
    }))


    const handleSubmit= () => {  
        return formData
    }

    return (
        <div className={style.div}>
            <TitlePageResponsiveIcon 
                children={
                    <GetButtonIcon color='#094f00' height={40} width={40} icon={expanded? faAngleUp :  faAngleDown} handleFunction={()=>setExpand(!expanded)}/> 
                }
                subtitle={"DETALHES E FRETE"} path={"São Detalhes da Nota Fiscal Eletrônica (NF-e)"} icon={faFileImport}/> 
             <div className={expanded? style.visible :   style.notVisible}>
                <div className={style.div}>
                    <div className={style.divInRow}>
                    <GenericDropDown legend={'MODALIDADE DO FRETE'} 
                        data={['0 - Por conta do emitente', 
                        '1 - Por conta do destinatário/remetente', 
                        '2 - Por conta de terceiros',
                        '3 - Transporte próprio por conta do remetente',
                        '4 - Transporte próprio por conta do destinatário',
                        '9 - Sem cobrança de frete',]} 
                    name={'modFrete'} initialState={formData.modFrete} handlerFuncion={handleChange}  /> 
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'INFORMAÇÕES ADICIONAIS'} onChange={handleChange}  maxLength={250} value={formData.infCpl} name={'infCpl'} iconButton={faFile} type={"text"} placeholder={" Informações adicionais de interesse do Fisco...."}/>  
                    </div>
                </div>
            </div>
            {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
    )
})