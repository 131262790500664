import React, { useState } from "react"
import AddEmployeeGeneric from "./AddEmployerGeneric"
import style from "../../style/EditeEmployee.module.css"
import GetFloatingButton from "../../layout/GetFloatingButton"
import { faClose, faLock } from "@fortawesome/free-solid-svg-icons"
import { employeeData } from "../../backEnd/InitController"
import { GetButtonIcon } from "../../layout/ButtonsLayout"
import { TitlePageResponsiveIcon } from "../../layout/TitlePage"

function EditeEmployee({employee, onClose,onSave}){
    
    const [employeelogdedIn] = useState(employeeData())
    const handleClose = () => {
        onClose()
    } 

  
    if(!employeelogdedIn.employeePermission.permissionsEmployers[2]){
        return(
            <div className={style.overlay}>
                <div className={style.alertBlock}>
                    <TitlePageResponsiveIcon title={'ACESSO NEGADO'} 
                    children={  <GetButtonIcon color={"#ff0000"} handleFunction={onClose} icon={faClose} />}
                    icon={faLock}
                    path={'Você Não Tem Permissão para Visualizar Dados dos Funcionários'}/> 
                </div>
            </div>
        )    
    }

    return(
        <div className={style.overlay}>
            <div className={style.alert}>
                <AddEmployeeGeneric onSave={onSave} onClose={onClose} employeeDataControle={employee} />
            </div>
            <GetFloatingButton legend={'FECHAR'} width={window.innerWidth > 1200 ? 75 : 65} height={window.innerWidth > 1200 ? 75: 65} icon={faClose} color='#Ff0000' 
            margiRight={window.innerWidth > 1200 ? '1%': '20%'} margiBottom={window.innerWidth > 1200 ?  "90%" : '1%'} handleFunction={handleClose} /> 
        </div>
    )
}

export default EditeEmployee