import React from "react"
import style from "../../style/NewSaleScreen.module.css"
import SaleGeneric from "./SaleGeneric.js"

function NewSaleScreen(){
    return(
        <div>
            <div className={style.containerGlobal}>
                <div className={style.containerCenter}>
                    <SaleGeneric/>
                </div>
            </div>
        </div>
    )
}

export default NewSaleScreen