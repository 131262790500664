import { employeeData } from "../../backEnd/InitController";
import { myStoreDataMemory } from "../../backEnd/Memory";
import { requestPDFs } from "../../controllers/CloudPdfs";
import ClientsObject from "../ClientsAndPerson/clientsObject";

class AccountingAllSalesReceiver{
    constructor(){
        this.ownerSales = new ClientsObject()
        this.saleOpen = [] //Sales
        this.valueTotalOpen = 0
        this.amountTotalSalesOpen  = 0
    }

    async openPDFFutureReceivers(showError){
        const body = {
          'type':'pdfAllSaleOpenOnlyClient',
          'employee': employeeData().genericPerson.name,
          'client': this.ownerSales._genericPerson.name,
          'controller': this.getMapFromCloud(),
          'valueTotalOpen': this.valueTotalOpen,
          'amountTotalSalesOpen': this.amountTotalSalesOpen,
          'myStore': myStoreDataMemory.getMapFromCloud(),
        }
        await requestPDFs(body,showError)
      }

    getMapFromCloud(){
        let map = {}
        this.saleOpen.forEach(ss=>{
            map[ss.getCodeSale()] = ss.getMapFromCloud()
        })

        return{
           'saleList' : map
        }
    }
      
    getValueTotalOpen(){
        return this.valueTotalOpen.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
    
    fromInit(ownerSales,saleOpen){
        this.ownerSales = ownerSales
        this.saleOpen = saleOpen
    }

    calculate(){
        this.valueTotalOpen = 0
        this.amountTotalSalesOpen = 0
        for (var element of this.saleOpen) {
            this.valueTotalOpen += parseFloat(element.controllerPayment.getValueNeedPay())
            this.amountTotalSalesOpen++;
        }
    }
}

export default AccountingAllSalesReceiver