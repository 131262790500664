class AccountingDataDetails{
    constructor(){
        this.totalSalesInPeriod = 0
        this.allValue = 0
        this.allDiscount = 0
        this.allExtra = 0
  
        this.valueFactorCompany = 0
        this.valueRealCoastHelp = 0
        this.valueRealCoastHelpWithLiquid = 0
        this.valueIsPresent = 0
        this.valueTotalCancel = 0
    }
  
    getAllValue(){
        return this.allValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
    getAllDiscount(){
        return this.allDiscount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
    getAllExtra(){
        return this.allExtra.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
    getValueIsPresent(){
        return this.valueIsPresent.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    clearData(){
        this.valueTotalCancel = 0
        this.allExtra = 0
        this.valueFactorCompany =0
        this.valueRealCoastHelp =0
        this.allDiscount = 0
        this.valueIsPresent = 0
        this.valueRealCoastHelpWithLiquid =0
        this.totalSalesInPeriod = 0
        this.allValue = 0
    }
  
}

export default AccountingDataDetails
