import React, { useEffect, useRef, useState } from "react"
import style from "../../../style/UpdateStock.module.css"
import { GetSmallModels } from "../../../Models/GetModels"
import {
    faBarcode, faCalculator, faCamera, faCircleInfo, faKeyboard, faListUl, faLock, faMagnifyingGlass,
    faSave, faSliders, faTrashCan
} from "@fortawesome/free-solid-svg-icons"
import { faNewspaper } from "@fortawesome/free-regular-svg-icons"
import InputModels from "../../../Models/InputModels.js"
import TitlePage, { TitlePageResponsiveIcon } from "../../../layout/TitlePage.js"
import { ToggleButtonWithContainer } from "../../../layout/ToggleButton.js"
import FindWithBarcode from "../../FindWithBarcode.js"
import GetFloatingButton, { GetFloatingButtonWithLegend } from "../../../layout/GetFloatingButton.js"
import Loader from "../../../layout/Loader.js"
import InputModelsBlock from "../../../layout/InputModelsBlock.js"
import GetExpandedImage from "../../../layout/GetExpandedImage.js"
import ConfirmationPopup from "../../../Models/ConfirmationPopup.js"
import Alert from "../../../Models/Alert.js"
import GenericProduct from "../../../objects/Brands/Products/GenericProducts.js"
import CalculateProducts from "../../../objects/CalculateProducts.js"
import { getMapFromListProdutcs, setUpdateProductsInBatch } from "../../../controllers/CloudProducts.js"
import ProductModelList from "../../../Models/ProductModelList.js"
import { useError, useLoading } from "../../../AppContext.js"
import { findproduts, initSystem, listProductsMemory, myStoreDataMemory, } from "../../../backEnd/Memory.js"
import { employeeData } from "../../../backEnd/InitController.js"
import { useNavigate } from "react-router-dom"
import { routerFromStockOthers } from "../../../routers/routerList.js"
import { requestPDFs, setNewPdfSaveReposition } from "../../../controllers/CloudPdfs.js"
import { getMotion, setNewReposition } from "../../../controllers/CloudRepositions.js"
import Barcode from "../../Barcode.js"
import { GetButton } from "../../../layout/ButtonsLayout.js"
import Space from "../../../layout/Space.js"

export const labelListRepositionProduct = "listRepositionProducts"
export const labelToggleStatusProduct = "toggleStatusProducts"
const calculateProducts = new CalculateProducts()

function InitDataMemory(listProduct) {
    const dataLocal = localStorage.getItem(labelListRepositionProduct)
    if (dataLocal) {
        const parsedData = JSON.parse(dataLocal)
        var list = parsedData.map(itemDataLocal => {
            const foundItem = listProduct.find(element => element.idGenericProducts === itemDataLocal.idGenericProducts)
            if (foundItem) {
                const product = new GenericProduct()
                product.fromOtherWithAmount(itemDataLocal.amount, itemDataLocal)
                return product
            }
            return null
        }).filter(item => item !== null)

        calculateProducts.calculate(list)
        return list
    }
    return []
}

function UpdateProducts() {
    const navigate = useNavigate()
    const [canEdite] = useState(employeeData().employeePermission.permissionsItems[0])
    const [toggleStatus, setToggleStatus] = useState(() => {
        const storedValue = localStorage.getItem(labelToggleStatusProduct)
        return storedValue ? storedValue === 'true' : true
    })

    const containerRef = useRef(null)
    const [limit, setLimit] = useState([50])
    const { setIsLoading } = useLoading()
    const [showAlertSave, setShowAlertSave] = useState(false)
    const [showConfirmSave, setShowConfirmSave] = useState(false)

    const [listItems, setListItems] = useState()
    const [filtered, setFiltered] = useState([])
    const [list, setList] = useState([])

    const [loading, setLoading] = useState(false)
    const { showError } = useError()

    const [isExpanded, setIsExpanded] = useState(false)
    const [nameProduct, setNameProduct] = useState('')
    const [linkImage, setSetLinkImage] = useState('')
    const [showReadbarCode,setShowReadbarCode] = useState(false)
    const [inputBarcode, setInputbarcode] = useState('')

    const [statusFindWithCode, setStatusFindWithCode] = useState(false)

    const onValidator = (item, amount) => {
        var itemTemp = []
        var temp = []

        if (toggleStatus) {
            const foundIndex = list.findIndex(element => element.idGenericProducts === item.idGenericProducts)
            temp = []
            if (foundIndex !== -1) {
                temp = [...list]
                temp[foundIndex].amount += amount
            } else {
                itemTemp = { ...item }
                itemTemp.amount = amount
                temp = [...list, itemTemp]
            }
            saveLocalStorage(temp)
            return true
        } else {
            temp = []
            const foundIndex = listItems.findIndex(element => (element.idGenericProducts === item.idGenericProducts))

            if (foundIndex !== -1) {
                const hasIndex = list.findIndex(element => (element.idGenericProducts === item.idGenericProducts))

                if (hasIndex !== -1) {
                    if (list[hasIndex].amount + amount <= listItems[foundIndex].amount) {
                        temp = [...list]
                        temp[hasIndex].amount += amount
                    } else {
                        serErrorAmount()
                        return false
                    }

                } else {
                    itemTemp = { ...item }
                    itemTemp.amount = amount

                    if (itemTemp.amount <= listItems[foundIndex].amount) {
                        temp = [...list, itemTemp]
                    }
                    else {
                        serErrorAmount()
                        return false
                    }
                }
            }

            saveLocalStorage(temp)
            return true
        }
    }

    const serErrorAmount = () => {
        showError("A Quantidade ésta Inferior a Zero", "Não é Permitido Remover Peças Sem Estoque...")
    }

    const saveLocalStorage = (temp) => {
        localStorage.setItem(labelListRepositionProduct, JSON.stringify(temp))
        resetList(listItems)
    }

    const resetList = (results) => {
        var dataFromStorage = InitDataMemory(results)
        setList(dataFromStorage)
        setFiltered(dataFromStorage)
    }

    const toggleImageExpansion = (nameProduct, linkImage) => {
        setIsExpanded(!isExpanded)
        setNameProduct(nameProduct)
        setSetLinkImage(linkImage)
    }

    useEffect(() => {
        if (canEdite) {
            async function fetchData() {
                await handleFindDataBase()
            }
            fetchData()
        }
    }, [])

    const handleFindDataBase = async () => {
        setLoading(true)
        setIsLoading(true)
        await initSystem(showError)
        setListItems(listProductsMemory)
        resetList(listProductsMemory)
        setLoading(false)
        setIsLoading(false)
    }

    const handleChangeFilter = (value) => {
        const data = [...list]
        resetScroll()
        if (value != null) {
            const filteredStock = data.filter(
                stock => {
                    const filternName = stock.nameProduct.toLowerCase().includes(value.target.value.toLowerCase())
                    return filternName
                }
            )
            setFiltered(filteredStock)
        } else {
            setFiltered(data)
        }
    }

    const resetScroll = () => {
        const container = containerRef.current
        container.scrollTop = 0
        setLimit(50)
    }

    const checkScroll = () => {
        const container = containerRef.current;
        const scrollPosition = container.scrollTop;
        const containerHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;

        if (scrollPosition + containerHeight >= scrollHeight) {
            setLimit((limit + 50))
        }
    }

    const handleToggleChange = (newToggleStatus) => {
        localStorage.setItem(labelToggleStatusProduct, newToggleStatus)
        setToggleStatus(newToggleStatus)
        localStorage.removeItem(labelListRepositionProduct)
        resetList(listItems)
        handleClear()
    }

    const handleClear = () => {
        localStorage.removeItem(labelListRepositionProduct)
        localStorage.removeItem(labelToggleStatusProduct)
        resetList(listItems)
        calculateProducts.clear()
    }

    const handleCloseFindWithCode = () => {
        setStatusFindWithCode(false)
    }

    const handleOpenFindWithCode = () => {
        setStatusFindWithCode(true)
    }

    const onRemove = (itemToRemove) => {
        const temp = list.filter(item => item !== itemToRemove)
        saveLocalStorage(temp)
    }

    const onLess = (data) => {
        data.amount--
        if (data.amount <= 0)
            onRemove(data)
        else saveLocalStorage(list)
    }

    const handleTrySave = () => {
        if (list.length <= 0) {
            showError("A Lista Temporária Está Vazia", "Não é Permitido Atualizar uma Lista Vazia...")
            return
        }

        setShowConfirmSave(true)
    }

    const handleSave = async () => {
        setShowConfirmSave(false)
        setLoading(true)
        setIsLoading(true)
        var results = await setUpdateProductsInBatch(list, toggleStatus, showError)
        setNewReposition(getMotion(toggleStatus),true,list,showError)

        if (results.status === 200) {
            await findproduts()
            const blob = await requestPDFs({
                'type':'pdfRepositionProducts',
                'employee': employeeData().genericPerson.name,
                'typeOperation': toggleStatus? 1:0,
                'total':calculateProducts.items,
                'models':calculateProducts.models,
                'date':new Date().toISOString().replace("T",' ').slice(0,19),
                'listItems': getMapFromListProdutcs(list,''),
                'myStore': myStoreDataMemory.getMapFromCloud()
            },showError)
            setNewPdfSaveReposition(blob)
            navigate(routerFromStockOthers)
            setShowAlertSave(true)
            handleToggleChange(true)
            handleClear()
        }


        setLoading(false)
        setIsLoading(false)
    }

    const handleReadbarcode = (e) => {
        setInputbarcode(e.target.value)
        if (e.target.value.length >= 13) {
            var indexItem = listItems.findIndex(element => element.codebar === e.target.value)
            if (indexItem !== -1) {
                onValidator(listItems[indexItem], 1)
            } else {
                showError("Código de Barras Inválido", "Não Há Produtos com Esse Código de Barras")
                e.target.blur()
            }
            setInputbarcode('')
        }
    }

    const handlerCameraBarcode = (valueBarcode) =>{
        var indexItem = listProductsMemory.findIndex(element => (element.codebar.toString() === valueBarcode))
        if (indexItem !== -1) {
            if(onValidator(listProductsMemory[indexItem], 1)){
                return listProductsMemory[indexItem]
            }
        } else {
            showError("CÓDIGO DE BARRAS INVÁLIDO", "Não Há Produtos com Esse Código de Barras")
            setShowReadbarCode(false)
            return null
        }

        return null
    } 


    let label = toggleStatus ? 'Reposição' : 'Remoção'
    let label2 = toggleStatus ? 'Repor' : 'Remover'

    return (
        <div>
            <TitlePage title={'Produtos e Reposições'} subtitle={"Reposição/Remoção Marcas Genéricas"}
                path={"Estoque  >  Produtos e Reposições  >  Reposição Marcas Genéricas"} />

            {!canEdite ?
                <div className={style.containerGlobal}>
                    <div className={style.div}>
                        <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                            subtitle={'Você Não Tem Permissão para Editar o Estoque da Loja'} />
                    </div>
                </div>
                :
                <>
                    <div className={style.containerGlobal}>
                        <div className={style.containerCenter}>
                            {!loading ?
                                <>
                                    <div className={style.divList}>
                                        <div className={style.row}>
                                            <TitlePageResponsiveIcon subtitle={'Lista Temporária de ' + label}
                                                path={'É a Lista dos Produtos que Você Deseja ' + label2 + ' do Estoque'} icon={faListUl} />
                                            <div className={style.containerToggleType}>
                                                <ToggleButtonWithContainer
                                                    titleOn={'REPOR PRODUTOS'} titleOff={'REMOVER PRODUTOS'}
                                                    initState={toggleStatus} onChange={handleToggleChange} />
                                            </div>
                                        </div>
                                        <div className={style.buildList}>
                                            <div className={style.build} ref={containerRef} onScroll={checkScroll}>
                                                {(filtered.length === 0) ?
                                                    <GetSmallModels title={"Não há Produtos com Esse Filtro"}
                                                        subtitle={"Tente Trocar o Filtro..."} icon={faCircleInfo} /> :
                                                    filtered.slice(0, limit).map((data, index) => (
                                                        <div key={index}>
                                                            <ProductModelList
                                                                onPlus={() => onValidator(data, 1)}
                                                                onLess={() => onLess(data)}
                                                                onRemove={() => onRemove(data)} data={data} fucntionButton={() => { }} functionImage={
                                                                    () => toggleImageExpansion(data.nameProduct, data.linkImage)} />
                                                        </div>
                                                    ))}
                                            </div>
                                            <div className={style.inputFilter}>
                                                <InputModels iconButton={faMagnifyingGlass} type={"text"}
                                                    placeholder={"Buscar Produto na Lista Acima..."} maxLength={25}
                                                    onChange={handleChangeFilter} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.data}>
                                        <div className={style.div}>
                                            <TitlePageResponsiveIcon subtitle={'Produtos na Lista de ' + label}
                                                path={'É uma Breve Contabilidade da ' + label} icon={faCalculator} />
                                            <div className={style.div}>
                                                <GetSmallModels title={'Modelos na Lista de ' + label} value={calculateProducts.models + ' Modelos'}
                                                    subtitle={'É a Somatória dos Modelos dos Produtos na Lista de ' + label} icon={faNewspaper} />
                                                <GetSmallModels title={'Produtos na Lista de ' + label} value={calculateProducts.items + ' Un'}
                                                    subtitle={'É a Somatória de Todos os Produtos da Lista de ' + label} icon={faSliders} />
                                            </div>
                                        </div>
                                        <div className={style.div}>
                                            <div className={style.inputInserItems}>
                                                <InputModelsBlock legend={'INSIRA O CÓDIGO DE BARRAS'} iconButton={faBarcode} type={"number"}
                                                    value={inputBarcode}
                                                    placeholder={"Código de Barras..."} maxLength={13}
                                                    onChange={handleReadbarcode} />
                                                    <Space top={5}/>
                                                {window.innerWidth<1200? <GetButton title={'LER CÓDIGO DE BARRAS'} handleFunction={()=>setShowReadbarCode(true)} icon={faCamera} color={"#ff0000"}/>:null}
                                            </div>
                                        </div>
                                    </div>
                                </> : <Loader titleFinding={'Estabelecendo Comunicação com o Servidor'}
                                    subtitleFinding={'Aguarde...'} loading={loading} />
                            }
                        </div>
                    </div>
                    {showAlertSave ? <Alert top={100} message={"Estoque Atualizado com Sucesso"} onClose={() => setShowAlertSave(false)} type={"success"} /> : null}
                    {showConfirmSave ? <ConfirmationPopup title={'ATUALIZAR ESTOQUE EM LOTE'} message={'Tem Certeza que Deseja Atualizar o Estoque em Lote'} onCancel={() => setShowConfirmSave(false)} onConfirm={handleSave} /> : null}
                    {isExpanded ? <GetExpandedImage nameProduct={nameProduct} linkImage={linkImage} onClose={toggleImageExpansion} /> : null}
                    {
                        !loading ? <>
                            {
                            window.innerWidth <= 1200 ?
                                <>
                                    <GetFloatingButton width={65} height={65} icon={faKeyboard} color='#007bff' margiRight={"1%"} handleFunction={handleOpenFindWithCode} margiBottom={145}/>
                                    <GetFloatingButton width={65} height={65} icon={faTrashCan} handleFunction={handleClear} color='#ff0000' margiRight={"1%"} margiBottom={75}/>
                                    <GetFloatingButton width={65} height={65} icon={faSave} handleFunction={handleTrySave} color='#007bff' margiRight={'1%'} margiBottom={'1%'} />
                                </>:
                                <>
                                    <GetFloatingButtonWithLegend legend={'BUSCAR POR Código'} width={window.innerWidth <= 1200? '40%' :275} icon={faKeyboard} color='#007bff' margiRight={225} handleFunction={handleOpenFindWithCode} />
                                    <GetFloatingButton icon={faTrashCan} handleFunction={handleClear} color='#ff0000' margiRight={120} />
                                    <GetFloatingButton icon={faSave} handleFunction={handleTrySave} color='#007bff' margiRight={20} />
                                </>
                            }   
                        </> : null
                    }
                    {showReadbarCode ? <Barcode onClose={()=>setShowReadbarCode(false)} onValidate={handlerCameraBarcode}/>: null}
                    {statusFindWithCode ? <FindWithBarcode isBJ={false} onValidator={onValidator} list={[...listItems]} onClose={handleCloseFindWithCode} /> : null}
                </>}
        </div>
    )
}

export default UpdateProducts