import React, { useEffect, useState } from "react"
import TitlePage, { TitlePageResponsiveIcon } from "../../layout/TitlePage"
import { faArrowUpShortWide, faArrowUpWideShort, faFilePdf, faLock, faMoneyBill1, faMoneyBill1Wave, faTag, faTags } from "@fortawesome/free-solid-svg-icons"
import style from "../../style/ActionsStore.module.css"
import { GetButtonIcon } from "../../layout/ButtonsLayout"
import { employeeData } from "../../backEnd/InitController"
import { databaseSettings, listItemsMemory, listProductsMemory, myStoreDataMemory, updateIfNull } from "../../backEnd/Memory"
import { useError, useLoading } from "../../AppContext"
import { GetSmallModels } from "../../Models/GetModels"
import { requestPDFs } from "../../controllers/CloudPdfs"
import GetTag from "../../layout/GetTag"

function GeralPdfStock(){
    const [employeeUser] = useState(employeeData())
    const [canViewBasicData] = useState(employeeUser.employeePermission.permissionsItems[1])
    const { setIsLoading } = useLoading()
    const { showError } = useError()
    const [showGetTag,setshowGetTag] = useState(false)
    const [normal,setType] = useState(true)

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            await updateIfNull(showError)   
            setIsLoading(false)
        }
        fetchData()
      }, [])

    const handlePdfBJSimples = async() =>{

        const temp = listItemsMemory.filter(ss => ss.amount > 0)
        const map = {}
        temp.forEach(ss=>{
            map[ss.itemGlobal.codebar.toString()] = ss.getMapFromCloud()
        })
      
        setIsLoading(true)
        await requestPDFs({
            'type': 'pdfPrecificatorBJSimples',
            'list': map,
            'myStore': myStoreDataMemory.getMapFromCloud()
        },showError)
        setIsLoading(false)
    }

    const handlePdfBJComplete = async() =>{
        const map = {}
        listItemsMemory.forEach(ss=>{
            map[ss.itemGlobal.codebar.toString()] = ss.getMapFromCloud()
        })

        setIsLoading(true)
        await requestPDFs({
            'type': 'pdfPrecificatorBJComplete',
            'list': map,
            'myStore': myStoreDataMemory.getMapFromCloud()
        },showError)
        setIsLoading(false)
    }

    const handlePdfProductSimples = async(isTag,data) =>{
        const temp = listProductsMemory.filter(ss=>ss.amount > 0)
        const map = {}
        temp.forEach(ss=>{
            map[ss.idGenericProducts.toString()] = ss.getMapFromCloud()
        })

        setIsLoading(true)
        await requestPDFs({
            'type': !isTag? 'pdfPrecificatorProductSimples': 'pdfTag',
            'list': map,
            'data':data,
            'myStore': myStoreDataMemory.getMapFromCloud()
        },showError)
        setIsLoading(false)
    }
    
    const handlePdfProductComplete = async(isTag,data) =>{
        const map = {}
        listProductsMemory.forEach(ss=>{
            map[ss.idGenericProducts.toString()] = ss.getMapFromCloud()
        })

        setIsLoading(true)
        await requestPDFs({
            'type': !isTag? 'pdfPrecificatorProductComplete' : 'pdfTag',
            'list': map,
            'data':data,
            'myStore': myStoreDataMemory.getMapFromCloud()
        },showError)
        setIsLoading(false)
    }

    const handleType = (type)=>{
        setType(type)
        setshowGetTag(true)
    }

    
    return(
        <div>
            <TitlePage title={'PDFs'} subtitle={"PDFs Gerais do Estoque"}
            path={"Estoque  >  PDFs  >  PDFs Gerais do Estoque"}/>
            <div className={style.containerGlobal}>
            {canViewBasicData? <>
                {databaseSettings.isCanUseBrunaBJ?
                    <div className={style.container}>
                        <div className={style.itemPDF}>
                            <div className={style.titleMenu}>
                                <TitlePageResponsiveIcon subtitle={'Gerar PDF Para Precificar Estoque Bruna Semijoias'} 
                                children={
                                    <GetButtonIcon 
                                    color='#007bff' icon={faFilePdf} handleFunction={handlePdfBJSimples}/>
                                }
                                path={'É um PDF Apenas das peças com Estoque Maior que Zero...'} icon={faMoneyBill1}/>    
                            </div>
                        </div>
                        <div className={style.itemPDF}>
                            <div className={style.titleMenu}>
                                <TitlePageResponsiveIcon subtitle={'Gerar PDF Para Precificar Estoque Completo Bruna Semijoias'} 
                            children={
                                <GetButtonIcon 
                                    color='#007bff' icon={faFilePdf} handleFunction={handlePdfBJComplete}/>
                            }
                                path={'É um PDF Completo com Todas as Peças...'} icon={faMoneyBill1Wave}/>    
                            </div>
                        </div>
                    </div>
                :null}
                <div className={style.container}>
                    <div className={style.itemPDF}>
                        <div className={style.titleMenu}>
                            <TitlePageResponsiveIcon subtitle={'Gerar PDF Para Precificar Estoque de Produtos Genéricos'} 
                            children={
                                <GetButtonIcon 
                                color='#007bff' icon={faFilePdf} handleFunction={()=>handlePdfProductSimples(false)}/>
                            }
                            path={'É um PDF Apenas dos Produtos com Estoque Maior que Zero...'} icon={faArrowUpWideShort}/>     
                        </div>
                    </div>
                    <div className={style.itemPDF}>
                        <div className={style.titleMenu}>
                            <TitlePageResponsiveIcon subtitle={'Gerar PDF Completo de Precificação dos Produtos Genéricos'} 
                            children={
                                <GetButtonIcon 
                                color='#007bff' icon={faFilePdf} handleFunction={()=>handlePdfProductComplete(false)}/>
                            }
                            path={'É um PDF Completo com Todos os Produtos'} icon={faArrowUpShortWide}/>                               
                        </div>
                    </div>
                </div>
                <div className={style.container}>
                    <div className={style.itemPDF}>
                        <div className={style.titleMenu}>
                            <TitlePageResponsiveIcon subtitle={'Gerar Etiquetas Para Produtos com Estoque'} 
                            children={
                                <GetButtonIcon 
                                color='#007bff' icon={faFilePdf} handleFunction={()=>handleType(true)}/>
                            }
                            path={'Apenas dos Produtos com Estoque Maior que Zero...'} icon={faTag}/>     
                        </div>
                    </div>
                    <div className={style.itemPDF}>
                        <div className={style.titleMenu}>
                            <TitlePageResponsiveIcon subtitle={'Gerar Etiquetas Para Todos os Produtos'} 
                            children={
                                <GetButtonIcon 
                                color='#007bff' icon={faFilePdf} handleFunction={()=>handleType(false)}/>
                            }
                            path={'Completo com Todos os Produtos'} icon={faTags}/>                               
                        </div>
                    </div>
                </div>
                </>:<GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar as Iformações dos Produtos/Peças'} />}
            </div>
            {showGetTag? <GetTag onClick={normal? handlePdfProductSimples : handlePdfProductComplete} onClose={()=>setshowGetTag(false)}/>:null}
        </div>
    )
}

export default GeralPdfStock