import React, { useState } from "react";
import style from "../style/EmployeeModel.module.css"


function EmployeeModel({data,fucntionButton,functionImage}) {

    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    
    const handleImageLoad = () => {
        setImageLoaded(true); 
    }


    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }

    return(
            <div className={data.isMaster ? style.container + ' ' + style.master : style.container}>
                <div className={style.imageContainer}>
                    {errorOccurred ? (
                        <img
                        src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                        alt="Erro de carregamento"
                        className={style.loaded}
                        />) :
                        (
                        <img src={data.linkImage} alt={data.genericPerson.name} onLoad={handleImageLoad}
                        onError={handleImageError} onClick={functionImage}
                        className={!imageLoaded ? style.loading : style.loaded}/> )}
                </div> 
                <div onClick={data.isMaster? null : fucntionButton} className={style.containerNames}>
                    <h3>{data.genericPerson.name}</h3>
                    {window.innerWidth <= 1200? null:<h3>{data.isMaster? "MASTER" : "NORMAL"}</h3>}
                    <h5>{data.status ? "ATIVO":"DESATIVO"}</h5>
                </div>
            </div>
    )
}

export default EmployeeModel