import React from 'react';
import style from "../../../style/ListClients.module.css";
import Loader from '../../../layout/Loader';

export function LoaderCenter() {
  return (
    <div className={style.containerGeneral}>
      <div className={style.containerGlobal}>
        <Loader titleFinding={'Buscando no Servidor!'}
          subtitleFinding={'Aguarde...'} loading={true} />
      </div>
    </div>
  );
}
