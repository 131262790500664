import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { NavBarGlobal, ObjectNavbarMenu } from "../NavbarGlobal.js";
import { errorRouter, routerFromClientsGlobal, routerFromListClients, routerFromaddClients } from "../../routers/routerList.js";
import ListClients from "./ListClients.js";
import style from "../../style/Controller.module.css"
import AddClient from "./AddClient.js";
import {faUsers,faList,faUserPlus} from "@fortawesome/free-solid-svg-icons";


function ControllerClients({isLoggedIn}){
    const location = useLocation();

    const menu1 = new ObjectNavbarMenu('Menu de Clientes',faUsers)
    menu1.addMenu('Lista de Clientes',faList,routerFromListClients)
    menu1.addMenu('Cadastrar Nova Cliente',faUserPlus,routerFromaddClients)
    
    const listRoutesToNav = [menu1]

    return isLoggedIn ? (
        <div className={style.containerGlobal}>
            <NavBarGlobal listObjectNavBarMenu={listRoutesToNav}/>
            <div className={style.containerData}>
            {(() => {
                switch(location.pathname){
                    case routerFromaddClients: return <AddClient/>

                    case routerFromListClients: return <ListClients/>
                    case routerFromClientsGlobal: return <ListClients/>
                    default: return <Navigate to={errorRouter} replace />
                }
            })()}
            </div>
        </div>
    ) : (
        <Navigate to="/login" replace />
    )
}

export default ControllerClients