import React, { useState }  from "react"
import { TitlePageResponsiveIcon } from "../layout/TitlePage"
import { faCheck, faCheckDouble, faClose, faLock, faUnlockKeyhole } from "@fortawesome/free-solid-svg-icons"
import { GetButton, GetButtonIcon } from "../layout/ButtonsLayout"
import style from '../style/GetPassWord.module.css'
import Alert from '../Models/Alert.js';
import Space from "../layout/Space.js"
import InputModelsBlock from "../layout/InputModelsBlock.js"

const getFormData = () => {
    return {
        password:'',
        confirmationPassword:''
    }
}

const getFormNeed = () => {
    return {
        password:false,
        confirmationPassword:false
    }
}

function GetChangePasswordEmployee({handleCheck, handleClose,isMaster}){
    const [isVisible,setAlert] = useState(false)
    const [valueAlert,setValueAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData)
    const [needData, setNeedData] = useState(getFormNeed)
    
    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmitSave= async() => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            forceNoticeError("Complete Todos os Campos em Vermelho")
            return
        }

        if(formData.password !== formData.confirmationPassword){
            setNeedData(prevState => ({ ...prevState, 'password': true }))
            setNeedData(prevState => ({ ...prevState, 'confirmationPassword': true }))
            forceNoticeError("Nova Senha Não é Igual a Senha de Confirmação")
            return
        }

        handleCheck(formData)
        handleClose()

    }

    const forceNoticeError =(message) => {
        setValueAlert(message)
        setAlert(true)
    }

    return(
        <div className={style.overlay}>
        <div className={style.alert}>
            <div className={style.title}>
                    <TitlePageResponsiveIcon title={"TROCAR SENHA DO FUNCIONÁRIO"} 
                    children={
                        <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                    }
                    path={"Complete o Campo Abaixo com a Senha Correta"} icon={faLock}/>  
            </div>
            <div className={style.div}>
                    <InputModelsBlock
                    legend={'NOVA SENHA'}
                     onChange={handleChange} validation={needData.password} maxLength={50}
                    value={formData.password}
                    name={'password'} iconButton={faUnlockKeyhole} type={"password"} placeholder={"Nova Senha do Usúario..."}/>
                    <Space top={5}/>
                    <InputModelsBlock
                    legend={'CONFIRMAÇÃO DA NOVA SENHA'}
                     onChange={handleChange} validation={needData.confirmationPassword} maxLength={50}
                    value={formData.confirmationPassword}
                    name={'confirmationPassword'} iconButton={faCheckDouble} type={"password"} placeholder={"Confirmação da Nova Senha..."}/>
            </div>
            <Space top={5}/>
            <div className={style.divButton}>
                <GetButton title={'TROCAR SENHA AGORA'} color='#007bff' icon={faCheck} handleFunction={handleSubmitSave}/> 
            </div>
           {isVisible? <Alert message={valueAlert} time={1000} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
      </div>
    )
}

export default GetChangePasswordEmployee