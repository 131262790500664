import React from "react";
import { TitlePageResponsiveIcon } from "../../layout/TitlePage";
import style from '../../style/EditeSale.module.css'
import { GetButtonIcon } from "../../layout/ButtonsLayout";
import { faClose, faEdit } from "@fortawesome/free-solid-svg-icons";
import SaleGeneric from "./SaleGeneric";
import { auxSale } from "../../backEnd/Memory";

export function EditeSale({onClose , saleEdite}){
    
    const handleClose = () =>{
        auxSale.resetAuxSale()
        onClose()
    }

    return(
        <div className={style.overlay}>
            <div className={style.alert}>
                <div className={style.title}>
                    <TitlePageResponsiveIcon subtitle={"EDITAR VENDA"} 
                    path={"COMPLETE COM CUIDADO ESSA OPERAÇÃO"} icon={faEdit}/>  
                    <div className={style.buttonClose}>
                        <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                    </div>
                </div>
                <div className={style.div}>
                    <SaleGeneric saleEdite={saleEdite} isEdite={true}/>
                </div>
            </div>
      </div>
    )
}