import { employeeData } from "../../backEnd/InitController"

class Payment {
    constructor(){
        this.idListOfPayment =0
        this.idEmployee = 0
        this.idPaymentController = 0
        this.description = ''
        this.value = 0.0
        this.date = ''
    }

    getValuePayment(){
        return  this.value
    }

    getValueToString(){
        return parseFloat(this.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    getDate(){
        return this.date.replace("T",' ').slice(0, 19)
    }

    fromNew(value,description,date){
        this.idEmployee = employeeData().idEmployee
        this.value = value
        this.description = description
        this.date = date
    }

    fromInCash(value,description){
        this.idEmployee = employeeData().idEmployee
        this.value = value
        this.description = description
        this.date = new Date().toISOString().replace('T', ' ').slice(0, 19)
    }

    fromCloud(data){
        this.idListOfPayment = data.idListOfPayment
        this.idEmployee = data.idEmployee
        this.idPaymentController = data.idPaymentController
        this.description = data.description
        this.value = parseFloat(data.value)
        this.date = data.date
    }

    getMapFromCloud(){
        return{
        'idListOfPayment':this.idListOfPayment,
        'idEmployee':this.idEmployee,
        'idPaymentController':this.idPaymentController,
        'description':this.description,
        'value': this.value,
        'date': this.date.slice(0,10),
        //'date': getFormatDateTimeWithSeconds(date).toString(),
        }
    }
}

export default Payment