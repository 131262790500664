import React,{useEffect, useRef, useState}from "react";
import style from "../style/FindWithBarcode.module.css"
import { faCircleInfo, faClose, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { GetSmallModels } from "../Models/GetModels";
import { 
  TitlePageResponsiveIcon } from "../layout/TitlePage";
import GetAmountList from "../Models/GetAmountList";
import ItemModelInsert from "../Models/ItemModelInsert";
import { GetButtonIcon } from "../layout/ButtonsLayout";
import InputModelsBlock from "../layout/InputModelsBlock";
import Space from "../layout/Space";
import GetExpandedImage from "../layout/GetExpandedImage";
import Alert from "../Models/Alert";
import ProductInsertModel from "../Models/ProductInsertModel";

function FindWithBarcode({list, onValidator,onClose,isBJ,showValueSale}){
    const containerRef = useRef(null)
    const [limit, setLimit] = useState([50])
    const [filtered, setFiltered] = useState([])
    
    const [formData, setFormData] = useState({
      amount: '1',
    })

    const [needData, setNeedData] = useState({
      amount: false,    
    })

    const [showAlertInsert, setShowAlertInsert] = useState(false)
    const [showAlertError, setShowAlertError] = useState(false)

    const [isExpanded, setIsExpanded] = useState(false)
    const [nameProduct, setNameProduct] = useState('')
    const [linkImage, setSetLinkImage] = useState('')
  
    const toggleImageExpansion = (nameProduct,linkImage) => {
        setIsExpanded(!isExpanded)
        setNameProduct(nameProduct)
        setSetLinkImage(linkImage)
    }

    useEffect(() =>{
      handleChangeFilter()
    },[])

    const handleClose = () => {
        onClose()
    } 

    const handleChangeFilter = (value) => {
        const data = [...list]
        resetScroll()
        if(value != null){
          const filteredStock = data.filter(
            stock => {
              let filternName
              if(isBJ)
                 filternName = stock.itemGlobal.name.toLowerCase().includes(value.target.value.toLowerCase())
              else
                 filternName = stock.nameProduct.toLowerCase().includes(value.target.value.toLowerCase())

              return filternName
            }
          )
          setFiltered(filteredStock)
        }else{
          setFiltered(data)
        }
    }

    const resetScroll =()=>{
        const container = containerRef.current
        container.scrollTop = 0
        setLimit(50)
    }
    
    const checkScroll= ()=> {
        const container = containerRef.current;
        const scrollPosition = container.scrollTop;
        const containerHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;
    
        if (scrollPosition + containerHeight >= scrollHeight) {   
          setLimit((limit + 50))
        }
    }

    const handleChange = (e) => {
      if(e !== ''){
          const { name, value } = e.target;
          setFormData({ ...formData, [name]: value })
      }
    }

    const handleInsert = (item) => {
      if(formData.amount === '' || formData.amount === '0'){
        setNeedData({...needData,'amount': true})
        setShowAlertError(true)
        return
      }

      if(onValidator(item,parseInt(formData.amount))){
        setShowAlertInsert(true)
      }
    }
  
    var labelNamelist = isBJ? 'Bruna Semijoias': 'Produtos Genéricos'
    var typeInput = isBJ? 'number' : 'text'

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
            <div className={style.divList}>
            <TitlePageResponsiveIcon title={'Buscar Produto'} subtitle={labelNamelist} 
              children={
                <GetButtonIcon handleFunction={handleClose} icon={faClose} color='red'/>
              }
              path={'Buscar > Por Nome'}/>
              <div className={style.buildList}>
                <div className={style.divRow}>
                  <InputModelsBlock legend={'Nome do Produto que Deseja Buscar'}
                  iconButton={faMagnifyingGlass} type={typeInput}
                  maxLength={25} 
                  placeholder={"Nome do Produto..."} onChange={handleChangeFilter}/>
                  <Space right={2} left={2} top={2} bottom={2}/>
                  <InputModelsBlock legend={'Quantidade que Deseja Adcionar na Lista'}
                  iconButton={faMagnifyingGlass} type={"number"} 
                  maxLength={5}
                  validation={needData.amount} name={'amount'} 
                  placeholder={"Quantidade..."} value={formData.amount} onChange={handleChange}/>
                </div>
                <Space right={5} left={5} top={5} bottom={5}/>
                {(filtered.length > 0) ? (<div className={style.buildList}>
                 <GetAmountList amount={filtered.length} title={"Produtos Totais Encontrados na Busca..."}/>
                </div>): null}
                <Space right={5} left={5} top={5} bottom={5}/>
                <div className={style.build} ref={containerRef} onScroll={checkScroll}>
                  {(filtered.length === 0)?
                  <GetSmallModels title={"Não há Produtos com Esse Filtro"}
                  subtitle={"Tente Trocar o Filtro..."} icon={faCircleInfo} />:
                  filtered.slice(0, limit).map((data, index) => (
                      <div key={index}>
                        {
                          isBJ? <ItemModelInsert showValueSale={showValueSale} data={data} onInsert={()=>handleInsert(data)} functionImage={
                          ()=>toggleImageExpansion(data.itemGlobal.name,data.getImage())} /> : 
                          <ProductInsertModel showValueSale={showValueSale} data={data} onInsert={()=>handleInsert(data)} functionImage={
                          ()=>toggleImageExpansion(data.nameProduct,data.linkImage)} />
                        }
                      </div>))}
                </div>   
              </div>
            </div>
        </div>
        {showAlertInsert? <Alert time={500} top={50} right={25}  message={'Produto Adicionado com sucesso.'} 
        onClose={() => setShowAlertInsert(false)} type={"success"} />:null}
        {showAlertError? <Alert top={25} right={25}  message={'Complete os Campos em Vermelho'} 
        onClose={() => setShowAlertError(false)} type={"error"} />:null}
        {isExpanded? <GetExpandedImage nameProduct={nameProduct} linkImage={linkImage} onClose={toggleImageExpansion}/>:null}
      </div>
    )
}
export default FindWithBarcode