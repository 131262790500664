import { initSystem, listClientsMemory, listEmployeeMemory, listItemsMemory, listProductsMemory, myStoreDataMemory } from "../../backEnd/Memory"
import { getAllRepairs } from "../../controllers/CloudRepairs"
import { getAllSalesOpen, getSalesInPeriod, getSalesWithPaymentInPeriod } from "../../controllers/CloudSales"
import PaidSale from "../Moth/PaidSale"
import AccountingAllCommission from "./AccountingAllCommission"
import AccountingAllItemsSale from "./AccountingAllItemsSale"
import AccountingAllReceiversThisMoth from "./AccountingAllReceiversThisMoth"
import AccountingAllRepairs from "./AccountingAllRepairs"
import AccountingAllSalesReceiver from "./AccountingAllSalesReceiver"
import AccountingCommissions from "./AccountingCommissions"
import AccountingControllerAllSalesReceiver from "./AccountingControllerAllSalesReceiver"
import AccountingDataDetails from "./AccountingDataDetails"
import AccountingDataSales from "./AccountingDataSales"
import OtherFactors from "./OtherFactors"
import SaleInClient from "./SaleInClient"

class MakeAccounting {
    constructor() {
        this.isCalculated = false
        this.valorCompany =  0
        this.accountingAllItemsSale = new AccountingAllItemsSale()
        this.accountingDataSales = new AccountingDataSales()
        this.accountingDataDetails = new AccountingDataDetails()
        this.accountingAllCommissions = new AccountingAllCommission()

        this.salesInPeriod = []
        this.otherFactorsReal = []
        this.accountingAllRepairs = new AccountingAllRepairs()
        this.accountingAllReceiversThisMoth = new AccountingAllReceiversThisMoth()
        this.accountingControllerAllSalesReceiver = new AccountingControllerAllSalesReceiver()
        this.daysCount = ''

        this.allSales = [] // SaleInClient
        this.allDevolutions = [] //SaleInClient

        this.dateStartFind = new Date()
        this.dateFinalFind = new Date()
    }

    getAllValueReceived(){
        return ( this.accountingAllRepairs.valueReceived + this.accountingAllReceiversThisMoth.valueTotalPaid).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
    }

    getAllValueFutureReceiver(){
        return ( this.accountingAllRepairs.valueAllReceiverInFuture + this.accountingControllerAllSalesReceiver.valueAllReceiverInFuture).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
    }

    getDateStart(){
        return this.dateStartFind.toLocaleDateString()
    }

    getDateFinish(){
        return this.dateFinalFind.toLocaleDateString()
    }

    getDaysCount(dateStart, dateFinal) {
        this._resetValues()
        this.dateStartFind = dateStart
        this.dateFinalFind = dateFinal
        const differenceInMilliseconds = dateFinal - dateStart
        const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24))
        this.daysCount = differenceInDays.toString()
    }
  
    async getTotalSaleInPeriod(dateStart, dateFinal,showError) {
        dateFinal.setDate(dateFinal.getDate() + 1)
        await initSystem(showError)
        this.valorCompany = myStoreDataMemory.purchasingFactor
        this.getDaysCount(dateStart, dateFinal,showError)
        await this._getUpdateAllValues(dateStart, dateFinal,showError)
        this.isCalculated = true
    }
  
    async _findInEveryClientsSalesOpen(showError,genericProducts,listItemsBj,employeeList,clientsList) {
        const tempClients = clientsList.filter(ss => ss.isOpenSales)
        const allSalesOpen = await getAllSalesOpen(showError,genericProducts,listItemsBj,employeeList,clientsList)

        for (const element of tempClients) {
            const tempSales = []
            for (const e of allSalesOpen) {
                if (e.thisOwner.IdClient === element.IdClient) {
                    if (e.detailsSale.isOpen) {
                        tempSales.push(e)
                    }
                }
            }
            let temp = new AccountingAllSalesReceiver()
            temp.fromInit(element, tempSales)
            temp.calculate()
            this.accountingControllerAllSalesReceiver.accountingAllSalesReceiver.push(temp)
        }

        this.accountingControllerAllSalesReceiver.calculate()
    }
  
    async updateSalesPaid(dateStart, dateFinal,showError,genericProducts,listItemsBj,employeeList,clientsList) {
      const withPayment = await getSalesWithPaymentInPeriod(dateStart,dateFinal,
      showError,genericProducts,listItemsBj,employeeList,clientsList)

      const listTemp = []
      for (const element of withPayment) {
        for (const e of element.controllerPayment.paymentList) {
          if(e.date >= dateStart.toISOString() && e.date <= dateFinal.toISOString()){
            listTemp.push(new PaidSale(element.getCodeSale(), e.idListOfPayment.toString(), e.date, e.value,e.description));    
          }
        }
      }


      listTemp.sort((a, b) => a.dataPaid.localeCompare(b.dataPaid))
      let temp = new AccountingAllReceiversThisMoth()
      temp.fromInit(listTemp)
      this.accountingAllReceiversThisMoth = temp
    }
  
    async _getUpdateAllValues(dateStart, dateFinal,showError) {
        const otherFactors = {}
        this._clearData()
        this.accountingAllRepairs.accountingRepairs= await getAllRepairs(dateStart,showError)
        this.accountingAllRepairs.calculate()
        this.salesInPeriod = await getSalesInPeriod(dateStart,dateFinal,showError,listProductsMemory,listItemsMemory,listEmployeeMemory,listClientsMemory)
      

        await this._findInEveryClientsSalesOpen(showError,listProductsMemory,listItemsMemory,listEmployeeMemory,listClientsMemory)
        await this.updateSalesPaid(dateStart, dateFinal,showError,listProductsMemory,listItemsMemory,listEmployeeMemory,listClientsMemory)
    
        listEmployeeMemory.forEach(element => {
            var temp = new AccountingCommissions()
            temp.fromOnlyPerson(element)
            this.accountingAllCommissions.accountingCommissions[element.idEmployee.toString()] = temp
        })
  
        for (const element of this.salesInPeriod) {
            element.calculateFromCloud()
            if (!element.cancelSale.isCancel) {
                this.accountingAllItemsSale.insertValuesInMapsFromSales(element.itemsThisSale.listItems, element.listGenericProduct.genericProducts)
                this.accountingDataSales.insertValuesFromSale(element)
        
                this.allSales.push(new SaleInClient(element.thisOwner._genericPerson.name, element))
                this.accountingDataDetails.totalSalesInPeriod++
                this.accountingDataDetails.allValue += element.totalValueIncludeAll === 0 ? element.totalValue : element.totalValueIncludeAll
                this.accountingDataDetails.allExtra += element.extraValue
        
                this.accountingDataDetails.valueFactorCompany += this.valorCompany * element.totalFactorItems
        
                const name = element.detailsSale.factorCompany.toString()
                
                if (otherFactors.hasOwnProperty(name)) {
                    otherFactors[name].addNewSale(element)
                } else {
                    otherFactors[name] = new OtherFactors(element.detailsSale.factorCompany)
                    otherFactors[name].addNewSale(element)
                }
        
                if (element.detailsSale.isPresent) {
                    this.accountingDataDetails.valueIsPresent += element.totalValueIncludeAll
                }
        
                if (element.saleDiscount.isHasDiscount) {
                    this.accountingDataDetails.allDiscount += element.saleDiscount.valueDiscountLiquid
                }
        
                if (!element.detailsSale.isPresent) {
                    const stringPartCode = element.detailsSale.idEmployee.toString()
                    this.accountingAllCommissions.accountingCommissions[stringPartCode].setAddNewSale(element)
                    this.accountingAllCommissions.accountingCommissions[stringPartCode].calculate()
                }

                } else {
                this.accountingDataDetails.valueTotalCancel += element.totalValueIncludeAll === 0 ? element.totalValue : element.totalValueIncludeAll
                this.allDevolutions.push(new SaleInClient(element.thisOwner._genericPerson.name, element))
                this.accountingDataSales.insertValuesCancelAllDevolutionFromSale(element)
            }
        }
  
        // this.accountingDataDetails.valueRealCoastHelp = this.accountingDataDetails.totalSaleMoneyInPeriod - this.accountingDataDetails.valueFactorCompany;
        this.accountingDataDetails.valueRealCoastHelpWithLiquid = (this.accountingDataDetails.valueRealCoastHelp - this.accountingDataDetails.allDiscount) - this.accountingDataDetails.valueIsPresent
        this.accountingAllCommissions.calculate()
        this.accountingAllItemsSale.updateValues()
        Object.values(otherFactors).forEach(value => {
            this.otherFactorsReal.push(value)
        })

      return true
    }
  
    _resetValues() {
      this.accountingDataDetails.clearData()
      this.accountingDataSales.clearData()
      this.isLoading = true
      this.daysCount = ''
      this.otherFactorsReal = []
    }
  
    _clearData() {
      this.accountingAllRepairs.clear()
      this.accountingAllReceiversThisMoth.clear()
      this.salesInPeriod = []
      this.allSales = []
      this.accountingAllCommissions.clear()
      this.allDevolutions = []
      this.accountingAllItemsSale.clearData()
      this.accountingControllerAllSalesReceiver.accountingAllSalesReceiver = []
    }
}

export const makeAccounting = new MakeAccounting()
  
export default MakeAccounting;
  