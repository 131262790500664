import React, { useState, useEffect, useRef } from 'react';
import style from "../../style/ListClients.module.css"
import { GetSmallModels } from '../../Models/GetModels.js';
import { faMagnifyingGlass,faCircleInfo, faList, faLock} from '@fortawesome/free-solid-svg-icons';
import InputModels from "../../Models/InputModels.js"
import GetAmountList from '../../Models/GetAmountList.js';
import Space from '../../layout/Space.js';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage.js';
import SaleModel from '../../Models/SaleModel.js';
import GetExpandedImage from '../../layout/GetExpandedImage.js';
import ViewSaleController from '../Sale/ShowSale/ViewSaleController.js';
import { makeAccounting } from '../../objects/Accounting/MakeAccounting.js';
import GetNeedUpdate from './GetNeedUpdate.js';
import { employeeData } from '../../backEnd/InitController.js';

function ListOfAllSales() {

  const [isExpanded, setIsExpanded] = useState(false)
  const [name, setNameProduct] = useState('')
  const [linkImage, setSetLinkImage] = useState('')
  const [CanView] = useState(employeeData().employeePermission.permissionsContabilite[2])
  const toggleImageExpansion = (name,linkImage) => {
      setIsExpanded(!isExpanded)
      setNameProduct(name)
      setSetLinkImage(linkImage)
  }

  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])

  const [filtered, setFiltered] = useState(makeAccounting.salesInPeriod)
  const [filter, setFilter] = useState('all')
  const [orderBy, setOrderBy] = useState('desc')

  const [showViewSale, setShowViewSale] = useState(false)
  const [saleSelected, setSaleSelected] = useState(null)

  const handleOpenViewSale = (sale) =>{
    setSaleSelected(sale)
    setShowViewSale(true)
  }

  useEffect(() => {
    handleChangeFilter()
  }, [filter, orderBy])

  const controllerFilter = () => {
    let data = []
    switch (filter) {
      case 'all':
        data = makeAccounting.salesInPeriod
        break
      case 'owing':
        data = makeAccounting.salesInPeriod.filter(item => item.detailsSale.isOpen)
        break
      case 'paidOff':
        data = makeAccounting.salesInPeriod.filter(item => !item.detailsSale.isOpen)
        break
      case 'withDiscount':
        data = makeAccounting.salesInPeriod.filter(item => item.saleDiscount.isHasDiscount)
        break
      case 'cancel':
        data = makeAccounting.salesInPeriod.filter(item => item.cancelSale.isCancel)
        break
      default:
        data = makeAccounting.salesInPeriod
        break
    }

    let sorted = [...data]
    switch (orderBy) {
      case 'asc':
        sorted.sort((a, b) => a.detailsSale.date.localeCompare(b.detailsSale.date))
        break
      case 'desc':
        sorted.sort((a, b) => b.detailsSale.date.localeCompare(a.detailsSale.date))
        break
      case 'value':
        sorted.sort((a, b) => b.totalValueIncludeAll.toString().localeCompare(a.totalValueIncludeAll))
        break
      case 'seller':
        sorted.sort((a, b) => b.detailsSale.idEmployee.toString().localeCompare(a.detailsSale.idEmployee))
        break
      case 'factor':
        sorted.sort((a, b) => a.detailsSale.factorCompany.toString().localeCompare(b.detailsSale.factorCompany))
        break
      case 'amount':
        sorted.sort((a, b) => b.totalAmountItems.toString().localeCompare(a.totalAmountItems))
        break
      default:
        break
    }
    return sorted;
  }

  const handleFilterChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }
  
  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()

    resetScroll()
    if(value!= null){
      const filtered = data.filter(
        sale => {
          const filterValue = sale.totalValueIncludeAll.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterData= sale.detailsSale.date.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterEmployee = sale.detailsSale.idEmployee.toString().toLowerCase().includes(value.target.value.toLowerCase())
          return filterValue || filterData || filterEmployee 
        }
      )
      setFiltered(filtered)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    if(container){
      container.scrollTop = 0
      setLimit(50)
    }
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  return (
    <div className={style.containerGeneral}>
       <div className={style.containerGlobal}>  
           {CanView? <>{makeAccounting.isCalculated === true ? 
           <>
              <div className={style.titleMenu}>     
                  {window.innerWidth > 1200?<TitlePageResponsiveIcon subtitle={'Lista de Todas as Venda No Período'}  
                  path={'São Todas as Venda Entre a Data Inicial e Final da Contabilidade...'} icon={faList}/>:null} 
                  { window.innerWidth > 1200?
                    <>
                      <Space left={5}/>
                      <div className={style.buidWithText}>
                          <GetSmallModels title={'SÃO AS VENDAS REALIZADAS DO DIA:'} subtitle={'ATÉ: ' + makeAccounting.getDateFinish() + ' | TOTAL DE DIAS: ' + makeAccounting.daysCount} value={makeAccounting.getDateStart()}/>   
                      </div>
                    </>:null
                  }
              </div>
              { window.innerWidth <= 1200?
                <div className={style.buidWithText}>
                  <GetSmallModels title={'SÃO AS VENDAS REALIZADAS DO DIA:'} subtitle={'ATÉ: ' + makeAccounting.getDateFinish() + ' | TOTAL DE DIAS: ' + makeAccounting.daysCount} value={makeAccounting.getDateStart()}/>   
                </div>:null
              }
              {window.innerWidth <= 1200? <Space top={5}/>:null}
              <div className={style.buildList}>
              <div className={style.filter}>
                  <div className={style.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} 
                    type={"text"} placeholder={"Buscar Venda..."}
                    onChange={handleChangeFilter}/>
                  </div>
                  <div className={style.containerDropDown}>
                    <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                      <option value="all">TODOS</option>
                      <option value="owing">DEVENDO</option>
                      <option value="paidOff">QUITADA</option>
                      <option value="withDiscount">COM DESCONTO</option>
                      <option value="cancel">CACELADAS</option>
                    </select>
                    <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                        <option value="asc">CRESCENTE</option>
                        <option value="desc">DESCRESCENTE</option>
                        <option value="value">VALOR</option>
                        <option value="seller">VENDEDOR</option>
                        <option value="factor">FATOR DE VENDA</option>
                        <option value="amount">QUANTIDADE DE PEÇAS</option>
                    </select>
                  </div>
              </div>
              <Space right={2} left={2} top={5} bottom={0}/> 
              {(filtered.length > 0) ?<div className={style.buildList}>
                  <GetAmountList amount={filtered.length} title={"Vendas Totais Encontradas na Busca..."}/>
                  <div className={style.labelData}>
                    <h5>Valor Final</h5>
                    <h5>Data</h5>
                    <h5>Tipo</h5>
                    <h5>Cliente</h5>
                    <h5>Status</h5>
                  </div>
                <Space right={0} left={0} top={0} bottom={10}/> 
              </div> : null}
              <div ref={containerRef} className={style.containerList} onScroll={checkScroll}>
                  <div>
                    {(filtered.length === 0)?
                    <GetSmallModels title={"Não há Vendas com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo} />:
                      filtered.slice(0, limit).map((sale, index) => (
                          <div key={index}>
                            <SaleModel sale={sale} onClick={()=>handleOpenViewSale(sale)}
                            functionImage={()=>toggleImageExpansion(sale.tempEmployee.genericPerson.name,
                              sale.tempEmployee.linkImage)} />
                          </div>
                      ))
                    }
                  </div>
              </div>
              </div>
            </> :
            <div className={style.divSapce}> 
              <GetNeedUpdate/>
            </div> }</>:
               <div className={style.divSapce}>
               <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar a Lista de Todas as Vendas'} />
               </div>}
        {showViewSale ? <ViewSaleController handleSave={()=>{}} saleStart={saleSelected} handleClose={()=> setShowViewSale(false)}/> : null}
        {isExpanded? <GetExpandedImage nameProduct={name} linkImage={linkImage} onClose={toggleImageExpansion}/>:null}
       </div>
    </div>
   )

}

export default ListOfAllSales