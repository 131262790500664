import { sendRequestGetLocal, sendRequestPostLocal } from "../backEnd/requesition"
import { NfeEmited } from "../objects/NFe/NfeEmited"

const _routerFromGetNFeInCloud = '/nfe/getNfeEmitedInCloud'
const _routerFromGetNFeWithIdInCloud = '/nfe/getNfeEmitedWithIdInCloud'

export const getNfeEmited = async (showError) => {
    var results = []
    try {
        const returnClound = await sendRequestGetLocal(_routerFromGetNFeInCloud)
        if(Array.isArray(returnClound)){
            Object.values(returnClound).forEach(data =>{   
                var nfeTemp = new NfeEmited()
                nfeTemp.fromCloud(data)
                results.push(nfeTemp)
            })
        }else throw new Error(results.response.data.sqlMessage) 
    } catch (error) {
        showError('ERROR AO BUSCAR LISTA DE NFe/NFC-e',error.message) 
    }finally{
        return results
    }
}

export const getNfeEmitedWithId = async (idNfe,showError) => {
    var result = null
    try {

        var body = {'idNfe' : idNfe}
        const returnClound = await sendRequestPostLocal(_routerFromGetNFeWithIdInCloud,body)
        if(Array.isArray(returnClound.data)){
            Object.values(returnClound.data).forEach(data =>{   
                result = new NfeEmited()
                result.fromCloud(data)
            })
        }else throw new Error(result.response.data.sqlMessage) 
    } catch (error) {
        showError('ERROR AO BUSCAR NFe/NFC-e DA VENDA',error.message) 
    }finally{
        return result
    }
}