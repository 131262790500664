import React, { useState, useEffect, useRef } from 'react';
import style from "../../../style/ListClients.module.css"
import { GetSmallModels } from '../../../Models/GetModels.js';
import { faMagnifyingGlass,faCircleInfo, faDownload, faList, faCartArrowDown, faFilePdf, faCalculator} from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../layout/Loader.js';
import InputModels from "../../../Models/InputModels.js"
import GetAmountList from '../../../Models/GetAmountList.js';
import Space from '../../../layout/Space.js';
import {  GetButtonIcon } from '../../../layout/ButtonsLayout.js';
import { TitlePageResponsiveIcon } from '../../../layout/TitlePage.js';
import { routerFromSale } from '../../../routers/routerList.js';
import { useNavigate } from 'react-router-dom';
import { getListSalesFromClient } from '../../../controllers/CloudSales.js';

import SaleModel, { SaleModelHelperName } from '../../../Models/SaleModel.js';
import GetExpandedImage from '../../../layout/GetExpandedImage.js';
import ViewSaleController from '../../Sale/ShowSale/ViewSaleController.js';
import { useError, useLoading } from '../../../AppContext.js';
import GetFloatingButton, { GetFloatingButtonWithLegend } from '../../../layout/GetFloatingButton.js';
import { updateIfNull } from '../../../backEnd/Memory.js';
import AccountingAllSalesReceiver from '../../../objects/Accounting/AccountingAllSalesReceiver.js';

function ListOfSalesClient({client}) {
  const navigate = useNavigate()

  const [countSalesOpen,setCountSalesOpen] = useState(new AccountingAllSalesReceiver())
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 180)
    return currentDate.toISOString().slice(0,10)
  })

  const [isExpanded, setIsExpanded] = useState(false)
  const [name, setNameProduct] = useState('')
  const [linkImage, setSetLinkImage] = useState('')
  const toggleImageExpansion = (name,linkImage) => {
      setIsExpanded(!isExpanded)
      setNameProduct(name)
      setSetLinkImage(linkImage)
  }

  const {setIsLoading}  = useLoading()
  
  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])

  const [filtered, setFiltered] = useState([])
  const [filter, setFilter] = useState('all')
  const [orderBy, setOrderBy] = useState('desc')

  const [showViewSale, setShowViewSale] = useState(false)
  const [saleSelected, setSaleSelected] = useState(null)

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const {showError} = useError()

  useEffect(() => {
      async function fetchData() {
          setList([])
          setLoading(true)
          setIsLoading(true)
          await updateIfNull(showError) 
          await handleUpdateValues()    
          setIsLoading(false)  
      }
      fetchData()
  }, [])

  const handleOpenViewSale = (sale) =>{
    setSaleSelected(sale)
    setShowViewSale(true)
  }

  const handleUpdateValues = async() => {
    setLoading(true)
    setList([])
    var value = await getListSalesFromClient(client,selectedDate,showError)
    setList(value)
    calculateSalesOpen(value)
    setFilter('all')
    setOrderBy('desc')
    setLoading(false)
  }

  const handleFindDataBase = async () => {
    setIsLoading(true)
    await handleUpdateValues()
    setIsLoading(false)
  }

  useEffect(() => {
    handleChangeFilter()
  }, [filter, orderBy,list])

  const controllerFilter = () => {
    let data = []
    switch (filter) {
      case 'all':
        data = list
        break
      case 'owing':
        data = list.filter(item => item.detailsSale.isOpen)
        break
      case 'paidOff':
        data = list.filter(item => !item.detailsSale.isOpen)
        break
      case 'withDiscount':
        data = list.filter(item => item.saleDiscount.isHasDiscount)
        break
      case 'cancel':
        data = list.filter(item => item.cancelSale.isCancel)
        break
      default:
        data = list
        break
    }

    let sortedClients = [...data]
    switch (orderBy) {
      case 'asc':
        sortedClients.sort((a, b) => a.detailsSale.date.localeCompare(b.detailsSale.date))
        break
      case 'desc':
        sortedClients.sort((a, b) => b.detailsSale.date.localeCompare(a.detailsSale.date))
        break
      case 'value':
        sortedClients.sort((a, b) => b.totalValueIncludeAll.toString().localeCompare(a.totalValueIncludeAll))
        break
      case 'seller':
        sortedClients.sort((a, b) => b.detailsSale.idEmployee.toString().localeCompare(a.detailsSale.idEmployee))
        break
      case 'factor':
        sortedClients.sort((a, b) => a.detailsSale.factorCompany.toString().localeCompare(b.detailsSale.factorCompany))
        break
      case 'amount':
        sortedClients.sort((a, b) => b.totalAmountItems.toString().localeCompare(a.totalAmountItems))
        break
      default:
        break
    }
    return sortedClients;
  }

  const handleFilterChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }
  
  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()

    resetScroll()
    if(value!= null){
      const filteredClients = data.filter(
        sale => {
          const filterValue = sale.totalValueIncludeAll.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterData= sale.detailsSale.date.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterEmployee = sale.detailsSale.idEmployee.toString().toLowerCase().includes(value.target.value.toLowerCase())
          return filterValue || filterData || filterEmployee 
        }
      )
      setFiltered(filteredClients)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    container.scrollTop = 0
    setLimit(50)
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  const setDateTime = async (value)=>{
    setSelectedDate(value)
    handleFindDataBase('',value)
  }

  const calculateSalesOpen = (value)=>{
    const tempList = value.filter(ss => ss.detailsSale.isOpen)
    countSalesOpen.fromInit(client,tempList)
    countSalesOpen.calculate()

  }

  const pdfAllSalesOpen = async ()=>{
    setIsLoading(true)
    await countSalesOpen.openPDFFutureReceivers()
    setIsLoading(false)
  }

  return (
    <div className={style.containerGeneral}> 
       <div className={style.containerGlobal}>  
           <Space right={2} left={2} top={2} bottom={2}/> 
           <div className={style.titleMenu}>     
             {window.innerWidth <= 1200 ? 
              <TitlePageResponsiveIcon title={'TOTAL DEVENDO'}
              subtitle={countSalesOpen.getValueTotalOpen()}
             children={
              <div className={style.row}>
                 <GetButtonIcon
                  color='#007bff' icon={faFilePdf} handleFunction={pdfAllSalesOpen}/>
              </div>
             }
             path={client._genericPerson.name} icon={faList}/>  
             :<TitlePageResponsiveIcon subtitle={'Lista de Todas as Vendas'} 
             children={
              <div className={style.row}>
               <TitlePageResponsiveIcon title={'TOTAL DEVENDO'}
                    subtitle={countSalesOpen.getValueTotalOpen()}
                  children={
                    <div className={style.row}>
                      <Space left={50}/>
                      <GetButtonIcon
                        color='#007bff' icon={faFilePdf} handleFunction={pdfAllSalesOpen}/>
                       {/*<DatePickerComponent 
                        handlerFunction={setDateTime}
                        valueStart={selectedDate} label={'DATA DE BUSCA'}/>*/}
                        <Space left={5}/>
                        <GetButtonIcon responsive={true} title={'BUSCAR NO SERVIDOR'} 
                        color='#007bff' icon={faDownload} handleFunction={handleFindDataBase}/>
                          </div>
                        }
                  path={client._genericPerson.name} icon={faCalculator}/>  
              </div>
             }
             path={'São Todas as Vendas da ' + client._genericPerson.name + ' Cadastradas no Sitema...'} icon={faList}/>}
            </div>
            
           <div className={style.buildList}>
             <div className={style.filter}>
                 <div className={style.inputFilter}>
                   <InputModels iconButton={faMagnifyingGlass} 
                   type={"text"} placeholder={"Buscar Venda..."}
                   onChange={handleChangeFilter}/>
                 </div>
                 <div className={style.containerDropDown}> 
                  <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                    <option value="all">TODOS</option>
                    <option value="owing">DEVENDO</option>
                    <option value="paidOff">QUITADA</option>
                    <option value="withDiscount">COM DESCONTO</option>
                    <option value="cancel">CACELADAS</option>
                  </select>
                   <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                       <option value="asc">CRESCENTE</option>
                       <option value="desc">DESCRESCENTE</option>
                       <option value="value">VALOR</option>
                       <option value="seller">VENDEDOR</option>
                       <option value="factor">FATOR DE VENDA</option>
                       <option value="amount">QUANTIDADE DE PEÇAS</option>
                   </select>
                 </div>
             </div>
             <Space right={2} left={2} top={5} bottom={0}/> 
             {(filtered.length > 0 && !loading ) ?<div className={style.buildList}>
                <GetAmountList amount={filtered.length} title={"Vendas Totais Encontradas na Busca..."}/>
               {window.innerWidth> 1200?  <SaleModelHelperName />: null}
               <Space right={0} left={0} top={0} bottom={10}/> 
             </div> : null}
             <div ref={containerRef} className={window.innerWidth > 1200? style.containerList:style.containerList1} onScroll={checkScroll}>
                  <div className={style.loaderCenter}>
                    <Loader titleFinding={'Buscando Vendas no Servidor!'} 
                         subtitleFinding={'Aguarde...'} loading={loading}/>
                  </div>
                 <div>
                   {(filtered.length === 0 && !loading)?
                   <GetSmallModels title={"Não há Vendas com Esse Filtro"}
                   subtitle={"Tente Trocar o Filtro..."}
                   icon={faCircleInfo} />:
                     filtered.slice(0, limit).map((sale, index) => (
                         <div key={index}>
                          <SaleModel sale={sale} inClient={true} onClick={()=>handleOpenViewSale(sale)}
                          functionImage={()=>toggleImageExpansion(sale.tempEmployee.genericPerson.name,
                            sale.tempEmployee.linkImage)} />
                         </div>
                     ))
                   }
                 </div>
             </div>
           </div>
          {
            window.innerWidth <= 1200?
            <GetFloatingButton width={65} height={65} icon={faCartArrowDown} color='#007bff' margiRight={'1%'} margiBottom={'1%'} handleFunction={()=>navigate(routerFromSale)} />
            :
            <GetFloatingButtonWithLegend legend={'NOVA VENDA'} width={'10%'} icon={faCartArrowDown} margiBottom={'1%'} color='#007bff' margiRight={'1%'} handleFunction={()=>navigate(routerFromSale)} />
          }
        {showViewSale ? <ViewSaleController canEdite={true} handleSave={handleUpdateValues} saleStart={saleSelected} handleClose={()=> setShowViewSale(false)}/> : null}
        {isExpanded? <GetExpandedImage nameProduct={name} linkImage={linkImage} onClose={toggleImageExpansion}/>:null}
       </div>
    </div>
  )

}

export default ListOfSalesClient