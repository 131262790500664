
import { findNFeSettings } from '../backEnd/Memory.js'
import { sendRequestGetLocal, sendRequestPostLocal} from '../backEnd/requesition.js'
const _routerSetEmitNfeInCloud ='/nfe/emit'
const _routerSetCancelNfeInCloud ='/nfe/cancel'
const _routerGetCertData ='/nfe/getData'

export const getDataNfe = async (showError) =>{
    try {
        const results = await sendRequestGetLocal(_routerGetCertData)
        if(Array.isArray(results)){
            if(results.length > 0) return results[0]
        }else return false
    } catch (error) {
        showError('ERROR AO BUSCAR DADOS PARA EMISSAO DA NFe',error.message)     
        return false
    }
}

export const setCancelNFe = async (nfeEmited,showError) =>{
    try {
        const data = await getDataNfe(showError)
        if(data === false){
            throw new Error('Certificados Não Foram Lidos do Servidor')
        }
            const results = await sendRequestPostLocal(_routerSetCancelNfeInCloud,
            {
                nfeData: nfeEmited.getMapFromCloud(),
                certData:data
            }
        )
        await findNFeSettings(showError)
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
       
    } catch (error) {
        showError('ERROR AO CANCELAR NOTA FISCAL',error.message)     
        return {status:404}
    }
}

export const setEmitNFe = async (nfe,showError) =>{
    try {

        const data = await getDataNfe(showError)
        if(data === false){
            throw new Error('Certificados Não Foram Lidos do Servidor')
        }
            const results = await sendRequestPostLocal(_routerSetEmitNfeInCloud,
            {
                nfeData: nfe.getMapFromCloud(),
                certData:data
            }
        )
        await findNFeSettings(showError)
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
       
    } catch (error) {
        showError('ERROR AO EMITIR NOTA FISCAL',error.message)     
        return {status:404}
    }
}
