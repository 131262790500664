import React from "react"
import style from '../style/GetReceivers.module.css'

function GetReceivers({paidSale}){
    return(
        <div className={style.sale}>
            <div>
                <p className={style.nameSale}>{paidSale.codeSale}</p>
                <div className={style.divRow}>
                    <p className={style.valueSale}>{paidSale.getTotalValuePaid()}</p>
                    <p className={style.dataSale}>
                        {'Data do Pagamento: ' + paidSale.dataPaid.slice(0,10)}
                    </p>
                    {window.innerWidth > 1200 ? <p className={style.cancel}>
                        {'Código: '+paidSale.codeParcel}
                    </p>:null}
                    <p className={style.dataSale}>
                        {'Detalhes: ' + paidSale.descrition}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default GetReceivers