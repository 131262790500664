import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import InputModelsBlock from "../../layout/InputModelsBlock";
import style from '../../style/GetNFeConfigureCompany.module.css'
import { faPeopleArrows,  faHandshakeAngle, faPercent, faFilterCircleDollar, faAngleUp, faAngleDown, faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import Space from "../../layout/Space";
import { TitlePageResponsiveIcon } from "../../layout/TitlePage";
import { GetButton, GetButtonIcon } from "../../layout/ButtonsLayout";

import { GetSmallModels } from "../../Models/GetModels";
import { GenericDropDown } from "../../layout/GenericDropDown";

const getFormData = (pis) => {
    return {
        CST:  pis.CST,
        pPIS: pis.pPIS.toString(),
    }
}

const getFormNeed = () => {
    return {
        CST:false,
        pPIS:false,
    }
}

export const GetPIS = forwardRef(({initialExpand,isEdite,update,pis}, ref) =>{

    const [updateScreen,SetUpdateScreen] = useState(true)
    const [formData, setFormData] = useState(getFormData(pis))
    const [needData, setNeedData] = useState(getFormNeed)
    const [expanded, setExpand] = useState(initialExpand?? true)

    const handleChange = (e) => {
        if(e !== ''){
            SetUpdateScreen(false)
            let { name, value } = e.target
            setFormData({ ...formData, [name]: value })
        }
    }

    useEffect(() => {
        SetUpdateScreen(false)
        pis.fromEditePIS(formData)
        SetUpdateScreen(true)
    },[formData,update])

    useImperativeHandle(ref, () => ({
        handleSubmit
    }))

    const handleSubmit= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) 
            return false
    
        return formData

    }

    return (
        <div className={style.div}>
            <TitlePageResponsiveIcon 
                children={
                    <GetButtonIcon color='#094f00' height={40} width={40} icon={expanded? faAngleUp :  faAngleDown} handleFunction={()=>setExpand(!expanded)}/> 
                }
                subtitle={"PIS"} path={"Imposto sobre Programa de Integração Social..."} icon={faHandshakeAngle}/> 
             <div className={expanded? style.visible :   style.notVisible}>
                <div className={style.div} >
                <GetSmallModels 
                    children={
                        <GetButton responsive={true} color='#FF0000' title={'AJUDA'}  icon={faCircleQuestion} handleFunction={()=>window.open('https://docs.enotasgw.com.br/v2/docs/cst-pis-e-cofins','_black')}/> 
                    }
                    title={'PRECISA DE AJUDA PARA CONFIGURAR?'} backgroundColor={"#005f00"} subtitle={'Clique no Botão ao Lado para Ver a Tabela do Sefaz'}/>
                        <GenericDropDown 
                            legend={'CST - CÓDIGO DE SITUAÇÃO TRIBUTÁRIA DO PIS'} 
                            data={[
                                '01 - Operação Tributável com Alíquota Básica',
                                '02 - Operação Tributável com Alíquota Diferenciada',
                                '03 - Operação Tributável com Alíquota por Unidade de Medida de Produto',
                                '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero',
                                '05 - Operação Tributável por Substituição Tributária',
                                '06 - Operação Tributável a Alíquota Zero',
                                '07 - Operação Isenta da Contribuição',
                                '08 - Operação sem Incidência da Contribuição',
                                '09 - Operação com Suspensão da Contribuição',
                                '49 - Outras Operações de Saída',
                                '50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
                                '51 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno',
                                '52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação',
                                '53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
                                '54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
                                '55 - Operação com Direito a Crédito - Vinculada a Receitas Não Tributadas no Mercado Interno e de Exportação',
                                '56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação',
                                '60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
                                '61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno',
                                '62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação',
                                '63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
                                '64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação',
                                '65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação',
                                '66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação',
                                '67 - Crédito Presumido - Outras Operações',
                                '70 - Operação de Aquisição sem Direito a Crédito',
                                '71 - Operação de Aquisição com Isenção',
                                '72 - Operação de Aquisição com Suspensão',
                                '73 - Operação de Aquisição a Alíquota Zero',
                                '74 - Operação de Aquisição sem Incidência da Contribuição',
                                '75 - Operação de Aquisição por Substituição Tributária',
                                '98 - Outras Operações de Entrada',
                                '99 - Outras Operações'
                            ]} 
                            name={'CST'} 
                            initialState={formData.CST} 
                            handlerFuncion={handleChange} 
                            />
                        <Space left={5} top={5}/>
                        <div className={style.divInRow}>
                        {
                            !isEdite?
                            <>
                                <Space left={5} top={5}/>
                                <InputModelsBlock legend={'VALOR DA BASE DE CÁLCULO DO PIS'} disable={true} onChange={handleChange} maxLength={10} value={pis.vBC} iconButton={faFilterCircleDollar} type={"number"} placeholder={"Este valor é a base sobre a qual a alíquota do PIS será aplicada..."}/>  
                            </>:null
                        }
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'PERCENTUAL DA ALÍQUOTA DO PIS'} onChange={handleChange} validation={needData.pPIS} maxLength={10} value={formData.pPIS} name={'pPIS'} iconButton={faPercent} type={"number"} placeholder={" Percentual aplicado sobre a base de cálculo para determinar o valor do PIS..."}/>  
                    </div>
                    {
                        !isEdite?
                        <>
                            <Space left={5} top={5}/> 
                            <GetSmallModels title={'VALOR FINAL DO PIS'} data={updateScreen} subtitle={'(VBC*PIS)/100 = VALOR PIS'} value={pis.vPIS.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}/>
                        </>:
                    null}
                </div>
            </div>
        </div>
    )
})