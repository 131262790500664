import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import style from '../../style/GetNFeConfigureCompany.module.css'
import { faCircleInfo, faAngleDown, faAngleUp, faPlus } from "@fortawesome/free-solid-svg-icons";
import { TitlePageResponsiveIcon } from "../../layout/TitlePage";
import { GetButtonIcon } from "../../layout/ButtonsLayout";
import { faProductHunt } from "@fortawesome/free-brands-svg-icons";
import { GetSmallModels } from "../../Models/GetModels";
import { GetNFeOnlyProduct } from "./GetNFeOnlyProduct";
import Space from "../../layout/Space";
import { Det } from "../../objects/NFe/NFe";
import { ToggleButtonWithContainer } from "../../layout/ToggleButton";


export const GetNfeProducts = forwardRef(({initialExpand,nfe,canEdite,}, ref) =>  {
    const productRefs = useRef([])
    const [expanded, setExpand] = useState(initialExpand?? true)
    const [listProducts,setProducts] = useState(nfe.det)
    const [isCalculateActive,setCalculate] = useState(false)

    useImperativeHandle(ref, () => ({
        handleSubmit
    }))

    const handleSubmit = () => {
        let hasErro = true
        for (let i = 0; i < productRefs.current.length; i++) {
            const element = productRefs.current[i]
            
            if (element.handleSubmit() === false) {
                hasErro = false  
            }
        }
    
        return hasErro
    }

    const newPorduct = () =>{
        nfe.det.push(new Det())
        setProducts(nfe.det)
    }

    const removeProduct = (product) =>{
        const listTemp = nfe.det.filter(ss=>ss!==product)
        nfe.det = listTemp
        setProducts(listTemp)
    }

    return (
        <div className={style.div}>
            <TitlePageResponsiveIcon 
                children={
                    <div className={style.buttons}>
                        {
                            expanded && canEdite? <>
                                <GetButtonIcon color='#ff0000' height={40} width={40} icon={faPlus}  handleFunction={newPorduct}/> 
                                <Space left={5}/>
                            </>:null
                        }
                        <GetButtonIcon color='#094f00' height={40} width={40} icon={expanded? faAngleUp :  faAngleDown}  handleFunction={()=>setExpand(!expanded)}/> 
                    </div>
                }
                subtitle={"PRODUTOS DA NFe"} path={"Configure os Produtos Abaixo..."} icon={faProductHunt}/>   
            <div className={expanded? style.visible :   style.notVisible}>
                {/*<ToggleButtonWithContainer
                titleOn={'CALCULAR ICMS AUTOMATICAMENTE'} titleOff={'CALCULO DO ICMS MANUAL'}
                initState={isCalculateActive} onChange={(e)=>setCalculate(e)} />*/}
                <Space top={5}/>
                {(listProducts.length === 0)?
                   <GetSmallModels backgroundColor={"#ff0000"} title={"Não há Produtos na NFe"}
                   subtitle={"Tente Trocar o Filtro..."}
                   icon={faCircleInfo} />:
                     listProducts.map((data, index) => (
                        <div key={index}>
                         <GetNFeOnlyProduct 
                         ref={(el) => (productRefs.current[index] = el)}
                         product={data} automaticMath={isCalculateActive} index={index} canEdite={canEdite} fuctionTrash={()=>removeProduct(data)} initialExpand={false}/>
                         <Space top={5}/>
                        </div>
                     ))
                }
            </div>
        </div>
    )
})
