import React from 'react';
import style from '../style/GetWarning.module.css'
import { faWarning, faClose } from '@fortawesome/free-solid-svg-icons';
import { TitlePageResponsiveIcon, TitlePageResponsiveIconExpansive } from './TitlePage';
import { GetButton } from './ButtonsLayout';
function GetWarning({title, message, onClose }) {
    const handleClose = () => {
      onClose()
    } 

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
          <div className={style.div}>
            <TitlePageResponsiveIconExpansive subtitle={title} path={message} icon={faWarning}/>  
            <div className={style.buttons}>
              <GetButton title={"OK, FECHAR"} color='#E4080A' icon={faClose} handleFunction={handleClose}/>   
            </div>
          </div>
        </div>
      </div>
    )
}

export default GetWarning
