import React, { useState } from 'react';
import style from "../style/StateCountry.module.css"

const StatesCountry = ({ initialState , handlerFuncion,name,disable}) => {
  const states = [
    "MATO GROSSO", "ALAGOAS", "AMAPÁ", "AMAZONAS", "BAHIA", "CEARÁ", "DISTRITO FEDERAL",
    "ESPÍRITO SANTO", "GOIÁS", "MARANHÃO", "ACRE", "MATO GROSSO DO SUL",
    "MINAS GERAIS", "PARÁ", "PARAÍBA", "PARANÁ", "PERNAMBUCO", "PIAUÍ", "RIO DE JANEIRO",
    "RIO GRANDE DO NORTE", "RIO GRANDE DO SUL", "RONDÔNIA", "RORAIMA", "SANTA CATARINA",
    "SÃO PAULO", "SERGIPE", "TOCANTINS"
  ]

  const [state, setState] = useState(initialState || "");

  const handleChange = (event) => {
    setState(event.target.value);
    handlerFuncion(event)
  }

  return (
    <div>
        <select disabled={disable} name={name} className={style.state} onChange={handleChange} value={state}>
            {states.map((estado, index) => (
                <option key={index} value={estado}>{estado}</option>
            ))}
        </select>
    </div>
  );
}


export default StatesCountry;
