import React, { useState, useEffect, useRef } from 'react';
import style from "../../style/AllMothsScreem.module.css"
import { GetSmallModels } from '../../Models/GetModels.js';
import { faMagnifyingGlass, faCircleInfo, faDownLeftAndUpRightToCenter, faCalendarCheck, faLock } from '@fortawesome/free-solid-svg-icons';
import InputModels from "../../Models/InputModels.js"
import GetAmountList from '../../Models/GetAmountList.js';
import Space from '../../layout/Space.js';
import { GetButton } from '../../layout/ButtonsLayout.js';
import DatePickerComponent from '../../layout/DatePickerComponent.js';
import { useError } from '../../AppContext.js';
import { getListMoths } from '../../controllers/CloudMoth.js';
import Loader from '../../layout/Loader.js';
import { updateIfNull } from '../../backEnd/Memory.js';
import { MothsComparisonChart } from './SalesPerformanceChart.js';
import ViewMothUnic from './ViewMothUnic.js';
import { employeeData } from '../../backEnd/InitController.js';

const getDate = (timePast) => {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - timePast)
    return currentDate.toISOString().slice(0,10)
}

function AllMothsScreem() {
    const [selectedDate, setSelectedDate] = useState(getDate(180))
    const [canViewMoths] = useState(employeeData().employeePermission.permissionsContabilite[1])

    const containerRef = useRef(null)
    const [limit, setLimit] = useState([50])

    const [filtered, setFiltered] = useState([])
    const [list, setList] = useState([])

    const [showViewMoth, setShowViewMoth] = useState(false)
    const [selectedMoth, setSelectedMoth] = useState(null)

    const [orderBy, setOrderBy] = useState('desc')
    const [loading, setLoading] = useState(false)
    const { showError } = useError()

    const handleOpenMoth = (moth) => {
        setSelectedMoth(moth)
        setShowViewMoth(true)
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            await updateIfNull(showError)
            await handleFindDataBase(getDate(180))
            setLoading(false)
        }
        fetchData()
    }, [])

    useEffect(() => {
        handleChangeFilter()
    }, [list])

    const handleFindDataBase = async (valueData) => {
        setLoading(true)
        setList([])
        setFiltered([])
        const value = await getListMoths(valueData ?? selectedDate, showError)   
        setOrderBy('desc')
        setList(value)
        setFiltered(value)
        setLoading(false)
    }

    const controllerFilter = () => {
        let sortedMoths = [...list]
        switch (orderBy) {
            case 'asc':
                sortedMoths.sort((a, b) => a.getTimeFinishedToString().localeCompare(b.getTimeFinishedToString()))
                break
            case 'desc':
                sortedMoths.sort((a, b) => b.getTimeFinishedToString().localeCompare(a.getTimeFinishedToString()))
                break
            default:
                break
        }
        return sortedMoths
    }

    const handleOrderByChange = async (event) => {
        setOrderBy(event.target.value)
        handleChangeFilter()

    }

    const handleChangeFilter = (value) => {
        const data = controllerFilter()
        resetScroll()
        if (value != null) {
            const filtered = data.filter(
                stock => {
                    const filternName = stock.codeName.toLowerCase().includes(value.target.value.toLowerCase())
                    return filternName
                }
            )
            setFiltered(filtered)
        } else {
            setFiltered(data)
        }
    }

    const resetScroll = () => {
        const container = containerRef.current
        if (container) {
            container.scrollTop = 0
            setLimit(50)
        }
    }

    const setDateTime = async (value) => {
        setSelectedDate(value)
        handleFindDataBase(value)
    }

    const checkScroll = () => {
        const container = containerRef.current;
        const scrollPosition = container.scrollTop;
        const containerHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;

        if (scrollPosition + containerHeight >= scrollHeight) {
            setLimit((limit + 50))
        }
    }

    return (
        <div className={style.containerGeneral}>
            <div className={style.containerGlobal}>
                {canViewMoths ?
                    <>
                    {!loading? <div className={style.graphic}>
                        <MothsComparisonChart height={window.innerWidth <= 1200? 350: 650} data={filtered} />
                    </div>:null}
                        <Space left={2} />
                        <div className={style.other}>
                            <div className={style.buildList}>
                                <div className={style.filter}>
                                    <div className={style.divInRow}>
                                        <div className={style.div}>
                                            <div className={style.inputFilter}>
                                                <InputModels iconButton={faMagnifyingGlass}
                                                    type={"text"} placeholder={"Buscar Mês Pelo Nome..."}
                                                    onChange={handleChangeFilter} />
                                            </div>
                                        </div>
                                        <div className={style.div}>
                                            <div className={style.containerDropDown}>
                                                <select className={style.dropDown} value={orderBy}
                                                    onChange={handleOrderByChange}>
                                                    <option value="asc">CRESCENTE</option>
                                                    <option value="desc">DESCRESCENTE</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <Space left={5} />
                                    <div className={style.divInRow}>
                                        <div className={style.div}>
                                            <DatePickerComponent
                                                handlerFunction={setDateTime}
                                                valueStart={selectedDate} label={'Data Inicial da Busca no Servidor'} />
                                        </div>
                                        <div className={style.div}>
                                            <GetButton title={'BUSCAR NO SERVIDOR'} height={65}
                                                color={"#0161DD"} handleFunction={() => handleFindDataBase(selectedDate)}
                                                icon={faDownLeftAndUpRightToCenter} />
                                        </div>
                                    </div>
                                </div>
                                <Space right={2} left={2} top={5} bottom={0} />
                                {(filtered.length > 0 && !loading) ? <div className={style.buildList}>
                                    <GetAmountList amount={filtered.length} title={"Meses no Período de Busca..."} />
                                    <Space right={0} left={0} top={0} bottom={10} />
                                </div> : null}
                                <div ref={containerRef} className={style.containerList} onScroll={checkScroll}>
                                    <div className={style.loaderCenter}>
                                        <Loader titleFinding={'Buscando Meses no Servidor!'}
                                        subtitleFinding={'Aguarde...'} loading={loading} />
                                    </div>
                                    {(filtered.length === 0 && !loading) ?
                                        <GetSmallModels title={"Não há Mês com Esse Filtro"}
                                            subtitle={"Tente Trocar o Filtro..."}
                                            icon={faCircleInfo} /> :
                                        filtered.slice(0, limit).map((item, index) => (
                                            <div onClick={() => handleOpenMoth(item)} className={style.click}>
                                                <GetSmallModels icon={faCalendarCheck}
                                                    title={item.codeName}
                                                    subtitle={'Período: ' + item.getTimeStartToString().slice(0, 10) + ' Até ' + item.getTimeFinishedToString().slice(0, 10)} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>   
                        </> : 
                        <div className={style.divBlock}>
                            <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                            subtitle={'Você Não Tem Permissão para Visualizar Extratos do Mês'} />
                        </div>
                    }
            </div>

            {showViewMoth ? <ViewMothUnic myMoth={selectedMoth} onClose={() => setShowViewMoth(false)} /> : null}
        </div>
    )

}

export default AllMothsScreem