import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "../style/NavbarGlobal.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown,faAngleRight, faBell, faClose, faMoneyBill1Wave, faMoneyBillTransfer, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import logoApi from "../assets/icon-android1.png"
import { employeeData } from "../backEnd/InitController";
import { useShowEditeEmployee, useShowExit, useShowMenu } from "../AppContext";
import { alertsNotices, mothPay, myMoth, myStoreDataMemory } from "../backEnd/Memory";
import GetNoticeBell from "../layout/GetNoticeBell";
import GetNoticeMothPay from "../layout/GetNoticeMothPay";
import { GetButton } from "../layout/ButtonsLayout";
import { GetSmallModels } from "../Models/GetModels";


let viewOneTimeNeedPay = false;
const nameApp = 'B-Semijoias Gerenciador'

export class ObjectNavbarMenu{
    constructor(title,iconMenu){
        this.title = title
        this.iconMenu = iconMenu
        this.listMenus = []
        this.expanded = false
    }

    addMenu(title, icon, path,params,expanded) {
        this.expanded = true
        this.listMenus.push(new AuxNavBar(title, icon, path,params))
    }
}

class AuxNavBar{
    constructor(title,icon,path,params){
        this.title = title
        this.icon = icon
        this.path = path
        this.params = params
    }
}


export function NavBarGlobal({listObjectNavBarMenu}){
    const location = useLocation()
    const navigate = useNavigate()

    const {setShowExit} = useShowExit()
    const {setShowEditeEmployee} = useShowEditeEmployee()

    const [update , setUpdate] = useState(false)
    const [showMothPay,setShowMothPay] = useState(mothPay.isOverdue && !viewOneTimeNeedPay)
    const [showNotices,setShowNotices] = useState(false)
    const [name, setName] = useState(myStoreDataMemory ? myStoreDataMemory.storeName : 'Carregando...')
    const [menus, setMenus] = useState(listObjectNavBarMenu)
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    const [userLogged] = useState(employeeData())
    const {showMenu , setShowMenu} = useShowMenu()
  

    useState(()=>{
        setShowMenu(window.innerWidth > 768)
        setMenus(listObjectNavBarMenu)
    },[showMenu,listObjectNavBarMenu])

    useEffect(() => {
        const intervalId = setInterval(() => {
          setUpdate((prevUpdate) => !prevUpdate);
        }, 1000); 
    
        return () => clearInterval(intervalId);
    }, []);

    useEffect(()=>{
        setMenus(listObjectNavBarMenu)
    },[listObjectNavBarMenu])
    
    const handleNavigate = (item) => {
       navigate(item.path,{ state: item.params})
       if(window.innerWidth < 768) setShowMenu(false)
    }

    const handleImageLoad = () => {
        setImageLoaded(true) 
    }

    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }
 
    const handleLogout = () => {
        setShowExit(true)
    }

    const toggleExpansion = (index) => { 
        const updatedMenus = [...menus]
        updatedMenus[index].expanded = !updatedMenus[index].expanded
        setMenus(updatedMenus)
    }

    const userData = () => {
       return(
        <> {userLogged ? (
            <div className={style.loginData}>
                <div className={style.loginTitles}>
                    <div className={style.imageContainer} onClick={() => setShowEditeEmployee(true)}>
                        {errorOccurred ? (
                            <img src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif"
                                alt="Erro de carregamento" className={style.loaded} />) : (
                                <img src={userLogged.linkImage} alt={userLogged.genericPerson.name} onLoad={handleImageLoad}
                                    onError={handleImageError} onClick={() => { }}
                                    className={!imageLoaded ? style.loading : style.loaded} />)}
                    </div>
                    <div className={style.loginNames}>
                        <h5>{userLogged.genericPerson.name}</h5>
                        <h6>{userLogged.function}</h6>
                    </div>
                </div>
                <div onClick={()=>setShowNotices(true)} className={alertsNotices.length> 0? style.bellNotice:style.bell }>
                    <FontAwesomeIcon icon={faBell} />
                    <h6>{alertsNotices.length}</h6>
                </div>
                <div onClick={handleLogout} className={style.iconLogin}>
                    <FontAwesomeIcon icon={faRightFromBracket} />
                </div>
            </div>
        ) : null}</>
       )
    }

    return (
        showMenu ? (
            <div className={style.overlay}>
                <div className={style.containerNavbar}>
                    <div>
                        <div className={style.logo}>
                            <div className={style.logoAux}>
                                <img src={logoApi} className={style.containerIconeApp} alt="Logo BSemijoias Api" />
                                <div>
                                    <span className={style.title}>{nameApp}</span>
                                    <span className={style.titleCompany}>{name}</span>
                                </div>
                            </div>
                            <div onClick={()=>setShowMenu(false)} className={style.iconCloseMobile}>
                                <FontAwesomeIcon icon={faClose} />
                            </div>
                        </div>
                        {window.innerWidth < 1200? <>
                            {mothPay.isOverdue?<GetButton title={'MENSALIDADE VENCIDA'} color='#ff0000' icon={faBell} handleFunction={()=>setShowMothPay(true)}/>:null }  
                            {userData()}
                            {<GetTotal update={update}/>}
                        </>: null}
                        {menus.map((item, index) => (
                            <div key={index} className={item.expanded}>
                                {getTitle(item, () => toggleExpansion(index))}
                                <nav>
                                    <ul className={style.containerUlLinks}>
                                        {item.listMenus.map((subMenu, index) => (
                                            item.expanded ? (
                                                <div className={style.subMenuLine} key={index}>
                                                    <svg className={style.line}>
                                                        <line x1="0" y1="0" x2="0" y2="100" style={{ stroke: 'white', strokeWidth: 3 }} />
                                                    </svg>
                                                    {getLi(() => handleNavigate(subMenu), subMenu, location)}
                                                </div>
                                            ) : null
                                        ))}
                                    </ul>
                                </nav>
                                <div className={style.space}></div>
                            </div>
                        ))}
                    </div>
                    {window.innerWidth > 1200? 
                    <div>
                        <GetTotal update={update}/>
                        {userData()}
                    </div>
                    : null}
                </div>
                {showNotices? <GetNoticeBell onClose={()=>setShowNotices(false)}/> : null}
                {showMothPay? <GetNoticeMothPay onClose={()=>{
                    setShowMothPay(false)
                    viewOneTimeNeedPay = true
                }}/>:null}
            </div>
        ) : null
    );
    
}

function GetTotal({update}){
    return(
        <div className={style.divSmall}>
            <GetSmallModels title={'SUAS VENDAS HOJE:'} 
                backgroundColor={"#00ff00"} subtitle={update? myMoth.getValueTotalSaleEmployeeToday() : myMoth.getValueTotalSaleEmployeeToday()} 
                icon={faMoneyBill1Wave}/>
            <GetSmallModels disable={!employeeData().employeePermission.permissionsContabilite[3]} title={'TOTAL/VENDAS HOJE:'} 
                backgroundColor={"#fF0f00"} subtitle={update? myMoth.getValueTotalSaleToday() : myMoth.getValueTotalSaleToday()} 
                icon={faMoneyBillTransfer}/>
        </div>
    )
}

function getTitle(item,toggleExpansion){
   return(
    <div onClick={toggleExpansion} className={style.menuName}>
       <div className={style.menuName1}>
            <FontAwesomeIcon className={style.iconMenu} icon={item.iconMenu}/>
            <h2 className={style.titleMenu}>{item.title}</h2>
       </div>
        <FontAwesomeIcon className={style.iconExanded} icon={item.expanded? faAngleDown: faAngleRight}/>
    </div>
   )
}

function getLi(handleNavigate,item,location){
    return  (
        <li key={item.title}>
            <div onClick={handleNavigate} className={`${style.containerLink} ${location.pathname === item.path ? style.activeLink : ""}`}>
                <FontAwesomeIcon className={style.iconSubMenu} icon={item.icon} />
                {item.title}
            </div>
        </li>
    )
}