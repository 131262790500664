import React, { useEffect, useState } from 'react';
import style from '../style/InputPhoto.module.css';
import GetWarning from '../layout/GetWarning';
import { GetButton } from '../layout/ButtonsLayout';
import { faClose, faImage, faImages } from '@fortawesome/free-solid-svg-icons';
import TypePhotoSave from '../objects/TypePhotoSave';

function InputPhoto({ initialPhoto, handlerFileSelected,disable ,iconButton,titleButton}) {
    const titleError = "Erro Ao Selecionar Foto";
    const messageError = "Por favor selecione uma foto Válida";
    const [selectedFile, setSelectedFile] = useState(null);
    const [showModal, setShowModal] = useState(false);
   
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    
    const handleImageLoad = () => {
        setImageLoaded(true); 
    }

    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }


    useEffect(() => {
        setSelectedFile(null);
    }, [initialPhoto]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.includes('image')) {
            setSelectedFile(file);
            handlerFileSelected(file);
        } else {
            setSelectedFile(null);
            handlerFileSelected(null);
            setShowModal(true);
        }
    }

    const handleOpenFileDialog = () => {
        if(!disable)
            document.getElementById('fileInput').click();
    }


    return (
        <div className={style.containerPhoto}>
            {(!selectedFile && !initialPhoto && !disable) ? (
                <div className={style.buttom}>
                    {!iconButton?<GetButton icon={faImage} title={titleButton??"Selecionar Foto"} 
                    handleFunction={handleOpenFileDialog} height={50} color={"#ff0000"} />
                    :<GetButton icon={faImage}  handleFunction={handleOpenFileDialog}  color={"#ff0000"}/>}
                </div>
            ) : (
                <>
                    {selectedFile && (
                        <div onClick={handleOpenFileDialog} className={style.selectedFile}>
                            <img className={style.image} onLoad={handleImageLoad} 
                            src={URL.createObjectURL(selectedFile)} alt="Icone" />
                        </div>
                    )}
                    {initialPhoto && !selectedFile && (
                        <div onClick={handleOpenFileDialog} className={style.selectedFile}>
                            {errorOccurred ? (
                                <img
                                src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                                alt="Erro de carregamento"
                                className={style.loaded}
                                />
                            ) : (
                            <img src={`${initialPhoto}?${new Date().getTime()}`} alt={'icone'} 
                            onLoad={handleImageLoad} onError={handleImageError}
                            className={!imageLoaded ? style.loadingIndicator : style.loaded}/> )}
                        </div>
                    )}
                </>
            )}

            <input id="fileInput" type="file" accept="image/*" style={{ display: 'none' }} onChange={handleFileChange} />
            {showModal && <GetWarning title={titleError} message={messageError} onClose={() => setShowModal(false)} />}
        </div>
    )
}

export function InputMultiplePhotos({ initialPhotos, handlerFilesSelected, iconButton, titleButton }) {
    const titleError = "Erro Ao Selecionar Fotos";
    const messageError = "Por favor selecione fotos válidas";
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleFileChange = (e) => {
        const files = e.target.files
        const isValidFiles = Array.from(files).every(file => file.type.includes('image'));

        if (isValidFiles) {
            setSelectedFiles([...selectedFiles, ...files]);
            let tempList = []
            let count = 0
            files.forEach(element => {
                let temp = TypePhotoSave()
                temp.fromInit(count.toString(),URL.createObjectURL(element))
                tempList.push(temp)
            })
            handlerFilesSelected(tempList)
        } else {
            setShowModal(true)
        }
    }

    const handleOpenFileDialog = () => {
        document.getElementById('fileInput').click();
    }

    const handleRemove = (photo) => {
        const updatedFiles = selectedFiles.filter(file => file !== photo);
        setSelectedFiles(updatedFiles);
        handlerFilesSelected(updatedFiles);
    }

    useEffect(() => {
        setSelectedFiles([])
    }, [initialPhotos])

    return (
        <div className={style.containerPhotos}>
                <div className={style.button}>
                    {!iconButton ? (
                        <GetButton icon={faImages} title={titleButton ?? "Selecionar Fotos"} handleFunction={handleOpenFileDialog} height={50} color={"#ff0000"} />
                    ) : (
                        <GetButton icon={faImages} handleFunction={handleOpenFileDialog} color={"#ff0000"} />
                    )}
                </div>
                <div className={style.multiples}>
                        {selectedFiles.map((file, index) => (
                            <div key={index} className={style.selectedFileMutiple}>                     
                                <img className={style.image} src={URL.createObjectURL(file)} alt={`Foto ${index + 1}`} />
                                <GetButton title={'REMOVER'} handleFunction={()=>handleRemove(file)} height={40}  icon={faClose} color={'#ff0000'}/>
                            </div>
                        ))}
                        {initialPhotos.map((photo, index) => (
                            <div key={index} className={style.selectedFileMutiple}>
                                <img src={`${photo.linkImage}?${new Date().getTime()}`} alt={`Foto ${index + 1}`} />
                                <GetButton title={'REMOVER'} handleFunction={()=>handleRemove(photo)} height={40}  icon={faClose} color={'#ff0000'}/>
                            </div>
                        ))}
                    </div>
            <input id="fileInput" type="file" accept="image/*" style={{ display: 'none' }} multiple onChange={handleFileChange} />
            {showModal && <GetWarning title={titleError} message={messageError} onClose={() => setShowModal(false)} />}
        </div>
    );
}


export function ShowMultiplePhotos({ initialPhotos}) {
    return (
        <div className={style.containerPhotos}>
                <div className={style.multiples}>  
                        {initialPhotos.map((photo, index) => (
                            <div key={index} className={style.selectedFileMutiple}>
                                <img src={`${photo.linkImage}?${new Date().getTime()}`} alt={`Foto ${index + 1}`} />
                            </div>
                        ))}
                </div>
        </div>
    );
}

export default InputPhoto;
