import React, { useState } from "react";
import logoApi from '../assets/logoBruna2.png';
import style from "../style/LoginComponent.module.css"
import { GetButton } from "../layout/ButtonsLayout";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { tryLogin } from "../controllers/CloudLogin";
import Loader from "../layout/Loader";
import { useNavigate } from "react-router-dom";
import { routerFromLoginEmployee } from "../routers/routerList";
import Space from "../layout/Space"
import { useError } from "../AppContext";
import { Helmet } from "react-helmet";

export const nameSaveEmail ='savedEmail'

function LoginComponent({handleLogin}){
    const navigate = useNavigate()
    const [email, setEmail] = useState(localStorage.getItem(nameSaveEmail) || '')
    const [rememberEmail, setRememberEmail] = useState(true)
    const [password,setPassword] = useState('')
    const {showError} = useError()
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
      setLoading(true)
      var response = await tryLogin(email,password,showError)

      
      if(response.status === 200){
        localStorage.setItem(nameSaveEmail, email)
        handleLogin()
        navigate(routerFromLoginEmployee)
      }

      setLoading(false)
    }


    
    return(
        <div className={style.container}>
          <Helmet>
              <title>Entra na Sua Conta BSemijoias</title>
              <meta name="description" content="App B Semijoias Clientes é uma ferramenta essencial para revendedoras que buscam otimizar suas operações." />
              <meta name="keywords" content="B Semijoias login, login, b semijoias, logar bsemijoias , semijoias logar" />
          </Helmet>
           {!loading? <>
          <div className={style.containerLogin}>
            <div className={style.wrapLogin}>
              <div className={style.loginForm}>
               
                  <span className={style.loginFormTitle}>
                    <img src={logoApi} alt='Icone da Api B-Semijoias Clientes'></img>        
                  </span>              
                  <div className={style.wrapInput}>
                  <input className={style.input} type='email' value={email} onChange={e => setEmail(e.target.value)}/>
                  <span className={style.focus} data-placeholder='Email'></span>
                  </div>
                  <Space top={30}/>
                  <div className={style.wrapInput}>
                  <input className={ style.input} id='passwordId' type='password' value={password} onChange={e=> setPassword(e.target.value)}/>
                  <span className={style.focus} data-placeholder='Senha'></span>
                  </div>
                  <Space top={15}/>
                  <div className={style.rememberEmailCheckbox}>
                    <input type="checkbox" checked={rememberEmail} onChange={() => setRememberEmail(!rememberEmail)} />
                    <label>Salvar E-mail</label>
                  </div>
                  <Space top={15}/>
                  <GetButton title={'ENTRAR'} color={'#0774FC'} handleFunction={handleSubmit} icon={faArrowRightToBracket} />
              </div>
            </div>
          </div>  
          </> : 
          <div className={style.load}>
            <Loader titleFinding={'Estabelecendo Comunicação com o Servidor'}  
            subtitleFinding={'Aguarde...'} loading={loading}/>
          </div>}
      </div>
    )
}

export default LoginComponent;