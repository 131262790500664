import React, { useState } from "react"
import style from "../../style/AllGraphics.module.css"
import { makeAccounting } from "../../objects/Accounting/MakeAccounting"
import GetNeedUpdate from "./GetNeedUpdate"
import SalesPerformanceChart, { ClientsDebtChart, EmployeeSalesChart, OtherFactorsChart, ReceiversPerformace } from "./SalesPerformanceChart"
import Space from "../../layout/Space"
import { employeeData } from "../../backEnd/InitController"
import { GetSmallModels } from "../../Models/GetModels"
import { faLock } from "@fortawesome/free-solid-svg-icons"
import { databaseSettings } from "../../backEnd/Memory"

function GraphicItems(){
    return(
        <div>
            <div className={style.containerGlobal}>
                {makeAccounting.isCalculated ? (
                   <>
              
                   </>
                ) : (
                    <GetNeedUpdate />
                )}
            </div>
        </div>
    )
}

export default GraphicItems

export function GraphicCompare(){
    const [CanView] = useState(employeeData().employeePermission.permissionsContabilite[12])
    return(
        <div>
            <div className={style.containerGlobal}>
                {CanView? 
                <>
                {makeAccounting.isCalculated ? (
                   <>
                        <div className={style.divGraphic}>
                            <ReceiversPerformace  height={500} listReceivers={makeAccounting.accountingAllReceiversThisMoth.listPaidSalesThisMoth}/>
                        </div>
                        <Space top={5}/>
                        <div className={style.divGraphic}>
                            <ClientsDebtChart accountingData={makeAccounting.accountingControllerAllSalesReceiver} height={500}/>
                        </div>
                        <Space top={5}/>
                        <div className={style.divGraphic}>
                            <EmployeeSalesChart height={500} data={makeAccounting.accountingAllCommissions.getListComissions()}/>
                        </div>
                   </>
                ) : (
                    <GetNeedUpdate />
                )}</>:
               <div className={style.div}>
                <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                    subtitle={'Você Não Tem Permissão para Visualizar Gráficos'} />
               </div>}
            </div>
        </div>
    )
}

export function GraphicSales(){
    const [CanView] = useState(employeeData().employeePermission.permissionsContabilite[12])
    return(
        <div>
            <div className={style.containerGlobal}>
               {CanView?
               <>{makeAccounting.isCalculated ? (
                  <>
                    <div className={databaseSettings.isCanUseBrunaBJ? style.divGraphic :style.divGraphicExpanded }>
                        <SalesPerformanceChart repairs={makeAccounting.accountingAllRepairs.accountingRepairs} sales={makeAccounting.salesInPeriod} width={500} height={500} listReceivers={makeAccounting.accountingAllReceiversThisMoth.listPaidSalesThisMoth}/>
                    </div>
                    {databaseSettings.isCanUseBrunaBJ?
                        <>
                            <Space top={25}/>
                            <div className={style.divGraphic}>
                                <OtherFactorsChart factors={makeAccounting.otherFactorsReal} width={500} height={500}/>
                            </div>
                        </> : null
                    }
                  </>
                ) : (
                    <GetNeedUpdate />
                )}</>:
               <div className={style.div}>
                <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                    subtitle={'Você Não Tem Permissão para Visualizar Gráficos'} />
               </div>}
            </div>
        </div>
    )
}