import { format } from 'date-fns';
class CancelSale{
    constructor(){
        this.isCancel =false
        this.descriptionCancel =''
        this.idSelleCancel = 0
        this.dateCancel = ''
        this.tempSallerCancel = null
    }

    getDateCancel(){
        return this.dateCancel
    }

    fromCloud(data,employeeCancel){
        this.dateCancel = data.dateCancel;
        this.isCancel = data.isCancel;
        this.descriptionCancel = data.descriptionCancel;
        this.idSelleCancel = data.idSelleCancel;
        this.tempSallerCancel = employeeCancel
    }

    clear(){
        this.isCancel = false
        this.descriptionCancel = ''
        this.idSelleCancel = 0
    }

    getMapFromCloud(){
        return{
            'dateCancel': (this.dateCancel === null || this.dateCancel === '')? '' : format(this.dateCancel, 'yyyy-MM-dd HH:mm:ss'),
            'isCancel': this.isCancel,
            'descriptionCancel': this.descriptionCancel,
            'idSelleCancel': this.idSelleCancel
        }
    }

}

export default CancelSale