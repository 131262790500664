import { listItemsMemory } from "../backEnd/Memory";
import ItemsSale from "./ItemBJ/ItemsSale";

class SaleFromOtherSeller {
    constructor(
      codeSale,
      totalValue,
      totalValueRequest,
      totalValueOnlyNormal,
      totalValueIncludeAll,
      totalValueNoRegister,
      totalGramsNoRegister,
      totalItemsNoRegister,
      totalAmountItems,
      totalItemsNormal,
      totalItemsRequest,
      totalFactorItems,
      totalFactorItemsRequest,
      totalFactorItemsNormal,
      dateSale,
      isPresent,
      salle,
      factorCompany,
      payForm,
      isCanceled,
      nameSellerCancel,
      isEdite,
      isHasDiscount,
      isHasExtraValue,
      discountPercent,
      extraValue,
      itemsThisSale,
      requestThisSale,
      lastVersionBase
    ) {
      this.codeSale = codeSale;
      this.totalValue = totalValue;
      this.totalValueRequest = totalValueRequest;
      this.totalValueOnlyNormal = totalValueOnlyNormal;
      this.totalValueIncludeAll = totalValueIncludeAll;
      this.totalValueNoRegister = totalValueNoRegister;
      this.totalGramsNoRegister = totalGramsNoRegister;
      this.totalItemsNoRegister = totalItemsNoRegister;
      this.totalAmountItems = totalAmountItems;
      this.totalItemsNormal = totalItemsNormal;
      this.totalItemsRequest = totalItemsRequest;
      this.totalFactorItems = totalFactorItems;
      this.totalFactorItemsRequest = totalFactorItemsRequest;
      this.totalFactorItemsNormal = totalFactorItemsNormal;
      this.dateSale = dateSale;
      this.isPresent = isPresent;
      this.salle = salle;
      this.factorCompany = factorCompany;
      this.payForm = payForm;
      this.isCanceled = isCanceled;
      this.nameSellerCancel = nameSellerCancel;
      this.isEdite = isEdite;
      this.isHasDiscount = isHasDiscount;
      this.isHasExtraValue = isHasExtraValue;
      this.discountPercent = discountPercent;
      this.extraValue = extraValue;
      this.itemsThisSale = itemsThisSale;
      this.requestThisSale = requestThisSale;
      this.lastVersionBase = lastVersionBase;
      this.valueDiscountLiquid = 0.0;
      this.calculateDiscount();
    }
  
    fromCloud(data) {
        this.requestThisSale = []
        this.itemsThisSale = []
        
        if (data.items.length > 0) {
            for (const element of data.items) {
                let currentElement = element
                var foundIndex = listItemsMemory.findIndex(ss => ss.itemGlobal.codebar === currentElement.codebar)
                if(foundIndex !== -1){
                    var newItemSale = new ItemsSale()
                    newItemSale.fromOther(listItemsMemory[foundIndex])
                    newItemSale.amount = element.amount
                    if(element['isRequest'] === 1)   
                        this.requestThisSale.push(newItemSale)   
                    else
                        this.itemsThisSale.push(newItemSale)   
                    
                }
            }
        }
    
        this.codeSale = data['idController'];
        this.totalValue = parseFloat(data['totalValue']);
        this.totalValueRequest = parseFloat(data['totalValueRequest']);
        this.totalValueOnlyNormal = parseFloat(data['totalValueOnlyNormal']);
        this.totalValueIncludeAll = parseFloat(data['totalValueIncludeAll']);
        this.totalFactorItems = parseFloat(data['totalFactorItems']);
        this.totalAmountItems = data['totalAmountItems'];
        this.totalItemsNormal = data['totalItemsNormal'];
        this.totalItemsRequest = data['totalItemRequest'];
        this.isPresent = data['isPresent'] === 1 ? true : false;
        this.totalFactorItemsRequest = parseFloat(data['totalFactorItemRequest']);
        this.totalFactorItemsNormal = parseFloat(data['totalFactorsItemsNormal']);
        this.dateSale = new Date(data.dateRegister).toISOString();
        this.salle = data['seller'];
        this.factorCompany = parseFloat(data['factorCompany']);
        this.payForm = data['payForm'];
        this.isCanceled = data['isCancel'] === 1 ? true : false;
        this.nameSellerCancel = data['sellerCancel'];
        this.isEdite = data['isEdite'] === 1 ? true : false;
        this.isHasDiscount = data['isDiscount'] === 1 ? true : false;
        this.isHasExtraValue = data['isExtraValue'] === 1 ? true : false;
        this.discountPercent = data['discountPercent'];
        this.extraValue = parseFloat(data['extraValue']);
        this.totalValueNoRegister = parseFloat(data['totalValueNoRegister']);
        this.totalGramsNoRegister = parseFloat(data['totalGramsNoRegister']);
        this.totalItemsNoRegister = data['totalItemsNoRegister'];
        this.calculateDiscount();
    }


    getDate(){
        return this.dateSale.replace('T',' ').slice(0,19)
    }

    getTotalValueItems() {
        return (this.totalValueOnlyNormal + this.totalValueRequest).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
  
    }

    getTotalValueToString() {
        return this.totalValueIncludeAll.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
  
    }

    getPercentageToString() {
        return this.valueDiscountLiquid.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
    }
  
    calculateDiscount() {
      this.valueDiscountLiquid = 0.0;
      if (this.isHasDiscount) {
        const sumAll = this.totalValue + this.extraValue;
        this.valueDiscountLiquid = sumAll * (this.discountPercent / 100);
      }
    }
  }
export default SaleFromOtherSeller