import React, { useRef, useState } from 'react';
import style from '../../style/GetNFeConfigureCompany.module.css'
import { faClose, faTicket,faSave } from '@fortawesome/free-solid-svg-icons';
import Alert from '../../Models/Alert';
import Space from '../../layout/Space';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage';
import { GetButton, GetButtonIcon } from '../../layout/ButtonsLayout';
import { GetNFeCompany } from './GetNFeCompany';
import Loader from '../../layout/Loader';
import { setSetNfeFromCloud } from '../../controllers/CloudNFeSettings';
import { Emit } from '../../objects/NFe/NFe';
import { useError } from '../../AppContext';
import { emit } from '../../backEnd/Memory';


function GetNFeConfigureCompany({onClose}) {
    const companyRef = useRef(null)
    const [emitTemp] = useState(()=>{
      const emitTemp = new Emit()
      emitTemp.fromCloudCopy(emit.getMapFromCloud())
      return emitTemp
    })
    const [isVisible,setAlert] = useState(false)
    const [loading,setLoading] = useState(false)
    const { showError } = useError()

    const handleSaveClick = async () => {
      if (companyRef.current) {
        const formData = companyRef.current.handleSubmit()
        if(formData !== false){
          setLoading(true)     
          emitTemp.fromEdite(formData)
          const result = await setSetNfeFromCloud(emitTemp,formData.file,formData.keyCert,showError)
          if(result.status === 200) onClose()
          setLoading(false)
 
        }
      }
    }

    const handleClose = () => {
      onClose()
    } 

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
           <div className={style.title}>
                <TitlePageResponsiveIcon 
                children={
                    <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                }
                subtitle={"CONFIGURAR EMISSOR NFe"} path={"Complete os Campos Abaixo..."} icon={faTicket}/>      
           </div>
           { !loading?
            <>
              <Space left={5} top={5}/>
              <GetNFeCompany initialExpand={true} initialValue={emitTemp.getMapFromCloud()} isBlockEdite={false} ref={companyRef}/>
              <Space left={5} top={5}/>
              <div className={style.div}>
                <GetButton title={"SALVAR EMISSOR"} color='#007bff' icon={faSave} handleFunction={handleSaveClick}/> 
              </div>
            </>:
            <div className={style.loaderCenter}>
              <Loader titleFinding={'Trabalhando no Servidor Aguarde'} 
              subtitleFinding={'Aguarde...'} loading={loading}/>
            </div>    
           }
           {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
      </div>
    )
}

export default GetNFeConfigureCompany
