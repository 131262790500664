class PaidSale{
    constructor(
        codeSale,
        codeParcel,
        dataPaid,
        totalValuePaid,
        descrition
    ){
        this.codeSale  = codeSale;
        this.codeParcel = codeParcel;
        this.dataPaid = dataPaid;
        this.totalValuePaid = totalValuePaid;
        this.descrition = descrition
    }

    getDataPaidToString(){
        return this.dataPaid.replace('T', ' ').slice(0, 10)
    }

    getTotalValuePaid(){
        return this.totalValuePaid.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
}

export default PaidSale