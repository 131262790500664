import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import LoginComponent, { nameSaveEmail } from './pages/LoginComponent.js';
import NavigatorBar, { NavigatorBarAdmin, NavigatorBarSelectUser } from './layout/NavigatorBar.js';
import SelectionPersonComponent from './pages/SelectionPersonComponet.js';
import EditeMaster from './pages/Employees/EditeMaster.js';
import DefaultPage from './pages/Menus/DefaultPage.js';
import DefaultNavigatorBar from './layout/DefaultNavigatorBar.js';
import ControllerClients from './pages/Clients/ControllerClients.js';
import { errorRouter, routerFromAccountingGlobal, routerFromAdmin, routerFromAdminController, routerFromAdminNoticesController, routerFromClient, routerFromClientsGlobal, routerFromFinancialGlobal, routerFromLogin, routerFromLoginEmployee, routerFromMoreOptionsGlobal, routerFromMyStoreGlobal, 
  routerFromPerfilGlobal, routerFromSale, routerFromStockGlobal } from './routers/routerList.js';
import ControllerMyStore from './pages/myStore/ControllerMyStore.js'
import ControllerStock from './pages/Stock/ControllerStock.js'
import ControllerPerfil from './pages/Perfil/ControllerPerfil.js'
import ControllerAccounting from './pages/Accounting/ControllerAccounting.js'
import ControllerMoreOptions from './pages/More-Options/ControllerMoreOpitions.js'
import ControllerViewClient from "./pages/Clients/ViewClient/ControllerViewClient.js"
import {FooterSmall } from "./layout/Footer.js"
import Error404, { Error404NotLogged } from './pages/Error404.js'
import ControllerSale from './pages/Sale/ControllerSale.js';
import { jwtDecode } from 'jwt-decode';
import ConfirmationPopup from './Models/ConfirmationPopup.js';
import { useError, useLoading, useShowEditeEmployee, useShowExit, useShowPDF } from './AppContext.js';
import GetWarning from './layout/GetWarning.js';
import GlobalLoading from './Models/GlobalLoading.js';
import EditeEmployeeSimple from './pages/Employees/EditeEmployeeSimple.js';
import { employeeData } from './backEnd/InitController.js';
import style from './style/App.Module.css'
import PDFViewer from './pages/PDFViewer.js';
import { ControllerAdmin } from './pages/Admin/ControllerAdmin.js';
import { LoginAdmin } from './pages/LoginAdmin.js';
import { resetAll } from './backEnd/Memory.js';
import FinancialController from './pages/Financial/FinancialController.js';
import { ListBellNoticesAdmin } from './pages/Admin/ListBellNoticesAdmin.js';

const nameLogin ='tokenLogins'
const nameUser ='tokenUser'
const nameAdmin ='tokenAdmin'

function AppController(){

  const location = useLocation()

  const { showExit, setShowExit } = useShowExit()
  const { showEditeEmployee , setShowEditeEmployee} = useShowEditeEmployee()
  const { error, hideError } = useError()
  const { isLoading ,setIsLoading } = useLoading()
  //const { showShowPDF, urlPdf,setUrlPdf} = useShowPDF()


  const [isLoggedIn,setIsLoggedIn] = useState(localStorage.getItem(nameLogin))
  const [userLogeed,setUserLogeed] = useState(localStorage.getItem(nameUser))
  const [userLogeedAdmin,setUserLogeedAdmin] = useState(localStorage.getItem(nameAdmin))

  const navigate = useNavigate()

  

  useEffect(() => {
    handleStorageChange()
    checkTokenUser(nameLogin)
    checkTokenUser(nameUser)
    checkTokenUser(nameAdmin)
    
  }, [location])



  const handleStorageChange = () => {
    setIsLoggedIn(localStorage.getItem(nameLogin))
    setUserLogeed(localStorage.getItem(nameUser))
  }

  const handleStorageAdmin = () => {
    setUserLogeedAdmin(localStorage.getItem(nameAdmin))
  }

  const checkTokenUser =(name)=>{
    const token = localStorage.getItem(name)
    if(token){
      const userFromToken = jwtDecode(token)
      const tokenExpiration = userFromToken.exp;
      const currentTime = Date.now() / 1000;
      if (tokenExpiration < currentTime) {
          handleLogOut()
      }
    }
  }

  const clearLocalStorage = () =>{
    const email = localStorage.getItem(nameSaveEmail)
    localStorage.clear()
    window.sessionStorage.clear()
    window.localStorage.clear()
    resetAll()
    localStorage.setItem(nameSaveEmail, email)
  }

  const handleLogOut = ()=>{
    clearLocalStorage()
    navigate(routerFromLogin)
    setShowExit(false)
    setIsLoading(false)
}
 
  const getIsFullLogin = () => {
    return isLoggedIn && userLogeed
  }

  const getIsLoginAdmin = () => {
    return userLogeedAdmin
  }
 

  if(location.pathname.startsWith("/admin")){
    return(
      <>
        <NavigatorBarAdmin/>
        <Routes> 
          <Route exact path={routerFromAdmin} element={getIsLoginAdmin()? <Navigate to={routerFromAdminController} replace /> : <LoginAdmin handleLogin={handleStorageAdmin}/>} />
          <Route exact path={routerFromAdminController} element={getIsLoginAdmin()? <ControllerAdmin handleLogOut={handleLogOut}/>:<Navigate to={routerFromAdmin} replace />}/>
          <Route exact path={routerFromAdminNoticesController} element={getIsLoginAdmin()? <ListBellNoticesAdmin handleLogOut={handleLogOut}/>:<Navigate to={routerFromAdmin} replace />}/>
          <Route path="*" element={getIsLoginAdmin()? <Error404 /> : <Error404NotLogged/>} />
        </Routes>

        {error? <GetWarning
          title={error.title} 
          message={error.message } 
          onClose={hideError}/>: null
        }

        {
          showExit? <ConfirmationPopup
          title={'SAIR DO SISTEMA'} 
          message={'Tem Certeza que Deseja Sair do Sistema da Loja?'} 
          onCancel={()=>setShowExit(false)} onConfirm={handleLogOut}/>: null
        }

        {getIsLoginAdmin() ? null: <FooterSmall />}

      </>
    )
  }

  return(
      <div className={style.globalCss}>
          {(isLoggedIn) ? ( userLogeed ? <DefaultNavigatorBar/> : <NavigatorBarSelectUser/> ): <NavigatorBar/>}
          {isLoggedIn && ! userLogeed && <Navigate to={routerFromLoginEmployee} replace />}
          {!isLoggedIn && <Navigate to={routerFromLogin} replace />}
          
          <Routes> 
            <Route exact path="/" element={getIsFullLogin()? <Navigate to={routerFromSale} replace /> : <Navigate to={routerFromLogin} replace />} />
            <Route exact path={routerFromLogin} element={getIsFullLogin()? <Navigate to={routerFromSale} replace /> : <LoginComponent handleLogin={handleStorageChange}/>} />
            <Route exact path={routerFromLoginEmployee} element={isLoggedIn && !getIsFullLogin() ? <SelectionPersonComponent  handleLoginData={handleStorageChange}/>: <Navigate to={routerFromLogin} replace /> }/>
            <Route exact path="/about-more" element={getIsFullLogin() ? <SelectionPersonComponent />:<Navigate to={routerFromLogin} replace /> } />
            <Route exact path="/edite-master" element={ getIsFullLogin() ?  <EditeMaster />:<Navigate to={routerFromLogin} replace />}/>
            <Route exact path="/default-menu" element={getIsFullLogin() ? <DefaultPage/>: <Navigate to={routerFromLogin} replace />}/>
            <Route path={routerFromClientsGlobal} element={getIsFullLogin() ? <ControllerClients isLoggedIn={isLoggedIn}/>: <Navigate to={routerFromLogin} replace />}/>
            <Route path={routerFromMyStoreGlobal} element={getIsFullLogin() ? <ControllerMyStore isLoggedIn={isLoggedIn}/>: <Navigate to={routerFromLogin} replace />}/>
            <Route path={routerFromStockGlobal} element={getIsFullLogin() ? <ControllerStock isLoggedIn={isLoggedIn}/>: <Navigate to={routerFromLogin} replace />}/>
            <Route path={routerFromPerfilGlobal} element={getIsFullLogin() ? <ControllerPerfil isLoggedIn={isLoggedIn}/>: <Navigate to={routerFromLogin} replace />}/>
            <Route path={routerFromAccountingGlobal} element={getIsFullLogin() ? <ControllerAccounting isLoggedIn={isLoggedIn}/>: <Navigate to={routerFromLogin} replace />}/>
            <Route path={routerFromClient} element={getIsFullLogin() ? <ControllerViewClient isLoggedIn={isLoggedIn}/>: <Navigate to={routerFromLogin} replace />}/>
            <Route path={routerFromMoreOptionsGlobal} element={getIsFullLogin() ? <ControllerMoreOptions isLoggedIn={isLoggedIn}/>: <Navigate to={routerFromLogin} replace />}/>
            <Route path={errorRouter} element={getIsFullLogin() ? <Error404/>: <Navigate to={routerFromLogin} replace />}/>
            <Route path={routerFromFinancialGlobal} element={getIsFullLogin()? <FinancialController isLoggedIn={isLoggedIn} /> :  <Navigate to={routerFromLogin} replace />} />
            <Route path={routerFromSale} element={getIsFullLogin()? <ControllerSale isLoggedIn={isLoggedIn} /> :  <Navigate to={routerFromLogin} replace />} />
            <Route path="*" element={getIsFullLogin()? <Error404 /> : <Error404NotLogged/>} />
          </Routes>

          {error? <GetWarning
          title={error.title} 
          message={error.message } 
          onClose={hideError}/>: null}

          {showEditeEmployee ? <EditeEmployeeSimple employeeData={
            employeeData()
          } onSave={handleLogOut} onClose={()=>setShowEditeEmployee(false)}/> : null }

          {isLoading? <GlobalLoading/>: null}
          {/*{showShowPDF? <PDFViewer pdfUrl={urlPdf}/>: null}*/}

          {showExit? <ConfirmationPopup
          title={'SAIR DO SISTEMA'} 
          message={'Tem Certeza que Deseja Sair do Sistema da Loja?'} 
          onCancel={()=>setShowExit(false)} onConfirm={handleLogOut}/>: null}
          {isLoggedIn ? null : <FooterSmall />}
      </div>
  )
}

function App() {
  return (
    <Router>
      <AppController/>
    </Router>
  )
}


export default App;
