import React from "react";
import { TitlePageResponsiveIcon } from "../../layout/TitlePage";
import { faCalculator, faWarning } from "@fortawesome/free-solid-svg-icons";
import { GetButton } from "../../layout/ButtonsLayout";
import { useNavigate } from "react-router-dom";
import { routerFromAccountingGlobal } from "../../routers/routerList";
import style from '../../style/GetNeedUpdate.module.css'

function GetNeedUpdate(){
    const navigator = useNavigate()
    return(
        <div className={style.divGlobal}>
            <TitlePageResponsiveIcon subtitle={'É necessário Realizar a Contabilidade Antes.'}
            children={
                <GetButton responsive={ true}
                    button
                    width={300}
                    height={65}
                    title={'IR PARA PÁGINA DE CONTABILIDADE'}
                    color='#004AC0'
                    icon={faCalculator}
                    handleFunction={()=> navigator(routerFromAccountingGlobal)}
                    />
            }
            path={'Em Controle do Mês > Gerenciar Mês'} icon={faWarning} />
        
        </div>
    )
}

export default GetNeedUpdate