import { emit, findNFeSettings } from "../backEnd/Memory"
import { sendRequestPostLocal } from "../backEnd/requesition"
const _routerFromNFeSetInCloud = '/nfe/setNfeSettings'
const _routerFromNFeSettingsInCloud = '/nfe/getNfeSettings'

export const setFindNfeFromCloud = async (showError) => {
    var results = []
    try {
        results = await sendRequestPostLocal(_routerFromNFeSettingsInCloud)
        if(Array.isArray(results.data)){
        
            if(results.data.length > 0) emit.fromCloud(results.data[0])
        }else throw new Error(results.data.response.data.sqlMessage) 


    } catch (error) {
        //showError('ERROR AO BUSCAR DADOS DE CONFIGURAÇÃO DA NFe',error.message)
    }
}

export const setSetNfeFromCloud = async (emit,file,key,showError) => {
    var results = []
    try {      
        if (file && file instanceof File) {
            const certificateWithData = new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64Image = reader.result.split(',')[1];
                    const data = {
                        data: emit.getMapFromCloud(),
                        filePfx: base64Image,
                        password: key
                    }
                    resolve(sendRequestPostLocal(_routerFromNFeSetInCloud, data))
                }
                reader.onerror = error => reject(error)
                reader.readAsDataURL(file)
            })

            results = await certificateWithData;
            if (results.status !== 200) throw new Error(results.response.data.sqlMessage)
            findNFeSettings(showError);
            return results
        }

        results = await sendRequestPostLocal(_routerFromNFeSetInCloud,{data:emit.getMapFromCloud()})
        findNFeSettings(showError)
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO ATUALIZAR NFe EMISSOR',error.message)
        return {status:404}
    }
}