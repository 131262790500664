import React, { useEffect, useState } from "react"
import { TitlePageResponsive } from "../../layout/TitlePage"
import {
    faAddressBook, faAddressCard, faBagShopping, faBuildingColumns, faBuildingNgo, faCalculator, faCalendarDays, faDollarSign, faEnvelope, faFeatherPointed,
    faFilePdf,
    faGift, faHouseUser, faIcons, faIdBadge, faKey, faLocationArrow, faLock, faMapLocationDot,
    faMoneyBillTransfer, faMoneyBillTrendUp, faMoneyBillWheat, faMoneyCheckDollar, faNewspaper, faNoteSticky,
    faPaperclip, faPeopleArrows, faPercent, faPerson, faPersonBreastfeeding, faPersonChalkboard, faPhone, faPhoneVolume, faPiggyBank, faRing, faSave,
    faSitemap,
    faTreeCity, faUserGraduate, faUserTag, faUsersGear, faVault
} from "@fortawesome/free-solid-svg-icons"
import style from "../../style/AddEmployeeGeneric.module.css"
import GetFloatingButton, { GetFloatingButtonWithLegend } from "../../layout/GetFloatingButton"
import { GetSmallModels } from "../../Models/GetModels"
import StatesCountry from "../../layout/StatesCountry"
import InputPhoto from "../../Models/InputPhoto"
import { ToggleButtonWithContainer } from "../../layout/ToggleButton";
import DynamicPermissions from "../myStore/DynamicPermissions"
import ConfirmationPopup from "../../Models/ConfirmationPopup"
import Loader from "../../layout/Loader.js"
import { setSaveEmployee, setUpdateEmployee, setUpdatePasswordEmployee } from "../../controllers/CloudEmployee.js"
import Alert from "../../Models/Alert.js"
import { useNavigate } from "react-router-dom"
import { routerFromMyStoreEmployees } from "../../routers/routerList.js"
import { useError, useLoading } from "../../AppContext.js"
import { findEmployees, updateIfNull } from "../../backEnd/Memory.js"
import { GetButton, GetButtonIcon } from "../../layout/ButtonsLayout.js"
import { employeeData } from "../../backEnd/InitController.js"
import GetChangePasswordEmployee from "../../Models/GetChangePasswordEmployee.js"
import InputModelsBlock from "../../layout/InputModelsBlock.js"
import Space from "../../layout/Space.js"
import { Employee } from "../../objects/ClientsAndPerson/employee.js"

const permissionEmplopyees = (dataFromEmployee) => {
    var data = [
        {
            titleOn: 'Cadastrar Funcionários',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Editar Funcionários',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Dados dos Funcionários',
            initState: false,
            handleToggleChange: () => { },
        },
    ]


    for (let index = 0; index < data.length; index++) {
        data[index].initState = dataFromEmployee[index]
        data[index].handleToggleChange = (value) => { dataFromEmployee[index] = value }
    }

    return data
}

const permissionsItems = (dataFromEmployee) => {
    var data = [

        {
            titleOn: 'Editar Estoque',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Dados Básicos da Peça',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Valor de Custo da Peça',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Histórico  da Peça',
            initState: false,
            handleToggleChange: () => { },
        },
    ]

    for (let index = 0; index < data.length; index++) {
        data[index].initState = dataFromEmployee[index]
        data[index].handleToggleChange = (value) => { dataFromEmployee[index] = value }
    }

    return data
}

const permissionsSales = (dataFromEmployee) => {
    var data = [
        {
            titleOn: 'Efetuar Vendas',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Cancelar Vendas',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Modificar Vendas',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Editar Vendas de Todos os Funcionários',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Vendas de Todos os Funcionários',
            initState: false,
            handleToggleChange: () => { },
        },
    ]

    for (let index = 0; index < data.length; index++) {
        data[index].initState = dataFromEmployee[index]
        data[index].handleToggleChange = (value) => { dataFromEmployee[index] = value }
    }

    return data
}

const permissionsMyStore = (dataFromEmployee) => {
    var data = [
        {
            titleOn: 'Editar Dados Cadastrais da Loja',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Dados Cadastrais da Loja',
            initState: false,
            handleToggleChange: () => { },
        },
    ]

    for (let index = 0; index < data.length; index++) {
        data[index].initState = dataFromEmployee[index]
        data[index].handleToggleChange = (value) => { dataFromEmployee[index] = value }
    }

    return data
}

const permissionsClients = (dataFromEmployee) => {
    var data = [
        {
            titleOn: 'Editar Dados Cadastrais das Clientes',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Dados Cadastrais das Clientes',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Cadastrar Nova Cliente',
            initState: false,
            handleToggleChange: () => { },
        },
    ]

    for (let index = 0; index < data.length; index++) {
        data[index].initState = dataFromEmployee[index]
        data[index].handleToggleChange = (value) => { dataFromEmployee[index] = value }
    }

    return data
}

const permissionsFinancial = (dataFromEmployee) => {
    var data = [
        {
            titleOn: 'Visualizar Dados Financeiros',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Editar Dados Financeiros',
            initState: false,
            handleToggleChange: () => { },
        },
    ]

    for (let index = 0; index < data.length; index++) {
        data[index].initState = dataFromEmployee[index]
        data[index].handleToggleChange = (value) => { dataFromEmployee[index] = value }
    }

    return data
}


const permissionsContabilite = (dataFromEmployee) => {
    var data = [

        {
            titleOn: 'Visualizar Detalhes do Estoque',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Histórico Financeiro dos Meses',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Vendas Líquidas no Mês',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Vendas Brutas no Mês',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Total Cancelado no Mês',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Lista de Todas as Peças Vendidas no Mês',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Total de Reposições no Mês',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Lista de Recebimentos',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Lista de Vendas por Fatores',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Lista de Comissões',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Lista de Recebimentos Futuros',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Extrato Final do Mês',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Contabilidade Geral',
            initState: false,
            handleToggleChange: () => { },
        },
        {
            titleOn: 'Visualizar Extratos Gerais',
            initState: false,
            handleToggleChange: () => { },
        },
    ]

    for (let index = 0; index < data.length; index++) {
        data[index].initState = dataFromEmployee[index]
        data[index].handleToggleChange = (value) => { dataFromEmployee[index] = value }
    }

    return data
}

const getFormData = (dataEmployee) => {
    return {
        isMaster: dataEmployee.isMaster || false,
        linkImage: dataEmployee.linkImage || '',
        keycode: dataEmployee.keycode || '',
        idEmployee: dataEmployee.idEmployee || '',
        function: dataEmployee.function || '',
        percentComission: dataEmployee.percentComission || 0,
        status: dataEmployee.status || '',
        comissionFixed : dataEmployee.comissionFixed || 0, 

        idEmployeePermission: dataEmployee.employeePermission.idEmployeePermission || 0,
        idSimpleContact: dataEmployee.contact.idSimpleContact || '',
        number1: dataEmployee.contact.number1 || '',
        number2: dataEmployee.contact.number2 || '',
        email: dataEmployee.contact.email || '',

        idPerson: dataEmployee.genericPerson.idPerson || '',
        name: dataEmployee.genericPerson.name || '',
        cpf: dataEmployee.genericPerson.cpf || '',
        rg: dataEmployee.genericPerson.rg || '',
        dateBorn: dataEmployee.genericPerson.dateBorn || '',
        nameSpouse: dataEmployee.genericPerson.nameSpouse || '',
        rgGovernationEmission: dataEmployee.genericPerson.rgGovernationEmission || '',
        rgDateEmission: dataEmployee.genericPerson.rgDateEmission || '',
        profession: dataEmployee.genericPerson.profession || '',
        nameFather: dataEmployee.genericPerson.nameFather || '',
        nameMother: dataEmployee.genericPerson.nameMother || '',

        idAddress: dataEmployee.address.idAddress || '',
        state: dataEmployee.address.state || '',
        city: dataEmployee.address.city || '',
        address: dataEmployee.address.address || '',
        cep: dataEmployee.address.cep || '',

        /*idGovernmentData:dataEmployee.company.idGovernmentData  || '',
        socialReason: dataEmployee.company.socialReason || '',
        fantasyName:  dataEmployee.company.fantasyName ||'',
        cnpj:  dataEmployee.company.cnpj ||'',
        simplesNational: dataEmployee.company.simplesNational || '',
        subscriptionState:  dataEmployee.company.subscriptionState ||'',*/

        idBank: dataEmployee.bank.idBank || '',
        account: dataEmployee.bank.account || '',
        agency: dataEmployee.bank.agency || '',
        ownerAccount: dataEmployee.bank.ownerAccount || '',
        bankName: dataEmployee.bank.bankName || '',
        typeAccount: dataEmployee.bank.typeAccount || '',
        keycodePix: dataEmployee.bank.keycodePix || '',
    }
}

const getFormNeed = () => {
    return {
        keycode: false,
        function: false,
        percentComission: false,
        name: false,
    }
}

function AddEmployeeGeneric({ employeeDataControle, onClose, onSave }) {
    const navigate = useNavigate()

    const [canEdite] = useState(employeeData().employeePermission.permissionsEmployers[1])

    const [employeeTemp] = useState(employeeDataControle ?? new Employee())
    const [toggleStatus, setToggleStatus] = useState(employeeTemp.status)

    const [formData, setFormData] = useState(getFormData(employeeTemp))
    const [needData, setNeedData] = useState(getFormNeed)

    const [pFinancial] = useState(employeeTemp.employeePermission.permissionsFinancial.slice())
    const [pEmployees] = useState(employeeTemp.employeePermission.permissionsEmployers.slice())
    const [pItems] = useState(employeeTemp.employeePermission.permissionsItems.slice())
    const [pSales] = useState(employeeTemp.employeePermission.permissionsSales.slice())
    const [pMyStore] = useState(employeeTemp.employeePermission.permissionsMyStore.slice())
    const [pClients] = useState(employeeTemp.employeePermission.permissionsClients.slice())
    const [pContabilite] = useState(employeeTemp.employeePermission.permissionsContabilite.slice())

    const [isVisible, setAlert] = useState(false)
    const [showConfirmSave, setShowConfirmSave] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedImage] = useState(formData.linkImage)
    const [selectedImageFromFile, setSelectedImageFromFile] = useState(null)
    const [showChangePassword, setShowChangePassword] = useState(false)

    const { setIsLoading } = useLoading()
    const { showError } = useError()


    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            await updateIfNull(showError)  
            setLoading(false) 
        }
        fetchData()
    }, [])


    const handleToggleChangeStatus = (newToggleStatus) => {
        setToggleStatus(newToggleStatus)
    }

    const handleChange = (e) => {
        if (e !== '') {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleConfirmSave = async () => {
        setShowConfirmSave(false)
        setLoading(true)
        setIsLoading(true)
        var newEmployee = new Employee()
        newEmployee.fromEdite(formData, onSave ? toggleStatus : true, pItems, pContabilite, pClients, pEmployees, pMyStore, pSales,pFinancial)

        var result
        if (onSave) {
            result = await setUpdateEmployee(selectedImageFromFile, newEmployee, showError)
        } else {
            result = await setSaveEmployee(selectedImageFromFile, newEmployee, showError)
        }

        if (result.status === 200) {
            if (employeeDataControle) {
                onClose()
                onSave()
            } else {
                await findEmployees()
                navigate(routerFromMyStoreEmployees)
            }
        } else {
            setLoading(false)
        }

        setIsLoading(false)
    }

    const handleSubmitSave = () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if (key in needData) {
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            }
        })

        if (allFieldsEmpty) {
            setAlert(true)
            return
        }

        setShowConfirmSave(true)
    }

    const handlerSlectedImageFromFile = (image) => {
        setSelectedImageFromFile(image)
    }

    const handleChangePassword = async(value)=>{

        setLoading(true)
        const result = await setUpdatePasswordEmployee(value.password,employeeTemp.idEmployee,showError)
        setLoading(false)

        if(result.status === 200){
            showError('SENHA TROCADA COM SUCESSO','Você Modificou a Senha de Acesso')
            return true
        }
        else return false
    }

    const handlePdf = async()=>{
        setLoading(true)
        await employeeTemp.openPDFEmployee(showError)
        setLoading(false)
    }


    return (
        <div className={style.containerGlobal}>
            {!loading ?
                (<>
                    <div className={style.title}>
                    <TitlePageResponsive title={'Dados'} subtitle={"Informações Pessoais do Funcionário"}
                        path={"Adicione o Máximo de Informações Possiveis do Seu Funcionário..."} />
                    {onSave ?
                        <> {canEdite?
                            <>
                                {window.innerWidth > 1200? 
                                    <div className={style.containerStatus}>
                                        <GetButton title={'TROCAR SENHA'}
                                        handleFunction={()=>setShowChangePassword(true)}
                                        icon={faKey} color={"#ff0000"} />
                                        <Space left={5}/>
                                        <GetButtonIcon
                                        height={55}
                                        handleFunction={handlePdf}
                                        icon={faFilePdf} color={"#007bff"} />
                                    </div>
                                :null}
                                <div className={style.containerStatus}>
                                    <ToggleButtonWithContainer titleOn={'ATIVO'} titleOff={'DESATIVO'}
                                        initState={toggleStatus} onChange={handleToggleChangeStatus} />
                                    {window.innerWidth < 1200? 
                                        <>
                                            <Space left={15}/>
                                            <GetButtonIcon 
                                            handleFunction={()=>setShowChangePassword(true)}
                                            icon={faKey} color={"#ff0000"} />
                                            <Space left={5}/>
                                            <GetButtonIcon 
                                            handleFunction={handlePdf}
                                            icon={faFilePdf} color={"#007bff"} />
                                        </>
                                        :null}
                                </div>
                                </>: <div className={style.divLock}>
                                <GetSmallModels icon={faLock} title={'ACESSO PARCIALMENTE NEGADO'} 
                                subtitle={'Você Não Tem Permissão para Editar Dados dos Funcionários'}/> 
                            </div>}
                        </>
                        :null
                    }
                </div>
                    <div className={style.containerRow}>
                        <div className={style.containerDataLogo}>
                            <GetSmallModels title={"Foto do Funcionário"} subtitle={"É o Icone do Funcionário no Sistema..."} icon={faIcons} />
                            <div className={style.iconBrand}>
                                <InputPhoto disable={!canEdite} handlerFileSelected={handlerSlectedImageFromFile}
                                    initialPhoto={selectedImage} />
                            </div>
                        </div>
                        <div className={style.containerData}>
                            <GetSmallModels title={"DADOS BÁSICOS"} subtitle={"São os Principais Dados do Funcionário..."} icon={faUsersGear} />
                            <div className={style.divRow}>
                                <InputModelsBlock legend={'NOME DO FUNCIONÁRIO'} onChange={handleChange} disable={!canEdite} validation={needData.name} name={'name'} value={formData.name} iconButton={faIdBadge} maxLength={100} type={"text"} placeholder={"Nome do Funcionário..."} />
                                <InputModelsBlock legend={'CARGO NA LOJA'} onChange={handleChange} disable={!canEdite} validation={needData.function} name={'function'} value={formData.function} iconButton={faUserGraduate} maxLength={45} type={"text"} placeholder={"Cargo ou Função do Funcionário..."} />
                                {onSave ? null:<InputModelsBlock legend={'SENHA DE ACESSO'} onChange={handleChange} validation={needData.keycode} name={'keycode'} value={formData.keycode} iconButton={faKey} type={"text"} maxLength={50} placeholder={"Senha do Funcionário..."} />}
                                <InputModelsBlock legend={'% DE COMISSÃO POR VENDA'} onChange={handleChange} disable={!canEdite} validation={needData.percentComission} name={'percentComission'} value={formData.percentComission} iconButton={faPercent} maxLength={5} type={"text"} placeholder={"Porcentagem de Comissão Por Venda..."} />
                                <InputModelsBlock legend={'COMISSÃO FIXA EM REAIS (R$)'} onChange={handleChange} disable={!canEdite} validation={needData.comissionFixed} name={'comissionFixed'} value={formData.comissionFixed} iconButton={faDollarSign} maxLength={5} type={"number"} placeholder={"Comissão Fixa..."} />
                            </div> 
                        </div>
                    </div>
                    <div className={style.containerDataDefault}>
                            <div className={style.containerData}>
                                <GetSmallModels title={"DADOS PESSOAIS"} subtitle={"São os Dados Pessoais..."} icon={faPersonChalkboard} />
                                <div className={style.divRow}>
                                    <InputModelsBlock legend={'DATA DE NASCIMENTO'} onChange={handleChange} disable={!canEdite} name={'dateBorn'} value={formData.dateBorn} iconButton={faGift} type={"date"} maxLength={12} placeholder={"Data de Nascimento..."} />
                                    <InputModelsBlock legend={'NOME DO PAI'} onChange={handleChange} disable={!canEdite} name={'nameFather'} value={formData.nameFather} iconButton={faFeatherPointed} maxLength={100} type={"text"} placeholder={"Nome do Pai..."} />
                                    <InputModelsBlock legend={'NOME DA MÃE'} onChange={handleChange} disable={!canEdite} name={'nameMother'} value={formData.nameMother} iconButton={faPersonBreastfeeding} maxLength={100} type={"text"} placeholder={"Nome da Mãe..."} />
                                    <InputModelsBlock legend={'NOME DO CÔNJUGE'} onChange={handleChange} disable={!canEdite} name={'nameSpouse'} value={formData.nameSpouse} iconButton={faRing} type={"text"} maxLength={100} placeholder={"Nome do Cônjuge..."} />
                                </div>
                            </div>
                            <div className={style.containerData}>
                                <GetSmallModels title={"CPF / RG"} subtitle={"São os Dados Governamentais..."} icon={faPaperclip} />
                                <div className={style.divRow}>
                                    <InputModelsBlock legend={'RG'} onChange={handleChange} disable={!canEdite} name={'rg'} value={formData.rg} iconButton={faNoteSticky} type={"text"} maxLength={45} placeholder={"RG..."} />
                                    <InputModelsBlock legend={'CPF'} onChange={handleChange} disable={!canEdite} name={'cpf'} value={formData.cpf} iconButton={faNewspaper} type={"text"} maxLength={15} placeholder={"CPF..."} />
                                    <InputModelsBlock legend={'DATA DE EMISSÃO'} onChange={handleChange} disable={!canEdite} name={'rgDateEmission'} value={formData.rgDateEmission} iconButton={faCalendarDays} maxLength={12} type={"date"} placeholder={"Data de Emissão do RG..."} />
                                    <InputModelsBlock legend={'ÓRGÃO EXPEDIDOR'} onChange={handleChange} disable={!canEdite} name={'rgGovernationEmission'} value={formData.rgGovernationEmission} iconButton={faBuildingNgo} maxLength={50} type={"text"} placeholder={"órgão Expedidor do RG..."} />
                                </div>
                            </div>
                        <div className={style.containerData}>
                            <GetSmallModels title={"CONTATOS"} subtitle={"São os Meios de Contato..."} icon={faAddressBook} />
                            <div className={style.divRow}>
                                <InputModelsBlock legend={'E-MAIL'} onChange={handleChange} disable={!canEdite} name={'email'} value={formData.email} iconButton={faEnvelope} maxLength={90} type={"email"} placeholder={"E-mail..."} />
                                <InputModelsBlock legend={'TELEFONE'} onChange={handleChange} disable={!canEdite} name={'number1'} value={formData.number1} iconButton={faPhoneVolume} maxLength={15} type={"tel"} placeholder={"Telefone..."} />
                                <InputModelsBlock legend={'TELEFONE SECUNDARIO'} onChange={handleChange} disable={!canEdite} name={'number2'} value={formData.number2} iconButton={faPhone} maxLength={15} type={"tel"} placeholder={"Telefone Secundario..."} />
                            </div>
                        </div>
                        <div className={style.containerData}>
                            <GetSmallModels title={"ENDEREÇO"} subtitle={"É o Endereço da Cliente..."} icon={faMapLocationDot} />
                            <div className={style.divRow}>
                                <InputModelsBlock legend={'CIDADE'} onChange={handleChange} disable={!canEdite} name={'city'} value={formData.city} iconButton={faTreeCity} maxLength={45} type={"text"} placeholder={"Cidade..."} />
                                <InputModelsBlock legend={'ENDEREÇO'} onChange={handleChange} disable={!canEdite} name={'address'} value={formData.address} iconButton={faLocationArrow} maxLength={150} type={"text"} placeholder={"Endereço..."} />
                                <InputModelsBlock legend={'CEP'} onChange={handleChange} disable={!canEdite} name={'cep'} value={formData.cep} iconButton={faAddressCard} maxLength={15} type={"number"} placeholder={"Cep..."} />
                                <div className={style.dropDownState}>
                                    <StatesCountry disable={!canEdite} initialState={formData.state.toUpperCase()} handlerFuncion={handleChange}
                                        name={'state'} />
                                </div>
                            </div>
                        </div>
                        <div className={style.containerData}>
                            <GetSmallModels title={"DADOS BANCÁRIOS"} subtitle={"São os Dados Bancários"} icon={faBuildingColumns} />
                            <div className={style.divRow}>
                                <InputModelsBlock legend={'NOME DO BANCO'} onChange={handleChange} disable={!canEdite} name={'bankName'} value={formData.bankName} iconButton={faMoneyCheckDollar} maxLength={55} type={"text"} placeholder={"Nome do Banco..."} />
                                <InputModelsBlock legend={'TIPO DE CONTA'} onChange={handleChange} disable={!canEdite} name={'typeAccount'} value={formData.typeAccount} iconButton={faMoneyBillTrendUp} maxLength={25} type={"text"} placeholder={"Tipo de Conta"} />
                                <InputModelsBlock legend={'AGÊNCIA'} onChange={handleChange} disable={!canEdite} name={'agency'} value={formData.agency} iconButton={faMoneyBillTransfer} maxLength={55} type={"number"} placeholder={"Agência..."} />
                                <InputModelsBlock legend={'NÚMERO DA CONTA'} onChange={handleChange} disable={!canEdite} name={'account'} value={formData.account} iconButton={faPiggyBank} type={"number"} maxLength={55} placeholder={"Número da Conta..."} />
                                <InputModelsBlock legend={'TITULAR DA CONTA'} onChange={handleChange} disable={!canEdite} name={'ownerAccount'} value={formData.ownerAccount} iconButton={faPerson} type={"text"} maxLength={55} placeholder={"Titular da Conta..."} />
                                <InputModelsBlock legend={'CÓDIGO DO PIX'} onChange={handleChange} disable={!canEdite} name={'keycodePix'} value={formData.keycodePix} iconButton={faVault} type={"text"} maxLength={260} placeholder={"Código do Pix..."} />
                            </div>
                        </div>
                    </div>
                    <div className={style.permissions}>
                        <TitlePageResponsive title={'Permissões'} subtitle={"Configurar Permissões do Funcionário"}
                            path={"É Você pode Alterar as Permissões do Seu Funcionário..."} />
                        <div className={style.containerDataDefault}>
                            <DynamicPermissions disable={!canEdite} legeng={'São as Permissões Relacionadas aos Funcionários...'}
                                title={'Permissões Sobre Funcionários'} iconTitle={faUserTag}
                                listToggle={permissionEmplopyees(pEmployees)} />
                            <DynamicPermissions disable={!canEdite} legeng={'São as Permissões Relacionadas a Edição de Peças...'}
                                title={'Permissões Sobre Peças'} iconTitle={faSitemap}
                                listToggle={permissionsItems(pItems)} />
                            <DynamicPermissions disable={!canEdite} legeng={'São as Permissões Relacionadas as Vendas...'}
                                title={'Permissões de Vendas'} iconTitle={faBagShopping}
                                listToggle={permissionsSales(pSales)} />
                        </div>
                        <div className={style.containerDataDefault}>
                            <DynamicPermissions disable={!canEdite} legeng={'São as Permissões Relacionadas a Edição da Loja...'}
                                title={'Permissões da Loja'} iconTitle={faHouseUser} listToggle={permissionsMyStore(pMyStore)} />
                            <DynamicPermissions disable={!canEdite} legeng={'São as Permissões Relacionadas a Edição dos Clientes...'}
                                title={'Permissões Sobre Clientes'}
                                iconTitle={faPeopleArrows} listToggle={permissionsClients(pClients)} />
                        </div>
                        <div className={style.containerDataDefault}>
                            <DynamicPermissions disable={!canEdite} legeng={'São as Permissões do Financeiro...'}
                                title={'Permissões do Financeiro'} iconTitle={faMoneyBillWheat}
                                listToggle={permissionsFinancial(pFinancial)} />
                        </div>
                        <div className={style.containerDataDefault}>
                            <DynamicPermissions disable={!canEdite} legeng={'São as Permissões Relacionadas a Contabilidade e Valores...'}
                                title={'Permissões de Contabilidades'} iconTitle={faCalculator}
                                listToggle={permissionsContabilite(pContabilite)} />
                        </div>
                    </div>
                </>) : (<Loader titleFinding={'Buscando Dados do Funcionario...'}
                    subtitleFinding={'Aguarde...'} loading={loading} />)}
            {canEdite && !loading?
                <>
                    {   window.innerWidth > 1200 ?
                        <GetFloatingButtonWithLegend legend={'SALVAR'} icon={faSave}color='#007bff' margiRight={'1%'} margiBottom={'1%'} handleFunction={handleSubmitSave} />:
                        <GetFloatingButton icon={faSave} color='#007bff' margiBottom={'1%'}  width={65} height={65} margiRight={'1%'}  handleFunction={handleSubmitSave} />
                    }
                </>
            
            :null}
            {showChangePassword ? <GetChangePasswordEmployee handleCheck={handleChangePassword} handleClose={()=>setShowChangePassword(false)}/> : null}
            {showConfirmSave ? <ConfirmationPopup title={
                Employee ? 'SALVAR EDIÇÃO DO FUNCIONÁRIO' : 'CADASTRAR NOVO FUNCIONÁRIO'
            }
                message={
                    Employee ? 'Tem a Certeza que Deseja Salvar a Edição do Funcionário?' :
                        'Tem a Certeza que Deseja Cadastrar um Novo Funcionário?'
                }
                onCancel={() => setShowConfirmSave(false)} onConfirm={handleConfirmSave} /> : null}
            {isVisible ? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} /> : null}
        </div>
    )
}

export default AddEmployeeGeneric