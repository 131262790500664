import React, { useState } from "react"
import style from "../../../style/ViewProduct.module.css"
import {
    faArrowUpWideShort, faAudioDescription, faBarcode,
    faClose, faFileAlt, faFilePdf, faHandHoldingDollar, faIdCardClip, faListNumeric, faLock, faMoneyCheckDollar,
    faRandom,
    faSave, faSignature
} from "@fortawesome/free-solid-svg-icons";
import { TitlePageResponsiveIcon } from "../../../layout/TitlePage";
import { GetButton, GetButtonIcon } from "../../../layout/ButtonsLayout";
import InputModels from "../../../Models/InputModels";
import { faIntercom } from "@fortawesome/free-brands-svg-icons";
import Space from "../../../layout/Space";
import ViewLastEdition from "../ViewLastEditon";
import ConfirmationPopup from "../../../Models/ConfirmationPopup";
import Alert from "../../../Models/Alert";
import Loader from "../../../layout/Loader";
import InputPhoto from "../../../Models/InputPhoto";
import { setUpdateProduct } from "../../../controllers/CloudProducts";
import { ToggleButtonWithContainer } from "../../../layout/ToggleButton";
import { useError } from "../../../AppContext";
import { employeeData } from "../../../backEnd/InitController";
import { GetSmallModels } from "../../../Models/GetModels";
import GetAds from "../../../Models/GetAds";
import { GetMeasurement } from "./NewProduct";
import InputModelsBlock from "../../../layout/InputModelsBlock";
import { listProductsMemory } from "../../../backEnd/Memory";

const getFormData = (product) => {
    return {
        idGenericProducts: product.idGenericProducts,
        nameProduct: product.nameProduct,
        amount: product.amount,
        codebar: product.codebar,
        linkImage: product.linkImage,
        descriptionProduct: product.descriptionProduct,
        valueToSale: product.valueToSale,
        valueCoast: product.valueCoast,
        status: product.status,
        unitMeasurement:product.measurement.unitMeasurement,
        category:product.measurement.category,
        height:product.measurement.height,
        width:product.measurement.width,
        thickness:product.measurement.thickness,
        weight:product.measurement.weight,
        NCM:product.NCM,
    }
}

const configureSave = (product, formData, status) => {
    product.nameProduct = formData.nameProduct
    product.amount = formData.amount
    product.codebar = formData.codebar
    product.descriptionProduct = formData.descriptionProduct
    product.valueCoast = formData.valueCoast === ''? 0 : parseFloat(formData.valueCoast)
    product.valueToSale = formData.valueToSale
    product.measurement.fromEdite(formData)
    product.NCM = formData.NCM
    product.status = status
}

const getFormNeed = () => {
    return {
        nameProduct: false,
        amount: false,
        valueToSale: false,
    }
}

function ViewProducts({ product, handleClose, handleRefresh }) {

    const [toggleStatus, setToggleStatus] = useState(product.status)
    const [selectedImage] = useState(product.linkImage)
    const [selectedImageFromFile, setSelectedImageFromFile] = useState(null)

    const [employeeUser] = useState(employeeData())
    const [canViewHistory] = useState(employeeUser.employeePermission.permissionsItems[3])
    const [canEditeStock] = useState(employeeUser.employeePermission.permissionsItems[0])
    const [canViewBasicData] = useState(employeeUser.employeePermission.permissionsItems[1])
    const [canViewCoast] = useState(employeeUser.employeePermission.permissionsItems[2])

    const { showError } = useError()
    const [isVisible, setAlert] = useState(false)
    const [showLastVersion, setShowLastVersion] = useState(false)
    const [formData, setFormData] = useState(getFormData(product))
    const [needData, setNeedData] = useState(getFormNeed)
    const [showConfirmSave, setShowConfirmSave] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showAds , setShowAds] = useState(false)

    const handleChange = (e) => {
        if (e !== '') {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSave = async () => {
        setShowConfirmSave(false)
        setLoading(true)
        configureSave(product, formData, toggleStatus)
        var result = await setUpdateProduct(selectedImageFromFile,
            product, showError)
        if (result.status === 200) {
            handleRefresh()
            handleClose()
        }

        setLoading(false)
    }

    const handleSubmitSave = () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if (key in needData) {
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            }
        })

        if (allFieldsEmpty) {
            setAlert(true)
            return
        }

        setShowConfirmSave(true)
    }

    const handlerSlectedImageFromFile = (image) => {
        setSelectedImageFromFile(image)
    }

    const handleToggleChangeStatus = (newToggleStatus) => {
        setToggleStatus(newToggleStatus)
    }

    const generateBarcode = () =>{
        let codigo = '';
        for (let i = 0; i < 12; i++) {
            codigo += Math.floor(Math.random() * 10); 
        }
        const index = listProductsMemory.findIndex(ss => ss.codebar.toString() === codigo.toString())
        if(index === -1 ) setFormData({ ...formData, ['codebar']: codigo })
        else generateBarcode()
    }

    return (
        <div className={style.overlay}>
            <div className={style.alert}>
                {!loading ? <> <div className={style.title}>
                    <TitlePageResponsiveIcon title={product.nameProduct}
                        children={
                            <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose} />
                        }
                        path={'ID no Servidor: ' + product.idGenericProducts} icon={faIntercom} />
                    <Space left={5}/>
                    <div className={style.containerStatus}>
                        <ToggleButtonWithContainer disable={!canEditeStock} titleOn={'ATIVO'} titleOff={'DESATIVO'}
                            initState={toggleStatus} onChange={handleToggleChangeStatus} />
                    </div>
                </div>

                   { canViewBasicData? <>
                        <div className={style.divRow}>
                            <div className={style.div}>
                                <InputModels disable={!canEditeStock} onChange={handleChange}
                                    validation={needData.nameProduct} maxLength={50}
                                    value={formData.nameProduct} name={'nameProduct'}
                                    iconButton={faSignature} type={"text"} placeholder={"Nome do Produto..."} />
                                <InputModels disable={!canEditeStock} onChange={handleChange}
                                    maxLength={250} value={formData.descriptionProduct} name={'descriptionProduct'}
                                    iconButton={faAudioDescription} type={"text"} placeholder={"Descrição do Produto..."} />
                                <InputModels disable={!canEditeStock} onChange={handleChange}
                                    validation={needData.amount} maxLength={10}
                                    value={formData.amount} name={'amount'}
                                    iconButton={faArrowUpWideShort} type={"number"} placeholder={"Quantidade do Produto em Estoque..."} />
                                <InputModels disable={!canEditeStock} onChange={handleChange}
                                    validation={needData.valueToSale} maxLength={10}
                                    value={formData.valueToSale} name={'valueToSale'}
                                    iconButton={faMoneyCheckDollar} type={"number"} placeholder={"Valor de Venda do Produto..."} />
                                {canViewCoast ? <InputModels disable={!canEditeStock} onChange={handleChange}
                                    maxLength={10} value={formData.valueCoast} name={'valueCoast'}
                                    iconButton={faHandHoldingDollar} type={"number"} placeholder={"Valor de Custo do Produto..."} />:null}
                            </div>
                            <Space left={5} />
                            <div className={style.div}>
                                <div className={style.divRow}>
                                    <div className={style.divInput}>
                                        <InputModels disable={!canEditeStock} onChange={handleChange}
                                        maxLength={15} value={formData.codebar} name={'codebar'}
                                        iconButton={faBarcode} type={"number"} placeholder={"Código de Barras..."} />
                                    </div>
                                    <Space left={10}/>
                                    <div className={style.divButton}>
                                        <GetButtonIcon icon={faBarcode} handleFunction={generateBarcode} color={"#00ff00"} width={50} height={50}/>                              
                                    </div>
                                </div>
                                <Space top={5}/>
                                 <InputModelsBlock 
                                    legend={'NCM - PARA ESSE PRODUTO'} 
                                    onChange={handleChange} 
                                    validation={needData.NCM} 
                                    maxLength={20} 
                                    value={formData.NCM} 
                                    name={'NCM'} 
                                    iconButton={faIdCardClip} 
                                    type={"number"}  
                                    placeholder={"Nomenclatura Comum do Mercosul... "}  
                                />  
                                <GetMeasurement handleChange={handleChange} formData={formData} />
                            </div>
                        </div>
                        <div className={style.divRow}>
                            <div className={style.divImage}>
                                <InputPhoto disable={!canEditeStock} titleButton={'Selecionar Foto'} initialPhoto={selectedImage}
                                    handlerFileSelected={handlerSlectedImageFromFile} />
                            </div>
                            <Space left={5} />
                            <div className={style.divButtons}>
                                {!canEditeStock ? null : <GetButton title={"SALVAR EDIÇÃO"} color='#189001' icon={faSave} handleFunction={handleSubmitSave} />}
                                <Space top={5}/>
                                {!canViewHistory ? null : <GetButton title={"RELATÓRIO DE EDIÇÕES"} color='#007bff' icon={faFileAlt} handleFunction={() => setShowLastVersion(true)} />}
                                <Space top={5}/>
                                <GetButton title={"GERAR ANÚNCIO DE DIVULGAÇÃO"} color='#007bff' icon={faFilePdf} handleFunction={()=>setShowAds(true)} />
                            </div>
                        </div>
                    </>:
                    <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                            subtitle={'Você Não Tem Permissão para Visualizar as Iformações dos Produtos'} />}
                </> : <Loader titleFinding={'Trabalhando no Servidor...'}
                    subtitleFinding={'Aguarde...'} loading={loading} />}
                {showLastVersion ? <ViewLastEdition isGeneric={true}
                    last={product}
                    handleClose={() => setShowLastVersion(false)} /> : null}
                {isVisible ? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} /> : null}
                {showAds ? <GetAds name={product.nameProduct} imageLink={product.linkImage} value={product.valueToSale} onClose={()=>setShowAds(false)}/> : null}
                {showConfirmSave ? <ConfirmationPopup title={'SALVAR EDIÇÃO DE PEÇA'}
                    message={'Tem Certeza que Deseja Salvar Edição Dessa Peça?'} onCancel={() => setShowConfirmSave(false)} onConfirm={handleSave} /> : null}
            </div>
        </div>
    )
}

export default ViewProducts