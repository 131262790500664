import React, { useState, useEffect, useRef } from 'react';
import style from "../../style/ListClients.module.css"
import { GetSmallModels } from '../../Models/GetModels.js';
import { faMagnifyingGlass,faCircleInfo, faList, faLock} from '@fortawesome/free-solid-svg-icons';
import InputModels from "../../Models/InputModels.js"
import GetAmountList from '../../Models/GetAmountList.js';
import Space from '../../layout/Space.js';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage.js';
import { makeAccounting } from '../../objects/Accounting/MakeAccounting.js';
import GetNeedUpdate from './GetNeedUpdate.js';
import GetReceivers from '../../Models/GetReceivers.js';
import { employeeData } from '../../backEnd/InitController.js';

function ListOfReceivers() {

  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])
  const [canView] = useState(employeeData().employeePermission.permissionsContabilite[7])
  const [filtered, setFiltered] = useState(makeAccounting.accountingAllReceiversThisMoth.listPaidSalesThisMoth)
  const [orderBy, setOrderBy] = useState('asc')

  useEffect(() => {
    handleChangeFilter()
  }, [orderBy])

  const controllerFilter = () => {
    let sorted = [...makeAccounting.accountingAllReceiversThisMoth.listPaidSalesThisMoth]
    switch (orderBy) {
      case 'asc':
        sorted.sort((a, b) => a.codeSale.toString().localeCompare(b.codeSale))
        break
      case 'desc':
        sorted.sort((a, b) => b.codeSale.toString().localeCompare(a.codeSale))
        break
      default:
        break
    }
    return sorted
  }

  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()

    resetScroll()
    if(value!= null){
      const filtered = data.filter(
        sale => {
          const filterName = sale.codeSale.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterValue = sale.totalValuePaid.toString().toLowerCase().includes(value.target.value.toLowerCase())
          return filterValue || filterName
        }
      )
      setFiltered(filtered)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    if(container){
      container.scrollTop = 0
      setLimit(50)
    }
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  return (
    <div className={style.containerGeneral}>
       <div className={style.containerGlobal}>  
          {canView? <>{makeAccounting.isCalculated === true ? 
           <>
              <div className={style.titleMenu}>     
                   {window.innerWidth > 1200? <TitlePageResponsiveIcon subtitle={'Lista de Recebimentos no Período de Busca'}  
                  path={'São Todas as Venda com Recebimentos Cadastrados no Período de Busca...'} icon={faList}/>:null}
                  <Space left={5}/>
                  { window.innerWidth > 1200?
                    <div className={style.buidWithText}>
                      <GetSmallModels title={'TOTAL RECEBIDO: '} value={makeAccounting.accountingAllReceiversThisMoth.getValueTotalPaid()}/>
                      <Space left={5}/>
                      <GetSmallModels title={'QUANTIDADE DE PAGAMENTOS: '} value={makeAccounting.accountingAllReceiversThisMoth.amountTotalPaid}/>
                    </div>:null
                  }
              </div>
              { window.innerWidth <= 1200?
                <div className={style.buidWithText}>
                    <GetSmallModels title={'TOTAL RECEBIDO: '} value={makeAccounting.accountingAllReceiversThisMoth.getValueTotalPaid()}/>
                    <GetSmallModels title={'QUANTIDADE DE PAGAMENTOS: '} value={makeAccounting.accountingAllReceiversThisMoth.amountTotalPaid}/>
                </div>:null
              }
              {window.innerWidth <= 1200? <Space top={5}/>:null}
              <div className={style.buildList}>
              <div className={style.filter}>
                  <div className={style.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} 
                    type={"text"} placeholder={"Buscar Recebimentos Registrado..."}
                    onChange={handleChangeFilter}/>
                  </div>
                  <div className={style.containerDropDown}>
                    <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                        <option value="asc">CRESCENTE</option>
                        <option value="desc">DESCRESCENTE</option>
                    </select>
                  </div>
              </div>
              <Space right={2} left={2} top={5} bottom={0}/> 
              {(filtered.length > 0) ?<div className={style.buildList}>
                  <GetAmountList amount={filtered.length} title={"Recebimentos Registrados na Busca..."}/>     
                <Space right={0} left={0} top={0} bottom={10}/> 
              </div> : null}
              <div ref={containerRef} className={style.containerListForced} onScroll={checkScroll}>
                  {(filtered.length === 0)?
                    <GetSmallModels title={"Não há Recebimento com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo} />:
                    filtered.slice(0, limit).map((paidSale, index) => (
                       <GetReceivers paidSale={paidSale}/>
                    ))
                  }
              </div>
              </div>
            </> :
            <div className={style.divSapce}> 
              <GetNeedUpdate/>
            </div> }</>:
               <div className={style.divSapce}>
               <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar a Lista de Recebimentos'} />
               </div>}
       </div>
    </div>
   )

}

export default ListOfReceivers