import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import InputModelsBlock from "../../layout/InputModelsBlock";
import style from '../../style/GetNFeConfigureCompany.module.css'
import { faPeopleArrows, faHashtag, faLocationDot, faPerson, faAngleUp, faAngleDown, faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import Space from "../../layout/Space";
import Alert from "../../Models/Alert";
import { TitlePageResponsiveIcon } from "../../layout/TitlePage";
import { GetButton, GetButtonIcon } from "../../layout/ButtonsLayout";
import { GetNfeAddress } from "./GetNfeAddress";
import { GenericDropDown } from "../../layout/GenericDropDown";
import { GetSmallModels } from "../../Models/GetModels";

const getFormData = (nfe) => {
    return {
        CPF:nfe.CPF,
        CNPJ:nfe.CNPJ,
        xNome:nfe.xNome,
        indIEDest:'9 - Dispensa de IE',
        IE:nfe.IE,
    }
}

const getFormNeed = () => {
    return {
        xNome:false,
        indIEDest:false,
    }
}

export const GetNFeClient = forwardRef(({initialExpand,nfe}, ref) => {

    const addressRef = useRef(null)
    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData(nfe.dest))
    const [needData, setNeedData] = useState(getFormNeed)
    const [expanded, setExpand] = useState(initialExpand?? true)

    useImperativeHandle(ref, () => ({
        handleSubmit
    }))

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmit= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })

        let fomeDataFromAddress = {}
        if (addressRef.current) {
            fomeDataFromAddress = addressRef.current.handleSubmit()
            if(fomeDataFromAddress === false)
                return false
        }
        
        if (allFieldsEmpty) {
            setAlert(true)
            return false
        }

        return { ...formData, ...fomeDataFromAddress }

    }

    return (
        <div className={style.div}>
            <TitlePageResponsiveIcon 
                children={
                    <GetButtonIcon color='#094f00' height={40} width={40} icon={expanded? faAngleUp :  faAngleDown} handleFunction={()=>setExpand(!expanded)}/> 
                }
                subtitle={"DADOS DO CLIENTE DA NFe"} path={"Configure o Cliente Abaixo..."} icon={faPerson}/> 
             <div className={expanded? style.visible :   style.notVisible}>
                <div className={style.div}>
                <div className={style.divInRow}>
                        <InputModelsBlock legend={'RAZÃO SOCIAL/NOME'} onChange={handleChange} validation={needData.xNome} maxLength={50} value={formData.xNome} name={'xNome'} iconButton={faPeopleArrows} type={"text"} placeholder={"Razão Social..."}/>  
                        <Space left={5} top={5}/>
                        {formData.indIEDest === '1 - Contribuinte ICMS'?
                        <>
                            <InputModelsBlock legend={'CNPJ'} onChange={handleChange} validation={needData.CNPJ} maxLength={45} value={formData.CNPJ} name={'CNPJ'} iconButton={faHashtag} type={"text"} placeholder={"CNPJ Comprador..."}/>
                            <Space left={5} top={5}/>
                            <InputModelsBlock legend={'INSCRIÇÃO ESTADUAL'} onChange={handleChange} validation={needData.IE} maxLength={25} value={formData.IE} name={'IE'} iconButton={faLocationDot} type={"number"} placeholder={"Inscrição Estadual..."}/>    
                        </>:
                            <>
                            <InputModelsBlock legend={'CPF do Comprador...'} onChange={handleChange} validation={needData.CPF} maxLength={45} value={formData.CPF} name={'CPF'} iconButton={faHashtag} type={"text"} placeholder={"CPF do Comprador..."}/>
                            </>
                            }
                        <Space left={5} top={5}/>
                        <GenericDropDown legend={'INDICADOR DE DISPENSA IE'} data={['0 - Não se aplica', '1 - Contribuinte ICMS', '2 - Isento','9 - Dispensa de IE']} name={'indIEDest'} initialState={formData.indIEDest} handlerFuncion={handleChange}  />   
                </div>
                <Space left={5} top={5}/>
                    <GetSmallModels icon={faCircleQuestion} title={'MODELO NFC-E (65) - NÃO É NECESSÁRIO IDENTIFICAR O COMPRADOR (CPF/CNPJ E NOME)'} backgroundColor={"#005f00"} subtitle={'Se a Emissão For NF-e Venda Online, é Necessário Preencher CPF/CNPJ e Nome'}/>
                    <Space left={5} top={5}/>
                    <GetSmallModels icon={faCircleQuestion} title={'MODELO NFC-E (65) - O ENDEREÇO DO COMPRADOR NÃO É NECESSÁRIO'} backgroundColor={"#005f00"} subtitle={'Se a Emissão For NF-e Venda Online, é Necessário Preencher  todos os Campos do Endereço'}/>
                    <Space left={5} top={5}/>
                    <GetNfeAddress needComplete={false} initialValue={nfe.dest} ref={addressRef} initialExpand={true} title={'ENDEREÇO DO COMPRADOR'}/>
                </div>
            </div>
            {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
    )
})