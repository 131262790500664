import { sendRequestGetLocal, sendRequestPostLocal } from "../backEnd/requesition"
import MyStoreObject from "../objects/MyStoreObject"

const _routerFromGetMyStoreInCloud ='/myStore/getMyStore'
const _routerFromSetMyStoreInCloud ='/myStore/setMyStore'
const _routerFromSetEditeMyStoreInCloud ='/myStore/setEditeMyStore'

export const getMyStore = async (showError) => {
    var results = []
    try {
        results = await sendRequestGetLocal(_routerFromGetMyStoreInCloud)
        if(!results.response){
            let myStoreData = new MyStoreObject()  
            myStoreData.fromCloud(results)
            return myStoreData
        }else throw new Error(results.response.data.sqlMessage) 
       
    } catch (error) {
        showError('ERROR AO BUSCAR CLIENTES',error.message)
    }
}


export const setStartMyStore = async (showError) =>{
    var results
    try {
        const myStoreStart = new MyStoreObject()
        results = await sendRequestPostLocal(_routerFromSetMyStoreInCloud,myStoreStart.getMapFromCloud())

        if(results.status !== 200) throw new Error(results.response.data.sqlMessage) 
        else return results
    } catch (error) {
        showError('ERROR AO SALVAR A LOJA',error.message)
        return {status:404}
    }
}

export const setUpdateMyStore = async (selectedImageFromFile,myStoreData,showError) =>{
    var results
    try {

        if (selectedImageFromFile) {
         
            const imagePromise = new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                    const base64Image = reader.result.split(',')[1]
                    const dataBody = myStoreData.getMapFromCloud()
                    dataBody['image'] = base64Image
                    resolve(sendRequestPostLocal(_routerFromSetEditeMyStoreInCloud, dataBody))
                }
                reader.onerror = error => reject(error)
                reader.readAsDataURL(selectedImageFromFile)
            })

            results = await imagePromise

        }else{
            results = await sendRequestPostLocal(_routerFromSetEditeMyStoreInCloud,myStoreData.getMapFromCloud())
        }

        if(results.status !== 200) throw new Error(results.response.data.sqlMessage) 
        else return results
    } catch (error) {
        showError('ERROR AO SALVAR DADOS DA LOJA',error.message)
        return {status:404}
    }
}