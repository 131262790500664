import React, { useState, useEffect,useRef } from 'react'
import style from "../../../style/ListClients.module.css"
import { GetSmallModels } from '../../../Models/GetModels'
import { faMagnifyingGlass,faCircleInfo, faPlus, faListCheck, faLock, faPlusSquare, faCalendar} from '@fortawesome/free-solid-svg-icons'
import Loader from '../../../layout/Loader'
import InputModels from "../../../Models/InputModels.js"
import GetAmountList from '../../../Models/GetAmountList.js'
import GetExpandedImage from '../../../layout/GetExpandedImage.js'
import TitlePage, { TitlePageResponsiveIcon } from '../../../layout/TitlePage.js'
import GetFloatingButton, { GetFloatingButtonWithLegend } from '../../../layout/GetFloatingButton.js'
import Space from '../../../layout/Space.js'
import { useNavigate } from 'react-router-dom'
import { routerFromStockRepositionBJ, routerFromStockRepositionGenerics } from '../../../routers/routerList.js'
import { getListRepositions } from '../../../controllers/CloudRepositions.js'
import DatePickerComponent from '../../../layout/DatePickerComponent.js'
import RepositionModel from '../../../Models/RepositionModel.js'
import ViewRepositionList from './ViewRepositionList.js'
import { useError } from '../../../AppContext.js'
import { databaseSettings, updateIfNull } from '../../../backEnd/Memory.js'
import { employeeData } from '../../../backEnd/InitController.js'

function ListRepositions() {
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 60)
    return currentDate.toISOString().slice(0,10)
  })
  const [showRepositionList, setShowRepositionList] = useState(false)
  const [repositionSelected, setRepositionSelected] = useState(null)
  const [canView] = useState(employeeData().employeePermission.permissionsContabilite[6])
  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])
  const [filtered, setFiltered] = useState([])
  const [list, setList] = useState([])
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {showError} = useError()
  const [filter, setFilter] = useState('TODO OS LOTES')
  const [orderBy, setOrderBy] = useState('asc')

  const [isExpanded, setIsExpanded] = useState(false)
  const [nameProduct, setNameProduct] = useState('')
  const [linkImage, setSetLinkImage] = useState('')
  const toggleImageExpansion = (nameProduct,linkImage) => {
      setIsExpanded(!isExpanded)
      setNameProduct(nameProduct)
      setSetLinkImage(linkImage)
  }
 
  const handleOpenViewReposition= (reposition) =>{
      setRepositionSelected(reposition)
      setShowRepositionList(true)
  }

  useEffect(() => {
      async function fetchData() { 
        setLoading(true)
        await updateIfNull(showError)
        await handleFindDataBase()  
        setLoading(false)
      }
      fetchData()
  }, [])

  useEffect(() => {
    handleChangeFilter()
  }, [filter, orderBy,list])

  const handleFindDataBase = async (valueData) => {
    setLoading(true)
    setList([])
    const value = await getListRepositions(showError,valueData ?? selectedDate)
    setList(value)
    setFilter('TODO OS LOTES')
    setOrderBy('asc')
    setLoading(false)
  }
  
  const controllerFilter = () => {
    let data = []
    switch (filter) {
      case 'all':
        data = list
        break
      case 'add':
        data = list.filter(item => item.statusReposition === 'ADIÇÃO DE PEÇAS DA MALA')
        break
      case 'remove':
        data = list.filter(item => item.statusReposition !== "ADIÇÃO DE PEÇAS DA MALA")
        break
      default:
        data = list
        break
    }

    let sorted = [...data];

    switch (orderBy) {
      case 'asc':
        sorted.sort((a, b) => a.nameReposition.localeCompare(b.nameReposition))
        break
      case 'desc':
        sorted.sort((a, b) => b.nameReposition.localeCompare(a.nameReposition))
        break
      default:
        break
    }
    return sorted;
  }

  const handleFilterChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }
  
  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()
    resetScroll()
    if(value!= null){
      const filtered= data.filter(
        repositons => {
          const filterName= repositons.nameReposition.toLowerCase().includes(value.target.value.toLowerCase())
          return filterName
        }
      )
      setFiltered(filtered)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    if(container) container.scrollTop = 0
    setLimit(50)
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  const setDateTime = async (value)=>{
    setSelectedDate(value)
    handleFindDataBase(value)
  }

  const handleGoToReposition =(router)=>{
    navigate(router)
  }


  return (
    <div className={style.containerGeneral}>
        <div className={style.containerGlobal}>  
            {canView?
            <>
            <div className={style.titleMenu}>     
                <div className={style.desable}> 
                  <TitlePageResponsiveIcon subtitle={'Lista com Todas as Atualizações do Estoque em Lote'} 
                  children={
                    window.innerWidth > 1200?
                    <DatePickerComponent 
                      handlerFunction={setDateTime}
                      valueStart={selectedDate} label={'Data Inicial da Busca no Servidor'}/>:null
                  }
                  path={'São Todas as Adições ou Remoções de Produtos do Estoque...'} icon={faListCheck}/>
                </div>
                <Space right={2} left={2} top={2} bottom={2}/> 
                {
                  window.innerWidth < 1200?
                 <TitlePageResponsiveIcon
                  icon={faCalendar}
                  title={window.innerWidth > 768?  'Data Incial da Busca no Servidor' : ""}
                  path={window.innerWidth > 768?  'Selecione uma Data -> '  : ''}
                  children={
                     <DatePickerComponent 
                    handlerFunction={setDateTime}
                    valueStart={selectedDate} label={'Data Inicial da Busca no Servidor'}/>
                 } />:null
                }
                
            </div>
            <div className={style.buildList}>
              <div className={style.filter}>
                  <div className={style.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} type={"text"} 
                    placeholder={"Buscar Reposição/Remoção em Lote na Lista..."}
                    onChange={handleChangeFilter}/>
                  </div>
                  <div className={style.containerDropDown}>
                    <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                        <option value="all">TODO OS LOTES</option>
                        <option value="add">LOTE DE ADIÇÃO</option>
                        <option value="remove">LOTE DE REMOÇÃO</option>    
                    </select>
                    <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                        <option value="asc">CRESCENTE</option>
                        <option value="desc">DESCRESCENTE</option>
                    </select>
                  </div>
              </div>
              <Space right={2} left={2} top={5} bottom={0}/> 
              {(filtered.length > 0 && !loading ) ?<div className={style.buildList}>
                <GetAmountList amount={filtered.length} title={"Reposições/Remoções Totais Encontrados na Busca..."}/>
                <div className={style.labelData}>
                  <h5>Nome</h5>
                  <h5>Data</h5>
                  <h5>Tipo de Produto</h5>
                  <h5>Tipo da Atualização</h5>
                </div>
                <Space right={0} left={0} top={0} bottom={10}/> 
              </div> : null}
              <div ref={containerRef} className={style.containerList} onScroll={checkScroll}>
                  <div className={style.loaderCenter}>
                    <Loader titleFinding={'Buscando no Servidor!'} 
                         subtitleFinding={'Aguarde...'} loading={loading}/>
                  </div>
                  <div>
                    {(filtered.length === 0 && !loading)?
                    <GetSmallModels title={"Não há Reposições/Remoções com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo} />:
                      filtered.slice(0, limit).map((data, index) => (
                          <div key={index}>
                            <RepositionModel data={data} fucntionButton={()=>handleOpenViewReposition(data)}/>
                          </div>
                    ))
                  }
                  </div>
              </div>
            </div></>:
               <div className={style.divSapce}>
               <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar a Lista Reposições'} />
               </div>}
            {showRepositionList ? <ViewRepositionList reposition={repositionSelected} handleClose={()=>setShowRepositionList(false)} /> : null}
            {isExpanded? <GetExpandedImage nameProduct={nameProduct} linkImage={linkImage} onClose={toggleImageExpansion}/>:null}
            {
              window.innerWidth <= 1200 ?
                <>
                        <GetFloatingButton width={65} height={65} icon={faPlusSquare} color='#ff0000' margiRight={(databaseSettings.isCanUseBrunaBJ? 75: '1%')} margiBottom={'1%'} handleFunction={()=>handleGoToReposition(routerFromStockRepositionGenerics)} />
                        {databaseSettings.isCanUseBrunaBJ?
                        <GetFloatingButton width={65} height={65} icon={faPlus} color='#007bff' margiRight={'0.5%'} margiBottom={'1%'} handleFunction={()=>handleGoToReposition(routerFromStockRepositionBJ)} />
                        :null} 
                
               </>:
                <>
                        <GetFloatingButtonWithLegend width={window.innerWidth <= 1200 ? '40%': '13%'} 
                        legend={'REPOR PRODUTOS'} icon={faPlus} color='#ff0000' margiRight={window.innerWidth <= 1200 ? '42%' : (databaseSettings.isCanUseBrunaBJ? '16%' : '1%')} margiBottom={window.innerWidth <= 1200 ? '1%': '1%'} handleFunction={()=>handleGoToReposition(routerFromStockRepositionGenerics)} />
                        {databaseSettings.isCanUseBrunaBJ? 
                        <GetFloatingButtonWithLegend width={window.innerWidth <= 1200 ? '40%': '15%'}  
                        legend={'REPOR BRUNA SEMIJOIAS'} icon={faPlus} color='#007bff' margiRight={'0.5%'} margiBottom={window.innerWidth <= 1200 ? '1%': '1%'} handleFunction={()=>handleGoToReposition(routerFromStockRepositionBJ)} />
                        :null}

                </>
            }      
       </div>
    </div>
  )
}

export default ListRepositions