import React, { useEffect, useState } from "react"
import { useError } from "../../AppContext"
import style from '../../style/ControllerAdmin.module.css'
import InputModels from "../../Models/InputModels"
import { faCircleInfo, faMagnifyingGlass, faPlus, faRefresh, faRightFromBracket } from "@fortawesome/free-solid-svg-icons"
import Space from "../../layout/Space"
import GetAmountList from "../../Models/GetAmountList"
import Loader from "../../layout/Loader"
import { GetSmallModels } from "../../Models/GetModels"
import GetFloatingButton from "../../layout/GetFloatingButton"
import { getListUsers } from "../../controllers/CloudFromUsers"
import { GetButton, GetButtonIcon } from "../../layout/ButtonsLayout"
import UsersModel from "./UsersModel"
import GetEditeAndAddUsers from "./GetEditeAndAddUsers"

export function ControllerAdmin({handleLogOut}){

    const [filtered, setFiltered] = useState([])
    const [filter, setFilter] = useState('all')
    const [orderBy, setOrderBy] = useState('asc')
    const [list, setListUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [showEditeOrAdd,setShowEditeOrAdd] = useState(false) 
    const [selectedUser,setSelectedUser] = useState(null)
    const { showError } = useError()

    useEffect(() => {
        async function fetchData() {
            await handlerFind() 
        }
        fetchData()
      }, [])

    useEffect(() => {
        handleChangeFilter()
    }, [filter, orderBy,list])
    
    const handlerFind = async() => {
        setLoading(true)
        setListUsers([])
        const value = await getListUsers(showError)
        setListUsers(value)
        setLoading(false) 
    }

    const controllerFilter = () => {
        let data = []
        switch (filter) {
          case 'all':
            data = list
            break
          case 'isOpen':
            data = list.filter(item => item.isAcess)
            break
          case 'IsNotOpen':
            data = list.filter(item => !item.isAcess)
            break
          case 'needPay':
            data = list.filter(item => item.checkNeedPay())
            break
          default:
            data = list
            break
        }
    
        let sorted = [...data]
        switch (orderBy) {
          case 'asc':
            sorted.sort((a, b) => a.name.localeCompare(b.name))
            break
          case 'desc':
            sorted.sort((a, b) => b.name.localeCompare(a.name))
            break
          default:
            break
        }
        return sorted;
    }
    
    const handleFilterChange = async (event) => {
        setFilter(event.target.value)
        handleChangeFilter()
        setFiltered(filtered)
    }
      
    const handleOrderByChange = async (event) => {
        setOrderBy(event.target.value)
        handleChangeFilter()
        setFiltered(filtered)
    }

    const handleChangeFilter = (value) => {
        const data = controllerFilter()
    
        if(value!= null){
          const filter = data.filter(
            data => {
              const filterName = data.name.toLowerCase().includes(value.target.value.toLowerCase())
              return filterName
            }
          )
          setFiltered(filter)
        }else{
          setFiltered(data)
        }
    } 

    const handleSelectionUser = (user) => {
        setSelectedUser(user)
        setShowEditeOrAdd(true)
    }

    return(
        <div className={style.containerGeneral}>
            <div className={style.buildList}>
              <div className={style.filter}>
                  <div className={style.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} type={"text"} placeholder={"Buscar Usúario do Sistema..."}
                    onChange={handleChangeFilter}/>
                  </div>
                  <div className={style.containerDropDown}>
                   <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                     <option value="all">TODOS</option>
                     <option value="isOpen">LIBERADO</option>
                     <option value="IsNotOpen">BLOQUEADO</option>
                     <option value="needPay">DEVENDO</option>
                   </select>
                    <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                        <option value="asc">CRESCENTE</option>
                        <option value="desc">DESCRESCENTE</option>
                    </select>
                    <Space left={10}/>
                    {
                      window.innerWidth > 1200? 
                      <GetButton title={'BUSCAR NO SERVIDOR'} width={150} height={50} icon={faRefresh} color={'#007bff'} handleFunction={handlerFind}/>
                      :
                      <GetButtonIcon width={100} height={50} icon={faRefresh} color={'#007bff'} handleFunction={handlerFind}/>
                    }
                  </div>
              </div>
              <Space right={2} left={2} top={5} bottom={0}/>  
              {(filtered.length > 0 && !loading ) ?<div className={style.buildList}>
                 <GetAmountList amount={filtered.length} title={"Usúarios Totais Encontradas na Busca..."}/>
                <Space right={0} left={0} top={0} bottom={10}/> 
              </div> : null}
              <div className={style.labelData}>
                  <h5>Nome</h5>
                  <h5>Email</h5>
                  <h5>DataBase</h5>
                  <h5>Número</h5>
                  <h5>último Pagamento</h5>
                  <h5>Acesso</h5>
                  <h5>Status</h5>
                </div>
              <div className={style.containerList}>
                   <div className={style.loaderCenter}>
                     <Loader titleFinding={'Buscando Usúarios no Servidor!'} 
                          subtitleFinding={'Aguarde...'} loading={loading}/>
                   </div>
                  <div>
                    {(filtered.length === 0 && !loading)?
                    <GetSmallModels title={"Não há Usúarios com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo} />:
                      filtered.map((users, index) => (
                          <div key={index}>
                           <UsersModel data={users} onClick={()=>handleSelectionUser(users)} />
                          </div>
                      ))
                    }
                  </div>
              </div>
            </div>
          {showEditeOrAdd?  <GetEditeAndAddUsers onSave={handlerFind} user={selectedUser} onClose={()=>setShowEditeOrAdd(false)}/>: null}
          <GetFloatingButton icon={faRightFromBracket} color='#ff0000' width={window.innerWidth <= 1200 ? 65:75} margiBottom={'1%'}  height={window.innerWidth <= 1200 ? 65:75} margiRight={'1%'} handleFunction={handleLogOut} />
          <GetFloatingButton icon={faPlus} color='#007bff' width={window.innerWidth <= 1200 ? 65:75} margiBottom={'1%'}  height={window.innerWidth <= 1200 ? 65:75} margiRight={window.innerWidth <= 768? 75 :window.innerWidth <= 1200? 80:100} handleFunction={()=>handleSelectionUser(null)} />
        </div>
    )

}