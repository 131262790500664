import React, { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import style from "../style/DatePickerComponent.module.css"
import Space from './Space'
import InputModels from '../Models/InputModels'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

const DatePickerComponent = ({label,handlerFunction,valueStart}) => {
  const [selectedDate, setSelectedDate] = useState(valueStart)

  const handleDateChange = (date) => {
    setSelectedDate(date.target.value)
    const formattedDate = date && date.target.value !== '' ? new Date(date.target.value).toISOString().slice(0, 19).replace('T', ' ') : null;
    handlerFunction(formattedDate);
  }

  return (
    <div className={style.containerGlobal}>
      <h2>{label}</h2>
      <Space right={10}/>
      <InputModels placeholder={'DATA'}
        type={"date"}
        value={selectedDate}
        iconButton={faCalendar}
        maxLength={10}
        onChange={handleDateChange}
      />
    </div>
  )
}

export default DatePickerComponent;
