import { employeeData } from "../../backEnd/InitController"

class DetailsSale{
    constructor(){
        this.isOpen = false
        this.payForm = ""
        this.date = ""
        this.description =""
        this.idEmployee = 0
        this.codeSaleBase = ''
        this.factorCompany = 0.0
        this.dateRegister = ''
        this.isPresent = false
    }

    fromNewSale(auxSale){
        this.isOpen = (auxSale.payForm === 'PARCELADO')
        this.payForm = auxSale.payForm
        this.dateRegister = auxSale.dateRegister.toISOString().replace("T",' ').slice(0,19)
        this.date = auxSale.date === ''? this.dateRegister : new Date(auxSale.date).toISOString().replace("T",' ').slice(0,19)
        this.description = auxSale.details
        this.idEmployee = employeeData().idEmployee
        this.factorCompany = auxSale.factor
        this.isPresent = (auxSale.typeSale === 'BRINDE')
    }

    fromEdite(data){
        this.dateSale = data.dateSale
        this.date = data.date
        this.dateRegister = data.dateRegister
        this.idEmployee = data.idEmployee
        this.factorCompany =  parseFloat(data.factorCompany)
        this.payForm = data.payForm
        this.description = data.description
        this.isPresent =  data.isPresent 
        this.isOpen = data.isOpen
    }

    fromCloud(data){
        this.date = data.date
        this.dateRegister = data.dateRegister
        this.idEmployee = data.idEmployee
        this.factorCompany =  parseFloat(data.factorCompany)
        this.payForm = data.payForm
        this.description = data.description
        this.isPresent =  data.isPresent === 1 ? true : false
        this.isOpen = data.isOpen === 1 ? true : false

        if(this.dateSale === '') {
            this.dateSale = this.dateRegister
        }
    }

    clear(){
        this.isOpen = false
        this.payForm = ""
        this.date = ""
        this.description =""
        this.idEmployee = 0
        this.codeSaleBase = ''
        this.factorCompany = 0
        this.dateRegister = ''
        this.isPresent = false
    }

    getMapFromCloud(){
        return{
        'date': this.date,
        'dateRegister': this.dateRegister,
        'idEmployee': this.idEmployee,
        'factorCompany': this.factorCompany,
        'payForm': this.payForm,
        'description': this.description,
        'isPresent': this.isPresent,
        'isOpen': this.isOpen
        }
    }

    getDateToString(){
        return this.date.replace('T', ' ').slice(0,19)
    }

    getDateRegisterToString(){
        return this.dateRegister.replace('T', ' ').slice(0,19)
    }
}

export default DetailsSale