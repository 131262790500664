import { sendRequestPostLocal } from "../backEnd/requesition"
import SaleFromOtherSeller from "../objects/SaleFromOtherSeller";

const _routerFromGetPurchaseInCloud = '/purchase/get';

export const getListPurchase = async (dateTime,showError) => {
    var results = []
    const listShopping = []
    try {
        var body = {'date': dateTime}
        results = await sendRequestPostLocal(_routerFromGetPurchaseInCloud,body)    
        if(Array.isArray(results.data)){
            Object.values(results.data).forEach(sale =>{   
                var saleTemp = new SaleFromOtherSeller()
                saleTemp.fromCloud(sale)
                listShopping.push(saleTemp)
            })
        }else throw new Error(results.response.data.sqlMessage) 

    } catch (error) {
        showError('ERROR AO BUSCAR COMPRAS',error.message)
    }finally{
        return listShopping
    }
}