import React, { useEffect } from 'react';
import style from "../style/Barcode.module.css";
import { faBarcode, faCamera, faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { TitlePageResponsiveIcon } from '../layout/TitlePage';
import { Scanner } from '@yudiel/react-qr-scanner';
import { useState } from 'react';
import Space from '../layout/Space';
import { GetButton, GetButtonIcon } from '../layout/ButtonsLayout';

function Barcode({ onClose, onValidate }) {
    const [isPause, setPause] = useState(false)
    const [lastNameItem, setLastNameItem] = useState("")

    useEffect(() => {
    },[setLastNameItem])

    return(
        <div className={style.overlay}>
            <div className={style.title}>
            <TitlePageResponsiveIcon
                title="LER CÓDIGO DE BARRAS"
                children={
                    <GetButtonIcon icon={faClose} color="#ff0000" handleFunction={onClose} />
                }
                icon={faCamera}
                path="Use a Câmera para Ler"
            />
            </div>
            <Scanner 
            formats={['ean_13','ean_8']}
            paused={isPause}
            allowMultiple={true}
            components={{
                audio: true,
                tracker: undefined,
                onOff: false,
                finder: false,
                torch: false
            }}
            constraints={{ 
                facingMode: 'environment',
                frameRate: 60,
                width: { min: 640, ideal: 720, max: 1920 },
                height: { min: 640, ideal: 720, max: 1080 }
            }}
            onScan={(result) => {
                const value = onValidate(result[0].rawValue)
                if(value){
                    setLastNameItem(value.getName())
                    setPause(true)  
                }else{
                    onClose()
                }
            }}  />
            {isPause?
                <div className={style.div}>  
                    <TitlePageResponsiveIcon
                        title="PRODUTO IDENTIFICADO."
                        icon={faBarcode}
                        path={lastNameItem}
                    />
                    <div className={style.row}>
                        <GetButton title={"ADICIONAR MAIS"} icon={faPlus} color="#0377fc" handleFunction={()=>setPause(false)} />
                        <Space left={5}/>
                        <GetButton title={'SAIR'} icon={faClose} color="#ff0000" handleFunction={onClose} />
                    </div>
                </div> : null}
        </div>
    )
}

export default Barcode;
