import { forwardRef, useEffect, useRef, useState } from "react";
import InputModelsBlock from "../../layout/InputModelsBlock";
import style from '../../style/GetNFeConfigureCompany.module.css'
import { faBarcode, faMoneyBill, faMoneyBill1Wave, faArrowUp19, faScaleUnbalancedFlip, faEarthAmericas, faFileWord, faTrash, faAngleUp, faAngleDown, faCircleQuestion, faFileCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import Space from "../../layout/Space";
import Alert from "../../Models/Alert";
import { GetButton, GetButtonIcon } from "../../layout/ButtonsLayout";
import { GetSmallModels } from "../../Models/GetModels";
import { faUbuntu } from "@fortawesome/free-brands-svg-icons";
import { GetICMS } from "./GetICMS";
import { GetPIS } from "./GetPIS";
import { GetCOFINS } from "./GetCOFIS";
import { useImperativeHandle } from "react";

const getFormData = (product) => {
   
    return {
        cProd:product.cProd,
        cEAN:product.cEAN,
        xProd:product.xProd,
        NCM:product.NCM,
        CFOP:product.CFOP,
        uCom:product.uCom,
        qCom:product.qCom,
        vUnCom:product.vUnCom,
        vProd:product.vProd,
    }
}

const getFormNeed = () => {
    return {
        cProd:false,
        cEAN:false,
        xProd:false,
        NCM:false,
        CFOP:false,
        uCom:false,
        qCom:false,
        vUnCom:false,
    }
}

export const GetNFeOnlyProduct = forwardRef(({initialExpand,index,product,fuctionTrash,canEdite,automaticMath}, ref) => {

    const refICMS = useRef(null)
    const refPIS= useRef(null)
    const refCOFINS = useRef(null)
    
    const [isUpdate,setUpdate] = useState(false)
    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData(product.prod))
    const [needData, setNeedData] = useState(getFormNeed)
    const [expanded, setExpand] = useState(initialExpand?? true)

    const handleChange = (e) => {
        if(e !== ''){
            setUpdate(false)
            const { name, value } = e.target;
            if(name === 'xProd') product.prod.xProd = value     
            setFormData({ ...formData, [name]: value })
        }
    }

    useEffect(()=>{
        product.nItem = index+1
    },[])

    useEffect(() => {
    
        product.prod.fromEditeBasics(formData)
        product.prod.calculateTotal()
        product.ICMS.setValueVBC(product.prod.vProd)
        product.PIS.setValueVBC(product.prod.vProd)
        product.COFINS.setValueVBC(product.prod.vProd)

        setUpdate(true)
    }, [formData])

   
    useImperativeHandle(ref, () => ({
        handleSubmit
    }))

    const handleSubmit= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })

        if (refICMS.current.handleSubmit() === false 
        || refPIS.current.handleSubmit() === false 
        || refCOFINS.current.handleSubmit() === false)
            allFieldsEmpty = true
        
        if (allFieldsEmpty) {
            setAlert(true)
            return false
        }

        return true

    }

    return (
        <div className={style.div} data={isUpdate}>
            <div className={style.divTitleProduct}>
                <div className={style.nameProduct}>
                    <GetSmallModels backgroundColor={"#ff0000"} title={product.nItem +' '+ product.prod.xProd}
                   value={product.getValueTotalToString()}
                   subtitle={product.prod.getToString() + product.ICMS.getICMStoString() + product.PIS.getToString() + product.COFINS.getToString()}
                  />
                </div>
                <div className={style.buttons}>
                    <GetButtonIcon height={40} width={40} color='#7a7400' icon={expanded? faAngleUp :  faAngleDown} handleFunction={()=>setExpand(!expanded)}/> 
                    {canEdite?
                        <>
                            <Space left={5} top={5}/>
                            <GetButtonIcon height={40} width={40} color='#ff0000' icon={faTrash} handleFunction={fuctionTrash}/>
                        </>:null
                    }
                </div> 
            </div>
             <div className={expanded? style.visible :   style.notVisible}>
                <div className={style.div}>
                <GetSmallModels 
                    children={
                        <GetButton responsive={true} color='#FF0000' title={'AJUDA'}  icon={faCircleQuestion} handleFunction={()=>window.open('https://portalunico.siscomex.gov.br/classif/#/nomenclatura/tabela?perfil=publico','_black')}/> 
                    }
                    icon={faCircleQuestion}
                    title={'PARA SABER O (NCM) DO SEU PRODUTO'} backgroundColor={"#005f00"} subtitle={'Tabela Completa do NCM no Site do Governo, é Necessário Baixar No Formato EXCEL'}/>
                    <GetSmallModels 
                    children={
                        <GetButton responsive={true} color='#FF0000' title={'AJUDA'}  icon={faCircleQuestion} handleFunction={()=>window.open('https://www.sefaz.pe.gov.br/Legislacao/Tributaria/Documents/Legislacao/Tabelas/CFOP.htm','_black')}/> 
                    }
                    icon={faCircleQuestion}
                    title={'PARA SABER O (CFOP) DA OPERAÇÃO'} backgroundColor={"#005f00"} subtitle={'Tabela Completa do CFOP no Site do Governo Sefaz'}/>
                    <GetSmallModels title={'SDK - CÓDIGO DO PRODUTO'} value={product.prod.cProd}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'CÓDIGO DE BARRAS DO PRODUTO'} onChange={handleChange} validation={needData.cEAN} maxLength={20} value={formData.cEAN} name={'cEAN'} iconButton={faBarcode} type={"number"} placeholder={"Código de Barras do Produto..."}/>
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'DESCRIÇÃO DO PRODUTO'} onChange={handleChange} validation={needData.xProd} maxLength={250} value={formData.xProd} name={'xProd'} iconButton={faFileWord} type={"text"} placeholder={"Descrição do Produto..."}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'NOMENCLATURA COMUM DO MERCOSUL - NCM'} onChange={handleChange} validation={needData.NCM} maxLength={20} value={formData.NCM} name={'NCM'} iconButton={faEarthAmericas} type={"number"} placeholder={"NCM..."}/>  
                        <Space left={5} top={5}/>    
                    </div>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'CÓDIGO FISCAL DE OPERAÇÃO - CFOP'} onChange={handleChange} validation={needData.CFOP} maxLength={25} value={formData.CFOP} name={'CFOP'} iconButton={faUbuntu} type={"number"} placeholder={"CFOP..."}/>
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'UNIDADE COMERCIAL DO PRODUTO'} onChange={handleChange} validation={needData.uCom} maxLength={3} value={formData.uCom} name={'uCom'} iconButton={faScaleUnbalancedFlip} type={"text"} placeholder={"Ex: UN,KG,MT..."}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'QUANTIDADE COMERCIAL VENDIDA'} onChange={handleChange} validation={needData.qCom} maxLength={10} value={formData.qCom} name={'qCom'} iconButton={faArrowUp19} type={"number"} placeholder={"Ex: 5"}/>  
                        <Space left={5} top={5}/>    
                    </div>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'VALOR UNITÁRIO DO PRODUTO'} onChange={handleChange} validation={needData.vUnCom} maxLength={10} value={formData.vUnCom} name={'vUnCom'} iconButton={faMoneyBill1Wave} type={"number"} placeholder={"Ex: 150,50"}/>
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'VALOR TOTAL (UNIDADE * QUANTIDADE)'} disable={true} onChange={handleChange} maxLength={10} value={product.prod.vProd} name={'vProd'} iconButton={faMoneyBill} type={"number"} placeholder={"Ex: 1500,00"}/>  
                    </div>
                    <Space left={5} top={5}/>
                    <GetICMS initialExpand={true} automaticMath={automaticMath} ref={refICMS} isEdite={false} update={isUpdate} icms={product.ICMS}/>
                    <Space left={5} top={5} />
                    <GetPIS initialExpand={true} ref={refPIS} update={isUpdate} pis={product.PIS}/>
                    <Space left={5} top={5}/>
                    <GetCOFINS initialExpand={true} ref={refCOFINS} update={isUpdate} cofins={product.COFINS}/> 
                </div>
            </div>
            {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
    )
})