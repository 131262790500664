import React, { useState, useEffect, useRef } from 'react';
import style from "../../style/ListClients.module.css"
import { GetSmallModels } from '../../Models/GetModels.js';
import { faMagnifyingGlass,faCircleInfo, faList, faFilePdf, faLock} from '@fortawesome/free-solid-svg-icons';
import InputModels from "../../Models/InputModels.js"
import GetAmountList from '../../Models/GetAmountList.js';
import Space from '../../layout/Space.js';
import TitlePage, { TitlePageResponsiveIcon } from '../../layout/TitlePage.js';
import { makeAccounting } from '../../objects/Accounting/MakeAccounting.js';
import GetNeedUpdate from './GetNeedUpdate.js';
import { ItemModelFromSale } from '../../Models/ItemModel.js';
import { GetButton } from '../../layout/ButtonsLayout.js';
import { employeeData } from '../../backEnd/InitController.js';
import { useError, useLoading } from '../../AppContext.js';
import { requestPDFs } from '../../controllers/CloudPdfs.js';
import { myStoreDataMemory } from '../../backEnd/Memory.js';

function ListBJSold() {

  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])
  const [canViewSold] = useState(employeeData().employeePermission.permissionsContabilite[5])
  const [filtered, setFiltered] = useState(makeAccounting.accountingAllItemsSale.getItemsNormalStock())
  const [orderBy, setOrderBy] = useState('asc')
  const [filter, setFilter] = useState('TODOS')
  const { setIsLoading } = useLoading()
  const { showError } = useError()

  const handleOpenPdf = async()=>{
    const map = {}
    filtered.forEach(ss=>{
      map[ss.itemGlobal.codebar.toString()] = ss.getMapFromCloud()
    })

    setIsLoading(true)
    await requestPDFs({
      'type': 'pdfBJSold',
      'list': map,
      'myStore': myStoreDataMemory.getMapFromCloud()
    },showError)
    setIsLoading(false)
  } 

  useEffect(() => {
    handleChangeFilter()
  }, [filter,orderBy])

  const controllerFilter = () => {
    let data = []
    switch (filter) {
      case 'AB':
      case 'BB':
      case 'MB':
      case 'GB':
      case 'BP':
      case 'CE':
      case 'BT':
            data = makeAccounting.accountingAllItemsSale.getItemsNormalStock().filter(item => item.itemGlobal.databaseName === `BANCO DE DADOS - ${filter}`)
            break
      default:
        data = makeAccounting.accountingAllItemsSale.getItemsNormalStock()
        break
    }

    let sortedClients = [...data]
    switch (orderBy) {
      case 'asc':
        sortedClients.sort((a, b) => a.itemGlobal.name.localeCompare(b.itemGlobal.name))
        break
      case 'desc':
        sortedClients.sort((a, b) => b.itemGlobal.name.localeCompare(a.itemGlobal.name))
        break
      default:
        break
    }
    return sortedClients;
  }

  const handleFilterChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()
    resetScroll()
    if(value!= null){
      const filteredStock = data.filter(
        stock => {
          const filterCodeItems = stock.itemGlobal.name.toLowerCase().includes(value.target.value.toLowerCase());
          const filterFactorItem = stock.itemGlobal.factorItem.toString().toLowerCase().includes(value.target.value.toLowerCase());
          const filterCodebar = stock.itemGlobal.codebar.toString().toLowerCase().includes(value.target.value.toLowerCase());
          return filterCodeItems || filterFactorItem || filterCodebar
        }
      )
      setFiltered(filteredStock)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    if(container){
      container.scrollTop = 0
      setLimit(50)
    }
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  return (
    <div className={style.containerGeneral}>
       <div className={style.containerGlobal}>  
           {canViewSold?
           <>{makeAccounting.isCalculated === true ? 
           <>
              <div className={style.titleMenu}>     
                    {window.innerWidth > 1200? <TitlePageResponsiveIcon subtitle={'Lista de Peças da Bruna Semijoias Vendidas no Período de Busca'}  
                    path={'São Todas as Peças da Bruna Semijoias Vendidas no Período de Busca...'} icon={faList}/>:null}
                    <Space left={5}/>
                    { window.innerWidth > 1200?
                      <>
                        <div className={style.buidWithText}>
                          <GetSmallModels title={'PEÇAS VENDIDAS: '} value={makeAccounting.accountingAllItemsSale.itemsNormalStockSales.amountItems +' Un'}/>
                          <Space left={5}/>
                          <GetSmallModels title={'MODELOS VENDIDAS: '} value={makeAccounting.accountingAllItemsSale.itemsNormalStockSales.totalSaleCount}/>
                          <Space left={5}/>
                          <GetSmallModels title={'GRAMAS VENDIDAS: '} value={ makeAccounting.accountingAllItemsSale.itemsNormalStockSales.grams.toFixed(2) + 'g'}/>
                        </div>
                        <Space left={5}/>
                        <div className={style.divRow}>
                            <GetButton title={'PDF DAS PEÇAS'} handleFunction={handleOpenPdf} width={150} height={50} color={"#0161DD"} icon={faFilePdf}/>
                        </div>
                      </>:
                      <>
                        <div className={style.buidWithText}>
                            <GetSmallModels title={'PEÇAS VENDIDAS: '} value={makeAccounting.accountingAllItemsSale.itemsNormalStockSales.amountItems +' Un'}/>
                            <GetSmallModels title={'MODELOS VENDIDAS: '} value={makeAccounting.accountingAllItemsSale.itemsNormalStockSales.totalSaleCount}/>
                            <GetSmallModels title={'GRAMAS VENDIDAS: '} value={ makeAccounting.accountingAllItemsSale.itemsNormalStockSales.grams.toFixed(2) + 'g'}/>
                            <GetButton title={'PDF DAS PEÇAS'} handleFunction={handleOpenPdf}  height={45} color={"#0161DD"} icon={faFilePdf}/>
                        </div>
                      </>
                    }
              </div>
              {window.innerWidth < 1200? <Space top={5}/>:null}
              <div className={style.buildList}>
              <div className={style.filter}>
                  <div className={style.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} 
                    type={"text"} placeholder={"Buscar Peça Vendida..."}
                    onChange={handleChangeFilter}/>
                  </div>
                  <div className={style.containerDropDown}>
                    <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                        <option value="all">TODOS</option>
                        <option value="AB">AB</option>
                        <option value="BB">BB</option>
                        <option value="MB">MB</option>
                        <option value="GB">GB</option>
                        <option value="BP">BP</option>
                        <option value="CE">CE</option>
                        <option value="BT">BT</option>                             
                    </select>
                    <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                        <option value="asc">CRESCENTE</option>
                        <option value="desc">DESCRESCENTE</option>
                    </select>
                  </div>
              </div>
              <Space right={2} left={2} top={5} bottom={0}/> 
              {(filtered.length > 0) ?<div className={style.buildList}>
                  <GetAmountList amount={filtered.length} title={"Peças da Bruna Semijoias Vendidas no Período de Busca..."}/>     
                <div className={style.labelData}>
                <h5>Código</h5>
                <h5>Código de Barras</h5>
                <h5>Fator</h5>
              </div>
              <Space right={0} left={0} top={0} bottom={10}/> 
              </div> : null}
              <div ref={containerRef} className={style.containerList} onScroll={checkScroll}>
                  {(filtered.length === 0)?
                    <GetSmallModels title={"Não há Peças com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo} />:
                    filtered.slice(0, limit).map((item, index) => (
                        <ItemModelFromSale data={item} functionImage={()=>{}} />
                    ))
                  }
              </div>
              </div>
            </> :
            <div className={style.divSapce}> 
              <GetNeedUpdate/>
            </div> }</>:
               <div className={style.divSapce}>
               <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar a Lista de Peças da Bruna Semijoias Vendidas'} />
               </div>}
       </div>
    </div>
   )

}

export default ListBJSold