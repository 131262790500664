import React, { useRef, useState } from 'react';
import style from '../../style/GetNFeConfigureCompany.module.css'
import { faClose, faNewspaper, faEnvelopesBulk } from '@fortawesome/free-solid-svg-icons';
import Alert from '../../Models/Alert';
import Space from '../../layout/Space';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage';
import { GetButton, GetButtonIcon } from '../../layout/ButtonsLayout';
import { GetNFeCompany } from './GetNFeCompany';
import { GetNfeData } from './GetNfeData';
import { GetNFeClient } from './GetNFeClient';
import { GetNfeProducts } from './GetNfeProducts';
import { GetTotalTax } from './GetTotalTax';
import { GetNFeOthers } from './GetNFeOthers';
import { emit } from '../../backEnd/Memory';
import { setEmitNFe } from '../../controllers/CloudNfeProcess';
import { useError, useLoading } from '../../AppContext';
import Loader from '../../layout/Loader';

function GetNewNFe({onClose,nfe,onEmit,canEdite}) {

    const productsRef = useRef(null)
    const companyRef = useRef(null)
    const ClientRef = useRef(null)
    const dataRef = useRef(null)
    const taxRef = useRef(null)
    const taxInfadic = useRef(null)
    const [isVisible,setAlert] = useState(false)

    const {showError} = useError()
    const { setIsLoading } = useLoading()
    const [loading,setLoading] = useState(false)

    const handleClose = () => {
      onClose()
    } 

    const handleSendEmiteNFe = async() =>{
      const formDataCompany = companyRef.current.handleSubmit()
      if(formDataCompany === false)return
      nfe.emit.fromEdite(formDataCompany)

      const formDataData =  dataRef.current.handleSubmit()
      if(formDataData === false)return
      nfe.ide.fromEdite(formDataData)

      const data = productsRef.current.handleSubmit()
      if(data === false) 
      return
      
      const formDataClient = ClientRef.current.handleSubmit()
      if(formDataClient === false)return
      nfe.dest.fromEdite(formDataClient)

      const formDataTaxInfadic = taxInfadic.current.handleSubmit()
      if(formDataTaxInfadic === false)return
      nfe.infAdic.fromEditeInfAdic(formDataTaxInfadic)
      nfe.transp.fromEditeTransp(formDataTaxInfadic)

      taxRef.current.handleSubmit()

      setIsLoading(true)
      setLoading(true)
  
      await setEmitNFe(nfe,showError)
      
      onEmit()
      onClose()
      setIsLoading(false)
      setLoading(false)
    }

    if(loading){
      return(
        <div className={style.overlay}>
        <div className={style.alert}>
          <div className={style.title}>
              <Loader titleFinding={'Emitindo Nota Fiscal NFe...'}
              subtitleFinding={'Aguarde...'} loading={loading} />
          </div>
        </div>
      </div>
      )
    }

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
          <div className={style.title}>
                    <TitlePageResponsiveIcon 
                    children={
                        <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                    }
                    subtitle={"NOVA NOTA FISCAL NFe"} path={"Complete os Campos Abaixo..."} icon={faNewspaper}/>      
          </div>
          <div className={style.divAll} >
              <Space left={5} top={5}/>
              <GetNFeCompany isBlockEdite={true} nfe={nfe} initialValue={emit.getMapFromCloud()} initialExpand={false} ref={companyRef}/>
              <Space left={5} top={5}/>
              <GetNfeData initialExpand={false} nfe={nfe} ref={dataRef} title={'DADOS DA NOTA FISCAL NFe'}/>
              <Space left={5} top={5}/>
              <GetNFeClient initialExpand={false} nfe={nfe} ref={ClientRef}/>
              <Space left={5} top={5}/>
              <GetNfeProducts initialExpand={false} nfe={nfe} ref={productsRef} canEdite={canEdite} />
              <Space left={5} top={5}/>
              <GetTotalTax initialExpand={false} nfe={nfe} ref={taxRef}/>
              <Space left={5} top={5}/>
              <GetNFeOthers ref={taxInfadic}  initialExpand={false} nfe={nfe}/>
              <Space left={5} top={5}/>
              <div className={style.div}>
                <GetButton title={"EMITIR NOTA FISCAL ELETRONICA (NF-e) NO SEFAZ "} color='#007bff' icon={faEnvelopesBulk} handleFunction={handleSendEmiteNFe}/> 
              </div>
            </div>
           {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
      </div>
    )
}

export default GetNewNFe
