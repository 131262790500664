import {sendRequestGetLocal, sendRequestPostLocal} from '../backEnd/requesition.js'
import { Employee } from '../objects/ClientsAndPerson/employee.js'

const _routerEditeEmployeeInCloud ='/employee/editeEmployeeSimple'
const _routerEditeEmployeeCompleteInCloud = '/employee/editeEmployeeComplete'
const _routerAddEmployeeInCloud ='/employee/addEmployee'
const _routerGetListEmployeeInCloud ='/employee/getEmployee'
const _routerChangePasswordInCloud ='/employee/changePassword'
const _routerChangePasswordEmployeeInCloud ='/employee/changePasswordEmployee'

export const getListEmployees = async (showError,setNeedMaster) => {
    
    const employeeList = []
    
    try {
        var results = []
        results = await sendRequestGetLocal(_routerGetListEmployeeInCloud)
       
        if(Array.isArray(results)){
            Object.values(results).forEach(employeeData =>{  
                var employees = new Employee()
                employees.fromCloud(employeeData) 
                employeeList.push(employees)
            })
        }else throw new Error(results.response.data.sqlMessage) 
     
        if(setNeedMaster && employeeList.length===0){
            setNeedMaster(true)
        }
    } catch (error) {
        showError('ERROR AO BUSCAR FUNCIONÁRIOS',error.message)     
    }finally{
       return employeeList
    }
}

export const setEditeEmployeeSimple = async (selectedImageFromFile,employee,showError) =>{
    var results
    try {

        if (selectedImageFromFile) {
         
            const imagePromise = new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                    const base64Image = reader.result.split(',')[1]
                    const dataBody = employee.getMapFromCloud()
                    dataBody['image'] = base64Image
                    resolve(sendRequestPostLocal(_routerEditeEmployeeInCloud, dataBody))
                }
                reader.onerror = error => reject(error)
                reader.readAsDataURL(selectedImageFromFile)
            })

            results = await imagePromise

        }else{
            results = await sendRequestPostLocal(_routerEditeEmployeeInCloud,employee.getMapFromCloud())
        }

        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO SALVAR NOVO FUNCIONÁRIO',error.message) 
        return {status:404}
    }
}

export const setSaveEmployee = async (selectedImageFromFile,employee,showError) =>{
    var results
    try {

        if (selectedImageFromFile) {
         
            const imagePromise = new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                    const base64Image = reader.result.split(',')[1]
                    const dataBody = employee.getMapFromCloud()
                    dataBody['image'] = base64Image
                    resolve(sendRequestPostLocal(_routerAddEmployeeInCloud, dataBody))
                }
                reader.onerror = error => reject(error)
                reader.readAsDataURL(selectedImageFromFile)
            })

            results = await imagePromise

        }else{
            results = await sendRequestPostLocal(_routerAddEmployeeInCloud,employee.getMapFromCloud())
        }

        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO SALVAR NOVO FUNCIONÁRIO',error.message) 
        return {status:404}
    }
}

export const setUpdatePassword = async (password,newPassword,idEmployee,showError) => {
    try {
        const results = await sendRequestPostLocal(_routerChangePasswordInCloud, 
            {
                'password': password,
                'keycode': newPassword,
                'idEmployee':idEmployee
            }
        )

        if (results.status !== 200) 
            throw new Error(results.response.data.sqlMessage)
         else 
            return results
        
    } catch (error) {
        showError('ERROR AO SALVAR TROCA DE SENHA', error.message)
        return { status: 404 }
    }
}

export const setUpdatePasswordEmployee = async (newPassword,idEmployee,showError) => {
    try {
        const results = await sendRequestPostLocal(_routerChangePasswordEmployeeInCloud, 
            {
                'keycode': newPassword,
                'idEmployee':idEmployee
            }
        )

        if (results.status !== 200) 
            throw new Error(results.response.data.sqlMessage)
         else 
            return results
        
    } catch (error) {
        showError('ERROR AO SALVAR TROCA DE SENHA', error.message)
        return { status: 404 }
    }
}

export const setUpdateEmployee = async (selectedImageFromFile, employee, showError) => {
    try {
        let results

        if (selectedImageFromFile) {
         
            const imagePromise = new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                    const base64Image = reader.result.split(',')[1]
                    const dataBody = employee.getMapFromCloud()
                    dataBody['image'] = base64Image
                    resolve(sendRequestPostLocal(_routerEditeEmployeeCompleteInCloud, dataBody))
                }
                reader.onerror = error => reject(error)
                reader.readAsDataURL(selectedImageFromFile)
            })

            results = await imagePromise

        }else{
            results = await sendRequestPostLocal(_routerEditeEmployeeCompleteInCloud, employee.getMapFromCloud())
        }

        if (results.status !== 200) {
            throw new Error(results.response.data.sqlMessage)
        } else {
            return results;
        }

    } catch (error) {
        showError('ERROR AO SALVAR EDIÇÃO DO FUNCIONÁRIO', error.message)
        return { status: 404 }
    }
}

