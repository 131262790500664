import React, { useState,useEffect}  from "react";
import style from '../style/SelectionPersonComponent.module.css';
import PersonModels from "../Models/PersonModels";
import {faPlus, faRefresh, faUsersGear, faWarning} from "@fortawesome/free-solid-svg-icons";
import Loader from "../layout/Loader";
import { getListEmployees } from "../controllers/CloudEmployee.js"
import { TitlePageResponsiveIcon } from "../layout/TitlePage.js"
import { GetButton, GetButtonIcon } from "../layout/ButtonsLayout.js";
import GetPassword from "../Models/GetPassword.js";
import { useNavigate } from "react-router-dom";
import { routerFromListClients } from "../routers/routerList.js";
import { tryLoginUser } from "../controllers/CloudLogin.js";
import { useError } from "../AppContext.js";
import EditeEmployeeSimple from "./Employees/EditeEmployeeSimple.js";

function SelectionPersonComponent({handleLoginData}){
    const navigate = useNavigate()
    const [showGetPassword, setShowGetPassword] = useState(false)
    const [listEmployee, setListEmployee] = useState([])
    const [loading, setLoading] = useState(false)
    const [needMaster, setNeedMaster] = useState(false)
    const [employee, setEmployee] = useState('')
    const [showCreateMaster,setShowCreateMaster] = useState(false)
    const {showError} = useError()

    useEffect(() => {
        async function fetchData() {
            await handleFindUsers()
        }
        fetchData()
    }, [])


    const handleFindUsers = async() => {
        setLoading(true)
        setListEmployee([])
        let results = await getListEmployees(showError,setNeedMaster)
        setListEmployee(results)
        setLoading(false)
    }

    const handleTryLogin = (employee) => {
        setShowGetPassword(true)
        setEmployee(employee)
    }

    const handleLogin = async (password) => {
        setShowGetPassword(false)
        setLoading(true)
        var result = await tryLoginUser(employee.idEmployee,password,showError)
        if(result.status === 200){
            //await initSystem(showError)
            handleLoginData()
            navigate(routerFromListClients)
        }else{
            setLoading(false)
        }
    }


    return(
    <div className={style.containerGlobal}>
        <div className={style.wrap}>
            <div className={style.containerWrap}>
                <TitlePageResponsiveIcon title="Entrar com Login do Funcionário"
                path={'É Necessario Logar com o Seu Perfil na Loja...'}
                children={
                    listEmployee.length === 0? <GetButtonIcon icon={faRefresh} color={"#0295FE"} handleFunction={handleFindUsers}/> : null
                }
                value="" icon={faUsersGear}/>
                    {(!loading) ?
                        <div className={style.div}> 
                        {listEmployee.length === 0?
                            <TitlePageResponsiveIcon icon={faWarning} title="Não Há Funcionários!"
                                path={'Busque Novamente ou Cadastre um Funcionario...'}
                                children={
                                    listEmployee.length === 0? 
                                    <GetButton title={'ADICIONAR MASTER'} width={200} height={60} icon={faPlus} 
                                     responsive={true} color={"#ff0000"} handleFunction={()=>setShowCreateMaster(true)}
                                    /> : null
                            } />  : 
                            <div className={style.divList}>
                                {listEmployee.map((employee, index) => (
                                    <div key={index}>
                                        {employee.status? <PersonModels data={employee} fucntionButton={()=>handleTryLogin(employee)} />: null}
                                    </div>
                                ))}
                            </div>}
                        </div>
                        :null}
                    <Loader titleFinding={'Buscando Funcionários!'} 
                    subtitleFinding={'Aguarde...'} loading={loading}/>
            </div>
        </div> 
        {showGetPassword? <GetPassword handleCheck={handleLogin} password={employee.keycode} titleButton={'ENTRAR'} handleClose={()=>setShowGetPassword(false)}/> : null} 
        {showCreateMaster ? <EditeEmployeeSimple onSave={handleFindUsers} onClose={()=>setShowCreateMaster(false)}/> : null }
    </div>    
    )
}  


export default SelectionPersonComponent;