import React, { useEffect, useState } from "react";
import style from "../style/InputModels.module.css"
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";

function InputModels({ iconButton, type, placeholder,validation,name,disable, onChange, value,maxLength}) {
    const [inputValue, setInputValue] = useState(value || '');
    const handleChange = (e) => {
        let newValue = e.target.value
        if (newValue.length <= (maxLength || 100)) {

            setInputValue(newValue)
            if (onChange) {
                onChange(e)             
            }
        }
    }

    useEffect(() => {
        setInputValue(value || '');
    }, [value])


    const clearInput = (e) => {
        setInputValue('')
        if (onChange) {
            const fakeEvent = {
                target: {
                    name: name,
                    value: ''
                }
            }
            onChange(fakeEvent);
        }
    }
    return (
        <div className={style.containerInput}>
           <div className={style.inputContainer}>
            <FontAwesomeIcon className={style.iconButton} icon={iconButton} />
            <input className={style.input + (validation === true ? ' ' + style.needValue : '')}
                type={type}
                name={name}
                value={inputValue}
                onChange={handleChange}
                onWheel={(e) => e.target.blur()}
                placeholder={placeholder}
                maxLength={maxLength || 100} 
                disabled={disable}
            />
             {(inputValue && !disable) && (
                <button className={style.clearButton} onClick={clearInput}>
                    <FontAwesomeIcon className={style.clearButtonIcon} icon={faCircleXmark} />
                </button>
            )}
            </div>
            <h6 className={style.containerLeght}>{inputValue.length}/{maxLength || 100}</h6>
        </div>
    )
}

export default InputModels;