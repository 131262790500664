import React,{useState} from "react";
import style from "../style/ItemModel.module.css"
import { employeeData } from "../backEnd/InitController";

function ItemModel({data,fucntionButton,functionImage}) {
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    const [canShow] = useState(window.innerWidth > 1200)
    const [canViewCoast] = useState(employeeData().employeePermission.permissionsItems[2])
    const handleImageLoad = () => {
        setImageLoaded(true); 
    }


    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }
 
    return(
        <div className={style.containerWrap}>
            <div className={style.containerWrap1}>
                <div className={style.imageContainer}>
                            {errorOccurred ? (
                                <img
                                src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                                alt="Erro de carregamento"
                                className={style.loaded}
                                />
                            ) : (
                            <img src={data.getImage()} alt={data.itemGlobal.name} onLoad={handleImageLoad}
                                onError={handleImageError} onClick={functionImage}
                                className={!imageLoaded ? style.loading : style.loaded}/> )}
                </div> 
                <button className={style.container} onClick={fucntionButton}>
                    <div className={style.dataValues}>
                        <div className={style.containerAmount}>
                            <p className={`${style.amountHas} ${data.amount > 0 ? '' : style.noHas}`}>
                                {data.amount}
                            </p>
                        </div>
                        <div className={style.containerNames}>
                            <h3>{data.itemGlobal.name}</h3>
                            {canShow ?<h4>{data.itemGlobal.codebar}</h4>:null}
                            {canShow?<h4>{data.itemGlobal.factorItem}</h4>:null}
                            <h5>{data.getValueSaleToString()}</h5>
                            {canShow?<h6>{canViewCoast? 'R$:' + parseFloat(data.getValueCoastUnit()).toFixed(2): '****'}</h6>:null}
                        </div>
                    </div>
                </button>
            </div>
        </div>
    )
}

export function ItemModelFromSale({data,functionImage}) {
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    
    const handleImageLoad = () => {
        setImageLoaded(true); 
    }


    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }
 
    return(
        <div className={style.containerWrap}>
            <div className={style.containerWrap1}>
                <div className={style.imageContainer}>
                            {errorOccurred ? (
                                <img
                                src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                                alt="Erro de carregamento"
                                className={style.loaded}
                                />
                            ) : (
                            <img src={data.getImage()} alt={data.itemGlobal.name} onLoad={handleImageLoad}
                                onError={handleImageError} onClick={functionImage}
                                className={!imageLoaded ? style.loading : style.loaded}/> )}
                </div> 
                <button className={style.container}>
                    <div className={style.dataValues}>
                        <div className={style.containerAmount}>
                            <p className={`${style.amountHas} ${data.amount > 0 ? '' : style.noHas}`}>
                                {data.amount}
                            </p>
                        </div>
                        <div className={style.containerNames}>
                            <h3>{data.itemGlobal.name}</h3>
                            { window.innerWidth > 1200?<h4>{data.itemGlobal.codebar}</h4>:null}
                            <h4>{data.itemGlobal.factorItem}</h4>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default ItemModel