
class OtherFactors{
    constructor(factor){      
        this.valueTotalSale=0
        this.totalSale =0
        this.factor = factor
        this.grams = 0
        this.saleInList = [] // sales
    }

  addNewSale(sale){
    this.valueTotalSale += sale.totalValue
    this.totalSale++
    this.grams+=sale.totalFactorItems
    this.saleInList.push(sale)
  }
}

export default OtherFactors