import React, { useState, useEffect, useRef } from 'react';
import style from "../../style/ListClients.module.css"
import { GetSmallModels } from '../../Models/GetModels.js';
import { faMagnifyingGlass,faCircleInfo, faList, faLock} from '@fortawesome/free-solid-svg-icons';
import InputModels from "../../Models/InputModels.js"
import GetAmountList from '../../Models/GetAmountList.js';
import Space from '../../layout/Space.js';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage.js';
import ViewSaleController from '../Sale/ShowSale/ViewSaleController.js';
import { makeAccounting } from '../../objects/Accounting/MakeAccounting.js';
import GetNeedUpdate from './GetNeedUpdate.js';
import GetSalesOtherFactors from '../../Models/GetSalesOtherFactors'
import { employeeData } from '../../backEnd/InitController.js';

function ListSalesFactors() {

  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])
  const [canView] = useState(employeeData().employeePermission.permissionsContabilite[8])
  const [filtered, setFiltered] = useState(makeAccounting.otherFactorsReal)
  const [orderBy, setOrderBy] = useState('desc')

  const [showViewSale, setShowViewSale] = useState(false)
  const [saleSelected, setSaleSelected] = useState(null)

  const handleOpenViewSale = (sale) =>{
    setSaleSelected(sale)
    setShowViewSale(true)
  }

  useEffect(() => {
    handleChangeFilter()
  }, [orderBy])

  const controllerFilter = () => {
    let sorted = [...makeAccounting.otherFactorsReal]
    switch (orderBy) {
      case 'asc':
        sorted.sort((a, b) => a.factor.toString().localeCompare(b.factor))
        break
      case 'desc':
        sorted.sort((a, b) => b.factor.toString().localeCompare(a.factor))
        break
      default:
        break
    }
    return sorted
  }

  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()

    resetScroll()
    if(value!= null){
      const filtered = data.filter(
        sale => {
          const filterValue = sale.factor.toString().toLowerCase().includes(value.target.value.toLowerCase())
          return filterValue
        }
      )
      setFiltered(filtered)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    if(container){
      container.scrollTop = 0
      setLimit(50)
    }
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  return (
    <div className={style.containerGeneral}>
       <div className={style.containerGlobal}>  
           {canView?
           <>
           {makeAccounting.isCalculated === true ? 
           <>
           <Space top={5}/>
              {window.innerWidth > 1200?<div className={style.titleMenu}>     
                  <TitlePageResponsiveIcon subtitle={'Lista de Todas as Venda Por Fatores de Multiplicação No Período'}  
                  path={'São Todas as Venda Entre a Data Inicial e Final da Contabilidade Agrupadas Pelo Fator de Multiplicação Usado na Venda...'} icon={faList}/>
              </div>:null}
              <div className={style.buildList}>
              <div className={style.filter}>
                  <div className={style.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} 
                    type={"number"} placeholder={"Buscar Fator da Venda..."}
                    onChange={handleChangeFilter}/>
                  </div>
                  <div className={style.containerDropDown}>
                    <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                        <option value="asc">CRESCENTE</option>
                        <option value="desc">DESCRESCENTE</option>
                    </select>
                  </div>
              </div>
              <Space right={2} left={2} top={5} bottom={0}/> 
              {(filtered.length > 0) ?<div className={style.buildList}>
                  <GetAmountList amount={filtered.length} title={"Vendas Com Diferentes Fatores de Multiplicação..."}/>     
                <Space right={0} left={0} top={0} bottom={10}/> 
              </div> : null}
              <div ref={containerRef} className={style.containerList1} onScroll={checkScroll}>
                  {(filtered.length === 0)?
                    <GetSmallModels title={"Não há Vendas com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo} />:
                    filtered.slice(0, limit).map((other, index) => (
                        <GetSalesOtherFactors handlerFunction={handleOpenViewSale} otherFactors={other}/>
                    ))
                  }
              </div>
              </div>
            </> :
            <div className={style.divSapce}> 
              <GetNeedUpdate/>
            </div> }</>:
               <div className={style.divSapce}>
               <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar a Lista de Vendas Por Fatores'} />
               </div>}
        {showViewSale ? <ViewSaleController handleSave={()=>{}} saleStart={saleSelected} handleClose={()=> setShowViewSale(false)}/> : null} 
       </div>
    </div>
   )

}

export default ListSalesFactors