import {sendRequestPostLocal} from '../backEnd/requesition.js'
import Reposition from '../objects/Reposition.js'
import { getListItemsBJ } from './CloudItems.js'
import { getListProducts } from './CloudProducts.js'

const _routerGetReposition ='/reposition/getReposition'
const _routerSaveReposition ='/reposition/addReposition'

export const getListRepositions = async (showError,dateTime) => {
    const list = []
    try {
        var genericProducts = []
        var listItemsBj = []
        var results = []
        var body = {'dateFind': dateTime}
        results = await sendRequestPostLocal(_routerGetReposition,body)
        if(Array.isArray(results.data)){

            if(results.data.length > 0){
                genericProducts = await getListProducts(showError)
                listItemsBj = await getListItemsBJ(showError)    
            }

            Object.values(results.data).forEach(reposition =>{ 
                const newReposition = new Reposition()
                newReposition.fromCloud(reposition,genericProducts,listItemsBj)
                list.push(newReposition)
            })
        }else throw new Error(results.response.data.sqlMessage)
      
    } catch (error) {
        showError('ERROR AO BUSCAR REPOSIÇÕES',error.message)     
    }finally{
        return list
    }
}

export const getMotion = (isAdd) => {
    return isAdd ? 'Adição Dessa Peça em Lote' : 'Remoção Dessa Peça em Lote'
}

export const getMapFromList = (listItem) =>{
    let map = {}
    let count = 0
    listItem.forEach(ss=>{
        map[count] = ss.getMapFromCloud()
        count++
    })

    return map
}

export const setNewReposition= async (action, isGeneric,listItems, showError) =>{
    try {   
        let body = {
            'isGenericProduct': isGeneric,
            'nameReposition': 'ATUALIZAÇÃO_ESTOQUE_PELO_SITE',
            'dateReposition': new Date().toISOString().replace("T",' ').slice(0,19),
            'statusReposition': action.toUpperCase(),
            'items': getMapFromList(listItems)
          }
        var results = await sendRequestPostLocal(_routerSaveReposition,body)
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO ADICIONAR REPOSIÇÃO NO SISTEMA',error.message)
        return {status:404}
    }
}