import React, { useState } from 'react';
import style from '../style/GetNewBankPopop.module.css'
import { faClose, faAdd, faFont, faTextHeight, faTag, faRulerHorizontal, faRulerVertical, faGripLines } from '@fortawesome/free-solid-svg-icons';
import { TitlePageResponsiveIcon } from './TitlePage';
import { GetButton, GetButtonIcon } from './ButtonsLayout';
import Alert from '../Models/Alert';
import InputModelsBlock from './InputModelsBlock';
import Space from './Space';

const getFormData = () => {
    return {
        fontPrice:8,
        fontName:8,
        marginLeft:4,
        marginTop:3,
        labelMarginLeft:3,
        labelHeight:3,
        labelMargin:1,
        BarHeight:6,
        BarWidgh:35,
        marginNextTag:20,
        pageW:80,
        pageH:500,
    }
}

const getFormNeed = () => {
    return {
        marginLeft:false,
        marginTop:false,
        labelMarginLeft:false,
        labelHeight:false,
        labelMargin:false,
        BarHeight:false,
        BarWidgh:false,
        marginNextTag:false,
        pageW:false,
        pageH:false,
    }
}

function GetTag({onClose,onClick}) {

    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData)
    const [needData, setNeedData] = useState(getFormNeed)

    const handleClose = () => {
      onClose()
    } 

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmitSave= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setAlert(true)
            return
        }

        onClick(true,formData)
        handleClose()
    }

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
           <div className={style.title}>
                <TitlePageResponsiveIcon 
                children={
                    <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                }
                subtitle={"CONFIGURAR ETIQUETAS"} path={"Todas Unidade em Milimetros (mm)..."} icon={faTag}/>      
           </div>
           <div className={style.div}>
               <div className={style.divInRow}>
                    <InputModelsBlock legend={'TAMANHO DA FONTE DO NOME DO PRODUTO'} onChange={handleChange} validation={needData.fontName} maxLength={10} value={formData.fontName} name={'fontName'} iconButton={faFont} type={"number"} placeholder={"Tamanho Fonte..."}/>
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'TAMANHO DA FONTE DO PREÇO DO PRODUTO'} onChange={handleChange} validation={needData.fontPrice} maxLength={10} value={formData.fontPrice} name={'fontPrice'} iconButton={faTextHeight} type={"number"} placeholder={"Tamanho Fonte..."}/>  
               </div>
               <Space left={5} top={5}/>
               <div className={style.divInRow}>
                    <InputModelsBlock legend={'LARGURA DA PÁGINA'} onChange={handleChange} validation={needData.pageW} maxLength={10} value={formData.pageW} name={'pageW'} iconButton={faRulerHorizontal} type={"number"} placeholder={"Largura da Página..."}/>
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'ALTURA DA PÁGINA'} onChange={handleChange} validation={needData.pageH} maxLength={10} value={formData.pageH} name={'pageH'} iconButton={faRulerVertical} type={"number"} placeholder={"Atura da Página..."}/>  
               </div>     
               <Space left={5} top={5}/>
               <div className={style.divInRow}>
                    <InputModelsBlock legend={'MARGEM EXTERNA INCIAL DE TOPO'} onChange={handleChange} validation={needData.marginLeft} maxLength={10} value={formData.marginLeft} name={'marginLeft'} iconButton={faGripLines} type={"number"} placeholder={"Margem de Topo..."}/>
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'MARGEM EXTERNA DA ESQUERDA'} onChange={handleChange} validation={needData.marginTop} maxLength={10} value={formData.marginTop} name={'marginTop'} iconButton={faGripLines} type={"number"} placeholder={"Margem da Esquerda..."}/>  
               </div>  
               <Space left={5} top={5}/>
               <div className={style.divInRow}>
                    <InputModelsBlock legend={'MARGEM INTERNA DA ESQUERDA'} onChange={handleChange} validation={needData.labelMarginLeft} maxLength={10} value={formData.labelMarginLeft} name={'labelMarginLeft'} iconButton={faGripLines} type={"number"} placeholder={"Margem Interna Esquerda..."}/>
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'MARGEM EXTERNA PRÓXIMA ETIQUETA'} onChange={handleChange} validation={needData.marginNextTag} maxLength={10} value={formData.marginNextTag} name={'marginNextTag'} iconButton={faGripLines} type={"number"} placeholder={"Margem Para Próxima Etiqueta..."}/>  
               </div>   
               <Space left={5} top={5}/>
               <div className={style.divInRow}>
                    <InputModelsBlock legend={'ALTURA DO TEXTO'} onChange={handleChange} validation={needData.labelHeight} maxLength={10} value={formData.labelHeight} name={'labelHeight'} iconButton={faRulerVertical} type={"number"} placeholder={"Altura do Texto..."}/>
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'MARGEM INTERNA ENTRE TEXTO'} onChange={handleChange} validation={needData.labelMargin} maxLength={10} value={formData.labelMargin} name={'labelMargin'} iconButton={faGripLines} type={"number"} placeholder={"Margem Interna do Texto..."}/>  
               </div>  
               <Space left={5} top={5}/>
               <div className={style.divInRow}>
                    <InputModelsBlock legend={'ALTURA DO CÓDIGO DE BARRAS'} onChange={handleChange} validation={needData.BarHeight} maxLength={10} value={formData.BarHeight} name={'BarHeight'} iconButton={faRulerVertical} type={"number"} placeholder={"Altura do Código de Barras..."}/>
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'LARGURA DO CÓDIGO DE BARRAS'} onChange={handleChange} validation={needData.BarWidgh} maxLength={10} value={formData.BarWidgh} name={'BarWidgh'} iconButton={faRulerHorizontal} type={"number"} placeholder={"Largura do Código de Barras..."}/>  
               </div>          
           </div>
           <Space left={5} top={5}/>
           <div className={style.div}>
            <GetButton title={"GERAR ETIQUETAS"} color='#007bff' icon={faAdd} handleFunction={handleSubmitSave}/> 
           </div>
           {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
      </div>
    )
}

export default GetTag
