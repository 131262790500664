import React from 'react';
import style from "../style/ConfirmationPopup.module.css"
import { faCancel, faCheck, faWarning } from '@fortawesome/free-solid-svg-icons';
import { GetButton } from '../layout/ButtonsLayout';
import { TitlePageResponsiveIcon } from '../layout/TitlePage';
import Space from '../layout/Space';

const ConfirmationPopup = ({ title, message, onCancel, onConfirm }) => {
  const handleCancel = () => {
    onCancel()
  } 

  const handleConfirm = () => {
    onConfirm()
  } 

  return (
    <div className={style.overlay}>
      <div className={style.alert}>
        <TitlePageResponsiveIcon subtitle={title} path={message} icon={faWarning}/>
        <div className={style.buttons}>
          <GetButton title={"CANCELAR"} color='#E4080A' icon={faCancel} handleFunction={handleCancel}/>
          <Space left={5}/>
          <GetButton title={"CONFIRMAR"} color='#0870E6' icon={faCheck} handleFunction={handleConfirm}/>    
        </div>
      </div>
    </div>
  )
}

export default ConfirmationPopup;
