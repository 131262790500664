import React, { useEffect, useState } from "react"
import  { TitlePageResponsiveIcon } from "../../layout/TitlePage"
import { faCalendar, faCalendarCheck, faCalendarPlus, faCalendarXmark, faClose, faLock, faRotateRight, faSave, faSchoolLock, faSignature, faSquareUpRight, faWarning } from "@fortawesome/free-solid-svg-icons"
import style from "../../style/ControllerMoth.module.css"
import { GetButton, GetButtonIcon } from "../../layout/ButtonsLayout"
import Loader from "../../layout/Loader"
import { useError, useLoading } from "../../AppContext"
import { makeAccounting } from "../../objects/Accounting/MakeAccounting"
import { GetSmallModels } from "../../Models/GetModels"
import Space from "../../layout/Space"
import InputModelsBlock from "../../layout/InputModelsBlock"
import Alert from "../../Models/Alert"
import { findMyMoth, myMoth, updateIfNull } from "../../backEnd/Memory"
import { employeeData } from "../../backEnd/InitController"
import ConfirmationPopup from "../../Models/ConfirmationPopup"
import { setCloseMoth, setSaveNewMoth } from "../../controllers/CloudMoth"
import Moth from "../../objects/Moth/Moth"

function ControllerMoth() {
    const { showError } = useError()
    const [loading, setLoading] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const { setIsLoading } = useLoading()
    const [CanView] = useState(employeeData().employeePermission.permissionsContabilite[12])
    const [showFinalMoth, setShowFinalMoth] = useState(false)
    const [showStartMoth, setShowStartMoth] = useState(false)
    const [formData, setFormData] = useState({
        dateStart: myMoth.getTimeStartToString().slice(0, 10),
        dateFinished: new Date().toISOString().slice(0, 10)
    })

    const [needData, setNeedData] = useState({
        dateStart: false,
        dateFinished: false,
    })

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            await updateIfNull(showError)
            if (myMoth.idMoth === -1) {
                setLoading(true)
                const currentDate = new Date()
                const pastDate = new Date(currentDate)
                pastDate.setDate(pastDate.getDate() - 30)
                await makeAccounting.getTotalSaleInPeriod(pastDate, currentDate, showError)
                setLoading(false);
            }

            setFormData(
                {
                    dateStart: myMoth.getTimeStartToString().slice(0, 10),
                    dateFinished: new Date().toISOString().slice(0, 10)
                }
            )
            setIsLoading(false)
        }

        fetchData()
    }, [])

    const onSave = async () => {
        setLoading(true)
        await findMyMoth(showError)
        setLoading(false)
    }

    const handleInitCalculate = async () => {
        setLoading(true)
        setIsLoading(true)
        await makeAccounting.getTotalSaleInPeriod(new Date(formData.dateStart), new Date(formData.dateFinished),
            showError)
        setLoading(false)
        setIsLoading(false)
    }

    const handleSubmit = async (e) => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if (value === '') {
                setNeedData(prevState => ({ ...prevState, [key]: true }))
                allFieldsEmpty = true
            } else {
                setNeedData(prevState => ({ ...prevState, [key]: false }))
            }
        })

        if (allFieldsEmpty) {
            setIsVisible(true)
            return
        }

        handleInitCalculate()
    }

    const handleChange = (e) => {
        if (e !== '') {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    return (
        <div>
            <div className={style.containerGlobal}>
                <div className={style.container}>
                    {CanView ?
                        <>{!loading ? (
                            myMoth.idMoth === -1 ? (
                                <>
                                    <TitlePageResponsiveIcon
                                            subtitle={'Você Precisa Inicializar um Novo Mês'}
                                            path={'Toda Contabilidade é Feita do Dia Inicial ao Ultimo dia do Mês'}
                                            icon={faWarning}
                                            children={
                                                <GetButton
                                                responsive={true}
                                                button
                                                width={250}
                                                height={50}
                                                title={'INICIAR AGORA '}
                                                color='#038C0F'
                                                icon={faSquareUpRight}
                                                handleFunction={() => setShowStartMoth(true)} />
                                            }
                                        />
                                </>
                            ) : (
                                <div className={style.divCollum}>
                                    <div className={style.divCollor} >
                                        <div className={style.titleMenu}>
                                            <TitlePageResponsiveIcon
                                                title={'MÊS ATUAL: '}
                                                subtitle={myMoth.codeName}
                                                path={'Data de Início do Mês: ' + myMoth.getTimeStartToString()}
                                                children={
                                                    <GetButton
                                                    responsive={true}
                                                    button
                                                    width={window.innerWidth <= 1200? '100%': 250}
                                                    height={50}
                                                    title={'FINALIZAR O MÊS ATUAL'}
                                                    color='#FC0103'
                                                    icon={faSchoolLock}
                                                    handleFunction={() => setShowFinalMoth(true)}
                                                />
                                                }
                                                icon={faCalendarCheck}
                                            />
                                           
                                        </div>
                                        <div className={style.div}>
                                            <GetSmallModels
                                                value={makeAccounting.daysCount}
                                                subtitle={'É o Total de Dias Desde A Data Inicial do Mês'}
                                                title={'Total de Dias'} />
                                        </div>
                                    </div>
                                    <Space top={25} />
                                    <div className={style.divCollor}>
                                        <div className={style.div}>
                                            <InputModelsBlock
                                                type={'date'}
                                                maxLength={10}
                                                value={formData.dateStart}
                                                validation={needData.dateStart}
                                                onChange={handleChange}
                                                name={'dateStart'}
                                                legend={'DATA INICIAL DO CÁLCULO'}
                                            />
                                            <Space left={5} top={5}/>
                                            <InputModelsBlock
                                                onChange={handleChange}
                                                type={'date'}
                                                name={'dateFinished'}
                                                value={formData.dateFinished}
                                                validation={needData.dateFinished}
                                                maxLength={10}
                                                legend={'DATA FINAL DO CÁLCULO'}
                                            />
                                            <Space top={5}/>
                                            <div className={style.divButton}>
                                                <GetButton
                                                    button
                                                    width={window.innerWidth <= 1200? '100%': 250}
                                                    height={window.innerWidth <= 1200 ? 50 : 65}
                                                    title={'ATUALIZAR CONTABILIDADES'}
                                                    color='#004AC0'
                                                    icon={faRotateRight}
                                                    handleFunction={(handleSubmit)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        ) : (
                            <Loader
                                titleFinding={'Trabalhando no Servidor...'}
                                subtitleFinding={'Aguarde...'}
                                loading={loading}
                            />
                        )}</> :
                        <div className={style.div}>
                            <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                                subtitle={'Você Não Tem Permissão para Editar o Mês'} />
                        </div>}
                </div>
            </div>
            {showStartMoth ? <GetDateStartMoth onSave={onSave} onClose={() => setShowStartMoth(false)} /> : null}
            {showFinalMoth ? <GetDateFinalMoth onSave={onSave} onClose={() => setShowFinalMoth(false)} /> : null}
            {isVisible ? <Alert message={"Complete Todos os Campos em Vermelho"} onClose={() => setIsVisible(false)} type={"error"} /> : null}
        </div>
    )
}

function GetDateFinalMoth({ onClose, onSave }) {
    const [isVisible, setAlert] = useState(false)
    const [confirmationVisible, setConfirmationVisible] = useState(false)
    const [valueAlert, setValueAlert] = useState(false)
    const [loading, setLoading] = useState(false)
    const { showError } = useError()
    const [formData, setFormData] = useState({
        dateFinal: new Date().toISOString().slice(0, 10)
    })
    const [needData, setNeedData] = useState({
        dateFinal: false
    })

    const handleChange = (e) => {
        if (e !== '') {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmitSave = async () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if (key in needData) {
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            }
        })

        if (allFieldsEmpty) {
            setValueAlert("Complete Todos os Campos em Vermelho")
            setAlert(true)
            return
        }

        setConfirmationVisible(true)
    }

    const handleFinalMoth = async () => {
        setConfirmationVisible(false)
        setLoading(true)

        const result = await setCloseMoth(new Date(formData.dateFinal), showError)

        if (result.status === 200) {
            onSave()
            onClose()
        }

        setLoading(false)
    }

    return (
        <div className={style.overlay}>
            <div className={style.alert}>
                {!loading ?
                    <>
                        <div className={style.title1}>
                            <TitlePageResponsiveIcon subtitle={"FINALIZAR MÊS"}
                               children={
                                <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={onClose} />
                               } 
                                path={"Escolha a Data..."} icon={faLock} />
                        </div>
                        <div className={style.div}>
                            <InputModelsBlock legend={'ÚLTIMO DIA DO MÊS'} onChange={handleChange} validation={needData.dateFinal} maxLength={10}
                                value={formData.dateFinal}
                                name={'dateFinal'} iconButton={faCalendarXmark} type={"date"} placeholder={"Data de Finilização do Mês..."} />
                        </div>
                        <Space top={5} />
                        <div className={style.divButton1}>
                            <GetButton title={'FINALIZAR AGORA'} color='#007bff' icon={faSave} handleFunction={handleSubmitSave} />
                        </div>
                    </> : <Loader titleFinding={'Salvando Dados no Servidor...'}
                        subtitleFinding={'Aguarde...'} loading={loading} />}
                {confirmationVisible ? <ConfirmationPopup title={'FINALIZAR O MÊS'}
                    message={'Você Tem Certeza que Deseja Finalizar o Mês?'} onCancel={() => setConfirmationVisible(false)}
                    onConfirm={handleFinalMoth} /> : null}
                {isVisible ? <Alert message={valueAlert} top={25} right={25} onClose={() => setAlert(false)} type={"error"} /> : null}
            </div>
        </div>
    )
}

function GetDateStartMoth({ onClose, onSave }) {
    const [isVisible, setAlert] = useState(false)
    const [confirmationVisible, setConfirmationVisible] = useState(false)
    const [valueAlert, setValueAlert] = useState(false)
    const [loading, setLoading] = useState(false)
    const { showError } = useError()
    const [formData, setFormData] = useState({
        dateStart: new Date().toISOString().slice(0, 10),
        name:''
    })
    const [needData, setNeedData] = useState({
        dateFinal: false,
        name:false
    })

    const handleChange = (e) => {
        if (e !== '') {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmitSave = async () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if (key in needData) {
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            }
        })

        if (allFieldsEmpty) {
            setValueAlert("Complete Todos os Campos em Vermelho")
            setAlert(true)
            return
        }

        setConfirmationVisible(true)
    }

    const handleFinalMoth = async () => {
        setConfirmationVisible(false)
        setLoading(true)

        const moth = new Moth()
        moth.fromInit(formData.name,new Date(formData.dateStart),true)
        const result = await setSaveNewMoth(moth, showError)

        if (result.status === 200) {
            onSave()
            onClose()
        }

        setLoading(false)
    }

    return (
        <div className={style.overlay}>
            <div className={style.alert}>
                {!loading ?
                    <>
                        <div className={style.title1}>
                            <TitlePageResponsiveIcon subtitle={"INICIALIZAR NOVO MÊS"}
                            children={
                                <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={onClose} />
                            }
                                path={"Escolha a Data de Inicio..."} icon={faCalendarPlus} />
                            
                        </div>
                        <div className={style.div1}>
                            <InputModelsBlock legend={'NOME DO MÊS'} onChange={handleChange} validation={needData.name} maxLength={10}
                                value={formData.name}
                                name={'name'} iconButton={faSignature} type={"text"} placeholder={"Nome/Apelido do Mês..."} />
                            <Space top={5}/>
                            <InputModelsBlock legend={'DATA DE INICIO'} onChange={handleChange} validation={needData.dateStart} maxLength={10}
                                value={formData.dateStart}
                                name={'dateStart'} iconButton={faCalendar} type={"date"} placeholder={"Data de Finilização do Mês..."} />
                        </div>
                        <Space top={5} />
                        <div className={style.divButton1}>
                            <GetButton title={'INICIAR AGORA'} color='#007bff' icon={faSave} handleFunction={handleSubmitSave} />
                        </div>
                    </> : <Loader titleFinding={'Salvando Dados no Servidor...'}
                        subtitleFinding={'Aguarde...'} loading={loading} />}
                {confirmationVisible ? <ConfirmationPopup title={'INICIAR UM NOVO MÊS'}
                    message={'Você Tem Certeza que Deseja Iniciar um Novo o Mês?'} onCancel={() => setConfirmationVisible(false)}
                    onConfirm={handleFinalMoth} /> : null}
                {isVisible ? <Alert message={valueAlert} top={25} right={25} onClose={() => setAlert(false)} type={"error"} /> : null}
            </div>
        </div>
    )
}

export default ControllerMoth