class AccountingAllReceiversThisMoth{
    constructor(){     
        this.listPaidSalesThisMoth = [] //PaidSale
        this.valueTotalPaid= 0
        this.amountTotalPaid  = 0
    }

    getValueTotalPaid(){
        return this.valueTotalPaid.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    fromInit(listPaidSalesThisMoth){
        this.listPaidSalesThisMoth = listPaidSalesThisMoth
        this.calculate()
    }

    calculate(){
        for (var element of this.listPaidSalesThisMoth) {
            this.valueTotalPaid += element.totalValuePaid
            this.amountTotalPaid++
        }
    }
    clear(){
        this.listPaidSalesThisMoth = []
        this.valueTotalPaid= 0
        this.amountTotalPaid  = 0
    }

}

export default AccountingAllReceiversThisMoth