import { sendRequestGetLocal, sendRequestPostLocal } from "../backEnd/requesition"
import Sale from "../objects/Sale/Sales"
import { auxSale, findClients, listEmployeeMemory, listItemsMemory, listProductsMemory } from "../backEnd/Memory"
import VersionProduct from "../objects/VersionProduct"
import { getNfeEmitedWithId } from "./CloudNfeEmited"
import { setCancelNFe } from "./CloudNfeProcess"
import { differenceInDays, parseISO } from "date-fns"


const _routerFromGetPaymentInPeriodInCloud = '/sales/getSaleWithPaymentInPeriod'
const _routerFromGetAllOpenSaleInCloud = '/sales/getOpenSale'
const _routerFromGetSaleWithIdInCloud = '/sales/getSaleWithId'
const _routerFromGetAllSaleInPeriodInCloud = '/sales/getSalesInPeriod'

const _routerFromAddNewSaleInCloud = '/sales/addSales'
const _routerFromGetListSaleInCloud = '/sales/getSales'
const _routerFromCancelSaleInCloud = '/sales/cancelSale'
const _routerFromUpdateSaleDescriptionInCloud = '/sales/updateDescription'
const _routerFromAddNewPaymentInCloud = '/sales/addNewPayment'
const _routerFromEditeSaleInCloud = '/sales/editeSale'


export const getListSalesFromClient = async (client,dateTime,showError) => {
    const list = []
    try {
        var results = []
        var body = {
            'IdClient': client.IdClient,
            'dateTimeStart':dateTime,
        }
        results = await sendRequestPostLocal(_routerFromGetListSaleInCloud,body)

        if(Array.isArray(results.data)){
            Object.values(results.data).forEach(sales =>{ 
                const newSale = new Sale()
            
                var indexEmployee = listEmployeeMemory.findIndex(ss => ss.idEmployee === sales.idEmployee)
                var employee = null
                if(indexEmployee !== -1){
                    employee = listEmployeeMemory[indexEmployee] 
                }

              
                if(sales.isCancel){
                    var indexEmployeeCancel = listEmployeeMemory.findIndex(ss => ss.idEmployee === sales.idSelleCancel)
                    var employeeCancel = null
                    if(indexEmployeeCancel !== -1){
                        employeeCancel = listEmployeeMemory[indexEmployeeCancel] 
                    }
                }

                newSale.fromCloud(sales,client,listProductsMemory,listItemsMemory,employee,employeeCancel)
                list.push(newSale)
            })
        }else throw new Error(results.response.data.sqlMessage)
      
    } catch (error) {
        showError('ERROR AO BUSCAR VENDAS NO SERVIDOR',error.message)     
    }finally{
        return list
    }
}

export const setUpdateDescription = async (sale,showError) =>{
    var results

    try {
            results = await sendRequestPostLocal(
            _routerFromUpdateSaleDescriptionInCloud,{
                'idSale':sale.idSaleCloud,
                'description':sale.detailsSale.description
            })

        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO SALVAR NOVAR DESCRIÇÃO DA VENDA',error.message)     
        return {status:404}
    }
}

export const setEditeSale = async (saleEdite,newProductList,newItemList,showError) =>{
    var results

    try {

        const itemsNewSale = {}
        const genericProducts = {}

        newItemList.forEach(ss=>{
            const version = new VersionProduct()
            ss.versionProduct = version.configureNewVersion('Esse Item Foi Vendido, Venda Editada' + saleEdite.getCodeSale())
            itemsNewSale[ss.itemGlobal.codebar] = ss.getMapFromCloud()
        })

        newProductList.forEach(ss=>{
            const version = new VersionProduct()
            ss.versionProduct = version.configureNewVersion('Esse Item Foi Vendido, Venda Editada' + saleEdite.getCodeSale())
            genericProducts[ss.idGenericProducts] = ss.getMapFromCloud()
        })

        results = await sendRequestPostLocal(
                _routerFromEditeSaleInCloud,{
                'container-listItems':itemsNewSale,
                'container-genericProductsList':genericProducts,
                'newSale':saleEdite.getMapFromCloud(),
                'insertStock':auxSale.insertStock.getMapFromCloud()
            })

        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO SALVAR EDIÇÃO DE VENDA',error.message)     
        return {status:404}
    }
}

export const updateSalePayments = async (sale,paymentController,showError) =>{
    var results

    try {
       
        results = await sendRequestPostLocal(
            _routerFromAddNewPaymentInCloud,{
                'payment': paymentController.getMapFromCloud(),
                'isOpen': paymentController.status === 'DEVENDO',
                'idSale': sale.idSaleCloud,
                'idClient':sale.thisOwner.IdClient,
            })

        if(results.status !== 200) throw new Error(results.response.data.sqlMessage) 
        else 
        {
            findClients(showError)
            return results
        }
            
    } catch (error) {
        showError('ERROR AO SALVAR OS PAGAMENTOS DA VENDA',error.message)    
        return {status:404}
    }
}

export const updateSaleCancel = async (sale,reason,showError) =>{
    var results

    try {
        sale.setCancel(reason)

        if (sale.hasNfeEmited()) {
            const nfeEmitedTemp = await getNfeEmitedWithId(sale.idSaleCloud, showError)
            nfeEmitedTemp.setCancel(reason);
            await setCancelNFe(nfeEmitedTemp, showError);
        }

        results = await sendRequestPostLocal(
            _routerFromCancelSaleInCloud,sale.getMapFromCloud())

        if(results.status !== 200) throw new Error(results.response.data.sqlMessage) 
        else return results
    } catch (error) {
        showError('ERROR AO CANCELAR A VENDA',error.message) 
        return {status:404}
    }
}

export const getAllSalesOpen = async (showError,genericProducts,listItemsBj,employeeList,clients) => {
    const list = []
    try {
        var results = []
        results = await sendRequestGetLocal(_routerFromGetAllOpenSaleInCloud)
        if(Array.isArray(results)){
            genericCreateSale(results,list,genericProducts,listItemsBj,employeeList,clients)
        }else throw new Error(results.response.data.sqlMessage)   
    
    } catch (error) {
        showError('ERROR AO BUSCAR VENDAS EM ABERTO NO SERVIDOR',error.message)     
    }finally{
        return list
    }
}

export const getSaleWithID = async (showError,idSale,genericProducts,listItemsBj,employeeList,clients) => {
    const list = []
    try {
        var results = []
        const body = {'idSale' : idSale}
        results = await sendRequestPostLocal(_routerFromGetSaleWithIdInCloud,body)
        if(Array.isArray(results.data)){
            genericCreateSale(results.data,list,genericProducts,listItemsBj,employeeList,clients)
        }else throw new Error(results.response.data.sqlMessage)   
    } catch (error) {
        showError('ERROR AO BUSCAR VENDAS EM ABERTO NO SERVIDOR',error.message)     
    }finally{
        return list.length >0 ? list[0] : []
    }
}

export const getSalesWithPaymentInPeriod = async (datetimeStart,dateTimeEnd,showError,genericProducts,listItemsBj,employeeList,clients) => {
    const list = []
    try {
        var results = []
        var body = {
            'dateTimeStart':datetimeStart.toISOString(),
            'dateTimeEnd':dateTimeEnd.toISOString()
        }

        results = await sendRequestPostLocal(_routerFromGetPaymentInPeriodInCloud,body)
 
        if(Array.isArray(results.data)){
            genericCreateSale(results.data,list,genericProducts,listItemsBj,employeeList,clients)
        }else throw new Error(results.response.data.sqlMessage)
      
    } catch (error) {
        showError('ERROR AO BUSCAR VENDAS COM PAGAMENTOS NO SERVIDOR',error.message)     
    }finally{
        return list
    }
}

export const getSalesInPeriod = async (datetimeStart,dateTimeEnd,showError,genericProducts,listItemsBj,employeeList,clients) => {
    const list = []
    try {
        var results = []
        var body = {
            'dateTimeStart':datetimeStart.toISOString(),
            'dateTimeEnd':dateTimeEnd.toISOString()
        }

        results = await sendRequestPostLocal(_routerFromGetAllSaleInPeriodInCloud,body)
     
        if(Array.isArray(results.data)){
            genericCreateSale(results.data,list,genericProducts,listItemsBj,employeeList,clients)
        }else throw new Error(results.response.data.sqlMessage)
        
    } catch (error) {
        showError('ERROR AO BUSCAR VENDAS NO SERVIDOR',error.message)     
    }finally{
        return list
    }
}

export const setSaveSale = async (sale,showError) =>{
    var results

    try {
       
        results = await sendRequestPostLocal(_routerFromAddNewSaleInCloud,sale.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
     
    } catch (error) {
        showError('ERROR AO SALVAR NOVA VENDA NO SERVIDO',error.message)     
        return {status:404}
    }
}

function genericCreateSale(results,list,genericProducts,listItemsBj,employeeList,clients){
    Object.values(results).forEach(sales =>{ 
        const newSale = new Sale()
    
        var indexEmployee = employeeList.findIndex(ss => ss.idEmployee === sales.idEmployee)
        var employee = null
        if(indexEmployee !== -1){
            employee = employeeList[indexEmployee] 
        }

        let indexClient = clients.findIndex(ss => ss.IdClient === sales.IdClient)
        let client = null
        if(indexClient !== -1){
            client = clients[indexClient] 
        }

        if(sales.isCancel){
            var indexEmployeeCancel = employeeList.findIndex(ss => ss.idEmployee === sales.idSelleCancel)
            var employeeCancel = null
            if(indexEmployeeCancel !== -1){
                employeeCancel = employeeList[indexEmployeeCancel] 
            }
        }

        newSale.fromCloud(sales,client,genericProducts,listItemsBj,employee,employeeCancel)
        list.push(newSale)
    })
}