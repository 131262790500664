import { forwardRef, useImperativeHandle, useState } from "react";
import InputModelsBlock from "../../layout/InputModelsBlock";
import style from '../../style/GetNFeConfigureCompany.module.css'
import { faAddressCard, faAngleDown, faAngleUp, faCircleQuestion, faCodeBranch, faCodeCommit, faEarthAsia, faExpand, faExpandAlt, faListNumeric, faLock, faMapLocation, faMapLocationDot, faPhone, faSignHanging, faSignature } from "@fortawesome/free-solid-svg-icons";
import Space from "../../layout/Space";
import Alert from "../../Models/Alert";
import { TitlePageResponsiveIcon } from "../../layout/TitlePage";
import { GetButton, GetButtonIcon } from "../../layout/ButtonsLayout";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import { GetSmallModels } from "../../Models/GetModels";
import { GenericDropDown } from "../../layout/GenericDropDown";

const getFormData = (initialValue) => {
    return {
        xLgr:initialValue? initialValue.ender.xLgr: '',
        nro:initialValue? initialValue.ender.nro:'',
        xBairro:initialValue? initialValue.ender.xBairro:'',
        cMun:initialValue? initialValue.ender.cMun:'',
        xMun:initialValue? initialValue.ender.xMun:'',
        UF:initialValue? initialValue.ender.UF:'MT',
        CEP:initialValue? initialValue.ender.CEP:'',
        cPais:initialValue? initialValue.ender.cPais:'1058',
        xPais:initialValue? initialValue.ender.xPais:'Brasil',
        fone:initialValue? initialValue.ender.fone:''
    }
}

const getFormNeed = (needComplete) => {
    
    if(needComplete){
        return {
            xLgr:false,
            nro:false,
            xBairro:false,
            cMun:false,
            xMun:false,
            UF:false,
            CEP:false,
            cPais:false,
            xPais:false,
        }
    }else{
        return {
        }
    }
 
}

export const GetNfeAddress = forwardRef(({ title,initialExpand,initialValue,needComplete,isBlockEdite}, ref) => {

    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData(initialValue))
    const [needData, setNeedData] = useState(getFormNeed(needComplete))
    const [expanded, setExpand] = useState(initialExpand?? true)

    useImperativeHandle(ref, () => ({
        handleSubmit
    }))

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmit= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setAlert(true)
            return false
        }

        return formData
    }

    return (
        <div className={style.div}>
            <TitlePageResponsiveIcon 
                children={
                    <GetButtonIcon color='#0033d9' height={40} width={40} icon={expanded? faAngleUp :  faAngleDown} handleFunction={()=>setExpand(!expanded)}/> 
                }
                subtitle={title} path={"Configure o Endereço Abaixo..."} icon={faAddressCard}/> 
            <div className={expanded? style.visible :   style.notVisible}>
            {isBlockEdite? <GetSmallModels title={'EDIÇÃO DO ENDEREÇO DO EMISSOR BLOQUEADO'} backgroundColor={"#ff0000"} icon={faLock} subtitle={'Para Editar, Vá em Fiscal e Edite no Campo Emissor NFe'}/> : null}
                <GetSmallModels 
                    children={
                        <GetButton responsive={true} color='#FF0000' title={'AJUDA'}  icon={faCircleQuestion} handleFunction={()=>window.open('https://www.ibge.gov.br/explica/codigos-dos-municipios.php','_black')}/> 
                    }
                    icon={faCircleQuestion}
                    title={'BUSCAR CÓDIGO DO MUNICÍPIO NO IBGE'} backgroundColor={"#005f00"} subtitle={'Todos os Municípios do País tem um Código'}/>
                <div className={style.divInRow}>
                    <InputModelsBlock disable={isBlockEdite} legend={'LOGRADOURO'} onChange={handleChange} validation={needData.xLgr} maxLength={45} value={formData.xLgr} name={'xLgr'} iconButton={faMapLocationDot} type={"text"} placeholder={"Logradouro..."}/>
                    <Space left={5} top={5}/>
                    <InputModelsBlock disable={isBlockEdite} legend={'NÚMERO'} onChange={handleChange} validation={needData.nro} maxLength={10} value={formData.nro} name={'nro'} iconButton={faListNumeric} type={"number"} placeholder={"Número..."}/>  
                    <Space left={5} top={5}/>
                    <InputModelsBlock disable={isBlockEdite} legend={'BAIRRO'} onChange={handleChange} validation={needData.xBairro} maxLength={45} value={formData.xBairro} name={'xBairro'} iconButton={faMapLocation} type={"text"} placeholder={"Bairro..."}/>   
                    <Space left={5} top={5}/>
                    <InputModelsBlock disable={isBlockEdite} legend={'CÓDIGO DO MUNICÍPIO'} onChange={handleChange} validation={needData.cMun} maxLength={15} value={formData.cMun} name={'cMun'} iconButton={faCodeBranch} type={"number"} placeholder={"Código Municípal..."}/>    
                </div>
                <Space left={5} top={5}/>
                <div className={style.divInRow}>
                    <InputModelsBlock disable={isBlockEdite} legend={'NOME DO MUNICÍPIO'} onChange={handleChange} validation={needData.xMun} maxLength={45} value={formData.xMun} name={'xMun'} iconButton={faSignature} type={"text"} placeholder={"Nome do Município..."}/>  
                    <Space left={5} top={5}/>
                    <GenericDropDown disable={isBlockEdite} legend={'SIGLA DO ESTADO'} 
                            data={[ 
                            'RO', 
                            'AC',
                            'AM',
                            'RR',
                            'PA',
                            'AP',
                            'TO',
                            'MA',
                            'PI',
                            'CE',
                            'RN',
                            'PB',
                            'PE',
                            'AL',
                            'SE',
                            'BA',
                            'MG',
                            'ES',
                            'RJ',
                            'SP',
                            'PR',
                            'SC',
                            'RS',
                            'MS',
                            'MT',
                            'GO',
                            'DF']} 
                        name={'UF'} initialState={formData.UF} handlerFuncion={handleChange}/>
                    <Space left={5} top={5}/>
                    <InputModelsBlock disable={isBlockEdite} legend={'CEP'} onChange={handleChange} validation={needData.CEP} maxLength={10} value={formData.CEP} name={'CEP'} iconButton={faMap} type={"number"} placeholder={"Cep..."}/>    
                </div>
                <Space left={5} top={5}/>
                <div className={style.divInRow}>
                    <InputModelsBlock disable={isBlockEdite} legend={'NOME DO PAÍS'} onChange={handleChange} validation={needData.xPais} maxLength={45} value={formData.xPais} name={'xPais'} iconButton={faEarthAsia} type={"text"} placeholder={"Nome do País..."}/>    
                    <Space left={5} top={5}/>
                    <InputModelsBlock  disable={isBlockEdite} legend={'CÓDIGO DO PAÍS'} onChange={handleChange} validation={needData.cPais} maxLength={10} value={formData.cPais} name={'cPais'} iconButton={faCodeCommit} type={"number"} placeholder={"Código do País..."}/>    
                    <Space left={5} top={5}/>
                    <InputModelsBlock disable={isBlockEdite}  legend={'TELEFONE'} onChange={handleChange} maxLength={15} value={formData.fone} name={'fone'} iconButton={faPhone} type={"number"} placeholder={"Telefone de Contato..."}/>         
                </div>
            </div>
            {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
    )
})