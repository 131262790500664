import React, { useEffect, useState } from "react";
import style from "../../style/AddClients.module.css"
import {faAddressBook,faEnvelope,faPhoneVolume,faPhone,faPaperclip,faNoteSticky,faNewspaper,
faCalendarDays,faGift,faPersonBreastfeeding,faRing,faFeatherPointed,faBuildingNgo,faMapLocationDot,faBuildingColumns,faBuilding,
faTreeCity,faLocationArrow,faAddressCard,faMoneyCheckDollar,faMoneyBillTrendUp,faMoneyBillTransfer,faPiggyBank,faVault,faPerson
,faGavel,faHandsHoldingChild,faBook,faDownLeftAndUpRightToCenter,faCompress, faPersonCirclePlus, faSave, faUserPen, faLock, faFilePdf
} from "@fortawesome/free-solid-svg-icons";
import { GetSmallModels, } from "../../Models/GetModels.js";
import StatesCountry from "../../layout/StatesCountry.js";
import  { TitlePageResponsiveIcon } from "../../layout/TitlePage.js";
import Space from '../../layout/Space.js'
import GetFloatingButton, { GetFloatingButtonWithLegend } from "../../layout/GetFloatingButton.js";
import Alert from "../../Models/Alert.js";
import ConfirmationPopup from "../../Models/ConfirmationPopup.js";
import Loader from "../../layout/Loader.js";
import { setNewClient, setUpdateClient } from "../../controllers/CloudClients.js";
import ClientsObject from "../../objects/ClientsAndPerson/clientsObject.js";
import StatusClientDropDown from "../../layout/StatusClientDropDown.js";
import { useNavigate } from "react-router-dom";
import { routerFromListClients } from "../../routers/routerList.js";
import { useError, useLoading } from "../../AppContext.js";
import { findClients, updateIfNull } from "../../backEnd/Memory.js";
import { employeeData } from "../../backEnd/InitController.js";
import { GetButtonIcon } from "../../layout/ButtonsLayout.js";
import InputModelsBlock from "../../layout/InputModelsBlock.js";

const getFormData = (currentClient) => {
    if (currentClient === undefined) {
        return {
            name: '',
            rg: '',
            cpf: '',
            dateBorn: '',
            nameFather: '',
            nameMother: '',
            nameSpouse: '',
            rgDateEmission: '',
            profession: '',
            rgGovernationEmission: '',
            email: '',
            number1: '',
            number2: '',
            state: 'MATO GROSSO',
            city: '',
            address: '',
            cep: '',
            bankName: '',
            account: '',
            agency: '',
            numberAccount: '',
            ownerAccount: '',
            typeAccount: '',
            keycodePix: '',
            socialReason: '',
            fantasyName: '',
            cnpj: '',
            simplesNational: '',
            subscriptionState: '',
            statusClient:'ATIVA',
            isOpenSales:false,
            IdClient:0,
            idPerson:0,
            idSimpleContact:0,
            idAddress:0,
            idBank:0,
            idGovernmentData:0
        }
    }

    return {

        IdClient:currentClient.IdClient,
        isOpenSales:currentClient.isOpenSales,
        statusClient: currentClient.statusClient,

        idPerson:  currentClient._genericPerson.idPerson,
        name: currentClient._genericPerson.name,
        rg: currentClient._genericPerson.rg,
        cpf: currentClient._genericPerson.cpf,
        dateBorn: currentClient._genericPerson.dateBorn,
        nameFather: currentClient._genericPerson.nameFather,
        nameMother: currentClient._genericPerson.nameMother,
        nameSpouse: currentClient._genericPerson.nameSpouse,
        rgDateEmission: currentClient._genericPerson.rgDateEmission,
        profession: currentClient._genericPerson.profession,
        rgGovernationEmission: currentClient._genericPerson.rgGovernationEmission,

        idSimpleContact: currentClient._contact.idSimpleContact ,
        email: currentClient._contact.email,
        number1: currentClient._contact.number1,
        number2: currentClient._contact.number2,

        idAddress: currentClient._clientAddress.idAddress,
        state: currentClient._clientAddress.state,
        city: currentClient._clientAddress.city,
        address: currentClient._clientAddress.address,
        cep: currentClient._clientAddress.cep,

        idBank:currentClient._clientBank.idBank,
        bankName: currentClient._clientBank.bankName,
        account: currentClient._clientBank.account,
        agency: currentClient._clientBank.agency,
        ownerAccount: currentClient._clientBank.ownerAccount,
        typeAccount: currentClient._clientBank.typeAccount,
        keycodePix: currentClient._clientBank.keycodePix,

        idGovernmentData:currentClient._clientCompany.idGovernmentData,
        socialReason: currentClient._clientCompany.socialReason,
        fantasyName: currentClient._clientCompany.fantasyName,
        cnpj: currentClient._clientCompany.cnpj,
        simplesNational: currentClient._clientCompany.simplesNational,
        subscriptionState: currentClient._clientCompany.subscriptionState,
    }
}


function AddClient({client}){
    const navidate = useNavigate()

    const [currentClient] = useState(client?? undefined)
    const [employeeUser] = useState(employeeData())
    const [canAdd] = useState(employeeUser.employeePermission.permissionsClients[2])
    const [CanEdite] = useState(employeeUser.employeePermission.permissionsClients[0])
    const [CanView] = useState(employeeUser.employeePermission.permissionsClients[1])

    const [confirmationVisible, setConfirmationVisible] = useState(false)

    const [isVisible, setIsVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const { showError } = useError()
    const { setIsLoading    }  = useLoading()

    const [formData, setFormData] = useState(getFormData(currentClient))
    const [needData, setNeedData] = useState({
        name: false,
    })

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            await updateIfNull(showError) 
            setIsLoading(false)  
        }
        fetchData()
    }, [])

    const handleConfirm =async () => {
        setConfirmationVisible(false)
        setLoading(true)
        setIsLoading(true)
        var result
        var clientTemp = new ClientsObject()
        clientTemp.fromFormData(formData)
        if(currentClient)
            result = await setUpdateClient(clientTemp,showError)
        else
            result = await setNewClient(clientTemp,showError)
          
        if(result.status === 200){
            await findClients()
            navidate(routerFromListClients)
        }
        setFormData(getFormData(currentClient))
       
        setIsLoading(false)
        setLoading(false)
    }
  
    const handleCancel = () => {
        setConfirmationVisible(false)
    }

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleOpenConfirmation = () => {
        setConfirmationVisible(true)
    }

    const handleSubmitSave= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setIsVisible(true)
            return
        }

        handleOpenConfirmation()
    }

    const handleOpenPdf = async()=>{
        setIsLoading(true)
        var clientTemp = new ClientsObject()
        clientTemp.fromFormData(formData)
        await clientTemp.openPDFClient(showError)
        setIsLoading(false)
    }

    var labelTitleConfirmation = !currentClient? 'CADASTRAR NOVA CLIENTE' : 'EDITAR CLIENTE'
    var labelLegendConfirmation = !currentClient? 'Tem Certeza que Deseja Cadastrar uma Nova Cliente?' : 
    'Tem Certeza que Deseja Editar os Dados da Cliente?'

    var labelButton = !currentClient? 'Salvar Cliente' : 'Salvar EDIÇÃO'
    var labelTitle = !currentClient? 'Nova Cliente/' : 'Editar Cliente' 
    var labelSubTitle = !currentClient? 'Cadastrar Nova Cliente' : '' 
    var icon = !currentClient? faPersonCirclePlus: faUserPen

    return(
        <div>
            <div className={style.containerGlobal}>
            <Space top={5}/>
                {CanView? 
                <>{ !loading ? <div className={style.div}>
                    <div className={style.form}>
                            <div className={style.containerDataDefault}>
                                    <div className={style.containerData}>
                                    <TitlePageResponsiveIcon title={labelTitle} subtitle={labelSubTitle} 
                                        children={
                                        <div className={style.row}>
                                            {currentClient? <>
                                                <StatusClientDropDown disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} initialState={formData.statusClient.toUpperCase()} handlerFuncion={handleChange} 
                                                    name={'statusClient'}/>
                                                <Space left={5}/>
                                                <GetButtonIcon 
                                                handleFunction={handleOpenPdf}
                                                icon={faFilePdf} 
                                                color={"#ff0000"}  /> 
                                            </>: null}  
                                        </div>
                                        }
                                    path={window.innerWidth > 1200? 'É Necessário Apenas o Nome, Porém é Recomendado Completar os Dados...': ''} icon={icon}/>
                                        <div className={style.divRow}>
                                            <InputModelsBlock legend={'NOME DA CLIENTE'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} validation={needData.name} value={formData.name} maxLength={100} onChange={handleChange} name={'name'} iconButton={faAddressBook} type={"text"} placeholder={"Nome da Cliente..."}/>
                                            <InputModelsBlock legend={'DATA DE NASCIMENTO'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'dateBorn'} value={formData.dateBorn} iconButton={faGift} type={"date"} onChange={handleChange} maxLength={10} placeholder={"Data de Nascimento..."}/>  
                                            <InputModelsBlock legend={'NOME DO PAI'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'nameFather'} value={formData.nameFather} iconButton={faFeatherPointed} onChange={handleChange} type={"text"} maxLength={100} placeholder={"Nome do Pai..."}/>  
                                            <InputModelsBlock legend={'NOME DA MÃE'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'nameMother'} value={formData.nameMother} iconButton={faPersonBreastfeeding} onChange={handleChange} type={"text"} maxLength={100} placeholder={"Nome da Mãe..."}/>    
                                            <InputModelsBlock legend={'NOME DO CÔNJUGE'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'nameSpouse'} value={formData.nameSpouse} iconButton={faRing} type={"text"} onChange={handleChange} maxLength={100} placeholder={"Nome do Cônjuge..."}/>   
                                        </div>
                                    </div>   
                                    <div className={style.containerData}>
                                        <GetSmallModels backgroundColor={'#000000'} title={"CPF / RG"} subtitle={"São os Dados Governamentais..."} icon={faPaperclip}/>
                                       {/* <InputModels name={'profession'} value={formData.profession} iconButton={faBook} onChange={handleChange} maxLength={45} type={"text"} placeholder={"Profissão..."}/>*/}
                                       <div className={style.divRow}>
                                            <InputModelsBlock legend={'RG'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'rg'} iconButton={faNoteSticky} value={formData.rg} maxLength={45} onChange={handleChange} type={"text"} placeholder={"RG..."}/>
                                            <InputModelsBlock legend={'CPF'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'cpf'} iconButton={faNewspaper} value={formData.cpf} maxLength={15} onChange={handleChange} type={"text"} placeholder={"CPF..."}/>  
                                            <InputModelsBlock legend={'DATA DE EMISSÃO DO RG'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'rgDateEmission'} value={formData.rgDateEmission} iconButton={faCalendarDays} onChange={handleChange} maxLength={10} type={"date"} placeholder={"Data de Emissão do RG..."}/>  
                                            <InputModelsBlock legend={'ÓRGÃO EXPEDIDOR'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'rgGovernationEmission'} value={formData.rgGovernationEmission} onChange={handleChange} iconButton={faBuildingNgo} maxLength={50} type={"text"} placeholder={"órgão Expedidor do RG..."}/>     
                                        </div>
                                    </div> 
                                    <div className={style.containerData}>
                                        <GetSmallModels backgroundColor={'#000000'} title={"CONTATOS"} subtitle={"São os Meios de Contato..."} icon={faAddressBook}/>
                                        <div className={style.divRow}>
                                            <InputModelsBlock legend={'E-mail'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'email'} value={formData.email} iconButton={faEnvelope} onChange={handleChange} maxLength={90} type={"email"} placeholder={"E-mail..."}/>
                                            <InputModelsBlock legend={'TELEFONE'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'number1'} value={formData.number1} iconButton={faPhoneVolume} onChange={handleChange} maxLength={15} type={"tel"} placeholder={"Telefone..."}/>  
                                            <InputModelsBlock legend={'TELEFONE SECUNDARIO'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'number2'} value={formData.number2} iconButton={faPhone} onChange={handleChange} maxLength={15} type={"tel"} placeholder={"Telefone Secundario..."}/>  
                                        </div>
                                    </div> 
                                    <div className={style.containerData}>
                                        <GetSmallModels backgroundColor={'#000000'} title={"ENDEREÇO"} subtitle={"É o Endereço da Cliente..."} icon={faMapLocationDot}/>                                       
                                        <div className={style.divRow}>
                                            <InputModelsBlock legend={'CIDADE'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'city'} value={formData.city} onChange={handleChange} iconButton={faTreeCity} maxLength={45} type={"text"} placeholder={"Cidade..."}/>
                                            <InputModelsBlock legend={'ENDEREÇO'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'address'} value={formData.address} onChange={handleChange} iconButton={faLocationArrow} maxLength={150} type={"text"} placeholder={"Endereço..."}/>  
                                            <InputModelsBlock legend={'CEP'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'cep'} value={formData.cep} onChange={handleChange} iconButton={faAddressCard} maxLength={15} type={"number"} placeholder={"Cep..."}/>  
                                            <div className={style.dropDownState}> <StatesCountry disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} initialState={formData.state.toUpperCase()} handlerFuncion={handleChange} 
                                            name={'state'}/> </div>
                                        </div>
                                    </div>  
                            </div>   
                            <div className={style.containerDataDefault}>               
                                    <div className={style.containerData}>
                                        <GetSmallModels backgroundColor={'#000000'} title={"DADOS BANCÁRIOS"} subtitle={"São os Dados Bancários"} icon={faBuildingColumns}/>
                                        <div className={style.divRow}>
                                            <InputModelsBlock legend={'NOME DO BANCO'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'bankName'} value={formData.bankName} onChange={handleChange} iconButton={faMoneyCheckDollar} maxLength={55} type={"text"} placeholder={"Nome do Banco..."}/>
                                            <InputModelsBlock legend={'TIPO DE CONTA'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'typeAccount'} value={formData.typeAccount} onChange={handleChange} iconButton={faMoneyBillTrendUp} maxLength={25} type={"text"} placeholder={"Tipo de Conta"}/>  
                                            <InputModelsBlock legend={'AGÊNCIA'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'agency'} value={formData.agency} onChange={handleChange} iconButton={faMoneyBillTransfer} maxLength={15500} type={"number"} placeholder={"Agência..."}/>  
                                            <InputModelsBlock legend={'NÚMERO DA CONTA'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'account'} value={formData.account} onChange={handleChange} iconButton={faPiggyBank} maxLength={55} type={"number"} placeholder={"Número da Conta..."}/>   
                                            <InputModelsBlock legend={'TITULAR DA CONTA'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'ownerAccount'} value={formData.ownerAccount} onChange={handleChange} iconButton={faPerson} maxLength={55} type={"text"} placeholder={"Titular da Conta..."}/> 
                                            <InputModelsBlock legend={'CÓDIGO DO PIX'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'keycodePix'} value={formData.keycodePix} onChange={handleChange} iconButton={faVault} maxLength={260} type={"text"} placeholder={"Código do Pix..."}/>   
                                        </div>
                                    </div> 
                                    <div className={style.containerData}>
                                        <GetSmallModels backgroundColor={'#000000'} title={"DADOS EMPRESARIAIS"} subtitle={"São os Dados da Empresa..."} icon={faBuilding}/>
                                        <div className={style.divRow}>
                                            <InputModelsBlock legend={'RAZÃO SOCIAL'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'socialReason'} value={formData.socialReason} onChange={handleChange} iconButton={faGavel} type={"text"} maxLength={100} placeholder={"Razão Social..."}/>
                                            <InputModelsBlock legend={'NOME FANTASIA'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'fantasyName'} value={formData.fantasyName} onChange={handleChange} iconButton={faHandsHoldingChild} maxLength={150} type={"text"} placeholder={"Nome Fantasia..."}/>  
                                            <InputModelsBlock legend={'CNPJ'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'cnpj'} value={formData.cnpj} iconButton={faBook} onChange={handleChange} type={"number"} maxLength={20} placeholder={"CNPJ..."}/>  
                                            <InputModelsBlock legend={'SIMPLES NACIONAL'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'simplesNational'} value={formData.simplesNational} onChange={handleChange} iconButton={faDownLeftAndUpRightToCenter} maxLength={25} type={"text"} placeholder={"Simples Nacional..."}/>  
                                            <InputModelsBlock legend={'INSCRIÇÃO ESTADUAL'} disable={!((CanEdite && currentClient) || (canAdd && !currentClient))} name={'subscriptionState'} value={formData.subscriptionState} onChange={handleChange} iconButton={faCompress} type={"text"} maxLength={45} placeholder={"Inscrição Estadual..."}/>  
                                        </div>
                                    </div> 
                            </div>  
                    </div>
                </div> : (
                    <div className={style.saveLoad}>
                        <Loader titleFinding={'Salvando Dados no Servidor...'}
                        subtitleFinding={'Aguarde...'} loading={loading} />
                    </div>
                )}</>:
                <div className={style.div}>
                    <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                    subtitle={'Você Não Tem Permissão para Visualizar os Dados Cadastrais da Cliente'} />
                </div>}
            </div>
            {isVisible? <Alert  message={"Complete Todos os Campos em Vermelho"} onClose={() => setIsVisible(false)} type={"error"} />:null}
            {confirmationVisible? <ConfirmationPopup title={labelTitleConfirmation} 
            message={labelLegendConfirmation} onCancel={handleCancel} onConfirm={handleConfirm}/>: null}
            {((CanEdite && currentClient) || (canAdd && !currentClient))? <> {
                window.innerWidth > 1200 ?
                <GetFloatingButtonWithLegend legend={labelButton} width={225} icon={faSave} color='#007bff' margiBottom={'1%'} margiRight={'1%'} handleFunction={handleSubmitSave} />:
                <GetFloatingButton width={65} height={65} icon={faSave} color='#007bff' margiBottom={'1%'} margiRight={'1%'} handleFunction={handleSubmitSave} />
            }</>:null}
        </div>
    )
}

export default AddClient