class AccountingAllCommission{
    constructor(){
        this.accountingCommissions = {}
        this.valueAllComissions = 0
        this.valueComissionsCount = 0
        this.length = 0;
    }

    getListComissions(){
        return Object.values(this.accountingCommissions)
    }

    getValueAllComissions(){
        return this.valueAllComissions.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    calculate(){
        this.valueAllComissions = 0;
        this.valueAllComissions = 0;
        this.length = 0;

        Object.values(this.accountingCommissions).forEach(
            ss=>{
                this.length++
                ss.calculate()
                this.valueAllComissions += ss.FinvalValueWithFixedComission
                this.valueComissionsCount += ss.amountSale
            }
        )  
    }
    clear(){
        this.accountingCommissions = {}
        this.valueAllComissions = 0
        this.valueComissionsCount = 0
        this.length = 0;
    }
}

export default AccountingAllCommission