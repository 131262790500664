class AccountingControllerAllSalesReceiver{
    constructor(){
        this.valueAllReceiverInFuture = 0
        this.totalSaleReceiverInFuture = 0
        this.accountingAllSalesReceiver = [] // AccountingAllSalesReceiver
    }

  calculate(){
    this.valueAllReceiverInFuture = 0;
    this.totalSaleReceiverInFuture = 0;
    for (var element of this.accountingAllSalesReceiver) {
        this.valueAllReceiverInFuture += parseFloat(element.valueTotalOpen)
        this.totalSaleReceiverInFuture += parseInt(element.amountTotalSalesOpen)
    }
  }

  getTotalSaleReceiverInFuture(){
    return this.valueAllReceiverInFuture.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }
}

export default AccountingControllerAllSalesReceiver