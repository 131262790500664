import { SettingsNFE } from "../NFe/NFe"

class Brands{
    constructor(){
        this.idBrands = 0
        this.nameBrand =''
        this.linkImageBrand = ''
        this.descriptionBrand =''
        this.isActiveBrand = true

        this.settingsNFE = new SettingsNFE()
    }

    fromNewBrand(other){
        this.idBrands = 0
        this.linkImageBrand = ''   
        this.isActiveBrand = true

        this.nameBrand = other.nameBrand.toUpperCase()
        this.descriptionBrand = other.descriptionBrand.toUpperCase()
       
    }

    fromEditeBrand(other,status){
      this.nameBrand = other.nameBrand.toUpperCase()
      this.descriptionBrand = other.descriptionBrand.toUpperCase()
      this.isActiveBrand = status
    }
  
    fromCloud(data){
        this.idBrands = data.idBrands
        this.nameBrand = data.nameBrand
        this.linkImageBrand = data.linkImageBrand
        this.descriptionBrand = data.descriptionBrand
        this.isActiveBrand = data.isActiveBrand === 1 ? true: false;
        if(data.idTax) this.settingsNFE.fromCloud(data)
    }
  
    getMapFromCloud(){
      return{
        'idBrands': this.idBrands,
        'nameBrand': this.nameBrand,
        'linkImageBrand': this.linkImageBrand,
        'descriptionBrand': this.descriptionBrand,
        'isActiveBrand': this.isActiveBrand,
        'settigsTax': this.settingsNFE.getMapFromCloud()
      };
    }
}

export default Brands