import React, { useEffect, useState } from "react";
import style from '../../style/Financial.module.css'
import { GetSmallModels } from "../../Models/GetModels";
import { faCashRegister, faChartLine, faChartPie, faCircleInfo, faCommentsDollar, faDownload, faHandHoldingDollar, faLock, faMoneyBillTransfer, faPlus, faSackDollar, faSearch, faWallet } from "@fortawesome/free-solid-svg-icons";
import { GetButton } from "../../layout/ButtonsLayout";
import InputModelsBlock from "../../layout/InputModelsBlock";
import GetAmountList from "../../Models/GetAmountList";
import Space from "../../layout/Space";
import ModelFinancial, { ModelFinancialHelperName, ModelFinancialMobile } from "./ModelFinancial";
import GetAddFinancial from "./GetAddFinance";
import { useError } from "../../AppContext";
import { updateIfNull } from "../../backEnd/Memory";
import { getListFinancial, setRemove } from "../../controllers/CloudFinancial";
import Loader from "../../layout/Loader";
import ConfirmationPopup from "../../Models/ConfirmationPopup";
import { MathFinancial } from "../../backEnd/MathFinancial";
import DatePickerComponent from "../../layout/DatePickerComponent";
import GetPix from "../../Models/GetPix";
import { TitlePageResponsiveIcon } from "../../layout/TitlePage";
import { faPix } from "@fortawesome/free-brands-svg-icons";
import { employeeData } from "../../backEnd/InitController";

export function Financial(){

    const [selectedDate, setSelectedDate] = useState(() => {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() - 90)
      return currentDate.toISOString().slice(0,10)
    })


    const [canView] = useState(employeeData().employeePermission.permissionsFinancial[0])
    const [canEdite] = useState(employeeData().employeePermission.permissionsFinancial[1])
    const [selectedDateFinalDate, setSelectedDateFinalDate] = useState(new Date().toISOString().slice(0,10))
    const [showGetPix,setShowGetPix] = useState(false)
    const [mathFinancial] = useState( new MathFinancial())
    const [showAddFinancial,setShowAddFinancial] = useState(false)
    const [filter, setFilter] = useState('all')
    const [orderBy, setOrderBy] = useState('asc')
    const [list, setList] = useState([])
    const [filtered, setFiltered] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedFinancial, setSelectFinancial] = useState(null)
    const {showError} = useError()
    const [showConfirmSave,setShowConfirmSave] = useState(false)
    
      useEffect(() => {
        async function fetchData() {
            setLoading(true)
            await updateIfNull(showError) 
            await handlerFindDatabase()  
            setLoading(false) 
        }
        fetchData()
      }, [])
  
      const handlerFindDatabase= async()=>{
        setLoading(true)
        mathFinancial.calculate(filtered)
        const data = await getListFinancial(showError, selectedDate,selectedDateFinalDate)
        setList(data)
        setFiltered(data)
        mathFinancial.calculate(data)
        setLoading(false) 
      }
   
      const setDateTime = async (value)=>{
        setSelectedDate(value)
      }

      const setFinalDate = async (value)=>{
        setSelectedDateFinalDate(value)
      }

      useEffect(() => {
        handleChangeFilter()
      }, [filter])
  
  
  
      const controllerFilter = () => {
        let data = []
        switch (filter) {
          case 'all':
            data = list
            break
          case 'debits':
            data = list.filter(item => item.category === 'DEBITOS')
            break
          case 'credits':
            data = list.filter(item => item.category === 'CREDITOS')
            break  
            case 'open':
              data = list.filter(item => item.situation === 'EM ABERTO')
              break
            case 'close':
              data = list.filter(item => item.situation === 'FECHADO')
              break  
            case 'paper':
              data = list.filter(item => item.typePayment === 'BOLETO')
              break  
            case 'money':
              data = list.filter(item => item.typePayment === 'DINHEIRO')
                break
            case 'cheque':
              data = list.filter(item => item.typePayment === 'CHEQUE')
                break 
            case 'credit card':
              data = list.filter(item => item.typePayment === 'CARTÃO CREDITO')
                break
            case 'debit card':
              data = list.filter(item => item.typePayment === 'CARTÃO DEBITO')
                break 
          default:
            data = list
            break
        }
    
        let sorted = [...data]
     
        switch (orderBy) {
          case 'asc':
            sorted.sort((a, b) => a.name.localeCompare(b.name))
            break
          case 'desc':
            sorted.sort((a, b) => b.name.localeCompare(a.name))
            break
          default:
            break
        }
        return sorted;
      }
    
      const handleFilterChange = async (event) => {
        setFilter(event.target.value)
        setFiltered(filtered)
       
      }
      
      const handleOrderByChange = async (event) => {
        setOrderBy(event.target.value)
        setFiltered(filtered)

      }

      const onEdite =(financial)=>{
          setShowAddFinancial(true)
          setSelectFinancial(financial)
      }

      const onAdd =()=>{
        setShowAddFinancial(true)
        setSelectFinancial(null)
      }

      const handleRemove = async ()=>{
        setShowConfirmSave(false)
          setLoading(true)
          const result = await setRemove(selectedFinancial,showError)
          if(result.status === 200)
              await handlerFindDatabase()
          setLoading(false)
      }

      const handleConfigureRemove = async (selected) =>{
          setSelectFinancial(selected)
          setShowConfirmSave(true)
      }
    
      const handleChangeFilter = (value) => {
        const data = controllerFilter()
        if(value!= null){
          const filter = data.filter(
            client => {
              const filterName = client.name.toLowerCase().includes(value.target.value.toLowerCase())
              return filterName 
            }
          )
          setFiltered(filter)
        }else{
          setFiltered(data)
        }
      }

      return(
          <div className={style.containerGlobal}> 
              {canView?
              <>
                <div className={style.divInput}>
                  <TitlePageResponsiveIcon children={
                    <GetButton responsive={true} title={'GERAR PIX DE COBRANÇA'} 
                    handleFunction={()=>setShowGetPix(true)} icon={faPix} 
                    width={'100%'} height={50} color={'#007bff'}/>
                  } title={'CONTROLE FINANCEIRO'} path={'Registre Suas dívidas e Lucros.'} icon={faChartLine}/>
                </div>
                <Space top={25}/>
                <div className={style.MenuActions}>
                    <GetSmallModels icon={faCommentsDollar} title={'VENCIDAS'} 
                        value={mathFinancial.getOverdue()}/>
                    <Space left={5} top={5}/>
                        <GetSmallModels icon={faWallet} title={'VENCE HOJE'} 
                        value={mathFinancial.getOverdueToday()}/>
                    <Space left={5} top={5}/>
                        <GetSmallModels icon={faSackDollar} title={'HÁ VENCER'} 
                        value={mathFinancial.getfutureOverdue()}/>
                    <Space left={5} top={5}/>
                        <GetSmallModels icon={faMoneyBillTransfer} title={'PAGOS'} 
                        value={mathFinancial.getPaids()}/>
                    <Space left={5} top={5}/>
                        <GetSmallModels icon={faHandHoldingDollar} title={'RECEBIDOS'} 
                        value={mathFinancial.getreceivers()}/>
                    <Space left={5} top={5}/>
                        <GetSmallModels icon={faChartPie} title={'HÁ RECEBER'} value={mathFinancial.getfutureReceivers()}/>
                    <Space left={5} top={5}/>
                        <GetSmallModels icon={faCashRegister} title={'TOTAL'} value={mathFinancial.getTotal()}/> 
                </div>
                <div className={style.MenuActions}>
                    <div className={style.divInput}>
                        <InputModelsBlock iconButton={faSearch} placeholder={'Buscar Credito/Debito...'} 
                        legend={'BUSCAR DEBITO/CREDITO...'} onChange={handleChangeFilter} type={'text'} maxLength={100} />
                    </div>
                    <Space left={5} top={5}/>
                    <div className={style.containerDropDown}>
                        <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                            <option value="all">TODOS</option>
                            <option value="debits">DEBITOS</option>
                            <option value="credits">CREDITOS</option>
                            <option value="open">EM ABERTO</option>
                            <option value="close">FINALIZADO</option>
                            <option value="paper">BOLETO</option>
                            <option value="money">DINHEIRO</option>
                            <option value="cheque">CHEQUE</option>
                            <option value="credit card">CARTÃO DE CREDITO</option>      
                            <option value="debit card">CARTÃO DE DEBITO</option>      
                        </select>
                        <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                            <option value="asc">CRESCENTE</option>
                            <option value="desc">DESCRESCENTE</option>
                        </select>
                    </div>
                    <Space left={5} top={5}/>
                    <div className={style.divRow}>
                      <div className={style.divButRow}>
                        <DatePickerComponent
                        handlerFunction={setDateTime}
                        valueStart={selectedDate} label={'DATA INICIAL DA BUSCA'}/>
                        <DatePickerComponent
                        handlerFunction={setFinalDate}
                        valueStart={selectedDateFinalDate} label={'DATA FINAL DA BUSCA'}/>
                      </div>
                      {!loading?
                      <div className={style.divButRow}>
                        <GetButton title={'ADCIONAR'} handleFunction={onAdd} icon={faPlus} width={'100%'} height={50} color={'#ff0000'}/>
                        <Space top={5} left={5}/>
                        <GetButton title={'ATUALIZAR'} handleFunction={handlerFindDatabase} icon={faDownload} width={'100%'} height={50} color={'#007bff'}/>
                      </div>:null} 
                    </div>
                </div>
                <Space top={5}/>
                <div className={style.list}>
                { !loading? <>
                <GetAmountList title={'Debitos/Creditos na Busca...'} amount={filtered.length}/>
                    {filtered.length > 0 && window.innerWidth > 1280? <ModelFinancialHelperName/>: null}
                    <Space top={5}/>
                    {(filtered.length === 0 && !loading)?
                      <GetSmallModels title={"Não há Debitos/Creditos com Esse Filtro"}
                      subtitle={"Tente Trocar o Filtro..."}
                      icon={faCircleInfo} />:
                        filtered.map((model, index) => (
                          <> {
                              window.innerWidth > 1280?
                              <ModelFinancial canEdite={canEdite} onEdite={()=>onEdite(model)} onRemove={()=>handleConfigureRemove(model)} model={model}/>:
                              <ModelFinancialMobile canEdite={canEdite} onEdite={()=>onEdite(model)} onRemove={()=>handleConfigureRemove(model)} model={model}/>
                            }</>
                        ))
                      }
                    </>
                    :
                      <div className={style.loaderCenter}>
                      <Loader titleFinding={'Buscando Fluxo de Caixa!'} 
                          subtitleFinding={'Aguarde...'} loading={loading}/>
                      </div>
                    }
                </div>
              </>:
               <div className={style.div}>
               <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar a o Financeiro'} />
               </div>}
              {showGetPix ? <GetPix valor={0} onClose={()=>setShowGetPix(false)}/> : null}
              {showConfirmSave? <ConfirmationPopup title={'APAGAR FLUXO'} message={'Tem Certeza que Deseja Apagar Esse Fluxo?'} onCancel={()=>setShowConfirmSave(false)} onConfirm={handleRemove}/>: null} 
              {showAddFinancial? <GetAddFinancial financial={selectedFinancial} onSave={handlerFindDatabase} onClose={()=>setShowAddFinancial(false)}/>: null}
          </div>
      )
}