import { getPercentage } from "../../backEnd/ControllerGloba"

class SaleDiscount{
    constructor(){
        this.valueDiscountLiquid = 0.0
        this.discountPercent = 0
        this.isHasDiscount = false
    }

    fromNewSale(auxSale){
        let value = parseFloat(auxSale.percentDiscont)
        if(isNaN(value))
            value = 0
        this.discountPercent = value
        this.isHasDiscount = (value !== 0)
    }

    fromCloud(data){
        this.discountPercent = parseFloat(data.discountPercent)
        this.isHasDiscount = data.isDiscount === 1? true: false
    }

    fromEdite(other){
        this.isHasDiscount = other.isHasDiscount
        this.discountPercent = other.discountPercent
    }

    calculateDiscount(value){
        this.valueDiscountLiquid = 0
        if(this.isHasDiscount){
            this.valueDiscountLiquid = getPercentage(value, this.discountPercent)
            return  this.valueDiscountLiquid
        }
        return this.valueDiscountLiquid
    }

    getMapFromCloud(){
        return{
        'discountPercent': this.discountPercent,
        'isHasDiscount':this.isHasDiscount,
        }
    }

    getDiscountPercent(){ 
        return this.discountPercent.toString()+" %"
    } 
    
    getValueDiscountPercent(){
        return this.valueDiscountLiquid.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    clear(){
        this.valueDiscountLiquid = 0.0
        this.discountPercent = 0
        this.isHasDiscount = false
    }
}

export default SaleDiscount