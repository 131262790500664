import React, { useState } from "react";
import logoApi from '../assets/logoBruna-admin.png';
import style from "../style/LoginComponent.module.css"
import { GetButton } from "../layout/ButtonsLayout";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { tryLoginAdmin } from "../controllers/CloudLogin";
import Loader from "../layout/Loader";
import { useNavigate } from "react-router-dom";
import { routerFromAdminController } from "../routers/routerList";
import Space from "../layout/Space"
import { useError } from "../AppContext";

export function LoginAdmin({handleLogin}){
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password,setPassword] = useState('')
    const {showError} = useError()
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
      setLoading(true)
      var response = await tryLoginAdmin(email,password,showError)
      if(response.status === 200){
        handleLogin()
        navigate(routerFromAdminController)
      }

      setLoading(false)
    }

    return(
        <div className={style.container}>
           {!loading? <>
          <div className={style.containerLogin}>
            <div className={style.wrapLogin}>
              <div className={style.loginForm}>
               
                  <span className={style.loginFormTitle}>
                    <img src={logoApi} alt='Icone da Api B-Semijoias Clientes'></img>        
                  </span>              
                  <div className={style.wrapInput}>
                  <input className={style.input} type='email' value={email} onChange={e => setEmail(e.target.value)}/>
                  <span className={style.focus} data-placeholder='Email'></span>
                  </div>
                  <Space top={30}/>
                  <div className={style.wrapInput}>
                  <input className={ style.input} id='passwordId' type='Password' value={password} onChange={e=> setPassword(e.target.value)}/>
                  <span className={style.focus} data-placeholder='senha'></span>
                  </div>
                  <Space top={30}/>
                  <GetButton title={'ENTRAR'} color={'#0774FC'} handleFunction={handleSubmit} icon={faArrowRightToBracket} />
              </div>
            </div>
          </div>  
          </> : 
          <div className={style.load}>
            <Loader titleFinding={'Estabelecendo Comunicação com o Servidor'}  
            subtitleFinding={'Aguarde...'} loading={loading}/>
          </div>}
      </div>
    )
}