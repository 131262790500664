import ItemControllerSale from "./ItemControllerSale"
import ItemGlobal from "./ItemGlobal"
import VersionProduct from "../VersionProduct"
import { myStoreDataMemory } from "../../backEnd/Memory"


class ItemsSale{
    constructor(){
        this.itemGlobal = new ItemGlobal()
        this.idItem = 0
        this.amount= 0
        this.codeBar = 0
        this.personalizedValue = 0
        this.versionProduct = new VersionProduct()
        this.itemControllerSale = new ItemControllerSale()
    }

    fromAddInSystem(dataGlobal){
        this.idItem = 0
        this.amount = 0
        this.personalizedValue = 0
        this.itemGlobal.fromOther(dataGlobal)
        this.codeBar = this.itemGlobal.codebar;
        this.versionProduct.configureNewVersion('Essa Peça Foi Adicionado no Seu Banco de Dados Pelo Sistema Automático')
    }

    getGrams(){
        return this.itemGlobal.factorItem
    }

    getTotalGrams(){
        return this.amount * this.itemGlobal.factorItem
    }

    fromSale(data, global){
 
        var itemGlobalTemp = new ItemGlobal()
        itemGlobalTemp.fromOther(global)
        this.itemGlobal = itemGlobalTemp
        this.itemControllerSale.factorCompany = parseFloat(data.factor)
        this.amount = data.amount
        this.idItem = data.idItem
        this.personalizedValue = data.personalizedValue
        this.codeBar = this.itemGlobal.codebar
        this.versionProduct = new VersionProduct()
    }

    fromOther(other){
        var itemGlobalTemp = new ItemGlobal()
        itemGlobalTemp.fromOther(other.itemGlobal)
        this.itemGlobal = itemGlobalTemp
        this.itemControllerSale.factorCompany = myStoreDataMemory.profitFactor
        this.amount = other.amount
        this.idItem = other.idItem
        this.personalizedValue = other.personalizedValue
        this.codeBar = this.itemGlobal.codebar
        this.versionProduct = new VersionProduct()
    }

    fromCloud(data,itemGlobal){
        this.itemGlobal = itemGlobal
        this.idItem = data.idItem
        this.amount= data.amount
        this.codeBar = this.itemGlobal.codebar
        this.personalizedValue = data.personalizedValue

        const version = new VersionProduct()
        version.fromCloud(data)
        this.versionProduct = version

        this.itemControllerSale = new ItemControllerSale()
        this.itemControllerSale.fromInit(myStoreDataMemory.profitFactor)
    }

    getImage(){
        return this.itemGlobal.linkImage
    }

    getValueSale(factorFromStore){
        this.itemControllerSale.updateValuesItems(this.itemGlobal.factorItem, this.amount, this.personalizedValue,factorFromStore)
        return this.itemControllerSale.valueItemsUn
    }

    getValueSaleToString(factorFromStore){
        return this.getValueSale(factorFromStore).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getValueSaleTotalToString(factorFromStore){
        return this.getValueSaleTotal(factorFromStore).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getValueSaleTotal(factorFromStore){
        this.itemControllerSale.updateValuesItems(this.itemGlobal.factorItem, this.amount, this.personalizedValue,factorFromStore)
        return this.itemControllerSale.valueItemsUn * this.amount
    }

    getValueCoastUnit(){
        var unit = this.itemGlobal.factorItem * myStoreDataMemory.purchasingFactor
        if(isNaN(unit)) unit = 0
        return unit
    }

    getName(){
        return this.itemGlobal.name
    }

    getValueCoastTotal(){
        return this.getValueCoastUnit() * this.amount
        
    }

    updateValuesItems(factor){
        this.itemControllerSale.updateValuesItems(this.itemGlobal.factorItem, this.amount,this.personalizedValue,factor)
    }

    getValueSaleUnit(){
        this.updateValuesItems()
        if(isNaN(this.itemControllerSale.valueItemsUn)) this.itemControllerSale.valueItemsUn = 0
        return this.itemControllerSale.valueItemsUn
    }



    getMapFromCloud(){
        return{
          'idGlobal':this.itemGlobal.idGlobalItemBrunaSemijoias,
          'codeBar':this.codeBar,
          'amount':this.amount,
          'idItem':this.idItem,
          'personalizedValue':this.personalizedValue,
          'container-itemGlobal': this.itemGlobal.getMapFromCloud(),
          'container-lastVersion':this.versionProduct.getMapFromCloud(),
          'valueSale':this.getValueSale(myStoreDataMemory.profitFactor),
          'valueCoast':this.getValueCoastUnit(),
        };
      }
}

export default ItemsSale;