export const routerFromEditeMaster = '/edite-master'

export const errorRouter = "/404"

export const routerFromAdminController ='/admin/adminController'
export const routerFromAdminNoticesController ='/admin/notices'
export const routerFromAdmin ='/admin'


//clients
export const routerFromClientsGlobal = "/clients/*"
export const routerFromListClients = '/clients/list-clients'
export const routerFromaddClients = '/clients/add-clients'


export const routerFromLogin ='/login'
export const routerFromLoginEmployee = '/login-employee'
export const routerFromSale = '/newSale'

export const routerFromListSale = '/clients/client/sales'
export const routerFromClient = '/clients/client/*'
export const routerFromEditeClient = '/clients/client/edite'
export const routerFromListRepairs = '/clients/client/repairs'
//Perfil
export const routerFromPerfilGlobal= "/perfil/*"
export const routerFromExit= "/perfil/exit"

//Accounting
export const routerFromAccountingGlobal= "/accounting/*"
export const routerFromAccountingMoth= "/accounting/moth"
export const routerFromAccountingMothExtract= "/accounting/moth-extract"


export const routerFromAccountingListAllRepairsPeriod= "/accounting/listRepairs"
export const routerFromAccountingListSalesPeriod= "/accounting/list-sales-period"
export const routerFromAccountingListSalesFactorPeriod= "/accounting/list-sales-factor-period"

export const routerFromAccountingReceivers= "/accounting/list-receivers"
export const routerFromAccountingComissions= "/accounting/list-comissions"
export const routerFromAccountingFutureReceivers= "/accounting/list-future-receivers"

export const routerFromAccountingPerformace= "/accounting/performace"
export const routerFromAccountingItemSales= "/accounting/list-items-sales"
export const routerFromAccountingProducts= "/accounting/list-Products-sales"

export const routerFromAccountingGraphicMoths= "/accounting/graphic-Moths"
export const routerFromAccountingGraphicItems= "/accounting/graphic-Items"
export const routerFromAccountingGraphicSales= "/accounting/graphic-sales"
export const routerFromAccountingGraphicMoney= "/accounting/graphic-money"

//moreOptions
export const routerFromMoreOptionsGlobal = "/more-options/*"
export const routerFromMoreOptionsBalanceBj = "/more-options/Balance-bruna-semijoias"
export const routerFromMoreOptionsBalanceGenerics = "/more-options/Balance-generics"
export const routerFromMoreOptionsRequestAndNotes = "/more-options/request-notes"
export const routerFromMoreOptionsRequestAndNotesCreate = "/more-options/Create-request-notes"
export const routerFromMoreOptionsExtractsPds = "/more-options/extract-pdfs"

//financial
export const routerFromFinancialGlobal = '/financial/*'
export const routerFromFinancialNotaFiscal = '/financial/Nf-e'

//Stock
export const routerFromStockGlobal = "/stock/*"
export const routerFromStockBj = "/stock/bruna-semijoias"
export const routerFromStockOthers= "/stock/others-brands"
export const routerFromStockListBrands = "/stock/list-brands"
export const routerFromStockNewBrand = "/stock/new-brands"
export const routerFromStockNewProduct = "/stock/new-product"

export const routerFromStockPDFallItems = "/stock/pdf-all-items"

export const routerFromStockMyShop= "/stock/Shopping"
export const routerFromStockRepositionBJ= "/stock/stock-repositions-bj"
export const routerFromStockRepositionGenerics= "/stock/stock-repositions-generics"

export const routerFromStockAccounting= "/stock/stock-accounting"
export const routerFromStockRepositions= "/stock/list-repositions"
export const routerFromStockClear= "/stock/clear"
//myStore
export const routerFromMyStoreGlobal = "/myStore/*"
export const routerFromMyStoreData = "/myStore/data"
export const routerFromMyStoreEmployees= "/myStore/employees"
export const routerFromMyStoreActions = "/myStore/actions"
export const routerFromMyStoreAddEmployees = "/myStore/add-employees"