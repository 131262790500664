class AccountingObjectPart{
    constructor(){
        this.amountItems = 0
        this.grams = 0
        this.valueMoney = 0
        this.totalSaleCount =0
    }

    getValueMoney(){
        return this.valueMoney.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
  
    clearData(){
        this.totalSaleCount=0
        this.amountItems = 0
        this.grams = 0;
        this.valueMoney =0
    }
}

export default AccountingObjectPart