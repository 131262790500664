import React from 'react';
import style from "../style/BrandDropdown.module.css"

const SemijoiasDropdown = ({onSelect}) => {
  const states = [
    "BB - BRINCO", "AB - ANÉIS", "GB - GARGANTILHA", "MB - MEDALHA", "BP - PULSEIRA" +
    "CE - CORRENTE", "BT - TORNOZELEIRAS"  
  ]

  return (
    <div>
        <select className={style.branddropdown} onChange={(e) => onSelect(e.target.value)}>
            {states.map((value, index) => (
                <option key={index} value={value}>{value}</option>
            ))}
        </select>
    </div>
  );
}


export default SemijoiasDropdown;
