import { routerFromAccountingComissions, routerFromAccountingFutureReceivers, routerFromAccountingGraphicMoney, routerFromAccountingGraphicMoths, routerFromAccountingGraphicSales, routerFromAccountingItemSales, routerFromAccountingListSalesFactorPeriod, routerFromAccountingListSalesPeriod, routerFromAccountingMoth, routerFromAccountingMothExtract, routerFromAccountingPerformace, routerFromAccountingProducts, routerFromAccountingReceivers, routerFromFinancialGlobal, routerFromListClients, routerFromMoreOptionsRequestAndNotes, routerFromMyStoreActions, routerFromMyStoreAddEmployees, routerFromMyStoreData, routerFromMyStoreEmployees, routerFromSale, routerFromStockAccounting, routerFromStockBj, routerFromStockClear, routerFromStockListBrands, routerFromStockMyShop, routerFromStockNewBrand, routerFromStockOthers, routerFromStockPDFallItems, routerFromStockRepositionBJ, routerFromStockRepositionGenerics, routerFromStockRepositions, routerFromaddClients } from "../routers/routerList"
import { databaseSettings } from "./Memory"

export class Search{
    constructor(){
        this.listSearch = []
        this.listSearch.push(new PathSearch('Vender',routerFromSale,'realziar venda vender tipos de venda, consultar venda'))
        this.listSearch.push(new PathSearch('Lista de Clientes',routerFromListClients,'clientes lista de clientes cliente '))
        this.listSearch.push(new PathSearch('Cadastrar Nova Cliente',routerFromaddClients,'cadastrar cliente novo nova clietes'))
        this.listSearch.push(new PathSearch('Dados da Loja',routerFromMyStoreData,'Minha loja editar dados da loja loja dados valor de venda valor de custo, '))
        this.listSearch.push(new PathSearch('Ações da Loja',routerFromMyStoreActions,'Logo Marca cartao de visita contatos açoes ações dados da loja loja minha loja contas bancarias pdfs '))
        this.listSearch.push(new PathSearch('Lista de Funcionários',routerFromMyStoreEmployees,'Lista de Funcionários Funcionarios editar funcionario'))
        this.listSearch.push(new PathSearch('Cadastrar Funcionário',routerFromMyStoreAddEmployees,'cadastrar novo nova Funcionários Funcionarios'))
    
        if(databaseSettings.isCanUseBrunaBJ){
            this.listSearch.push(new PathSearch('Bruna Semijoias Vendidas',routerFromAccountingItemSales,'peças vendidas bruna semijoias vendidas lista de peças vendidas venda'))
            this.listSearch.push(new PathSearch('Estoque Bruna Semijoias',routerFromStockBj,'estoque repor bruna semijoias peças'))
            this.listSearch.push(new PathSearch('Reposição Bruna Semijoias',routerFromStockRepositionBJ,'repor bruna semijoias atualizar estoque meu estoque reposição reposicao atualziar estoque'))
        } 

        this.listSearch.push(new PathSearch('Reposição Marcas Genéricas',routerFromStockRepositionGenerics,'repor produtos atualizar estoque meu estoque genericos genéricos reposição reposicao atualziar estoque'))
        
       
        this.listSearch.push(new PathSearch('Estoque Outras Marcas',routerFromStockOthers,'estoque repor marcas genericas genéricos genericos'))
        this.listSearch.push(new PathSearch('Cadastrar Produtos Genéricos',routerFromStockOthers,'cadastrar produtos genéricos genericos'))
        this.listSearch.push(new PathSearch('Listar Marcas Genéricas',routerFromStockListBrands,'marcas genericas generico produtos lista de marcas '))
        this.listSearch.push(new PathSearch('Cadastrar Nova Marca',routerFromStockNewBrand,'marcas nova novo produtos cadastrar marca'))

        this.listSearch.push(new PathSearch('Financeiro/Fluxo de Caixa',routerFromFinancialGlobal,'fluxo de caixa financeiro contas dividas contabilidade dinheiro '))

        this.listSearch.push(new PathSearch('Zerar Estoque',routerFromStockClear,'zerar estoque apagar limpar restaurar apagar'))
        this.listSearch.push(new PathSearch('Lista de Reposições',routerFromStockRepositions,'lista de reposição reposicao verificar'))
        this.listSearch.push(new PathSearch('Estoque Total',routerFromStockAccounting,'Estoque Total dados de estoque graficos de estoque contabilidade'))
    
        this.listSearch.push(new PathSearch('Todas as Vendas',routerFromAccountingListSalesPeriod,'vendas todas vendas lista de vendas total de vendas'))
        this.listSearch.push(new PathSearch('Vendas por Fatores',routerFromAccountingListSalesFactorPeriod,'vendas por fatores  vendas total lista de vendas'))
        this.listSearch.push(new PathSearch('Gráfico Vendas/Fatores',routerFromAccountingGraphicSales,' lista vendas por fatores desempenho graficos gráficos'))

     
        this.listSearch.push(new PathSearch('Produtos Genéricos Vendidos',routerFromAccountingProducts,'vendidos peças produtos vendido produtos genéricos lista '))

        this.listSearch.push(new PathSearch('Lista de Recebimentos',routerFromAccountingReceivers,'lista de recebimentos pagamentos receber recebido'))
        this.listSearch.push(new PathSearch('Futuros Recebimentos',routerFromAccountingFutureReceivers,'recebimentos a receber futuros recebimentos pagamentos recebido'))
        this.listSearch.push(new PathSearch('Lista de Comissões',routerFromAccountingComissions,'lista de comissões comissoes '))
        this.listSearch.push(new PathSearch('Gráficos Comparativos',routerFromAccountingGraphicMoney,'comparar desempenho graficos gráficos'))

        this.listSearch.push(new PathSearch('Desempenho',routerFromAccountingPerformace,'performace desempenho final do mes mês'))
        this.listSearch.push(new PathSearch('Visualizar Pedidos/Notas',routerFromMoreOptionsRequestAndNotes,'notas pedidos cadastrar notas visualizar '))
        
        this.listSearch.push(new PathSearch('PDFs Gerais do Estoque',routerFromStockPDFallItems,'PDFS pdfs gerais de estoque valores precificador'))
        
        this.listSearch.push(new PathSearch('Minhas Compras',routerFromStockMyShop,'compras minhas compras comprar realizar compra'))
        this.listSearch.push(new PathSearch('Gerenciar Mês',routerFromAccountingMoth,'gerenciar mes mês iniciar contabilidade'))
        this.listSearch.push(new PathSearch('Extrato do Mês',routerFromAccountingMothExtract,'este mês mes desempenho extratos'))
        this.listSearch.push(new PathSearch('Comparação Mês a Mês',routerFromAccountingGraphicMoths,'comparar mes a mes mês desempenho graficos gráficos'))
    }

    findResults(value){
        return this.listSearch.filter(ss=>ss.keys.toLowerCase().includes(value.toLowerCase()))
    }
}

class PathSearch{
    constructor(name,router,keys){
        this.pathName = name
        this.router = router
        this.keys = keys
    }
}