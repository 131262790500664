import React, { useState } from "react";
import style from "../../../style/addBrands.module.css"
import {faFloppyDisk, faCopyright, faWandMagicSparkles, faPenToSquare, faPaperPlane, faIcons
} from "@fortawesome/free-solid-svg-icons";
import { GetSmallModels, } from "../../../Models/GetModels.js";
import InputModels from "../../../Models/InputModels.js";
import InputPhoto from "../../../Models/InputPhoto.js";
import {TitlePageResponsiveIcon } from "../../../layout/TitlePage.js";
import GetFloatingButton from "../../../layout/GetFloatingButton.js";
import ConfirmationPopup from "../../../Models/ConfirmationPopup.js";
import Alert from "../../../Models/Alert.js";
import Loader from "../../../layout/Loader.js";
import Brands from "../../../objects/Brands/Brands.js";
import { setSaveBrand } from "../../../controllers/CloudBrands.js";
import { useError } from "../../../AppContext.js";
import { findBrands } from "../../../backEnd/Memory.js";
import Space from "../../../layout/Space.js";

function AddBrands(){
    const [selectedImageFromFile,setSelectedImageFromFile] = useState(null)
    
    const [confirmationVisible, setConfirmationVisible] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [loading,setLoading] = useState(false)
  
    const {showError} = useError()
    const [showAlertSave,setShowAlertSave] = useState(false)

    const [formData, setFormData] = useState({
        nameBrand: '',
        descriptionBrand: '',
    })
    
    const [needData, setNeedData] = useState({
        nameBrand: false,
        descriptionBrand: false,
    })

    const handlerSlectedImageFromFile = (image) =>{
        setSelectedImageFromFile(image)
    }

    const handleConfirm = async() => {
        setConfirmationVisible(false)
        setLoading(true)

        var newBrand = new Brands()
        newBrand.fromNewBrand(formData)
        var result = await setSaveBrand(selectedImageFromFile,newBrand,showError)

        if(result.status === 200){
            await findBrands()
            setShowAlertSave(true)
            clear()
        }
        setLoading(false)
    }
  
    const clear = ()=>{
        formData.nameBrand =''
        formData.descriptionBrand=''
        setSelectedImageFromFile('')
    }

    const handleCancel = () => {
        setConfirmationVisible(false)
    }

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleOpenConfirmation = () => {
        setConfirmationVisible(true)
    }

    const handleSubmitSave= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if (value === '') {
                setNeedData(prevState => ({ ...prevState, [key]: true }))
                allFieldsEmpty = true
            } else {
                setNeedData(prevState => ({ ...prevState, [key]: false }))
            }
        })
    
        if (allFieldsEmpty) {
            setIsVisible(true)
            return
        }

        handleOpenConfirmation()
    }

    return(
        <div>
           <div className={style.containerGlobal}>
                <div className={style.container}>
                    {!loading? <><div className={style.titleMenu}>
                    <TitlePageResponsiveIcon subtitle={'Cadastrar Nova Marca'} 
                    path={'Complete os Campos Abaixo...'} icon={faCopyright}/>
                    </div>
                    <div className={style.form}>
                            <div className={style.containerDataDefault}>
                                <div className={style.containerData}>
                                <GetSmallModels title={"Logo Marca"} subtitle={"É o Icone da Marca..."} icon={faIcons}/>
                                <div className={style.iconBrand}>
                                    <InputPhoto titleButton={'Selecionar Logo Marca'} 
                                    handlerFileSelected={handlerSlectedImageFromFile}/>
                                </div>
                                </div>
                            </div>
                            <Space left={5}/>
                            <div className={style.containerDataDefault}>
                                <div className={style.containerData}>
                                    <GetSmallModels title={"Dados da Marca"} subtitle={"São os Dados Básicos da Marca..."} icon={faWandMagicSparkles}/>
                                    <InputModels validation={needData.nameBrand} name={'nameBrand'} onChange={handleChange} iconButton={faPenToSquare} maxLength={20} type={"text"} placeholder={"Nome Completo da Marca..."} />
                                    <InputModels validation={needData.descriptionBrand} name={'descriptionBrand'} onChange={handleChange} iconButton={faPaperPlane} maxLength={30} type={"text"} placeholder={"Tipo de Mercadoria..."}/>  
                                </div>    
                            </div>     
                    </div>
                    </>:
                    <div className={style.containerDataDefault}>
                        <Loader titleFinding={'Salvando Dados no Servidor...'}
                        subtitleFinding={'Aguarde...'} loading={loading} />
                    </div>}
                </div>
            </div>
            {showAlertSave? <Alert top={100} message={"Nova Marca Foi Salva Com Sucesso"} onClose={() => setShowAlertSave(false)} type={"success"} />:null}
            {isVisible? <Alert  message={"Complete Todos os Campos em Vermelho"} onClose={() => setIsVisible(false)} type={"error"} />:null}
            {confirmationVisible? <ConfirmationPopup title={'CADASTRAR NOVA MARCA'} message={'Tem Certeza que Deseja Cadastrar uma Nova Marca?'} onCancel={handleCancel} onConfirm={handleConfirm}/>: null}
            <GetFloatingButton icon={faFloppyDisk} handleFunction={handleSubmitSave} color='#007bff' margiRight={'1%'} height={window.innerWidth > 1200 ? 85: 65} width={window.innerWidth > 1200 ? 85: 65} margiBottom={'1%'} /> 
        </div>
    )
}

export default AddBrands