import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { NavBarGlobal, ObjectNavbarMenu } from "../../NavbarGlobal.js";
import style from "../../../style/Controller.module.css"
import AddClient from "../AddClient.js";
import {faUsers,faList, faUserEdit, faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import { errorRouter, routerFromClient, routerFromEditeClient, routerFromListRepairs, routerFromListSale, routerFromLogin } from "../../../routers/routerList.js";
import ListOfSalesClient from "./ListOfSalesClient.js";
import ListOfRepairs from "../Repairs/ListOfRepairs.js";

function ControllerClients({isLoggedIn}){
    const location = useLocation()
    const clientFromParams = location.state.client
    
    const menu1 = new ObjectNavbarMenu('Cliente', faUsers)
   
    menu1.addMenu('Lista de Vendas', faList,routerFromListSale,{client:clientFromParams})
    menu1.addMenu('Conserto e Reparos',faScrewdriverWrench,routerFromListRepairs,{client:clientFromParams})
    menu1.addMenu('Editar Cliente', faUserEdit,routerFromEditeClient,{client:clientFromParams})
    
    const listRoutesToNav = [menu1];

    return isLoggedIn ? (
        <div className={style.containerGlobal}>
            <NavBarGlobal listObjectNavBarMenu={listRoutesToNav}/>
            <div className={style.containerData}>
                {(() => {
                    switch(location.pathname){         
                        case routerFromClient: return <ListOfSalesClient client={clientFromParams}/>      
                        case routerFromListSale: return <ListOfSalesClient client={clientFromParams}/>
                        case routerFromEditeClient: return <AddClient client={clientFromParams}/>
                        case routerFromListRepairs: return <ListOfRepairs client={clientFromParams}/>
                        default: return <Navigate to={errorRouter} replace />
                    }
                })()}
            </div>
        </div>
    ) : (
        <Navigate to={routerFromLogin} replace />
    )
}

export default ControllerClients
