import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import InputModelsBlock from "../../layout/InputModelsBlock";
import style from '../../style/GetNFeConfigureCompany.module.css'
import { faHandshakeSimple, faFileCode, faAngleUp, faAngleDown, faCircleQuestion, faCircleInfo, faPercent, faCode } from "@fortawesome/free-solid-svg-icons";
import Space from "../../layout/Space";
import { TitlePageResponsiveIcon } from "../../layout/TitlePage";
import { GetButton, GetButtonIcon } from "../../layout/ButtonsLayout";
import { GenericDropDown } from "../../layout/GenericDropDown";
import { GetSmallModels } from "../../Models/GetModels";
import { emit } from "../../backEnd/Memory";

const getFormDataSelection = (icms) => {
    return {
        CST:icms.CST,
        CSOSN:icms.CSOSN,
    }
}

const getFormData = (icms,type) => {
  
    switch (type) {
        case '00':
            return {
                orig:icms.ICMS.orig,
                modBC:icms.ICMS.modBC,
                pICMS:icms.ICMS.pICMS,

                vBC:icms.ICMS.vBC,
                vICMS:icms.ICMS.vICMS,
            }
        case '10':
            return{
                orig:icms.ICMS.orig,
                modBC:icms.ICMS.modBC,
                pICMS:icms.ICMS.pICMS,
                modBCST:icms.ICMS.modBCST,
                pMVAST:icms.ICMS.pMVAST,
                pRedBCST:icms.ICMS.pRedBCST,
                pICMSST:icms.ICMS.pICMSST,

                vBC:icms.ICMS.vBC,
                vICMS:icms.ICMS.vICMS,
                vBCST:icms.ICMS.vBCST,
                vICMSST:icms.ICMS.vICMSST,
            }
        case '20':
            return{
                orig:icms.ICMS.orig,
                modBC:icms.ICMS.modBC,
                pRedBC:icms.ICMS.pRedBC,
                pICMS:icms.ICMS.pICMS,

                vBC:icms.ICMS.vBC,
                vICMS:icms.ICMS.vICMS,
            }
        case '30':
            return{
                orig:icms.ICMS.orig,
                modBCST:icms.ICMS.modBCST,
                pMVAST:icms.ICMS.pMVAST,
                pRedBCST:icms.ICMS.pRedBCST,
                pICMSST:icms.ICMS.pICMSST,
                
                vICMSST:icms.ICMS.vICMSST,
                vBCST:icms.ICMS.vBCST,
            }
        case '40':
        case '41':
        case '50':
            return{
                orig:icms.ICMS.orig,
                motDesICMS:icms.ICMS.motDesICMS,
                vICMS:icms.ICMS.vICMS,        
            }
        case '51':
            return{
                orig:icms.ICMS.orig,
                modBC:icms.ICMS.modBC,
                pRedBC:icms.ICMS.pRedBC,
                pICMS:icms.ICMS.pICMS,

                vBC:icms.ICMS.vBC,
                vICMS:icms.ICMS.vICMS,
            }
        case '60':
            return{
                orig:icms.ICMS.orig,
                vBCSTRet:icms.ICMS.vBCSTRet,
                vICMSSTRet:icms.ICMS.vICMSSTRet,
            }
        case '70':
            return{
                orig:icms.ICMS.orig,
                modBC:icms.ICMS.modBC,
                pRedBC:icms.ICMS.pRedBC,
                pICMS:icms.ICMS.pICMS,
                modBCST:icms.ICMS.modBCST,
                pMVAST:icms.ICMS.pMVAST,
                pRedBCST:icms.ICMS.pRedBCST,
                pICMSST:icms.ICMS.pICMSST,

                vBC:icms.ICMS.vBC,
                vICMS:icms.ICMS.vICMS,
                vBCST:icms.ICMS.vBCST,
                vICMSST:icms.ICMS.vICMSST,
            }
        case '90':
            return{
                orig:icms.ICMS.orig,
                modBC:icms.ICMS.modBC,
                pRedBC:icms.ICMS.pRedBC,
                pICMS:icms.ICMS.pICMS,
                modBCST:icms.ICMS.modBCST,
                pMVAST:icms.ICMS.pMVAST,
                pRedBCST:icms.ICMS.pRedBCST,
                pICMSST:icms.ICMS.pICMSST,

                vBC:icms.ICMS.vBC,
                vICMS:icms.ICMS.vICMS,
                vBCST:icms.ICMS.vBCST,
                vICMSST:icms.ICMS.vICMSST,
            }
        case '101':
            return{
                orig:icms.ICMS.orig,
                pCredSN:icms.ICMS.pCredSN,
                vCredICMSSN:icms.ICMS.vCredICMSSN, 
            }
        case '102':
        case '103':
        case '300':
        case '400':
            return{
                orig:icms.ICMS.orig,
            }
        case '201':
            return{
                orig:icms.ICMS.orig,
                modBCST:icms.ICMS.modBCST,
                pMVAST:icms.ICMS.pMVAST, 
                pRedBCST:icms.ICMS.pRedBCST,
                pICMSST:icms.ICMS.pICMSST,
                pCredSN:icms.ICMS.pCredSN, 

                vBCST:icms.ICMS.vBCST, 
                vICMSST:icms.ICMS.vICMSST, 
                vCredICMSSN:icms.ICMS.vCredICMSSN,
            }
        case '203':
        case '202':
            return{
                orig:icms.ICMS.orig,
                modBCST:icms.ICMS.modBCST,
                pMVAST:icms.ICMS.pMVAST, 
                pRedBCST:icms.ICMS.pRedBCST,
                pICMSST:icms.ICMS.pICMSST,

                vBCST:icms.ICMS.vBCST, 
                vICMSST:icms.ICMS.vICMSST, 
            }
        case '500':
            return{
                orig:icms.ICMS.orig,  
                vBCSTRet:icms.ICMS.vBCSTRet, 
                vICMSSTRet:icms.ICMS.vICMSSTRet,
            }
        case '900':
            return{
                orig:icms.ICMS.orig,
                modBC:icms.ICMS.modBC,
                pRedBC:icms.ICMS.pRedBC,
                pICMS:icms.ICMS.pICMS,
                modBCST:icms.ICMS.modBCST, 
                pMVAST:icms.ICMS.pMVAST,
                pRedBCST:icms.ICMS.pRedBCST, 
                pICMSST:icms.ICMS.pICMSST,
                pCredSN:icms.ICMS.pCredSN,

                vBC:icms.ICMS.vBC,
                vICMS:icms.ICMS.vICMS, 
                vBCST:icms.ICMS.vBCST, 
                vICMSST:icms.ICMS.vICMSST, 
                vBCSTRet:icms.ICMS.vBCSTRet, 
                vICMSSTRet:icms.ICMS.vICMSSTRet,
                vCredICMSSN:icms.ICMS.vCredICMSSN,
            }
        default:
          return null; 
      }
}

const getFormNeed = (type,isEdite) => {
    switch (type) {
        case '00':
            return {
                pICMS:false,
                ...(!isEdite ? { vBC: false, vICMS: false } : {})
            }
        case '10':
            return{
                pICMS:false,
                pMVAST:false,
                pRedBCST:false,
                pICMSST:false,

                ...(!isEdite ? { 
                    vBC: false, 
                    vICMS: false,
                    vBCST:false,
                    vICMSST:false,
                } : {})
 
            }
        case '20':
            return{
                pRedBC:false,
                pICMS:false,

                ...(!isEdite ? { 
                    vBC: false, 
                    vICMS: false,
                } : {})
            }
        case '30':
            return{
                pMVAST:false,
                pRedBCST:false,
                pICMSST:false,

                ...(!isEdite ? { 
                    vICMSST: false, 
                    vBCST: false,
                } : {})
            }
        case '40':
        case '41':
        case '50':
            return{
                ...(!isEdite ? { 
                    vICMS: false,
                } : {})
            }
        case '51':
            return{
                pRedBC:false,
                pICMS:false,

                ...(!isEdite ? { 
                    vBC:false,
                    vICMS:false,
                } : {})
            }
        case '60':
            return{
                ...(!isEdite ? { 
                    vBCSTRet:false,
                    vICMSSTRet:false,
                } : {})
            }
        case '70':
            return{          
                pRedBC:false,
                pICMS:false,
                pMVAST:false,
                pRedBCST:false,
                pICMSST:false,

                ...(!isEdite ? { 
                    vBC:false,
                    vICMS:false,
                    vBCST:false,
                    vICMSST:false,
                } : {})
            }
        case '90':
            return{
                pRedBC:false,
                pICMS:false,
                pMVAST:false,
                pRedBCST:false,
                pICMSST:false,

                ...(!isEdite ? { 
                    vBC:false,
                    vICMS:false,
                    vBCST:false,
                    vICMSST:false,
                } : {})
            }
        case '101':
            return{
                pCredSN:false,

                ...(!isEdite ? { 
                    vCredICMSSN:false, 
                } : {})
            }
        case '102':
        case '103':
        case '300':
        case '400':
            return{}
        case '201':
            return{
                pMVAST:false, 
                pRedBCST:false,
                pICMSST:false,
                pCredSN:false, 

                ...(!isEdite ? { 
                    vBCST:false, 
                    vICMSST:false, 
                    vCredICMSSN:false,
                } : {})
            }
        case '203':
        case '202':
            return{
                pMVAST:false, 
                pRedBCST:false,
                pICMSST:false,

                ...(!isEdite ? { 
                    vBCST:false, 
                    vICMSST:false, 
                } : {})
            }
        case '500':
            return{}
        case '900':
            return{
                pRedBC:false,
                pICMS:false,
                pMVAST:false,
                pRedBCST:false, 
                pICMSST:false,
                pCredSN:false,

                ...(!isEdite ? { 
                    vBC:false,
                    vICMS:false, 
                    vBCST:false, 
                    vICMSST:false, 
                    vBCSTRet:false, 
                    vICMSSTRet:false,
                    vCredICMSSN:false,
                } : {})
            }
        default:
          return null; 
    }
}

export const GetICMS = forwardRef(({initialExpand,update,isEdite,icms,automaticMath}, ref) => {

    const [updateScreen,SetUpdateScreen] = useState(false)
    const [formDataSelection, setFormDataSelection] = useState(getFormDataSelection(icms))
    const [formData, setFormData] = useState(getFormData(icms,emit.CRT.toString() === '3'? icms.CST:icms.CSOSN))
    const [needData, setNeedData] = useState(getFormNeed(emit.CRT.toString() === '3'? icms.CST:icms.CSOSN,isEdite))
    const [expanded, setExpand] = useState(initialExpand?? true)

    const handleChange = (e) => {
        if(e !== ''){
            SetUpdateScreen(false)
            let { name, value } = e.target
            setFormData({ ...formData, [name]: value })  
       
            if(name === 'CSOSN'){
                setFormDataSelection({ ...formDataSelection, [name]: value }) 
                const data = value.slice(0,3)
                setNeedData(getFormNeed(data,isEdite))
                icms.fromChange('-1',data)
                setFormData(getFormData(icms,data))
            }else if(name === 'CST'){
                const data = value.slice(0,2)
                setFormDataSelection({ ...formDataSelection, [name]: value }) 
                setNeedData(getFormNeed(data,isEdite))
                icms.fromChange(data,'-1')
                setFormData(getFormData(icms,data))
            }
        }
    }

    useEffect(() => {
        SetUpdateScreen(false)
        icms.fromEditeICMS({...formData, ...formDataSelection},automaticMath)
        SetUpdateScreen(true)
    },[formData,formDataSelection,needData,update,isEdite])

    useImperativeHandle(ref, () => ({
        handleSubmit
    }))

    const handleSubmit= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '' || value === undefined) {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                    console.log(key)
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
       
        if (allFieldsEmpty) 
            return false
        
        return {...formData , ...formDataSelection}
    }

    const getType=() =>{
        return (<>{
            emit.CRT.toString() === '3'?
            <TypeCST  isEdite={isEdite} formDataSelection={formDataSelection} formData={formData} needData={needData} handleChange={handleChange} icms={icms}/>
            :
            <TypeCSOSN  isEdite={isEdite} formDataSelection={formDataSelection} formData={formData} needData={needData} handleChange={handleChange} icms={icms}/>   
        }</>)
    }

    return (
        <div className={style.div}>
            <TitlePageResponsiveIcon 
                children={
                    <GetButtonIcon color='#094f00' height={40} width={40} icon={expanded? faAngleUp :  faAngleDown} handleFunction={()=>setExpand(!expanded)}/> 
                }
                subtitle={"ICMS"} path={"Imposto sobre Circulação de Mercadorias e Serviços..."} icon={faHandshakeSimple}/> 
                <div className={expanded? style.visible :   style.notVisible}>
                <div className={style.div}>
                <GetSmallModels 
                    children={
                        <GetButton responsive={true} color='#FF0000' title={'AJUDA'}  icon={faCircleQuestion} handleFunction={()=>window.open('https://documentacao.nstecnologia.com.br/docs/orientacoes-fiscais/materiais-explicativos/como-preencher-os-grupos-de-icms/#:~:text=Como%20o%20grupo%20ICMSSN101%20n%C3%A3o,empresa%20deve%20calcular%20este%20campo.','_black')}/> 
                    }
                    title={'DÚVIDAS GERAIS DE CÁLCULO'} backgroundColor={"#005f00"} icon={faCircleInfo} subtitle={'PARA CSOSN OU CST'}/>
                    <GetSmallModels 
                    children={
                        <GetButton responsive={true} color='#FF0000' title={'AJUDA'}  icon={faCircleQuestion} handleFunction={()=>window.open('https://www.sitesa.com.br/contabil/tabelas/crt.pdf','_black')}/> 
                    }
                    title={'O ICMS DEPENDE DO TIPO DO CRT - CÓDIGO DE REGIME TRIBUTÁRIO '} backgroundColor={"#005f00"} icon={faCircleInfo} subtitle={'CSOSN É PARA (CRT 1 E 2) - SIMPLES NACIONAL | CST É PARA (CRT 3) - REGIME NORMAL'}/>
                    <GetSmallModels 
                    children={
                        <GetButton responsive={true} color='#FF0000' title={'AJUDA'}  icon={faCircleQuestion} handleFunction={()=>window.open('https://docs.enotasgw.com.br/v2/docs/cst-icms-e-csosn','_black')}/> 
                    }
                    title={'TABELA COMPLETA COM CST E CSOSN'} backgroundColor={"#005f00"} icon={faCircleInfo}  subtitle={'ACESSE A TABELA COMPLETA PARA CONFIGURAR CORRETAMENTE O ICMS'}/>
                    <Space left={5} top={5}/>
                    {
                        emit.CRT.toString() === '3'?
                        <>
                            <GenericDropDown legend={'CST - CÓDIGO DA SITUAÇÃO TRIBUTÁRIA'} 
                                data={['00 - Tributada integralmente', 
                                '10 - Tributada e com cobrança do ICMS por substituição tributária', 
                                '20 - Com redução da BC',
                                '30 - Isenta/ não tributada e com cobrança do ICMS por substituição tributária',
                                '40 - Isenta',
                                '41 - Não tributada',
                                '50 - Com suspensão',
                                '51 - Com diferimento',
                                '60 - ICMS cobrado anteriormente por substituição tributária',
                                '70 - Com redução da BC e cobrança do ICMS por substituição tributária',
                                '90 - Outras'
                                ]} 
                            name={'CST'} initialState={formDataSelection.CST} handlerFuncion={handleChange}  /> 
                        </>:
                        <>
                            <GenericDropDown legend={'CSOSN - CÓDIGO DA SITUAÇÃO DA OPERAÇÃO NO SIMPLES NACIONAL'} 
                                data={[
                                '101 - Tributada pelo Simples Nacional com permissão de crédito',
                                '102 - Tributada pelo Simples Nacional sem permissão de crédito', 
                                '103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta', 
                                '201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária',
                                '202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária',
                                '203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária',
                                '300 - Imune',
                                '400 - Não tributada pelo Simples Nacional',
                                '500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação',
                                '900 - Outros'
                                ]} 
                            name={'CSOSN'} initialState={formDataSelection.CSOSN} handlerFuncion={handleChange}  />
                        </>
                    }
                    <Space left={5} top={5}/>
                    {   updateScreen?
                        getType():getType()
                    }
                </div>
            </div>
        </div>
    )
})

function TypeCST({formData,needData,handleChange,icms,isEdite,formDataSelection }){
    const cstPrefix = formDataSelection.CST ? formDataSelection.CST.slice(0, 2) : '';

    switch (cstPrefix) {
        case '00':
            return (
                <ICMS00
                    formData={formData}
                    needData={needData}
                    handleChange={handleChange}
                    icms={icms}
                    isEdite={isEdite}
                />
            ) 
        case '10':
            return (
                <ICMS10
                    formData={formData}
                    needData={needData}
                    handleChange={handleChange}
                    icms={icms}
                    isEdite={isEdite}
                />
            ) 
        case '20':
            return (
                <ICMS20
                    formData={formData}
                    needData={needData}
                    handleChange={handleChange}
                    icms={icms}
                    isEdite={isEdite}
                />
            ) 
        case '30':
            return (
                <ICMS30
                    formData={formData}
                    needData={needData}
                    handleChange={handleChange}
                    icms={icms}
                    isEdite={isEdite}
                />
            ) 
        case '40':
        case '41':
        case '50':        
            return (
                <ICMS40
                    formData={formData}
                    needData={needData}
                    handleChange={handleChange}
                    icms={icms}
                    isEdite={isEdite}
                />
            )
        case '51':        
            return (
                <ICMS51
                    formData={formData}
                    needData={needData}
                    handleChange={handleChange}
                    icms={icms}
                    isEdite={isEdite}
                />
            )
        case '60':        
            return (
                <ICMS60
                    formData={formData}
                    needData={needData}
                    handleChange={handleChange}
                    icms={icms}
                    isEdite={isEdite}
                />
            )
        case '70':        
            return (
                <ICMS70
                    formData={formData}
                    needData={needData}
                    handleChange={handleChange}
                    icms={icms}
                    isEdite={isEdite}
                />
            )
        case '90':        
            return (
                <ICMS90
                    formData={formData}
                    needData={needData}
                    handleChange={handleChange}
                    icms={icms}
                    isEdite={isEdite}
                />
            )
      default:
        return null; 
    }
}

function TypeCSOSN({formData,needData,handleChange,icms,isEdite,formDataSelection}){
    const csosnPrefix = formDataSelection.CSOSN ? formDataSelection.CSOSN.slice(0, 3) : '102';

    switch (csosnPrefix) {
      case '101':
        return (
            <ICMSSN101
                formData={formData}
                needData={needData}
                handleChange={handleChange}
                icms={icms}
                isEdite={isEdite}
            />
        )
        case '102':
        case '103':
        case '300':
        case '400':
            return (
                <ICMSSN102
                    formData={formData}
                    handleChange={handleChange}
                />
            )
        case '201':
            return (
                <ICMSSN201
                    formData={formData}
                    needData={needData}
                    handleChange={handleChange}
                    icms={icms}
                    isEdite={isEdite}
                />
            )
        case '202':
        case '203':
            return (
                <ICMSSN201_CSOSN202_CSOSN203
                    formData={formData}
                    needData={needData}
                    handleChange={handleChange}
                    icms={icms}
                    isEdite={isEdite}
                />
            )
        case '500':
            return (
                <ICMSSN500
                    formData={formData}
                    needData={needData}
                    handleChange={handleChange}
                    icms={icms}
                    isEdite={isEdite}
                />
            )
        case '900':
            return (
                <ICMSSN900
                    formData={formData}
                    needData={needData}
                    handleChange={handleChange}
                    icms={icms}
                    isEdite={isEdite}
                />
            )

      default:
        return null; 
    }
}

function ICMSSN101({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
            <Space left={5} top={5}/>
           
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pCredSN - ALÍQUOTA APLICÁVEL DE CÁLCULO DO CRÉDITO'} onChange={handleChange} maxLength={5} validation={needData.pCredSN} value={icms.ICMS.pCredSN} name={'pCredSN'} iconButton={faFileCode} type={"number"} placeholder={"Alíquota aplicável de cálculo do crédito (Simples Nacional)."}/>  
                { 
                    !isEdite?
                    <>
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vCredICMSSN - VALOR DE CRÉDITO DO ICMS QUE PODE SER APROVEITADO'} onChange={handleChange} validation={needData.vCredICMSSN} maxLength={15} value={icms.ICMS.vCredICMSSN} name={'vCredICMSSN'} iconButton={faFileCode} type={"number"} placeholder={"Valor do Crédito do ICMS....."}/> 
                    </>:
                    null
                }
            </div>
        </>
    )
}

function ICMSSN102({formData,handleChange}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
        </>
    )
}

function ICMSSN201({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBCST - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS ST'} 
            data={['0 – Preço tabelado ou máximo sugerido', 
                '1 - Lista Negativa (valor)', 
                '2 - Lista Positiva (valor)',
                '3 - Lista Neutra (valor)',
                '4 - Margem Valor Agregado (%)',
                '5 - Pauta (valor)'
            ]} 
            name={'modBCST'} initialState={formData.modBCST} handlerFuncion={handleChange}  />
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pMVAST - PERCENTUAL DA MARGEM DE VALOR ADICIONADO DO ICMS ST'} onChange={handleChange}  maxLength={5} validation={needData.pMVAST} value={icms.ICMS.pMVAST} name={'pMVAST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da margem de valor Adicionado do ICMS ST"}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pRedBCST - PERCENTUAL DA REDUÇÃO DE BC DO ICMS ST'} onChange={handleChange} validation={needData.pRedBCST} maxLength={5} value={icms.ICMS.pRedBCST} name={'pRedBCST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da Redução de BC do ICMS ST"}/>  
            </div>
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pCredSN - ALÍQUOTA APLICAVEL DE CÁLCULO DO CRÉDITO'} onChange={handleChange}  maxLength={5} validation={needData.pCredSN} value={icms.ICMS.pCredSN} name={'pCredSN'} iconButton={faFileCode} type={"number"} placeholder={"Alíquota aplicável de cálculo do crédito (SIMPLES NACIONAL). "}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pICMSST - ALÍQUOTA DO IMPOSTO DO ICMS ST'} onChange={handleChange} validation={needData.pICMSST} maxLength={5} value={icms.ICMS.pICMSST} name={'pICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Alíquota do imposto do ICMS ST"}/>         
            </div>
            {   
                !isEdite?
                <> 
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vBCST - VALOR DA BC DO ICMS ST'} onChange={handleChange} maxLength={15} validation={needData.vBCST} value={icms.ICMS.vBCST} name={'vBCST'} iconButton={faFileCode} type={"number"} placeholder={"Valor da BC do ICMS ST."}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vCredICMSSN - VALOR DE CRÉDITO DO ICMS QUE PODE SER APROVEITADO'} onChange={handleChange} validation={needData.vCredICMSSN} maxLength={15} value={icms.ICMS.vCredICMSSN} name={'vCredICMSSN'} iconButton={faFileCode} type={"number"} placeholder={"Valor crédito do ICMS que pode ser aproveitado"}/>  
                    </div>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vICMSST - VALOR DE CRÉDITO DO ICMS QUE PODE SER APROVEITADO'} onChange={handleChange} validation={needData.vICMSST} maxLength={15} value={icms.ICMS.vICMSST} name={'vICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS ST"}/> 
                    </div>
                </>:null
            }
        </>
    )
}

function ICMSSN201_CSOSN202_CSOSN203({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBCST - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS ST'} 
            data={['0 – Preço tabelado ou máximo sugerido', 
                '1 - Lista Negativa (valor)', 
                '2 - Lista Positiva (valor)',
                '3 - Lista Neutra (valor)',
                '4 - Margem Valor Agregado (%)',
                '5 - Pauta (valor)'
            ]} 
            name={'modBCST'} initialState={formData.modBCST} handlerFuncion={handleChange}  />
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pMVAST - PERCENTUAL DA MARGEM DE VALOR ADICIONADO DO ICMS ST'} onChange={handleChange} maxLength={5} validation={needData.pMVAST} value={icms.ICMS.pMVAST} name={'pMVAST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da margem de valor Adicionado do ICMS ST"}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pRedBCST - PERCENTUAL DA REDUÇÃO DE BC DO ICMS ST'} onChange={handleChange} validation={needData.pRedBCST} maxLength={5} value={icms.ICMS.pRedBCST} name={'pRedBCST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da Redução de BC do ICMS ST"}/>  
            </div>
            {
                !isEdite?
                <>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vBCST - VALOR DA BC DO ICMS ST'} onChange={handleChange} maxLength={15} validation={needData.vBCST} value={icms.ICMS.vBCST} name={'vBCST'} iconButton={faFileCode} type={"number"} placeholder={"Valor da BC do ICMS ST."}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vICMSST - VALOR DO ICMS ST'} onChange={handleChange} validation={needData.vICMSST} maxLength={15} value={icms.ICMS.vICMSST} name={'vICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS ST"}/>  
                    </div>  
                </>:null
            }
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pICMSST - ALÍQUOTA DO IMPOSTO DO ICMS ST'} onChange={handleChange} validation={needData.pICMSST} maxLength={5} value={icms.ICMS.pICMSST} name={'pICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Alíquota do imposto do ICMS ST"}/>         
            </div>  
        </>
    )
}

function ICMSSN500({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
            {
                !isEdite?
                <>
                <Space left={5} top={5}/>
                <div className={style.divInRow}>
                    <InputModelsBlock legend={'vBCSTRet - VALOR DA BC DO ICMS ST RETIDO'} onChange={handleChange} maxLength={5} validation={needData.vBCSTRet} value={icms.ICMS.vBCSTRet} name={'vBCSTRet'} iconButton={faFileCode} type={"number"} placeholder={"Valor da BC do ICMS ST retido "}/>  
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'vICMSSTRet - VALOR DO ICMS ST RETIDO'} onChange={handleChange} validation={needData.vICMSSTRet} maxLength={5} value={icms.ICMS.vICMSSTRet} name={'vICMSSTRet'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS ST cobrado anteriormente por ST"}/>  
                </div> 
                </>:null
            }
        </>
    )
}

function ICMSSN900({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBC - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS'} 
            data={['0 - Margem Valor Agregado (%);', 
                '1 - Pauta (Valor)', 
                '2 - Preço Tabelado Máx. (valor)',
                '3 - valor da operação',
            ]} 
            name={'modBC'} initialState={formData.modBC} handlerFuncion={handleChange}  />
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBCST - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS ST'} 
            data={['0 – Preço tabelado ou máximo sugerido', 
                '1 - Lista Negativa (valor)', 
                '2 - Lista Positiva (valor)',
                '3 - Lista Neutra (valor)',
                '4 - Margem Valor Agregado (%)',
                '5 - Pauta (valor)'
            ]} 
            name={'modBCST'} initialState={formData.modBCST} handlerFuncion={handleChange}  />
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pICMS - ALÍQUOTA DO IMPOSTO'} onChange={handleChange} maxLength={5} validation={needData.pICMS} value={icms.ICMS.pICMS} name={'pICMS'} iconButton={faFileCode} type={"number"} placeholder={"Alíquota do Imposto"}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pRedBC - PERCENTUAL DA REDUÇÃO DE BC'} onChange={handleChange} validation={needData.pRedBC} maxLength={5} value={icms.ICMS.pRedBC} name={'pRedBC'} iconButton={faPercent} type={"number"} placeholder={"Percentual da Redução de BC"}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pCredSN - ALÍQUOTA APLICAVEL DE CÁLCULO DO CRÉDITO'} onChange={handleChange}  maxLength={5} validation={needData.pCredSN} value={icms.ICMS.pCredSN} name={'pCredSN'} iconButton={faFileCode} type={"number"} placeholder={"Alíquota aplicável de cálculo do crédito (SIMPLES NACIONAL). "}/>  
            </div>
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pMVAST - PERCENTUAL DA MARGEM DE VALOR ADICIONADO DO ICMS ST'} onChange={handleChange} maxLength={5} validation={needData.pMVAST} value={icms.ICMS.pMVAST} name={'pMVAST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da margem de valor Adicionado do ICMS ST"}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pICMSST - ALÍQUOTA DO IMPOSTO DO ICMS ST'} onChange={handleChange} validation={needData.pICMSST} maxLength={5} value={icms.ICMS.pICMSST} name={'pICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Alíquota do imposto do ICMS ST"}/>         
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pRedBCST - PERCENTUAL DA REDUÇÃO DE BC DO ICMS ST'} onChange={handleChange} validation={needData.pRedBCST} maxLength={5} value={icms.ICMS.pRedBCST} name={'pRedBCST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da Redução de BC do ICMS ST"}/>  
            </div>
            
            {
                !isEdite?
                <>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vBCST - VALOR DA BC DO ICMS ST'} onChange={handleChange} maxLength={15} validation={needData.vBCST} value={icms.ICMS.vBCST} name={'vBCST'} iconButton={faFileCode} type={"number"} placeholder={"Valor da BC do ICMS ST."}/>  
                    </div>  
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vBC - VALOR DA BC DO ICMS'} onChange={handleChange}  maxLength={15} validation={needData.vBC} value={icms.ICMS.vBC} name={'vBC'} iconButton={faCode} type={"number"} placeholder={"Valor da BC do ICMS"}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vICMS - VALOR DO ICMS'} onChange={handleChange} validation={needData.vICMS} maxLength={15} value={icms.ICMS.vICMS} name={'vICMS'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS"}/>         
                    </div>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vBCSTRet - VALOR DA BC DO ICMS ST RETIDO'} onChange={handleChange} maxLength={5} validation={needData.vBCSTRet} value={icms.ICMS.vBCSTRet} name={'vBCSTRet'} iconButton={faFileCode} type={"number"} placeholder={"Valor da BC do ICMS ST retido "}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vICMSST - VALOR DO ICMS ST'} onChange={handleChange} validation={needData.vICMSST} maxLength={15} value={icms.ICMS.vICMSST} name={'vICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS ST"}/>  
                    </div>  
                </>:null
            }

        </>
    )
}

function ICMS00({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBC - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS'} 
            data={['0 - Margem Valor Agregado (%);', 
                '1 - Pauta (Valor)', 
                '2 - Preço Tabelado Máx. (valor)',
                '3 - valor da operação',
            ]} 
            name={'modBC'} initialState={formData.modBC} handlerFuncion={handleChange}  />
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pICMS - Alíquota de Imposto'} onChange={handleChange} validation={needData.pICMS} maxLength={5} value={icms.ICMS.pICMS} name={'pICMS'} iconButton={faPercent} type={"number"} placeholder={"Alíquota do imposto"}/>  
            </div>
            {
                !isEdite?
                <>
                <Space left={5} top={5}/>
                <div className={style.divInRow}>
                    <InputModelsBlock legend={'vBC - VALOR DA BC DO ICMS'} onChange={handleChange}  maxLength={15} validation={needData.vBC} value={icms.ICMS.vBC} name={'vBC'} iconButton={faCode} type={"number"} placeholder={"Valor da BC do ICMS"}/>  
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'vICMS - VALOR DO ICMS'} onChange={handleChange} validation={needData.vICMS} maxLength={15} value={icms.ICMS.vICMS} name={'vICMS'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS"}/>         
                </div>
                </>:null
            }
        </>
    )
}

function ICMS10({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBC - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS'} 
            data={['0 - Margem Valor Agregado (%);', 
                '1 - Pauta (Valor)', 
                '2 - Preço Tabelado Máx. (valor)',
                '3 - valor da operação',
            ]} 
            name={'modBC'} initialState={formData.modBC} handlerFuncion={handleChange}  />
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBCST - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS ST'} 
            data={['0 – Preço tabelado ou máximo sugerido', 
                '1 - Lista Negativa (valor)', 
                '2 - Lista Positiva (valor)',
                '3 - Lista Neutra (valor)',
                '4 - Margem Valor Agregado (%)',
                '5 - Pauta (valor)'
            ]} 
            name={'modBCST'} initialState={formData.modBCST} handlerFuncion={handleChange}  />
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pICMSST - ALÍQUOTA DO IMPOSTO DO ICMS ST'} onChange={handleChange} validation={needData.pICMSST} maxLength={5} value={icms.ICMS.pICMSST} name={'pICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Alíquota do imposto do ICMS ST"}/>          
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pICMS - Alíquota de Imposto'} onChange={handleChange} validation={needData.pICMS} maxLength={5} value={icms.ICMS.pICMS} name={'pICMS'} iconButton={faPercent} type={"number"} placeholder={"Alíquota do imposto"}/>  
            </div>
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pMVAST - PERCENTUAL DA MARGEM DE VALOR ADICIONADO DO ICMS ST'} onChange={handleChange} maxLength={5} validation={needData.pMVAST} value={icms.ICMS.pMVAST} name={'pMVAST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da margem de valor Adicionado do ICMS ST"}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pRedBCST - PERCENTUAL DA REDUÇÃO DE BC DO ICMS ST'} onChange={handleChange} validation={needData.pRedBCST} maxLength={5} value={icms.ICMS.pRedBCST} name={'pRedBCST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da Redução de BC do ICMS ST"}/>  
            </div>
            {
                !isEdite?
                <>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vBCST - VALOR DA BC DO ICMS ST'} onChange={handleChange} maxLength={15} validation={needData.vBCST} value={icms.ICMS.vBCST} name={'vBCST'} iconButton={faFileCode} type={"number"} placeholder={"Valor da BC do ICMS ST."}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vBC - VALOR DA BC DO ICMS'} onChange={handleChange}  maxLength={15} validation={needData.vBC} value={icms.ICMS.vBC} name={'vBC'} iconButton={faCode} type={"number"} placeholder={"Valor da BC do ICMS"}/> 
                    </div>  
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vICMS - VALOR DO ICMS'} onChange={handleChange} validation={needData.vICMS} maxLength={15} value={icms.ICMS.vICMS} name={'vICMS'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS"}/>         
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vICMSST - VALOR DO ICMS ST'} onChange={handleChange} validation={needData.vICMSST} maxLength={15} value={icms.ICMS.vICMSST} name={'vICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS ST"}/>  
                    </div>
                </>:null
            }
        </>
    )
}

function ICMS20({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBC - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS'} 
            data={['0 - Margem Valor Agregado (%);', 
                '1 - Pauta (Valor)', 
                '2 - Preço Tabelado Máx. (valor)',
                '3 - valor da operação',
            ]} 
            name={'modBC'} initialState={formData.modBC} handlerFuncion={handleChange}  />
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pICMS - Alíquota de Imposto'} onChange={handleChange} validation={needData.pICMS} maxLength={5} value={icms.ICMS.pICMS} name={'pICMS'} iconButton={faPercent} type={"number"} placeholder={"Alíquota do imposto"}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pRedBC - PERCENTUAL DA REDUÇÃO BC'} onChange={handleChange} validation={needData.pRedBC} maxLength={5} value={icms.ICMS.pRedBC} name={'pRedBC'} iconButton={faPercent} type={"number"} placeholder={"Percentual da Redução de BC"}/>  
            </div>
            {
                !isEdite?
                <>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vBC - VALOR DA BC DO ICMS'} onChange={handleChange}  maxLength={15} validation={needData.vBC} value={icms.ICMS.vBC} name={'vBC'} iconButton={faCode} type={"number"} placeholder={"Valor da BC do ICMS"}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vICMS - VALOR DO ICMS'} onChange={handleChange} validation={needData.vICMS} maxLength={15} value={icms.ICMS.vICMS} name={'vICMS'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS"}/>         
                    </div>  
                </>:null
            }
        </>
    )
}

function ICMS30({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBCST - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS ST'} 
            data={['0 – Preço tabelado ou máximo sugerido', 
                '1 - Lista Negativa (valor)', 
                '2 - Lista Positiva (valor)',
                '3 - Lista Neutra (valor)',
                '4 - Margem Valor Agregado (%)',
                '5 - Pauta (valor)'
            ]} 
            name={'modBCST'} initialState={formData.modBCST} handlerFuncion={handleChange}  />
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pMVAST - PERCENTUAL DA MARGEM DE VALOR ADICIONADO DO ICMS ST'} onChange={handleChange} maxLength={5} validation={needData.pMVAST} value={icms.ICMS.pMVAST} name={'pMVAST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da margem de valor Adicionado do ICMS ST"}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pRedBCST - PERCENTUAL DA REDUÇÃO DE BC DO ICMS ST'} onChange={handleChange} validation={needData.pRedBCST} maxLength={5} value={icms.ICMS.pRedBCST} name={'pRedBCST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da Redução de BC do ICMS ST"}/>  
            </div>
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pICMSST - ALÍQUOTA DO IMPOSTO DO ICMS ST'} onChange={handleChange} validation={needData.pICMSST} maxLength={5} value={icms.ICMS.pICMSST} name={'pICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Alíquota do imposto do ICMS ST"}/>         
            </div>  
            {
                !isEdite?
                <>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vBCST - VALOR DA BC DO ICMS ST'} onChange={handleChange} maxLength={15} validation={needData.vBCST} value={icms.ICMS.vBCST} name={'vBCST'} iconButton={faFileCode} type={"number"} placeholder={"Valor da BC do ICMS ST."}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vICMSST - VALOR DO ICMS ST'} onChange={handleChange} validation={needData.vICMSST} maxLength={15} value={icms.ICMS.vICMSST} name={'vICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS ST"}/>  
                    </div>  
                </>:null
            }
        </>
    )
}

function ICMS40({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
            <Space left={5} top={5}/>
            <GenericDropDown legend={'motDesICMS - MOTIVO DA DESONERAÇÃO DO ICMS'} 
            data={['1 – Táxi', 
                '2 – Deficiente Físico', 
                '3 – Produtor Agropecuário',
                '4 – Frotista/Locadora',
                '5 – Diplomático/Consular',
                '6 – Utilitários e Motocicletas da Amazônia Ocidental e Áreas de Livre Comércio (Resolução 714/88 e 790/94 – CONTRAN e suas alterações',
                '7 – SUFRAMA',
                '9 – outros'
            ]} 
            name={'motDesICMS'} initialState={formData.motDesICMS} handlerFuncion={handleChange}  />
            {
                !isEdite?
                <>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vICMS - VALOR DO ICMS'} onChange={handleChange} validation={needData.vICMS} maxLength={15} value={icms.ICMS.vICMS} name={'vICMS'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS"}/>         
                    </div>
                </>:null
            } 
        </>
    )
}

function ICMS51({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBC - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS'} 
            data={['0 - Margem Valor Agregado (%);', 
                '1 - Pauta (Valor)', 
                '2 - Preço Tabelado Máx. (valor)',
                '3 - valor da operação',
            ]} 
            name={'modBC'} initialState={formData.modBC} handlerFuncion={handleChange}  />
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pRedBC - PERCENTUAL DA REDUÇÃO DE BC'} onChange={handleChange} validation={needData.pRedBC} maxLength={5} value={icms.ICMS.pRedBC} name={'pRedBC'} iconButton={faPercent} type={"number"} placeholder={"Percentual da Redução de BC"}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pICMS - Alíquota de Imposto'} onChange={handleChange} validation={needData.pICMS} maxLength={5} value={icms.ICMS.pICMS} name={'pICMS'} iconButton={faPercent} type={"number"} placeholder={"Alíquota do imposto"}/>  
            </div>
            {
                !isEdite?
                <>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>        
                        <InputModelsBlock legend={'vICMS - VALOR DO ICMS'} onChange={handleChange} validation={needData.vICMS} maxLength={15} value={icms.ICMS.vICMS} name={'vICMS'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS"}/>         
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vBC - VALOR DA BC DO ICMS'} onChange={handleChange}  maxLength={15} validation={needData.vBC} value={icms.ICMS.vBC} name={'vBC'} iconButton={faCode} type={"number"} placeholder={"Valor da BC do ICMS"}/>  
                    </div>
                </>:null
            }
        </>
    )
}

function ICMS60({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
           {
                !isEdite?            
                <>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vICMSSTRet - VALOR DO ICMS RETIDO'} onChange={handleChange} validation={needData.vICMSSTRet} maxLength={15} value={icms.ICMS.vICMSSTRet} name={'vICMSSTRet'} iconButton={faFileCode} type={"number"} placeholder={"Valor da BC do ICMS ST cobrado anteriormente por ST "}/>         
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vBCSTRet - VALOR DA BC DO ICMS RETIDO'} onChange={handleChange} validation={needData.vBCSTRet} maxLength={15} value={icms.ICMS.vBCSTRet} name={'vBCSTRet'} iconButton={faPercent} type={"number"} placeholder={"Valor do ICMS ST cobrado"}/>  
                    </div>
                </>:null
           }
        </>
    )
}

function ICMS70({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBC - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS'} 
            data={['0 - Margem Valor Agregado (%);', 
                '1 - Pauta (Valor)', 
                '2 - Preço Tabelado Máx. (valor)',
                '3 - valor da operação',
            ]} 
            name={'modBC'} initialState={formData.modBC} handlerFuncion={handleChange}  />
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBCST - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS ST'} 
            data={['0 – Preço tabelado ou máximo sugerido', 
                '1 - Lista Negativa (valor)', 
                '2 - Lista Positiva (valor)',
                '3 - Lista Neutra (valor)',
                '4 - Margem Valor Agregado (%)',
                '5 - Pauta (valor)'
            ]} 
            name={'modBCST'} initialState={formData.modBCST} handlerFuncion={handleChange}  />
            {
                !isEdite?
                <>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vBCST - VALOR DA BC DO ICMS ST'} onChange={handleChange} maxLength={15} validation={needData.vBCST} value={icms.ICMS.vBCST} name={'vBCST'} iconButton={faFileCode} type={"number"} placeholder={"Valor da BC do ICMS ST."}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vBC - VALOR DA BC DO ICMS'} onChange={handleChange}  maxLength={15} validation={needData.vBC} value={icms.ICMS.vBC} name={'vBC'} iconButton={faCode} type={"number"} placeholder={"Valor da BC do ICMS"}/>                
                    </div>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vICMS - VALOR DO ICMS'} onChange={handleChange} validation={needData.vICMS} maxLength={15} value={icms.ICMS.vICMS} name={'vICMS'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS"}/>         
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vICMSST - VALOR DO ICMS ST'} onChange={handleChange} validation={needData.vICMSST} maxLength={15} value={icms.ICMS.vICMSST} name={'vICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS ST"}/>  
                    </div>  
                </>:null
            }
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pMVAST - PERCENTUAL DA MARGEM DE VALOR ADICIONADO DO ICMS ST'} onChange={handleChange} maxLength={5} validation={needData.pMVAST} value={icms.ICMS.pMVAST} name={'pMVAST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da margem de valor Adicionado do ICMS ST"}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pRedBCST - PERCENTUAL DA REDUÇÃO DE BC DO ICMS ST'} onChange={handleChange} validation={needData.pRedBCST} maxLength={5} value={icms.ICMS.pRedBCST} name={'pRedBCST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da Redução de BC do ICMS ST"}/>  
            </div>
            <Space left={5} top={5}/>
            <div className={style.divInRow}> 
                <InputModelsBlock legend={'pICMS - Alíquota de Imposto'} onChange={handleChange} validation={needData.pICMS} maxLength={5} value={icms.ICMS.pICMS} name={'pICMS'} iconButton={faPercent} type={"number"} placeholder={"Alíquota do imposto"}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pICMSST - ALÍQUOTA DO IMPOSTO DO ICMS ST'} onChange={handleChange} validation={needData.pICMSST} maxLength={5} value={icms.ICMS.pICMSST} name={'pICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Alíquota do imposto do ICMS ST"}/>         
            </div>  
            <Space left={5} top={5}/>
            <div className={style.divInRow}>  
                <InputModelsBlock legend={'pRedBC - PERCENTUAL DA REDUÇÃO DE BC'} onChange={handleChange} validation={needData.pRedBC} maxLength={5} value={icms.ICMS.pRedBC} name={'pRedBC'} iconButton={faPercent} type={"number"} placeholder={"Percentual da Redução de BC"}/>  
            </div>
        </>
    )
}

function ICMS90({formData,needData,handleChange,icms,isEdite}){
    return(
        <>
            <GenericDropDown legend={'ORIGEM DA MERCADORIA'} 
            data={['0 - Nacional', 
                '1 - Estrangeira - Importação direta', 
                '2 - Estrangeira - Adquirida no mercado interno',
            ]} 
            name={'orig'} initialState={formData.orig} handlerFuncion={handleChange}  /> 
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBC - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS'} 
            data={['0 - Margem Valor Agregado (%);', 
                '1 - Pauta (Valor)', 
                '2 - Preço Tabelado Máx. (valor)',
                '3 - valor da operação',
            ]} 
            name={'modBC'} initialState={formData.modBC} handlerFuncion={handleChange}  />
            <Space left={5} top={5}/>
            <GenericDropDown legend={'modBCST - MODALIDADE DE DETERMINAÇÃO DA BC DO ICMS ST'} 
            data={['0 – Preço tabelado ou máximo sugerido', 
                '1 - Lista Negativa (valor)', 
                '2 - Lista Positiva (valor)',
                '3 - Lista Neutra (valor)',
                '4 - Margem Valor Agregado (%)',
                '5 - Pauta (valor)'
            ]} 
            name={'modBCST'} initialState={formData.modBCST} handlerFuncion={handleChange}  />
            {
                !isEdite?
                <>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vBC - VALOR DA BC DO ICMS'} onChange={handleChange}  maxLength={15} validation={needData.vBC} value={icms.ICMS.vBC} name={'vBC'} iconButton={faCode} type={"number"} placeholder={"Valor da BC do ICMS"}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vBCST - VALOR DA BC DO ICMS ST'} onChange={handleChange} maxLength={15} validation={needData.vBCST} value={icms.ICMS.vBCST} name={'vBCST'} iconButton={faFileCode} type={"number"} placeholder={"Valor da BC do ICMS ST."}/>  
                    </div>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock legend={'vICMS - VALOR DO ICMS'} onChange={handleChange} validation={needData.vICMS} maxLength={15} value={icms.ICMS.vICMS} name={'vICMS'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS"}/>         
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'vICMSST - VALOR DO ICMS ST'} onChange={handleChange} validation={needData.vICMSST} maxLength={15} value={icms.ICMS.vICMSST} name={'vICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Valor do ICMS ST"}/>  
                    </div>  
                </>:null
            }
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pMVAST - PERCENTUAL DA MARGEM DE VALOR ADICIONADO DO ICMS ST'} onChange={handleChange} maxLength={5} validation={needData.pMVAST} value={icms.ICMS.pMVAST} name={'pMVAST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da margem de valor Adicionado do ICMS ST"}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pRedBCST - PERCENTUAL DA REDUÇÃO DE BC DO ICMS ST'} onChange={handleChange} validation={needData.pRedBCST} maxLength={5} value={icms.ICMS.pRedBCST} name={'pRedBCST'} iconButton={faPercent} type={"number"} placeholder={"Percentual da Redução de BC do ICMS ST"}/>  
            </div>
            <Space left={5} top={5}/>
            <div className={style.divInRow}>
                <InputModelsBlock legend={'pICMS - Alíquota de Imposto'} onChange={handleChange} validation={needData.pICMS} maxLength={5} value={icms.ICMS.pICMS} name={'pICMS'} iconButton={faPercent} type={"number"} placeholder={"Alíquota do imposto"}/>  
                <Space left={5} top={5}/>
                <InputModelsBlock legend={'pICMSST - ALÍQUOTA DO IMPOSTO DO ICMS ST'} onChange={handleChange} validation={needData.pICMSST} maxLength={5} value={icms.ICMS.pICMSST} name={'pICMSST'} iconButton={faFileCode} type={"number"} placeholder={"Alíquota do imposto do ICMS ST"}/>         
            </div>  
            <Space left={5} top={5}/>
            <div className={style.divInRow}>  
                <InputModelsBlock legend={'pRedBC - PERCENTUAL DA REDUÇÃO DE BC'} onChange={handleChange} validation={needData.pRedBC} maxLength={5} value={icms.ICMS.pRedBC} name={'pRedBC'} iconButton={faPercent} type={"number"} placeholder={"Percentual da Redução de BC"}/>  
            </div>
        </>
    )
}