import React, { useState, useEffect,useRef } from 'react'
import style from "../../../style/ListClients.module.css"
import { GetSmallModels } from '../../../Models/GetModels'
import { faList ,faMagnifyingGlass,faCircleInfo, faPlus, faDownload} from '@fortawesome/free-solid-svg-icons'
import Loader from '../../../layout/Loader'
import InputModels from "../../../Models/InputModels.js"
import GetAmountList from '../../../Models/GetAmountList.js'
import GetExpandedImage from '../../../layout/GetExpandedImage.js'
import BrandsModel from '../../../Models/BrandsModel.js'
import { useNavigate } from 'react-router-dom'
import { routerFromStockNewBrand } from '../../../routers/routerList.js'
import { TitlePageResponsiveIcon } from '../../../layout/TitlePage.js'
import GetFloatingButton from '../../../layout/GetFloatingButton.js'
import Space from '../../../layout/Space.js'
import { GetButton } from '../../../layout/ButtonsLayout.js'
import EditeBrand from './EditeBrand.js'
import { useError } from '../../../AppContext.js'
import { findBrands, listBrandsMemory, updateIfNull } from '../../../backEnd/Memory.js'

function ListBrands() {
  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState([])
  const [list, setList] = useState(listBrandsMemory)

  const [brandSelected,setBrandSelected] = useState(null)
  const [showEditeBrand,setShowEditeBrand] = useState(false)

  const [loading, setLoading] = useState(false)
  const {showError} = useError()

  const [filter, setFilter] = useState('TODAS AS MARCAS')
  const [orderBy, setOrderBy] = useState('asc')

  const [isExpanded, setIsExpanded] = useState(false)
  const [nameProduct, setNameProduct] = useState('')
  const [linkImage, setSetLinkImage] = useState('')
  
  const toggleImageExpansion = (nameProduct,linkImage) => {
      setIsExpanded(!isExpanded)
      setNameProduct(nameProduct)
      setSetLinkImage(linkImage)
  }

  useEffect(() => {
    async function fetchData() {
        setLoading(true)
        setList([])
        await updateIfNull(showError) 
        setList(listBrandsMemory)
        handleUpdate()   
        setLoading(false) 
    }
    fetchData()
  }, [])

  useEffect(() => {
    handleChangeFilter()
  }, [filter, orderBy,list])

  const handleUpdate = ()=>{
    setList(listBrandsMemory)
    setFilter('TODAS AS MARCAS')
    setOrderBy('asc')
  }

  const handleFindDataBase = async () => {
    setLoading(true)
    setList([])
    await findBrands(showError)
    handleUpdate()
    setLoading(false)
  }
  
  const controllerFilter = () => {
    let data = []
    switch (filter) {
      case 'all':
        data = list.filter(item => item.nameBrand)
        break
      case 'active':
        data = list.filter(item => item.isActiveBrand)
        break
      case 'inactive':
        data = list.filter(item => !item.isActiveBrand)
        break
      default:
        data = list
        break
    }

    let sortedClients = [...data];
    switch (orderBy) {
      case 'asc':
        sortedClients.sort((a, b) => a.nameBrand.localeCompare(b.nameBrand))
        break
      case 'desc':
        sortedClients.sort((a, b) => b.nameBrand.localeCompare(a.nameBrand))
        break
      default:
        break
    }
    return sortedClients;
  }

  const handleFilterChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }
  
  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()
    resetScroll()
    if(value!= null){
      const filtered= data.filter(
        brands => {
          const filterName= brands.nameBrand.toLowerCase().includes(value.target.value.toLowerCase())
          const filterStatus = brands.isActiveBrand.toString().toLowerCase().includes(value.target.value.toLowerCase())
          return filterName || filterStatus
        }
      )
      setFiltered(filtered)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    container.scrollTop = 0
    setLimit(50)
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  const handleAddBrand = async (event) => {
    navigate(routerFromStockNewBrand);
  }

  const handleSelectBrand = (brand) => {
    setBrandSelected(brand)
    setShowEditeBrand(true)
  }

  return (
    <div className={style.containerGeneral}>
        <div className={style.containerGlobal}>  
            <div className={style.titleMenu}>     
              <TitlePageResponsiveIcon subtitle={'Lista de Marcas Cadastradas no Sistema'} 
              children={
                <>
                 {!loading?<GetButton responsive={true} title={'BUSCAR NO SERVIDOR'} 
                color='#007bff' icon={faDownload} handleFunction={handleFindDataBase}/>:null}
                </>
              }
              path={'São Todas as Marcas Cadastradas no Sistema...'} icon={faList}/>
            </div>
            <div className={style.buildList}>
              <div className={style.filter}>
                  <div className={style.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} type={"text"} placeholder={"Buscar Marcas na Lista..."}
                    onChange={handleChangeFilter}/>
                  </div>
                  <div className={style.containerDropDown}>
                    <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                        <option value="all">TODAS AS MARCAS</option>
                        <option value="active">ATIVA</option>
                        <option value="inactive">DESATIVA</option>    
                    </select>
                    <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                        <option value="asc">CRESCENTE</option>
                        <option value="desc">DESCRESCENTE</option>
                    </select>
                  </div>
              </div>
              <Space right={2} left={2} top={5} bottom={0}/> 
              {(filtered.length > 0 && !loading ) ?<div className={style.buildList}>
                <GetAmountList amount={filtered.length} title={"Marcas Totais Encontradas na Busca..."}/>
                <div className={style.labelData}>
                  <h5>Nome</h5>
                  <h5>Tipo de Produto</h5>
                  <h5>Status</h5>
                </div>
                <Space right={0} left={0} top={0} bottom={10}/> 
              </div> : null}
              <div ref={containerRef} className={style.containerList} onScroll={checkScroll}>
                  <div className={style.loaderCenter}>
                    <Loader titleFinding={'Buscando no Servidor!'} 
                         subtitleFinding={'Aguarde...'} loading={loading}/>
                  </div>
                  <div>
                    {(filtered.length === 0 && !loading)?
                    <GetSmallModels title={"Não há Marcas com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo} />:
                      filtered.slice(0, limit).map((data, index) => (
                          <div key={index}>
                            <BrandsModel data={data} fucntionButton={()=>handleSelectBrand(data)} functionImage={
                              ()=>toggleImageExpansion(data.nameBrand,data.linkIconBrand)} />
                          </div>
                    ))
                  }
                  </div>
              </div>
            </div>
            {showEditeBrand? <EditeBrand brand={brandSelected} handleSaveBrand={handleFindDataBase} handleClose={()=>setShowEditeBrand(false) }/> : null}
            {isExpanded? <GetExpandedImage nameProduct={nameProduct} linkImage={linkImage} onClose={toggleImageExpansion}/>:null}
            <GetFloatingButton icon={faPlus} color='#007bff'width={window.innerWidth <= 1200 ? 65:85} margiBottom={'1%'}  height={window.innerWidth <= 1200 ? 65:85} margiRight={'1%'} handleFunction={handleAddBrand}/>
       </div>
    </div>
  )
}

export default ListBrands