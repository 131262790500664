import React, { useState, useEffect,useRef } from 'react'
import style from "../../../style/ListClients.module.css"
import { GetSmallModels } from '../../../Models/GetModels'
import { faDatabase ,faMagnifyingGlass,faCircleInfo,faTruckMoving, faDownload, faTicket} from '@fortawesome/free-solid-svg-icons'
import Loader from '../../../layout/Loader'
import InputModels from "../../../Models/InputModels.js"
import ItemModel from '../../../Models/ItemModel.js'
import GetAmountList from '../../../Models/GetAmountList.js'
import GetExpandedImage from '../../../layout/GetExpandedImage.js'
import { TitlePageResponsiveIcon } from '../../../layout/TitlePage.js'
import { GetButton } from '../../../layout/ButtonsLayout.js'
import GetFloatingButton from '../../../layout/GetFloatingButton.js'
import Space from '../../../layout/Space.js'
import ViewItemBj from './ViewItemBj.js'
import Alert from '../../../Models/Alert.js'
import { useError } from '../../../AppContext.js'
import { useNavigate } from 'react-router-dom'
import { routerFromStockRepositionBJ } from '../../../routers/routerList.js'
import { brunaSemijoiasNFE, findBjItems, findBjSettingsTax, listItemsMemory, updateIfNull } from '../../../backEnd/Memory.js'
import GetEditeNfeBj from '../../Nfe/GetEditeNfeBj.js'

function ListStock() {
  const navigate = useNavigate()
  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])

  const [itemBjSelected,setItemBjSelected] = useState(null)
  const [showEditeItem,setShowEditeItem] = useState(false)
  const [filtered, setFiltered] = useState([])
  const [list, setList] = useState(listItemsMemory)
  const [showAlertSave,setShowAlertSave] = useState(false)
  const [loading, setLoading] = useState(false)

  const {showError} = useError()

  const [filter, setFilter] = useState('MEU ESTOQUE')
  const [orderBy, setOrderBy] = useState('asc')


  const [editeNFE, setEditeNfe] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [nameProduct, setNameProduct] = useState('')
  const [linkImage, setSetLinkImage] = useState('')
  const toggleImageExpansion = (nameProduct,linkImage) => {
      setIsExpanded(!isExpanded)
      setNameProduct(nameProduct)
      setSetLinkImage(linkImage)
  }

  useEffect(() => {
    async function fetchData() {
        setLoading(true)
        await updateIfNull(showError)  
        handleUpdate()  
        setLoading(false) 
    }
    fetchData()
  }, [])

  useEffect(() => {
    handleChangeFilter()
  }, [filter, orderBy,list]);


  const handleUpdate = () => {
    setList(listItemsMemory)
    setFilter('MEU ESTOQUE')
    setOrderBy('asc')
  }

  const handleFindDataBase = async () => {
    setList([])
    setLoading(true)
    await findBjItems(showError)
    handleUpdate()
    setLoading(false)
  }
  
  const controllerFilter = () => {
    let data = []
    switch (filter) {
      case 'AB':
      case 'BB':
      case 'MB':
      case 'GB':
      case 'BP':
      case 'CE':
      case 'BT':
        data = list.filter(item => item.itemGlobal.databaseName === `BANCO DE DADOS - ${filter}`)
        break
      case 'MEU ESTOQUE':
        data = list.filter(item => item.amount > 0)
        break
      case 'SEM ESTOQUE':
        data = list.filter(item => item.amount === 0)
        break
      default:
        data = list
        break
    }

    let sortedClients = [...data];
    switch (orderBy) {
      case 'asc':
        sortedClients.sort((a, b) => a.itemGlobal.name.localeCompare(b.itemGlobal.name))
        break
      case 'desc':
        sortedClients.sort((a, b) => b.itemGlobal.name.localeCompare(a.itemGlobal.name))
        break
      default:
        break
    }
    return sortedClients;
  }

  const handleFilterChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }
  
  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()
    resetScroll()
    if(value!= null){
      const filteredStock = data.filter(
        stock => {
          const filterCodeItems = stock.itemGlobal.name.toLowerCase().includes(value.target.value.toLowerCase());
          const filterFactorItem = stock.itemGlobal.factorItem.toString().toLowerCase().includes(value.target.value.toLowerCase());
          const filterCodebar = stock.itemGlobal.codebar.toString().toLowerCase().includes(value.target.value.toLowerCase());
          return filterCodeItems || filterFactorItem || filterCodebar
        }
      )
      setFiltered(filteredStock)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    container.scrollTop = 0
    setLimit(50)
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  const handleSelectItem = (item) => {
    setItemBjSelected(item)
    setShowEditeItem(true)
  }

  const handleSave=()=>{
    setShowAlertSave(true)
    handleFindDataBase()
  }

  return (
   <div className={style.containerGeneral}>
      <div className={style.containerGlobal}>  
          <div className={style.titleMenu}>     
            <TitlePageResponsiveIcon subtitle={'Lista do Estoque Bruna Semijoias'} 
            children={
              <>
                {!loading?
                  <div className={style.row}>
                    <GetButton responsive={true} title={'CONFIGURAR NOTA FISCAL'} 
                    color='#ff0000' icon={faTicket} handleFunction={()=>setEditeNfe(true)}/>
                    <Space left={5}/>
                    <GetButton responsive={true} title={'BUSCAR NO SERVIDOR'} 
                    color='#007bff' icon={faDownload} handleFunction={handleFindDataBase}/>
                  </div>
               :null}
                </>
            }
            path={'São Todas as Peças do Estoque Bruna Semijoias...'} icon={faDatabase}/>
          </div>
          <div className={style.buildList}>
            <div className={style.filter}>
                <div className={style.inputFilter}>
                  <InputModels iconButton={faMagnifyingGlass} 
                  type={"text"} placeholder={"Buscar Peças..."}
                  onChange={handleChangeFilter}/>
                </div>
                <div className={style.containerDropDown}>
                  <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                      <option value="BJ">BRUNA SEMIJOIAS</option>
                      <option value="AB">AB</option>
                      <option value="BB">BB</option>
                      <option value="MB">MB</option>
                      <option value="GB">GB</option>
                      <option value="BP">BP</option>
                      <option value="CE">CE</option>
                      <option value="BT">BT</option>
                      <option value="MEU ESTOQUE">MEU ESTOQUE</option>
                      <option value="SEM ESTOQUE">SEM ESTOQUE</option>          
                  </select>
                  <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                      <option value="asc">CRESCENTE</option>
                      <option value="desc">DESCRESCENTE</option>
                  </select>
                </div>
            </div>
            <Space right={2} left={2} top={5} bottom={0}/> 
            {(filtered.length > 0 && !loading ) ?<div className={style.buildList}>
              <GetAmountList amount={filtered.length} title={"Peças Totais Encontradas na Busca..."}/>
              <div className={style.labelData}>
                <h5>Quantidade</h5>
                <h5>Código</h5>
                <h5>Código de Barras</h5>
                <h5>Fator</h5>
                <h5>Valor de Venda</h5>
                <h5>Valor de Custo</h5>
              </div>
              <Space right={0} left={0} top={0} bottom={10}/> 
            </div> : null}
            <div ref={containerRef} className={style.containerList} onScroll={checkScroll}>
                  <div className={style.loaderCenter}>
                    <Loader titleFinding={'Buscando no Servidor!'} 
                         subtitleFinding={'Aguarde...'} loading={loading}/>
                  </div>
                <div>
                  {(filtered.length === 0 && !loading)?
                  <GetSmallModels title={"Não há Peças com Esse Filtro"}
                  subtitle={"Tente Trocar o Filtro..."}
                  icon={faCircleInfo} />:
                    filtered.slice(0, limit).map((data, index) => (
                        <div key={index}>
                          <ItemModel data={data} 
                          fucntionButton={()=>handleSelectItem(data)} 
                          functionImage={()=>toggleImageExpansion(data.itemGlobal.name,data.getImage())} />
                        </div>
                    ))
                  }
                </div>
            </div>
          </div> 
        {editeNFE?<GetEditeNfeBj onReload={()=>findBjSettingsTax()} idBrand={0} isBj={1} nfeSettings={brunaSemijoiasNFE} onClose={()=>setEditeNfe(false)}/>:null}
        {showAlertSave? <Alert top={95} right={25}  message={'Peça Atualizada com Sucesso'} onClose={() => setShowAlertSave(false)} type={"success"} />:null}
        {showEditeItem? <ViewItemBj handleRefresh={handleSave} itemBj={itemBjSelected} handleClose={()=>setShowEditeItem(false)}/> : null}
        {isExpanded? <GetExpandedImage nameProduct={nameProduct} linkImage={linkImage} onClose={toggleImageExpansion}/>:null}
        <GetFloatingButton handleFunction={()=>navigate(routerFromStockRepositionBJ)} icon={faTruckMoving} color='#007bff' width={window.innerWidth <= 1200 ? 65:85} margiBottom={'1%'}  height={window.innerWidth <= 1200 ? 65:85} margiRight={'1%'}/>
      </div>
   </div>
  )
}

export default ListStock