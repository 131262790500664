import DatabaseSettings from "../DatabaseSettings"

export class Users{
    constructor(){
        this.idUsers = -1
        this.emailLogin = ''
        this.databaseName = ''
        this.name =''
        this.number =''
        this.isAccess = false
        this.databaseSettings = new DatabaseSettings()
    }

    fromUpdate(data,status,toggleBrunaSemijoias){
        this.emailLogin = data.emailLogin
        this.databaseName = data.databaseName
        this.name = data.name
        this.isAccess = status
        this.number = data.number
        this.databaseSettings.fromUpdate(data)
        this.databaseSettings.isCanUseBrunaBJ = toggleBrunaSemijoias
    }

    checkNeedPay(){
        const today = new Date()
        const startService = new Date( this.databaseSettings.dayStartService)
    
        let differenceInMonths = (today.getFullYear() - startService.getFullYear()) * 12 + (today.getMonth() - startService.getMonth());
    
        if (differenceInMonths > 1) {
            return true
        } else if (differenceInMonths === 1) {
            if (today.getDate() > startService.getDate() + 1) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    fromCloud(data){
        this.idUsers = data.idUsers
        this.emailLogin = data.emailLogin
        this.databaseName = data.databaseName
        this.name = data.name
  
        this.isAccess = data.isAccess === 1 ? true : false
        this.number = data.number
        this.databaseSettings.fromCloud(data)
    }

    getMapFromCloud(){
        return{
            'idUsers':this.idUsers,
            'emailLogin': this.emailLogin,
            'databaseName': this.databaseName,
            'name': this.name,
            'isAccess': this.isAccess,
            'number': this.number,
            'dataBaseSettings':this.databaseSettings.getMapFromCloud()
        }
    }
}