import React, { useState } from "react"
import style from "../../../style/SaleModel.module.css"
import { faBugSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function RepairsModel({repair,onClick}){
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    
    const handleImageLoad = () => {
        setImageLoaded(true); 
    }

    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }

    let name = repair.status === 0? 'EM CONSERTO':'ENTREGUE'
    name = repair.status === 2 ? 'CANCELADO': name

    let isSpecial = repair.idEmployeeCancel !== null
    return(
        <div className={style.containerWrap}>
            <div className={style.containerWrap1}>
                {isSpecial? 
                <div className={style.icon}>
                    <FontAwesomeIcon icon={faBugSlash} />
                </div>
                :<div className={style.imageContainer}>
                    {errorOccurred ? (
                                <img
                                src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                                alt="Erro de carregamento"
                                className={style.loaded}
                                />
                            ) : (
                            <img src={repair.tempEmployee.linkImage} alt={repair.tempEmployee.genericPerson.name} onLoad={handleImageLoad}
                                onError={handleImageError} className={!imageLoaded ? style.loading : style.loaded}/> )}
                              
                    </div> 
                }
                <button className={style.container} onClick={onClick}>
                    <div className={style.containerNames}>
                            <h5>{repair.title}</h5>
                            <h4>{repair.date}</h4>
                            <h3>{name}</h3>
                            <h4>{repair.getValueToString()}</h4>
                    </div>
                </button>
            </div>
        </div>
    )
 
}

export default RepairsModel