import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import style from "../../../style/StockCharts.module.css"
import { makeAccounting } from '../../../objects/Accounting/MakeAccounting';

const getStyle = () =>{
  return  {
      backgroundColor: 'rgba(28,28,28,1)',
      padding:10,
      borderRadius:5,
      border: '1px solid rgba(255, 255, 255, 0.5)',
      width: '100%' , height: 100
  }
}


const ChartsCompareReceiversData = ({ labels, data, title }) => {
    const canvasRef = useRef(null);
    const chartRef = useRef(null);
  
    const colors = [
        'rgb(16, 189, 5,0.5)', 
        'rgb(189, 180, 5,0.5)',   
        'rgb(255, 0, 0,0.5)',   
    ];

    useEffect(() => {
      if (canvasRef.current) {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
  
        const ctx = canvasRef.current.getContext('2d');
        chartRef.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [{
              label: '',
              data: data,
              backgroundColor: colors,
              borderColor: colors.map(color => color.replace('0.2', '1')),
              borderWidth: 1
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                    color: 'white' 
                },
                grid: {
                    display: false
                }
              },
              x: {
                ticks: {
                  color: 'white' 
                },
                grid: {
                    display: false
                }
              }
            },
            elements: {
                bar: {
                  shadow: {
                    color: 'rgba(0, 0, 0, 0.5)',
                    blur: 10,
                    offsetX: 5,
                    offsetY: 5,
                    opacity: 0.5
                  }
                }
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return 'Valor: ' + context.parsed.y;
                    }
                  }
                },
                legend: {
                  display: false,
                
                  labels: {
                    font: {
                        position: 'left',
                        align: 'start', 
                        size: 16,
                    },
                    color: 'rgba(255, 255, 255, 0.5)',
                  }
                }
              },animation: {
                duration: 2000,
                easing: 'easeInOutQuart'
              },title: {
                display: true,
                text: title, // Usamos o título passado como propriedade
                font:{
                  align: 'start', 
                  size: 18,
                },
                color: 'white',
              }
          }
        });
      }
  
      return () => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
      };
    }, [labels, data, title]);
  
    return (
      <div className={style.divChart}>
        <p>{title}</p>
        <canvas ref={canvasRef} style={getStyle()}></canvas>
      </div>
    );
}

export const ChartsComparePerformace = () => {
    const sales = {
      labels: ['Vendas','Brindes','Vendas Canceladas'],
      data: [
            makeAccounting.accountingDataDetails.allValue,
            makeAccounting.accountingDataSales.itemsPresent.valueMoney,
            makeAccounting.accountingDataSales.itemsCancelAllSales.valueMoney
      ],
      title: 'Tipos de Vendas'
    }
  
    const items = {
      labels: ['Produtos Vendidos','Produtos Brindes','Produtos Cancelados'],
      data: [
        makeAccounting.accountingDataSales.allItems,
        makeAccounting.accountingDataSales.itemsPresent.amountItems,
        makeAccounting.accountingDataSales.itemsCancelAllSales.amountItems
      ],
      title: 'Peças e Produtos em Relação aos Tipos de Vendas'
    }
  
    const amountSales = {
        labels: ['Venda Normais','Brindes','Vendas Canceladas'],
        data: [
          makeAccounting.accountingDataSales.allSales,
          makeAccounting.accountingDataSales.itemsPresent.totalSaleCount,
          makeAccounting.accountingDataSales.itemsCancelAllSales.totalSaleCount
        ],
        title: 'Quantidade Númerica de Vendas'
      }
  
    return (
      <div className={style.divInCollum}>
        <div className={style.div}>
          <ChartsCompareReceiversData {...sales} />
          <ChartsCompareReceiversData {...items} />
          <ChartsCompareReceiversData {...amountSales} />
        </div>
      </div>
    );
  }

export const ChartsCompareReceivers = () => {
  const futureReceivers = {
    labels: ['Total em Reais'],
    data: [
        makeAccounting.accountingControllerAllSalesReceiver.valueAllReceiverInFuture
    ],
    title: 'Recebimentos Futuros'
  }

  const receivers = {
    labels: ['Total em Reais'],
    data: [
        makeAccounting.accountingAllReceiversThisMoth.valueTotalPaid
    ],
    title: 'Recebimentos No Período'
  }

  const comparation = {
    labels: ['Recebidos','Futuro Recebimentos'],
    data: [
        makeAccounting.accountingAllReceiversThisMoth.valueTotalPaid,
        makeAccounting.accountingControllerAllSalesReceiver.valueAllReceiverInFuture
    ],
    title: ['Recebidos em Relação aos Futuro Recebimentos',]
  }

  return (
    <div className={style.divInCollum}>
      <div className={style.div}>
        <ChartsCompareReceiversData {...receivers} />
        <ChartsCompareReceiversData {...comparation} />
        <ChartsCompareReceiversData {...futureReceivers} />
      </div>
    </div>
  );
}
