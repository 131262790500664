import React, { useState, useEffect, useRef } from 'react';
import style from "../../style/ListClients.module.css"
import { GetSmallModels } from '../../Models/GetModels';
import { faMagnifyingGlass,faCircleInfo, faDownload, faList, faEdit} from '@fortawesome/free-solid-svg-icons';
import Loader from '../../layout/Loader';
import InputModels from "../../Models/InputModels.js"
import GetAmountList from '../../Models/GetAmountList.js';
import Space from '../../layout/Space.js';
import { GetButton } from '../../layout/ButtonsLayout.js';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage.js';
import { useError, useLoading } from '../../AppContext.js';
import { listClientsMemory, listEmployeeMemory, listItemsMemory, listProductsMemory, updateIfNull } from '../../backEnd/Memory.js';
import GetNFeConfigureCompany from './GetNFeConfigureCompany.js';
import { getNfeEmited } from '../../controllers/CloudNfeEmited.js';
import { ModelNfeEmited, ModelNfeEmitedHelperName } from './ModelNfeEmited.js';
import ViewSaleController from '../Sale/ShowSale/ViewSaleController.js';
import { getSaleWithID, setEditeSale } from '../../controllers/CloudSales.js';

function ListNfe() {

  const containerRef = useRef(null)
  const [showEditeNfe,setShowEditeNfe] = useState(false)
  const [showViewSale, setShowViewSale] = useState(false)
  const [saleSelected, setSaleSelected] = useState(null)
  const [limit, setLimit] = useState([50])

  const [filtered, setFiltered] = useState([])
  const [filter, setFilter] = useState('all')
  const [orderBy, setOrderBy] = useState('asc')
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const { setIsLoading } = useLoading()

  const { showError } = useError()

  useEffect(() => {
    async function fetchData() {
        setLoading(true)
        setIsLoading(true)
        setList([])
        await updateIfNull(showError)   
        handleFindDataBase()
        setIsLoading(false) 
    }
    fetchData()
  }, [])

  const handleFindDataBase = async () => {
    setLoading(true)
    setList([])
    const value = await getNfeEmited(showError)
    setList(value)
    setLoading(false)
  }

  useEffect(() => {
    handleChangeFilter()
  }, [filter, orderBy,list])

  const findSaleFromID = async (idSale) =>{
    setIsLoading(true)
    setSaleSelected(await getSaleWithID(showError,idSale,listProductsMemory,listItemsMemory,listEmployeeMemory,listClientsMemory))
    setShowViewSale(true)
    setIsLoading(false)
  }

  const controllerFilter  = () => {
    let data = []
    switch (filter) {
      case 'all':
        data = list
        break
      case 'authorized':
        data = list.filter(item => item.cstat === 100)
        break
      case 'canceled':
        data = list.filter(item => item.cstat === 101)
        break
      case 'denied':
        data = list.filter(item => item.cstat === 110)
        break
      case 'rejected':
        data = list.filter(item => item.cstat === 204)
        break
      case 'contingency':
        data = list.filter(item => item.cstat === 302)
        break
      case 'wearOut':
        data = list.filter(item => item.cstat === 150)
        break
      case 'processing':
        data = list.filter(item => item.cstat === 105)
        break
      default:
        data = list
        break
    }
    return data
  }

  const handleFilterChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }
  
  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()

    resetScroll()
    if(value!= null){
      const filtered = data.filter(
        data => {
          const dataEmite = data.dataEmite.toLowerCase().includes(value.target.value.toLowerCase())
          const chNFe= data.chNFe.toLowerCase().includes(value.target.value.toLowerCase())
          const dateCanceled = data.dateCanceled.toLowerCase().includes(value.target.value.toLowerCase())
          const cstat= data.cstat.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const tpAmb= data.tpAmb.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const mod= data.mod.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const clientTempcpf = data.clientTemp._genericPerson.cpf.toLowerCase().includes(value.target.value.toLowerCase())
          const clientTemp = data.clientTemp._genericPerson.name.toLowerCase().includes(value.target.value.toLowerCase())
          return dataEmite || chNFe || dateCanceled  || cstat || tpAmb  || mod || clientTempcpf || clientTemp
        }
      )
      setFiltered(filtered)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    container.scrollTop = 0
    setLimit(50)
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  const title = 'Lista de NFe Cadastradas'
  const Subtitle = 'São Todas as NFe Cadastradas no Sistema...'
  const butFindName = 'BUSCAR NO SERVIDOR';

  return (
    <div className={style.containerGeneral}>
       <div className={style.containerGlobal}>  
           <div className={style.titleMenu}>     
             <TitlePageResponsiveIcon subtitle={title} 
             path={Subtitle} 
             children={
              <>{!loading?
                <div className={style.divButton}>
                  <GetButton height={50} responsive={true} title={butFindName} 
                    color='#007bff' icon={faDownload} handleFunction={handleFindDataBase}/>
                  <Space left={5}/>
                  <GetButton
                    responsive={true}
                    height={50}
                    title={'CONFIGURAR EMISSOR'}
                    handleFunction={()=>setShowEditeNfe(true)}
                    icon={faEdit} 
                    color={"#ff0000"}  />  
                </div>:null}
              </>
             }
             icon={faList}/>
             <Space right={2} left={2} top={2} bottom={2}/> 
            </div>
           <div className={style.buildList}>
             <div className={style.filter}>
                 <div className={style.inputFilter}>
                   <InputModels iconButton={faMagnifyingGlass} type={"text"} placeholder={"Buscar NFe..."}
                   onChange={handleChangeFilter}/>
                 </div>
                 <div className={style.containerDropDown}>
                  <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                    <option value="all">TODOS</option>
                    <option value="authorized">AUTORIZADA</option>
                    <option value="canceled">CANCELADA</option>
                    <option value="denied">DENEGADA</option>
                    <option value="rejected">REJEITADA</option>
                    <option value="contingency">CONTINGÊNCIA</option>
                    <option value="wearOut">INUTILIZADA</option>
                    <option value="processing">EMITIDA</option>
                  </select>
                   <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                       <option value="asc">CRESCENTE</option>
                       <option value="desc">DESCRESCENTE</option>
                   </select>
                 </div>
             </div>
             <Space right={2} left={2} top={5} bottom={0}/> 
             {(filtered.length > 0 && !loading ) ?<div className={style.buildList}>
                <GetAmountList amount={filtered.length} title={"NFe/NFC-e Totais Encontradas na Busca..."}/>
                {window.innerWidth> 1200? <ModelNfeEmitedHelperName/>:null}
             </div> : null}
             <div ref={containerRef} className={style.containerList} onScroll={checkScroll}>
                  <div className={style.loaderCenter}>
                    <Loader titleFinding={'Buscando NFe no Servidor!'} 
                         subtitleFinding={'Aguarde...'} loading={loading}/>
                  </div>
                 <div>
                   {(filtered.length === 0 && !loading)?
                   <GetSmallModels title={"Não há NFe com Esse Filtro"}
                   subtitle={"Tente Trocar o Filtro..."}
                   icon={faCircleInfo} />:
                     filtered.slice(0, limit).map((nfe, index) => (
                         <div key={index}>
                          <Space top={5}/>
                          <ModelNfeEmited actionOpenSale={(e)=>findSaleFromID(e)} viewSale={true} nfeEmited={nfe} />
                         </div>
                     ))
                   }
                 </div>
             </div>
           </div>
          {showViewSale ? <ViewSaleController handleSave={()=>{}} saleStart={saleSelected} handleClose={()=> setShowViewSale(false)}/> : null}
          {showEditeNfe? <GetNFeConfigureCompany onClose={()=>setShowEditeNfe(false)}/>: null}
       </div>
    </div>
  )

}

export default ListNfe