import ItemsSale from "../ItemBJ/ItemsSale"
import VersionProduct from "../VersionProduct"

class ListOfItems{
    constructor(){
        this.totalMoney = 0
        this.totalModels= 0
        this.totalItems = 0
        this.totalGrams = 0
        this.listItems = []

    }

    fromNewSale(auxSale,codesale){
        this.listItems = [...auxSale.listItems]
        this.calculate(auxSale.factor)
        this.addLastVersionNewSale(codesale)
    }

    getTotalMoneyToString(){
        return this.totalMoney.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getTotalGrams(){
        return parseFloat(this.totalGrams).toFixed(2) + " g"  
      }
  
    getTotalInItems(){
        return this.totalItems + " Un" 
    }
  
    getTotalInModels(){
        return this.totalModels + " Modelos" 
    }


    fromMap(mapTemp){
        this.listItems = [...mapTemp]
    }

    fromEdite(other, factor){
        this.listItems = [...other.listItems]
        this.calculate(factor)
    }

    addLastVersionCancel(codeSale){
        for (var element of this.listItems) {
            var version = new VersionProduct()
            version.configureNewVersion("Venda "+ codeSale + " foi Cancelada, Item Devolvido para o Estoque")
            element.versionProduct = version
        }
    }

    addLastVersionEditeSale(codeSale){
        for (var element of this.listItems) {
            var version = new VersionProduct()
            version.configureNewVersion("Venda " + codeSale + " foi Editada, Item Devolvido para o Estoque")
            element.versionProduct = version
        }
    }

    addLastVersionNewSale(codeSale){
        for (var element of this.listItems) {
            var version = new VersionProduct()
            version.configureNewVersion("Esse Item foi Vendido para: "+codeSale)
            element.versionProduct = version
        }
    }

    fromCloud(data,listItemFromCloud){
        this.totalItems = data.totalItemsNormal
        this.totalGrams = parseFloat(data.totalFactorsItemsNormal)
        this.totalMoney = parseFloat(data.totalValueOnlyNormal)

        var list = [...data.listOfItems]
        for (var element of list) {
            let currentElement = element
            var foundIndex = listItemFromCloud.findIndex(ss => ss.idItem === currentElement.idItem)
            if(foundIndex !== -1){
                var newItemSale = new ItemsSale()
                newItemSale.fromSale(element,listItemFromCloud[foundIndex].itemGlobal)
                this.listItems.push(newItemSale)
            }
           
        }

        this.totalModels = list.length
       
    }
    
    getMapFromCloud(){

        var list= {}
        var count = 1
        for (var element of [...this.listItems]) {
            list[count.toString()] = element.getMapFromCloud()
            count++
        }
        
        return{
            'totalItemsNormal':this.totalItems,
            'totalFactorsItemsNormal': this.totalGrams,
            'totalValueOnlyNormal':this.totalMoney,
            'container-listItems':list
        }
    }

    calculate(factorCompany){
        this.totalMoney = 0
        this.totalModels= 0
        this.totalItems = 0
        this.totalGrams = 0

        for (var element of this.listItems) {
            element.itemControllerSale.factorCompany = factorCompany
            element.updateValuesItems()
            this.totalModels++
            this.totalItems += element.amount
            this.totalGrams += element.getTotalGrams()
            this.totalMoney += element.getValueSaleTotal()
        }
    }

    clear(){
        this.totalMoney = 0
        this.totalModels= 0
        this.totalItems = 0
        this.totalGrams = 0
        this.listItems = []
    }

}

export default ListOfItems