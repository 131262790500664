export class StockAll{
    constructor(){
        this.brunaSemijoiasStock = new BrunaSemijoiasStock()
        this.genericProductStock = new GenericProductStock()
    }

    calculate(listStockBruna, listStockGenerics,listBrands){
        this.brunaSemijoiasStock.calculate(listStockBruna)
        this.genericProductStock.calculate(listStockGenerics,listBrands)
    }

    getCoastGlobal(){
        return this.brunaSemijoiasStock.coasts + this.genericProductStock.coasts
    }

    getSaleValueGlobal(){
        return this.brunaSemijoiasStock.saleValue + this.genericProductStock.saleValue
    }
}

class GenericProductStock{
    constructor(){
        this.brands = 0
        this.models = 0
        this.items = 0
        this.coasts = 0 
        this.saleValue = 0
    }

    calculate(listStock,amountBrands){
        this.clear()
        this.brands = amountBrands
        listStock.forEach(element => {
            if(element.amount > 0){
                this.models ++
                this.items += element.amount
                this.coasts += element.getCoastValueTotal()
                this.saleValue += element.getSaleValueTotal()
            }
        });
    }

    clear(){
        this.brands = 0
        this.models = 0
        this.items = 0
        this.coasts = 0 
        this.saleValue = 0
    }
}

class BrunaSemijoiasStock{
    constructor(){
        this.grams = 0
        this.models = 0
        this.items = 0
        this.coasts = 0 
        this.saleValue = 0
    }
    
    calculate(listStock){
        this.clear()
        listStock.forEach(element => {
            if(element.amount > 0){
                var grams = element.amount * element.itemGlobal.factorItem
                this.grams += grams
                this.models ++
                this.items += element.amount
                this.coasts += element.getValueCoastTotal()
                this.saleValue += element.getValueSaleTotal()
            }
        })
    }

    clear(){
        this.grams = 0
        this.models = 0
        this.items = 0
        this.coasts = 0 
        this.saleValue = 0
    }
}