
import { useShowPDF } from "../AppContext";
import { sendRequestGetLocal, sendRequestPostLocal } from "../backEnd/requesition";
import Extract from "../objects/Extract";

/*const _routerSaveBalances = '/upload/pdf/balances';*/
const _routerSaveRepositions = '/upload/pdf/repositions';
const _routerGetRepositions = '/upload/pdf/getRepositions';
/*const _routerGetBalances = '/upload/pdf/getBalances';*/
const _routerFromPDFS = '/pdfGenerator'

export const setNewPdfSaveReposition = async (bytes) =>{
    /*try {
        const formData = new FormData();
        formData.append('pdf', new Blob([bytes], { type: 'application/pdf' }), 'pdf');
        var results = await sendRequestPostLocal(_routerSaveRepositions,formData)
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {   
    }*/
}

export const getListExtracts = async (showError) => {
    var results = []
    const extractList = []
    try {
        results = await sendRequestGetLocal(_routerGetRepositions)
        
        if(Array.isArray(results)){
            Object.values(results).forEach(data =>{   
                var extractTemp = new Extract()
                extractTemp.fromCloud(data)
                extractList.push(extractTemp)
            })
        }else throw new Error(results.response.data.sqlMessage) 

    } catch (error) {
        showError('ERROR AO BUSCAR LISTA DE EXTRATOS',error.message)
    }finally{
        return extractList
    }
}

export const requestPDFs = async (body,showError) =>{
   
    try {
        let results = await sendRequestPostLocal(_routerFromPDFS,body) 
        const uint8Array = new Uint8Array(results.data.response.data)
        const blob = new Blob([uint8Array], { type: 'application/pdf' })
        const url = URL.createObjectURL(blob)


        const isMobile = /iPhone|iPad|iPod|Android|Windows Phone/.test(navigator.userAgent);

        if (isMobile) {
            /*if(!window.open(url,'_blank')){
                const link = document.createElement('a')
                link.href = url;
                link.download = 'PDF-BSemijoias'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }*/
            const link = document.createElement('a')
                link.href = url;
                link.download = 'PDF-BSemijoias'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
        } else {
            if(!window.open(url)){
                window.open(url,'_blank')
            }
        }

        return uint8Array
        
    } catch (error) {
        showError('ERROR AO GERAR PDF NO SERVIDOR',error.message)
    }
}

