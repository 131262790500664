import React, { useState } from 'react';
import style from "../../../style/GetNewBankPopop.module.css"
import { TitlePageResponsiveIcon } from '../../../layout/TitlePage';
import { faCancel, faClose, faSave, faSignature } from '@fortawesome/free-solid-svg-icons';
import InputModelsBlock from '../../../layout/InputModelsBlock';
import Space from '../../../layout/Space';
import { GetButton, GetButtonIcon } from '../../../layout/ButtonsLayout';
import Alert from '../../../Models/Alert';
import ConfirmationPopup from '../../../Models/ConfirmationPopup';
import GetEmployee from '../../../Models/GetEmployee';
import { employeeData } from '../../../backEnd/InitController';

const getFormData = () => {
    return {
        reasonCancel:''
    }
}

const getFormNeed = () => {
    return {
        reasonCancel:false
    }
}

export function GetCancelRepairs({onClose,onCancel}){
    const [isVisible,setAlert] = useState(false)
    const [confirmationVisible, setConfirmationVisible] = useState(false)

    const [formData, setFormData] = useState(getFormData())
    const [needData, setNeedData] = useState(getFormNeed())

    const handleSubmitSave= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setAlert(true)
            return
        }
      
        setConfirmationVisible(true)
    }

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleCancel = ()=>{
        onClose()
        onCancel(formData.reasonCancel)
    }

    return (
        <div className={style.overlay}>
          <div className={style.alert}>
                  <TitlePageResponsiveIcon subtitle={'CANCELAR CONSERTO/REPARO'} 
                          children={
                            <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={onClose}/>   
                          }
                          path={"Complete os Campos Abaixo..."} icon={faCancel}/>      
                  <Space top={5}/>
                  <GetEmployee employee={employeeData()}/>
                  <Space top={5}/>
                  <div className={style.div}>
                          <InputModelsBlock onChange={handleChange} 
                          legend={'MOTIVO DO CANCELAMENTO'}
                          validation={needData.reasonCancel} 
                          maxLength={200} 
                          value={formData.reasonCancel} 
                          name={'reasonCancel'} 
                          iconButton={faSignature}
                          type={"text"} 
                          placeholder={"Qual é o Motivo do Cancelamento..."}/>  
                  </div>            
                  <Space top={5}/>
                  <div className={style.div}>
                      <GetButton title={'CANCELAR CONSERTO AGORA'} color='#007bff' icon={faSave} handleFunction={handleSubmitSave}/> 
                  </div>     
             {confirmationVisible? 
             <ConfirmationPopup title={'CANCELAR CONSERTO/REPARO'} 
              message={'TEM CERTEZA QUE DESEJA CANCELAR ESSE CONSERTO/REPARO'} onCancel={()=>setConfirmationVisible(false)} 
              onConfirm={handleCancel}/>: 
              null}
             {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
          </div>
        </div>
      )
}