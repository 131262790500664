import React, { useState, useEffect, useRef } from 'react';
import style from "../../style/ListClients.module.css"
import { GetSmallModels } from '../../Models/GetModels';
import { faMagnifyingGlass,faCircleInfo, faPlus, faDownload, faList, faFilePdf} from '@fortawesome/free-solid-svg-icons';
import Loader from '../../layout/Loader';
import ClientsModels from '../../Models/ClientsModels';
import InputModels from "../../Models/InputModels.js"
import GetAmountList from '../../Models/GetAmountList.js';
import GetFloatingButton from '../../layout/GetFloatingButton.js';
import Space from '../../layout/Space.js';
import { GetButton, GetButtonIcon } from '../../layout/ButtonsLayout.js';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage.js';
import { routerFromaddClients, routerFromClient } from '../../routers/routerList.js';
import { useNavigate } from 'react-router-dom';
import { useError, useLoading } from '../../AppContext.js';
import { findClients, listClientsMemory, myStoreDataMemory, updateIfNull } from '../../backEnd/Memory.js';
import { requestPDFs } from '../../controllers/CloudPdfs.js';

function ListClients() {
  const navigate = useNavigate()
  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])
  const { setIsLoading } = useLoading()
  const [filtered, setFiltered] = useState([])
  const [filter, setFilter] = useState('all')
  const [orderBy, setOrderBy] = useState('asc')
  const [list, setListClients] = useState(listClientsMemory)
  const [loading, setLoading] = useState(false)

  const { showError } = useError()

  useEffect(() => {
    async function fetchData() {
        setLoading(true)
        setListClients([])
        await updateIfNull(showError)   
        handlerUpdate() 
        setLoading(false) 
    }
    fetchData()
  }, [])

  const handlerUpdate = () => {
    setListClients(listClientsMemory)
    setFilter('all')
    setOrderBy('asc')
  }

  const handleFindDataBase = async () => {
    setLoading(true)
    setListClients([])
    await findClients(showError)
    handlerUpdate()
    setLoading(false)
  }

  useEffect(() => {
    handleChangeFilter()
  }, [filter, orderBy,list])

  const controllerFilter = () => {
    let data = []
    switch (filter) {
      case 'all':
        data = list
        break
      case 'isOpen':
        data = list.filter(item => item.isOpenSales)
        break
      case 'IsNotOpen':
        data = list.filter(item => !item.isOpenSales)
        break
      case 'cpf':
        data = list.filter(item => item._genericPerson.cpf)
        break
      case 'rg':
        data = list.filter(item => item._genericPerson.rg)
        break
      case 'city':
        data = list.filter(item => item._clientAddress._city)
        break
      case 'state':
        data = list.filter(item => item._clientAddress._state)
        break
      case 'cnpj':
        data = list.filter(item => item._clientCompany._cnpj)
        break
      default:
        data = list
        break
    }

    let sortedClients = [...data]
    switch (orderBy) {
      case 'asc':
        sortedClients.sort((a, b) => a._genericPerson.name.localeCompare(b._genericPerson.name))
        break
      case 'desc':
        sortedClients.sort((a, b) => b._genericPerson.name.localeCompare(a._genericPerson.name))
        break
      default:
        break
    }
    return sortedClients;
  }

  const handleFilterChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }
  
  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()

    resetScroll()
    if(value!= null){
      const filteredClients = data.filter(
        client => {
          const filtercpf = client._genericPerson.cpf.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterName= client._genericPerson.name.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterRg = client._genericPerson.rg.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterCat= client.statusClient.toString().toLowerCase().includes(value.target.value.toLowerCase())
          return filtercpf || filterName || filterRg  || filterCat 
        }
      )
      setFiltered(filteredClients)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    container.scrollTop = 0
    setLimit(50)
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  const handleAddClient = async () => {
    navigate(routerFromaddClients)
  }

  const handleOpenPdf = async()=>{
    setIsLoading(true)

    let map = {}
    filtered.forEach(ss=>{
      map[ss.IdClient.toString()] = ss.getMapFromCloud()
    })

    await requestPDFs({
      'type':'pdfListClients',
      'listClints' : map,
      'myStore': myStoreDataMemory.getMapFromCloud()
    },showError)
    setIsLoading(false)
}

  const buttonScale = window.innerWidth > 1200 ? 50 : 35
  const title = 'Lista Completa com Todas as Clientes Cadastradas'
  const Subtitle = 'São Todas as Cliente Cadastradas no Sistema...'
  const butFindName = 'BUSCAR NO SERVIDOR';
  return (
    <div className={style.containerGeneral}>
       <div className={style.containerGlobal}>  
           <div className={style.titleMenu}>     
             <TitlePageResponsiveIcon subtitle={title} 
             path={Subtitle} 
             children={
              <>{!loading?
                <div className={style.divButton}>
                  <GetButton height={50} responsive={true} title={butFindName} 
                    color='#007bff' icon={faDownload} handleFunction={handleFindDataBase}/>
                  <Space left={5}/>
                  <GetButtonIcon 
                    width={buttonScale}
                    height={buttonScale}
                    handleFunction={handleOpenPdf}
                    icon={faFilePdf} 
                    color={"#ff0000"}  />  
                </div>:null}
              </>
             }
             icon={faList}/>
             <Space right={2} left={2} top={2} bottom={2}/> 
            </div>
           <div className={style.buildList}>
             <div className={style.filter}>
                 <div className={style.inputFilter}>
                   <InputModels iconButton={faMagnifyingGlass} type={"text"} placeholder={"Buscar Cliente..."}
                   onChange={handleChangeFilter}/>
                 </div>
                 <div className={style.containerDropDown}>
                  <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                    <option value="all">TODOS</option>
                    <option value="isOpen">DEVENDO</option>
                    <option value="IsNotOpen">SEM DÍVIDAS</option>
                    <option value="cpf">CPF</option>
                    <option value="rg">RG</option>
                    <option value="city">CIDADE</option>
                    <option value="state">ESTADO</option>
                    <option value="cnpj">CNPJ</option>
                  </select>
                   <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                       <option value="asc">CRESCENTE</option>
                       <option value="desc">DESCRESCENTE</option>
                   </select>
                 </div>
             </div>
             <Space right={2} left={2} top={5} bottom={0}/> 
             {(filtered.length > 0 && !loading ) ?<div className={style.buildList}>
                <GetAmountList amount={filtered.length} title={"Clientes Totais Encontradas na Busca..."}/>
                <div className={style.labelData}>
                  <h5>Nome</h5>
                  <h5>CPF</h5>
                  <h5>RG</h5>
                  <h5>Cidade</h5>
                  <h5>Categoria</h5>
                  <h5>Status</h5>
                </div>
             </div> : null}
             <div ref={containerRef} className={style.containerList} onScroll={checkScroll}>
                  <div className={style.loaderCenter}>
                    <Loader titleFinding={'Buscando Clientes no Servidor!'} 
                         subtitleFinding={'Aguarde...'} loading={loading}/>
                  </div>
                 <div>
                   {(filtered.length === 0 && !loading)?
                   <GetSmallModels title={"Não há Clientes com Esse Filtro"}
                   subtitle={"Tente Trocar o Filtro..."}
                   icon={faCircleInfo} />:
                     filtered.slice(0, limit).map((clients, index) => (
                         <div key={index}>
                          <ClientsModels data={clients} fucntionButton={
                            ()=> {navigate(routerFromClient,{ state: {client:clients}})}
                          } />
                         </div>
                     ))
                   }
                 </div>
             </div>
           </div>
         <GetFloatingButton icon={faPlus} color='#007bff' width={window.innerWidth <= 1200 ? 65:85} margiBottom={'1%'}  height={window.innerWidth <= 1200 ? 65:85} margiRight={'1%'} handleFunction={handleAddClient} />
       </div>
    </div>
   )

}

export default ListClients