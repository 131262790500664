import React,{useState} from "react";
import style from "../style/BrandsModel.module.css"
import Space from "../layout/Space";
function BrandsModel({data,fucntionButton,functionImage}) {
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    const [canShow] = useState(window.innerWidth > 1200)
    const handleImageLoad = () => {
        setImageLoaded(true); 
    }

    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }
 
    return(
        <div className={(data.isActiveBrand === true ? style.containerWrap : style.containerWrap + ' ' + style.inactive)}>
                <div className={style.imageContainer}>
                            {errorOccurred ? (
                                <img
                                src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                                alt="Erro de carregamento"
                                className={style.loaded}
                                />
                            ) : (
                            <img src={data.linkImageBrand} alt={data.nameBrand} onLoad={handleImageLoad}
                                onError={handleImageError} onClick={functionImage}
                                className={!imageLoaded ? style.loading : style.loaded}/> )}
                </div> 
                <Space left={25}/>
                <button className={style.container} onClick={fucntionButton}>
                    <div className={style.containerNames}>
                        <h3>{data.nameBrand}</h3>
                        {canShow? <h5>{data.descriptionBrand}</h5>: null}
                        <h4 className={(data.isActiveBrand === true ? style.status : style.status + ' ' + style.desable)}>{data.isActiveBrand === true? 'ATIVA':'DESATIVA'}</h4>
                    </div>
                </button>
        </div>
    )
}

export default BrandsModel