import { databaseSettings, myStoreDataMemory } from "../../backEnd/Memory"
import { ICMS } from "./ICMS"

class KeyAccess {
    constructor(data,cnpj) {
        const today = new Date()
        this.cUf = data.cUF
        this.aa = today.getFullYear().toString().slice(-2)
        this.mm = (today.getMonth() + 1).toString().padStart(2, '0')
        this.cnpj = cnpj
        try{
            this.mod = data.mod.slice(0,2)
        }catch(e){
            this.mod = data.mod
        }
        this.tpEmis = data.tpEmis.slice(0,1)
        this.serie =  data.serie
        this.nNf = String(data.nNF).padStart(9, '0')
        this.cNF = data.cNF
    }

    getKey() {
        return this.cUf + this.aa + this.mm + this.cnpj + this.mod + this.serie + this.nNf + this.tpEmis + this.cNF
    }

    getCDv() {
        const chave = this.getKey()
        let peso = 4;
        let digito = 0;
        
        for (let i = 0; i < chave.length; i++) {
            const n = parseInt(chave.charAt(i), 10);
            digito += n * peso;
            peso = (peso === 2) ? 9 : peso - 1
        }

        digito = (11 - (digito % 11)) % 11;
        return (digito > 9) ? 0 : digito
    }

    getChNFe() {
        return this.getKey().toString() + this.getCDv().toString()
    }
}

export class NFe {
    constructor(data) {
        this.idClient = -1
        this.idSale = -1
        this.emit = new Emit()
        this.emit.fromCloud(data)
        this.ide = new Ide(data)
        this.dest = new Dest()
        this.det = []
        this.ICMSTot = new ICMSTot()
        this.transp = new Transp()
        this.infAdic = new InfAdic()
        this.cobr = new Cobr()
        this.pag = new Pag()
    }

    fromFastEmitter(client,idSale,listDet,valuePaid){
        this.idClient = client.IdClient
        this.idSale = idSale
        this.det = listDet
        this.ide.fromFastEmitter()
        this.dest.fromConfigureClient(client)
        this.calculateTotal()
        this.pag.addPagamento('01',valuePaid,'Dinheiro')
    }

    calculateTotal(){
        this.ICMSTot.clear()   
        this.det.forEach(produto => {
            produto.ICMS.calculateICMS()
            produto.PIS.calculate()
            produto.COFINS.calculate()

            this.ICMSTot.vBC += validateNumber(produto.ICMS.vBC)
            this.ICMSTot.vICMS += validateNumber(produto.ICMS.vICMS)
            this.ICMSTot.vPIS += parseFloat(parseFloat(produto.PIS.vPIS).toFixed(2))
            this.ICMSTot.vCOFINS +=  parseFloat(parseFloat(produto.COFINS.vCOFINS).toFixed(2))
            this.ICMSTot.vProd +=  parseFloat(parseFloat(produto.prod.vProd).toFixed(2)) 
        })
  
        this.ICMSTot.calculateTotal()
    }

    getMapFromCloud(){
        return {
            emit: this.emit.getMapFromCloud(),
            ide: this.ide.getMapFromCloud(),
            dest: this.dest.getMapFromCloud(),
            det: this.det.map(produto => produto.getMapFromCloud()),
            icmstot: this.ICMSTot.getMapFromCloud(),
            transp: this.transp.getMapFromCloud(),
            infadic: this.infAdic.getMapFromCloud(),
            cobr: this.cobr.getMapFromCloud(),
            pag: this.pag.getMapFromCloud(),
            idClient :this.idClient,
            idSale : this.idSale
        }
    }
}

export function formatDateToDatetimeLocal(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Get timezone offset in minutes and convert it to hours and minutes
    const timezoneOffset = date.getTimezoneOffset();
    const offsetSign = timezoneOffset > 0 ? '-' : '+';
    const offsetHours = String(Math.abs(Math.floor(timezoneOffset / 60))).padStart(2, '0');
    const offsetMinutes = String(Math.abs(timezoneOffset % 60)).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
}

function generateCNF() {
    return Math.floor(10000000 + Math.random() * 90000000);
}

export class Ide {
    constructor(data) {

        this.cnpj = data.CNPJ
        this.cNF = generateCNF() 
        this.cUF = data.cUF //código da Unidade da Federação (UF)
        this.cMunFG = data.cMunFG //Código do Município de Ocorrência do Fato Gerador
        this.nNF = parseInt(data.nNF) + 1 //Número da NF-e
    
        
        const currentDateTime = new Date()
       
        this.mod = '65 - NFEc Venda Presencial' //Modelo do Documento Fiscal 
        this.natOp = 'Venda de Semijoias'
        this.serie = '102' //Número da série da NF-e
        this.dhEmi = formatDateToDatetimeLocal(currentDateTime) //Data e hora de emissão
        this.dhSaiEnt = formatDateToDatetimeLocal(currentDateTime)   //Data e hora de saída ou entrada
        this.tpNF = '1 - Saída' //Tipo de operação
        this.idDest = '1 - Operação interna' //Identificador de destino da operação
        this.tpImp = '4 - DANFE NFC-e' //Formato de impressão do DANFE
        this.tpEmis = '1 - Normal' //Tipo de emissão
        this.tpAmb = '1 - Produção' //Tipo de ambiente
        this.finNFe = '1 - Normal' //Finalidade da emissão
        this.indFinal = '1 - Sim' //Indicador de operação com consumidor final
        this.indPres = '1 - Operação presencial' //Indicador de presença do comprador
        this.procEmi = '0' // 0 - Emissão com aplicativo do contribuinte
        this.verProc = '1.0' //Versão do Processo de Emissão

        this.keyClass = new KeyAccess(this,this.cnpj)

        this.cDV = this.keyClass.getCDv()
        this.KeyAccess = this.keyClass.getKey()
        this.keyClassWithCDV = this.keyClass.getChNFe()
    }

    fromEdite(data){
        this.natOp = data.natOp
        this.serie = data.serie
        this.dhEmi = data.dhEmi
        this.dhSaiEnt = data.dhSaiEnt

        this.mod = data.mod
        this.tpNF = data.tpNF
        this.idDest= data.idDest
        this.tpImp= data.tpImp
        this.tpEmis=data.tpEmis
        this.tpAmb= data.tpAmb
        this.finNFe= data.finNFe
        this.indFinal= data.indFinal
        this.indPres= data.indPres
    }

    fromFastEmitter(){
        this.natOp = 'Venda de Semijoias Presencial'
    }

    getMapFromCloud(){

        const currentDateTime = new Date()
        this.dhEmi = formatDateToDatetimeLocal(currentDateTime) 
        this.dhSaiEnt = formatDateToDatetimeLocal(currentDateTime)  

        this.keyClass = new KeyAccess(this,this.cnpj)
        this.cDV = this.keyClass.getCDv()
        this.KeyAccess = this.keyClass.getKey()
        this.keyClassWithCDV = this.keyClass.getChNFe()

        return{
            KeyAccess: this.KeyAccess,
            keyClassWithCDV: this.keyClassWithCDV,
            cUF: this.cUF,
            cMunFG: this.cMunFG,
            nNF: this.nNF,
            cNF: this.cNF,
            cDV: this.cDV,
            mod: this.mod.slice(0,2),
            natOp: this.natOp,
            serie: this.serie,
            dhEmi: this.dhEmi,
            dhSaiEnt: this.dhSaiEnt,
            tpNF: this.tpNF.slice(0,1),
            idDest: this.idDest.slice(0,1),
            tpImp: this.tpImp.slice(0,1),
            tpEmis: this.tpEmis.slice(0,1),
            tpAmb: this.tpAmb.slice(0,1),
            finNFe: this.finNFe.slice(0,1),
            indFinal: this.indFinal.slice(0,1),
            indPres: this.indPres.slice(0,1),
            procEmi: this.procEmi,
            verProc: this.verProc
        }
    }
}

export class Emit {
    constructor() {
        this.idNFESettings = -1
        this.CNPJ = ''
        this.xNome = ''
        this.xFant = ''
        this.ender = new Ender() 
        this.IE = ''
        this.CRT = ''
        this.cUF= ''
        this.nNF= 0
        this.cMunFG= ''
        this.numberCsc = ''
        this.csc = ''
        this.nfeLote = ''
    }

    fromCloud(data){
        this.idNFESettings = data.idNFESettings
        this.CNPJ = data.CNPJ //CNPJ do emitente.
        this.xNome = data.xNome //Razão Social ou Nome do emitente.
        this.xFant = data.xFant // Nome fantasia.
        this.ender.fromCloud(data) //Endereço do emitente
        this.IE = data.IE //Inscrição Estadual.
        this.CRT = data.CRT  //Código de Regime Tributário.
        this.cUF= data.cUF
        this.nNF= data.nNF
        this.cMunFG= data.cMunFG
        this.numberCsc = data.numberCsc
        this.csc = data.csc
        this.nfeLote = data.nfeLote
    }

    fromCloudCopy(data){
        this.fromCloud(data)
        this.ender.fromCloud(data.ender) //Endereço do emitente
    }

    fromEdite(data){
        this.CNPJ = data.CNPJ //CNPJ do emitente.
        this.xNome = data.xNome //Razão Social ou Nome do emitente.
        this.xFant = data.xFant // Nome fantasia.
        this.ender.fromEditeEnder(data) //Endereço do emitente
        this.IE = data.IE //Inscrição Estadual.
        this.CRT = data.CRT  //Código de Regime Tributário.
        this.cUF= data.cUF
        this.cMunFG=data.cMun
        this.numberCsc = data.numberCsc
        this.csc = data.csc
    }

    getMapFromCloud(){
        return{
            idNFESettings:this.idNFESettings,
            cUF:this.cUF.slice(0,2),
            nNF:this.nNF,
            cMunFG:this.cMunFG,
            CNPJ: this.CNPJ,
            xNome: this.xNome,
            xFant: this.xFant,
            ender: this.ender.getMapFromCloud(),
            IE: this.IE,
            CRT: this.CRT.slice(0,1),
            csc: this.csc,
            numberCsc: this.numberCsc,
            nfeLote : this.nfeLote
        }
    }
}

export class Ender {
    constructor() {
        this.idNFEAddress = ''
        this.xLgr =  '' //Logradouro
        this.nro =  '' //Número
        this.xBairro = '' //Bairro
        this.cMun = '' //Código do Município.
        this.xMun = '' //Nome do Município.
        this.UF =  '' // Sigla da UF.
        this.CEP = '' //CEP
        this.cPais = '' //Código do País.
        this.xPais = '' //Nome do País.
        this.fone ='' //Telefone.
    }

    fromConfigureClient(client){
        this.xLgr =  client._clientAddress.state
        this.nro =  '' //Número
        this.xBairro =  client._clientAddress.address
        this.cMun = '' //Código do Município.
        this.xMun =  client._clientAddress.city
        this.UF =  ''
        this.CEP = client._clientAddress.cep
        this.cPais = '1058'
        this.xPais = 'BRASIL' //Nome do País.
        this.fone = client._contact.number1
    }
    fromCloud(data) {
        if(data.ender !== undefined) data = data.ender
        this.idNFEAddress =  data.idNFEAddress 
        this.xLgr =  data.xLgr
        this.nro =   data.nro
        this.xBairro = data.xBairro
        this.cMun = data.cMun
        this.xMun = data.xMun
        this.UF = data.UF
        this.CEP =  data.CEP
        this.cPais =  data.cPais
        this.xPais =  data.xPais 
        this.fone = data.fone
    }

    fromEditeEnder(data){
        this.xLgr = data.xLgr //Logradouro
        this.nro = data.nro //Número
        this.xBairro = data.xBairro //Bairro
        this.cMun = data.cMun //Código do Município.
        this.xMun = data.xMun //Nome do Município.
        this.UF = data.UF // Sigla da UF.
        this.CEP = data.CEP //CEP
        this.cPais = data.cPais //Código do País.
        this.xPais = data.xPais //Nome do País.
        this.fone = data.fone //Telefone.
    }

    getMapFromCloud(){
        return{
            idNFEAddress: this.idNFEAddress,
            xLgr: this.xLgr,
            nro: this.nro,
            xBairro: this.xBairro,
            cMun: this.cMun,
            xMun: this.xMun,
            UF: this.UF,
            CEP: this.CEP,
            cPais: this.cPais,
            xPais: this.xPais,
            fone: this.fone
        }
    }
}

export class Dest {
    constructor() {
        this.CPF = ''
        this.CNPJ = ''
        this.xNome = ''
        this.ender = new Ender() 
        this.indIEDest = '1'
        this.IE = ''
    }

    fromConfigureClient(client){
        this.CPF = client._genericPerson.cpf
        this.CNPJ = client._clientCompany.cnpj
        this.xNome = client._genericPerson.name
        this.ender.fromConfigureClient(client) //Endereço do destinatário.
        this.indIEDest = '1' //Indicador da IE do destinatário (1 - Contribuinte ICMS).
        this.IE = client._clientCompany.subscriptionState 
    }

    fromEdite(data){
        this.CPF = data.CPF ? data.CPF : ''// cpf
        this.CNPJ =  data.CNPJ ? data.CNPJ : ''//CNPJ do destinatário.
        this.xNome = data.xNome //Nome do destinatário.
        this.ender.fromEditeEnder(data) //Endereço do destinatário.
        this.indIEDest = data.indIEDest //Indicador da IE do destinatário (1 - Contribuinte ICMS).
        this.IE = data.IE //Inscrição Estadual do destinatário.
    }

    fromEditeDest(data){
        this.CPF = data.CPF // cpf
        this.CNPJ = data.CNPJ //CNPJ do destinatário.
        this.xNome = data.xNome //Nome do destinatário.
        this.ender = new Ender(data.enderDest) //Endereço do destinatário.
        this.indIEDest = data.indIEDest //Indicador da IE do destinatário (1 - Contribuinte ICMS).
        this.IE = data.IE //Inscrição Estadual do destinatário.
    }

    getMapFromCloud(){
        return{
            CPF: this.CPF,
            CNPJ: this.CNPJ,
            xNome:this.xNome,
            ender:this.ender.getMapFromCloud(),
            indIEDest:this.indIEDest.slice(0,1),
            IE:this.IE,
        }
    }
}

export class SettingsNFE{
    constructor(){
        this.NCM = ''
        this.idBrand = 0
        this.idTax = 0
        this.isBj = 0
        this.needSave = false
        this.configured = 0
        this.ICMS = new ICMS() 
        this.PIS = new PIS() 
        this.COFINS = new COFINS()
    }

    fromOther(data){
        this.NCM = data.NCM
        this.idTax = data.idTax
        this.configured = data.configured
        this.needSave = false
      
        this.ICMS.fromOther(data.ICMS)
        this.PIS.fromOther(data.PIS)
        this.COFINS.fromOther(data.COFINS)
    }

    fromCloud(data){
        this.NCM = data.NCM
        this.idTax = data.idTax
        this.configured = data.TaxConfigured
        this.needSave = false
        this.ICMS.fromCloud(data)
        this.PIS.fromCloud(data)
        this.COFINS.fromCloud(data)
    }

    configure(icms,pis,cofins,formData,isBj,idBrand){
        this.NCM = formData.NCM
        this.idBrand = idBrand
        this.needSave = true
        this.isBj = isBj
        this.ICMS.fromOther(icms)
        this.PIS.fromConfigureSave(pis)
        this.COFINS.fromConfigureSave(cofins)
    }

    getMapFromCloud(){
        return{
            NCM:this.NCM,
            idBrand:this.idBrand,
            isBj:this.isBj,
            idTax: this.idTax,
            TaxConfigured:this.configured,
            ICMS:this.ICMS.getMapFromCloud(),
            PIS_CST:this.PIS.CST.slice(0,2),
            PIS_ppis:this.PIS.pPIS,
            COFINS_pcofins:this.COFINS.pCOFINS,
            COFINS_cst:this.COFINS.CST.slice(0,2),
            needSave:this.needSave
        }
    }
}

export class Det { // (Detalhamento do Item da NF-e)
    constructor() {
        this.nItem = 0 //numero
        this.prod = new Prod() //(Dados do Produto):
        this.ICMS = new ICMS() //(Imposto sobre Circulação de Mercadorias e Serviços):
        this.PIS = new PIS() //(Programa de Integração Social):
        this.COFINS = new COFINS()
    }

    ConfigureToEmitFromSale(NfeSettings){
        this.ICMS.fromNfeSettigs(NfeSettings.ICMS)
        this.PIS.fromNfeSettigs(NfeSettings.PIS)
        this.COFINS.fromNfeSettigs(NfeSettings.COFINS)
    }

    getValueTotalToString(){
        return 'TOTAL DE IMPOSTO: ' +(this.ICMS.getICMSTotal()+this.PIS.vPIS+this.COFINS.vCOFINS).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getMapFromCloud(){
        return{
            nItem: this.nItem,
            prod: this.prod.getMapFromCloud(),
            ICMS: this.ICMS.getMapFromCloud(),
            PIS: this.PIS.getMapFromCloud(),
            COFINS: this.COFINS.getMapFromCloud()
        }
    }
}

export class Prod {
    constructor() {
        this.cProd = generateCNF()
        this.cEAN = ''
        this.xProd = ''
        this.NCM = ''
        this.CFOP = ''
        this.uCom = 'UN'
        this.qCom = 1
        this.vUnCom = 0
        this.vProd = 0
        this.cEANTrib = ''
        this.uTrib = ''
        this.qTrib = ''
        this.vUnTrib = ''
        this.indTot = '1'
    }


    fromProduct(product,NfeSettings){
        this.cProd = generateCNF()
        this.cEAN = product.codebar
        this.xProd = product.nameProduct
        this.NCM = product.NCM === ''? NfeSettings.NCM : product.NCM
        this.CFOP = '5102'
        this.uCom = 'UN'
        this.qCom = product.amount
        this.vUnCom = product.valueToSale
        this.vProd = product.getSaleValueTotal()
        this.cEANTrib = product.codebar
        this.uTrib = 'UN'
        this.qTrib = product.amount
        this.vUnTrib = product.valueToSale
        this.indTot = '1'
    }

    fromBJ(itemsSale,NfeSettings){
        this.cProd = generateCNF()
        this.cEAN = itemsSale.codeBar
        this.xProd = itemsSale.itemGlobal.name
        this.NCM = NfeSettings.NCM
        this.CFOP = '5102'
        this.uCom = 'UN'
        this.qCom = itemsSale.amount
        this.vUnCom = itemsSale.getValueSale(myStoreDataMemory.profitFactor)
        this.vProd = itemsSale.getValueSaleTotal(myStoreDataMemory.profitFactor)
        this.cEANTrib = itemsSale.codeBar
        this.uTrib = 'UN'
        this.qTrib = itemsSale.amount
        this.vUnTrib = itemsSale.getValueSale(myStoreDataMemory.profitFactor)
        this.indTot = '1'
    }

    getToString(){
        return 'TOTAL ' + this.xProd + ' (' +this.vUnCom + '*'  +this.qCom +')' + ' = ' +
        this.vProd.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    calculateTotal(){
        this.vProd = this.qCom*this.vUnCom
    }

    fromEditeBasics(data){
        this.cProd = data.cProd // Código do produto.
        this.cEAN = data.cEAN //Código EAN (opcional).
        this.xProd = data.xProd //Descrição do produto.
        this.NCM = data.NCM //Nomenclatura Comum do Mercosul.
        this.CFOP = data.CFOP //Código Fiscal de Operações e Prestações.
        this.uCom = data.uCom //Unidade comercial.
        this.qCom = data.qCom //Quantidade comercial.
        this.vUnCom = data.vUnCom //Valor unitário de comercialização.
    }

    getMapFromCloud(){
        return{
            cProd: this.cProd,
            cEAN: this.cEAN,
            xProd: this.xProd,
            NCM: this.NCM.replace('.',''),
            CFOP: this.CFOP.replace('.',''),
            uCom: this.uCom,
            qCom: this.qCom,
            vUnCom: this.vUnCom,
            vProd: this.vProd,
            cEANTrib: this.cEANTrib,
            uTrib: this.uTrib,
            qTrib: this.qTrib,
            vUnTrib: this.vUnTrib,
            indTot: this.indTot,
        }
    }
 
}

export class PIS {
    constructor() {
        this.CST = '01 - Operação Tributável com Alíquota Básica'
        this.vBC = 0.0
        this.pPIS = 0.0
        this.vPIS = 0.0
        this.calculatePIS(0,0)
    }

    getMapFromCloud(){
        return{
            CST: this.CST,
            vBC: this.vBC,
            pPIS: this.pPIS,
            vPIS: parseFloat(this.vPIS).toFixed(2),
        }
    }

    fromNfeSettigs(data){
        this.CST =  data.CST
        this.pPIS =  data.pPIS
        this.calculatePIS(this.vPIS,this.pPIS )
    }

    fromOther(data){
        this.CST = data.CST
        this.vBC = data.vBC
        this.pPIS = data.pPIS
        this.vPIS = data.vPIS
    }

    fromConfigureSave(data){
        this.CST = data.CST  
        this.pPIS = data.pPIS 
    }

    fromCloud(data){
        this.CST = data.PIS_CST  
        this.pPIS = data.PIS_ppis  
    }

    getToString(){
        return ' | PIS = ' + this.vPIS.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    setValueVBC(value){
        this.vBC = parseFloat(value)
        if(isNaN(this.vBC)) this.vBC = 0
        this.calculatePIS(this.vBC, this.pPIS)
    }

    calculate() {
        this.vPIS = parseFloat(this.vBC) * (parseFloat(this.pPIS) / 100)
        if(isNaN(this.vPIS)) this.vPIS = 0
    }

    fromEditePIS(data) {
        this.CST = data.CST
        this.pPIS = parseFloat(data.pPIS)
        if(isNaN(this.pPIS)) this.pPIS = 0
        this.calculatePIS(this.vBC,this.pPIS)
    }

    calculatePIS(vBC,pPIS) {
        this.vPIS = vBC * (pPIS / 100)
        if(isNaN(this.vPIS)) this.vPIS = 0
    }
}

export class COFINS {
    constructor() {
        this.CST = '01 - Operação Tributável com Alíquota Básica'
        this.vBC = 0.0
        this.pCOFINS = 0.0
        this.vCOFINS = 0.0
        this.calculateCOFINS(this.vBC,this.pCOFINS)
    }

    getMapFromCloud(){
        return{
            CST: this.CST,
            vBC: this.vBC,
            pCOFINS: this.pCOFINS,
            vCOFINS: parseFloat(this.vCOFINS).toFixed(2),
        }
    }

    fromNfeSettigs(data){
        this.CST =  data.CST
        this.pCOFINS =  data.pCOFINS
        this.calculateCOFINS(this.vBC,this.pCOFINS)
    }

    fromOther(data){
        this.CST = data.CST
        this.vBC = data.vBC
        this.pCOFINS = data.pCOFINS
        this.vCOFINS = data.vCOFINS
    }

    fromConfigureSave(data){
        this.CST = data.CST  
        this.pCOFINS = data.pCOFINS 
    }

    fromCloud(data){
        this.CST = data.COFINS_cst  
        this.pCOFINS = data.COFINS_pcofins 
    }

    getToString(){
        return ' | COFINS = ' + this.vCOFINS.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    setValueVBC(value){
        this.vBC = parseFloat(value)
        if(isNaN(this.vBC)) this.vBC = 0
        this.calculateCOFINS(this.vBC, this.pCOFINS)
    }

    fromEditeCOFINS(data) {
        this.CST = data.CST
        this.pCOFINS = parseFloat(data.pCOFINS)
        if(isNaN(this.pCOFINS)) this.pCOFINS = 0
        this.calculateCOFINS(this.vBC,this.pCOFINS)
    }

    calculate() {
        this.vCOFINS = parseFloat(this.vBC) * (parseFloat(this.pCOFINS) / 100)
        if(isNaN(this.vCOFINS)) this.vCOFINS = 0
    }

    calculateCOFINS(vBC,pCOFINS) {
        this.vCOFINS = parseFloat(vBC) * (parseFloat(pCOFINS) / 100)
        if(isNaN(this.vCOFINS)) this.vCOFINS = 0
    }
}

function validateNumber(value) {
    const number = parseFloat(value)

    if (isNaN(number)) {
        return 0.00
    }

    return number
}

export class ICMSTot {
    constructor() {
        this.vBC = 0
        this.vICMS = 0
        this.vICMSDeson = 0
        this.vFCP = 0
        this.vBCST = 0
        this.vST = 0
        this.vFCPST = 0
        this.vFCPSTRet = 0
        this.vProd = 0
        this.vFrete = 0
        this.vSeg = 0
        this.vDesc = 0
        this.vII = 0
        this.vIPI = 0
        this.vIPIDevol = 0
        this.vPIS = 0
        this.vCOFINS = 0
        this.vOutro = 0
        this.vNF = 0
    }

   

    getMapFromCloud(){
        return{
            vBC: parseFloat(this.vBC).toFixed(2),
            vICMS: parseFloat(this.vICMS).toFixed(2),
            vICMSDeson: parseFloat(this.vICMSDeson).toFixed(2),
            vFCP: parseFloat(this.vFCP).toFixed(2),
            vBCST: parseFloat(this.vBCST).toFixed(2),
            vST: parseFloat(this.vST).toFixed(2),
            vFCPST: parseFloat(this.vFCPST).toFixed(2),
            vFCPSTRet: parseFloat(this.vFCPSTRet).toFixed(2),
            vProd: parseFloat(this.vProd).toFixed(2),
            vFrete: parseFloat(this.vFrete).toFixed(2),
            vSeg: parseFloat(this.vSeg).toFixed(2),
            vDesc: parseFloat(this.vDesc).toFixed(2),
            vII: parseFloat(this.vII).toFixed(2),
            vIPI: parseFloat(this.vIPI).toFixed(2),
            vIPIDevol: parseFloat(this.vIPIDevol).toFixed(2),
            vPIS: parseFloat(this.vPIS).toFixed(2),
            vCOFINS: parseFloat(this.vCOFINS).toFixed(2),
            vOutro: parseFloat(this.vOutro).toFixed(2),
            vNF: parseFloat(this.vNF).toFixed(2),
        }
    }

    clear(){
        this.vBC = 0
        this.vICMS = 0
        this.vPIS = 0
        this.vCOFINS = 0
        this.vProd = 0
    }

    parseValue(value) {
        return isNaN(parseFloat(value)) ? 0 : parseFloat(value)
    }

    fromEditeBasics(data){
        this.vFrete = this.parseValue(data.vFrete)
        this.vIPI = this.parseValue(data.vIPI)
        this.vII = this.parseValue(data.vII)
        this.vIPIDevol = this.parseValue(data.vIPIDevol)
        this.vDesc = this.parseValue(data.vDesc)
        this.vSeg = this.parseValue(data.vSeg)
        this.vOutro = this.parseValue(data.vOutro)
        this.calculateTotal()
    }

    calculateTotal() {
        this.vNF = this.vProd - this.vDesc - this.vICMSDeson + this.vST + this.vFCPST + this.vFCPSTRet +  this.vFrete
        + this.vSeg + this.vOutro + this.vII + this.vIPI + this.vIPIDevol + this.vPIS + this.vCOFINS
    }
}

export class Transp {
    constructor() {
        this.modFrete = '9 - Sem cobrança de frete'
    }
    
    getMapFromCloud(){
        return{
            modFrete: this.modFrete.slice(0,1)
        }
    }

    fromEditeTransp(data){
        this.modFrete = data.modFrete
    }
}

export class InfAdic {
    constructor() {
        this.infCpl = '...'    // Informações Complementares de Interesse do Fisco
    }

    getMapFromCloud(){
        return{
            infCpl: this.infCpl.length === 0 ? '...' : this.infCpl
        }
    }

    fromEditeInfAdic(data) {
        this.infCpl = data.infCpl
    }
}

export class Pag {
    constructor() {
        this.clear()
    }

    clear(){
        this.xPag = 'Dinheiro'
        this.tPag = 0
        this.vPag = 0
    }

    getMapFromCloud() {
        
        return {
            indPag: 0,
            tPag: this.tPag,
            vPag: this.vPag,
            xPag : this.xPag,
        }
    }

    addPagamento(tPag, vPag,xPag) {
        this.tPag = tPag
        this.vPag = vPag
        this.xPag = xPag
    }
}

export class Cobr {
    constructor() {
        this.fat = null
        this.dup = []
    }

    getMapFromCloud() {
        return {
            fat: this.fat ? {
                nFat: this.fat.nFat,
                vOrig: this.fat.vOrig,
                vDesc: this.fat.vDesc,
                vLiq: this.fat.vLiq
            } : null,
            dup: this.dup.map(dup => ({
                nDup: dup.nDup,
                dVenc: dup.dVenc,
                vDup: dup.vDup
            }))
        };
    }

    // Método para configurar dados de cobrança a partir dos dados recebidos
    fromEditCobr(data) {
        this.fat = data.fat || null;
        this.dup = data.dup || [];
    }
    
    // Método para adicionar uma fatura
    setFatura(nFat, vOrig, vDesc, vLiq) {
        this.fat = { nFat, vOrig, vDesc, vLiq };
    }
    
    // Método para adicionar uma duplicata
    addDuplicata(nDup, dVenc, vDup) {
        this.dup.push({ nDup, dVenc, vDup });
    }
}

