import { faClose,  faTicket, faWarning } from "@fortawesome/free-solid-svg-icons";
import { GetButtonIcon } from "../../layout/ButtonsLayout";
import { TitlePageResponsiveIcon } from "../../layout/TitlePage";
import style from '../../style/ShowNfeEmited.module.css'
import { useEffect, useState } from "react";
import { getNfeEmitedWithId } from "../../controllers/CloudNfeEmited";
import { useError, useLoading } from "../../AppContext";
import Loader from "../../layout/Loader";
import { ModelNfeEmited, ModelNfeEmitedHelperName } from "./ModelNfeEmited";
import { GetSmallModels } from "../../Models/GetModels";
import Space from "../../layout/Space";

export function ShowNfeEmited({onClose,idNfe}) {

    const [nfeEmited,setNfeEmited] = useState(null)
    const {showError} = useError()
    const {isLoading,setIsLoading} = useLoading()

    useEffect(() => {
       const find = async() => {
            setIsLoading(true)
            const nfeTemp = await getNfeEmitedWithId(idNfe,showError)
            setNfeEmited(nfeTemp)
            setIsLoading(false)
       }
       find()
    },[])

    return(
        <div className={style.overlay}>
            <div className={style.alert}>
                { 
                    !isLoading?
                    <>
                        <div className={style.title}>
                            <TitlePageResponsiveIcon 
                            children={
                                <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={onClose}/> 
                            }
                            subtitle={"EMITIR NOTA FISCAL NFE PARA ESSA VENDA"} path={"Aviso Importante de Configuração"} icon={faTicket}/>      
                        </div>
                            {
                                nfeEmited!== null ? 
                                <>
                                    <ModelNfeEmitedHelperName/>
                                    <Space top={5}/>
                                    <ModelNfeEmited viewSale={false} nfeEmited={nfeEmited} />
                                </>
                                : 
                                    <GetSmallModels 
                                        title={'ERRO AO BUSCAR NFe/NFC-e'}
                                        icon={faWarning}
                                        backgroundColor={'#ff0000'}
                                        subtitle={'Aconteceu algum erro no servidor, e não foi possível recuperar a NFe/NFC-e'}
                                    />
                                }
                    </> :  
                    <div className={style.loaderCenter}>
                        <Loader titleFinding={'Buscando NFe/NFC-e no Servidor!'} 
                        subtitleFinding={'Aguarde...'} loading={isLoading}/>
                    </div>
                }
            </div>
        </div>
    )
}