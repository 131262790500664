import { listItemsMemory, listProductsMemory, myStoreDataMemory } from "../backEnd/Memory"
import { requestPDFs } from "../controllers/CloudPdfs"
import GenericProduct from "./Brands/Products/GenericProducts"
import ItemsSale from "./ItemBJ/ItemsSale"
import TypePhotoSave from "./TypePhotoSave"

class BlockNote{
    constructor(){
        this.idNotes = 0
        this.name =''
        this.description =''
        this.date =''
        this.codeImage = ''

        this.images = [] //TypePhotoSave
        this.requestsItems = [] // itemsSale
        this.requestGenericProducts = [] // Produtos
        
        this.hasImage = false;
    }

  
  fromAdd(data,requestsItems,requestGenericProducts){
    this.description = data.description
    this.name = data.name
    this.images = []
    this.requestsItems = requestsItems
    this.requestGenericProducts = requestGenericProducts

    this.date = new Date().toISOString().replace("T",' ').slice(0,19)
    if(this.images.length > 0)
        this.hasImage = true
  }

  fromOther(other){
    this.idNotes = other.idNotes
    this.name = other.name
    this.images = other.images
    this.description = other.description
    this.date = other.date
    this.codeImage = other.codeImage
    this.hasImage = other.hasImage
    this.requestsItems = other.requestsItems
    this.requestGenericProducts = other.requestGenericProducts
  }

  setUpdate(data,imagesList,listItems,ListProducts){
    this.name = data.name;
    this.description = data.description
    //this.images = imagesList
    this.requestsItems = listItems
    this.requestGenericProducts = ListProducts

    if(this.images.length > 0)
      this.hasImage = true
    else 
      this.hasImage = false
    
  }

   fromCloud(data){
    this.idNotes = data.idNotes
    this.name = data.name
    this.description = data.description
    this.codeImage = data.codeImage
    this.hasImage = data.hasImage === 1? true : false;
    this.date = new Date(data.date).toISOString().replace("T",' ').slice(0,19)

    if (data.images !== null) {
      for (var images of data.images) {
        let temp = new TypePhotoSave()
        temp.fromCloud(images)
        this.images.push(temp)
      }
    }

    if (data.items !== null) {
      for (var items of data.items) {
        let ssItem = items
        let index =  listItemsMemory.findIndex(ss => ss.idItem === ssItem.idItem)
        if(index !== -1){
            let temp = new ItemsSale()
            temp.fromOther(listItemsMemory[index])
            temp.amount = items.amount
            this.requestsItems.push(temp)
        }   
      }
    }

    if (data.generics !== null) {
      for (var generics of data.generics) {
        let temp = generics
        let index =  listProductsMemory.findIndex(ss => ss.idGenericProducts === temp.idGenericProducts)
        if(index !== -1){
            let Product = new GenericProduct()
            Product.fromOtherWithAmount(generics.amount, listProductsMemory[index])
            this.requestGenericProducts.push(Product)
        }     
      }
    }
  }

  async requestPDF(showError){
    await requestPDFs({
      'type':'pdfBJNote',
      'data' : this.getMapFromCloud(),
      'myStore': myStoreDataMemory.getMapFromCloud()
    },showError)
  }

  getMapFromCloud(imagesLink){

    let items = {}
    for (let element of this.requestsItems) {
      items[element.idItem.toString()] = element.getMapFromCloud()
    }

    let generics = {}
    for (let element of this.requestGenericProducts) {
      generics[element.idGenericProducts.toString()] = element.getMapFromCloud()
    }

    let listImages = this.images.map(objeto => objeto.linkImage)

    return{
      'date': this.date,
      'description':this.description,
      'hasImage':this.hasImage,
      'name':this.name.toUpperCase(),
      'codeImage': this.codeImage,
      'ListImage': listImages,
      'ListItems':items,
      'ListGenericProducts':generics,
      'idNotes':this.idNotes,
    }
  }
}

export default BlockNote