class genericPersonObject{
    constructor(){
        this.idPerson = 0
        this.name= ''
        this.cpf= ''
        this.rg= ''
        this.dateBorn= ''

        this.nameSpouse= ''
        this.rgGovernationEmission= ''
        this.rgDateEmission= ''
        this.profession= ''

        this.nameFather = ''
        this.nameMother = ''
    }

    fromCloud(data){
      this.idPerson = data.idPerson??0
      this.name= data.name??''
      this.cpf= data.cpf??''
      this.rg= data.rg??''

      if(data.dateBorn){
        let date = new Date(data.dateBorn)
        let formattedDate = date.toISOString().split('T')[0]
        this.dateBorn = formattedDate ?? ''
      }else this.dateBorn = ""

      this.nameSpouse= data.nameSpouse??''
      this.rgGovernationEmission= data.rgGovernationEmission??''

      if(data.rgDateEmission){
        let date = new Date(data.rgDateEmission)
        let formattedDate = date.toISOString().split('T')[0]
        this.rgDateEmission = formattedDate ?? ''
      }else this.rgDateEmission = ""

      this.profession= data.profession??''

      this.nameFather = data.nameFather??''
      this.nameMother = data.nameMother??''
    }

    getMapFromCloud(){
        return{
          'idPerson':this.idPerson,
          'profession':this.profession.toUpperCase(),
          'name':this.name.toUpperCase(),
          'rg': this.rg,
          'cpf':this.cpf,
          'nameFather':this.nameFather.toUpperCase(),
          'nameMother': this.nameMother.toUpperCase(),
          'nameSpouse':this.nameSpouse.toUpperCase(),
          'dateBorn': this.dateBorn,
          'rgDateEmission':this.rgDateEmission,
          'rgGovernationEmission' : this.rgGovernationEmission,
        };
    }
}
export default genericPersonObject;