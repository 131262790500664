class addressObject{
    constructor(){
        this.idAddress = 0;
        this.city = ''
        this.state = "MATO GROSSO";
        this.address = ''
        this.cep=  ''
    }

    fromCloud(data){
      this.idAddress = data.idAddress
      this.city = data.city
      this.state = data.state
      this.address = data.address
      this.cep=  data.cep
  }

    getMapFromCloud(){
        return{
          'idAddress':this.idAddress,
          'address': this.address.toUpperCase(),
          'city': this.city.toUpperCase(),
          'state': this.state.toUpperCase(),
          'cep': this.cep.toUpperCase(),
        }
      }
    
}
export default addressObject;