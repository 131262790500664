import {sendRequestGetLocal, sendRequestPostLocal} from '../backEnd/requesition.js'
import Brands from '../objects/Brands/Brands.js'

const _routerGetBrandsInCloud ='/brands/getBrandsTemp'
const _routerSetNewBrandsInCloud ='/brands/setBrands'
const _routerSetUpdateBrandsInCloud ='/brands/setEditeBrands'

export const getListBrands = async (showError) => {
    const listOfBrands = []
    try {
        var results = []
        results = await sendRequestGetLocal(_routerGetBrandsInCloud)
        if(Array.isArray(results)){
            Object.values(results).forEach(brands =>{   
                const newBrands = new Brands()
                newBrands.fromCloud(brands)
                listOfBrands.push(newBrands)
            })
        }else throw new Error(results.response.data.sqlMessage) 
    } catch (error) {
        showError('ERROR AO BUSCAR MARCAS',error.message)     
    }finally{
        return listOfBrands
    }
}

export const setSaveBrand = async (selectedImageFromFile,brand,showError) =>{
    var results
    try {

        if (selectedImageFromFile) {
         
            const imagePromise = new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                    const base64Image = reader.result.split(',')[1]
                    const dataBody = brand.getMapFromCloud()
                    dataBody['image'] = base64Image
                    resolve(sendRequestPostLocal(_routerSetNewBrandsInCloud, dataBody))
                }
                reader.onerror = error => reject(error)
                reader.readAsDataURL(selectedImageFromFile)
            })

            results = await imagePromise

        }else{
            results = await sendRequestPostLocal(_routerSetNewBrandsInCloud,brand.getMapFromCloud())
        }

        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO SALVAR NOVA MARCA',error.message)    
        return {status:404}
    }
}

export const setUpdateBrand = async (image,brand,showError) => {
   
    try { 
        var results = []

        if (image) {
         
            const imagePromise = new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                    const base64Image = reader.result.split(',')[1]
                    const dataBody = brand.getMapFromCloud()
                    dataBody['image'] = base64Image
                    resolve(sendRequestPostLocal(_routerSetUpdateBrandsInCloud, dataBody))
                }
                reader.onerror = error => reject(error)
                reader.readAsDataURL(image)
            })

            results = await imagePromise

        }else{
            results = await sendRequestPostLocal(_routerSetUpdateBrandsInCloud,brand.getMapFromCloud())
        }

        if(results.status !== 200)  throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERRO AO SALVAR EDIÇÃO DESSE MARCA',error.message)    
        return {status:404}
    }
}