import React, { useRef, useState } from "react"
import { GetButton, GetButtonIcon } from "../../../layout/ButtonsLayout"
import Alert from "../../../Models/Alert"
import style from "../../../style/EditeBrand.module.css"
import { TitlePageResponsiveIcon } from "../../../layout/TitlePage"
import InputModels from "../../../Models/InputModels"
import { faAngleDown, faAngleUp, faClose, faEdit, faPaperPlane, faPenToSquare, faSave, faTicket, faWandMagicSparkles, faWarning } from "@fortawesome/free-solid-svg-icons"
import { GetSmallModels } from "../../../Models/GetModels"
import Space from "../../../layout/Space"
import ConfirmationPopup from "../../../Models/ConfirmationPopup"
import InputPhoto from "../../../Models/InputPhoto"
import { setUpdateBrand } from "../../../controllers/CloudBrands"
import Loader from "../../../layout/Loader"
import { ToggleButtonWithContainer } from "../../../layout/ToggleButton"
import { useError } from "../../../AppContext"
import { GetICMS } from "../../Nfe/GetICMS"
import { GetPIS } from "../../Nfe/GetPIS"
import { GetCOFINS } from "../../Nfe/GetCOFIS"
import GetEditeNfeBj from "../../Nfe/GetEditeNfeBj"
import { findBrands } from "../../../backEnd/Memory"

const getFormData = (brand) => {
    return {
        nameBrand: brand.nameBrand,
        descriptionBrand: brand.descriptionBrand,
    }
}

const getFormNeed = () => {
    return {
        nameBrand: false,
        descriptionBrand: false,
    }
}

function EditeBrand({brand,handleClose,handleSaveBrand}){


    const [toggleStatus,setToggleStatus] = useState(brand.isActiveBrand)
    const [showConfirmSave,setShowConfirmSave] = useState(false)
    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData(brand))
    const [needData, setNeedData] = useState(getFormNeed)
    const [selectedImage] = useState(brand.linkImageBrand)
    const [selectedImageFromFile,setSelectedImageFromFile] = useState(null)
    const [editeNFE, setEditeNfe] = useState(false)
    const [loading,setLoading] = useState(false)
    const {showError} = useError()

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleReload = () =>{
        handleSaveBrand()
        handleClose()
    }

    const handleSave = async() =>{
        setShowConfirmSave(false)    
        setLoading(true)

        brand.fromEditeBrand(formData,toggleStatus)
        var result = await setUpdateBrand(selectedImageFromFile,
            brand,showError)

        if(result.status === 200){
            handleSaveBrand()
            handleClose()
        }

        setLoading(false)
    }

    const handleSubmitSave= () => {
        let allFieldsEmpty = false
        
        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setAlert(true)
            return
        }

        setShowConfirmSave(true)       
    }

    const handlerSlectedImageFromFile = (image) =>{
        setSelectedImageFromFile(image)
    }

    const handleToggleChangeStatus = (newToggleStatus) => {
        setToggleStatus(newToggleStatus)
    }

    return (
        <div className={style.overlay}>
          <div className={style.alert}>
             {!loading? 
             <>
                <div className={style.title}>
                    <TitlePageResponsiveIcon subtitle={"Editar Dados da Marca"}
                       children={
                        <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                       }
                        path={"Edite os Campos Abaixo..."} icon={faEdit}/>  
                   <Space left={5}/>
                    <div className={style.containerStatus}>
                        <ToggleButtonWithContainer titleOn={'ATIVO'} titleOff={'DESATIVO'}
                        initState={toggleStatus} onChange={handleToggleChangeStatus}/>
                    </div>
                </div>
                <div className={style.row}>
                        <div className={style.divImage}>
                            <InputPhoto titleButton={'Selecionar Logo Marca da Loja'} initialPhoto={selectedImage} 
                            handlerFileSelected={handlerSlectedImageFromFile}/>
                        </div>
                        <Space left={5}/>
                        <div className={style.div}>
                            <GetSmallModels title={"Dados da Marca"} subtitle={"São os Dados Básicos da Marca..."} icon={faWandMagicSparkles}/>
                            <Space left={5}/>
                            <InputModels validation={needData.nameBrand} value={formData.nameBrand} name={'nameBrand'} onChange={handleChange} iconButton={faPenToSquare} maxLength={20} type={"text"} placeholder={"Nome Completo da Marca..."} />
                            <InputModels validation={needData.descriptionBrand} value={formData.descriptionBrand} name={'descriptionBrand'} onChange={handleChange} iconButton={faPaperPlane} maxLength={30} type={"text"} placeholder={"Tipo de Mercadoria..."}/>  
                        </div>
                </div>
                <div className={style.div}>
                    <TitlePageResponsiveIcon
                    children={
                        <GetButton responsive={true} title={'EDITAR IMPOSTOS'} color='#094f00'  icon={faEdit} handleFunction={()=>setEditeNfe(true)}/> 
                    } icon={faTicket} title={'IMPOSTO SOBRE NOTA FISCAL NFe'} path={
                        'Para Emitir Nota FISCAL NFe é Necessario Configurar os Impostos'
                    }/>
                </div>
                    
                {showConfirmSave? <ConfirmationPopup title={'SALVAR EDIÇÃO DA MARCA'} 
                message={'Tem Certeza que Deseja Salvar Edição Dessa Marca?'} onCancel={()=>setShowConfirmSave(false)} onConfirm={handleSave}/>: null} 
                <Space top={5}/>
                <GetButton title={"SALVAR EDIÇÃO DA MARCA"} color='#007bff' icon={faSave} handleFunction={handleSubmitSave}/> 
                {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
            
            </> : <Loader titleFinding={'Salvando Dados no Servidor...'}
            subtitleFinding={'Aguarde...'} loading={loading} />}
          </div>
          {editeNFE?<GetEditeNfeBj idBrand={brand.idBrands} onReload={handleReload} isBj={0} nfeSettings={brand.settingsNFE} onClose={()=>setEditeNfe(false)}/>:null}
        </div>
    )
}

export default EditeBrand