import React,{useState} from "react";
import style from "../style/ItemModelList.module.css"
import { GetButtonIcon } from "../layout/ButtonsLayout";
import { faMinus, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Space from "../layout/Space";

function ProductModelList({data,fucntionButton,functionImage,onPlus,onRemove,onLess,showValueSale}) {
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    
    const handleImageLoad = () => {
        setImageLoaded(true); 
    }

    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }
 
  
    const iconSize = window.innerWidth > 1200 ? 45:30
    return(
        <div className={style.containerWrap}>
            <div className={style.containerWrap1}>
                <div className={style.imageContainer}>
                            {errorOccurred ? (
                                <img
                                src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                                alt="Erro de carregamento"
                                className={style.loaded}
                                />
                            ) : (
                            <img src={data.linkImage} alt={data.nameProduct} onLoad={handleImageLoad}
                                onError={handleImageError} onClick={functionImage}
                                className={!imageLoaded ? style.loading : style.loaded}/> )}
                </div> 
                <button className={style.container} onClick={fucntionButton}>
                    <div className={style.dataValues}>
                        {window.innerWidth > 400 ?
                            <>
                                <div className={style.containerAmount}>
                                <p className={style.amountHas}>{data.amount}</p>
                                </div>
                                <div className={style.containerNames}>
                                    <h3>{data.nameProduct}</h3>
                                    <h4>{data.codebar}</h4> 
                                    {showValueSale? <div>
                                        <h3>{data.getSaleValueTotalToString()}</h3>
                                    </div>: null}                        
                                </div>
                            </>:
                            <>
                                    <div className={style.containerNames}>
                                        <p className={style.amountHas}>{data.amount}</p>
                                        <h3>{data.nameProduct}</h3>
                                        {window.innerWidth > 1200 ?<h4>{data.codebar}</h4> :null}
                                        {showValueSale? <div>
                                            <h3>{data.getSaleValueTotalToString()}</h3>
                                    </div>: null}                        
                                </div>
                            </>
                        }
                        <div className={style.buttons}>
                            <GetButtonIcon width={iconSize} height={iconSize} icon={faMinus} handleFunction={onLess} color={"#ff0000"}/>
                            <Space left={5}/>
                            <GetButtonIcon width={iconSize} height={iconSize}  icon={faPlus} handleFunction={onPlus} color={"#078DFB"} />
                            <Space left={5}/>
                            <GetButtonIcon width={iconSize} height={iconSize}  icon={faTrashCan} handleFunction={onRemove} color={"#ff0000"} />
                        </div>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default ProductModelList