import { listBrandsMemory } from "../../../backEnd/Memory"
import VersionProduct from "../../VersionProduct"
import Brands from "../Brands"

class GenericProduct {
    constructor(){
        this.idGenericProducts = 0
        this.nameProduct = ''
        this.idBrands = 0
        this.amount = 0
        this.codebar = ''
        this.linkImage =''
        this.descriptionProduct =''
        this.valueToSale = 0
        this.valueCoast =0
        this.versionProduct = new VersionProduct()
        this.status = true
        this.measurement = new Measurement()
        this.NCM = ''

        this.tempBrand = new Brands()
        this.nameBrandTemp = ''
    }

    getName(){
        return this.nameProduct
    }

    fromNewProduct(data,brand){
        var coast = parseFloat(data.valueCoast)
        if (isNaN(coast)) {
            coast = 0
        }

        this.NCM = data.NCM
        this.measurement.fromEdite(data)
        this.tempBrand  = data.tempBrand
        this.status = true;
        this.nameProduct = data.nameProduct
        this.codebar = data.codebar
        this.descriptionProduct = data.descriptionProduct
        this.valueToSale = parseFloat(data.valueToSale).toFixed(2)
        this.valueCoast = coast
        this.amount = 0
        this.idBrands = parseInt(brand)
    }

    fromOtherWithAmount(amountValue, other){
        this.NCM = other.NCM
        this.tempBrand  = other.tempBrand
        this.status = other.status
        this.idGenericProducts= other.idGenericProducts
        this.nameProduct = other.nameProduct
        this.idBrands = other.idBrands
        this.codebar = other.codebar
        this.linkImage = other.linkImage
        this.descriptionProduct = other.descriptionProduct
        this.valueToSale = other.valueToSale
        this.amount = amountValue
        this.valueCoast = other.valueCoast
        this.versionProduct.fromOther(other.versionProduct)
        this.measurement.fromCloud(other.measurement)
    }

    fromCloud(data){ 
        this.NCM = data.NCM
        this.status = data.status === 1? true:false;
        this.nameProduct = data.nameProduct
        this.codebar = data.codebar
        this.linkImage = data.linkImage
        this.descriptionProduct = data.descriptionProduct
        this.valueToSale = data.valueToSale
        this.valueCoast = data.valueCoast
        this.idGenericProducts = data.idGenericProducts
        this.amount = data.amount
        this.idBrands = data.idBrands
        this.measurement.fromCloud(data)
        this.versionProduct.lastEdition = data.dateLastEdite
        const brand = listBrandsMemory.find(ss => ss.idBrands === this.idBrands)
        this.tempBrand = brand
    }

    getMapFromCloud(){
      return{
        'status':this.status,
        'idBrands':this.idBrands,
        'amount':this.amount,
        'codebar':this.codebar,
        'descriptionProduct':this.descriptionProduct.toUpperCase(),
        'nameProduct':this.nameProduct.toUpperCase(),
        'valueCoast':this.valueCoast,
        'valueToSale':this.valueToSale,
        'linkImage':this.linkImage,
        'container-lastVersion':this.versionProduct.getMapFromCloud(),
        'idGenericProducts':this.idGenericProducts,
        'measurement': this.measurement,
        'NCM':this.NCM
      }
    } 

    getCoastValueUnit(){
        return this.valueCoast
    }
    
    getSaleValueUnit(){
        return this.valueToSale
    }

    getSaleValueUnitToString(){
        return this.valueToSale.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getSaleValueTotalToString(){
        return this.getSaleValueTotal().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getSaleValueTotal(){
        return this.valueToSale * this.amount
    }

    getCoastValueTotal(){
        return this.valueCoast * this.amount
    }
}

class Measurement{
    constructor() {
        this.idMeasurement = 0
        this.unitMeasurement =''
        this.category =''
        this.height =''
        this.width =''
        this.thickness =''
        this.weight =''
    }

    fromEdite(data){
        this.unitMeasurement = data.unitMeasurement
        this.category = data.category
        this.height =  data.height === ''? 0: data.height
        this.width = data.width === ''? 0: data.width
        this.thickness = data.thickness === ''? 0: data.thickness
        this.weight = data.weight === ''? 0: data.weight
    }

    fromCloud(data){ 
        this.idMeasurement = data.idMeasurement ?? ''
        this.unitMeasurement = data.unitMeasurement  ?? ''
        this.category = data.category  ?? ''
        this.height = data.height  ?? ''
        this.width = data.width  ?? ''
        this.thickness = data.thickness  ?? ''
        this.weight = data.weight  ?? ''
    }

    getMapFromCloud(){
        return{
          'idMeasurement':this.idMeasurement,
          'unitMeasurement':this.unitMeasurement,
          'category':this.category,
          'height':this.height,
          'width':this.width,
          'thickness':this.thickness,
          'weight':this.weight,        
        }
      } 
}

export default GenericProduct