import React from "react"
import  { TitlePageResponsiveIcon } from "../../layout/TitlePage"
import { faBugSlash, faCalendarWeek, faCartPlus, faGift, faHammer, faHandshake, faScrewdriverWrench, faShopSlash } from "@fortawesome/free-solid-svg-icons"
import style from "../../style/ViewMoth.module.css"
import { GetSmallModels } from "../../Models/GetModels"
import Space from "../../layout/Space"
import SalesPerformanceChart from "./SalesPerformanceChart"
import GetAmountList from "../../Models/GetAmountList"

function ViewMoth({myMoth}){
    return(
        <div className={style.max}>
            <TitlePageResponsiveIcon subtitle={'Dados Sobre Esse Mês'}
                    path={'São Todas as Informações Sobre as Contabilidades Desse Mês'} icon={faCalendarWeek} />
                    <div className={style.grafic}>
                        <SalesPerformanceChart listReceivers={myMoth.listPaid} 
                        repairs={myMoth.getRepairs()}
                        sales={myMoth.salesMoth} />
                    </div>
                    <Space top={25}/>
                    <div className={style.container}>
                        <div className={style.grid}>
                            <div className={style.titleMenu}>
                                <GetSmallModels title={'Total de Vendas:'} value={myMoth.getValueTotalToString()}
                                    subtitle={'É o Valor Total de Vendas Esse Mês...'} />
                                <GetSmallModels title={'Valor (Total - Extras): '} value={myMoth.getValueNormalToString()}
                                    subtitle={'É a Somatória de Todas as Marcas Associadas aos Podutos...'} />
                                <GetSmallModels title={'Total em Valores Extras:'} value={myMoth.getValueExtraToString()}
                                    subtitle={'É a Somatória de Todos os Valores Extras...'} />
                                <GetSmallModels title={'Total em Brindes'} value={myMoth.getValuePresentToString()}
                                    subtitle={'É o Valor Total Gasto em Brindes'} />
                                <GetSmallModels title={'Total de Descontos'} value={myMoth.getValueDiscountToString()}
                                    subtitle={'É o Valor Total da Somatória de Todos os Descontos'} />
                            </div>
                        </div>
                        <div className={style.grid}>
                            <div className={style.titleMenu}>
                                <GetSmallModels title={'Mês Atual:'} value={myMoth.codeName}
                                    subtitle={'É o Mês Atual Salvo no Servidor!'} />
                                <GetSmallModels title={'Data Inicial do Mês:'} value={myMoth.getTimeStartToString()}
                                    subtitle={'É a Data de Inicio das Contabilidades Desse Mês'} />
                                <GetSmallModels title={'Status do Mês:'} value={myMoth.isOpenMoth? 'OPERANDO': 'FINALIZADO'}
                                    subtitle={'É a Situação Operacional do Mês'} />
                                <GetSmallModels title={'Total de Cancelamentos'} value={myMoth.getValueTotalCancelToString()}
                                    subtitle={'É o Valor Total da Somatória dos Cancelamentos'} />
                                <GetSmallModels title={'Total Recebido'} value={myMoth.getValueReceiversToString()}
                                    subtitle={'É o Valor Total da Somatória dos Recebimentos'} />
                            </div>
                        </div>
                    </div>
                    <Space top={50}/>
                    <div className={style.divInRow}>
                            <div className={style.div}>
                                <TitlePageResponsiveIcon subtitle={'Lista de Vendas'} 
                                path={'São Todas as Vendas Utilizadas no Cálculo Acima'} icon={faCartPlus}/>   
                                <div className={style.divList}>
                                  <div className={style.div}>
                                    <GetAmountList title={'Vendas Registradas...'} amount={myMoth.getSale().length}/>
                                    <Space top={5}/>
                                    {myMoth.getSale().map(
                                            (sale) => (
                                                <GetSmallModels title={sale.getCodeSale()} value={sale.getTotalValueIncludeAllToString()}
                                                subtitle={'Extras: '  + sale.getExtraValue() + ' |  Descontos: ' + sale.saleDiscount.getValueDiscountPercent() +' |  Data: '+ sale.detailsSale.getDateToString().slice(0,100)} />
                                            )
                                        )}
                                  </div>
                                </div>
                            </div>
                            <Space left={10}/>
                            <div className={style.div}>
                                <TitlePageResponsiveIcon subtitle={'Lista de Recebimentos'} 
                                path={'São Todas as Vendas Com Recebimentos...'} icon={faHandshake}/>   
                                <div className={style.divList}>
                                  <div className={style.div}>
                                    <GetAmountList title={'Pagamentos Registrados...'} amount={myMoth.listPaid.length}/>
                                    <Space top={5}/>
                                    {myMoth.listPaid.map(
                                            (sale) => (
                                                <GetSmallModels title={sale.codeSale} value={sale.getTotalValuePaid()}
                                                subtitle={'Pago às: ' + sale.getDataPaidToString()} />
                                            )
                                        )}
                                  </div>
                                </div>
                            </div>
                    </div>
                    <Space top={50}/>
                    <div className={style.divInRow}>
                            <div className={style.div}>
                                <TitlePageResponsiveIcon subtitle={'Lista de Vendas Canceladas'} 
                                path={'São Todas as Vendas Canceladas...'} icon={faShopSlash}/>   
                                <div className={style.divList}>
                                  <div className={style.div}>
                                    <GetAmountList title={'Vendas Canceladas...'} amount={myMoth.getCancels().length}/>
                                    <Space top={5}/>
                                    {myMoth.getCancels().map(
                                            (sale) => (
                                                <GetSmallModels title={sale.getCodeSale()} value={sale.getTotalValueIncludeAllToString()}
                                                subtitle={'Data do Cancelamento: ' + sale.cancelSale.getDateCancel()} />
                                            )
                                        )}
                                  </div>
                                </div>
                            </div>
                            <Space left={10}/>
                            <div className={style.div}>
                                <TitlePageResponsiveIcon subtitle={'Lista de Brindes'} 
                                path={'São Todos os Brindes do Mês...'} icon={faGift}/>   
                                <div className={style.divList}>
                                  <div className={style.div}>
                                    <GetAmountList title={'Brindes Registrados...'} amount={myMoth.getPresents().length}/>
                                    <Space top={5}/>
                                    {myMoth.getPresents().map(
                                            (sale) => (
                                                <GetSmallModels title={sale.getCodeSale()} value={sale.getTotalValueIncludeAllToString()}
                                                subtitle={'Data do Brinde: ' + sale.detailsSale.getDateToString()} />
                                            )
                                        )}
                                  </div>
                                </div>
                            </div>
                    </div>
                    <Space top={50}/>
                    <div className={style.divInRow}>
                            <div className={style.div}>
                                <TitlePageResponsiveIcon subtitle={'Lista de Consertos/Reparos'} 
                                path={'São Todos Consertos/Reparos Realizados no Período...'} icon={faScrewdriverWrench}/>   
                                <div className={style.divList}>
                                  <div className={style.div}>
                                    <GetAmountList title={'Consertos/Reparos...'} amount={myMoth.getRepairs().length}/>
                                    <Space top={5}/>
                                    {myMoth.getRepairs().map(
                                            (repair) => (
                                                <GetSmallModels icon={repair.status === 2?faBugSlash:faHammer } title={repair.title} value={repair.getValueToString()}
                                                subtitle={'Data: ' + repair.date + ' |  Status: ' + (repair.status === 2 ? 'CANCELADO': (repair.status === 0? 'EM CONSERTO' : 'ENTREGUE'))} />
                                            )
                                        )}
                                  </div>
                                </div>
                            </div>
                    </div>      
        </div>
    )
}

export default ViewMoth