import React, { useEffect, useState } from "react"
import { TitlePageResponsiveIcon } from "../../layout/TitlePage"
import { faBlenderPhone, faDrawPolygon, faFilePdf, faIdCard, faMapLocationDot, faMoneyCheck, faShop, faUsersLine } from "@fortawesome/free-solid-svg-icons"
import style from "../../style/ActionsStore.module.css"
import { GetButtonIcon } from "../../layout/ButtonsLayout"
import { employeeData } from "../../backEnd/InitController"
import { myStoreDataMemory, updateIfNull } from "../../backEnd/Memory"
import { useError, useLoading } from "../../AppContext"

function ActionsStore(){
    const [employeeUser] = useState(employeeData())
    const [canView] = useState(employeeUser.employeePermission.permissionsMyStore[0])
    const [canViewEmployee] = useState(employeeUser.employeePermission.permissionsEmployers[2])
    const { setIsLoading } = useLoading()
    const { showError } = useError()
    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            await updateIfNull(showError)   
            setIsLoading(false)
        }
        fetchData()
      }, [])

    const handlePdfContacts = async() =>{
        setIsLoading(true)
        await myStoreDataMemory.openPDFContacts(showError)
        setIsLoading(false)
    }

    const handlePdfBanks = async() =>{
        setIsLoading(true)
        await myStoreDataMemory.openPDFBanks(showError)
        setIsLoading(false)
    }

    const handlePdfAdressStore = async() =>{
        setIsLoading(true)
        await myStoreDataMemory.openPDFAdress(showError)
        setIsLoading(false)
    }
    
    const handlePdfDataStore = async() =>{
        setIsLoading(true)
        await myStoreDataMemory.openPDFDataStore(showError)
        setIsLoading(false)
    }

    const handlePdfLogoStore = async() =>{
        setIsLoading(true)
        await myStoreDataMemory.openPDFLogoStore(showError)
        setIsLoading(false)
    }

    const handlePdfCard = async() =>{
        setIsLoading(true)
        await myStoreDataMemory.openPDFCard(showError)
        setIsLoading(false)
    }

    const handlePdfEmployees = async() =>{
        setIsLoading(true)
        await myStoreDataMemory.openPDFEmployees(showError)
        setIsLoading(false)
    }
    
    return(
        <div>
            <div className={style.containerGlobal}>
                <div className={style.container}>
                    {canView? <div className={style.itemPDF}>
                        <div className={style.titleMenu}>
                            <TitlePageResponsiveIcon children={ <GetButtonIcon 
                                color='#007bff' icon={faFilePdf} handleFunction={handlePdfDataStore}/>}
                                 subtitle={'Gerar PDF dos Dados Cadastrais da Loja'} 
                            path={'É um PDF Completo com Todos os Dados Cadastrais da Sua Loja...'} icon={faShop}/>    
                        </div>
                    </div>:null}
                    {canView? <div className={style.itemPDF}>
                        <div className={style.titleMenu}>
                            <TitlePageResponsiveIcon subtitle={'Gerar PDF das Contas Bancarias da Loja'} 
                            children={ <GetButtonIcon 
                                color='#007bff' icon={faFilePdf} handleFunction={handlePdfBanks}/>}
                            path={'É um PDF Completo com Todas as Contas Bancarias da sua Loja...'} icon={faMoneyCheck}/>    
                        
                        </div>
                    </div>:null}
                </div>
                <div className={style.container}>
                    <div className={style.itemPDF}>
                        <div className={style.titleMenu}>
                            <TitlePageResponsiveIcon subtitle={'Gerar PDF dos Contatos da Loja'}                  
                           children={ <GetButtonIcon 
                                color='#007bff' icon={faFilePdf} handleFunction={handlePdfContacts}/>}
                            path={'É um PDF Completo com Todos os Contatos da sua Loja...'} icon={faBlenderPhone}/>    
                        </div>
                    </div>
                    <div className={style.itemPDF}>
                        <div className={style.titleMenu}>
                            <TitlePageResponsiveIcon subtitle={'Gerar PDF do Endereço Loja'} 
                           children={<GetButtonIcon 
                                color='#007bff' icon={faFilePdf} handleFunction={handlePdfAdressStore}/>}
                            path={'É um PDF Completo com o Endereço Físico da Sua Loja...'} icon={faMapLocationDot}/>      
                        </div>
                    </div>
                </div>
                <div className={style.container}>
                    <div className={style.itemPDF}>
                        <div className={style.titleMenu}>
                            <TitlePageResponsiveIcon subtitle={'Gerar Cartão de Visita da Loja'} 
                            children={<GetButtonIcon 
                                color='#007bff' icon={faFilePdf} handleFunction={handlePdfCard}/>}
                            path={'É um PDF no Formato de Cartão de Visita, Pra Impressão...'} icon={faIdCard}/>    
                        </div>
                    </div>
                    <div className={style.itemPDF}>
                        <div className={style.titleMenu}>
                            <TitlePageResponsiveIcon subtitle={'Gerar PDF da Logo Marca da Loja'} 
                            children={<GetButtonIcon 
                                color='#007bff' icon={faFilePdf} handleFunction={handlePdfLogoStore}/>}
                            path={'É um PDF Completo da Identidade Visual da Sua Loja...'} icon={faDrawPolygon}/>    
                        </div>
                    </div>
                </div>
                {canView && canViewEmployee?<div className={style.container}>
                    <div className={style.itemPDF}>
                    <TitlePageResponsiveIcon subtitle={'Gerar PDF de Todos os Funcionários da Loja'} 
                            children={<GetButtonIcon  
                                color='#007bff' icon={faFilePdf} handleFunction={handlePdfEmployees}/>}
                            path={'É um PDF Completo com Todos os Dados Cadastrais dos Funcionários'} icon={faUsersLine}/>    
                    </div>
                </div>:null}
            </div>
        </div>
    )
}

export default ActionsStore