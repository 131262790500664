import {sendRequestGetLocal, sendRequestPostLocal} from '../backEnd/requesition.js'
import GenericProduct from '../objects/Brands/Products/GenericProducts.js';
import VersionProduct from '../objects/VersionProduct.js';
import { getListBrands } from './CloudBrands.js';
import { getMotion } from './CloudRepositions.js';

const _routerGetGenericProductsInCloud ='/genericProducts/getGenericProducts'
const _routerSetGenericProductsInCloud ='/genericProducts/setGenericProducts'
const _routerUpdateGenericProductsInCloud ='/genericProducts/setEditeGenericProducts'
const _routerUpdateBachProductsInCloud ='/genericProducts/updateBach'

export const getListProducts = async (showError) => {
    const listOfProcuts = []
    try {
        var results = []
        results = await sendRequestGetLocal(_routerGetGenericProductsInCloud)
       if(Array.isArray(results)){
        Object.values(results).forEach(brands =>{   
            const newBrands = new GenericProduct()
            newBrands.fromCloud(brands)
            listOfProcuts.push(newBrands)
        })
       }else throw new Error(results.response.data.sqlMessage)
    } catch (error) {
        showError('ERROR AO BUSCAR PRODUTOS GENÉRICOS',error.message)     
    }finally{
        return listOfProcuts
    }
}

export const getListOnlyActiveAndEnableProducts = async(showError)=>{
    var products = await getListProducts(showError)
    var brands = await getListBrands(showError)

    const items = []
    brands.forEach(product =>{
        products.forEach(data =>{
            if(data.idBrands === product.idBrands && product.isActiveBrand && data.status){
                data.nameBrandTemp = product.nameBrand
                items.push(data)
            }
        })
    })

    return items
}

export const setSaveProduct = async (selectedImageFromFile,product,showError) =>{
    var results
    try {

        product.versionProduct = new VersionProduct()
        product.versionProduct.configureNewVersion('Essa Peça foi Editada Individualmente')
        if (selectedImageFromFile) {
         
            const imagePromise = new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                    const base64Image = reader.result.split(',')[1]
                    const dataBody = product.getMapFromCloud()
                    dataBody['image'] = base64Image
                    resolve(sendRequestPostLocal(_routerSetGenericProductsInCloud, dataBody))
                }
                reader.onerror = error => reject(error)
                reader.readAsDataURL(selectedImageFromFile)
            })

            results = await imagePromise

        }else{
            results = await sendRequestPostLocal(_routerSetGenericProductsInCloud,product.getMapFromCloud())
        }

        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO SALVAR NOVO PRODUTO',error.message)
        return {status:404}
    }
}

export const setUpdateProduct = async (image,product,showError) => {
   
    try { 
        var results = []
        product.versionProduct = new VersionProduct()
        product.versionProduct.configureNewVersion('Essa Peça foi Editada Individualmente')
        if (image) {
         
            const imagePromise = new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                    const base64Image = reader.result.split(',')[1]
                    const dataBody = product.getMapFromCloud()
                    dataBody['image'] = base64Image
                    resolve(sendRequestPostLocal(_routerUpdateGenericProductsInCloud, dataBody))
                }
                reader.onerror = error => reject(error)
                reader.readAsDataURL(image)
            })

            results = await imagePromise

        }else{
            results = await sendRequestPostLocal(_routerUpdateGenericProductsInCloud,product.getMapFromCloud())
        }

        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERRO AO SALVAR EDIÇÃO DESSE PRODUTO',error.message)
        return {status:404}
    }
}

export const getMapFromListProdutcs = (listItem,phrase) => {
    let map = {}
    listItem.forEach(item =>{
        item.versionProduct = new VersionProduct()
        item.versionProduct.configureNewVersion(phrase)
        map[item.idGenericProducts.toString()] = item.getMapFromCloud()
    })

    return map
}


export const setUpdateProductsInBatch = async (listItem,isAdd,showError) => {
   
    try { 
        var results = []
        var map = getMapFromListProdutcs(listItem,getMotion(isAdd))
           
        results = await sendRequestPostLocal(_routerUpdateBachProductsInCloud,{
            'isAdd':isAdd,
            'items':map
        })

        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERRO AO SALVAR LOTE DE PRODUTOS NO SERVIDOR',error.message)
        return {status:404}
    }
}