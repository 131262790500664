import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { errorRouter, routerFromStockGlobal, routerFromStockBj, routerFromStockOthers, routerFromStockListBrands, routerFromStockNewBrand, routerFromStockNewProduct, routerFromStockAccounting, routerFromStockRepositions, routerFromStockClear, routerFromStockRepositionBJ, routerFromStockRepositionGenerics, routerFromStockPDFallItems, routerFromStockMyShop } from "../../routers/routerList.js";
import style from "../../style/Controller.module.css"
import { NavBarGlobal, ObjectNavbarMenu } from "../NavbarGlobal.js";
import {faTruckArrowRight, faTruckMoving,faLayerGroup,faDatabase,faBoxesPacking,
    faFileLines,faFilePdf,faTrashArrowUp,faListCheck,faCalculator,faScaleBalanced,faCopyright,faList,faFileCirclePlus, faHandshake } from "@fortawesome/free-solid-svg-icons";
import ListStock from "./Database/ListStockBj.js";
import ListBrands from "./Brands/ListBrands.js";
import ListProducts from "./OtherProducts/ListProducts.js";
import AddBrands from "./Brands/addBrands.js";
import UpdateStock from "./Database/UpdateStock.js";
import AllStock from "./controllerStock/AllStock.js";
import ClearStock from "./controllerStock/clearStock.js";
import ListRepositions from "./controllerStock/ListRepositions.js";
import UpdateProducts from "./OtherProducts/UpdateProducts.js";
import GeralPdfStock from "./GeralPdfStock.js";
import { faShopify } from "@fortawesome/free-brands-svg-icons";
import ListShopping from "./Database/ListShopping.js";
import { databaseSettings, findDatabase } from "../../backEnd/Memory.js";
import { LoaderCenter } from "./Database/LoaderCenter.js";

function ControllerStock({isLoggedIn}){
    const location = useLocation()

    const [settings, setSettings] = useState(null)
    const [listRoutesToNav, setListRoutesToNav] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const data = await findDatabase()
            setSettings(data) 
        }
        fetchData()
    }, [])

    useEffect(() => {
        if(settings){
            const menu1 = new ObjectNavbarMenu('Estoque', faLayerGroup)
            if (settings.isCanUseBrunaBJ) menu1.addMenu('Estoque Bruna Semijoias', faDatabase, routerFromStockBj)
            menu1.addMenu('Estoque Outras Marcas', faBoxesPacking, routerFromStockOthers)

            const menu2 = new ObjectNavbarMenu('Marcas Genéricas', faCopyright)
            menu2.addMenu('Listar Marcas Genéricas', faList, routerFromStockListBrands)
            menu2.addMenu('Cadastrar Nova Marca', faFileCirclePlus, routerFromStockNewBrand)

            const menu3 = new ObjectNavbarMenu('Produtos e Reposições', faLayerGroup)
            if (settings.isCanUseBrunaBJ) menu3.addMenu('Reposição Bruna Semijoias', faTruckMoving, routerFromStockRepositionBJ)
            menu3.addMenu('Reposição Marcas Genéricas', faTruckArrowRight, routerFromStockRepositionGenerics)

            let menu6 = null;
            if (settings.isCanUseBrunaBJ) {
                menu6 = new ObjectNavbarMenu('Minhas Compras', faShopify)
                menu6.addMenu('Compras Bruna Semijoias', faHandshake, routerFromStockMyShop)
            }

            const menu4 = new ObjectNavbarMenu('Gerencia de Estoques', faScaleBalanced)
            menu4.addMenu('Estoque Total', faCalculator, routerFromStockAccounting)
            menu4.addMenu('Lista de Reposições', faListCheck, routerFromStockRepositions)
            menu4.addMenu('Zerar Estoque', faTrashArrowUp, routerFromStockClear)

            const menu5 = new ObjectNavbarMenu('PDF e Extratos', faFileLines)
            menu5.addMenu('PDF Gerais do Estoque', faFilePdf, routerFromStockPDFallItems)

            const menus = [menu1, menu2, menu3, menu4, menu5]
            if (settings.isCanUseBrunaBJ) {
                menus.splice(3, 0, menu6)
            }
            setListRoutesToNav(menus)
        }
    }, [settings])


    return isLoggedIn ? (
        <div className={style.containerGlobal}>
        <NavBarGlobal listObjectNavBarMenu={listRoutesToNav}/>
        <div className={style.containerData}>
           {settings? <>{(() => {
                switch(location.pathname){
                    case routerFromStockGlobal: return databaseSettings.isCanUseBrunaBJ? <ListStock/> : <ListProducts/>
                    case routerFromStockBj: return <ListStock/>
                    case routerFromStockOthers: return <ListProducts/>
                    case routerFromStockListBrands: return <ListBrands/>
                    case routerFromStockNewBrand: return <AddBrands/>
                    case routerFromStockMyShop: return <ListShopping/>
                    case routerFromStockPDFallItems: return <GeralPdfStock/>
                    case routerFromStockAccounting: return <AllStock/>
                    case routerFromStockRepositions: return <ListRepositions/>
                    case routerFromStockRepositionBJ: return <UpdateStock/>
                    case routerFromStockRepositionGenerics: return <UpdateProducts/>
                    case routerFromStockClear: return <ClearStock/>
                    default: return <Navigate to={errorRouter} replace />
                }
            })()}</>: <LoaderCenter/>}
        </div>
    </div>
    ) : (
        <Navigate to="/login" replace />
    )
}

export default ControllerStock