import { createContext, useContext, useState } from "react"

export const ShowExitContext = createContext()
export const useShowExit = () => useContext(ShowExitContext)
export const ShowExitProvider = ({ children }) => {
  const [showExit, setShowExit] = useState(false)

  return (
    <ShowExitContext.Provider value={{ showExit, setShowExit }}>
      {children}
    </ShowExitContext.Provider>
  )
}

export const ShowPDFContext = createContext()
export const useShowPDF = () => useContext(ShowPDFContext)
export const ShowPDFProvider = ({ children }) => {
  const [showShowPDF, setShowPDF] = useState(false)
  const [urlPdf, setUrlPdf] = useState(false)


  
  return (
    <ShowPDFContext.Provider value={{ showShowPDF, setShowPDF , urlPdf, setUrlPdf}}>
      {children}
    </ShowPDFContext.Provider>
  )
}


export const ShowMenuContext = createContext()
export const useShowMenu = () => useContext(ShowMenuContext)
export const ShowMenuProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <ShowMenuContext.Provider value={{ showMenu, setShowMenu }}>
      {children}
    </ShowMenuContext.Provider>
  )
}



export const ShowEditeEmployeeContext = createContext()
export const useShowEditeEmployee = () => useContext(ShowEditeEmployeeContext)
export const ShowEditeEmployeeProvider = ({ children }) => {
  const [showEditeEmployee, setShowEditeEmployee] = useState(false)

  return (
    <ShowEditeEmployeeContext.Provider value={{ showEditeEmployee, setShowEditeEmployee }}>
      {children}
    </ShowEditeEmployeeContext.Provider>
  )
}

export const ErrorContext = createContext()
export const useError = () => useContext(ErrorContext)
export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null)

  const showError = (title, message) => {
    setError({ title, message });
  }

  const hideError = () => {
    setError(null)
  }

  return (
    <ErrorContext.Provider value={{ error, showError, hideError }}>
      {children}
    </ErrorContext.Provider>
  )
}

export const GlobalLoadingContext = createContext()
export const useLoading  = () => useContext(GlobalLoadingContext)
export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <GlobalLoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </GlobalLoadingContext.Provider>
  )
}