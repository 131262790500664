import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { errorRouter,routerFromLogin, routerFromMoreOptionsExtractsPds, routerFromMoreOptionsGlobal, routerFromMoreOptionsRequestAndNotes, routerFromMoreOptionsRequestAndNotesCreate } from "../../routers/routerList.js";
import style from "../../style/Controller.module.css"
import { NavBarGlobal, ObjectNavbarMenu } from "../NavbarGlobal.js";
import { faCartArrowDown,faFileInvoiceDollar,
faMoneyCheckDollar,faSackDollar} from "@fortawesome/free-solid-svg-icons";
import ListExtractsSave from "./ListExtractsSave.js";
import ListBlockNotes from "./ListBlockNotes.js";

function ControllerMoreOptions({isLoggedIn}){
    const location = useLocation();

    const menu2 = new ObjectNavbarMenu('Pedidos e Notas',faCartArrowDown)
    menu2.addMenu('Visualizar Pedidos/Notas',faFileInvoiceDollar,routerFromMoreOptionsRequestAndNotes)

    const menu3 = new ObjectNavbarMenu('Extratos e PDFs',faSackDollar)
    menu3.addMenu('Lista de Extratos Salvos',faMoneyCheckDollar,routerFromMoreOptionsExtractsPds)

    const listRoutesToNav = [menu2,menu3];

    return isLoggedIn ? (
        <div className={style.containerGlobal}>
            <NavBarGlobal listObjectNavBarMenu={listRoutesToNav}/>
            <div className={style.containerData}>
               {(()=>{
                    switch(location.pathname){
                    case routerFromMoreOptionsGlobal: return <ListBlockNotes/>;
                    case routerFromMoreOptionsRequestAndNotes: return <ListBlockNotes/>;
                    case routerFromMoreOptionsExtractsPds: return <ListExtractsSave/>
                    default: return <Navigate to={errorRouter} replace />
                }
               })()}
            </div>
        </div>
    ) : (
        <Navigate to={routerFromLogin} replace />
    )
}

export default ControllerMoreOptions