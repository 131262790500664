import React, { useEffect, useState } from "react"
import { ItemModelFromSale } from "../../Models/ItemModel"
import style from "../../style/ViewList.module.css"
import { TitlePageResponsiveIcon } from "../../layout/TitlePage"
import Space from "../../layout/Space"
import InputModels from "../../Models/InputModels"
import { faCircleInfo, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import GetAmountList from "../../Models/GetAmountList"
import { GetSmallModels } from "../../Models/GetModels"
import GetExpandedImage from "../../layout/GetExpandedImage"
import { ProductModelFromSale } from "../../Models/ProductModel"

function ViewList({description,icon,title,listItems,isBJ}){
   
    const [filtered, setFiltered] = useState([])
    const [orderBy, setOrderBy] = useState('asc')
    const [list] = useState(listItems)
    
    const [isExpanded, setIsExpanded] = useState(false)
    const [name, setNameProduct] = useState('')
    const [linkImage, setSetLinkImage] = useState('')


    const toggleImageExpansion = (name,linkImage) => {
        setIsExpanded(!isExpanded)
        setNameProduct(name)
        setSetLinkImage(linkImage)
    }

    useEffect(() => {
        handleChangeFilter()
    }, [orderBy,list])

    const controllerFilter = () => {
        let sortedClients = [...list]
        switch (orderBy) {
          case 'asc':
            isBJ? sortedClients.sort((a, b) => a.itemGlobal.name.localeCompare(b.itemGlobal.name)):
            sortedClients.sort((a, b) => a.nameProduct.localeCompare(b.nameProduct))
            break
          case 'desc':
            isBJ? sortedClients.sort((a, b) => b.itemGlobal.name.localeCompare(a.itemGlobal.name)):
            sortedClients.sort((a, b) => b.nameProduct.localeCompare(a.nameProduct))
            break
          default:
            break
        }
        return sortedClients;
    }

    const handleOrderByChange = async (event) => {
        setOrderBy(event.target.value)
        handleChangeFilter()
        setFiltered(filtered)
    }
   
    const handleChangeFilter = (value) => {
        const data = controllerFilter()
        if(value!= null){
          const filteredItems = data.filter(
            item => {
              const filterName = 
              isBJ? item.itemGlobal.name.toString().toLowerCase().includes(value.target.value.toLowerCase()):
              item.nameProduct.toString().toLowerCase().includes(value.target.value.toLowerCase())
              return filterName 
            }
          )
          setFiltered(filteredItems)
        }else{
          setFiltered(data)
        }
    }

    return(
        <div className={style.containerGlobal}>
            <div className={style.title}>
                <TitlePageResponsiveIcon subtitle={title} 
                path={description } 
                icon={icon}/>
            </div>
            <div className={style.buildList}>
                <div className={style.filter}>
                    <div className={style.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} 
                    type={"text"} placeholder={"Buscar Produto..."}
                    onChange={handleChangeFilter}/>
                    </div>
                    <div className={style.containerDropDown}>
                        <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                            <option value="asc">CRESCENTE</option>
                            <option value="desc">DESCRESCENTE</option>
                        </select>
                    </div>
                </div>
                <Space right={2} left={2} top={5} bottom={0}/> 
                {(filtered.length > 0) ?<div className={style.buildList}>
                    <GetAmountList amount={filtered.length} title={"Produtos Totais Encontrados na Busca..."}/>
                    <Space right={0} left={0} top={0} bottom={10}/> 
                </div> : null}
                <Space top={5}/>
                <div className={style.containerList}>
                        <div>
                            {(filtered.length === 0)?
                            <GetSmallModels title={"Não há Produtos com Esse Filtro"}
                            subtitle={"Tente Trocar o Filtro..."}
                            icon={faCircleInfo} />:
                                filtered.map((item, index) => (
                                    <div key={index}>
                                   {isBJ? <ItemModelFromSale data={item}
                                    functionImage={()=>
                                    toggleImageExpansion(
                                        item.itemGlobal.name,
                                        item.getImage())
                                    } />:
                                    <ProductModelFromSale data={item}
                                    functionImage={()=>
                                    toggleImageExpansion(
                                        item.nameProduct,
                                        item.linkImage)
                                    } />}
                                    </div>
                                ))
                            }
                        </div>
                </div>
            </div>
            {isExpanded? <GetExpandedImage nameProduct={name} linkImage={linkImage} onClose={toggleImageExpansion}/>:null}
        </div>
    )
}


export default ViewList