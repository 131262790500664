import React from "react"
import ViewList from "../../Sale/ViewList"
import { faAdd, faClose, faList, faRemove } from "@fortawesome/free-solid-svg-icons"
import { GetButtonIcon } from "../../../layout/ButtonsLayout"
import style from "../../../style/ViewRepositionList.module.css"
import { TitlePageResponsiveIcon } from "../../../layout/TitlePage"

function ViewRepositionList({reposition,handleClose}){

    var icon = reposition.statusReposition === 'ADIÇÃO DE PEÇAS DA MALA' ? faAdd: faRemove

    return(
        <div className={style.overlay}>
            <div className={style.alert}>
            <TitlePageResponsiveIcon subtitle={"LISTA DE REPOSIÇÃO/REMOÇÃO"} 
                   children={
                    <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                   }
                    path={"É a Relação de Peças Nessa Operação"} icon={faList}/>  
            <ViewList icon={icon}  
            isBJ={reposition.isGenericProduct === false}
            listItems={reposition.items}
            description={reposition.statusReposition + ' - ' + reposition.dateReposition}
            title={reposition.nameReposition}/>
            </div>
        </div>
    )
}

export default ViewRepositionList