import React from "react";
import style from "../../style/DynamicPermissions.module.css"
import { GetSmallModels } from "../../Models/GetModels";
import { ToggleButtonWithContainer } from "../../layout/ToggleButton";

function DynamicPermissions({title,iconTitle,legeng,listToggle,disable}){
    return(
        <div className={style.containerData}>
            <GetSmallModels title={title} subtitle={legeng} icon={iconTitle}/> 
                <div className={style.listType}>
                {listToggle.map((data,index) =>(
                    <div className={style.permissionItem}>
                        <ToggleButtonWithContainer disable={disable} titleOn={data.titleOn} titleOff={data.titleOff}
                        initState={data.initState} onChange={data.handleToggleChange}/>
                    </div>
                ))}
            </div>                 
        </div>  
    )
}

export default DynamicPermissions