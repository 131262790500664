import React, {  useState } from 'react';
import style from '../../style/AddEndViewNote.module.css'
import { faClose,  faSignature, faEquals, faSave, faTrash, faFilePdf, faFileCircleCheck, faDatabase, 
  faPlus } from '@fortawesome/free-solid-svg-icons';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage';
import { GetButton, GetButtonIcon } from '../../layout/ButtonsLayout';
import InputModelsBlock from '../../layout/InputModelsBlock'
import Alert from '../../Models/Alert';
import Space from '../../layout/Space';
import ProductModelList from '../../Models/ProductModelList';
import { GetSmallModels } from '../../Models/GetModels';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import GetAmountList from '../../Models/GetAmountList';
import FindWithBarcode from '../FindWithBarcode';
import { databaseSettings, listItemsMemory, listProductsMemory } from '../../backEnd/Memory';
import ItemsSale from '../../objects/ItemBJ/ItemsSale';
import ItemModelList from '../../Models/ItemModelList';
import GenericProduct from '../../objects/Brands/Products/GenericProducts';
import { ShowMultiplePhotos } from '../../Models/InputPhoto';
import ConfirmationPopup from '../../Models/ConfirmationPopup';
import Loader from '../../layout/Loader';
import { setBlockNoteRemove, setNewBlockCode, setUpdateBlockNote } from '../../controllers/CloudBlockNotes';
import { useError, useLoading } from '../../AppContext';
import BlockNote from '../../objects/BlockNote';

const getFormData = (note) => {
    return {
        name:note? note.name:'' ,
        description:note? note.description:'',
    }
}

const getFormNeed = (note) => {
    return {
        name:false,
        description:false,
    }
}

function AddEndViewNote({note, onClose,onSave}) {
    const [confirmationVisible, setConfirmationVisible] = useState(false)
    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData(note))
    const [needData, setNeedData] = useState(getFormNeed(note))
    const [listItems, setListItems] = useState(note? note.requestsItems : [])
    const [listProducts, setProducts] = useState(note? note.requestGenericProducts : [])
    const [statusFindWithCode, setStatusFindWithCode] = useState(false)
    const [statusFindWithCodeProduct, setStatusFindWithCodeProduct] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([])
    const [selectedImage] = useState(note? note.images : [])
    const [title,setTitle] = useState('')
    const [legend,setLegend] = useState('')
    const [isRemove,setRemove] = useState(false)
    const { setIsLoading } = useLoading()
    const {showError} = useError()
    const handlerSlectedImageFromFile = (image) =>{
        setSelectedFiles(image)
    }

    const handleSave = async () =>{
        setConfirmationVisible(false)
        setLoading(true)

        let result 
       
        if(note){
            note.setUpdate(formData,selectedFiles,listItems,listProducts)
            result = await setUpdateBlockNote(note,showError)
        }else{
            let note = new BlockNote()
            note.fromAdd(formData,listItems,listProducts)
            result = await setNewBlockCode(note,showError)
        }
            
    
        if(result.status === 200){
            onSave()
            handleClose()
        }

        setLoading(false) 
    }

    const handleRemove = async () => {
        setConfirmationVisible(true)
        setTitle('APAGAR ESSA NOTA/PEDIDO')
        setLegend('Tem Certeza que Deseja Apagar Essa Nota/Pedido')
        setRemove(true)
    }

    const handleRemoveNow = async()=>{
        setLoading(true) 
        setConfirmationVisible(false)
        let result = await setBlockNoteRemove(note,showError)
        if(result.status === 200){
            onSave()
            handleClose()
        }
        setLoading(false) 
    }

    const handleClose = () => {
      onClose()
    } 

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmitSave= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setAlert(true)
            return
        }
        setTitle(note? 'ATUALIZAR NOTA/PEDIDO' : 'SALVAR NOVA NOTA/PEDIDO')
        setLegend(note? 'Tem Certeza que Deseja Atualizar a Nota/Pedido' : 'Tem Certeza que Deseja Salvar Uma Nova Nota/Pedido')
        setRemove(false)
        setConfirmationVisible(true)
    }

    const onRemoveItem = (itemBj) => {
        const temp = listItems.filter(item => item !== itemBj)
        setListItems(temp)
    }

    const onLessItem = (data) => {
        data.amount--
        if(data.amount <= 0)
            onRemoveItem(data)
        else setListItems([...listItems]) 
    }

    const onPlusItem = (data) => {
        let index = listItems.findIndex(ss => ss.idItem === data.idItem)
        if(index !== -1){
            listItems[index].amount++
            setListItems([...listItems])
        }else{
            const item  = new ItemsSale()
            item.fromOther(data)
            item.amount = 1
            setListItems([...listItems, item]);
        }
    }

    const onRemoveProduct = (product) => {
        const temp = listProducts.filter(item => item !== product)
        setProducts(temp)
    }

    const onLessProduct = (product) => {
        product.amount--
        if(product.amount <= 0)
            onRemoveProduct(listProducts)
        else setProducts([...listProducts])
    }

    const onPlusProduct = (product) => {
        let index = listProducts.findIndex(ss => ss.idGenericProducts === product.idGenericProducts)
        if(index !== -1){
            listProducts[index].amount++
            setProducts([...listProducts])
        }else{
            const item  = new GenericProduct()
            item.fromOtherWithAmount(1,product)
            setProducts([...listProducts, item]);
        }
    }

    const handleOpenPdf = async()=>{
        setIsLoading(true)
        await note.requestPDF(showError)
        setIsLoading(false)
    }

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
            { !loading ? <>
                <TitlePageResponsiveIcon subtitle={note? 'EDITAR NOTA/PEDIDO' : 'ADICIONAR NOVA NOTA/PEDIDO'} 
                        children={
                            <div className={style.rowButtons}>
                                {note? 
                                <GetButtonIcon color='#007bff' icon={faFilePdf} handleFunction={handleOpenPdf}/>  : null}
                                <Space left={5}/>
                                <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/>   
                            </div>
                        }
                        path={"Complete os Campos Abaixo..."} icon={faFileCircleCheck}/>      
                <Space top={5}/>
                <div className={style.div}>
                        <InputModelsBlock onChange={handleChange} 
                        legend={'ASSUNTO DESSA NOTA/PEDIDO'}
                        validation={needData.name} 
                        maxLength={50} 
                        value={formData.name} 
                        name={'name'} 
                        iconButton={faSignature}
                        type={"text"} 
                        placeholder={"Assunto..."}/>
                        <Space top={5}/>
                        <InputModelsBlock 
                        legend={'O QUE VOCÊ DESEJA DETALHAR?'}
                        onChange={handleChange} 
                        validation={needData.description}
                        maxLength={10000} 
                        value={formData.description} 
                        name={'description'} 
                        iconButton={faEquals}
                        type={"text"} placeholder={"Detalhes..."}/>  
                </div>
                <Space top={25}/>
                {databaseSettings.isCanUseBrunaBJ?
                 <div className={style.div}>
                        <div className={style.divInRow}>
                                <GetSmallModels icon={faDatabase} title={'Lista de Peças Bruna Semijoias'}/>
                                <Space left={5}/>
                                <div className = {style.button}>
                                    <GetButtonIcon handleFunction={()=>setStatusFindWithCode(true)} icon={faPlus} color='#007bff' width={50} height={50}/>
                                </div>
                            </div>
                            <GetAmountList title={' Peças na Lista'} amount={listItems.length}/>
                            {listItems.map((item, index) => (
                                <ItemModelList 
                                    onPlus={()=>onPlusItem(item)}
                                    onLess={()=>onLessItem(item)}
                                    onRemove={
                                    ()=>onRemoveItem(item)} data={item} fucntionButton={()=>{}} functionImage={()=>{}} />
                            ))}
                </div>:null}
                <Space top={25}/>
                <div className={style.div}>
                        <div className={style.divInRow}>
                            <GetSmallModels icon={faProductHunt} title={'Lista de Produtos'}/>
                                <Space left={5}/>
                                <div className = {style.button}>
                                <GetButtonIcon handleFunction={()=>setStatusFindWithCodeProduct(true)} icon={faPlus} color='#007bff' width={50} height={50}/>
                                </div>  
                            </div>
                            <GetAmountList title={' Produtos na Lista'} amount={listProducts.length}/>
                            {listProducts.map((data, index) => (
                                <ProductModelList 
                                    onPlus={()=>onPlusProduct(data)}
                                    onLess={()=>onLessProduct(data)}
                                    onRemove={
                                ()=>onRemoveProduct(data)} data={data} fucntionButton={()=>{}} functionImage={()=>{}} />
                            ))}
                </div>
                <Space top={25}/>
                {selectedImage.length > 0 ?<div className={style.divPhotos}>
                    <ShowMultiplePhotos initialPhotos={selectedImage}/>
                </div>:null}
                <div className={style.row}>
                    <div className={style.div}>
                            <GetButton title={'SALVAR ESSA NOTA'} color='#007bff' icon={faSave} handleFunction={handleSubmitSave}/> 
                    </div>
                    <Space left={5} top={5}/>
                    {note? <div className={style.div}>
                        <GetButton title={'APAGAR ESSA NOTA'} color='#FF0000' icon={faTrash} handleFunction={handleRemove}/> 
                    </div>  : null}         
                </div>
           </>:  
           <Loader titleFinding={'Salvando Dados no Servidor...'}
            subtitleFinding={'Aguarde...'} loading={loading} />}
           {confirmationVisible? <ConfirmationPopup title={title} 
            message={legend} onCancel={()=>setConfirmationVisible(false)} 
            onConfirm={isRemove ? handleRemoveNow : handleSave}/>: null}
           {statusFindWithCodeProduct? <FindWithBarcode isBJ={false} onValidator={onPlusProduct} list={[...listProductsMemory]} onClose={()=>setStatusFindWithCodeProduct(false)}/> : null}
           {statusFindWithCode? <FindWithBarcode isBJ={true} onValidator={onPlusItem} list={[...listItemsMemory]} onClose={()=>setStatusFindWithCode(false)}/> : null}
           {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
      </div>
    )
}

export default AddEndViewNote
