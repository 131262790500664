import { employeeData } from "../../backEnd/InitController"
import { listClientsMemory, listEmployeeMemory, myStoreDataMemory } from "../../backEnd/Memory"
import { requestPDFs } from "../../controllers/CloudPdfs"

export class Repairs{
    constructor(client){
        this.tempEmployee = null
        this.idEmployee = 0
        this.idClient= 0
        this.idRepairs = 0
        this.title =''
        this.description =''
        this.internalDescription =''
        this.value = 0
        this.date = new Date().toISOString().replace("T",' ').slice(0,10)
        this.dateReturned = new Date().toISOString().replace("T",' ').slice(0,10)

        this.dateCancel = ''
        this.reasonCancel = ''
        this.idEmployeeCancel = 0
        this.tempClient = client?? null
        this.status = 0
    }

    fromAdd(data,idClient){
        this.idClient = idClient
        this.idEmployee = employeeData().idEmployee
        this.description = data.description
        this.internalDescription = data.internalDescription
        this.title = data.title
        this.value = parseFloat(data.value?? 0)
        this.date = new Date().toISOString().replace("T",' ').slice(0,10)
        this.status = 0
    }

    setCancel(reason){
        this.dateCancel = new Date().toISOString().replace("T",' ').slice(0,10)
        this.reasonCancel = reason
        this.idEmployeeCancel = employeeData().idEmployee
        this.status = 2
    }

    setDelivered(){
        this.dateReturned = new Date().toISOString().replace("T",' ').slice(0,10)
        this.status = 1
    }

    fromUpdate(data){
        this.description = data.description
        this.internalDescription = data.internalDescription
        this.title = data.title
        this.value = parseFloat(data.value?? 0)
        this.date = new Date(data.date).toISOString().replace("T",' ').slice(0,10)
        this.status = 0
    }

    fromCloud(data){
        this.idClient = data.IdClient
        this.idRepairs = data.idRepairs
        this.title = data.title
        this.description = data.description
        this.value = data.value
        this.internalDescription = data.internalDescription
        this.status = data.status
        this.tempEmployee = listEmployeeMemory.filter(ss=>ss.idEmployee === data.idEmployee)[0]
        this.idEmployee = data.idEmployee
        this.dateCancel = data.dateCancel !== null ? new Date(data.dateCancel).toISOString().replace("T",' ').slice(0,10) : null
        this.reasonCancel = data.reasonCancel
        this.idEmployeeCancel = data.idEmployeeCancel
    
        this.tempClient = listClientsMemory.filter(ss => ss.IdClient === data.IdClient)[0]
  
        this.date = new Date(data.date).toISOString().replace("T",' ').slice(0,10)
        this.dateReturned = new Date(data.dateReturned).toISOString().replace("T",' ').slice(0,10)
    }

    async requestPDF(showError){
        await requestPDFs({
          'type':'pdfRepairs',
          'data' : this.getMapFromCloud(),
          'myStore': myStoreDataMemory.getMapFromCloud()
        },showError)
    }

    getValueToString(){
        return this.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getMapFromCloud(){
        return{
            'idClient':this.idClient,
            'idEmployee':this.idEmployee,
            'reasonCancel':this.reasonCancel,
            'idEmployeeCancel':this.idEmployeeCancel,
            'dateCancel':this.dateCancel,
            'idRepairs': this.idRepairs,
            'title':this.title.toUpperCase(),
            'description':this.description.toUpperCase(),
            'internalDescription':this.internalDescription.toUpperCase(),
            'value':this.value,
            'date': this.date,
            'dateReturned': this.dateReturned,
            'status':this.status,
        }
    }
}