import { getPercentage } from "../../backEnd/ControllerGloba"
import { Employee } from "../ClientsAndPerson/employee"


class AccountingCommissions{
    constructor(){
        this.personOwner = new Employee() //employee
        this.sale = [] //Sale
        this.amountSale = 0
        this.valueMoney = 0
        this.valueAllSales = 0 
        this.FinvalValueWithFixedComission  = 0
    }

    getFinalValue(){
        return this.FinvalValueWithFixedComission.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getFixedComission(){
        return this.personOwner.comissionFixed.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getValueMoney(){
        return this.valueMoney.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getValueAllSales(){
        return this.valueAllSales.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }


    fromOnlyPerson(personOwner){
        this.personOwner = personOwner
    }

    setAddNewSale(newSale){
        this.sale.push(newSale)
    }

    calculate(){
        this.valueAllSales = 0;
        this.amountSale = 0;
        this.valueMoney = 0;

        for (var element of this.sale) {
            this.amountSale++;
            this.valueAllSales += element.totalValueIncludeAll;
            this.valueMoney +=  getPercentage(element.totalValueIncludeAll, this.personOwner.percentComission)
        }

        this.FinvalValueWithFixedComission = this.personOwner.comissionFixed + this.valueMoney
    }
}

export default AccountingCommissions