import React, { useEffect, useState } from "react"
import style from "../../style/ViewLastEdition.module.css"
import { TitlePageResponsiveIcon } from "../../layout/TitlePage"
import { GetButtonIcon } from "../../layout/ButtonsLayout"
import { faChartPie, faCircleInfo, faClose } from "@fortawesome/free-solid-svg-icons"
import { getListLastVersion } from "../../controllers/CloudLastVersion"
import Loader from "../../layout/Loader"
import { GetSmallModels } from "../../Models/GetModels"
import LastEditionModel from "../../Models/LastEditionModel"
import { getListEmployees } from "../../controllers/CloudEmployee"
import { useError } from "../../AppContext"
import Space from "../../layout/Space"

function ViewLastEdition({last,handleClose,isGeneric}){
    
    const [loading, setLoading] = useState(false)
    const [listEmployee,setlistEmployee] = useState([])
    const [listLastVersion,setListLastVersion] = useState([])
    const {showError} = useError()

    useEffect(() => {
        async function fetchData() {
            await handleFindDataBase()      
        }
        fetchData()
    }, [])

    const handleFindDataBase = async() => {
        setLoading(true)
        var resultEmployee = await getListEmployees(showError)
        setlistEmployee(resultEmployee)
        var result = await getListLastVersion(!isGeneric? last.idItem:last.idGenericProducts,isGeneric,showError)
        setListLastVersion(result)
        setLoading(false)
    }

    return(
        <div className={style.overlay}>
            <div className={style.alert}>
                <div className={style.title}>
                    <TitlePageResponsiveIcon title={"Relatório de Edições Desse modelo"}
                        children={ <GetButtonIcon color='#E4080A' icon={faClose} 
                            handleFunction={handleClose}/> }
                        path={ window.innerWidth< 768  ? 'São Todas as Alterações de Estoque':"São Todas as Alterações de Estoque Desse Modelo"} icon={faChartPie}/>    
                </div>
                <Space top={10}/>
                {   loading? 
                <div className={style.loaderCenter}>
                     <Loader titleFinding={'Buscando Relatório no Servidor!'} 
                    subtitleFinding={'Aguarde...'} loading={loading}/>
                </div>:
                    <div className={style.div}>
                        {listLastVersion.length === 0 ? (
                            <>
                                <GetSmallModels
                                    title={"Não há Produtos com Esse Filtro"}
                                    subtitle={"Tente Trocar o Filtro..."}
                                    icon={faCircleInfo}
                                />
                            </>
                        ) : (
                            listLastVersion.map((data, index) => (
                                <div key={index}>
                                <LastEditionModel 
                                employee={listEmployee.find(employee => employee.idEmployee === data.idEmployee)} 
                                lastVersion={data}/>
                                </div>
                            ))
                        )}
                    </div>
                }
            </div>
          </div>
        )
}

export default ViewLastEdition