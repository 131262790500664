import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';
import { ErrorProvider, LoadingProvider, ShowEditeEmployeeProvider, ShowExitProvider, ShowMenuProvider, ShowPDFProvider } from './AppContext.js';

const root = ReactDOM.createRoot(document.getElementById('root'))

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registrado com sucesso:', registration)
      })
      .catch(registrationError => {
        console.log('Falha ao registrar o Service Worker:', registrationError)
      })
  })
}

root.render(
  <React.StrictMode>
      <ErrorProvider>
        <LoadingProvider>
          <ShowExitProvider>
            <ShowEditeEmployeeProvider>
              <ShowMenuProvider>
                <ShowPDFProvider>
                  <App />
                </ShowPDFProvider>
              </ShowMenuProvider>
            </ShowEditeEmployeeProvider>
          </ShowExitProvider>
        </LoadingProvider>
      </ErrorProvider>
  </React.StrictMode>
)


