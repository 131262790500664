export class contactObject{
  constructor(){
      this.idSimpleContact = 0
      this.number1= ''
      this.number2 = ''
      this.email = ''
  }

  fromCloud(data){
    this.idSimpleContact = data.idSimpleContact??0
    this.number1= data.number1??''
    this.number2 = data.number2??''
    this.email = data.email??''
  }

    
  getMapFromCloud(){
    return{
      'idSimpleContact':this.idSimpleContact,
      'email':this.email,
      'number1': this.number1,
      'number2': this.number2,
    };
  }
}
