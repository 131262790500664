import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import style from "../../../style/StockCharts.module.css"
import { databaseSettings } from '../../../backEnd/Memory';

const getStyle = () =>{
  return  {
      backgroundColor: 'rgba(28,28,28,1)',
      padding:10,
      borderRadius:5,
      border: '1px solid rgba(255, 255, 255, 0.5)',
      width: '100%' , height: 100
  }
}

const BarChart = ({ labels, data, title }) => {
    const canvasRef = useRef(null);
    const chartRef = useRef(null);
  
    const colors = [
        'rgba(210, 1, 3, 1)', 
        'rgba(39, 134, 1, 1)', 
        'rgba(3, 157, 152, 1)',
       
    ];

    useEffect(() => {
      if (canvasRef.current) {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
  
        const ctx = canvasRef.current.getContext('2d');
        chartRef.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [{
              label: title,
              data: data,
              backgroundColor: colors,
              borderColor: colors.map(color => color.replace('0.2', '1')),
              borderWidth: 1
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                    color: 'white' 
                },
                grid: {
                    display: false
                }
              },
              x: {
                ticks: {
                  color: 'white' 
                },
                grid: {
                    display: false
                }
              }
            },
            elements: {
                bar: {
                  shadow: {
                    color: 'rgba(0, 0, 0, 0.5)',
                    blur: 10,
                    offsetX: 5,
                    offsetY: 5,
                    opacity: 0.5
                  }
                }
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return 'Valor: ' + context.parsed.y;
                    }
                  }
                },
                legend: {
                  display: false,
                  labels: {
                    font: {
                        position: 'left',
                        align: 'start', 
                        size: 16,
                    },
                    color: 'rgba(255, 255, 255, 0.5)',
                  }
                }
              },animation: {
                duration: 2000,
                easing: 'easeInOutQuart'
              }
          }
        });
      }
  
      return () => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
      };
    }, [labels, data, title]);
  
    return (
      <div className={style.divChart}>
        <p>{title}</p>
        <canvas ref={canvasRef} style={getStyle()}></canvas>
      </div>
    );
}

const StockCharts = ({ stockAll }) => {
  const brunaSemijoiasData = {
    labels: ['Gramas', 'Modelos', 'Itens'],
    data: [
      stockAll.brunaSemijoiasStock.grams,
      stockAll.brunaSemijoiasStock.models,
      stockAll.brunaSemijoiasStock.items,
    ],
    title: 'Estoque da Bruna Semijoias'
  };

  const brunaSemijoiasDataValues = {
    labels: ['Custo Total', 'Valor de Venda Total'],
    data: [
      stockAll.brunaSemijoiasStock.coasts,
      stockAll.brunaSemijoiasStock.saleValue
    ],
    title: 'Valores do Estoque da Bruna Semijoias'
  };

  const genericProductData = {
    labels: ['Marcas', 'Modelos', 'Itens'],
    data: [
      stockAll.genericProductStock.brands,
      stockAll.genericProductStock.models,
      stockAll.genericProductStock.items,
    ],
    title: 'Estoque de Produtos Genéricos'
  };

  const genericProductDataValues = {
    labels: ['Custo Total', 'Valor de Venda Total'],
    data: [
      stockAll.genericProductStock.coasts,
      stockAll.genericProductStock.saleValue
    ],
    title: 'Valores do Estoque Produtos Genéricos'
  };

  return (
    <div className={style.divInCollum}>
         {databaseSettings.isCanUseBrunaBJ?
         <> 
            <div className={style.div}>
              <BarChart {...brunaSemijoiasData} />
              <BarChart {...genericProductData} />
            </div>
            <div className={style.div}>
              <BarChart {...brunaSemijoiasDataValues} />
              <BarChart {...genericProductDataValues} />
            </div>
        </>:
        <> 
          <div className={style.div}>
            <BarChart {...genericProductData} />
            <BarChart {...genericProductDataValues} />
          </div>
        </>}
    </div>
  );
}

export default StockCharts;
