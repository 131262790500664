import React, { useState } from 'react';
import style from "../style/StateCountry.module.css"

const TypePaymentDropDown = ({ initialState, disable , handlerFuncion, name}) => {
  const states = [
    "PARCELADO", "À VISTA"
  ]

  const [type, setType] = useState(initialState || "");

  const handleChange = (event) => {
    setType(event.target.value);
    handlerFuncion(event)
  }

  return (
    <div>
        <select disabled={disable} name={name} className={style.state} onChange={handleChange} value={type}>
            {states.map((status, index) => (
                <option key={index} value={status}>{status}</option>
            ))}
        </select>
    </div>
  );
}


export default TypePaymentDropDown
