import React,{useEffect, useRef, useState}from "react";
import style from "../style/GetClientList.module.css"
import { faCircleInfo, faClose, faMagnifyingGlass, faPlus, faRemove, faUser } from "@fortawesome/free-solid-svg-icons";
import { GetSmallModels } from "../Models/GetModels";
import { TitlePageResponsiveIcon } from "../layout/TitlePage";
import GetAmountList from "../Models/GetAmountList";
import { GetButtonIcon } from "../layout/ButtonsLayout";
import InputModelsBlock from "../layout/InputModelsBlock";
import Space from "../layout/Space";

function GetClientList({list,onClose,onSelect}){

    const containerRef = useRef(null)
    const [limit, setLimit] = useState([50])
    const [filtered, setFiltered] = useState([])
    
    useEffect(() =>{
      handleChangeFilter()
    },[])

    const handleClose = () => {
        onClose()
    } 

    const handleChangeFilter = (value) => {
        const data = [...list]
        data.sort((a,b) => a._genericPerson.name.localeCompare(b._genericPerson.name))
        resetScroll()
        if(value != null){
          const filteredStock = data.filter(
            client => {
                let filternName = client._genericPerson.name.toLowerCase().includes(value.target.value.toLowerCase())
                return filternName
            }
          )
          setFiltered(filteredStock)
        }else{
          setFiltered(data)
        }
    }

    const resetScroll =()=>{
        const container = containerRef.current
        container.scrollTop = 0
        setLimit(50)
    }
    
    const checkScroll= ()=> {
        const container = containerRef.current;
        const scrollPosition = container.scrollTop;
        const containerHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;
    
        if (scrollPosition + containerHeight >= scrollHeight) {   
          setLimit((limit + 50))
        }
    }

    const handlerSelect = (client) => {
        onSelect(client)
        onClose()
    }

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
            <div className={style.divList}>
              <TitlePageResponsiveIcon children={
                <GetButtonIcon handleFunction={handleClose} icon={faClose} color='red'/>
              } title={'BUSCAR'} 
              subtitle={'SELECIONE A CLIENTE'} 
              path={'Buscar Cliente > Por Nome'}/>
              <div className={style.buildList}>
                <InputModelsBlock legend={'NOME DA CLIENTE QUE VOCÊ DESEJA BUSCAR'}
                  iconButton={faMagnifyingGlass} type={'text'}
                  maxLength={50} 
                  placeholder={"Nome da Cliente..."} onChange={handleChangeFilter}/>
                <Space right={5} left={5} top={5} bottom={5}/>
                {(filtered.length > 0) ? (<div className={style.buildList}>
                 <GetAmountList amount={filtered.length} title={"Clientes Totais Encontradas na Busca..."}/>
                </div>): null}
                <Space right={5} left={5} top={5} bottom={5}/>
                <div className={style.build} ref={containerRef} onScroll={checkScroll}>
                  {(filtered.length === 0)?
                  <GetSmallModels title={"Não há Clientes com Esse Filtro"}
                  subtitle={"Tente Trocar o Filtro..."} icon={faCircleInfo} />:
                  filtered.slice(0, limit).map((data, index) => (
                    <GetClient client={data} onSelect={()=>handlerSelect(data)}/>
                    ))}
                </div>   
              </div>
            </div>
        </div>
      </div>
    )
}

function GetClient({client,onSelect}){
    return(
        <div className={style.divClient}>
            <GetSmallModels icon={faUser} subtitle={client._genericPerson.cpf} title={client._genericPerson.name} value={window.innerWidth <= 1200 ? '': client.isOpenSales ? "DEVENDO":"SEM DIVIDAS"}/>
            <Space left={5}/>
            <div className={style.buttonAdd}>
                <GetButtonIcon width={45} handleFunction={onSelect} height={45} icon={faPlus} color={'#0164C6'}/>
            </div>
        </div>
    )
}

export function GetClientAfterSelect({client,onRemove}){
    return(
        <div className={style.divClient}>
            <GetSmallModels icon={faUser} subtitle={client._genericPerson.cpf} title={client._genericPerson.name} value={window.innerWidth> 768 ? client.isOpenSales ? "DEVENDO":"SEM DIVIDAS" : ''}/>
            <Space left={5}/>
            <div className={style.buttonAdd}>
                <GetButtonIcon width={45} handleFunction={onRemove} height={45} icon={faRemove} color={'#ff0000 '}/>
            </div>
        </div>
    )
}

export default GetClientList