export class AlertsNotices{
    constructor(){
        this.idNotice = 0
        this.title =''
        this.message = ''
        this.open = false
    }

    fromCloud(data,isOpen){
        this.title = data.title
        this.message = data.message
        this.open = isOpen
    }

    getMapFromCloud(){
        return{
            idNotice: this.idNotice,
            message: this.message,
            title: this.title,
        }
    }
}