import React from 'react';
import QRCode from 'qrcode.react';
import style from "../style/QRCodeGenerator.module.css"
import { TitlePageResponsiveIcon } from '../layout/TitlePage';
import { GetButtonIcon } from '../layout/ButtonsLayout';
import { faClose, faQrcode } from '@fortawesome/free-solid-svg-icons';

function QRCodeGenerator({ value, handleClose}) {
    return (
        <div className={style.overlay}>
          <div className={style.alert}>
             <div className={style.title}>
                  <TitlePageResponsiveIcon 
                  children={
                    <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/>
                  } subtitle={window.innerWidth <= 1200 ? "Vincular Loja Com Vendedor" :"Vincular Loja Com Vendedor Bruna Semijoias"}
                  path={window.innerWidth <= 1200 ? 'Capture o QRCODE Abaixo': "O Vendedor da Bruna Semijoias Deve Captura o QRCODE Abaixo"} 
                  icon={faQrcode}/>  
             </div>
             <div className={style.div}>
                <QRCode size={window.innerWidth <= 1200 ? (window.innerWidth/2) : 600} value={value} />
             </div>
          </div>
        </div>
      )
}

export default QRCodeGenerator;
