import React from "react";
import style from "../style/Loader.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHand } from "@fortawesome/free-solid-svg-icons";


function Loader({titleFinding,subtitleFinding,loading }){
  return (
    loading ? <div className={style.wrap}>
        <FontAwesomeIcon className={style.wrapIcon} icon={faHand}/>
        <div className={style.wrapTitle}>
            <h3>{titleFinding}</h3>
            <h4>{subtitleFinding}</h4>  
        </div>
        <div className={style.loading}>
          <div className={style.spinner} />
        </div>
    </div> : null
  );
}

export default Loader