class companyObject{
    constructor(){
        this.idGovernmentData = 0;
        this.socialReason = ''
        this.cnpj =''
        this.fantasyName =''
        this.simplesNational = ''
        this.subscriptionState =''
    }

    fromCloud(data){
      this.idGovernmentData = data.idGovernmentData??0
      this.socialReason = data.socialReason??''
      this.cnpj = data.cnpj??''
      this.fantasyName = data.fantasyName??''
      this.simplesNational = data.simplesNational??''
      this.subscriptionState = data.subscriptionState??''
    }

    getMapFromCloud(){
        return{
          'idGovernmentData':this.idGovernmentData,
          'socialReason': this.socialReason.toUpperCase(),
          'cnpj': this.cnpj.toUpperCase(),
          'simplesNational':this.simplesNational.toUpperCase(),
          'subscriptionState': this.subscriptionState.toUpperCase(),
          'fantasyName': this.fantasyName.toUpperCase(),
        };
      }
}

export default companyObject