import React, { useEffect, useState } from 'react';
import style from '../style/GetPix.module.css'
import { TitlePageResponsiveIcon } from '../layout/TitlePage';
import { faPix } from '@fortawesome/free-brands-svg-icons';
import { GetButton, GetButtonIcon } from '../layout/ButtonsLayout';
import { faAddressBook, faCity, faClose, faCopy, faKey, faMoneyBill, faPersonBurst, faWarning } from '@fortawesome/free-solid-svg-icons';
import PIX from 'react-qrcode-pix';
import Space from '../layout/Space';
import Alert from './Alert';
import InputModelsBlock from '../layout/InputModelsBlock';
import { myStoreDataMemory } from '../backEnd/Memory';
import { BacksDropDown } from '../layout/FinancialDropDown';
import { GetSmallModels } from './GetModels';

const GetPix = ({ onClose, valor}) => {
    
    const [isVisible,setVisible] = useState(false)
    const [alertError,setAlertError] = useState(false)
    const [fullPIX, setFullPIX] = useState("")
    const [isConfigured,setConfigured] = useState(false)
    const [showNeedBank] = useState(myStoreDataMemory._listOfBanks.length)

    const [formData, setFormData] = useState({
        pixValue: myStoreDataMemory._listOfBanks.length > 0 ? myStoreDataMemory._listOfBanks[0]['keycodePix'] : '',
        name: myStoreDataMemory._listOfBanks.length > 0 ? myStoreDataMemory._listOfBanks[0]['ownerAccount'] : '',
        city: myStoreDataMemory._myAddress.city || "",
        cepCity:  myStoreDataMemory._myAddress.cep || "",
        value: valor || '',
        code : myStoreDataMemory.storeName || ""
    })

  
    const [needData, setNeedData] = useState({
        value:false,
        name:false,
        city:false,
        cepCity:false,
        code:false,
    })

    useEffect(()=>{
      if(myStoreDataMemory._listOfBanks.length > 0)
        handleChangeBack(JSON.stringify(myStoreDataMemory._listOfBanks[0]))
    },[])


    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const copy = async()=>{
        await navigator.clipboard.writeText(fullPIX)
        setVisible(true)
    }

    const handleChangeBack = async (e) => {
      const values = JSON.parse(e)
      setFormData(prevFormData => ({ 
        ...prevFormData, 
        pixValue: values.keycodePix, 
        name: values.ownerAccount 
      }));
    }

    const createPxi = () =>{

      let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setAlertError(true)
            return
        }

      setConfigured(true)
    }

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
          <TitlePageResponsiveIcon
            icon={faPix}
            title={'PIX DE PAGAMENTO'}
            path={"Leia o Qrcode ou Compartilhe o Copia e Cola"}
            children={<GetButtonIcon icon={faClose} handleFunction={onClose} color={"#ff0000"} />}
          />
          { showNeedBank ?
            <>
            {isConfigured? 
                <>
                  <div className={style.pix}>
                    <Space top={25}/>
                    <PIX
                        pixkey= {formData.pixValue}
                        merchant= {formData.name}
                        city= {formData.city}
                        cep= {formData.cepCity}
                        variant="fluid"
                        padding={30}
                        color="#357"
                        bgColor="#def"
                        bgRounded
                        divider
                        code={formData.code}
                        size={window.innerWidth > 1200? 500 : 250}
                        amount={ parseFloat(formData.value) }
                        onLoad={ payload => setFullPIX(payload) }
                    />
                    <Space top={25}/>
                    <GetButton handleFunction={copy} title={'COPIAR PIX PARA COMPARTILHAR'} icon={faCopy} color={"#ff0000"}/>
                  </div>
                </>
              :
              <>
                <div className={style.pix}>
                  <div className={style.pix}>
                    <TitlePageResponsiveIcon children={
                      <BacksDropDown handlerFuncion={(e)=>handleChangeBack(e.target.value)} banks={myStoreDataMemory._listOfBanks}/>
                    } icon={faPersonBurst} title={"NOME DO TITULAR DA CONTA"} path={formData.name} />
                    <GetSmallModels icon={faKey} title={"CHAVE PIX"} subtitle={formData.pixValue} />
                  </div>
                  <InputModelsBlock
                    validation={needData.value}
                    legend={'VALOR DA COBRANÇA'}
                    onChange={handleChange} maxLength={10} 
                    value={formData.value} name={'value'} iconButton={faMoneyBill} type={"number"} 
                    placeholder={"Valor da Cobrança Pix..."}/>
                    <Space top={5}/>
                    <InputModelsBlock
                    validation={needData.city}
                    legend={'CIDADE DO COBRADOR'}
                    onChange={handleChange} maxLength={25} 
                    value={formData.city} name={'city'} iconButton={faCity} type={"text"} 
                    placeholder={"Cidade..."}/>
                    <Space top={5}/>
                    <InputModelsBlock
                    validation={needData.cepCity}
                    legend={'CEP DO COBRADOR'}
                    onChange={handleChange} maxLength={15} 
                    value={formData.cepCity} name={'cepCity'} iconButton={faAddressBook} type={"number"}  
                    placeholder={"Cep..."}/>
                    <Space top={5}/>
                    <InputModelsBlock
                    validation={needData.code}
                    legend={'CÓDIGO DE IDENTIFICAÇÃO DO PIX'}
                    onChange={handleChange} maxLength={50} 
                    value={formData.code} name={'code'} iconButton={faAddressBook} type={"text"}  
                    placeholder={"Identificador..."}/>
                  <Space top={5}/>
                  <GetButton handleFunction={createPxi} title={'GERAR PIX DE PAGAMENTO'} icon={faCopy} color={"#005500"}/>
                </div>
              </>
              }
              </>:<GetSmallModels title={"NÃO HÁ BANCOS CADASTRADOS"} backgroundColor={"#ff0000"} icon={faWarning} subtitle={"Cadastre um Novo Banco em MINHA LOJA"}/>}
        </div>
        {isVisible? <Alert  message={"Pix Copiado com Sucesso"} 
            top={'2%'}
            right={'2%'} type={'success'} />:null}
        {alertError? <Alert  message={"Complete os Campos em Vermelho"} 
            top={'2%'}
            right={'2%'} type={'error'} />:null}
      </div>
    );
  };

export default GetPix
