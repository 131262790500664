import AccountingObjectPart from "./AccountingObjectPart"

class AccountingDataSales {
    constructor(){
        this.itemsNormalStockSales = new AccountingObjectPart()
        this.itemsStockGenericProducts = new AccountingObjectPart()
        this.itemsCancelAllSales = new AccountingObjectPart()
        this.itemsPresent = new AccountingObjectPart()

        this.allMoney = 0
        this.allSales = 0
        this.allItems = 0
        this.allGrams = 0
    }

  getAllMoney() {
    return this.allMoney.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  insertValuesFromSale(sale){
    if(sale.detailsSale.isPresent){
        this.itemsPresent.amountItems += sale.totalAmountItems
        this.itemsPresent.grams += sale.totalFactorItems
        this.itemsPresent.valueMoney += sale.totalValue
        this.itemsPresent.totalSaleCount++
        return
    }


    this.itemsNormalStockSales.amountItems += sale.totalAmountItems
    this.itemsNormalStockSales.grams +=sale.totalFactorItems
    this.itemsNormalStockSales.valueMoney +=sale.totalValue
    this.itemsNormalStockSales.totalSaleCount++

    if(sale.listGenericProduct.genericProducts.length >0){
        this.itemsStockGenericProducts.amountItems += sale.listGenericProduct.totalCountGenericProducts
        this.itemsStockGenericProducts.valueMoney += sale.listGenericProduct.totalMoneyInGenericProducts
        this.itemsStockGenericProducts.totalSaleCount++
    }

    this.allSales++
    this.allMoney += sale.totalValue 
    this.allGrams += sale.totalFactorItems
    this.allItems += sale.totalAmountItems
  }

  insertValuesCancelAllDevolutionFromSale(saleDevolution){
    this.itemsCancelAllSales.amountItems += saleDevolution.totalAmountItems
    this.itemsCancelAllSales.grams += saleDevolution.totalFactorItems
    this.itemsCancelAllSales.valueMoney += saleDevolution.totalValueIncludeAll === 0? saleDevolution.totalValue : saleDevolution.totalValueIncludeAll
    this.itemsCancelAllSales.totalSaleCount++
  }

  clearData(){
    this.allMoney = 0
    this.allSales = 0
    this.allItems = 0
    this.allGrams = 0

    this.itemsPresent.clearData()
    this.itemsCancelAllSales.clearData()
    this.itemsNormalStockSales.clearData()
    this.itemsStockGenericProducts.clearData()
  }
}

export default AccountingDataSales