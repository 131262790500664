import React,{useState} from "react";
import style from "../style/ItemModelInsert.module.css"
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { auxSale } from "../backEnd/Memory";
import Space from "../layout/Space";

function ItemModelInsert({data,onInsert,functionImage,showValueSale}) {
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    
    const handleImageLoad = () => {
        setImageLoaded(true) 
    }

    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }
 
    return(
        <div className={style.containerWrap}>
            <div className={style.containerWrap1}>
                <div className={style.imageContainer}>
                            {errorOccurred ? (
                                <img
                                src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                                alt="Erro de carregamento"
                                className={style.loaded}
                                />
                            ) : (
                            <img src={data.getImage()} alt={data.itemGlobal.name} onLoad={handleImageLoad}
                                onError={handleImageError} onClick={functionImage}
                                className={!imageLoaded ? style.loading : style.loaded}/> )}
                </div> 
                <div className={style.container}>
                    <div className={style.dataValues}>
                        <div className={style.containerAmount}>
                            <p className={`${style.amountHas} ${data.amount > 0 ? '' : style.noHas}`}>
                                {data.amount}
                            </p>
                        </div>
                        {window.innerWidth >  1200 ? 
                        <div className={style.containerNames}>
                            <div>
                                <h3>{data.itemGlobal.name}</h3>
                               <div className={style.nameRow}>
                                 <h4>{data.itemGlobal.codebar}</h4>
                                 <h6>{data.itemGlobal.factorItem}</h6>
                               </div>
                            </div>
                            {showValueSale?<div className={style.valueSale}>
                                 {data.getValueSaleToString(auxSale.factor)}
                            </div>: null}
                            <button className={style.butAdd}  onClick={onInsert}>
                                <FontAwesomeIcon className={style.icon} icon={faAdd}/>
                            </button>
                        </div>:
                        <div className={style.containerNames}>
                            <div>
                                <h3>{data.itemGlobal.name}</h3>
                                <h4>{data.itemGlobal.codebar}</h4>
                                <Space top={5}/>
                                 {showValueSale?<h3>
                                   { data.getValueSaleToString(auxSale.factor)}
                                 </h3>: null}
                            </div>
                            <button className={style.butAdd}  onClick={onInsert}>
                                <FontAwesomeIcon className={style.icon} icon={faAdd}/>
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemModelInsert