import { databaseSettings, mothPay } from "../backEnd/Memory"
import { sendRequestGetGlobal, sendRequestPostLocal } from "../backEnd/requesition"

const _routerFromGetDatabaseSettingsInCloud = '/databaseSettings/getSettings'
const _routerFromSetFirstTimeDatabaseSettingsInCloud = '/databaseSettings/SetSettingsFirstTime'

const getMapFromCloud = () => {
    return{
      'serviceValue':'R$ 200,00',
      'keyCodePix': '02306261148',
      'messageMoth':'Deus é Bom!',
      'dayStartService':(new Date()).toISOString().replace('T',' ').slice(0,19),
      'versionApp': '®Mushroom Angels Corporation 2018-2024 | Version 1.0.0',
      'idApp':'BsemijoiasClientes',
    }
}

export const setSaveTheFirstTimeApp = async (showError) =>{
    try {
        var results = await sendRequestPostLocal(_routerFromSetFirstTimeDatabaseSettingsInCloud,getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {   
        showError('ERROR AO SALVAR DATABASE INICIAL NO SISTEMA',error.message)
        return {status:404}
    }
}

export const setFindFromCloud = async (showError) => {
    var results = []
    try {
        results = await sendRequestGetGlobal(_routerFromGetDatabaseSettingsInCloud)
        
        if(Array.isArray(results)){
            if(results.length > 0){
                mothPay.fromCloud(results[0])
                databaseSettings.fromCloud(results[0])
            }else{
                if(await setSaveTheFirstTimeApp(showError).status !== 200)
                    await setFindFromCloud(showError)
            }
        }else throw new Error(results.response.data.sqlMessage) 

    } catch (error) {
        showError('ERROR AO BUSCAR DADOS BASICOS DO SISTEMA',error.message)
    }
}