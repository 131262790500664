import React from "react"
import { faArrowRightToBracket, faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons"
import style from '../style/GetCommissions.module.css'
import { TitlePageResponsiveIcon } from "../layout/TitlePage"
import { GetButton, GetButtonIcon } from "../layout/ButtonsLayout"
import GetAmountList from "./GetAmountList"
import GetEmployee from "./GetEmployee"
import Space from "../layout/Space"

function GetCommissions({comission,handlerFunction}){
    return(
        <div className={style.divGlobal}>
            <div className={style.divRow}>
                <GetEmployee employee={comission.personOwner}/>
                <Space left={5} top={5}/>
                <TitlePageResponsiveIcon icon={faFileInvoiceDollar} title={'Comissão Total: ' + comission.getFinalValue()}
                    path={'Cálculo: (' + comission.getValueAllSales() + ' * ' + comission.personOwner.percentComission + '%) = ' + comission.getValueMoney() + ' + Comissão Fixa: ' + comission.getFixedComission()}
                />
            </div>
           
            <div className={style.div}>
                <GetAmountList
                 title={'Vendas Relizadas pelo Funcionário...'} amount={comission.amountSale}/>
                {comission.sale.map((sale,index) =>(
                    <div className={style.sale}>
                        <div>
                            <p className={style.nameSale}>{sale.getCodeSale()}</p>
                            <div className={style.divRow}>
                                <p className={style.valueSale}>{sale.getTotalValueIncludeAllToString()}</p>
                                <p className={style.dataSale}>
                                    {'Data: ' + sale.detailsSale.getDateToString()}
                                </p>
                            </div>
                        </div>
                        <div>
                             { window.innerWidth > 1200?
                                <GetButton handleFunction={()=>handlerFunction(sale)}
                                title={'VISUALIZAR VENDA'} 
                                height={50} width={175} color='#007bff' 
                                icon={faArrowRightToBracket}/>:
                                <GetButtonIcon handleFunction={()=>handlerFunction(sale)} height={50} width={50} color='#007bff' 
                                icon={faArrowRightToBracket}/>
                           }
                        </div>
                    </div>
                ))}
            </div>        
        </div>
    )
}

export default GetCommissions