import React,{useState} from "react";
import style from "../style/PersonModels.module.css"


function PersonModels({data,fucntionButton}) {
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    
    const handleImageLoad = () => {
        setImageLoaded(true); 
    }


    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }

    return(  
          <div onClick={fucntionButton} className={style.containerWrap}>
                <div className={style.imageContainer}>
                                {errorOccurred ? (
                                    <img
                                    src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                                    alt="Erro de carregamento"
                                    className={style.loaded}
                                    />
                                ) : (
                                <img src={data.linkImage} alt={data.genericPerson.name} onLoad={handleImageLoad}
                                    onError={handleImageError}
                                    className={!imageLoaded ? style.loading : style.loaded}/> )}
                </div> 
                <div className={style.containerNames}>
                    <h3>{data.genericPerson.name}</h3>
                     <h4>{data.function}</h4>
                </div>
          </div>
    )
}

export default PersonModels