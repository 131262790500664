import React from "react";
import style from "../style/GetAmountList.module.css"

function GetAmountList({title,amount}){
    return(
        <div className={style.globalData}>
           <div className={style.div}>
                <h4>{amount}</h4>
                <h6>{title}</h6>
           </div>
        </div>
    );
}

export default GetAmountList