import React from "react";
import style from "../style/GetModels.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function GetSmallModels({ title, subtitle, value, icon,color,backgroundColor,disable,children }) {

    const styleColors = {
        color:color,
        backgroundColor:backgroundColor
    }

    return (
        <div className={`${style.wrapSmall} ${style.ss}`} style={styleColors}>
            <div className={style.row}>
                {icon? <FontAwesomeIcon className={style.wrapIconSmall} icon={icon}/>:null}
               <div>
                    <div className={style.wrapTitleSmall}>
                        <h3>{title}</h3>
                        <h5>{disable? '****' : value}</h5>  
                    </div>
                    <h4>{disable? 'Você Não tem Permissão de Acesso' : subtitle}</h4>
               </div>
            </div> 
            {children? 
            <div>
                {children}
            </div>: 
            null} 
        </div>
    )
}

export { GetSmallModels }
