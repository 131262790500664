import React, {  useState } from 'react';
import style from '../../../style/AddEndViewNote.module.css'
import { faClose,  faSignature, faEquals, faSave, faFilePdf, 
  faMoneyBill1Wave,
  faScrewdriverWrench,
  faCalendar,
  faRemove,
  faTruck,
  faLock,
  faCancel,
  faEraser,
  faDriversLicense} from '@fortawesome/free-solid-svg-icons';
import { TitlePageResponsiveIcon } from '../../../layout/TitlePage';
import { GetButton, GetButtonIcon } from '../../../layout/ButtonsLayout';
import InputModelsBlock from '../../../layout/InputModelsBlock'
import Alert from '../../../Models/Alert';
import Space from '../../../layout/Space';
import ConfirmationPopup from '../../../Models/ConfirmationPopup';
import Loader from '../../../layout/Loader';
import { useError, useLoading } from '../../../AppContext';
import { Repairs } from '../../../objects/Repairs/Repairs';
import { setNewRepair, setUpdateRepair } from '../../../controllers/CloudRepairs';
import { GetCancelRepairs } from './GetCancelRepairs';
import GetPix from '../../../Models/GetPix';
import { faPix } from '@fortawesome/free-brands-svg-icons';
import { employeeData } from '../../../backEnd/InitController';
import { GetSmallModels } from '../../../Models/GetModels';
import GetEmployee from '../../../Models/GetEmployee';
import ClientsModels from '../../../Models/ClientsModels';

const getFormData = (repairs) => {
    return {
        idEmployee:repairs.idEmployee,
        idRepairs:repairs.idRepairs,
        title:repairs.title,
        internalDescription:repairs.internalDescription,
        description:repairs.description,
        value:repairs.value,
        date:repairs.date,
        dateReturned:repairs.dateReturned,

        idClient: repairs.idClient,
        status : repairs.status,
        dateCancel:repairs.dateCancel,
        idEmployeeCancel:repairs.idEmployeeCancel,
        reasonCancel:repairs.reasonCancel,
    }
}

const getFormNeed = () => {
    return {
        title:false,
        internalDescription:false,
        description:false,
        date:false,
    }
}

function AddEditeRepairs({repairs, onClose,onSave,client}) {

    const [employeeUser] = useState(employeeData())
    const [typeAlert,setTypeAlert] = useState(1)
    const [showCancel,setShowCancel] = useState(false)
    const [isVisible,setAlert] = useState(false)
    const [currentRepair] = useState(repairs?? new Repairs(client))

    const [formData, setFormData] = useState(getFormData(currentRepair))
    const [needData, setNeedData] = useState(getFormNeed(currentRepair))
    const [loading, setLoading] = useState(false)
    const [showGetPix,setShowGetPix] = useState(false)
    const [confirmationVisible, setConfirmationVisible] = useState(false)
    const [title,setTitle] = useState('')
    const [legend,setLegend] = useState('')

    const [canEditeOtherEmployeesSale] = useState(employeeUser.employeePermission.permissionsSales[3])
    const [canCancel] = useState(employeeUser.employeePermission.permissionsSales[1])
    const [canViewOtherEmployeesSale] = useState(employeeUser.employeePermission.permissionsSales[4])

    const { setIsLoading } = useLoading()
    const { showError } = useError()
 
    const handleSave = async () =>{
        setConfirmationVisible(false)
        setLoading(true)

        let result 
       
        if(currentRepair.idRepairs != 0){
            currentRepair.fromUpdate(formData)
            result = await setUpdateRepair(currentRepair,showError)
        }else{
            let repair = new Repairs()
            repair.fromAdd(formData,client.IdClient)
            result = await setNewRepair(repair,showError)
        }
            
    
        if(result.status === 200){
            onSave()
            handleClose()
        }

        setLoading(false) 
    }

    const handleClose = () => {
      onClose()
    } 

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmitSave= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setAlert(true)
            return
        }
        setTitle(currentRepair.idRepairs != 0? 'ATUALIZAR CONSERTO/REPARO' : 'SALVAR NOVO CONSERTO/REPARO')
        setLegend(currentRepair.idRepairs != 0? 'Tem Certeza que Deseja Atualizar o Conserto/Reparo' : 'Tem Certeza que Deseja Salvar Uma Novo Conserto/Reparo')
        setConfirmationVisible(true)
        setTypeAlert(0)
    }

    const handleOpenPdf = async()=>{
        setIsLoading(true)
        await currentRepair.requestPDF(showError)
        setIsLoading(false)
    }

    const handleDelivered = async()=>{
        setTitle('ENTREGAR CONSERTO/REPARO')
        setLegend('Após Entregar, Não será Possível Remover ou Cancelar, Tem Certeza?')
        setConfirmationVisible(true)
        setTypeAlert(1)
    }

    const SaveDelivered = async () => {
        setLoading(true)

        currentRepair.setDelivered()
        const result = await setUpdateRepair(currentRepair,showError)
   
        if(result.status === 200){
            onSave()
            handleClose()
        }

        setLoading(false)
    }

    const handleOnCancel= async(reason)=>{
        setLoading(true)
        setConfirmationVisible(false)
        currentRepair.setCancel(reason)
        const result = await setUpdateRepair(currentRepair,showError)
   
        if(result.status === 200){
            onSave()
            handleClose()
        }

        setLoading(false)
    }

    let disable = repairs !== null? repairs.status !== 0 : false
    if(!onSave) disable = true

  
    if(!canViewOtherEmployeesSale && repairs.tempEmployee.idEmployee !== employeeUser.idEmployee){
        return(
            <div className={style.overlay}>
                <div className={style.alertPayment}>
                    <div className={style.div}>
                        <TitlePageResponsiveIcon 
                                children={
                                    <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                                } icon={faLock} title={'ACESSO NEGADO'}
                                path={'Sem Permissão para Visualizar Conserto ou Vendas de Outro Funcionário'} />
                        </div>
                </div>
            </div>
        )
    }


    return (
      <div className={style.overlay}>
        <div className={style.alert}>
            { !loading ? <>
                <TitlePageResponsiveIcon subtitle={currentRepair.idRepairs != 0? 'EDITAR CONSERTO/REPARO' : 'ADICIONAR NOVO CONSERTO/REPARO'} 
                        children={
                            <div className={style.rowButtons}>
                                {currentRepair.idRepairs != 0? 
                                <GetButtonIcon color='#007bff' icon={faFilePdf} handleFunction={handleOpenPdf}/>  : null}
                                { currentRepair.status !== 2?
                                    <>
                                        <Space left={5}/>
                                        <GetButtonIcon color='#008000 ' icon={faPix} handleFunction={()=>setShowGetPix(true)}/> 
                                    </> : null
                                }
                                <Space left={5}/>
                                <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/>   
                            </div>
                        }
                        path={"Complete os Campos Abaixo..."} icon={faScrewdriverWrench}/>      
                <Space top={5}/>
                {
                     currentRepair.status ===2?
                    <div className={style.div}>
                        <GetSmallModels backgroundColor={'#ff0000'} icon={faCancel} subtitle={'CONSERTO CANCELADO'}/>
                        <GetEmployee employee={repairs.tempEmployee}/>
                        <Space top={5}/>
                        <GetSmallModels icon={faCalendar} title={'DATA DO CANCELAMENTO DO CONSERTO'} value={repairs.dateCancel}/>
                        <GetSmallModels icon={faEraser} title={'MOTIVO DO CANCELAMENTO'} subtitle={repairs.reasonCancel}/>
                    </div>
                    :null
                }
                <Space top={5}/>
                {
                    currentRepair.status ===1?
                    <div className={style.div}>
                        <GetSmallModels backgroundColor={'#1d7500'} icon={faDriversLicense} subtitle={'CONSERTO ENTREGUE'}/>
                        <Space top={5}/>
                        <GetSmallModels icon={faCalendar} title={'DATA DA ENTREGA DO CONSERTO'} value={repairs.dateReturned}/> 
                    </div>
                    :null
                }
                <div className={style.div}>
                        <div className={style.max}>
                            <ClientsModels data={currentRepair.tempClient} onlyBasic={false} />
                        </div>
                        <Space top={5}/>
                        { currentRepair.idRepairs != 0?<GetEmployee employee={repairs.tempEmployee}/>:null}
                        <Space top={5}/>
                        <InputModelsBlock onChange={handleChange} 
                        legend={'IDENTIFICAÇÃO DESSE CONSERTO/REPARO'}
                        validation={needData.title} 
                        maxLength={50} 
                        disable={disable}
                        value={formData.title} 
                        name={'title'} 
                        iconButton={faSignature}
                        type={"text"} 
                        placeholder={"Identificação..."}/>
                        <Space top={5}/>
                        <InputModelsBlock 
                        disable={disable}
                        legend={'DETALHES O CONSERTO'}
                        onChange={handleChange} 
                        validation={needData.description}
                        maxLength={200} 
                        value={formData.description} 
                        name={'description'} 
                        iconButton={faEquals}
                        type={"text"} placeholder={"Detalhes do Conserto..."}/>  
                         <Space top={5}/>
                        <InputModelsBlock 
                        disable={disable}
                        legend={'DETALHES INTERNOS'}
                        onChange={handleChange} 
                        validation={needData.internalDescription}
                        maxLength={200} 
                        value={formData.internalDescription} 
                        name={'internalDescription'} 
                        iconButton={faEquals}
                        type={"text"} placeholder={"Detalhes Privados..."}/>  
                        <Space top={5}/>
                        <InputModelsBlock 
                        disable={disable}
                        legend={'DATA DE REGISTRO'}
                        onChange={handleChange} 
                        validation={needData.date}
                        maxLength={10} 
                        value={formData.date} 
                        name={'date'} 
                        iconButton={faCalendar}
                        type={"date"} placeholder={"Data de Registro..."}/>  
                        <Space top={5}/>
                        <InputModelsBlock 
                        disable={disable}
                        legend={'VALOR DESSE CONSERTO/REPARO'}
                        onChange={handleChange} 
                        maxLength={10} 
                        value={formData.value} 
                        name={'value'} 
                        iconButton={faMoneyBill1Wave}
                        type={"number"} placeholder={"Valor do Conserto/Reparo..."}/>  
                </div>            
                <Space top={5}/>
                {   
                    currentRepair.status === 0 && onSave && canEditeOtherEmployeesSale ?  
                    <div className={style.div}>
                        <GetButton title={'SALVAR CONSERTO/REPARO'} color='#007bff' icon={faSave} handleFunction={handleSubmitSave}/> 
                        {
                            currentRepair.idRepairs !== 0 &&  currentRepair.status !== 2 ?
                            <>
                                <Space top={5}/>
                                <GetButton title={'ENTREGAR CONSERTO PARA CLIENTE'} color='#026300' icon={faTruck} handleFunction={handleDelivered}/> 
                                {canCancel?
                                    <>   
                                        <Space top={5}/>
                                        <GetButton title={'CANCELAR CONSERTO/REPARO'} color='#FF0000' icon={faRemove} handleFunction={()=>setShowCancel(true)}/> 
                                    </> : null
                                }


                            </>:null
                        }
                    </div>  : null
                }  
           </>:  
           <Loader titleFinding={'Salvando Dados no Servidor...'}
            subtitleFinding={'Aguarde...'} loading={loading} />}
           {confirmationVisible? 
           <ConfirmationPopup title={title} 
            message={legend} onCancel={()=>setConfirmationVisible(false)} 
            onConfirm={typeAlert ===0?handleSave:SaveDelivered}/>: 
            null}
            {
                showCancel?
                    <GetCancelRepairs onCancel={handleOnCancel} onClose={()=>setShowCancel(false)}/>
                :null
            }
           {showGetPix ? <GetPix valor={formData.value} onClose={()=>setShowGetPix(false)}/> : null}
           {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
      </div>
    )
}

export default AddEditeRepairs
