import { listEmployeeMemory } from "../backEnd/Memory"
import { requestPDFs } from "../controllers/CloudPdfs"
import addressObject from "./ClientsAndPerson/addressObject"
import bankObject from "./ClientsAndPerson/bankObject"
import companyObject from "./ClientsAndPerson/companyObject"
import SocialMediaObject from "./SocialMediaObject"

class MyStoreObject{
    constructor(){
        this.idStoreSettings = 0
        this.iconLink = ''
        this.storeName = ''
        this.storeSortDescription = ''
        this.storeLargeDescription = ''
        this.profitFactor = 220
        this.purchasingFactor = 106
        this._myAddress = new addressObject()
        this._myCompany = new companyObject()
        this._socialMedia = new SocialMediaObject()

        this._listOfBanks = [] //bank
        this._emailList = [] //string
        this._phoneNumberList= [] //string    

    }

    

    async openPDFContacts(showError){
      const body = {
        'type':'pdfListContacts',
        'myStore': this.getMapFromCloud()
      }
      await requestPDFs(body,showError)
    }

    async openPDFBanks(showError){
      const body = {
        'type':'pdfListBanks',
        'myStore': this.getMapFromCloud()
      }
      await requestPDFs(body,showError)
    }

    async openPDFAdress(showError){
      const body = {
        'type':'pdfaddressStore',
        'myStore': this.getMapFromCloud()
      }
      await requestPDFs(body,showError)
    }
    
    async openPDFDataStore(showError){
      const body = {
        'type':'pdfDataStore',
        'myStore': this.getMapFromCloud()
      }
      await requestPDFs(body,showError)
    }

    async openPDFLogoStore(showError){
      const body = {
        'type':'pdfLogoStore',
        'myStore': this.getMapFromCloud()
      }
      await requestPDFs(body,showError)
    }

    async openPDFCard(showError){
      const body = {
        'type':'pdfCard',
        'myStore': this.getMapFromCloud()
      }
      await requestPDFs(body,showError)
    }

    async openPDFEmployees(showError){
      const map = {}

      listEmployeeMemory.forEach(ss=>{
        map[ss.idEmployee.toString()] = ss.getMapFromCloud()
      })
      const body = {
        'type':'pdfListExployees',
        'listEmployees':map, 
        'myStore': this.getMapFromCloud()
      }
      await requestPDFs(body,showError)
    }

    fromCloud(dataFromCloud){
      var data = dataFromCloud['StoreSettings'][0]
      this.idStoreSettings = data.idStoreSettings
      this.storeName=  data.storeName
      this.storeSortDescription= data.storeSortDescription
      this.storeLargeDescription= data.storeLargeDescription
      this.iconLink =  data.iconLink
      this.profitFactor =  data.profitFactor
      this.purchasingFactor = data.purchasingFactor
  
      if (dataFromCloud['Emails'] != null) {
        for (var emailData of dataFromCloud['Emails']) {
          this._emailList.push(emailData.email.toString())
        }
      }

      if (dataFromCloud['PhoneNumbers'] != null) {
        for (var phoneNumberData of dataFromCloud['PhoneNumbers']) {
            this._phoneNumberList.push(phoneNumberData['phone'])
        }
      }
  
     
      if (dataFromCloud['Banks'] != null) {
        for (var bankData of dataFromCloud['Banks']) {
          var bank = new bankObject()
          bank.fromCloud(bankData)
          this._listOfBanks.push(bank)
        }
      }
      
      this._myAddress = new addressObject()
      this._myAddress.fromCloud(data)

      this._socialMedia = new SocialMediaObject()
      this._socialMedia.fromCloud(data)

      this._myCompany = new companyObject()
      this._myCompany.fromCloud(data)
    }
  
    fromEdite(data,listBanks,listPhone,listEmail){
      this.idStoreSettings = data.idStoreSettings
      this.storeName=  data.storeName
      this.storeSortDescription= data.storeSortDescription
      this.storeLargeDescription= data.storeLargeDescription
      this.iconLink =  data.iconLink
      this.profitFactor =  data.profitFactor
      this.purchasingFactor = data.purchasingFactor

      this._emailList = listEmail
      this._phoneNumberList = listPhone
      this._listOfBanks = listBanks

      this._myAddress = new addressObject()
      this._myAddress.fromCloud(data)

      this._socialMedia = new SocialMediaObject()
      this._socialMedia.fromCloud(data)

      this._myCompany = new companyObject()
      this._myCompany.fromCloud(data)
    }

    getBanksToSaveFirebase(){
        var temp = {}
        var intCount  = 0
        for (var element of this._listOfBanks) {          
            temp[element._idBank + intCount.toString()] = element.getMapFromCloud()
            intCount++
        }
        return temp
    }
  
  
    getMapFromCloud(){
      return{
        'idStoreSettings':this.idStoreSettings,
        'iconLink': this.iconLink,
        'storeName': this.storeName.toUpperCase(),
        'storeSortDescription': this.storeSortDescription.toUpperCase(),
        'storeLargeDescription': this.storeLargeDescription.toUpperCase(),
        'container-bankList': this.getBanksToSaveFirebase(),
        'container-emailList': JSON.stringify(this._emailList),
        'container-numberList': JSON.stringify(this._phoneNumberList),
        'container-address':this._myAddress.getMapFromCloud(),
        'container-company':this._myCompany.getMapFromCloud(),
        'container-socialMedia':this._socialMedia.getMapFromCloud(),
        'profitFactor': this.profitFactor,
        'purchasingFactor': this.purchasingFactor,
      }
    }
}

export default MyStoreObject
  
  