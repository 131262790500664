import React, { useState }  from "react"
import { faBullhorn, faCalendar, faCheck, faClose, faDatabase, faEdit, faKey, faMessage, faMoneyBill1Wave, faPhone, faSignature, faViruses } from "@fortawesome/free-solid-svg-icons"
import { Users } from "../../objects/Admin/Users.js"
import { useError } from "../../AppContext.js"
import { TitlePageResponsiveIcon } from "../../layout/TitlePage.js"
import { GetButton, GetButtonIcon } from "../../layout/ButtonsLayout.js"
import InputModelsBlock from "../../layout/InputModelsBlock.js"
import Space from "../../layout/Space.js"
import style from '../../style/GetEditeAndAddUsers.module.css'
import { faPix } from "@fortawesome/free-brands-svg-icons"
import Alert from "../../Models/Alert.js"
import { setUpdateUser } from "../../controllers/CloudFromUsers.js"
import Loader from "../../layout/Loader.js"
import { ToggleButtonWithContainer } from "../../layout/ToggleButton.js"

const getFormData = (user) => {
    return {
        'idUsers':user.idUsers,
        'emailLogin':user.emailLogin,
        'databaseName':user.databaseName,
        'name':user.name,
        'number':user.number,
        'isAccess':user.isAcess,
        'password':'',

        'idDatabaseSettings':user.databaseSettings.idDatabaseSettings,
        'serviceValue':user.databaseSettings.serviceValue.toString(),
        'keyCodePix':user.databaseSettings.keyCodePix,
        'messageMoth':user.databaseSettings.messageMoth,
        'dayStartService':user.databaseSettings.dayStartService.toISOString().slice(0,10),
        'versionApp':user.databaseSettings.versionApp,
        'idApp':user.databaseSettings.idApp,
    }
}

const getFormNeed = () => {
    return {
        'emailLogin':false,
        'databaseName':false,
        'name':false,
        'number':false,
        'isAccess':false,

        'serviceValue':false,
        'keyCodePix':false,
        'messageMoth':false,
        'dayStartService':false,
        'versionApp':false,
    }
}

function GetEditeAndAddUsers({onClose,user,onSave}){

    const [userTemp] = useState(user?? new Users())
    const [isVisible,setAlert] = useState(false)
    const [valueAlert,setValueAlert] = useState(false)
    const [toggleStatus, setToggleStatus] = useState(user.isAccess )
    const [toggleBrunaSemijoias, settoggleBrunaSemijoias] = useState(user.databaseSettings.isCanUseBrunaBJ)
    const [formData, setFormData] = useState(getFormData(userTemp))
    const [needData, setNeedData] = useState(getFormNeed)

    const { showError } = useError()
    const [loading,setLoading] = useState(false)

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }       
    }

    const handleSubmitSave= async() => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setValueAlert("Complete Todos os Campos em Vermelho")
            setAlert(true)
            return
        }

        handleSave()
    }

    const handleToggleChangeStatus = (newToggleStatus) => {
        setToggleStatus(newToggleStatus)
    }

    const handleToggleChangeStatusBruna = (newToggleStatus) => {
        settoggleBrunaSemijoias(newToggleStatus)
    }
  
    const handleSave = async()=>{
        setLoading(true)
        if(userTemp.idUsers === -1){
            
        }else{   
          
            userTemp.fromUpdate(formData,toggleStatus,toggleBrunaSemijoias)
            const results =  await setUpdateUser(userTemp,showError)
            if(results.status === 200){
                onSave()   
                onClose()        
            }
        }
        setLoading(false)
    }

    return(
        <div className={style.overlay}>
        <div className={style.alert}>
        {!loading? <><div className={style.title}>
                    <TitlePageResponsiveIcon title={userTemp.idUsers === -1 ? 'CADASTRAR USÚARIO NO SISTEMA' : 'EDITAR USÚARIO DO SISTEMA' } 
                    children={
                        <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={onClose}/> 
                    }
                    path={"Configure os Valores Abaixo..."} icon={faEdit}/>                    
            </div>
            <div className={style.div}>
                    <ToggleButtonWithContainer titleOn={'DESBLOQUEADO'} titleOff={'BLOQUEADO'}
                    initState={toggleStatus} onChange={handleToggleChangeStatus} />
                    <Space top={5}/>
                    <ToggleButtonWithContainer titleOn={'USAR BRUNA'} titleOff={'NÃO USAR BRUNA'}
                    initState={toggleBrunaSemijoias} onChange={handleToggleChangeStatusBruna} />
                    <Space top={5}/>
                    <InputModelsBlock legend={'NOME DO USÚARIO'} 
                    onChange={handleChange} validation={needData.name} maxLength={90}
                    value={formData.name}
                    name={'name'} iconButton={faSignature} type={"text"} placeholder={"Nome do Usúario do Sistema..."}/>
                    <Space top={5}/>
                    <InputModelsBlock legend={'DATABASE'} 
                    onChange={handleChange} validation={needData.databaseName} maxLength={45}
                    value={formData.databaseName}
                    name={'databaseName'} iconButton={faDatabase} type={"databaseName"} placeholder={"Nome do Banco de Dados..."}/>
                    <Space top={5}/>
                    <InputModelsBlock legend={'EMAIL DE LOGIN'} 
                    onChange={handleChange} validation={needData.emailLogin} maxLength={100}
                    value={formData.emailLogin}
                    name={'emailLogin'} iconButton={faMessage} type={"email"} placeholder={"Email de Login..."}/>
                     <Space top={5}/>
                    {userTemp.idUsers === -1 ?<> 
                    <InputModelsBlock legend={'SENHA'} 
                    onChange={handleChange} validation={needData.password} maxLength={45}
                    value={formData.password}
                    name={'password'} iconButton={faKey} type={"text"} placeholder={"Senha do Sistema..."}/>
                    <Space top={5}/> </>: null}
                    <InputModelsBlock legend={'NÚMERO DE CELULAR'} 
                    onChange={handleChange} validation={needData.number} maxLength={100}
                    value={formData.number}
                    name={'number'} iconButton={faPhone} type={"text"} placeholder={"Telefone..."}/>
                     <Space top={5}/>
                    <InputModelsBlock legend={'DATA DO ÚLTIMO PAGAMENTO'} 
                    onChange={handleChange} validation={needData.dayStartService} maxLength={10}
                    value={formData.dayStartService}
                    name={'dayStartService'} iconButton={faCalendar} type={"date"} placeholder={"Data do Último Pagamento..."}/>
                     <Space top={5}/>
                    <InputModelsBlock legend={'VALOR DO SISTEMA'} 
                    onChange={handleChange} validation={needData.serviceValue} maxLength={45}
                    value={formData.serviceValue}
                    name={'serviceValue'} iconButton={faMoneyBill1Wave} type={"number"} placeholder={"Valor do Sistema..."}/>
                     <Space top={5}/>
                    <InputModelsBlock legend={'CHAVE PIX'} 
                    onChange={handleChange} validation={needData.keyCodePix} maxLength={45}
                    value={formData.keyCodePix}
                    name={'keyCodePix'} iconButton={faPix} type={"number"} placeholder={"Chave Pix..."}/>
                     <Space top={5}/>
                     <InputModelsBlock legend={'MENSAGEM'} 
                    onChange={handleChange} validation={needData.messageMoth} maxLength={200}
                    value={formData.messageMoth}
                    name={'messageMoth'} iconButton={faBullhorn} type={"text"} placeholder={"Mensagem..."}/>
                     <Space top={5}/>
                    <InputModelsBlock legend={'VERSÃO DO APLICATIVO'} 
                    onChange={handleChange} validation={needData.versionApp} maxLength={90}
                    value={formData.versionApp}
                    name={'versionApp'} iconButton={faViruses} type={"text"} placeholder={"Versão do Aplicativo..."}/>
            </div>
            <Space top={5}/>
            <div className={style.div}>
                <GetButton title={'SALVAR'} color='#007bff' icon={faCheck} handleFunction={handleSubmitSave}/> 
            </div>
            </>:<div className={style.div}>
            <Loader titleFinding={'Salvando...'}
            subtitleFinding={'Aguarde...'} loading={loading} />
            </div>}
           {isVisible? <Alert message={valueAlert} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div> 
      </div>
    )
}

export default GetEditeAndAddUsers