import React from "react";
import style from "../style/Error404.module.css"
import { NavBarGlobal } from "./NavbarGlobal";
import { faFaceDizzy } from "@fortawesome/free-solid-svg-icons";
import TitlePage, { TitlePageResponsiveIcon } from "../layout/TitlePage";

function Error404(){
    return(
     <div>
        <TitlePage icon={faFaceDizzy} title={'Erro'} subtitle={"404"}
            path={"Essa Páginal Não Existe..."}
        />  
       <NavBarGlobal listObjectNavBarMenu={[]}/>
     </div>
    )
}

export function Error404NotLogged(){
    return(
        <div className={style.containerGlobalNotLogged}>
            <div className={style.menuErrorNotLogged}>
                <TitlePageResponsiveIcon icon={faFaceDizzy} title={'Páginal/'} subtitle={"Não Existe"}
                path={"O Redirecionamento Para Essa Páginal Não Existe, Tente Outros..."}
                /> 
            </div>
        </div>
    )
}
export default Error404