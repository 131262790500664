import React from "react";

function Space({right, left, top,bottom}){

    const styleButton={
        marginRight: right,
        marginLeft: left,
        marginTop: top,
        marginBottom: bottom,
    }

    return(
        <div style={styleButton}></div>
    )
}

export default Space