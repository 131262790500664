import React,{useEffect, useRef, useState}from "react";
import style from "../style/GetClientList.module.css"
import { faCircleInfo, faClose, faMagnifyingGlass, faPlus, faRemove, faUser } from "@fortawesome/free-solid-svg-icons";
import { GetSmallModels } from "../Models/GetModels";
import { TitlePageResponsiveIcon } from "../layout/TitlePage";
import GetAmountList from "../Models/GetAmountList";
import { GetButtonIcon } from "../layout/ButtonsLayout";
import Space from "../layout/Space";
import { alertsNotices } from "../backEnd/Memory";

function GetNoticeBell({onClose}){

    const handleClose = () => {
        onClose()
    } 

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
            <div className={style.divList}>
              <TitlePageResponsiveIcon children={
                <GetButtonIcon handleFunction={handleClose} icon={faClose} color='red'/>
              } title={'AVISOS E NOTIFICAÇÕES'} 
              path={'Alertas Avisos ou Notificações do Servidor'}/>
              <div className={style.buildList}>
                {(alertsNotices.length > 0) ? (<div className={style.buildList}>
                 <GetAmountList amount={alertsNotices.length} title={"Alertas..."}/>
                </div>): null}
                <Space right={5} left={5} top={5} bottom={5}/>
                <div className={style.build}>
                  {(alertsNotices.length === 0)?
                  <GetSmallModels title={"Não há Alertas ou Avisos"}
                  subtitle={"Tudo Certo no Sistema..."} icon={faCircleInfo} />:
                  alertsNotices.map((data, index) => (
                    <GetClient client={data}/>
                    ))}
                </div>   
              </div>
            </div>
        </div>
      </div>
    )
}

function GetClient({client,onSelect}){
    return(
        <div className={style.divClient}>
            <GetSmallModels icon={faUser} subtitle={client._genericPerson.cpf} title={client._genericPerson.name} value={window.innerWidth <= 1200 ? '': client.isOpenSales ? "DEVENDO":"SEM DIVIDAS"}/>
            <Space left={5}/>
            <div className={style.buttonAdd}>
                <GetButtonIcon width={45} handleFunction={onSelect} height={45} icon={faPlus} color={'#0164C6'}/>
            </div>
        </div>
    )
}


export default GetNoticeBell