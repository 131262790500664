import axios from 'axios'
import { jwtDecode } from 'jwt-decode'

const headersGlobal = {
    'Content-Type': 'application/json',
    'keyapp': 'API-BSEMIJOIAS-WEB'
}

const getUrlFromServer = 'https://www.bsemijoias.com:3001'

const getTokenDecoded = (tokenFromMemory) =>{
    let decodedToken = null
    const token = localStorage.getItem(tokenFromMemory)
    if(token){
        decodedToken = jwtDecode(token)
        decodedToken['security_token'] = localStorage.getItem('tokenLogins')
        decodedToken['security_token_employee'] = localStorage.getItem('tokenUser')
        decodedToken['security_token_admin'] = localStorage.getItem('tokenAdmin')
    }

    return decodedToken
}

export const sendRequestGetGlobal = async (url) => {
    try {
        const result = await axios.get(getUrlFromServer + url, {
             headers: getTokenDecoded('tokenGlobal') ?? headersGlobal,
             timeout: 100000      
        })

        if (result.status === 200) {
            return result.data
        } else {
            return 'Erro'
        }
    } catch (error) {
        return error
    }
}

export const sendRequestPostGlobal = async (url,body) => {
    try {
        const result = await axios.post(getUrlFromServer + url,body, {
             headers: getTokenDecoded('tokenGlobal') ?? headersGlobal,
             timeout: 100000      
        })

        if (result.status === 200) {
            return result
        } else {
            return 'Erro'
        }
    } catch (error) {
        return error
    }
}

export const sendRequestGetLocal = async (url,body) => {
    try {   
        const result = await axios.get(getUrlFromServer + url, { 
            headers: getTokenDecoded('tokenHeader')  ?? headersGlobal,
            body: body,
            timeout: 100000
        })
        if (result.status === 200) {
            return result.data
        } else {
            return 'Erro'
        }
    } catch (error) {
        return error
    }
}

export const sendRequestPostLocal = async (url, body) => {
    try {
        const result = await axios.post(getUrlFromServer + url,body, { 
            headers: getTokenDecoded('tokenHeader') ?? headersGlobal,
            timeout: 100000,
        })
        if (result.status === 200) {
            return result
        } else {
            return result
        }
    } catch (error) {
        return error
    }
}

export const sendRequestPostLocalImage = async (url, body) => {
    try {
        const result = await axios.post(getUrlFromServer + url,body, { 
            headers: getTokenDecoded('tokenHeaderImage'),
            timeout: 100000
        })

    
        if (result.status === 200) {
            return result
        } else {
            return result
        }
    } catch (error) {
        return error
    }
}