import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import style from "../style/ButtonsLayout.module.css"
import Space from "./Space";

export function GetButton({title,icon,handleFunction,color,width,height,responsive}){

    if(window.innerWidth < 1200 && responsive){
        return <GetButtonIcon icon={icon} handleFunction={handleFunction} color={color} width={35} height={35}/>
    }

    const buttonStyle = {
        backgroundColor: color,
        width:width,
        height:height??width
    }

    return(
        <button className={`${style.butWithLegend} ${style.iconButton}`} style={buttonStyle} onClick={handleFunction}>
            <h6 className={style.title}>{title}</h6>
            <Space left={20}/>
            {icon? <FontAwesomeIcon className={style.icon} icon={icon} />:null}
        </button>
    )
}

export function GetButtonIcon({icon,handleFunction,color,width,height}){

    const widthUse = width?? window.innerWidth > 1200? width : 35
    const heightUse = height?? window.innerWidth > 1200? height : 35

    const buttonStyle = {
        backgroundColor: color,
        width:widthUse,
        height:heightUse
    }

    return(
        <button className={`${style.but} ${style.iconButton}`} style={buttonStyle} onClick={handleFunction}>
            {icon? <FontAwesomeIcon className={style.icon}  icon={icon} />:null}
        </button>
    )
}
