import { employeeData } from "../../backEnd/InitController"

export class FinancialObject{
    constructor(){
        this.name = ''
        this.idFinancial=''
        this.description = ''
        this.dateRegister = new Date()
        this.date = new Date()
        this.situation ='EM ABERTO' //EM ABERTO FECHADO PROBLEMA
        this.typePayment = 'BOLETO' //BOLETO CHEQUE DINHEIRO CARTÃO
        this.category = 'DEBITOS' //DEBITO  CREDITO
        this.value =0
        this.idEmployee = employeeData().idEmployee
    }
    
    getValue(){
        return this.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    fromNew(data){
        this.idEmployee = employeeData().idEmployee
        this.name = data.name
        this.idFinancial= 0
        this.description = data.description
        this.dateRegister = new Date()
        this.date = new Date(data.date)
        this.situation = data.situation
        this.typePayment = data.typePayment
        this.category = data.category
        this.value = parseFloat(data.value)
    }

    fromUpdate(data){
        this.idEmployee = employeeData().idEmployee
        this.name = data.name
        this.description = data.description
        this.dateRegister = new Date()
        this.date =  new Date(data.date)
        this.situation = data.situation
        this.typePayment = data.typePayment
        this.category = data.category
        this.value = parseFloat(data.value)
    }

    
    fromCloud(data){
        this.idEmployee = data.idEmployee
        this.name = data.name
        this.idFinancial= data.idFinancial
        this.description = data.description
        this.dateRegister = new Date(data.dateRegister)
        this.date =  new Date(data.date)
        this.situation = data.situation
        this.typePayment = data.typePayment
        this.category = data.category
        this.value = parseFloat(data.value)
    }

    getDateRegister(){
        return this.dateRegister.toISOString().slice(0,10)
    }

    getDateFormatEdite(){
        return this.date.toISOString().slice(0,10)
    }

    getDate(){
        return this.date.toISOString().slice(0,10)
    }

    getMapFromCloud(){
        return{
          'value':this.value,
          'idEmployee': this.idEmployee,
          'name': this.name,
          'idFinancial':this.idFinancial,
          'description':this.description.toUpperCase(),
          'dateRegister': this.dateRegister.toISOString().slice(0,10),
          'date': this.date.toISOString().slice(0,10),
          'situation':this.situation.toUpperCase(),
          'typePayment':this.typePayment.toUpperCase(),
          'category':this.category.toUpperCase(),
        }
      }
}