import {sendRequestGetGlobal, sendRequestGetLocal, sendRequestPostLocal} from '../backEnd/requesition.js'
import ItemsSale from '../objects/ItemBJ/ItemsSale.js'
import ItemGlobal from '../objects/ItemBJ/ItemGlobal.js'
import VersionProduct from '../objects/VersionProduct.js'
import { findBjItems } from '../backEnd/Memory.js'
import { getMotion } from './CloudRepositions.js'

const _routerGetListItemsGlobalInCloud ='/items/global'
const _routerGetListItemsBjInCloud ='/items/local/getItems'
const _routerFromUpdateLocalItems = '/items/local/setEditeItems'
const _routerUpdateItemBachInCloud = '/items/local/updateItemsBatch'
const _routerFromAddLocalItems = '/items/local/addItems';

export const getListItemsBJ = async (showError) => {
    try {
        var results = []
        var resultsGlobal = []
        resultsGlobal = await sendRequestGetGlobal(_routerGetListItemsGlobalInCloud)

        if(Array.isArray(resultsGlobal)){
            results = await sendRequestGetLocal(_routerGetListItemsBjInCloud)
        }
        
        const itemBj = []
        const globalItems = []
        if(Array.isArray(resultsGlobal) && Array.isArray(results)){
            Object.values(resultsGlobal).forEach(globalData =>{   
                const itemGlobal = new ItemGlobal()
                itemGlobal.fromData(globalData) 
                globalItems.push(itemGlobal)
                Object.values(results).forEach(itemDataLocal =>{   
                    if(itemGlobal.idGlobalItemBrunaSemijoias === itemDataLocal.idGlobal){
                        const item =new ItemsSale()
                        item.fromCloud(itemDataLocal,itemGlobal)
                        itemBj.push(item)
                    }
                })
            })

        }else throw new Error(results.response.data.sqlMessage) 

        await insertItemsNoHaveInLocalDataBase(globalItems,itemBj,showError)
        return itemBj
    } catch (error) {
        showError('ERROR AO BUSCAR PEÇAS DA BRUNA SEMIJOIAS',error.message)     
        return []
    }
}

export const setUpdateItem = async (itemBj,showError) => {
   
    try { 
        var results = []
        itemBj.versionProduct = new VersionProduct()
        itemBj.versionProduct.configureNewVersion('Essa Peça foi Editada Individualmente')

        results = await sendRequestPostLocal(_routerFromUpdateLocalItems,itemBj.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage) 
        else return results
    } catch (error) {
        showError('ERRO AO SALVAR EDIÇÃO DESSA PEÇA',error.message)     
        return {status:404}
    }
}

export const getMapFromList = (listItem,phrase) => {
    let map = {}
        listItem.forEach(item =>{
            item.versionProduct = new VersionProduct()
            item.versionProduct.configureNewVersion(phrase)
            map[item.itemGlobal.codebar.toString()] = item.getMapFromCloud()
        })

    return map
}

export const setUpdateBatch = async (listItem,isAdd,showError) => {
   
    try { 
        var results = []
        var map = getMapFromList(listItem,getMotion(isAdd))
           
        results = await sendRequestPostLocal(_routerUpdateItemBachInCloud,{
            'isAdd':isAdd,
            'items':map
        })

        if(results.status !== 200)  throw new Error(results.response.data.sqlMessage) 
        else return results
    } catch (error) {
        showError('ERRO AO SALVAR LOTE DE PEÇAS NO SERVIDOR',error.message)     
        return {status:404}
    }
}

export const setNewItemLocal = async (listItemsGlobal,showError) => {
   
    try { 
        var results = []

        const map = {}
        listItemsGlobal.forEach(item =>{
            const newItem = new ItemsSale()
            newItem.fromAddInSystem(item)
            map[item.codebar.toString()] = newItem.getMapFromCloud()
        })

        results = await sendRequestPostLocal(_routerFromAddLocalItems,map)
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage) 
        else return true
    } catch (error) {
        showError('ERRO AO SALVAR NOVAS PEÇAS DA BRUNA NO SISTEMA',error.message)     
        return false
    }
}

async function insertItemsNoHaveInLocalDataBase(listGlobal,listItems,showError) {
    try {
      const needAdd = []
      if(listGlobal.length > listItems.length){
        for (var element of listGlobal) {
          let has = false
          let newItem = element
          for (var element1 of listItems) {

            if(element.codebar === element1.codeBar){
              has = true
              break
            }
          }

          if(!has){
            needAdd.push(newItem)
          }
        }
      }


      if(needAdd.length > 0) {
        if(await setNewItemLocal(needAdd,showError)){
          await findBjItems(showError)
        }
      }
     
    } catch (e) {
      showError('ERRO AO VERIFICAR PEÇAS NÃO CADASTRADAS', e.message)
    }
}


