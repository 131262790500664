import { getPercentage } from "../backEnd/ControllerGloba"
import { listItemsMemory, listProductsMemory, myStoreDataMemory } from "../backEnd/Memory"
import GenericProduct from "./Brands/Products/GenericProducts";
import ItemsSale from "./ItemBJ/ItemsSale";
import ControllerPayment from "./Sale/ControllerPayment";
import ListProducts from "./Sale/ListProducts";
import Sales from "./Sale/Sales";
import VersionProduct from "./VersionProduct";

class AuxSale{
    constructor(){
        this.resetAuxSale()
        this.updateFixedValue = this.updateFixedValue.bind(this);
    }

    resetAuxSale(){
        this.fixed = 0
        this.factor = myStoreDataMemory? myStoreDataMemory.profitFactor: 0
        this.percentDiscont  = 0
        this.extraValue  = 0
        this.details  = ''
        this.date  = ''
        this.dateRegister  = new Date()
        this.client = null
        this.typeSale = 'NOVA VENDA'    
        this.payForm = 'PARCELADO'
        this.listItems = []
        this.listProducts = []
        this.configured = false
        this.calculateAllFromSale = new CalculateAllFromSale()
        this.calculateMoneyFromSale = new CalculateMoneyFromSale()
        this.totalValueAll = 0
        this.sale = new Sales()
        this.insertStock = new InsertStock([],[])

        this.controllerPayment = new ControllerPayment()
        
    }

    canShowValues(){
        return this.calculateMoneyFromSale.moneyTotalAll > 0
    }

    configureSaleToSave(){
        this.sale.fromNewSale(this)
    }

    configureEditeSale(saleEdite){
        this.resetAuxSale()
        this.sale = saleEdite

        this.fixed = this.sale.valueFixed ? this.totalValueIncludeAll : 0
        this.factor = this.sale.detailsSale.factorCompany
        this.percentDiscont  = this.sale.saleDiscount.discountPercent
        this.extraValue  = this.sale.extraValue
        this.details  = this.sale.detailsSale.description
        this.date  = this.sale.detailsSale.date.slice(0,10)
        this.dateRegister  = new Date()
        this.client = this.sale.thisOwner
        this.typeSale = this.sale.detailsSale.isPresent? 'BRINDE' : 'NOVA VENDA'
        this.payForm = this.sale.detailsSale.payForm
        this.configured = true
    
        this.sale.itemsThisSale.listItems.forEach(ss=>{
            const foundIndex = listItemsMemory.findIndex(element => element.itemGlobal.codebar === ss.itemGlobal.codebar)
            if(foundIndex !== -1){
                const item = new ItemsSale()
                item.fromOther(listItemsMemory[foundIndex])
                item.amount = ss.amount
                this.listItems.push(item)
            }
        })

        this.sale.listGenericProduct.genericProducts.forEach(ss=>{
            const foundIndex = listProductsMemory.findIndex(element => element.idGenericProducts === ss.idGenericProducts)
            if(foundIndex !== -1){
                const product = new GenericProduct()
                product.fromOtherWithAmount(ss.amount,listProductsMemory[foundIndex])
                this.listProducts.push(product)
            }
        })
        this.insertStock = new InsertStock(this.sale.itemsThisSale.listItems ,this.sale.listGenericProduct.genericProducts)
        this.insertStock.setVersionEdite(saleEdite.getCodeSale())
        this.totalValueAll = this.sale.totalValueIncludeAll
        this.updateValues(this.listItems,this.listProducts)
        this.controllerPayment.totalNeedingPayment =  this.totalValueAll
    }

    updateValues(items,products){
        this.listItems = [...items]
        this.listProducts = [...products]
        this.calculateAllFromSale.updateValues(this.listItems,this.listProducts)
        this.calculateMoneyFromSale.updateValues(this.listItems,this.listProducts,parseFloat(this.factor),parseFloat(this.percentDiscont),this.extraValue,this.fixed)
        this.controllerPayment.totalNeedingPayment = this.calculateMoneyFromSale.moneyTotalAll
    }

    updateFixedValue(value){
        this.fixed = value
        this.controllerPayment = new ControllerPayment()
        this.updateValues(this.listItems,this.listProducts)
    }

    getExtraValueToString(){
        return this.extraValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getDataRegisterToString(){
        return this.dateRegister.toISOString().replace('T',' ').slice(0,19)
    }

    getDataToString(){
        return this.date === ''? this.getDataRegisterToString() : new Date(this.date).toISOString().slice(0,10)
    }

    configure(data,client){

        this.date  = data.date === '' ? new Date() : data.date
        this.dateRegister  = new Date()
        this.client = client
        this.typeSale = data.typeSale
        this.factor = parseFloat(data.factor)

        if(data.typeSale === 'NOVA VENDA'){
            this.percentDiscont  = parseFloat(data.percentDiscont)
            this.extraValue  = parseFloat(data.extraValue)
            this.details  = data.details
            this.payForm = data.payForm  
        }else{
            this.factor = myStoreDataMemory.purchasingFactor
            this.percentDiscont = 0
            this.extraValue = 0
            this.details = 'BRINDE'
            this.payForm = 'À VISTA'
        }

        this.configured = true
        this.updateValues(this.listItems,this.listProducts)
    }
}

class CalculateMoneyFromSale{
    constructor(){
        this.clear()
    }

    clear(){
        this.moneyItems = 0
        this.moneyProducts = 0
        this.moneyTotal = 0
        this.moneyTotalAll = 0
        this.moneyWithExtra = 0
        this.valueDiscount = 0
        this.moneyExtra = 0
    }
    
    updateValues(itemsBJ,products,factorFromStore,percentDiscount,valueExtra,fixed){
        this.clear()
        itemsBJ.forEach(ss=>{
            this.moneyItems += ss.getValueSaleTotal(factorFromStore)
        })

        products.forEach(ss=>{
            this.moneyProducts += ss.getSaleValueTotal()
        })

        let valueExtraMoney = parseFloat(valueExtra)
        if(isNaN(valueExtraMoney))
            valueExtraMoney = 0

        this.moneyExtra = valueExtraMoney
        this.moneyTotal = this.moneyItems + this.moneyProducts
        this.moneyWithExtra = this.moneyTotal + this.moneyExtra

        if(percentDiscount > 0){
            this.valueDiscount = getPercentage(this.moneyWithExtra,percentDiscount)
        }

        this.moneyTotalAll = fixed !== 0 ? parseFloat(fixed) : (this.moneyWithExtra - this.valueDiscount)
    }

    getHowCalculateExtra(){
        return this.getMoneyTotalToString() + ' + ' + this.moneyExtra.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ' = '
    }

    getHowCalculateNormal(){
        return this.getMoneyItemsToString() + ' + ' + this.getMoneyProductsToString() + ' = '
    }

    getMoneyWithExtraToString(){
        return this.moneyWithExtra.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getMoneyItemsToString(){
        return this.moneyItems.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getMoneyProductsToString(){
        return this.moneyProducts.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getMoneyTotalToString(){
        return this.moneyTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getMoneyTotalAllToString(){
        return this.moneyTotalAll.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getValueDiscountToString(){
        return this.valueDiscount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }


}

class CalculateAllFromSale{
    constructor(){
        this.itemsAll = 0
        this.modelsAll = 0
        this.calculateBJ = new CalculateFromSale()
        this.calculateProduct = new CalculateFromSale()
    }

    getHowCalculateItems(){
        return  this.calculateBJ.items + ' Bruna Semijoias + ' + this.calculateProduct.items + ' Produtos = '  
    }

    getHowCalculateModels(){
        return  this.calculateBJ.models + ' Bruna Semijoias + ' + this.calculateProduct.models + ' Produtos = '  
    }

    getItemsToString(){
        return this.itemsAll.toString() + ' Un'
    }

    getModelsToString(){
        return this.modelsAll.toString() + ' Modelos'
    }

    updateValues(itemsBJ,products){
        this.calculateBJ.calculate(itemsBJ)
        this.calculateProduct.calculate(products)
        this.itemsAll =  this.calculateBJ.items + this.calculateProduct.items
        this.modelsAll =  this.calculateBJ.models + this.calculateProduct.models
    }
}

class CalculateFromSale{
    constructor(){
        this.items = 0
        this.models = 0
    }

    clear(){
        this.items = 0
        this.models = 0
    }

    getItemsToString(){
        return this.items.toString() + ' Un'
    }

    getModelsToString(){
        return this.models.toString() + ' Modelos'
    }

    calculate(list){
        this.clear()
        list.forEach(ss=>{
            this.items += ss.amount
            this.models ++
        })
    }
}

class InsertStock {
    constructor(tempEditeSale, tempEditeGenericProducts) {
      this.tempEditeSale = [...tempEditeSale]
      this.tempEditeGenericProducts = [...tempEditeGenericProducts]
    }
  
    setVersionEdite(codeSale) {
      for (let element of this.tempEditeSale) {
        const version = new VersionProduct()
        element.versionProduct = version.configureNewVersion(`Venda ${codeSale} foi Editada, Item Devolvido para o Estoque`)
      }
      for (let element of this.tempEditeGenericProducts) {
        const version = new VersionProduct()
        element.versionProduct = version.configureNewVersion(`Venda ${codeSale} foi Editada, Item Devolvido para o Estoque`)
      }
    }
  
    clear() {
      this.tempEditeSale = []
      this.tempEditeGenericProducts = []
    }
  
    getMapFromCloud() {
      let list = {}
      let count = 1
      for (let element of this.tempEditeSale) {
        list[count] = element.getMapFromCloud()
        count++
      }
  
      let generics = {}
      count = 0
      for (let element of this.tempEditeGenericProducts) {
        generics[count] = element.getMapFromCloud()
        count++
      }
  
      return {
        'container-listItems': list,
        'container-genericProductsList': generics
      };
    }
  }
  

export default AuxSale