import React from "react";
import style from "../style/ClientsModels.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceFrown, faUserCheck, faUserXmark } from "@fortawesome/free-solid-svg-icons";


function ClientsModels({data,fucntionButton,onlyBasic}) {

    let isFull = onlyBasic?? true

    var icon
    switch (data.statusClient) {
        case 'ATIVA':
            icon = faUserCheck
            break
        case 'DESATIVA':
            icon = faUserXmark
            break
        case 'PROBLEMA':
            icon = faFaceFrown
             break
        default:
            icon = faUserCheck
            break
    }

    return(
        <div>
            <div className={style.containerWrap}>
                <button className={style.container}
                    onClick={fucntionButton}>
                    <div className={style.containerNames}>
                        <div className={isFull? style.name : style.fullName}>
                            <div className={data.statusClient === "ATIVA"? style.icon :style.icon + ' ' + style.hasProblem}><FontAwesomeIcon icon={icon} /></div>
                            <h3>{data._genericPerson.name}</h3>
                        </div>
                        {isFull?
                            <>
                                <h4>{data._genericPerson.cpf}</h4>
                                <h4>{data._genericPerson.rg}</h4>
                                <h4>{data._clientAddress.city}</h4>
                                <h4>{data._clientAddress._state}</h4>
                                <h4>{data.statusClient}</h4>
                            </>
                        :null} 
                        <h5 className={!data.isOpenSales ? style.status : style.status +" "+ style.hasDebt}>{data.isOpenSales ? "DEVENDO":"SEM DIVIDAS"}</h5>
                    </div>
                </button>
            </div>
        </div>  
    )
}

export default ClientsModels