import React, { useEffect, useState } from 'react'
import style from "../style/ToggleButton.module.css"

const ToggleButton = ({initState,onChange }) => {
    const [isToggled, setIsToggled] = useState(initState)
  
    const toggleButton = () => {
      const newToggledState = !isToggled
      setIsToggled(newToggledState)
      onChange(newToggledState)
    }
  
    return (
      <button className={`${style.toggleButton} ${isToggled ? style.active : ''}`} onClick={toggleButton}>
        <div className={style.ball}></div>
      </button>
    )
}

export const ToggleButtonWithContainer = ({initState,onChange,titleOn,titleOff,disable }) => {
  const [isToggled, setIsToggled] = useState(initState)
  const toggleButton = (e) => {
    const newToggledState = !isToggled
    setIsToggled(newToggledState)
    onChange(newToggledState)
  }

  return (
    <div className={style.toggleContainer}>
      <h6 className={`${style.toggleStatus} ${isToggled ?'':  style.remove }`}>
      {isToggled === true ? titleOn : titleOff || titleOn}</h6>
      {!disable?<button className={`${style.toggleButton} ${isToggled ? style.active : ''}`} onClick={toggleButton}>
        <div className={style.ball}></div>
      </button>:null}
    </div> 
  )
}

export default ToggleButton;
