import React from 'react';
import style from "../style/BrandDropdown.module.css"

function BrandDropdown({ brands, onSelect }) {
  return (
    <div>
      <select className={style.branddropdown} onChange={(e) => {onSelect(e.target.value)}}>
        {brands.map((brand) => (
          <option key={brand.idBrands} value={brand.idBrands}>
            {brand.nameBrand}
          </option>
        ))}
      </select>
    </div>
  );
}



export default BrandDropdown;
