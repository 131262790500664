import React, { useState, useEffect, useRef } from 'react';
import style from "../../style/ListClients.module.css"
import { GetSmallModels } from '../../Models/GetModels.js';
import { faMagnifyingGlass,faCircleInfo, faList, faLock} from '@fortawesome/free-solid-svg-icons';
import InputModels from "../../Models/InputModels.js"
import GetAmountList from '../../Models/GetAmountList.js';
import Space from '../../layout/Space.js';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage.js';
import ViewSaleController from '../Sale/ShowSale/ViewSaleController.js';
import { makeAccounting } from '../../objects/Accounting/MakeAccounting.js';
import GetNeedUpdate from './GetNeedUpdate.js';
import GetFutureReceivers from '../../Models/GetFutureReceivers.js';
import { employeeData } from '../../backEnd/InitController.js';
import { useError, useLoading } from '../../AppContext.js';

function ListOfFutureReceivers() {

  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])
  const [canView] = useState(employeeData().employeePermission.permissionsContabilite[10])
  const [filtered, setFiltered] = useState(makeAccounting.accountingControllerAllSalesReceiver.accountingAllSalesReceiver)
  const [orderBy, setOrderBy] = useState('asc')
  const [showViewSale, setShowViewSale] = useState(false)
  const [saleSelected, setSaleSelected] = useState(null)
  const { setIsLoading } = useLoading()
  const {showError} = useError()
  const handleOpenViewSale = (sale) =>{
    setSaleSelected(sale)
    setShowViewSale(true)
  }

  useEffect(() => {
    handleChangeFilter()
  }, [orderBy])

  const controllerFilter = () => {
    let sorted = [...makeAccounting.accountingControllerAllSalesReceiver.accountingAllSalesReceiver]
    switch (orderBy) {
      case 'asc':
        sorted.sort((a, b) => a.ownerSales._genericPerson.name.toString().localeCompare(b.ownerSales._genericPerson.name))
        break
      case 'desc':
        sorted.sort((a, b) => b.ownerSales._genericPerson.name.toString().localeCompare(a.ownerSales._genericPerson.name))
        break
      default:
        break
    }
    return sorted
  }

  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()

    resetScroll()
    if(value!= null){
      const filtered = data.filter(
        sale => {
          const filterName = sale.ownerSales._genericPerson.name.toString().toLowerCase().includes(value.target.value.toLowerCase())
          return filterName
        }
      )
      setFiltered(filtered)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    if(container){
      container.scrollTop = 0
      setLimit(50)
    }
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  const handleOpenPdf = async (dataReceiver)=>{
    setIsLoading(true)
    await dataReceiver.openPDFFutureReceivers(showError)
    setIsLoading(false)
  }

  return (
    <div className={style.containerGeneral}>
       <div className={style.containerGlobal}>  
           { canView? <>{makeAccounting.isCalculated === true ? 
           <>
              <div className={style.titleMenu}>     
              {window.innerWidth > 1200?<TitlePageResponsiveIcon subtitle={'Lista de Todos os Futuros Recebimentos'}  
                  path={'São Todas as Venda com Futuros Recebimentos...'} icon={faList}/>:null}
                  <Space left={5}/>
                  { window.innerWidth > 1200?
                    <div className={style.buidWithText}>
                        <GetSmallModels title={'TOTAL EM ABERTO:'} value={makeAccounting.accountingControllerAllSalesReceiver.getTotalSaleReceiverInFuture()}/>
                        <Space left={5}/>
                        <GetSmallModels title={'QUANTIDADE DE VENDAS:'} value={makeAccounting.accountingControllerAllSalesReceiver.totalSaleReceiverInFuture}/>
                    </div>:null
                  }
              </div>
              { window.innerWidth <= 1200?
                <div className={style.buidWithText}>
                    <GetSmallModels title={'TOTAL EM ABERTO:'} value={makeAccounting.accountingControllerAllSalesReceiver.getTotalSaleReceiverInFuture()}/>
                    <GetSmallModels title={'QUANTIDADE DE VENDAS:'} value={makeAccounting.accountingControllerAllSalesReceiver.totalSaleReceiverInFuture}/>
                </div>:null
              }
              {window.innerWidth <= 1200? <Space top={5}/>:null}
              <div className={style.buildList}>
              <div className={style.filter}>
                  <div className={style.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} 
                    type={"text"} placeholder={"Buscar Cliente Com Venda em Aberto..."}
                    onChange={handleChangeFilter}/>
                  </div>
                  <div className={style.containerDropDown}>
                    <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                        <option value="asc">CRESCENTE</option>
                        <option value="desc">DESCRESCENTE</option>
                    </select>
                  </div>
              </div>
              <Space right={2} left={2} top={5} bottom={0}/> 
              {(filtered.length > 0) ?<div className={style.buildList}>
                  <GetAmountList amount={filtered.length} title={"Clientes Com Vendas em Aberto..."}/>     
                <Space right={0} left={0} top={0} bottom={10}/> 
              </div> : null}
              <div ref={containerRef} className={style.containerListForced} onScroll={checkScroll}>
                  {(filtered.length === 0)?
                    <GetSmallModels title={"Não há Futuros Recebimentos com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo} />:
                    filtered.slice(0, limit).map((dataReceiver, index) => (
                      <GetFutureReceivers
                      openPdf={handleOpenPdf}
                      handlerFunction={handleOpenViewSale} dataReceiver={dataReceiver}/>
                    ))
                  }
              </div>
              </div>
            </> :
            <div className={style.divSapce}> 
              <GetNeedUpdate/>
            </div> }</>:
               <div className={style.divSapce}>
               <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar a Lista de Futuros Recebimentos'} />
               </div>}
       </div>
       {showViewSale ? <ViewSaleController handleSave={()=>{}} saleStart={saleSelected} handleClose={()=> setShowViewSale(false)}/> : null}
    </div>
   )

}

export default ListOfFutureReceivers