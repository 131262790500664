import React, { useState }  from "react"
import { TitlePageResponsiveIcon } from "../layout/TitlePage"
import { faCheck, faClose, faKey, faLock } from "@fortawesome/free-solid-svg-icons"
import { GetButton, GetButtonIcon } from "../layout/ButtonsLayout"
import style from '../style/GetPassWord.module.css'
import Alert from '../Models/Alert.js';
import Space from "../layout/Space.js"
import InputModelsBlock from "../layout/InputModelsBlock.js"

const getFormData = () => {
    return {
        password:''
    }
}

const getFormNeed = () => {
    return {
        password:false,
    }
}

function GetPassword({handleCheck,titleButton, handleClose}){
    const [isVisible,setAlert] = useState(false)
    const [valueAlert,setValueAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData)
    const [needData, setNeedData] = useState(getFormNeed)
    
    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmitSave= async() => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setValueAlert("Complete Todos os Campos em Vermelho")
            setAlert(true)
            return
        }

        if(!handleCheck(formData.password)){         
            setValueAlert("A Senha Está Incorreta!")
            setAlert(true)
        }

    }

    return(
        <div className={style.overlay}>
        <div className={style.alert}>
            <div className={style.title}>
                    <TitlePageResponsiveIcon title={"Iforme a Senha Correta"} 
                    path={"Complete o Campo Abaixo com a Senha Correta"} icon={faLock}
                    children={<GetButtonIcon color='#E4080A' 
                    icon={faClose}
                    handleFunction={handleClose}/> }
                    />  
            </div>
            <div className={style.div}>
                    <InputModelsBlock legend={'SENHA DO FUNCIONÁRIO'} onChange={handleChange} validation={needData.password} maxLength={50}
                    value={formData.password}
                    name={'password'} iconButton={faKey} type={"password"} placeholder={"Senha..."}/>
            </div>
            <Space top={5}/>
            <div className={style.divButton}>
                <GetButton title={titleButton} color='#007bff' icon={faCheck} handleFunction={handleSubmitSave}/> 
            </div>
           {isVisible? <Alert message={valueAlert} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
      </div>
    )
}

export default GetPassword