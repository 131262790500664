import React from "react";
import style from "../../style/UsersModel.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck, faUserXmark } from "@fortawesome/free-solid-svg-icons";


function UsersModel({data,onClick}) {

    var icon
    switch (data.isAcess) {
        case true:
            icon = faUserCheck
            break
        case false:
            icon = faUserXmark
            break
        default:
            icon = faUserCheck
            break
    }

    return(
        <div>
            <div className={style.containerWrap}>
                <button className={style.container}
                    onClick={onClick}>
                    <div className={style.containerNames}>
                        <div className={style.name}>
                            <div className={data.isAccess === true? style.icon :style.icon + ' ' + style.hasProblem}><FontAwesomeIcon icon={icon} /></div>
                            <h3>{data.name}</h3>
                        </div>
                       {
                            window.innerWidth > 768?
                            <>
                            <h4>{data.emailLogin}</h4>
                            <p>{data.databaseName}</p>
                            <h4>{data.number}</h4>
                            <p>{data.databaseSettings.getDateToString()}</p>
                            <h6>{data.isAccess ? 'ACESSO LIBERADO':'BLOQUEADO'}</h6>
                            </>:null
                       }
                        <h5 className={!data.checkNeedPay() ? style.status : style.status +" "+ style.hasDebt}>{data.checkNeedPay() ? "DEVENDO":"SEM DIVIDAS"}</h5>
                    </div>
                </button>
            </div>
        </div>  
    )
}

export default UsersModel