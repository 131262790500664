import React, { useRef, useState } from 'react'
import style from '../../style/GetNFeConfigureCompany.module.css'
import { faClose, faNewspaper, faEnvelopesBulk, faWarning, faIdCardClip, faCircleQuestion, faNavicon } from '@fortawesome/free-solid-svg-icons'
import Alert from '../../Models/Alert'
import Space from '../../layout/Space'
import { TitlePageResponsiveIcon } from '../../layout/TitlePage'
import { GetButton, GetButtonIcon } from '../../layout/ButtonsLayout'
import { GetPIS } from './GetPIS'
import { GetICMS } from './GetICMS'
import { GetCOFINS } from './GetCOFIS'
import { GetSmallModels } from '../../Models/GetModels'
import ConfirmationPopup from '../../Models/ConfirmationPopup'
import { SettingsNFE } from '../../objects/NFe/NFe'
import { useError } from '../../AppContext'
import Loader from '../../layout/Loader'
import { setSaveNFeTax } from '../../controllers/CloudNFeTax'
import InputModelsBlock from '../../layout/InputModelsBlock'
import { emit } from '../../backEnd/Memory'
import { useNavigate } from 'react-router-dom'
import { routerFromFinancialNotaFiscal } from '../../routers/routerList'


function GetEditeNfeBj({onClose,nfeSettings,isBj,idBrand,onReload}) {

    const [nfeSettingsTemp] = useState(()=>{
      const tempNfe = new SettingsNFE()
      tempNfe.fromOther(nfeSettings)
      return tempNfe
    })

    const [formData, setFormData] = useState({
      NCM: nfeSettings.NCM
    })

    const [needData, setNeedData] = useState({
      NCM: false,
    })

    const navigate = useNavigate()
    const [showConfirmSave,setShowConfirmSave] = useState(false)
    const refICMS = useRef(null)
    const refPIS = useRef(null)
    const refCOFINS = useRef(null)
    const [isVisible,setAlert] = useState(false)
    const [formDataICMS,setFormDataICMS] = useState([])
    const [formDataPIS,setFormDataPIS] = useState([])
    const [formDataCOFINS,setFormDataCOFINS] = useState([])
    const {showError} = useError()
    const [loading, setLoading] = useState(false)

    const onTrySave = () => {
        let canEditeTax = true
        

        const dataIcms = refICMS.current.handleSubmit()
        setFormDataICMS(dataIcms)
        if(dataIcms === false) canEditeTax = false
        
        const dataPis = refPIS.current.handleSubmit()
        setFormDataPIS(dataPis)
        if(dataPis === false) canEditeTax = false

        const dataCofins = refCOFINS.current.handleSubmit()
        setFormDataCOFINS(dataCofins)
        if(dataCofins === false) canEditeTax = false

        if(formData.NCM === ''){
          setNeedData(prevState => ({ ...prevState, ['NCM']: true }))
          canEditeTax = false
        }else{
          setNeedData(prevState => ({ ...prevState, ['NCM']: false }))
        }

        if(!canEditeTax){
            setAlert(true)
            return 
        }

        setShowConfirmSave(true)
    }

    const handleSave = async()=>{
      setLoading(true)
      setShowConfirmSave(false)
      nfeSettingsTemp.configure(formDataICMS,formDataPIS,formDataCOFINS,formData,isBj,idBrand)
      const result = await setSaveNFeTax(nfeSettingsTemp,showError)
      if(result.status === 200){
        onReload()
        onClose()
      }
      setLoading(false)
    }

    const handleChange = (e) => {
      if(e !== ''){
          const { name, value } = e.target;
          setFormData({ ...formData, [name]: value })
      }
    }

    const handleClose = () => {
      onClose()
    } 

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
           {
            !loading ?
              <>
                  <div className={style.title}>
                              <TitlePageResponsiveIcon 
                              children={
                                  <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                              }
                              subtitle={"EDITAR IMPOSTOS DA NFe PARA ESSA MARCA"} path={"Complete os Campos Abaixo..."} icon={faNewspaper}/>      
                  </div>
                  {
                    emit.idNFESettings.toString() !== '-1'?
                    <>
                        <div className={style.divAll} >
                          {
                            !nfeSettingsTemp.configured?
                              <GetSmallModels backgroundColor={"#ff0000"} icon={faWarning} title={'IMPOSTOS NÃO CONFIGURADOS'} subtitle={'Para Emitir Nota Fiscal é Necessario Configurar os Campos Abaixo'}/>
                            :null
                          }
                          <GetSmallModels 
                        children={
                            <GetButton responsive={true} color='#FF0000' title={'AJUDA'}  icon={faCircleQuestion} handleFunction={()=>window.open('https://portalunico.siscomex.gov.br/classif/#/nomenclatura/tabela?perfil=publico','_black')}/> 
                        }
                        icon={faCircleQuestion}
                        title={'PARA SABER O (NCM) DO SEU PRODUTO'} backgroundColor={"#005f00"} subtitle={'Tabela Completa do NCM no Site do Governo, é Necessário Baixar No Formato EXCEL'}/>
                          <InputModelsBlock 
                            legend={'NCM - PARA TODOS OS PRODUTOS DA MARCA'} 
                            onChange={handleChange} 
                            validation={needData.NCM} 
                            maxLength={20} 
                            value={formData.NCM} 
                            name={'NCM'} 
                            iconButton={faIdCardClip} 
                            type={"number"}  
                            placeholder={"Nomenclatura Comum do Mercosul... "}  
                          />  
                          <Space left={5} top={5} />
                          <GetICMS initialExpand={false} ref={refICMS} isEdite={true} icms={nfeSettingsTemp.ICMS}/>
                          <Space left={5} top={5} />
                          <GetPIS initialExpand={false} ref={refPIS} isEdite={true} pis={nfeSettingsTemp.PIS}/>
                          <Space left={5} top={5}/>
                          <GetCOFINS initialExpand={false} ref={refCOFINS} isEdite={true} cofins={nfeSettingsTemp.COFINS}/> 
                        <div className={style.div}>
                          <GetButton title={"SALVAR IMPOSTOS DA NOTA FISCAL NFe"} color='#007bff' icon={faEnvelopesBulk} handleFunction={onTrySave}/> 
                        </div>
                      </div>
                    </>:
                    <>
                    <GetSmallModels 
                        children={
                            <GetButton responsive={true} color='#005F00' title={'CONFIGURAR'}  icon={faNavicon} handleFunction={()=>navigate(routerFromFinancialNotaFiscal)}/> 
                        }
                        title={'É NECESSÁRIO CONFIGURAR O EMISSOR PRIMEIRO'} backgroundColor={"#9F0000"} icon={faWarning} subtitle={'EM FINANCEIRO/NFE'}/>
                    </>
                  }
              </>:
              <div className={style.loaderCenter}>
                  <Loader titleFinding={'Salvando Dados no Servidor...'} 
                  subtitleFinding={'Aguarde...'} loading={loading}/>
              </div>
            }
            {showConfirmSave? <ConfirmationPopup title={'SALVAR IMPOSTOS DA NOTA FISCAL NFe'} 
                message={'Tem Certeza que Deseja Salvar?'} onCancel={()=>setShowConfirmSave(false)} onConfirm={handleSave}/>: null} 
           {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
      </div>
    )
}

export default GetEditeNfeBj
