import { sendRequestPostLocal } from "../backEnd/requesition"
const _routerFromLoginInCloud ='/login-web'
const _routerFromLoginUser ='/html-login-user'
const _routerFromLoginAdmin ='/loginAdmin'

export const tryLogin = async (email,password,showError) => {
    try {
       
        var response = await sendRequestPostLocal(_routerFromLoginInCloud,{
            'emailLogin': email,
            'password':password})

        if(response.status === 200){
            const { tokenSecretKey , tokeSecretKeyHeader1 ,tokeSecretKeyHeader2 , tokenSecretKeyGlobal } = response.data
            localStorage.setItem('tokenLogins', tokenSecretKey)
            localStorage.setItem('tokenHeader', tokeSecretKeyHeader1)
            localStorage.setItem('tokenHeaderImage', tokeSecretKeyHeader2)
            localStorage.setItem('tokenGlobal', tokenSecretKeyGlobal)
            return {status:200}
        }else if(response.response.status === 505){
            throw new Error('SISTEMA ESTÁ BLOQUEADO POR FALTA DE PAGAMENTO OU CANCELAMENTO.') 
        }else{
            throw new Error('SENHA ESTÁ INCORRETA') 
        }

    } catch (error) {
        showError('ERROR AO LOGAR NO SISTEMA',error.message)  
        return {status:404}
    }
}

export const tryLoginAdmin = async (email,password,showError) => {
    try {
       
        var response = await sendRequestPostLocal(_routerFromLoginAdmin,{
            'emailLogin': email,
            'password':password})

        if(response.status === 200){
            const { tokeAdmin ,tokenSecretKeyGlobal  } = response.data
            localStorage.setItem('tokenAdmin', tokeAdmin)
            localStorage.setItem('tokenGlobal', tokenSecretKeyGlobal)
            return {status:200}
        }else{
            throw new Error('Senha Está Errada') 
        }

    } catch (error) {
        showError('ERROR AO LOGAR NO SISTEMA ADMIN',error.message)  
        return {status:404}
    }
}

export const tryLoginUser = async (idEmployee,password,showError) => {
    try {
       
        var response = await sendRequestPostLocal(_routerFromLoginUser,{
            'idEmployee': idEmployee,
            'password':password})

       
        if(response.status === 200){
            const { token } = response.data
            localStorage.setItem('tokenUser', token)
            return {status:200}
        }else{
            throw new Error('Senha Está Errada') 
        }

    } catch (error) {
        showError('ERROR AO LOGAR NO SISTEMA',error.message)  
        return {status:404}
    }
}