import React, { useState, useEffect,useRef } from 'react'
import style from "../../../style/ListClients.module.css"
import { GetSmallModels } from '../../../Models/GetModels'
import { faBoxesPacking ,faMagnifyingGlass,faCircleInfo, 
  faTruckArrowRight, faPlus, faDownload} from '@fortawesome/free-solid-svg-icons'
import Loader from '../../../layout/Loader'
import InputModels from "../../../Models/InputModels.js"
import GetAmountList from '../../../Models/GetAmountList.js'
import GetExpandedImage from '../../../layout/GetExpandedImage.js'
import ProductModel from '../../../Models/ProductModel.js'
import { TitlePageResponsiveIcon } from '../../../layout/TitlePage.js'
import GetFloatingButton from '../../../layout/GetFloatingButton.js'
import Space from '../../../layout/Space.js'
import { GetButton } from '../../../layout/ButtonsLayout.js'
import ViewProducts from './ViewProducts.js'
import Alert from '../../../Models/Alert.js'
import NewProduct from './NewProduct.js'
import { useError } from '../../../AppContext.js'
import { useNavigate } from 'react-router-dom'
import { routerFromStockRepositionGenerics } from '../../../routers/routerList.js'
import { findproduts, listBrandsMemory, listProductsMemory, updateIfNull } from '../../../backEnd/Memory.js'


function ListProducts() {
  const containerRef = useRef(null)
  const navigate = useNavigate()
  const [limit, setLimit] = useState([50])

  const [showAddProduct,setShowAddProduct] = useState(false)
  const [showAlertSave,setShowAlertSave] = useState(false)
  const [productSelected,setProductSelected] = useState(null)
  const [showEditeItem,setShowEditeItem] = useState(false)

  const [filtered, setFiltered] = useState([])
  const [list, setList] = useState(listProductsMemory)

  const [loading, setLoading] = useState(false)


  const {showError} = useError()

  const [filter, setFilter] = useState('active')
  const [orderBy, setOrderBy] = useState('asc')

  const [isExpanded, setIsExpanded] = useState(false)
  const [nameProduct, setNameProduct] = useState('')
  const [linkImage, setSetLinkImage] = useState('')

  const toggleImageExpansion = (nameProduct,linkImage) => {
      setIsExpanded(!isExpanded)
      setNameProduct(nameProduct)
      setSetLinkImage(linkImage)
  }

  useEffect(() => {
    async function fetchData() {
        setLoading(true)
        await updateIfNull(showError)   
        handleUpdate() 
        setLoading(false) 
    }
    fetchData()
  }, [])

  const handleUpdate = () =>{
    const temp = []
    listBrandsMemory.forEach((brand) => {
      listProductsMemory.forEach((data) => {
        if (brand && data.idBrands === brand.idBrands && brand.isActiveBrand) {
          data.nameBrandTemp = brand.nameBrand
          temp.push(data)
        }
      })
    })

    setList(temp)
    setFilter('active')
    setOrderBy('asc')
  }

  useEffect(() => {
    handleChangeFilter()
  }, [filter, orderBy,list]);

  const handleFindDataBase = async () => {
    setLoading(true)
    setList([])
    await findproduts(showError)
    handleUpdate()
    setLoading(false)
  }

  const handleEditeSucess =()=>{
    handleFindDataBase()
    setShowAlertSave(true)
  }
  
  const isSameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
  };

 
  const dateDiffInDays = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000; // Milissegundos em um dia
    const diffInTime = date2.getTime() - date1.getTime(); // Diferença em milissegundos
    return Math.floor(diffInTime / oneDay); // Calcula o número de dias inteiros
  };


  const controllerFilter = () => {
    let data = []
    const dateToday = new Date();
    switch (filter) {
        case 'all':
            data = list
            break
        case 'withNoitems':
            data = list.filter(item => item.amount === 0)
            break  
        case 'withItems':
            data = list.filter(item => item.amount > 0)
            break    
        case 'active':
              data = list.filter(item => item.status)
              break  
        case 'desactive':
              data = list.filter(item => !item.status)
              break  
            case 'todayEdite':
                data = list.filter(item => {
                    const itemDate = new Date(item.versionProduct.lastEdition);
                    return isSameDay(itemDate, dateToday);  
                });
                break;
            case 'last5DaysEdite':
                data = list.filter(item => {
                    const itemDate = new Date(item.versionProduct.lastEdition);
                    return dateDiffInDays(itemDate, dateToday) <= 5;
                });
                break;
            case 'last10DaysEdite':
                data = list.filter(item => {
                    const itemDate = new Date(item.versionProduct.lastEdition);
                    return dateDiffInDays(itemDate, dateToday) <= 10;
                });
                break;
            case 'last15DaysEdite':
                data = list.filter(item => {
                    const itemDate = new Date(item.versionProduct.lastEdition);
                    return dateDiffInDays(itemDate, dateToday) <= 15;
                });
                break;  
        default:
            data = list
            break
    }

    let sortedClients = [...data];
    switch (orderBy) {
      case 'asc':
        sortedClients.sort((a, b) => a.nameProduct.localeCompare(b.nameProduct))
        break
      case 'desc':
        sortedClients.sort((a, b) => b.nameProduct.localeCompare(a.nameProduct))
        break
      default:
        break
    }
    return sortedClients;
  }

  const handleFilterChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }
  
  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()
    resetScroll()
    if(value!= null){
      const filteredStock = data.filter(
        stock => {
          const filternName = stock.nameProduct.toLowerCase().includes(value.target.value.toLowerCase())
          const filterValue = stock.valueToSale.toString().toLowerCase().includes(value.target.value.toLowerCase());
          const filterValueCoast = stock.valueCoast.toString().toLowerCase().includes(value.target.value.toLowerCase());
          const filterCodebar = stock.codebar.toString().toLowerCase().includes(value.target.value.toLowerCase())
          return filternName || filterValue || filterValueCoast  || filterCodebar
        }
      )
      setFiltered(filteredStock)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    container.scrollTop = 0
    setLimit(50)
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  const handleSelectItem = (item) => {
    setProductSelected(item)
    setShowEditeItem(true)
  }

  
  return (
    <div className={style.containerGeneral}>
       <div className={style.containerGlobal}>  
           <div className={style.titleMenu}>     
              <TitlePageResponsiveIcon subtitle={'Lista de Produtos Genéricos do Seu Estoque'} 
             children={
              <>
              {!loading?<GetButton responsive={true} title={'BUSCAR NO SERVIDOR'} 
                color='#007bff' icon={faDownload} handleFunction={handleFindDataBase}/>
              :null}</>
             }
              path={'São Todos os Produtos Genéricos...'} icon={faBoxesPacking}/>
            </div>
           <div className={style.buildList}>
             <div className={style.filter}>
                 <div className={style.inputFilter}>
                   <InputModels iconButton={faMagnifyingGlass} type={"text"} placeholder={"Buscar Produtos na Lista..."}
                   onChange={handleChangeFilter}/>
                 </div>
                 <div className={style.containerDropDown}>
                  <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                      <option value="active">ATIVOS</option>
                      <option value="all">TODOS</option>
                      <option value="desactive">DESATIVOS</option>
                      <option value="withNoitems">SEM ESTOQUE</option>
                      <option value="withItems">COM ESTOQUE</option>
                      <option value="todayEdite">EDITADO/CADASTRADO HOJE</option>
                      <option value="last5DaysEdite">EDITADO/CADASTRADO ÚLTIMOS 5 DIAS</option>
                      <option value="last10DaysEdite">EDITADO/CADASTRADO ÚLTIMOS 10 DIAS</option>
                      <option value="last15DaysEdite">EDITADO/CADASTRADO ÚLTIMOS 15 DIAS</option>
                      
                  </select>
                  <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                      <option value="asc">CRESCENTE</option>
                      <option value="desc">DESCRESCENTE</option>
                  </select>
                </div>
             </div>
             <Space right={2} left={2} top={5} bottom={0}/> 
             {(filtered.length > 0 && !loading ) ?<div className={style.buildList}>
               <GetAmountList amount={filtered.length} title={"Produtos Totais Encontrados na Busca..."}/>
               <div className={style.labelData}>
                  <h5>Quantidade</h5>
                  <h5>Nome</h5>
                  <h5>Código de Barras</h5>
                  <h5>Marca</h5>
                  <h5>Valor de Venda</h5>
                  <h5>Valor de Custo</h5>
               </div>
               <Space right={0} left={0} top={0} bottom={10}/> 
             </div> : null}
             <div ref={containerRef} className={style.containerList} onScroll={checkScroll}>
                  <div className={style.loaderCenter}>
                    <Loader titleFinding={'Buscando no Servidor!'} 
                         subtitleFinding={'Aguarde...'} loading={loading}/>
                  </div>
              <div>
                {(filtered.length === 0 && !loading) ? (
                  <GetSmallModels
                    title={"Não há Produtos com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo}
                  />
                ) : (
                  filtered.slice(0, limit).map((data, dataIndex) => (
                      <div key={dataIndex}>
                        <ProductModel
                          data={data}
                          functionButton={() => handleSelectItem(data)}
                          functionImage={() => toggleImageExpansion(data.nameProduct, data.linkImage)}
                        />
                      </div>
                    ))
                  )}
              </div>
             </div>
           </div>
         {showAddProduct? <NewProduct handleSave={handleFindDataBase} brandsList={listBrandsMemory} handleClose={()=>setShowAddProduct(false)}/> : null}
         {showAlertSave? <Alert top={95} right={25}  message={'Produto Editado com Sucesso'} onClose={() => setShowAlertSave(false)} type={"success"} />:null}
         {showEditeItem? <ViewProducts handleRefresh={handleEditeSucess} product={productSelected} handleClose={()=>setShowEditeItem(false)}/> : null}
         {isExpanded? <GetExpandedImage nameProduct={nameProduct} linkImage={linkImage} onClose={toggleImageExpansion}/>:null}
         {loading?null:<GetFloatingButton icon={faPlus} color='#007bff' width={window.innerWidth <= 1200 ? 65:85} margiBottom={'1%'}  height={window.innerWidth <= 1200 ? 65:85} margiRight={window.innerWidth <= 1200 ?75: 110} handleFunction={()=>setShowAddProduct(true)} />}
         <GetFloatingButton icon={faTruckArrowRight} handleFunction={()=>navigate(routerFromStockRepositionGenerics)} color='#007bff' width={window.innerWidth <= 1200 ? 65:85} margiBottom={'1%'}  height={window.innerWidth <= 1200 ? 65:85} margiRight={'1%'}/>
       </div>
    </div>
  )
}

export default ListProducts