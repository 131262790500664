import GenericPersonObject from './genericPersonObject.js';
import AddressObject from './addressObject.js';
import BankObject from './bankObject.js';
import CompanyObject from './companyObject.js';
import EmployeePermission from '../EmployeePermissions.js';
import { employeeData } from '../../backEnd/InitController.js';
import { myStoreDataMemory } from '../../backEnd/Memory.js';
import { requestPDFs } from '../../controllers/CloudPdfs.js';
import { contactObject } from './contactObject.js';

export class Employee{
    constructor(){ 
      this.isMaster = false
      this.linkImage = ''
      this.keycode = ''
      this.idEmployee = 0
      this.function = ''
      this.percentComission = 0
      this.status = false
      this.genericPerson = new GenericPersonObject()
      this.address = new  AddressObject()
      this.bank = new BankObject()
      this.employeePermission = new EmployeePermission()
      this.comissionFixed = 0
      this.contact = new contactObject()
    }

    fromCloud(data){
      this.isMaster = data.isMaster === 1 ? true : false
      this.linkImage = data.linkImage
      this.keycode = data.keycode
      this.idEmployee = data.idEmployee
      this.function = data.function
      this.percentComission = data.percentComission
      this.status = data.status === 1 ? true : false

      this.genericPerson = new GenericPersonObject()
      this.genericPerson.fromCloud(data)
      this.comissionFixed = parseFloat(data.comissionFixed)

      this.contact = new contactObject()
      this.contact.fromCloud(data)
      
      this.address = new  AddressObject()
      this.address.fromCloud(data)

      this.bank = new BankObject()
      this.bank.fromCloud(data)

      this.employeePermission = new EmployeePermission()

      if(this.isMaster )
        this.employeePermission.fromMaster()
      else
        this.employeePermission.fromCloud(data)
      
  
      this.company = new CompanyObject()
      this.company.fromCloud(data)
    }

    async openPDFEmployee(showError){
      const body = {
        'type':'pdfEmployee',
        'employee': employeeData().genericPerson.name,
        'employeeData': this.getMapFromCloud(),
        'myStore': myStoreDataMemory.getMapFromCloud()
      }
      await requestPDFs(body,showError)
    }

    
    fromInitMaster(data){
      this.isMaster = true
      this.status = true
      this.keycode = data.keycode
      this.function = data.function
      this.genericPerson.name = data.name
      this.employeePermission.fromMaster()
    }

    fromEditeSimple(data){ 
      this.keycode = data.keycode
      this.function = data.function
      this.genericPerson.name = data.name
    }

    fromEdite(data,status,pItems,pContabilite,pClients,pEmployees,pMyStore,pSales,pFinancial){
      this.status = status
      this.isMaster = data.isMaster
      this.linkImage = data.linkImage
      this.keycode = data.keycode
      this.idEmployee = data.idEmployee
      this.function = data.function
      this.percentComission = data.percentComission

      const comissionFixedTemp = parseFloat(data.comissionFixed)
      this.comissionFixed = isNaN(comissionFixedTemp) ? 0 : comissionFixedTemp
      this.genericPerson = new GenericPersonObject()
      this.genericPerson.fromCloud(data)

      this.contact = new contactObject()
      this.contact.fromCloud(data)
      
      this.address = new  AddressObject()
      this.address.fromCloud(data)

      this.bank = new BankObject()
      this.bank.fromCloud(data)

      this.employeePermission = new EmployeePermission()
      this.employeePermission.fromEdite(data.idEmployeePermission, 
        pItems,pContabilite,pClients,pEmployees,pMyStore,pSales,pFinancial)
    }

    getMapFromCloud(){
        return {
          'container-person':this.genericPerson.getMapFromCloud(),
          'container-contact':this.contact.getMapFromCloud(),
          'container-address':this.address.getMapFromCloud(),
          //'container-company':this.clientCompany.getMapFromCloud(),
          'container-bank':this.bank.getMapFromCloud(),
          'comissionFixed':this.comissionFixed,
          'container-permission':this.employeePermission.getMapFromCloud(),
          'idEmployee': this.idEmployee,
          'status': this.status.toString(),
          'isMaster': this.isMaster.toString(),
          'linkImage': this.linkImage,
          'keycode': this.keycode,
          'function': this.function.toUpperCase(),
          'percentComission': this.percentComission,
        }
    }
}
