import React, { useState } from "react"
import style from "../../style/AddEmployee.module.css"
import AddEmployeeGeneric from "../Employees/AddEmployerGeneric"
import { employeeData } from "../../backEnd/InitController"
import { GetSmallModels } from "../../Models/GetModels"
import { faLock } from "@fortawesome/free-solid-svg-icons"

function AddEmployee(){
    const [employeelogdedIn] = useState(employeeData())

    return(
        <div>
            <div className={style.containerGlobal}>     
               <div className={style.div}>
                    {!employeelogdedIn.employeePermission.permissionsEmployers[1]?
                        <GetSmallModels icon={faLock} title={'ACESSO NEGADO'} 
                        subtitle={'Você Não Tem Permissão para Editar Dados dos Funcionários'}/> 
                        :<AddEmployeeGeneric/>}
               </div>
            </div> 
        </div>
    )
}

export default AddEmployee