import React, { useState } from 'react';
import style from '../../style/GetConfigureSale.module.css'
import { faClose, faMoneyCheckDollar,
     faGear, faCalendar, faCalendarAlt, faPercent, faNewspaper, faPersonCircleCheck, faBalanceScale  } from '@fortawesome/free-solid-svg-icons';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage';
import { GetButton, GetButtonIcon } from '../../layout/ButtonsLayout';
import InputModelsBlock from '../../layout/InputModelsBlock';
import Space from '../../layout/Space';
import { GetSmallModels } from '../../Models/GetModels';
import GetEmployee from '../../Models/GetEmployee';
import { employeeData } from '../../backEnd/InitController';
import TypeSaleDropDown from '../../layout/TypeSaleDropDown';
import TypePaymentDropDown from '../../layout/TypePaymentDropDown';
import GetClientList, { GetClientAfterSelect } from '../../layout/GetClientList';
import { auxSale, databaseSettings, listClientsMemory } from '../../backEnd/Memory';
import Alert from '../../Models/Alert';

const getFormData = () => {
    return {
        factor: auxSale.factor,
        percentDiscont: auxSale.percentDiscont,
        extraValue: auxSale.extraValue,
        details: auxSale.details,
        date: auxSale.date,
        typeSale:auxSale.typeSale,
        payForm: auxSale.payForm,
    }
}

const getFormNeed = () => {
    return {
        factor:false,
    }
}

function GetConfigureSale({onClose,isEdite}) {

    const [isPresent,setPresent] = useState(auxSale.typeSale !== 'NOVA VENDA')
    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData)
    const [needData, setNeedData] = useState(getFormNeed)
    const [showFindClient ,setShowFindClient] = useState(false)
    const [clientSelected,setSelectedClient] = useState(auxSale.client)

    
    const handleClose = () => {
        if(formData.factor === '')
        {
            setNeedData({ ...needData, factor: true })
            setAlert(true)
            return
        }else setNeedData({ ...needData, factor: false })
        
        auxSale.configure(formData,clientSelected)
        onClose()
    } 

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })

            if(name ==='typeSale')
                setPresent(value === 'BRINDE')
        }
    }


    return (
      <div className={style.overlay}>
        <div className={style.alert}>
           <div className={style.title}>
                <TitlePageResponsiveIcon 
                children={ 
                <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> }
                subtitle={"CONFIGURAR VENDA"} path={"Complete os Campos Abaixo Para Configurar a Venda"} icon={faGear}/>  
              
           </div>
           <div className={style.div}>
                <GetSmallModels title={'Data de Registro no Sistema: ' } icon={faCalendar} subtitle={new Date().toISOString().replace('T',' ').slice(0,19)} />
                <GetEmployee employee={employeeData()}/>
                <div className={style.divInRow}>
                    <div className={style.divInRow}>
                        {window.innerWidth <= 1200 ? null:<p>TIPO DE VENDA</p>}
                        <TypeSaleDropDown disable={isEdite} initialState={formData.typeSale.toUpperCase()} handlerFuncion={handleChange} name={'typeSale'}/>
                    </div>
                    {isPresent ? null:
                    <>
                        <Space left={5}/>
                        <div className={style.divInRow}>
                            {window.innerWidth <= 1200 ? null: <p>FORMA DE PAGAMENTO</p>}
                            <TypePaymentDropDown disable={isEdite} initialState={formData.payForm.toUpperCase()} handlerFuncion={handleChange} name={'payForm'}/>
                        </div>
                    </>
                    }
                </div>
                <Space top={5}/>
                <div className={style.div}>
                    {clientSelected ? 
                        <GetClientAfterSelect client={clientSelected} onRemove={()=>setShowFindClient(true)}/>
                     :  <GetButton title={'SELECIONAR CLIENTE DA VENDA'} handleFunction={()=>setShowFindClient(true)} color={'#FF0000'} icon={faPersonCircleCheck}/>
                    }
                </div>
                <Space top={5}/>
                <InputModelsBlock legend={'DATA DA VENDA'} 
                onChange={handleChange} validation={needData.date} 
                maxLength={10} value={formData.date} name={'date'} 
                iconButton={faCalendarAlt} type={"date"} placeholder={"Data da Venda..."}/>
                <Space top={5}/>
                {isPresent ? null:
                <>
                    {databaseSettings.isCanUseBrunaBJ?
                    <>
                        <InputModelsBlock legend={'FATOR DE MULTIPLICAÇÃO'} 
                        onChange={handleChange} validation={needData.factor} 
                        maxLength={6} value={formData.factor} name={'factor'} 
                        iconButton={faBalanceScale} type={"number"} placeholder={"Fator de Multiplicação..."}/>
                        <Space top={5}/>
                    </>:null}
                    <InputModelsBlock legend={'DESCONTO EM PORCENTAGEM ( % )'} 
                    onChange={handleChange} validation={needData.percentDiscont} 
                    maxLength={5} value={formData.percentDiscont}
                    name={'percentDiscont'} iconButton={faPercent} 
                    type={"number"} placeholder={"Porcentagem de Desconto..."}/>  
                    <Space top={5}/>
                    <InputModelsBlock legend={'VALOR EXTRA OU VENDA SEM REGISTRO'} 
                    onChange={handleChange} validation={needData.extraValue} 
                    maxLength={15} value={formData.extraValue} 
                    name={'extraValue'} iconButton={faMoneyCheckDollar}
                    type={"number"} placeholder={"Valor Extra..."}/>  
                    <Space top={5}/>
                    <InputModelsBlock 
                    legend={'DETALHAR ALGO SOBRE A VENDA'} 
                    onChange={handleChange} validation={needData.details}
                    maxLength={500} value={formData.details} name={'details'} 
                    iconButton={faNewspaper} type={"text"} 
                    placeholder={"Detalhar Algo Sobre a Venda..."}/>  
                </>}  
           </div>
        </div>
        {isVisible? <Alert  message={"Complete Todos os Campos em Vermelho"} onClose={() => setAlert(false)} type={"error"} />:null}
        {showFindClient ? <GetClientList onSelect={setSelectedClient} list={listClientsMemory} onClose={()=>setShowFindClient(false)}/> : null}
      </div>
    )
}

export default GetConfigureSale
