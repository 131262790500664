import {sendRequestPostLocalImage} from "./requesition";

export const getIdFromLink = (link) =>{
    var value = link.split('-');
    return value[2].split('.')[0];
}

export const getRandomNumber = () => {
    return Math.floor(Math.random() * 100000000) + 1;
}

export const uploadImageFormData = async (url,formData,showError) => {
    try {
        var response = await sendRequestPostLocalImage(url,formData)
        if(response.status !== 200) throw new Error(response.response.data.sqlMessage) 
        return response.data.imageUrl
    } catch (error) {
        showError("ERRO AO SALVAR FOTO",error.message)
        return {status:'404'}
    }
}

export const uploadImage = async (url,imageFile,showError) => {
    try {
        const formData = new FormData()
        formData.append('image', imageFile)
        var response = await sendRequestPostLocalImage(url,formData)
        if(response.status !== 200) throw new Error(response.response.data.sqlMessage) 
        return response.data.imageUrl
    } catch (error) {
        showError("ERRO AO SALVAR FOTO",error.message)
        return {status:'404'}
    }
}

export async function downloadImage(imageUrl, fileName) {
    try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error('Failed to download image');
        }
        const blob = await response.blob();
        const file = new File([blob], fileName);
        return file;
    } catch (error) {
        console.error('Error downloading image:', error);
        return null;
    }
}

export async function uploadImages(images, routerImage, body) {
    const imageUrls = [];
    try {
        const formData = new FormData();

        Object.keys(body).forEach(key => {
            formData.append(key, body[key]);
        })
      
        images.forEach((image, index) => {
            formData.append(`images${index}`, image);
        });

        const response = await sendRequestPostLocalImage(routerImage, formData)

        if (response.status === 200) {
            const responseData = await response.json();
            responseData.imageUrls.forEach(url => {
                imageUrls.push(url);
            });
        } else {
            const errorJson = await response.json();
            throw new Error(errorJson.sqlMessage);
        }
    } catch (error) {
        throw new Error(error.toString());
    }

    return imageUrls;
}