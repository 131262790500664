import React, { useState } from 'react';
import style from "../style/StateCountry.module.css"

const StatusClientDropDown = ({ initialState , handlerFuncion,name,disable}) => {
  const states = [
    "ATIVA", "DESATIVA", "PROBLEMA"
  ]

  const [state, setState] = useState(initialState || "");

  const handleChange = (event) => {
    setState(event.target.value);
    handlerFuncion(event)
  }

  return (
    <div>
        <select disabled={disable} name={name} className={style.state} onChange={handleChange} value={state}>
            {states.map((status, index) => (
                <option key={index} value={status}>{status}</option>
            ))}
        </select>
    </div>
  );
}


export default StatusClientDropDown;
