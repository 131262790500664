import { sendRequestPostLocal } from "../backEnd/requesition"
import LastVersionItem from "../objects/LastVersionItem"

const _routerGetLastVersionGenericProductsInCloud ='/genericProducts/lastVersion';
const _routerGetLastVersionItemInCloud ='/items/lastVersion'

export const getListLastVersion = async (idFind,isGeneric,showError) => {
    try {
        var results = []
        results = await sendRequestPostLocal(isGeneric?_routerGetLastVersionGenericProductsInCloud
            :_routerGetLastVersionItemInCloud,
        {
            'idGenericProducts':idFind,
            'idItem':idFind
        })
        const lastVersion = []
        if(Array.isArray(results.data)){
            Object.values(results.data).forEach(data =>{  
                const last = new LastVersionItem()
                last.fromCloud(data)
                lastVersion.push(last)
            })
        }else throw new Error(results.response.data.sqlMessage)
        return lastVersion
    } catch (error) {
        showError('ERROR AO BUSCAR O RELATÓRIO',error.message)  
        return []
    }
}