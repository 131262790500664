import { employeeData } from "../../backEnd/InitController";
import ClientsObject from "../ClientsAndPerson/clientsObject"
import { format } from 'date-fns';
import { formatDateToDatetimeLocal } from "./NFe";

 
export class NfeEmited{
    constructor(){
        this.mod = -1
        this.chNFe = ''
        this.idNFeEmited = -1
        this.dataEmite =''
        this.numberNFe = -1
        this.idClient = -1
        this.idSale = -1
        this.canceled = -1
        this.motionCanceled = ''
        this.dateCanceled = ''
        this.idEmployeeCanceled = -1
        this.xml = ''
        this.cstat = -1
        this.nProt = ''
        this.tpAmb = -1
        this.link = ''

        this.clientTemp = new ClientsObject()
        //this.saleTemp = new Sales()
        this.employeeCancelTemp = null
    }

    getMod(){
        return this.mod === 65 ? 'NFC-e' : 'NF-e'
    }

    getTpAmb(){
        return this.tpAmb === 2 ? 'TESTE' : 'PROD'
    }

    getStatus(){
        switch(this.cstat){
            case 100:
                return 'AUTORIZADO'
            case 101:
                return 'CANCELADO'
            case 110:
                return 'DENEGADO'
            case 204:
                return 'REJEITADO'
            case 302:
                return 'CONTIGENCIA'
            case 105:
                return 'EM PROCESSO'
            default:
                return this.cstat
        }
    }

    goLink(){
        window.open(this.link,'_blank')
    }

    goXml() {
        const blob = new Blob([this.xml], { type: 'application/xml' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
    }

    async getPdf(){     
        window.open('https://www.freenfe.com.br/leitor-de-xml-online','_blank')
    }
    
    

    goDownLoadXml() {
        
        const blob = new Blob([this.xml], { type: 'application/xml' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = this.getClientName() + '_' +  this.chNFe + '.xml'; 
        link.click()
        URL.revokeObjectURL(url);
    }

    getClientName(){
        return this.clientTemp._genericPerson.name
    }

    setCancel(descriptionCancel){
        this.motionCanceled = descriptionCancel
        this.idEmployeeCanceled = employeeData().idEmployee
        this.canceled = 1
        this.dateCanceled = new Date()
    }

    getMapFromCloud(){
        return{
            mod: this.mod,
            chNFe: this.chNFe,
            idNFeEmited: this.idNFeEmited,
            dhEvento: formatDateToDatetimeLocal(new Date()),
            dataEmite: this.dataEmite,
            numberNFe: this.numberNFe,
            idClient: this.idClient,
            idSale: this.idSale,
            canceled: this.canceled,
            motionCanceled: this.motionCanceled,
            dateCanceled: (this.dateCanceled === null || this.dateCanceled === '')? '' : format(this.dateCanceled, 'yyyy-MM-dd HH:mm:ss'),
            idEmployeeCanceled: this.idEmployeeCanceled,
            xml: this.xml,
            cstat: this.cstat,
            nProt: this.nProt,
            tpAmb: this.tpAmb,
            link: this.link,
        }
    }

    fromCloud(data){
        this.mod = data.mod
        this.idNFeEmited = data.idNFeEmited
        this.chNFe = data.chNFe
        this.dataEmite = data.dataEmite
        this.numberNFe = data.numberNFe
        this.idClient = data.idClient
        this.idSale = data.idSale
        this.canceled = data.canceled === null ? false : data.canceled
        this.motionCanceled = data.motionCanceled === null ? '' : data.motionCanceled
        this.dateCanceled = data.dateCanceled === null ? '' : data.dateCanceled
        this.idEmployeeCanceled = data.idEmployeeCanceled === null ? -1 : data.idEmployeeCanceled
        this.xml = data.xml
        this.cstat = data.cstat
        this.nProt = data.nProt
        this.tpAmb = data.tpAmb
        this.link = data.link

        this.clientTemp.fromCloud(data)
        //this.saleTemp.fromCloud(data,this.clientTemp,listProductsMemory,listItemsMemory,)
    }
}