import React, { useEffect, useState } from "react"
import { TitlePageResponsiveIcon } from "../../../layout/TitlePage"
import { faBoxesPacking, faDatabase, faLock, faRefresh, faScaleBalanced } from "@fortawesome/free-solid-svg-icons"
import style from "../../../style/AllStock.module.css"
import { GetSmallModels } from "../../../Models/GetModels"
import { stockAllController,CalculateStockAll } from "../../../backEnd/CalculateStock"
import Loader from "../../../layout/Loader"
import GetFloatingButton, { GetFloatingButtonWithLegend } from "../../../layout/GetFloatingButton"
import { useError, useLoading } from "../../../AppContext"
import StockCharts from "./StockCharts"
import { databaseSettings, initSystem, updateIfNull } from "../../../backEnd/Memory"
import { employeeData } from "../../../backEnd/InitController"

function AllStock(){
    const [canViewStock] = useState(employeeData().employeePermission.permissionsContabilite[0])
    const [loading, setLoading] = useState(false)
    const {showError} = useError()
    const {setIsLoading}  = useLoading()

    useEffect(() => {       
        async function fetchData() {
            setLoading(true)
            setIsLoading(true)   
            await updateIfNull(showError) 
            await CalculateStockAll()     
            setLoading(false)  
            setIsLoading(false)    
        }
   
        fetchData()
    }, [])

    const handleCalculate= async ()=>{
        setLoading(true)
        setIsLoading(true)
        await initSystem(showError) 
        await CalculateStockAll()  
        setLoading(false)
        setIsLoading(false)
    }

    return(
        <div>
            <div className={style.containerGlobal}>
            {canViewStock? 
            <>
            {loading === false ? (
                   <>
                   <StockCharts stockAll={stockAllController} />
                    <div className={style.container}>
                        {databaseSettings.isCanUseBrunaBJ?
                            <div className={style.grid}>
                                <div className={style.titleMenu}>
                                    <TitlePageResponsiveIcon subtitle={'Estoque Bruna Semijoias'}
                                        path={'São os Dados do Estoque da Bruna Semijoias...'} icon={faDatabase} />
                                    <GetSmallModels title={'Gramas no Estoque'} value={stockAllController.brunaSemijoiasStock.grams.toFixed(2) + ' Gramas'}
                                        subtitle={'É a Somatória de Todas as Gramas das Peças no Estoque...'} />
                                    <GetSmallModels title={'Peças Totais'} value={stockAllController.brunaSemijoiasStock.items + ' Unidades'}
                                        subtitle={'É a Somatória das Peças...'} />
                                    <GetSmallModels title={'Modelos Totais'} value={stockAllController.brunaSemijoiasStock.models + " Modelos"}
                                        subtitle={'É a Somatória dos Modelos de Peça...'} />
                                    <GetSmallModels title={'Valor de Custo'} value={"R$: " + stockAllController.brunaSemijoiasStock.coasts.toFixed(2)}
                                        subtitle={'É o Valor Total de Custo Estimado...'} />
                                    <GetSmallModels title={'Valor de Venda'} value={"R$: " + stockAllController.brunaSemijoiasStock.saleValue.toFixed(2)}
                                        subtitle={'É o Valor Total de Venda Estimado...'} />
                                </div>
                            </div>
                        :null}
                        <div className={databaseSettings.isCanUseBrunaBJ?style.grid : style.gridOnlyProducts}>
                            <div className={style.titleMenu}>
                                <TitlePageResponsiveIcon subtitle={'Estoque Produtos Genéricos'}
                                    path={'São os Dados do Estoque dos Produtos Genéricos...'} icon={faScaleBalanced} />
                                <GetSmallModels title={'Produtos Totais'} value={stockAllController.genericProductStock.items + " Unidades"}
                                    subtitle={'É a Somatória de Todos os Produtos...'} />
                                <GetSmallModels title={'Total de Marcas Associadas aos Produtos'} value={stockAllController.genericProductStock.brands}
                                    subtitle={'É a Somatória de Todas as Marcas Associadas aos Podutos...'} />
                                <GetSmallModels title={'Modelos Totais'} value={stockAllController.genericProductStock.models + " Modelos"}
                                    subtitle={'É a Somatória dos Modelos de Peça...'} />
                                <GetSmallModels title={'Valor de Custo'} value={"R$: " + stockAllController.genericProductStock.coasts.toFixed(2)}
                                    subtitle={'É o Valor Total de Custo Estimado...'} />
                                <GetSmallModels title={'Valor de Venda'} value={"R$: " + stockAllController.genericProductStock.saleValue.toFixed(2)}
                                    subtitle={'É o Valor Total de Vendas Estimado...'} />
                            </div>
                        </div>
                    </div>
                    <div className={style.titleMenu}>
                            <TitlePageResponsiveIcon subtitle={'Estimativa Total de Valores'} 
                            path={'São as Estimativas Totais do Estoques'} icon={faBoxesPacking}/>   
                            <GetSmallModels title={'Valor Total Estimado de Custo'} value={"R$: " +  stockAllController.getCoastGlobal().toFixed(2)} 
                            subtitle={'É a Somatória do Custo Total dois Estoques'}/>
                            <GetSmallModels title={'Valor Total Estimado de Venda'} value={"R$: " +  stockAllController.getSaleValueGlobal().toFixed(2)} 
                            subtitle={'É a Somatória do Valor de Venda dos Estoques'}/>
                    </div>
                   </>
                ) : (
                    <Loader titleFinding={'Realizando Calculos...'}
                        subtitleFinding={'Aguarde...'} loading={loading} />
                )}
                </>:
                <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar Valores Totais do Estoque'} />}
            </div>
            {
                window.innerWidth <= 1200 ?
                <GetFloatingButton handleFunction={handleCalculate}  icon={faRefresh} color='#ff0000' width={65} margiBottom={'1%'}  height={65} margiRight={'1%'}/>
                :<GetFloatingButtonWithLegend handleFunction={handleCalculate} legend={"Atualizar"} margiBottom={'1%'} icon={faRefresh} color='#ff0000' margiRight={'1%'} />
            }
           
        </div>
    )
}

export default AllStock