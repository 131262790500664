import { jwtDecode } from "jwt-decode";
import { Employee } from "../objects/ClientsAndPerson/employee";


export let employeeData = () => {
    const token = localStorage.getItem('tokenUser')
    if (token) {
      const userFromToken = jwtDecode(token)
      const employeeData = new Employee()
      employeeData.fromCloud(userFromToken)
      return employeeData
    }
}
