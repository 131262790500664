import React from "react"
import { faArrowRightToBracket, faScaleUnbalancedFlip } from "@fortawesome/free-solid-svg-icons"
import style from '../style/GetSalesOtherFactors.module.css'
import { TitlePageResponsiveIcon } from "../layout/TitlePage"
import { GetButton, GetButtonIcon } from "../layout/ButtonsLayout"
import GetAmountList from "./GetAmountList"

function GetSalesOtherFactors({otherFactors,handlerFunction}){
    return(
        <div className={style.divGlobal}>
            <TitlePageResponsiveIcon icon={faScaleUnbalancedFlip} title={'Fator de Multiplicação: '}
                subtitle={otherFactors.factor.toFixed(2)}
                path={'Somatória Total de Gramas da Peças Bruna Semijoias: ' + otherFactors.grams.toFixed(2)}
            />
            <div className={style.div}>
                <GetAmountList
                 title={'Vendas Nessa Relação de Fator...'} amount={otherFactors.totalSale}/>
                {otherFactors.saleInList.map((sale,index) =>(
                    <div className={style.sale}>
                        <div>
                            <p className={style.nameSale}>{sale.getCodeSale()}</p>
                            <div className={style.divRow}>
                                <p className={style.valueSale}>{sale.getTotalValueIncludeAllToString()}</p>
                                <p className={style.dataSale}>
                                    {'Data: ' + sale.detailsSale.getDateToString()}
                                </p>
                                <p className={style.cancel}>
                                    {sale.detailsSale.isPresent? 'BRINDE': ''}
                                </p>
                                <p className={style.cancel}>
                                    {sale.cancelSale.isCancel? 'CANCELADA': ''}
                                </p>
                            </div>
                        </div>
                        <div>
                          { window.innerWidth > 1200?
                            <>
                                <GetButton handleFunction={()=>handlerFunction(sale)}
                                title={'VISUALIZAR VENDA'} 
                                height={50} width={175} color='#007bff' 
                                icon={faArrowRightToBracket}/>
                            </>:
                            <>
                                <GetButtonIcon handleFunction={()=>handlerFunction(sale)}
                                height={50} width={50} color='#007bff' 
                                icon={faArrowRightToBracket}/>
                            </>
                          }
                        </div>
                    </div>
                ))}
            </div>        
        </div>
    )
}

export default GetSalesOtherFactors