import React, { useEffect, useState }  from "react"
import { TitlePageResponsiveIcon } from "../layout/TitlePage"
import { faBook, faBullhorn, faCheck, faClose, faMoneyBillWave, faPercent } from "@fortawesome/free-solid-svg-icons"
import { GetButton, GetButtonIcon } from "../layout/ButtonsLayout"
import style from '../style/GetNewBankPopop.module.css'
import Alert from '../Models/Alert.js';
import Space from "../layout/Space.js"
import InputModelsBlock from "../layout/InputModelsBlock.js"
import { GetSmallModels } from "./GetModels.js"
import { useError, useLoading } from "../AppContext.js"
import { requestPDFs } from "../controllers/CloudPdfs.js"
import { myStoreDataMemory } from "../backEnd/Memory.js"
import { getPercentage } from "../backEnd/ControllerGloba.js"
const getFormData = (valueStart) => {
    return {
        value:valueStart,
        percent:'10',
        message:'Promoção do Dia das Mães',
      
    }
}

const getFormNeed = () => {
    return {
        value:false,
        percent:false,
        message:false
    }
}

function GetAds({onClose,imageLink,value,name}){
    const [isVisible,setAlert] = useState(false)
    const [valueAlert,setValueAlert] = useState(false)
    const [finalValue,setFinalValue] = useState('0')
    const [formData, setFormData] = useState(getFormData(value))
    const [needData, setNeedData] = useState(getFormNeed)
    const {showError} = useError()
    const { setIsLoading } = useLoading()

    useEffect(()=>{
        calculate()
    },[formData])

    const calculate=()=>{
        let value = parseFloat(formData.value) - getPercentage(parseFloat(formData.value),parseFloat(formData.percent))
        setFinalValue(value)
    }

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
            calculate()
        }       
    }

    const handleSubmitSave= async() => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setValueAlert("Complete Todos os Campos em Vermelho")
            setAlert(true)
            return
        }

        await requestPDF()
    }

    const requestPDF = async()=>{
        setIsLoading(true)
        await requestPDFs({
            'type':'pdfAds',
            'imageUrl' : imageLink,
            'value': formData.value,
            'percent':formData.percent,
            'message': formData.message,
            'name': name,
            'valueFinal': finalValue,
            'myStore': myStoreDataMemory.getMapFromCloud()
          },showError)
        setIsLoading(false)
    }

    return(
        <div className={style.overlay}>
        <div className={style.alert}>
            <TitlePageResponsiveIcon title={"GERAR ANÚNCIO DE DIVULGAÇÃO"} 
                    children={
                        <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={onClose}/> 
                    }
                    path={"Configure os Valores..."} icon={faBullhorn}/>      
            <div className={style.div}>
                    <InputModelsBlock legend={'VALOR DE VENDA'} 
                    onChange={handleChange} validation={needData.value} maxLength={10}
                    value={formData.value}
                    name={'value'} iconButton={faMoneyBillWave} type={"number"} placeholder={"Valor de Venda..."}/>
                    <Space top={5}/>
                    <InputModelsBlock legend={'PORCENTAGEM DE DESCONTO'} 
                    onChange={handleChange} validation={needData.percent} maxLength={5}
                    value={formData.percent}
                    name={'percent'} iconButton={faPercent} type={"number"} placeholder={"Porcentagem do Desconto Aplicado..."}/>
                    <Space top={5}/>
                    <InputModelsBlock legend={'MENSAGEM'} 
                    onChange={handleChange} validation={needData.message} maxLength={500}
                    value={formData.message}
                    name={'message'} iconButton={faBook} type={"text"} placeholder={"Mensagem do Anúncio..."}/>
                    <Space top={5}/>
                    <GetSmallModels title={'Valor Final com Desconto'} value={finalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}/>
            </div>
            <Space top={5}/>
            <div className={style.divButton}>
                <GetButton title={'GERAR ANÚNCIO'} color='#007bff' icon={faCheck} handleFunction={handleSubmitSave}/> 
            </div>
           {isVisible? <Alert message={valueAlert} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
      </div>
    )
}

export default GetAds