import React, { useState } from 'react';
import style from '../style/GetNewBankPopop.module.css'
import { faClose, faAdd, faMapPin, faCalendar, faCalculator, faBackspace } from '@fortawesome/free-solid-svg-icons';
import { TitlePageResponsiveIcon } from './TitlePage';
import { GetButton, GetButtonIcon } from './ButtonsLayout';
import Alert from '../Models/Alert';
import InputModelsBlock from './InputModelsBlock';
import Space from './Space';
import { auxSale } from '../backEnd/Memory';

const getFormData = () => {
    return {
        condition:'',
        validate:'',
    }
}

const getFormNeed = () => {
    return {
        condition:false,
        validate:false,
    }
}

function GetBudget({onClose,onGenerate,containerData}) {

    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData)
    const [needData, setNeedData] = useState(getFormNeed)

    const handleClose = () => {
      onClose()
    } 

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmitSave= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setAlert(true)
            return
        }

        onGenerate(formData)
        handleClose()
    }

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
                <TitlePageResponsiveIcon subtitle={"GERAR ORÇAMENTO"} 
                children={<GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> }
                path={"Complete os Campos Abaixo Para Gerar um Orçamento"} icon={faCalculator}/>  
                <div className={style.div}>
                    <InputModelsBlock legend={'CONDIÇÃO E TERMOS - DETALHES'} 
                    onChange={handleChange} validation={needData.condition} maxLength={250} 
                    value={formData.condition} name={'condition'} iconButton={faMapPin} 
                    type={"text"} placeholder={"Condição e Detalhes..."}/>
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'VALIDO ATÉ'} onChange={handleChange} 
                    validation={needData.validate} maxLength={10} value={formData.validate} 
                    name={'validate'} iconButton={faCalendar} type={"date"} 
                    placeholder={"Validade da Proposta..."}/>  
                </div>
                <Space top={5}/>
                {containerData('')}
                <div className={style.div}>
                    {
                        auxSale.client?
                        <GetButton title={"GERAR ORÇAMENTO"} color='#007bff' icon={faAdd} handleFunction={handleSubmitSave}/> 
                        :  <GetButton title={"VOLTAR E CONFIGURAR OPERAÇÃO"} color='#FF0000' icon={faBackspace} handleFunction={onClose}/> }
                </div>
           {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
      </div>
    )
}

export default GetBudget
