import React, { useState } from "react"
import style from "../../style/ViewExtractMoth.module.css"
import GetNeedUpdate from "./GetNeedUpdate"
import { makeAccounting } from "../../objects/Accounting/MakeAccounting"
import { myMoth } from "../../backEnd/Memory"
import ViewMoth from "./ViewMoth"
import { employeeData } from "../../backEnd/InitController"
import { GetSmallModels } from "../../Models/GetModels"
import { faLock } from "@fortawesome/free-solid-svg-icons"

function ViewExtractMoth(){
    const [canViewMoths] = useState(employeeData().employeePermission.permissionsContabilite[11])
    return(
        <div>
            <div className={style.containerGlobal}>
            {canViewMoths? <>{makeAccounting.isCalculated === true ? (
                    <ViewMoth myMoth={myMoth}/>
                ) : (
                    <GetNeedUpdate/>
                )}</>: 
                <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar Extratos do Mês'} />}
                </div>
        </div>
    )
}

export default ViewExtractMoth