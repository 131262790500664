import React, { useState } from 'react';
import style from '../style/GetNewBankPopop.module.css'
import { faClose, faBank, faMoneyCheckDollar, faMoneyBillTrendUp, 
    faMoneyBillTransfer, faPiggyBank, faPerson, faVault, faAdd } from '@fortawesome/free-solid-svg-icons';
import { TitlePageResponsiveIcon } from './TitlePage';
import { GetButton, GetButtonIcon } from './ButtonsLayout';
import Alert from '../Models/Alert';
import InputModelsBlock from './InputModelsBlock';
import Space from './Space';
import { faPix } from '@fortawesome/free-brands-svg-icons';

const getFormData = () => {
    return {
        bankName:'',
        account:'',
        agency:'',
        typeAccount:'',
        ownerAccount:'',
        keycodePix:''
    }
}

const getFormNeed = () => {
    return {
        bankName:false,
        account:false,
        agency:false,
        typeAccount:false,
        ownerAccount:false,
        keycodePix:false,
    }
}

function GetNewBankPopop({onClose,handlerAdd}) {

    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData)
    const [needData, setNeedData] = useState(getFormNeed)

    const handleClose = () => {
      onClose()
    } 

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmitSave= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setAlert(true)
            return
        }

        handlerAdd(formData)
        handleClose()
    }

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
           <div className={style.title}>
                <TitlePageResponsiveIcon 
                children={
                    <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                }
                subtitle={"CADASTRAR NOVO BANCO"} path={"Complete os Campos Abaixo..."} icon={faBank}/>      
           </div>
           <div className={style.div}>
               <div className={style.divInRow}>
                    <InputModelsBlock legend={'NOME DO BANCO'} onChange={handleChange} validation={needData.bankName} maxLength={55} value={formData.bankName} name={'bankName'} iconButton={faMoneyCheckDollar} type={"text"} placeholder={"Nome do Banco..."}/>
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'TIPO DE CONTA'} onChange={handleChange} validation={needData.typeAccount} maxLength={25} value={formData.typeAccount} name={'typeAccount'} iconButton={faMoneyBillTrendUp} type={"text"} placeholder={"Tipo de Conta..."}/>  
               </div>
               <Space left={5} top={5}/>
               <div className={style.divInRow}>
                    <InputModelsBlock legend={'NÚMERO DA CONTA'} onChange={handleChange} validation={needData.account} maxLength={55} value={formData.account} name={'account'} iconButton={faPiggyBank} type={"text"} placeholder={"Conta..."}/>  
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'AGÊNCIA'} onChange={handleChange} validation={needData.agency} maxLength={55} value={formData.agency} name={'agency'} iconButton={faMoneyBillTransfer} type={"text"} placeholder={"Agência..."}/>    
               </div>
               <Space left={5} top={5}/>
               <div className={style.divInRow}>
                    <InputModelsBlock legend={'TITULAR DA CONTA'} onChange={handleChange} validation={needData.ownerAccount} maxLength={55} value={formData.ownerAccount} name={'ownerAccount'} iconButton={faPerson} type={"text"} placeholder={"Titular"}/>   
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'CHAVE PIX'} onChange={handleChange} validation={needData.keycodePix} maxLength={260} value={formData.keycodePix} name={'keycodePix'} iconButton={faPix} type={"text"} placeholder={"Chave Pix..."}/>  
               </div>
           </div>
           <Space left={5} top={5}/>
           <div className={style.div}>
            <GetButton title={"CADASTRAR NOVO BANCO"} color='#007bff' icon={faAdd} handleFunction={handleSubmitSave}/> 
           </div>
           {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
      </div>
    )
}

export default GetNewBankPopop
