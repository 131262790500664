import React from "react";
import style from "../../style/EditeMaster.module.css";
import { GetSmallModels } from "../../Models/GetModels";
import {faUser,faFloppyDisk,faPenToSquare,faBriefcase} from "@fortawesome/free-solid-svg-icons";
import styleGlobal from "../../style/Global.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputModels from "../../Models/InputModels";
import InputPhoto from "../../Models/InputPhoto";

function EditeMaster({isEdite}) {

    let nameButton = "Salvar Perfil Master";
    if(isEdite) nameButton = "Cadastrar Novo Perfil Master";

    return(
        <div className={style.containerGlobal}>
          <div className={style.wrap}>
          <GetSmallModels title="Cadrastrar Novo Perfil Master" 
          subtitle="É O Usúario com Poder Máximo..." 
          value="" icon={faUser}/>
            <div className={style.wrapLogin}>
                <form className={style.loginForm}>      
                 <InputPhoto/>                  
                 <InputModels iconButton={faPenToSquare} type={"text"} placeholder={"Nome do Perfil Master..."}/>        
                 <InputModels iconButton={faBriefcase} type={"text"} placeholder={"Qual o Cargo ou Função do Perfil..."}/>  
                </form>
            </div>
            <button className={styleGlobal.Btn}>
                <span>{nameButton}</span>
                <FontAwesomeIcon className={styleGlobal.BtnIcon} icon={faFloppyDisk} />
            </button>
            
          </div>
        </div>  
    )
}

export default EditeMaster;