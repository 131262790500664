import { employeeData } from "../backEnd/InitController"

class LastVersionItem{
    constructor(){
        this.idLastVersionItem = 0
        this.amount = 0
        this.amountNewVersion = 0
        this.dateLastEdition = ''
        this.nameLastSalleEdition =''
        this.action = ''
        this.idEmployee = 0
    }

    fromCloud(data){
        this.idLastVersionItem = data.idLastVersionItem
        this.amount = data.amount
        this.amountNewVersion = data.amountNewVersion
        this.dateLastEdition = data.dateLastEdition.replace('T', ' ').slice(0, 19)
        this.nameLastSalleEdition = data.nameLastSalleEdition
        this.action = data.action
        this.idEmployee = data.idEmployee
    }

    setAction(action){
        const date = new Date().toISOString().replace('T', ' ').slice(0, 19)
        let employee = employeeData()
        this.action = action
        this.dateLastEdition = date
        this.nameLastSalleEdition = employee.genericPerson.name
        this.idEmployee = employee.idEmployee;
    }
    
    
    getMapFromCloud(){
        const date = new Date().toISOString().replace('T', ' ').slice(0, 19)
        return{
          'idLastVersionItem':this.idLastVersionItem,
          'amount':this.amount,
          'dateLastEdition': date,
          'nameSelleLastEdition':this.nameLastSalleEdition,
          'action':this.action,
          'idEmployee':this.idEmployee,
        }
      }
}

export default LastVersionItem;