class TypePhotoSave{
    constructor() {
        this.linkImage = ''
        this.idListImage = 0
        this.loadPhotoNetwork = false
        this.nameImage = ''
    }

    fromInit(name,linkImage){
        this.linkImage = linkImage
        this.nameImage = name
        this.loadPhotoNetwork = false
    }

    fromCloud(data){
        this.linkImage = data.linkImage
        this.idListImage = data.idListImage
        this.loadPhotoNetwork = true
        this.nameImage = data.linkImage.toString().split('\\').pop().split('.').shift();
    }

    getFromMapSave(){
        return{
        'idListImage' :this.idListImage,
        'linkImage':this.linkImage,
        'nameImage':this.nameImage
        }
    }
}

export default TypePhotoSave