export class MathFinancial{
    constructor(){
        this.overdue = 0
        this.overdueToday = 0
        this.futureOverdue = 0
        this.receivers = 0
        this.paids = 0
        this.futureReceivers = 0
        this.total = 0
    }

    getOverdue(){
        return this.overdue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getOverdueToday(){
        return this.overdueToday.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getreceivers(){
        return this.receivers.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getfutureReceivers(){
        return this.futureReceivers.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getPaids(){
        return this.paids.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getfutureOverdue(){
        return this.futureOverdue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getTotal(){
        return this.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    clear(){
        this.paids = 0
        this.overdue = 0
        this.overdueToday = 0
        this.futureOverdue = 0
        this.receivers = 0
        this.futureReceivers = 0
        this.total = 0
    }

    calculate(list){
        this.clear()
        const dateNow = new Date().toISOString().slice(0, 10)

        list.forEach(ss=>{
            const ssDate = ss.date.toISOString().slice(0, 10)
            if(ss.category === 'DEBITOS' && ss.situation === 'EM ABERTO'){
                this.total -=ss.value
                if(ssDate< dateNow){
                    this.overdue += ss.value
                }

                if(ss.date.toISOString().slice(0, 10)=== dateNow){
                    this.overdueToday += ss.value
                }

                if(ssDate > dateNow ){
                    this.futureOverdue += ss.value
                }

            }else if(ss.category === 'DEBITOS' && ss.situation !== 'EM ABERTO'){
                this.total -=ss.value
                this.paids += ss.value
            }else if(ss.category !== 'DEBITOS'){
                this.total +=ss.value
                if(ssDate <= dateNow && ss.situation !== 'EM ABERTO'){
                    this.receivers += ss.value
                }

                if(ssDate > dateNow && ss.situation === 'EM ABERTO' ){
                    this.futureReceivers += ss.value
                }
            }
    
        })

       
    }
}