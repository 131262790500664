import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { errorRouter, routerFromExit, routerFromPerfilGlobal } from "../../routers/routerList.js";
import style from "../../style/Controller.module.css"
import { NavBarGlobal, ObjectNavbarMenu } from "../NavbarGlobal.js";
import { faRightFromBracket,faUserPen,faCircleUser} from "@fortawesome/free-solid-svg-icons";

function ControllerMyStore({isLoggedIn}){
    const location = useLocation();

    const menu1 = new ObjectNavbarMenu('Perfil do Usuário',faCircleUser)
    menu1.addMenu('Editar Perfil',faUserPen,routerFromPerfilGlobal)
    menu1.addMenu('Sair',faRightFromBracket,routerFromExit)
    
    const listRoutesToNav = [menu1];

    return isLoggedIn ? (
        <div className={style.containerGlobal}>
            <NavBarGlobal listObjectNavBarMenu={listRoutesToNav}/>
            <div className={style.containerData}>
               {(()=>{
                    switch(location.pathname){
                    case routerFromPerfilGlobal: return <></>;
                    case routerFromExit: return <></>;
                    default: return <Navigate to={errorRouter} replace />
                }
               })}
            </div>
        </div>
    ) : (
        <Navigate to="/login" replace />
    )
}

export default ControllerMyStore