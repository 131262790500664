import GenericProduct from "./Brands/Products/GenericProducts"
import ItemsSale from "./ItemBJ/ItemsSale"

class Reposition{ 
  constructor(){
    this.isGenericProduct = false
    this.idReposition = 0
    this.dateReposition =''
    this.nameReposition = ''
    this.statusReposition = ''
    this.items = []
  }

  getDate(){
    return this.dateReposition.replace("T",' ').slice(0,19)
  }

  Reposition(nameReposition,operationReposition,dateReposition,isGenericProduct){
    this.nameReposition = nameReposition
    this.statusReposition = operationReposition
    this.dateReposition = dateReposition
    this.isGenericProduct = isGenericProduct
  }

  fromCloud(data,listGenericProduct,listItems){
    this.idReposition = data.idReposition
    this.dateReposition =  data.dateReposition
    this.nameReposition =  data.nameReposition
    this.statusReposition = data.statusReposition
    this.isGenericProduct =  data.isGenericProduct === 1 ? true : false;
    var foundIndex

    if (data.items !== null) {
      for (var element of [...data.items]) {
        if(this.isGenericProduct){
            let currentElement = element
            foundIndex = listGenericProduct.findIndex(ss => ss.idGenericProducts === parseInt(currentElement.idProduct))
            if(foundIndex !== -1) {
                var genericTemp = new GenericProduct()
                genericTemp.fromOtherWithAmount(element.amount, listGenericProduct[foundIndex])
                genericTemp.valueCoast = parseFloat(element.coastValue)
                genericTemp.valueToSale = parseFloat(element.saleValue)
                this.items.push(genericTemp)
            }
        }else{
            let currentElement = element
            foundIndex = listItems.findIndex(ss => ss.idItem === parseInt(currentElement.idProduct))
            if(foundIndex !== -1){
                var newItemSale = new ItemsSale()
                newItemSale.fromSale(element,listItems[foundIndex].itemGlobal)
                this.items.push(newItemSale)
            }
        }
      }
    }
  }


  getMapFromSave(){
    return{
      'idReposition':this.idReposition
    };
  }

}

export default Reposition