import React from "react";
import { GetButtonIcon } from "./ButtonsLayout";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import style from "../style/BanksComponet.module.css"

function BanksComponet({bank,functionButton,disable}){

    const handlerFunction = ()=>{
        functionButton()
    }


    return(
        <div>
            <div className={style.Container}>
                <div className={style.data}>
                    <div className={style.dataItem}>
                        <h4>Banco:</h4>
                        <h5>{bank.bankName}</h5>
                    </div>
                    <div className={style.dataItem}>
                        <h4>Conta:</h4>
                        <h5>{bank.account}</h5>
                    </div>
                    <div className={style.dataItem}>
                        <h4>Agência:</h4>
                        <h5>{bank.agency}</h5>
                    </div>
                    <div className={style.dataItem}>
                        <h4>Tipo de Conta:</h4>
                        <h5>{bank.typeAccount}</h5>
                    </div>
                    <div className={style.dataItem}>
                        <h4>Titular:</h4>
                        <h5>{bank.ownerAccount}</h5>
                    </div>
                    <div className={style.dataItem}>
                        <h4>Pix:</h4>
                        <h5>{bank.keycodePix}</h5>
                    </div>
                </div>
                {disable? null: <GetButtonIcon width={35} height={35} color={'#ff0000'} icon={faRemove} handleFunction={handlerFunction}/>}
            </div>
        </div>
    )
}

export default BanksComponet