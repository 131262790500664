import React,{useState} from "react"
import style from "../style/LastEditionModel.module.css"
import { GetSmallModels } from "./GetModels"
import Space from "../layout/Space"
import { TitlePageResponsiveIcon } from "../layout/TitlePage"

function LastEditionModel({employee,lastVersion}) {
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)

    const handleImageLoad = () => {
        setImageLoaded(true); 
    }
    

    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }
    return(
        <div className={style.containerWrap}>    
                <div className={style.dataValues}>
                    <div className={style.name}>
                        {<TitlePageResponsiveIcon children={
                            <div className={style.imageContainer}>
                            {errorOccurred ? (
                                <img
                                src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                                alt="Erro de carregamento"
                                className={style.loaded}
                                />
                            ) : (
                            <img src={employee.linkImage} alt={employee.genericPerson.name} onLoad={handleImageLoad}
                                onError={handleImageError}
                                className={!imageLoaded ? style.loading : style.loaded}/> )}
                        </div> 
                        } title={employee.genericPerson.name} 
                        path={lastVersion.dateLastEdition}/>}
                    </div>
                        <Space left={5}/>
                        <GetSmallModels title={ window.innerWidth <= 768 ? 'Anterior -> ' + lastVersion.amount: 'Quantidade Anterior: ' + lastVersion.amount} 
                        subtitle={lastVersion.action} value={'Atual: ' +lastVersion.amountNewVersion}/>
                </div>
        </div>
    )
}

export default LastEditionModel