import { getListBrands } from "../controllers/CloudBrands"
import { getListClients } from "../controllers/CloudClients"
import { setFindFromCloud } from "../controllers/CloudDatabaseSettings"
import { getListEmployees } from "../controllers/CloudEmployee"
import { getListItemsBJ } from "../controllers/CloudItems"
import { getMyMoth } from "../controllers/CloudMoth"
import { getMyStore } from "../controllers/CloudMyStore"
import { setFindBJTax } from "../controllers/CloudNFeTax"
import { setFindNfeFromCloud } from "../controllers/CloudNFeSettings"
import { getListProducts } from "../controllers/CloudProducts"
import AuxSale from "../objects/AuxSale"
import DatabaseSettings from "../objects/DatabaseSettings"

import Moth from "../objects/Moth/Moth"
import MothPay from "../objects/MothPay"
import { Emit, SettingsNFE } from "../objects/NFe/NFe"
import { Search } from "./Search"

export let alertsNotices = []
export let listItemsMemory = []
export let listProductsMemory = []
export let listBrandsMemory = []
export let listClientsMemory = []
export let listEmployeeMemory = []
export let myStoreDataMemory = null
export let myMoth = new Moth()
export let mothPay = new MothPay()
export let databaseSettings = new DatabaseSettings()
export let search = new Search()
export let auxSale
export const brunaSemijoiasNFE = new SettingsNFE()
export let emit = new Emit()

export const resetAll = async() =>{
    myMoth = new Moth()
    auxSale = new AuxSale()
    mothPay = new MothPay()
    databaseSettings = new DatabaseSettings()
    search = new Search()
    listItemsMemory = []
    listProductsMemory = []
    listBrandsMemory = []
    listClientsMemory = []
    listEmployeeMemory = []
    myStoreDataMemory = null
}

export const findMyMoth = async (showError) => {
    await initSystem(showError)
}

export const findBjItems = async (showError) => {
    await findMyStore(showError)
    await findOnlyBj(showError)
}

export const findNFeSettings = async(showError)=>{
    await setFindNfeFromCloud(showError)
}

export const findBjSettingsTax = async(showError) => {
    if(databaseSettings.isCanUseBrunaBJ) await setFindBJTax(showError)
}

export const findOnlyBj = async(showError)=>{
    listItemsMemory = databaseSettings.isCanUseBrunaBJ? await getListItemsBJ(showError) : []
}

export const findClients = async (showError) => {
    listClientsMemory = await getListClients(showError)
}

export const findEmployees = async (showError) => {
    listEmployeeMemory = await getListEmployees(showError)
}

export const findBrands = async (showError) => {
    listBrandsMemory = await getListBrands(showError)
}

export const findproduts = async (showError) => {
    await findBrands(showError)
    listProductsMemory = await getListProducts(showError)
}

export const findMyStore = async(showError) =>{
    myStoreDataMemory = await getMyStore(showError)
    if(auxSale.factor === 0)
        auxSale.factor = myStoreDataMemory.profitFactor
}

export const findDatabase =async (showError) =>{
    await setFindFromCloud(showError)
    search = new Search()
    return databaseSettings
}

export const initSystem = async (showError) => {
    auxSale = new AuxSale()
    myMoth = new Moth()
    await findDatabase(showError)
    findBjSettingsTax(showError)
    await findMyStore(showError)
    await findNFeSettings(showError)
    await findOnlyBj(showError)
    await findClients(showError)
    await findEmployees(showError)
    await findproduts(showError)
    await getMyMoth(showError,listProductsMemory,listItemsMemory,listEmployeeMemory,listClientsMemory)
}

export const updateIfNull = async (showError) => {
    if(!myStoreDataMemory){
        await initSystem(showError)
        return true
    }

    return false
}

export const updateFromRepositions = async (showError) => {
    await findMyStore(showError)
    await findOnlyBj(showError)
    await findproduts(showError)
}

export const updateAfterSale = async(showError) =>{
    await findOnlyBj(showError)
    await findproduts(showError)
    await findClients(showError)
}

export const updateFromSale = async (showError) => {
    await findMyStore(showError)
    await findOnlyBj(showError)
    await findproduts(showError)
    await findClients(showError)
    await getMyMoth(showError,listProductsMemory,listItemsMemory,listEmployeeMemory,listClientsMemory)
}

window.addEventListener('beforeunload', () => {
    initSystem(null)
})

//setInterval(initSystem, 300000)
