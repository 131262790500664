import React from "react";
import style from "../style/TitlePage.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//<FontAwesomeIcon  className={styles.menuTranstion} icon={faUsers} />
function TitlePage({title, subtitle,path}){
    return(
        <div className={style.containerGlobal}>
            <div className={style.titles}>
                <h1>{title} /</h1>
                <h4>{subtitle}</h4>
                <hr/>
            </div>
            <div className={style.cantainerPath}>
                <h6>{path}</h6>
            </div>
        </div>
    )
}

export function TitlePageResponsive({title, subtitle,path, children}){
    return(
       <>
         <div className={style.containerGlobalResponsive}>
         <div className={style.divTitle}>
            <div className={style.titles}>
                <h1>{title} /</h1>
                <h4>{subtitle}</h4>
            </div>
            <div className={style.cantainerPath}>
                <h6>{path}</h6>
            </div>
        </div>
        </div>
            {children? 
            <div>
                {children}
            </div>: 
        null}
       </>
    )
}

export function TitlePageResponsiveIcon({title, subtitle,path,icon,children}){
    
    return(
        <div className={style.containerGlobalResponsive}>
           <div className={style.divTitle}>
                <div className={style.titles}>
                    {icon? <FontAwesomeIcon className={style.iconsTitlee} icon={icon}/>:null}
                    <h1>{title}</h1>
                    <h4>{subtitle}</h4>
                </div>
                <div className={style.cantainerPath}>
                    <h6>{path}</h6>
                </div>
           </div>
            {children? 
            <div>
                {children}
            </div>: 
            null}
        </div>
    )
}


export function TitlePageResponsiveIconExpansive({title, subtitle,path,icon,children}){
    
    return(
        <div className={style.containerGlobalExpansive}>
           <div className={style.divTitle}>
                <div className={style.titles}>
                    {icon? <FontAwesomeIcon className={style.iconsTitlee} icon={icon}/>:null}
                    <h1>{title}</h1>
                    <h4>{subtitle}</h4>
                </div>
                <div className={style.cantainerPath}>
                    <h6>{path}</h6>
                </div>
           </div>
            {children? 
            <div>
                {children}
            </div>: 
            null}
        </div>
    )
}

export default TitlePage