import { faClose, faTicket, faWarning } from '@fortawesome/free-solid-svg-icons'
import { GetButtonIcon } from '../../layout/ButtonsLayout'
import { TitlePageResponsiveIcon } from '../../layout/TitlePage'
import style from '../../style/GetNewBankPopop.module.css'
import { useEffect, useState } from 'react'
import { brunaSemijoiasNFE, emit } from '../../backEnd/Memory'
import { GetSmallModels } from '../../Models/GetModels'
import GetNewNFe from './GetNewNFe'
import { Det, NFe } from '../../objects/NFe/NFe'

export function GetSaleNFe({onClose,sale}) {
  
    const [nfe] = useState(new NFe(emit.getMapFromCloud()))
    const [saleWithoutProduct,setSaleWithoutProduct] = useState(false)
    const [canEmit,seCanEmit] = useState(true)
    const [cantEmit] = useState(emit.idNFESettings === 0)
    const [isLoading,setLoading] = useState(true)

    const handleClose = () => {
      onClose()
    } 

    useEffect(()=>{
        setLoading(true)
        const list = []
        let count = 1
        sale.listGenericProduct.genericProducts.forEach(element => {
            if(!element.tempBrand.settingsNFE.configured)
                seCanEmit(false)

            const detTemp = new Det()
            detTemp.nItem = count
            detTemp.prod.fromProduct(element,element.tempBrand.settingsNFE)
            detTemp.ConfigureToEmitFromSale(element.tempBrand.settingsNFE)
            list.push(detTemp)
            count++
        })

        if(sale.itemsThisSale.listItems.length > 0){
            if(!brunaSemijoiasNFE.configure){
                seCanEmit(false)  
            }
           
            sale.itemsThisSale.listItems.forEach(item => {
                const detTemp = new Det()
                detTemp.nItem = count
                detTemp.prod.fromBJ(item,brunaSemijoiasNFE)
                detTemp.ConfigureToEmitFromSale(brunaSemijoiasNFE)
                list.push(detTemp)
                count++
            })
        }

        if(sale.listGenericProduct.totalCountGenericProducts === 0 &&sale.itemsThisSale.totalItems ===0 )
            setSaleWithoutProduct(true)

        nfe.fromFastEmitter(sale.thisOwner,sale.idSaleCloud,list,sale.totalValueIncludeAll)
        setLoading(false)
    },[sale])

    if(saleWithoutProduct){
        return (
            <div className={style.overlay}>
              <div className={style.alert}>
                 <div className={style.title}>
                      <TitlePageResponsiveIcon 
                      children={
                          <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                      }
                      subtitle={"EMITIR NOTA FISCAL NFE PARA ESSA VENDA"} path={"Escolha o Modelo de Emissão..."} icon={faTicket}/>      
                    </div>
                    <GetSmallModels icon={faWarning} backgroundColor={'#ff0000'} title={'NÃO É POSSIVEL EMITIR NOTA FISCAL'} subtitle={
                                'Essa Venda Não Tem Produtos'
                    }/>
                </div>
            </div>
          )
    }

    if(!canEmit || cantEmit){
        return (
            <div className={style.overlay}>
              <div className={style.alert}>
                 <div className={style.title}>
                      <TitlePageResponsiveIcon 
                      children={
                          <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                      }
                      subtitle={"EMITIR NOTA FISCAL NFE PARA ESSA VENDA"} path={"Aviso Importante de Configuração"} icon={faTicket}/>      
                    </div>
                    <GetSmallModels icon={faWarning} backgroundColor={'#ff0000'} title={'NÃO É POSSIVEL EMITIR NOTA FISCAL'} subtitle={
                    'A configuração do emissor ou dos impostos da marca estão incompletas.'
                    }/>
                </div>
            </div>
          )
    }

    
    if(isLoading){
        return (
            <div className={style.overlay}>
              <div className={style.alert}>
              </div>
            </div>
          )
    }

    return (<GetNewNFe onEmit={handleClose} nfe={nfe} onClose={()=>onClose()}/>)
}