import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import InputModelsBlock from "../../layout/InputModelsBlock";
import style from '../../style/GetNFeConfigureCompany.module.css'
import { faAngleDown, faAngleUp, faCalendar, faCalendarAlt, faDesktopAlt, faListNumeric, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import Space from "../../layout/Space";
import Alert from "../../Models/Alert";
import { TitlePageResponsiveIcon } from "../../layout/TitlePage";
import { GetButtonIcon } from "../../layout/ButtonsLayout";
import { GetSmallModels } from "../../Models/GetModels";
import { GenericDropDown } from "../../layout/GenericDropDown";

const getFormData = (data) => {
    return {
        cUF :data.cUF,
        cNF :data.cNF,
        natOp :data.natOp,
        mod:data.mod,
        serie :data.serie,
        nNF :data.nNF,
        dhEmi :data.dhEmi,
        tpNF  :data.tpNF,
        cMunFG  :data.cMunFG,
        tpImp :data.tpImp,
        tpEmis :data.tpEmis,
        cDV :data.cDV,
        tpAmb :data.tpAmb,
        finNFe :data.finNFe,
        indFinal :data.indFinal,
        indPres :data.indPres,
        procEmi :data.procEmi,
        verProc :data.verProc,
        dhSaiEnt:data.dhSaiEnt,
        idDest:data.idDest,
    }
}

const getFormNeed = () => {
    return {
        natOp:false,
        serie:false,
        dhEmi:false,
        dhSaiEnt :false,
        tpNF :false,
        tpImp  :false,
        tpEmis :false,
        tpAmb:false,
        finNFe:false,
        indFinal :false,

        idDest:false,
        indPres :false,
        finNFe:false,
        indFinal :false,
        verProc:false,
    }
}

export const GetNfeData = forwardRef(({title,initialExpand,nfe}, ref) => {

    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData(nfe.ide))
    const [needData, setNeedData] = useState(getFormNeed)
    const [expanded, setExpand] = useState(initialExpand?? true)
    const [dataTpImp, setDataTpImp] = useState(['4 - DANFE NFC-e', '5 - DANFE NFC-e em mensagem eletrônica'])

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const checkCurrentDanfe = () =>{
        if (formData.mod.includes('55')) {
            formData.tpImp = '1 - Retrato'
            setDataTpImp(['0 - Sem DANFE', '1 - Retrato', '2 - Paisagem', '3 - Simplificado',])
        }else if (formData.mod.includes('65')){
            formData.tpImp = '4 - DANFE NFC-e'
            setDataTpImp(['4 - DANFE NFC-e', '5 - DANFE NFC-e em mensagem eletrônica'])
        }
    }

    useEffect(()=>{
        checkCurrentDanfe()
    },[formData])

    useImperativeHandle(ref, () => ({
        handleSubmit
    }))

    const handleSubmit= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setAlert(true)
            return false
        }

        return formData

    }

    return (
        <div className={style.div}>
            <TitlePageResponsiveIcon 
                children={
                    <GetButtonIcon color='#094f00' height={40} width={40} icon={expanded? faAngleUp :  faAngleDown} handleFunction={()=>setExpand(!expanded)}/> 
                }
                subtitle={title} path={"Configure o Endereço Abaixo..."} icon={faPaperPlane}/> 
            <div className={expanded? style.visible :   style.notVisible}>
                <GetSmallModels title={'Código Numérico'} subtitle={'Código numérico que compõe a chave de acesso da NF-e.'} value={nfe.ide.cNF}/>
                <GetSmallModels title={'Número da NF-e'} subtitle={'Número sequencial da NF-e.'} value={nfe.ide.nNF}/>
                <GetSmallModels title={'Dígito Verificador'} subtitle={'Número sequencial da NF-e.'} value={nfe.ide.cDV}/>
                <div className={style.divInRow}> 
                    <InputModelsBlock legend={'NATUREZA DA OPERAÇÃO'} onChange={handleChange} validation={needData.natOp} maxLength={45} value={formData.natOp} name={'natOp'} iconButton={faDesktopAlt} type={"text"} placeholder={"Ex: 'Venda Semijoias'"}/>
                    <Space left={5} top={5}/>
                    <InputModelsBlock legend={'NÚMERO DE SÉRIE DA NF-e.'} onChange={handleChange} validation={needData.serie} maxLength={10} value={formData.serie} name={'serie'} iconButton={faListNumeric} type={"number"} placeholder={"Número da série da NF-e...."}/>  
                    <Space left={5} top={5}/>
                    <GetSmallModels title={'DATA E HORA DA EMISSÃO'} subtitle={formData.dhEmi}/>
                    <Space left={5} top={5}/>
                    <GetSmallModels title={'DATA E HORA SAÍDA OU ENTRADA'}  subtitle={formData.dhSaiEnt}/>                
                </div>
                <Space left={5} top={5}/>
                <GenericDropDown legend={'MODELO DA OPERAÇÃO'} data={['55 - NFe Venda Online', '65 - NFEc Venda Presencial']} name={'mod'} initialState={formData.mod} handlerFuncion={handleChange}  />
                <Space left={5} top={5}/>
                <div className={style.divInRow}>
                    <GenericDropDown legend={'TIPO DA OPERAÇÃO'} data={['0 - Entrada', '1 - Saída']} name={'tpNF'} initialState={formData.tpNF} handlerFuncion={handleChange}  />
                    <Space left={5} top={5}/>
                    <GenericDropDown legend={'IDENTIFICADOR DE DESTINO'} data={['1 - Operação interna', '2 - Operação interestadual', '3 - Operação com exterior']} name={'idDest'} initialState={formData.idDest} handlerFuncion={handleChange}  />
                    <Space left={5} top={5}/>
                    <GenericDropDown legend={'TIPO DE IMPRESSÃO'} data={dataTpImp} name={'tpImp'} initialState={formData.tpImp} handlerFuncion={handleChange}  />
                    <Space left={5} top={5}/>
                    <GenericDropDown legend={'TIPO DE EMISSÃO'} data={['1 - Normal']} name={'tpEmis'} initialState={formData.tpEmis } handlerFuncion={handleChange}  />
                </div>
                <Space left={5} top={5}/>
                <div className={style.divInRow}>
                    <GenericDropDown legend={'TIPO DE AMBIENTE'} data={['1 - Produção', '2 - Homologação']} name={'tpAmb'} initialState={formData.tpAmb} handlerFuncion={handleChange}  />
                    <Space left={5} top={5}/>
                    <GenericDropDown legend={'FINALIDADE DA EMISSÃO'} data={['1 - Normal']} name={'finNFe'} initialState={formData.finNFe} handlerFuncion={handleChange}  />
                    <Space left={5} top={5}/>
                    <GenericDropDown legend={'PARA CONSUMIDOR FINAL'} data={['0 - Não', '1 - Sim']} name={'indFinal'} initialState={formData.indFinal} handlerFuncion={handleChange}  />
                    <Space left={5} top={5}/>
                    <GenericDropDown legend={'PRESENÇA DO COMPRADOR'} data={['0 - Não se aplica', '1 - Operação presencial', '2 - Operação não presencial']} name={'indPres'} initialState={formData.indPres} handlerFuncion={handleChange}  />
                </div>
            </div>
            {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
    )
})