import React, { useState, useEffect, useRef } from 'react';
import style from "../../style/ListClients.module.css"
import { GetSmallModels } from '../../Models/GetModels';
import { faMagnifyingGlass,faCircleInfo, faPlus, faDownload, faList} from '@fortawesome/free-solid-svg-icons';
import Loader from '../../layout/Loader';
import InputModels from "../../Models/InputModels.js"
import GetAmountList from '../../Models/GetAmountList.js';
import GetFloatingButton from '../../layout/GetFloatingButton.js';
import Space from '../../layout/Space.js';
import { GetButton } from '../../layout/ButtonsLayout.js';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage.js';
import { routerFromMyStoreAddEmployees } from '../../routers/routerList.js';
import { useNavigate } from 'react-router-dom';
import EmployeeModel from '../../Models/EmployeeModel.js';
import EditeEmployee from '../Employees/EditeEmployee.js';
import GetExpandedImage from '../../layout/GetExpandedImage.js';
import Alert from '../../Models/Alert.js';
import { useError } from '../../AppContext.js';
import { findEmployees, listEmployeeMemory, updateIfNull } from '../../backEnd/Memory.js';

function ListEmployee() {
  const navigate = useNavigate()
  const containerRef = useRef(null)

  const [isVisible,setAlert] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [nameEmployee, setNameProduct] = useState('')
  const [linkImage, setSetLinkImage] = useState('')

  const [showEditeEmployee, setEditeEmployee] = useState(false)
  const [employeeSelected, setEmployeeSelected] = useState(null)
  const [limit, setLimit] = useState([50])

  
  const [list, setList] = useState(listEmployeeMemory)
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState('active');
  const [orderBy, setOrderBy] = useState('asc');

  const [loading, setLoading] = useState(false)
  
  const {showError} = useError()

  useEffect(() => {
    async function fetchData() {
        setLoading(true)
        setList([])
        await updateIfNull(showError)  
        setList(listEmployeeMemory)
        setLoading(false) 
    }
    fetchData()
  }, [])


  const handleFindDataBase = async () => {
    setLoading(true)
    setList([])
    await findEmployees(showError)
    setList(listEmployeeMemory)
    setFilter('active')
    setOrderBy('asc')
    setLoading(false)
  }

  useEffect(() => {
    handleChangeFilter()
  }, [filter, orderBy,list])

  const controllerFilter = () => {
    let data = []
    switch (filter) {
      case 'all':
        data = list
        break
      case 'active':
        data = list.filter(item => item.status)
        break
      case 'desactive':
        data = list.filter(item => !item.status)
        break  
      default:
        data = list
        break
    }

    let sorted = [...data]
 
    switch (orderBy) {
      case 'asc':
        sorted.sort((a, b) => a.genericPerson.name.localeCompare(b.genericPerson.name))
        break
      case 'desc':
        sorted.sort((a, b) => b.genericPerson.name.localeCompare(a.genericPerson.name))
        break
      default:
        break
    }
    return sorted;
  }

  const handleFilterChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }
  
  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()
    resetScroll()
    if(value!= null){
      const filteredClients = data.filter(
        client => {
          const filtercpf = client.genericPerson.cpf.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterName= client.genericPerson.name.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterRg = client.genericPerson.rg.toString().toLowerCase().includes(value.target.value.toLowerCase())
          return filtercpf || filterName || filterRg
        }
      )
      setFiltered(filteredClients)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    container.scrollTop = 0
    setLimit(50)
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  const handleAddEmployee = async (event) => {
    navigate(routerFromMyStoreAddEmployees)
  }

  const handleEmployeeSelected = (employee) =>{
    setEmployeeSelected(employee)
    setEditeEmployee(true)
  }

  const toggleImageExpansion = (nameEmployee,linkImage) => {
    setIsExpanded(!isExpanded)
    setNameProduct(nameEmployee)
    setSetLinkImage(linkImage)
  }

  const handlerOnSave = () =>{
    handleFindDataBase()
    setAlert(true)
  }

  return (
    <div className={style.containerGeneral}>  
       <div className={style.containerGlobal}>  
           <div className={style.titleMenu}>     
             <TitlePageResponsiveIcon subtitle={'Lista Completa com Todos os Funcionários'} 
             children={
                <>
                  {!loading?<GetButton title={'BUSCAR NO SERVIDOR'} responsive={true}
                  color='#007bff' icon={faDownload} handleFunction={handleFindDataBase}/>:null}
                </>
             }
             path={'São Todos Funcionários Cadastrados no Sitema...'} icon={faList}/>
             </div>
           <div className={style.buildList}>
             <div className={style.filter}>
                 <div className={style.inputFilter}>
                   <InputModels iconButton={faMagnifyingGlass} 
                   type={"text"} placeholder={"Buscar Funcionário..."}
                   onChange={handleChangeFilter}/>
                 </div>
                 <div className={style.containerDropDown}>
                  <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                    <option value="all">TODOS</option>
                    <option value="active">ATIVOS</option>
                    <option value="desactive">DESATIVOS</option>            
                  </select>
                   <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                       <option value="asc">CRESCENTE</option>
                       <option value="desc">DESCRESCENTE</option>
                   </select>
                 </div>
             </div>
             <Space right={2} left={2} top={5} bottom={0}/> 
             {(filtered.length > 0 && !loading ) ?<div className={style.buildList}>
                <GetAmountList amount={filtered.length} title={"Funcionários Totais Encontrados na Busca..."}/>
                <div className={style.labelData}>
                  <h5>Nome</h5>
                  <h5>Status</h5>
                </div>
               <Space right={0} left={0} top={0} bottom={10}/> 
             </div> : null}
             <div ref={containerRef} className={style.containerList} onScroll={checkScroll}>
                  <div className={style.loaderCenter}>
                    <Loader titleFinding={'Buscando no Servidor!'} 
                         subtitleFinding={'Aguarde...'} loading={loading}/>
                  </div>
                 <div>
                   {(filtered.length === 0 && !loading)?
                   <GetSmallModels title={"Não há Funcionários com Esse Filtro"}
                   subtitle={"Tente Trocar o Filtro..."}
                   icon={faCircleInfo} />:
                     filtered.slice(0, limit).map((employee, index) => (
                         <div key={index}>
                          <EmployeeModel data={employee} 
                          functionImage={()=>toggleImageExpansion(employee.genericPerson.name,employee.linkImage)}
                          fucntionButton={() => handleEmployeeSelected(employee)} />
                         </div>
                     ))
                   }
                 </div>
             </div>
           </div>
         {isVisible? <Alert message={"Edição Salva Com Sucesso"} top={90} right={25} onClose={() => setAlert(false)} type={"success"} />:null}
         {isExpanded? <GetExpandedImage nameProduct={nameEmployee} linkImage={linkImage} onClose={toggleImageExpansion}/>:null}
         {showEditeEmployee? <EditeEmployee onSave={handlerOnSave} employee={employeeSelected} onClose={()=>setEditeEmployee(false)}/> : null}
         <GetFloatingButton icon={faPlus} color='#007bff' width={window.innerWidth <= 1200 ? 65:85} margiBottom={'1%'}  height={window.innerWidth <= 1200 ? 65:85} margiRight={'1%'} handleFunction={handleAddEmployee} />
       </div>
    </div>
   )

}

export default ListEmployee