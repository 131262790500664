import GenericPersonObject from './genericPersonObject.js';
import AddressObject from './addressObject.js';
import BankObject from './bankObject.js';
import CompanyObject from './companyObject.js';
import { employeeData } from '../../backEnd/InitController.js';
import { myStoreDataMemory } from '../../backEnd/Memory.js';
import { requestPDFs } from '../../controllers/CloudPdfs.js';
import { contactObject } from './contactObject.js';

class ClientsObject{

    constructor(){ 
      this.IdClient = 0
      this.isOpenSales = false
      this.statusClient = 'ATIVA'

      this._genericPerson = new GenericPersonObject()
      this._contact = new contactObject()
      this._clientAddress = new  AddressObject()
      this._clientBank = new BankObject()
      this._clientCompany = new CompanyObject()
    }

    async openPDFClient(showError){
      const body = {
        'type':'pdfClientData',
        'employee': employeeData().genericPerson.name,
        'clientData': this.getMapFromCloud(),
        'myStore': myStoreDataMemory.getMapFromCloud()
      }
      await requestPDFs(body,showError)
    }

    fromFormData(data){
      this.IdClient = data.IdClient
      this.isOpenSales = data.isOpenSales
      this.statusClient = data.statusClient
 
      this._genericPerson = new GenericPersonObject()
      this._genericPerson.fromCloud(data)

      this._contact = new contactObject()
      this._contact.fromCloud(data)

      this._clientAddress = new  AddressObject()
      this._clientAddress.fromCloud(data)

      this._clientBank = new BankObject()
      this._clientBank.fromCloud(data)

      this._clientCompany = new CompanyObject()
      this._clientCompany.fromCloud(data)
    }

    fromCloud(data){
      this.IdClient = data.IdClient
      this.isOpenSales = data.isOpenSales === 1? true : false
      this.statusClient = data.status
 
      this._genericPerson = new GenericPersonObject()
      this._genericPerson.fromCloud(data)

      this._contact = new contactObject()
      this._contact.fromCloud(data)

      this._clientAddress = new  AddressObject()
      this._clientAddress.fromCloud(data)
      
      this._clientBank = new BankObject()
      this._clientBank.fromCloud(data)

      this._clientCompany = new CompanyObject()
      this._clientCompany.fromCloud(data)
    }

    getMapFromCloud(){
        return {
          'IdClient':this.IdClient,
          'isOpenSales':this.isOpenSales,
          'container-person':this._genericPerson.getMapFromCloud(),
          'container-contact':this._contact.getMapFromCloud(),
          'container-address':this._clientAddress.getMapFromCloud(),
          'container-company':this._clientCompany.getMapFromCloud(),
          'container-bank':this._clientBank.getMapFromCloud(),
          'statusClient':this.statusClient,
        }
    }
}

export default ClientsObject;