import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';


const SalesPerformanceChart = ({ repairs,sales,listReceivers, width, height  }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        renderChart();
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [sales, width, height]);

    const organizeSalesByDate = () => {
      const organizedSales = {};
      sales.forEach(sale => {
          const date = new Date(sale.detailsSale.date);
          const formattedDate = date.toISOString().slice(0, 10); // Formata a data para AAAA-MM-DD
          if (!organizedSales[formattedDate]) {
              organizedSales[formattedDate] = {
                  normal: 0,
                  present: 0,
                  cancel: 0,
                  receivers: 0,
                  repairs: 0,
                  repairsCancel:0,
                  repairsDelivered: 0
              };
          }
          if (!sale.cancelSale.isCancel && !sale.detailsSale.isPresent) {
              organizedSales[formattedDate].normal += sale.totalValueIncludeAll;
          } else if (!sale.cancelSale.isCancel && sale.detailsSale.isPresent) {
              organizedSales[formattedDate].present += sale.totalValueIncludeAll;
          } else if (sale.cancelSale.isCancel) {
              organizedSales[formattedDate].cancel += sale.totalValueIncludeAll;
          } 
      });
    
      listReceivers.forEach(ss => {
          const date = new Date(ss.dataPaid);
          const formattedDate = date.toISOString().slice(0, 10); // Formata a data para AAAA-MM-DD
          if (!organizedSales[formattedDate]) {
              organizedSales[formattedDate] = {
                  normal: 0,
                  present: 0,
                  cancel: 0,
                  receivers: 0,
                  repairs: 0,
                  repairsCancel:0,
                  repairsDelivered: 0
              };
          }
    
          organizedSales[formattedDate].receivers += ss.totalValuePaid;
      });

      repairs.forEach(ss => {
        const date = new Date(ss.date)
        const formattedDate = date.toISOString().slice(0, 10); // Formata a data para AAAA-MM-DD
        if (!organizedSales[formattedDate]) {
            organizedSales[formattedDate] = {
                normal: 0,
                present: 0,
                cancel: 0,
                receivers: 0,
                repairs: 0,
                repairsCancel:0,
                repairsDelivered: 0
            };
        }
        if(ss.status === 2){
            organizedSales[formattedDate].repairsCancel += parseFloat(ss.value)
        }else if(ss.status === 1){
            organizedSales[formattedDate].repairsDelivered += parseFloat(ss.value)
        }else{
            organizedSales[formattedDate].repairs += parseFloat(ss.value)
        }
        
    });
    
      return organizedSales;
    };

    const getChartData = () => {
        const organizedSales = organizeSalesByDate()
        const labels = Object.keys(organizedSales)
        const normalData = []
        const presentData = []
        const cancelData = []
        const Receivers = []
        const RepairsCancel = []
        const Repairs = []
        const RepairsDelivered = []
        labels.forEach(date => {
            normalData.push(organizedSales[date].normal)
            presentData.push(organizedSales[date].present)
            cancelData.push(organizedSales[date].cancel)
            Receivers.push(organizedSales[date].receivers)
            RepairsCancel.push(organizedSales[date].repairsCancel)
            Repairs.push(organizedSales[date].repairs)
            RepairsDelivered.push(organizedSales[date].repairsDelivered)
        });
        return {
        labels: labels,
        datasets: [
            {
            label: 'VENDAS',
            data: normalData,
            backgroundColor: 'green',
            borderColor: 'black',
            fill: false
            },
            {
            label: 'BRINDES',
            data: presentData,
            backgroundColor: 'blue',
            borderColor: 'black',
            fill: false
            },
            {
            label: 'CANCELAMENTOS',
            data: cancelData,
            backgroundColor: 'red',
            borderColor: 'black',
            fill: false
            }
            ,
            {
            label: 'RECEBIMENTOS',
            data: Receivers,
            backgroundColor: '#e9ff24',
            borderColor: 'black',
            fill: false
            }
            ,
            {
            label: 'CONSERTOS EM ABERTO',
            data: Repairs,
            backgroundColor: '#4269f5',
            borderColor: 'black',
            fill: false
            }
            ,
            {
            label: 'CONSERTOS ENTREGUES',
            data: RepairsDelivered,
            backgroundColor: '#6ff542',
            borderColor: 'black',
            fill: false
            }
            ,
            {
            label: 'CONSERTO CANCELADOS',
            data: RepairsCancel,
            backgroundColor: '#e342f5',
            borderColor: 'black',
            fill: false
            }
        ]
        };
    };

    const renderChart = () => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
        const ctx = document.getElementById('salesPerformanceChart').getContext('2d');
        const chartData = getChartData();
        const chart = new Chart(ctx, {
          type: 'bar',
          data: chartData, 
          options: {
            responsive: true,
            maintainAspectRatio: false,     
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'day',
                  
                },
                ticks: {
                  color: 'white' 
                },
                grid: {
                    display: false
                },
             
              },
              y: {
                beginAtZero: true,
                ticks: {
                    color: 'white' 
                },
                grid: {
                    display: false
                }
              }
            },        
            plugins: {
              tooltip: {
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 0.8)', 
                titleColor: 'white', 
                bodyColor: 'white' 
              },
              legend: {
                display: true,
                labels: {
                  font: {
                      position: 'left',
                      align: 'start', 
                      size: 14,
                  },
                  color: 'rgba(255, 255, 255, 0.5)',
                }
              },
              title: {
                display: true,
                text: 'DESEMPENHO DE VENDAS',
                font:{
                  align: 'start', 
                  size: 18,
                },
                color: 'white',
              },
            }, 
            animation: {
              duration: 2000, 
              easing: 'easeInOutQuart' 
            },
            elements: {
              bar: {
                shadow: {
                  color: 'rgba(255, 255, 255, 0.5)',
                  blur: 10,
                  offsetX: 5,
                  offsetY: 5,
                  opacity: 0.5
                }
              }
             
            },
          }
        });
        chartRef.current = chart;
      };

    return (
        <canvas id="salesPerformanceChart" style={getStyle()} width={width} height={height}></canvas>
    )
}

const getStyle = () =>{
    return  {
        backgroundColor: 'rgba(28,28,28,1)',
        padding:10,
        borderRadius:5,
        border: '1px solid rgba(255, 255, 255, 0.5)',
    }
}

export default SalesPerformanceChart

export const OtherFactorsChart = ({ factors, width, height }) => {
  const chartRef = useRef(null)

  useEffect(() => {
      renderChart();
      return () => {
          if (chartRef.current) {
              chartRef.current.destroy()
          }
      };
  }, [factors, width, height])

  const getChartData = () => {
      const labels = factors.map(factor => factor.factor)
      const totalSalesData = factors.map(factor => factor.valueTotalSale)

      return {
          labels: labels,
          datasets: [
              {
                  label: 'Valor Monetário',
                  data: totalSalesData,
                  backgroundColor: 'rgb(13, 255, 178,0.8)',
                  borderColor: 'black',
                  fill: false
              }
          ]
      };
  }

  const renderChart = () => {
      if (chartRef.current) {
          chartRef.current.destroy();
      }
      const ctx = document.getElementById('otherFactorsChart').getContext('2d');
      const chartData = getChartData();
      const chart = new Chart(ctx, {
          type: 'bar',
          data: chartData,
          options: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                  y: {
                      beginAtZero: true,
                      ticks: {
                          color: 'white' 
                      },
                      grid: {
                          display: false
                      }
                  }
              },
              plugins: {
                  tooltip: {
                      enabled: true,
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      titleColor: 'white',
                      bodyColor: 'white'
                  },
                  legend: {
                      display: true,
                      labels: {
                          font: {
                              position: 'left',
                              align: 'start',
                              size: 14,
                          },
                          color: 'rgba(255, 255, 255, 0.5)',
                      }
                  },
                  title: {
                      display: true,
                      text: 'VENDAS POR FATORES DE MULTIPLICAÇÃO',
                      font:{
                          align: 'start',
                          size: 18,
                      },
                      color: 'white',
                  },
                  backgroundColor: 'rgba(255, 255, 255, 0.5)'
              },
              animation: {
                  duration: 2000,
                  easing: 'easeInOutQuart'
              },
              elements: {
                  bar: {
                      shadow: {
                          color: 'rgba(255, 255, 255, 0.5)',
                          blur: 10,
                          offsetX: 5,
                          offsetY: 5,
                          opacity: 0.5
                      }
                  }
              },
          }
      });
      chartRef.current = chart;
  }

  return (
      <canvas id="otherFactorsChart" style={getStyle()} width={width} height={height}></canvas>
  )
}

export const ReceiversPerformace = ({ listReceivers, width, height  }) => {
  const chartRef = useRef(null)

  useEffect(() => {
      renderChart()
      return () => {
          if (chartRef.current) {
              chartRef.current.destroy()
          }
      }
  }, [width, height])

  const organizeSalesByDate = () => {
    const organizedSales = {};
    listReceivers.forEach(ss => {
        const date = new Date(ss.dataPaid)
        const formattedDate = date.toISOString().slice(0, 10)
        if (!organizedSales[formattedDate]) {
            organizedSales[formattedDate] = {
                receivers: 0
            }
        }
  
        organizedSales[formattedDate].receivers += ss.totalValuePaid
    })
  
    return organizedSales
  }

  const getChartData = () => {
      const organizedSales = organizeSalesByDate()
      const labels = Object.keys(organizedSales)
      const Receivers = []
      labels.forEach(date => {
          Receivers.push(organizedSales[date].receivers)
      });
      return {
      labels: labels,
      datasets: [
          {
          label: 'RECEBIMENTOS',
          data: Receivers,
          backgroundColor: '#e9ff24',
          borderColor: 'black',
          fill: false
          }
      ]
      }
  }

  const renderChart = () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
      const ctx = document.getElementById('salesPerformanceChart').getContext('2d');
      const chartData = getChartData();
      const chart = new Chart(ctx, {
        type: 'bar',
        data: chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day',
                
              },
              ticks: {
                color: 'white' 
              },
              grid: {
                  display: false
              }
            },
            y: {
              beginAtZero: true,
              ticks: {
                  color: 'white' 
              },
              grid: {
                  display: false
              }
            }
          },        
          plugins: {
            tooltip: {
              enabled: true,
              backgroundColor: 'rgba(0, 0, 0, 0.8)', 
              titleColor: 'white', 
              bodyColor: 'white' 
            },
            legend: {
              display: true,
              labels: {
                font: {
                    position: 'left',
                    align: 'start', 
                    size: 14,
                },
                color: 'rgba(255, 255, 255, 0.5)',
              }
            },
            title: {
              display: true,
              text: 'RECEBIMENTOS DIA A DIA',
              font:{
                align: 'start', 
                size: 18,
              },
              color: 'white',
            },
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
          }, 
          animation: {
            duration: 2000, 
            easing: 'easeInOutQuart' 
          },
          elements: {
            bar: {
              shadow: {
                color: 'rgba(255, 255, 255, 0.5)',
                blur: 10,
                offsetX: 5,
                offsetY: 5,
                opacity: 0.5
              }
            }
          },
        }
      });
      chartRef.current = chart;
  };

  return (
      <canvas id="salesPerformanceChart" style={getStyle()} width={width} height={height}></canvas>
  )
}

export const ClientsDebtChart = ({ accountingData, width, height }) => {
  const chartRef = useRef(null)

  useEffect(() => {
      renderChart()
      return () => {
          if (chartRef.current) {
              chartRef.current.destroy()
          }
      }
  }, [accountingData, width, height])

  const getChartData = () => {
      const labels = accountingData.accountingAllSalesReceiver.map(client => client.ownerSales._genericPerson.name)
      const debts = accountingData.accountingAllSalesReceiver.map(sale => sale.valueTotalOpen)

      return {
          labels: labels,
          datasets: [
              {
                  label: 'Dívida do Cliente',
                  data: debts,
                  backgroundColor: 'green',
                  borderColor: 'black',
                  fill: false
              }
          ]
      }
  }

  const renderChart = () => {
      if (chartRef.current) {
          chartRef.current.destroy();
      }
      const ctx = document.getElementById('clientsDebtChart').getContext('2d');
      const chartData = getChartData();
      const chart = new Chart(ctx, {
          type: 'bar',
          data: chartData,
          options: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                  y: {
                      beginAtZero: true,
                      ticks: {
                          color: 'white' 
                      },
                      grid: {
                          display: false
                      }
                  }
              },
              plugins: {
                  tooltip: {
                      enabled: true,
                      backgroundColor: 'rgba(0, 0, 0, 1)',
                      titleColor: 'white',
                      bodyColor: 'white'
                  },
                  legend: {
                      display: true,
                      labels: {
                          font: {
                              position: 'left',
                              align: 'start',
                              size: 14,
                          },
                          color: 'rgba(255, 255, 255, 0.5)',
                      }
                  },
                  title: {
                      display: true,
                      text: 'DÍVIDAS POR CLIENTE',
                      font:{
                          align: 'start',
                          size: 18,
                      },
                      color: 'white',
                  },
                  backgroundColor: 'rgba(255, 255, 255, 0.5)'
              },
              animation: {
                  duration: 2000,
                  easing: 'easeInOutQuart'
              },
              elements: {
                  bar: {
                      shadow: {
                          color: 'rgba(255, 255, 255, 0.5)',
                          blur: 10,
                          offsetX: 5,
                          offsetY: 5,
                          opacity: 0.5
                      }
                  }
              },
          }
      });
      chartRef.current = chart;
  };

  return (
      <canvas id="clientsDebtChart" style={getStyle()} width={width} height={height}></canvas>
  )
}

export const EmployeeSalesChart = ({ data, width, height }) => {
  const chartRef = useRef(null);

  useEffect(() => {
      renderChart();
      return () => {
          if (chartRef.current) {
              chartRef.current.destroy();
          }
      };
  }, [data, width, height]);

  const getChartData = () => {
      const labels = data.map(entry => entry.personOwner.genericPerson.name);
      const valueMoney = data.map(entry => entry.valueMoney)
      const valueAllSales = data.map(entry => entry.valueAllSales)

      return {
          labels: labels,
          datasets: [
              {
                  label: 'Comissão Pelas Vendas',
                  data: valueMoney,
                  backgroundColor: 'rgb(206, 255, 13,0.8)',
                  borderColor: 'black',
                  fill: false
              },
              {
                  label: 'Valor Total Vendido',
                  data: valueAllSales,
                  backgroundColor: 'red',
                  borderColor: 'black',
                  fill: false
              }
          ]
      };
  };

  const renderChart = () => {
      if (chartRef.current) {
          chartRef.current.destroy();
      }
      const ctx = document.getElementById('employeeSalesChart').getContext('2d');
      const chartData = getChartData();
      const chart = new Chart(ctx, {
          type: 'bar',
          data: chartData,
          options: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                  y: {
                      beginAtZero: true,
                      ticks: {
                          color: 'white' 
                      },
                      grid: {
                          display: false
                      }
                  }
              },
              plugins: {
                  tooltip: {
                      enabled: true,
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      titleColor: 'white',
                      bodyColor: 'white'
                  },
                  legend: {
                      display: true,
                      labels: {
                          font: {
                              position: 'left',
                              align: 'start',
                              size: 14,
                          },
                          color: 'rgba(255, 255, 255, 0.5)',
                      }
                  },
                  title: {
                      display: true,
                      text: 'VENDAS POR FUNCIÓNARIOS',
                      font:{
                          align: 'start',
                          size: 18,
                      },
                      color: 'white',
                  },
                  backgroundColor: 'rgba(255, 255, 255, 0.5)'
              },
              animation: {
                  duration: 2000,
                  easing: 'easeInOutQuart'
              },
              elements: {
                  bar: {
                      shadow: {
                          color: 'rgba(255, 255, 255, 0.5)',
                          blur: 10,
                          offsetX: 5,
                          offsetY: 5,
                          opacity: 0.5
                      }
                  }
              },
          }
      });
      chartRef.current = chart;
  };

  return (
      <canvas id="employeeSalesChart" style={getStyle()} width={width} height={height}></canvas>
  )
}

export const MothsComparisonChart = ({ data, width, height,isloading }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        renderChart();
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [data, width, height,isloading]);

    const getChartData = () => {

        const labels = data.map(entry => entry.codeName)
        const valueTotal = data.map(entry => entry.valueTotal)
        const valueReceivers = data.map(entry => entry.valueReceivers)
        const valueTotalCancel = data.map(entry => entry.valueTotalCancel)
        
        return {
            labels: labels,
            datasets: [
                {
                    label: 'Valor Total',
                    data: valueTotal,
                    borderColor: 'green',
                    backgroundColor: 'green',
                    fill: false
                },
                {
                    label: 'Valor Recebido',
                    borderColor: 'blue',
                    data: valueReceivers,
                    backgroundColor: 'blue',
                    fill: false
                },
                {
                    label: 'Valor Total Cancelado',
                    data: valueTotalCancel,
                    borderColor: 'red',
                    backgroundColor: 'red',
                    fill: false
                }
            ]
        };
    }

    const renderChart = () => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }
        const ctx = document.getElementById('MothsComparisonChart').getContext('2d');
        const chartData = getChartData();
      
        const chart = new Chart(ctx, {
            type: 'line',
            data: chartData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            color: 'white' 
                        },
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        enabled: true,
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        titleColor: 'white',
                        bodyColor: 'white'
                    },
                    legend: {
                        display: true,
                        labels: {
                            font: {
                                position: 'left',
                                align: 'start',
                                size: 14,
                            },
                            color: 'rgba(255, 255, 255, 0.5)',
                        }
                    },
                    title: {
                        display: true,
                        text: 'COMPARAÇÃO DOS DESEMPENHOS MENSAIS',
                        font:{
                            align: 'start',
                            size: 18,
                        },
                        color: 'white',
                    },
                    backgroundColor: 'rgba(255, 255, 255, 0.5)'
                },
                animation: {
                    duration: 1000,
                    easing: 'easeInOutQuart'
                },
            }
        });
        chartRef.current = chart;
    };

    return (
        <canvas id="MothsComparisonChart" style={getStyle()} width={width} height={height}></canvas>
    )
}


