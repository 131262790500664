import React, { useEffect, useState } from "react"
import { useError } from "../../AppContext"
import style from '../../style/ControllerAdmin.module.css'
import InputModels from "../../Models/InputModels"
import { faCircleInfo, faMagnifyingGlass, faPlus, faRefresh, faRightFromBracket } from "@fortawesome/free-solid-svg-icons"
import Space from "../../layout/Space"
import GetAmountList from "../../Models/GetAmountList"
import Loader from "../../layout/Loader"
import { GetSmallModels } from "../../Models/GetModels"
import GetFloatingButton from "../../layout/GetFloatingButton"
import { getListUsers } from "../../controllers/CloudFromUsers"
import { GetButton, GetButtonIcon } from "../../layout/ButtonsLayout"
import UsersModel from "./UsersModel"
import GetEditeAndAddUsers from "./GetEditeAndAddUsers"
import { getListNotice } from "../../controllers/CloudBellNotices"

export function ListBellNoticesAdmin({handleLogOut}){

    const [filtered, setFiltered] = useState([])
    const [list, setListUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [showEditeOrAdd,setShowEditeOrAdd] = useState(false) 
    const [selectedUser,setSelectedUser] = useState(null)
    const { showError } = useError()

    useEffect(() => {
        async function fetchData() {
            await handlerFind() 
        }
        fetchData()
      }, [])

    useEffect(() => {
        handleChangeFilter()
    }, [list])
    
    const handlerFind = async() => {
        setLoading(true)
        setListUsers([])
        const value = await getListNotice(showError,true)
        setListUsers(value)
        setLoading(false) 
    }

    const handleChangeFilter = (value) => {
        const data = list
    
        if(value!= null){
          const filter = data.filter(
            data => {
              const filterName = data.title.toLowerCase().includes(value.target.value.toLowerCase())
              return filterName
            }
          )
          setFiltered(filter)
        }else{
          setFiltered(data)
        }
    } 

    const handleSelectionUser = (user) => {
        setSelectedUser(user)
        setShowEditeOrAdd(true)
    }

    return(
        <div className={style.containerGeneral}>
            <div className={style.buildList}>           
              {(filtered.length > 0 && !loading ) ?
                <GetAmountList amount={filtered.length} title={"Avisos Encontrados no Sistema..."}/>
              : null}
              <div className={style.labelData}>
                  <h5>Título</h5>
                  <h5>Mensagem</h5>
                  <h5>Data</h5>
                </div>
              <div className={style.containerList}>
                   <div className={style.loaderCenter}>
                     <Loader titleFinding={'Buscando Avisos no Servidor!'} 
                          subtitleFinding={'Aguarde...'} loading={loading}/>
                   </div>
                  <div>
                    {(filtered.length === 0 && !loading)?
                    <GetSmallModels title={"Não há Notícias com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo} />:
                      filtered.map((users, index) => (
                          <div key={index}>
                           <UsersModel data={users} onClick={()=>handleSelectionUser(users)} />
                          </div>
                      ))
                    }
                  </div>
              </div>
            </div>
          {showEditeOrAdd?  <GetEditeAndAddUsers onSave={handlerFind} user={selectedUser} onClose={()=>setShowEditeOrAdd(false)}/>: null}
          <GetFloatingButton icon={faRightFromBracket} color='#ff0000' width={window.innerWidth <= 1200 ? 65:75} margiBottom={'1%'}  height={window.innerWidth <= 1200 ? 65:75} margiRight={'1%'} handleFunction={handleLogOut} />
          <GetFloatingButton icon={faPlus} color='#007bff' width={window.innerWidth <= 1200 ? 65:75} margiBottom={'1%'}  height={window.innerWidth <= 1200 ? 65:75} margiRight={window.innerWidth <= 768? 75 :window.innerWidth <= 1200? 80:100} handleFunction={()=>handleSelectionUser(null)} />
        </div>
    )

}