import GenericProduct from "../Brands/Products/GenericProducts"
import VersionProduct from "../VersionProduct"

class ListProducts{
    constructor(){
        this.totalMoneyInGenericProducts = 0
        this.totalCountGenericProducts = 0
        this.totalModelsGenericProducts = 0
        this.genericProducts = []
        this.calculate()
    }

    fromNewSale(auxSale,codesale){
        this.genericProducts = [...auxSale.listProducts]
        this.calculate()
        this.addLastVersionNewSale(codesale)
    }

    getTotalMoneyToString(){
        return this.totalMoneyInGenericProducts.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getTotalInItems(){
        return this.totalCountGenericProducts + " Un" 
    }
  
    getTotalInModels(){
        return this.totalModelsGenericProducts + " Modelos" 
    }


    fromEdite(other){
        this.genericProducts = [...other.genericProducts]
        this.calculate()
    }

    addLastVersionCancel(codeSale){
        for (var element of this.genericProducts) {
            var version = new VersionProduct()
            version.configureNewVersion("Venda " + codeSale + " foi Cancelada, Item Devolvido para o Estoque")
            element.versionProduct = version
        }
    }

    addLastVersionNewSale(codeSale){
        for (var element of this.genericProducts) {
            var version = new VersionProduct()
            version.configureNewVersion("Esse Item foi Vendido para " + codeSale)
            element.versionProduct = version
        }
    }

    fromCloud(data,listGenericFromDatabase){

        this.totalModelsGenericProducts = data.totalModelGenericProducts
        this.totalCountGenericProducts  = data.totalCountGenericProducts
        this.totalMoneyInGenericProducts = parseFloat(data.totalMoneyInGenericProducts)

        for (var element of data.generics) {
            let currentElement = element
            var foundIndex = listGenericFromDatabase.findIndex(ss => ss.idGenericProducts === currentElement.idGenericProducts)
            if(foundIndex !== -1) {
                var genericTemp = new GenericProduct()
                genericTemp.fromOtherWithAmount(element.amount, listGenericFromDatabase[foundIndex])
                genericTemp.valueCoast = parseFloat(element.coastValue)
                genericTemp.valueToSale = parseFloat(element.saleValue)
                this.genericProducts.push(genericTemp)
            }
        }
    }

    calculate(){
        this.totalModelsGenericProducts = 0
        this.totalCountGenericProducts = 0
        this.totalMoneyInGenericProducts = 0

        for (var element of this.genericProducts) {
            this.totalModelsGenericProducts++
            this.totalCountGenericProducts += element.amount
            this.totalMoneyInGenericProducts += this.totalCountGenericProducts * element.valueToSale
        }
    }

    findValueRemoveGenericProductsEditeSale(listGenericFromDatabase){
        /*var temp = []
        for (var element in this.genericProducts) {
            let currentElement = element
            var foundIndex = listGenericFromDatabase.findIndex(ss => ss.idGenericProducts === currentElement.idGenericProducts)
            if(foundIndex !== -1) {
                var item  = listGenericFromDatabase[]
                item.amount -= element.amount;

                if(item.amount < 0) {
                item.amount = 0;
                }

                temp.add(item);
            }
        }

        return temp*/
    }

    getMapFromCloud(){

        var generics = {}
        var count = 1
        for (var element of this.genericProducts) {
            generics[count.toString()] = element.getMapFromCloud()
            count++
        }

        return{
            'totalMoneyInGenericProducts':this.totalMoneyInGenericProducts,
            'totalCountGenericProducts':this.totalCountGenericProducts,
            'totalModelsGenericProducts':this.totalModelsGenericProducts,
            'container-genericProductsList':generics
        }
    }

    clear(){
        this.totalMoneyInGenericProducts = 0
        this.totalCountGenericProducts = 0
        this.totalModelsGenericProducts = 0
        this.genericProducts.clear()
    }
}

export default ListProducts