class Extract{
    constructor(){
        this.name = ''
        this.url = ''
        this.sizeValue = ''
        this.date = ''
    }
  
    fromCloud(data){
        this.name = data.pdfName
        this.url = data.link
        this.sizeValue = (parseInt(data.pdfSize) * 0.0001).toFixed(2)
        this.sizeValue +=' KB'
        this.date = new Date(data.pdfDate).toISOString().replace('T',' ').slice(0,19)
    }
}

export default Extract