import React, { useState } from "react";
import style from "../style/GetEmployee.module.css"

function GetEmployee({employee}){
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    
    const handleImageLoad = () => {
        setImageLoaded(true); 
    }

    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }

    return(
        <div className={style.seller}>          
                <div className={style.imageContainer}>
                {errorOccurred ? (
                    <img
                    src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                    alt="Erro de carregamento"
                    className={style.loaded}/>
                    ) : (
                    <img src={employee.linkImage} alt={employee.genericPerson.name} onLoad={handleImageLoad}
                    onError={handleImageError} className={!imageLoaded ? style.loading : style.loaded}/> )}     
                </div> 
            <div>
                <p className={style.sellerName}>{employee.genericPerson.name}</p>
                <p className={style.sellerId}>{'ID DO FUNCIONÁRIO: ' + employee.idEmployee}</p>
            </div>
        </div>
    )
}

export default GetEmployee