import React from "react";
import { GetButtonIcon } from "./ButtonsLayout";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import style from "../style/EmailAndContact.module.css"

function EmailAndContact({title,functionButton,disable}){

    const handlerFunction = ()=>{
        functionButton()
    }

    return(
        <div>
            <div className={style.Container}>
                <h5>{title}</h5>
                {disable? null:<GetButtonIcon width={35} height={35} color={'#ff0000'} icon={faRemove} handleFunction={handlerFunction}/>}
            </div>
        </div>
    )
}

export default EmailAndContact