import { myStoreDataMemory } from "../../backEnd/Memory";


class ItemControllerSale{
    constructor(){
        this.factorCompany = myStoreDataMemory.profitFactor;
        this.valueItemsUn = 0;
        this.valueItemsAll = 0;
    }

    fromInit(factorCompany){
        this.factorCompany = factorCompany
    }

    fromOther(other){
        this.factorCompany = other.factorCompany;
        this.valueItemsUn = other.valueItemsUn;
        this.valueItemsAll = other.valueItemsAll;
    }
  
    updateValuesItems(factorItem, amountItems, personalizedValue,factorFromStore){
        this.valueItemsUn = factorItem * (factorFromStore?? this.factorCompany)
        this.valueItemsAll = this.valueItemsUn * amountItems;
        if(personalizedValue !== 0 && personalizedValue !== undefined) {
            this.valueItemsAll = amountItems * personalizedValue;
            this.valueItemsUn = personalizedValue
        }
    }
}

export default ItemControllerSale