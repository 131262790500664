import { sendRequestGetLocal } from "../backEnd/requesition"

const _routerClearStockInCloud = '/clear/clearStockAll'

export const setClearStock = async (showError) =>{
    try {
        var result =await sendRequestGetLocal(_routerClearStockInCloud)
        if(result.response)
            throw new Error(result.response.data.sqlMessage)
        return {status:200}
    } catch (error) {
        showError('ERROR AO ZERAR O ESTOQUE',error.message)     
        return {status:404}
    }
}