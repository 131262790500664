import { sendRequestGetLocal, sendRequestPostLocal } from "../backEnd/requesition"
import { Repairs } from "../objects/Repairs/Repairs"

const _routerGetAllFromCloud = '/repairs/getAllRepairs'
const _routerGetFromCloud = '/repairs/getRepairs'
const _routerAddRepairsInCloud = '/repairs/addRepairs'
const _routerUpdateRepairs = '/repairs/updateRepairs'

export const getAllRepairs = async (dateTime, showError) => {
    var results = []
    const repairsList = []
    try {
        var body = {
            'dateFind': dateTime
        }
        results = await sendRequestPostLocal(_routerGetAllFromCloud,body)

        if(Array.isArray(results.data)){
            Object.values(results.data).forEach(data =>{   
                var temp = new Repairs()
                temp.fromCloud(data)
                repairsList.push(temp)
            })
        }else throw new Error(results.response.data.sqlMessage) 

    } catch (error) {
        showError('ERROR AO BUSCAR CONSERTOS/PEDIDOS',error.message)
    }finally{
        return repairsList
    }
}


export const getRepairs = async (client,dateTime, showError) => {
    var results = []
    const repairsList = []
    try {
        var body = {
            'idClient': client.IdClient,
            'dateFind': dateTime
        }
        results = await sendRequestPostLocal(_routerGetFromCloud,body)

        if(Array.isArray(results.data)){
            Object.values(results.data).forEach(data =>{   
                var temp = new Repairs()
                temp.fromCloud(data)
                repairsList.push(temp)
            })
        }else throw new Error(results.response.data.sqlMessage) 

    } catch (error) {
        showError('ERROR AO BUSCAR CONSERTOS/PEDIDOS',error.message)
    }finally{
        return repairsList
    }
}


export const setUpdateRepair = async (repair,showError) =>{
    try {  
        let results = await sendRequestPostLocal(_routerUpdateRepairs,repair.getMapFromCloud()) 
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO ATUALIZAR CONSERTO/PEDIDO',error.message)
        return {status:404}
    }
}

export const setNewRepair= async (repair,showError) =>{
    try {   
        var results = await sendRequestPostLocal(_routerAddRepairsInCloud,repair.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO ADICIONAR NOVO CONSERTO/PEDIDO',error.message)
        return {status:404}
    }
}

