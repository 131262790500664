
import { brunaSemijoiasNFE } from '../backEnd/Memory.js'
import { sendRequestGetLocal, sendRequestPostLocal} from '../backEnd/requesition.js'

const _routerGetBJTaxInCloud ='/nfe/getTaxBJ'
const _routerSetTaxInCloud ='/nfe/setTax'

export const setFindBJTax= async (showError) => {
    var results = []
    try {
        results = await sendRequestGetLocal(_routerGetBJTaxInCloud)
        
        if(Array.isArray(results)){
            if(results.length > 0){
                brunaSemijoiasNFE.fromCloud(results[0])
            }
        }else throw new Error(results.response.data.sqlMessage) 

    } catch (error) {
        showError('ERROR AO BUSCAR TAXAS DA BRUNA SEMIJOIAS',error.message)
    }
}

export const setSaveNFeTax = async (settingsNFeTax,showError) =>{
    try {
        const results = await sendRequestPostLocal(_routerSetTaxInCloud,settingsNFeTax.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO SALVAR IMPOSTO DA MARCA',error.message)    
        return {status:404}
    }
}
