import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import InputModelsBlock from "../../layout/InputModelsBlock";
import style from '../../style/GetNFeConfigureCompany.module.css'
import { faKey, faCertificate, faPeopleArrows, faGhost, faHashtag, faLocationDot, faCode, faBuilding, faCodeCommit, faAngleUp, faAngleDown, faLock, faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import Space from "../../layout/Space";
import Alert from "../../Models/Alert";
import { TitlePageResponsiveIcon } from "../../layout/TitlePage";
import { GetButton, GetButtonIcon } from "../../layout/ButtonsLayout";
import { GetNfeAddress } from "./GetNfeAddress";
import { GetSmallModels } from "../../Models/GetModels";
import { GenericDropDown } from "../../layout/GenericDropDown";

const getFormData = (initialValue) => {
    return {
        cUF:initialValue.cUF,
        CNPJ:initialValue.CNPJ,
        xNome:initialValue.xNome,
        xFant:initialValue.xFant,
        IE:initialValue.IE,
        CRT:initialValue.CRT,
        keyCert:initialValue.keyCert,
        csc: initialValue.csc,
        numberCsc: initialValue.numberCsc
    }
}

const getFormNeed = () => {
    return {
        cUF:false,
        CNPJ:false,
        xNome:false,
        xFant:false,
        IE:false,
        CRT:false,
        csc: false,
        numberCsc: false
    }
}

export const GetNFeCompany = forwardRef(({ initialExpand,initialValue,isBlockEdite}, ref) => {

    const addressRef = useRef(null)
    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData(initialValue))
    const [needData, setNeedData] = useState(getFormNeed)
    const [expanded, setExpand] = useState(initialExpand?? true)
    const [file,setFile] = useState(null)

    useImperativeHandle(ref, () => ({
        handleSubmit
    }))

    const handleChange = (e) => {
        if(e !== ''){
            let { name, value } = e.target;
            if(name === 'cert')
            {
                setFile(e.target.files[0])
                return;
            }
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmit= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
         
        let fomeDataFromAddress = {}
        if (addressRef.current) {
            fomeDataFromAddress = addressRef.current.handleSubmit()
            if(fomeDataFromAddress === false) return false
        }

        if (allFieldsEmpty) {
            setAlert(true)
            return false
        }
        return { ...formData, ...fomeDataFromAddress, ...{file:file} }
    }

    return (
        <div className={style.div}>
            <TitlePageResponsiveIcon 
                children={
                    <GetButtonIcon color='#094f00' height={40} width={40} icon={expanded? faAngleUp :  faAngleDown} handleFunction={()=>setExpand(!expanded)}/> 
                }
                subtitle={"DADOS DA SUA EMPRESA"} path={"Configure a Sua Empresa Abaixo..."} icon={faBuilding}/> 
             <div className={expanded? style.visible :   style.notVisible}>
                <div className={style.div}>
                {isBlockEdite? <GetSmallModels title={'EDIÇÃO DE DADOS DO EMISSOR BLOQUEADO'} backgroundColor={"#ff0000"} icon={faLock} subtitle={'Para Editar, Vá em Fiscal e Edite no Campo Emissor NFe'}/> : null}
                <GetSmallModels 
                    children={
                        <GetButton responsive={true} color='#FF0000' title={'AJUDA'}  icon={faCircleQuestion} handleFunction={()=>window.open('https://www5.sefaz.mt.gov.br/-/codigo-de-seguranca-do-contribuinte-e-obrigatorio-para-nfc-e','_black')}/> 
                    }
                    icon={faCircleQuestion}
                    title={'COMO GERAR O CSC (TOKEN) NO SEFAZ? '} backgroundColor={"#005f00"} subtitle={'Clique no botão e depois entre no link do Sefaz, Faça o Login e Solicite o Token.'}/>
                <div className={style.divInRow}>
                    <GenericDropDown disable={isBlockEdite} legend={'CTR - CÓDIGO DE REGIME TRIBUTÁRIO'} 
                            data={[ 
                            '1 – Simples Nacional', 
                            '2 – Simples Nacional – excesso de sublimite de receita bruta',
                            '3 – Regime Normal',]} 
                        name={'CRT'} initialState={formData.CRT} handlerFuncion={handleChange}  /> 
                    <Space left={5} top={5}/>
                    <GenericDropDown disable={isBlockEdite} legend={'cUF - CÓDIGO DO ESTADO'} 
                            data={[ 
                            '11 – Rondônia (RO)', 
                            '12 – Acre (AC)',
                            '13 – Amazonas (AM)',
                            '14 – Roraima (RR)',
                            '15 – Pará (PA)',
                            '16 – Amapá (AP)',
                            '17 – Tocantins (TO)',
                            '21 – Maranhão (MA)',
                            '22 – Piauí (PI)',
                            '23 – Ceará (CE)',
                            '24 – Rio Grande do Norte (RN)',
                            '25 – Paraíba (PB)',
                            '26 – Pernambuco (PE)',
                            '27 – Alagoas (AL)',
                            '28 – Sergipe (SE)',
                            '29 – Bahia (BA)',
                            '31 – Minas Gerais (MG)',
                            '32 – Espírito Santo (ES)',
                            '33 – Rio de Janeiro (RJ)',
                            '35 – São Paulo (SP)',
                            '41 – Paraná (PR)',
                            '42 – Santa Catarina (SC)',
                            '43 – Rio Grande do Sul (RS)',
                            '50 – Mato Grosso do Sul (MS)',
                            '51 – Mato Grosso (MT)',
                            '52 – Goiás (GO)',
                            '53 – Distrito Federal (DF)']} 
                        name={'cUF'} initialState={formData.cUF} handlerFuncion={handleChange}/>
                    <Space left={5} top={5}/>
                    <GetSmallModels title={'NÚMERO DA NFe/NFCe ATUAL'} value={initialValue.nNF}
                        subtitle={'Número Importante Para o Sefaz, É Auto Incrementado'}
                    />
                    <Space left={5} top={5}/>
                    <GetSmallModels title={'NÚMERO DO LOTE'} value={initialValue.nfeLote}
                        subtitle={'Número Importante Para o Sefaz, É Auto Incrementado'}
                    />
                </div>
                <Space left={5} top={5}/>
                <div className={style.divInRow}>
                        <InputModelsBlock disable={isBlockEdite} legend={'CNPJ'} onChange={handleChange} validation={needData.CNPJ} maxLength={45} value={formData.CNPJ} name={'CNPJ'} iconButton={faHashtag} type={"number"} placeholder={"CNPJ da Sua Empresa..."}/>
                        <Space left={5} top={5}/>
                        <InputModelsBlock disable={isBlockEdite} legend={'RAZÃO SOCIAL'} onChange={handleChange} validation={needData.xNome} maxLength={50} value={formData.xNome} name={'xNome'} iconButton={faPeopleArrows} type={"text"} placeholder={"Razão Social da Sua Empresa..."}/>  
                        <Space left={5} top={5}/> 
                        <InputModelsBlock disable={isBlockEdite} legend={'NÚMERO CSC'} onChange={handleChange} validation={needData.numberCsc} maxLength={10} value={formData.numberCsc} name={'numberCsc'} iconButton={faHashtag} type={"number"} placeholder={"Número do CSC..."}/>
                        <Space left={5} top={5}/>
                        <InputModelsBlock disable={isBlockEdite} legend={'CSC - CÓDIGO DE SEGURANÇA DO CONTRIBUINTE'} onChange={handleChange} validation={needData.csc} maxLength={32} value={formData.csc} name={'csc'} iconButton={faPeopleArrows} type={"text"} placeholder={"Código de Segurança do Contribuinte - CSC (TOKEN)..."}/>  
                        <Space left={5} top={5}/>   
                </div>
                <Space left={5} top={5}/>
                <div className={style.divInRow}>
                <InputModelsBlock disable={isBlockEdite} legend={'INSCRIÇÃO ESTADUAL'} onChange={handleChange} validation={needData.IE} maxLength={25} value={formData.IE} name={'IE'} iconButton={faLocationDot} type={"number"} placeholder={"Inscrição Estadual da Sua Empresa..."}/>    
                        <Space left={5} top={5}/>
                        <InputModelsBlock disable={isBlockEdite} legend={'NOME FANTASIA'} onChange={handleChange} validation={needData.xFant} maxLength={55} value={formData.xFant} name={'xFant'} iconButton={faGhost} type={"text"} placeholder={"Nome Fantasia..."}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock disable={isBlockEdite} legend={'CERTIFICADO DIGITAL A1'} onChange={handleChange} maxLength={200} value={''} name={'cert'} iconButton={faCertificate} type={"file"} placeholder={"Importa Certificado..."}/>    
                        <Space left={5} top={5}/>
                        <InputModelsBlock disable={isBlockEdite} legend={'CHAVE DO CERTIFICADO DIGITAL A1'} onChange={handleChange} maxLength={200} value={formData.keyCert} name={'keyCert'} iconButton={faKey} type={"text"} placeholder={"Chave do Certificado..."}/>    
                </div>
                <Space left={5} top={5}/>
                <GetNfeAddress isBlockEdite={isBlockEdite} initialExpand={true} needComplete={true} initialValue={initialValue} title={'ENDEREÇO DO EMISSOR'}  ref={addressRef}/>
                </div>
            </div>
            {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
    )
})