import { getRandomNumber } from "../../backEnd/ControllerImages"
import Payment from "./Payment"

class ControllerPayment{
    constructor(){
        this.idPaymentController = 0
        this.status = 'DEVENDO'
        this.totalPayment = 0.0
        this.totalNeedingPayment = 0.0
        this.paymentList = []
    }
    
    fromOther(other){
        this.idPaymentController = other.idPaymentController
        this.status = other.status
        this.totalPayment = other.totalPayment
        this.totalNeedingPayment = other.totalNeedingPayment
        this.paymentList = [...other.paymentList]
        this.paymentList.sort((a,b) => b.date.localeCompare(a.date))
    }

    TotalNeedingPayment(value) {
        this.totalNeedingPayment = value
    }

    getValueNeedPay(){
        return (parseFloat(this.totalNeedingPayment) - parseFloat(this.totalPayment)).toFixed(2)
    }
    
    getTotalNeedingPaymentToString(){
        const valueNeedPay = parseFloat(this.getValueNeedPay())
        return valueNeedPay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getTotalPaidToString(){
        return this.totalPayment.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    isOpen(){
        return this.status !== 'QUITADA'
    }

    fromInCash(valueFinal){
        this.status = 'QUITADA'
        this.totalNeedingPayment = valueFinal
        this.totalPayment = valueFinal

        var payment = new Payment()
        payment.fromInCash(valueFinal, "Pago na Hora da Venda")
        this.paymentList.push(payment)

    }

    fromOwing(valueFinal){
        this.status = 'DEVENDO'
        this.totalNeedingPayment = valueFinal
        this.totalPayment = 0
    }

    fromCloud(data){
    
        this.idPaymentController = data.idPaymentController
        this.status = data.status
        this.totalPayment =  parseFloat(data.totalPayment.toString())
        this.totalNeedingPayment =  parseFloat(data.totalNeedingPayment.toString())

        for (var element of [...data.listOfPayments]) {
            var payment = new Payment()
            payment.fromCloud(element)
            this.paymentList.push(payment)
        }

        this.paymentList.sort((a,b) => b.date.localeCompare(a.date))
    }

    calculate(){
        this.totalPayment = 0;
        for (var element of this.paymentList) {
           this.totalPayment += parseFloat(element.value)
        }

        if(this.totalPayment >= this.totalNeedingPayment)
            this.status ='QUITADA'
        else this.status ='DEVENDO'
    }

    getMapFromCloud(){
        var payments = {}

        for (var element of this.paymentList) {
            payments[getRandomNumber().toString()] = element.getMapFromCloud()
        }

        return{
            'idPaymentController':this.idPaymentController,
            'status':this.status,
            'totalNeedingPayment':this.totalNeedingPayment,
            'totalPayment':this.totalPayment,
            'needPaid': this.getValueNeedPay(),
            'container-paymentList':payments,
        }

    }
}

export default ControllerPayment