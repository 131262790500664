import React, { useState, useEffect,useRef } from 'react'
import style from "../../style/ListClients.module.css"
import { GetSmallModels } from '../../Models/GetModels'
import { faMagnifyingGlass,faCircleInfo, faDownload, faListCheck, faNoteSticky, faPlus} from '@fortawesome/free-solid-svg-icons'
import Loader from '../../layout/Loader'
import InputModels from "../../Models/InputModels.js"
import { TitlePageResponsiveIcon } from '../../layout/TitlePage.js'
import Space from '../../layout/Space.js'
import { GetButton } from '../../layout/ButtonsLayout.js'
import { useError } from '../../AppContext.js'
import { updateIfNull } from '../../backEnd/Memory.js'
import GetAmountList from '../../Models/GetAmountList.js'
import { getNotes } from '../../controllers/CloudBlockNotes.js'
import GetFloatingButton from '../../layout/GetFloatingButton.js'
import AddEndViewNote from './AddEndViewNote.js'


function ListBlockNotes() {

    const containerRef = useRef(null)
    const [limit, setLimit] = useState([50])
    const [filtered, setFiltered] = useState([])
    const [list, setList] = useState([])
    
    const [loading, setLoading] = useState(false)
    const {showError} = useError()
    const [orderBy, setOrderBy] = useState('asc')

    const [selectedNote,setSelectedNote] = useState(null)
    const [showNote,setShowNote] = useState(false)


    const handlerAddNewNote = () => {
        setSelectedNote(null)
        setShowNote(true)
    }

    const handlerEditeNote = (note) =>{
        setShowNote(true)
        setSelectedNote(note)
    }

    useEffect(() => {
        async function fetchData() { 
            setLoading(true)
            await updateIfNull(showError)
            await handleFindDataBase()   
            setLoading(false)
        }
        fetchData()
    }, [])

    useEffect(() => {
        handleChangeFilter()
    }, [orderBy,list])

    const handleFindDataBase = async () => {
        setLoading(true)
        setList([])
        const value = await getNotes(showError)
        setList(value)
        setOrderBy('asc')
        setLoading(false)
    }
    
    const controllerFilter = () => { 
        let sorted = [...list];
        switch (orderBy) {
            case 'asc':
                sorted.sort((a, b) => {
                    return new Date(a.date) - new Date(b.date);
                });
                break;
            case 'desc':
                sorted.sort((a, b) => {
                    return new Date(b.date) - new Date(a.date);
                });
                break;
            default:
                break;
        }
    
        return sorted;
    };

    const handleOrderByChange = async (event) => {
        setOrderBy(event.target.value)
        handleChangeFilter()

    }

    const handleChangeFilter = (value) => {
        const data = controllerFilter()
        resetScroll()
        if(value!= null){
            const filtered= data.filter(
                note => {
                const filterName= note.name.toLowerCase().includes(value.target.value.toLowerCase())
                return filterName
                }
            )
            setFiltered(filtered)
        }else{
            setFiltered(data)
        }
    }

    const resetScroll =()=>{
        const container = containerRef.current
        container.scrollTop = 0
        setLimit(50)
    }

    const checkScroll= ()=> {
        const container = containerRef.current;
        const scrollPosition = container.scrollTop;
        const containerHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;

        if (scrollPosition + containerHeight >= scrollHeight) {   
        setLimit((limit + 50))
        }
    }

    

    return (
        <div className={style.containerGeneral}>
            <div className={style.containerGlobal}>                 
                <div className={style.titleMenu}>     
                <TitlePageResponsiveIcon subtitle={'Lista de Pedidos e Notas'} 
                children={
                    <GetButton responsive={true} title={'BUSCAR NO SERVIDOR'} 
                        color='#007bff' icon={faDownload} handleFunction={handleFindDataBase}/>
                }
                path={'Lista de Pedidos e Notas que Foram Salvos no Servidor...'} icon={faListCheck}/>           
                </div>
                <div className={style.buildList}>
                <div className={style.filter}>
                    <div className={style.inputFilter}>
                        <InputModels iconButton={faMagnifyingGlass} type={"text"} 
                        placeholder={"Buscar Pedido/Nota por Nome..."}
                        onChange={handleChangeFilter}/>
                    </div>
                    <div className={style.containerDropDown}>               
                        <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                            <option value="asc">CRESCENTE</option>
                            <option value="desc">DESCRESCENTE</option>
                        </select>
                    </div>
                </div>
                <Space right={2} left={2} top={5} bottom={0}/> 
                {(filtered.length > 0 && !loading ) ?<div className={style.buildList}>
                    <GetAmountList amount={filtered.length} title={"Pedidos/Notas Totais Encontrados na Busca..."}/>
                </div> : null}
                <div ref={containerRef} className={style.containerListForced} onScroll={checkScroll}>
                    <div className={style.loaderCenter}>
                        <Loader titleFinding={'Buscando no Servidor!'} 
                            subtitleFinding={'Aguarde...'} loading={loading}/>
                    </div>
                    <div>
                        {(filtered.length === 0 && !loading)?
                        <GetSmallModels title={"Não há Pedidos ou Notas com Esse Filtro"}
                        subtitle={"Tente Trocar o Filtro..."}
                        icon={faCircleInfo} />:
                        filtered.slice(0, limit).map((item, index) => (
                            <div key={index}>
                                <div onClick={() =>handlerEditeNote(item)} className={style.forceHover}>
                                    <GetSmallModels icon={faNoteSticky} 
                                    subtitle={'Data da Útima Modificação: ' + item.date}  
                                    title={item.name}/>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                </div>
                </div>
        </div>
    
        {showNote? <AddEndViewNote onSave={handleFindDataBase} note={selectedNote} onClose={() =>setShowNote(false)}/>:null}
        {loading ? null:<GetFloatingButton icon={faPlus} color='#007bff' width={window.innerWidth <= 1200 ? 65 : 85} height={window.innerWidth <= 1200 ? 65 : 85} margiRight={'1%'} margiBottom={'1%'} handleFunction={handlerAddNewNote} />}
        </div>
    )
}

export default ListBlockNotes