import { sendRequestPostLocal} from '../backEnd/requesition.js'
import { FinancialObject } from '../objects/Financial/FinancialObject.js'

const _routerGetListFinancial ='/financial/get'
const _routerFromFinancialEdite = '/financial/edite'
const _routerFromFinancialAdd = '/financial/add'
const _routerFromFinancialRemove = '/financial/remove'

export const setNewFinancial = async (financial,showError) =>{
    try {
        var results = await sendRequestPostLocal(_routerFromFinancialAdd,financial.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {   
        showError('ERROR AO SALVAR FLUXO DE CAIXA',error.message)
        return {status:404}
    }
}

export const setRemove = async (financial,showError) =>{
    try {
        var results = await sendRequestPostLocal(_routerFromFinancialRemove,financial.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {   
        showError('ERROR AO REMOVER FLUXO DE CAIXA',error.message)
        return {status:404}
    }
}

export const setUpdateFinancial= async (financial,showError) =>{
    try {       
        var results = await sendRequestPostLocal(_routerFromFinancialEdite,financial.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO SALVAR EDIÇÃO DO FLUXO DE CAIXA',error.message)
        return {status:404}
    }
}

export const getListFinancial = async (showError,dateTime,dateTimeFinal) => {
    var results = []
    const financialList = []
    try {
        var body = {
            'dateFind': dateTime,
            'dateFinal': dateTimeFinal
            }
        results = await sendRequestPostLocal(_routerGetListFinancial,body)
        if(Array.isArray(results.data)){    
            results.data.forEach(data =>{   
                var financialTemp = new FinancialObject()
                financialTemp.fromCloud(data)
                financialList.push(financialTemp)
            })
        }else throw new Error(results.response.data.sqlMessage) 

    } catch (error) {
        showError('ERROR AO BUSCAR FLUXO DE CAIXA',error.message)
    }finally{
        return financialList
    }
}