import React from "react";
import style from "../../style/ModelFinancial.module.css"
import Space from "../../layout/Space";
import { GetButton, GetButtonIcon } from "../../layout/ButtonsLayout";
import { faEdit, faLock, faRemove } from "@fortawesome/free-solid-svg-icons";
import { GetSmallModels } from "../../Models/GetModels";

function ModelFinancial({model,onEdite,onRemove,canEdite}) {
  
    let StyleClass = model.situation === 'EM ABERTO'? (model.category === 'CREDITOS'? style.containerWrapOpenCredits :style.containerWrap) : style.containerWrapClosed
    
    return(
        <>
            <div className={StyleClass}>
                <div className={style.divId}>
                    <h2>{model.idFinancial}</h2>
                </div>
                <Space left={2}/>
                <div className={style.descrition}>
                    <h2>{model.name}</h2>
                </div>
                <Space left={2}/>
                <div className={style.descrition}>
                    <h2>{model.description}</h2>
                </div>
                <Space left={2}/>
                <div className={style.value}>
                    <h2>{model.getValue()}</h2>
                </div>
                <Space left={2}/>
                <div className={style.date}>
                    <h2>{model.getDateRegister()}</h2>
                </div>
                <Space left={2}/>
                <div className={style.dueDate}>
                    <h2>{model.getDate()}</h2>
                </div>
                <Space left={2}/>
                <div className={style.date}>
                    <h2>{model.situation}</h2>
                </div>
                <Space left={2}/>
                <div className={style.date}>
                    <h2>{model.typePayment}</h2>
                </div>
                <Space left={2}/>
                <div className={style.type}>
                    <h2>{model.category}</h2>
                </div>
                <Space left={2}/>
                <div className={style.divButtonsAction}>
                {canEdite? <>
                    <GetButtonIcon icon={faEdit} handleFunction={onEdite} height={40} width={40} color={"#007830"}/>
                    <Space left={5}/>
                    <GetButtonIcon icon={faRemove} handleFunction={onRemove} height={40} width={40}  color={"#ff0000"}/>
                    </>:  <GetButtonIcon icon={faLock} color={"#ff0000"} height={40} width={40}/>}
                </div>
            </div>
        </>  
    )
}


export function ModelFinancialMobile({model,onEdite,onRemove,canEdite}) {

    let StyleClass = model.situation === 'EM ABERTO'? (model.category === 'CREDITOS'? style.containerWrapOpenCredits :style.containerWrap) : style.containerWrapClosed
    return(
        <>
            <div className={style.containerWrapCollum}>
                <GetSmallModels title={'ID NO SERVIDOR:'} value={model.idFinancial}/>
                <Space left={4}/>
                <GetSmallModels title={'NOME:'} value={model.name}/>
                <Space left={4}/>
                <GetSmallModels title={'DESCRIÇÃO:'} value={model.description}/>
                <Space left={4}/>
                <GetSmallModels title={'VALOR:'} value={model.getValue()}/>
                <Space left={4}/>
                <GetSmallModels title={'REGISTRO:'} value={model.getDateRegister()}/>
                <Space left={4}/>
                <GetSmallModels title={'VENCIMENTO:'} value={model.getDate()}/>
                <Space left={4}/>
                <GetSmallModels title={'STATUS:'} value={model.situation}/>
                <Space left={4}/>
                <GetSmallModels title={'FORMA:'} value={model.typePayment}/>
                <Space left={4}/>
                <GetSmallModels title={'TIPO:'} value={model.category}/>
                <Space left={4}/>
                {canEdite? <div className={style.div}>
                    <GetButton title={'EDITAR FLUXO'} icon={faEdit} handleFunction={onEdite} height={40} color={"#007830"}/>
                    <Space left={5}/>
                    <GetButton title={'REMOVER FLUXO'} icon={faRemove} handleFunction={onRemove} height={40}  color={"#ff0000"}/>
                </div>: null}
            </div>
        </>  
    )
}

export function ModelFinancialHelperName() {
    return(
        <>
            <div className={style.containerWrapHelper}>
                <div className={style.divId}>
                    <h2>ID</h2>
                </div>
                <Space left={2}/>
                <div className={style.descrition}>
                    <h2>NOME</h2>
                </div>
                <Space left={2}/>
                <div className={style.descrition}>
                    <h2>DESCRIÇÃO</h2>
                </div>
                <Space left={2}/>
                <div className={style.value}>
                    <h2>VALOR</h2>
                </div>
                <Space left={2}/>
                <div className={style.date}>
                    <h2>REGISTRO</h2>
                </div>
                <Space left={2}/>
                <div className={style.date}>
                    <h2>VENCIMENTO</h2>
                </div>
                <Space left={2}/>
                <div className={style.date}>
                    <h2>STATUS</h2>
                </div>
                <Space left={2}/>
                <div className={style.date}>
                    <h2>FORMA</h2>
                </div>
                <Space left={2}/>
                <div className={style.type}>
                    <h2>TIPO</h2>
                </div>
                <Space left={2}/>
                <div className={style.divButtonsAction}>
                    <h2>EDIÇÃO</h2>
                </div>
            </div>
        </>  
    )
}

export default ModelFinancial