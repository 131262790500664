import React, { useState } from "react"
import style from "../../../style/ViewSaleController.module.css"
import { TitlePageResponsiveIcon } from "../../../layout/TitlePage"
import { GetButton, GetButtonIcon } from "../../../layout/ButtonsLayout"
import { faCalendar, faCheckDouble, faClose, faDatabase, 
     faGift, faInfo, faLandmark, faListUl,  faShopSlash, faTags, faTruckFast } from "@fortawesome/free-solid-svg-icons"
import { GetSmallModels } from "../../../Models/GetModels"
import Space from "../../../layout/Space"
import { faProductHunt } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ViewList from "../ViewList"
import { employeeData } from "../../../backEnd/InitController"
import { clearAndInsert } from "../../Stock/Database/UpdateStock"
import { useNavigate } from "react-router-dom"
import { routerFromStockRepositionBJ } from "../../../routers/routerList"

function ViewPurchase({sale,handleClose}){

    const [employeeUser] = useState(employeeData()) 
    const [canEditeStock] = useState(employeeUser.employeePermission.permissionsItems[0])

    const [index,setIndex] = useState(0)
    const [icon,setIcon] = useState(faInfo)
    const [labelTitleMenu,setLabelTitleMenu] = useState('DETALHES') 
    const [descriptionTitle,setDescriptionTitle] = useState('São Iformações de Detalhes') 

    var title = sale.isCanceled? 'COMPRA CANCELADA' : 'COMPRA'
    title = sale.isPresent ? 'BRINDE' : title
    
    return(
        <div className={style.overlay}>
                <div className={style.alert}>
                {
                <>
                    <div className={style.div}>
                        <div className={style.title}>
                                <TitlePageResponsiveIcon subtitle={labelTitleMenu} 
                                        path={descriptionTitle } 
                                        icon={icon}
                                        children={
                                            <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                                    }

                                />
                                <NavigationBarOptions 
                                setIndex ={setIndex}
                                setIcon = {setIcon}
                                index = {index}
                                setDescriptionTitle={setDescriptionTitle}
                                setLabelTitleMenu={setLabelTitleMenu}  
                                sale={sale}/>  
                                 
                        </div>
                    </div>
                        {index === 0 ?
                            <>
                                {sale.isCanceled? 
                                <div className={style.div}>
                                    <GetSmallModels title={'STATUS DESSA VENDA: '}
                                    icon={faShopSlash}
                                    value={'VENDA CANCELADA'}
                                    />
                                </div> : null}
                                <Space top={50}/>
                                {sale.isPresent? 
                                <div className={style.div}>
                                    <GetSmallModels title={'É UM BRINDE'}
                                    icon={faGift}/>
                                </div> : null}
                                <Space top={50}/>
                                <ViewFinalValue sale={sale} canEditeStock={canEditeStock}/>
                                <Space top={50}/>                               
                                <ViewDiscount sale={sale}/>
                                <Space top={50}/>                         
                                <ViewBasicDetails sale={sale}/>
                                <Space top={50}/>
                                <ViewMotionCancel sale={sale}/>
                                <Space top={50}/>
                                <div className={style.divInRow}>
                                    <GetSmallModels 
                                    title={'Forma de Pagamento: '}
                                    subtitle={'É a Forma de Pagamento Dessa Compra'}
                                    value={sale.payForm}/>
                                </div> 
                            </> : null
                        }                       
                        {index === 2? <ViewList
                                icon={faListUl}
                                title={'LISTA DE PEDIDOS  ' + title} 
                                description={'É a Lista Relacionando Pedidos'}
                                listItems={[...sale.requestThisSale]} isBJ={true}/>:null}
                        {index === 3? <ViewList
                                icon={faListUl}
                                title={'LISTA DE SEMIJOIAS ' + title} 
                                description={'É a Lista Relacionando a Bruna Semijoias'}
                                listItems={[...sale.itemsThisSale]} isBJ={true}/>:null}                     
                        </> }
                </div> 
           
        </div>
    )
}

function NavigationBarOptions({sale,index,setIcon,setIndex,setLabelTitleMenu,setDescriptionTitle}){
    
    const handleChange = (index,icon,title,description)=>{
        setIcon(icon)
        setIndex(index)
        setLabelTitleMenu(title)
        setDescriptionTitle(description)
    }

    return(
        <div className={style.navibar}>
            <Space left={100}/>
                <div onClick={() =>handleChange(
                    0,
                    faInfo,
                    'DETALHES',
                    'São Iformações de Detalhes'
                )} className={index === 0?  style.selectionView + ' ' + style.selected : style.selectionView}>
                    <FontAwesomeIcon className={style.selectionViewIcon} 
                    icon={faInfo} />
                    <p>DETALHES</p>
                </div>
                {sale.requestThisSale.length === 0 ? null :
                <div onClick={() => handleChange(
                    2,
                    faProductHunt,
                    'PEDIDOS',
                    'É a Lista de Pedidos'
                )} className={index === 2?  style.selectionView + ' ' + style.selected : style.selectionView}>
                    <FontAwesomeIcon className={style.selectionViewIcon} 
                    icon={faProductHunt} />
                    <p>PEDIDOS</p>
                </div>}
                {sale.itemsThisSale.length === 0 ? null :
                <div onClick={() => handleChange(
                    3,
                    faDatabase,
                    'SEMIJOIAS',
                    'É a Lista da Bruna Semijoias'
                )} className={index === 3?  style.selectionView + ' ' + style.selected : style.selectionView}>
                    <FontAwesomeIcon className={style.selectionViewIcon} 
                    icon={faDatabase} />
                    <p>SEMIJOIAS</p>
                </div>}
                <Space right={50}/>
        </div>
    )
}

function ViewFinalValue({sale,canEditeStock}){
    const navigate = useNavigate()
    const handleEditeStock = () =>{
        const list = [...sale.requestThisSale, ...sale.itemsThisSale];
        clearAndInsert(list,!sale.isCanceled)
        navigate(routerFromStockRepositionBJ)
    }

    return(
        <div className={style.div}>
            <div className={style.title}>
                <TitlePageResponsiveIcon subtitle={'VALORES FINAIS'} 
                path={"É o Relatório Total de Todas as Operações"} 
                icon={faCheckDouble}/>   
                <Space left={5}/>         
               <GetSmallModels subtitle={sale.salle} title={'Vendedor'}/>
            </div>
            <div className={style.divInRow}>
                <GetSmallModels 
                title={'Valor Pago: '}
                subtitle={'É A Soma Total Final'}
                value={sale.getTotalValueToString()}/>              
            </div>
            {canEditeStock? 
            <div className={style.divInRow}>
                <GetButton handleFunction={handleEditeStock} width={'100%'} title={ sale.isCanceled? 'REMOVER PEÇAS DO ESTOQUE': 'REPOR PEÇAS COMPRADAS NO ESTOQUE'} color={sale.isCanceled ?"#ff0000":'#006400' } icon={faTruckFast}/>
            </div>
            :null}
        </div>
    )
}

function ViewDiscount({sale}){
    return(
        <>{(!sale.isHasDiscount)? null :
            <div className={style.div}>
                <div className={style.title}>
                    <TitlePageResponsiveIcon subtitle={'DADOS DO DESCONTO'} 
                    path={"Há Descontos Nessa Operação"} 
                    icon={faTags}/>  
                </div>
                <div className={style.divInRow}>
                    <GetSmallModels 
                    title={'Total desse Desconto: '}
                    subtitle={'É A Soma Total dos Descontos em Reais'}
                    value={sale.getPercentageToString()}/>
                    <Space left={5}/>
                    <GetSmallModels 
                    title={'Porcentagem Total do Desconto: '}
                    subtitle={'É a Porcentagem Total do Desconto'}
                    value={sale.discountPercent }/>
                </div>
            </div>
        }</>
      
    )
}

function ViewBasicDetails({sale}){
    return(
        <>{
            <div className={style.div}>
                <div className={style.title}>
                    <TitlePageResponsiveIcon subtitle={'DADOS BÁSICOS'} 
                    path={"Informações Básicas Sobre Essa Operação"} 
                    icon={faLandmark}/>  
                </div>
                <div className={style.divInRow}>
                    <GetSmallModels 
                    title={'Fator de Multiplicação: '}
                    subtitle={'É o Fator de Compra das Peças da Bruna Semijoias'}
                    value={sale.factorCompany}/>
                    <Space left={5}/>
                    <GetSmallModels 
                    title={'Data da Compra: '}
                    subtitle={'É a Data que a Compra Foi Realizada'}
                    value={sale.getDate()}/>
                </div>
                <div className={style.divInRow}>
                    <GetSmallModels 
                    title={'Data de Registro no Aplicativo: '}
                    subtitle={'É a Data de Registro da Venda no Aplicativo'}
                    value={sale.getDate()}/>
                </div>
            </div>
        }</>
      
    )
}

function ViewMotionCancel({sale}){
   
    return(
        <>{!sale.isCanceled? null:
            <div className={style.div}>
                <div className={style.title}>
                    <TitlePageResponsiveIcon subtitle={'DADOS SOBRE O CANCELAMENTO'} 
                    path={"Informações Básicas Sobre o Cancelamento Dessa Compra..."} 
                    icon={faShopSlash}/>   
                </div>
                <div className={style.divInRow}>
                    <GetSmallModels title={'Vendedor'} subtitle={sale.nameSellerCancel}/>
                    <Space left={5} top={5}/>
                    <GetSmallModels 
                    icon={faCalendar}
                    title={'Data do Cancelamento'}
                    subtitle={sale.dateSale}/>
                </div>
            </div>
        }</>
      
    )
}

export default ViewPurchase