import React, { useState } from 'react';
import style from '../style/GetExpandedImage.module.css'
import { faClose, faInfo } from '@fortawesome/free-solid-svg-icons';
import { GetButtonIcon } from './ButtonsLayout';
import { TitlePageResponsiveIcon } from './TitlePage';

function GetExpandedImage({nameProduct,linkImage,onClose}) {
    const handleClose = () => {
      onClose()
    } 

    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    
    const handleImageLoad = () => {
        setImageLoaded(true); 
    }


    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }
    return (
      <div className={style.overlay}>
        <div className={style.alert}>
        <div className={style.name}>
        <TitlePageResponsiveIcon icon={faInfo} title={nameProduct} children={
           <GetButtonIcon color={'#ff0000'} icon={faClose} handleFunction={handleClose}/>
        }/>
        </div>
        <div className={style.containerImage}>
            <div className={style.imageContainer}>
                    {errorOccurred ? (
                    <img
                        src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                        alt="Erro de carregamento"
                        className={style.loaded}/>) : 
                        (<img src={linkImage} alt={nameProduct} onLoad={handleImageLoad}
                            onError={handleImageError} 
                            className={!imageLoaded ? style.loading : style.loaded}/> )}
            </div> 
            </div> 
            </div> 
      </div>
    )
}

export default GetExpandedImage
