class MothPay {
    constructor() {
        this.payPix = ''
        this.message = ''
        this.startService = ''
        this.valueService = ''
        this.isOverdue = false
        this.isLoaded = false
    }

    getDateStartToString(){
        return this.startService.toISOString().slice(0,10)
    }

    fromCloud(data) {
        this.valueService = data.serviceValue
        this.payPix = data.keyCodePix
        this.message = data.messageMoth
        this.startService = new Date(data.dayStartService)
        this.calculate()
        this.isLoaded = true
    }

    calculate() {
        const today = new Date();
        const startService = this.startService;
    
        let differenceInMonths = (today.getFullYear() - startService.getFullYear()) * 12 + (today.getMonth() - startService.getMonth());
    
        if (differenceInMonths > 1) {
            this.isOverdue = true;
        } else if (differenceInMonths === 1) {
            if (today.getDate() > startService.getDate() + 1) {
                this.isOverdue = true
            } else {
                this.isOverdue = false
            }
        } else {
            this.isOverdue = false
        }
    }
    
    
    
}

export default MothPay
