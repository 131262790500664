import React from "react";
import style from "../../style/ModelFinancial.module.css"
import Space from "../../layout/Space";
import { GetButtonIcon } from "../../layout/ButtonsLayout";
import { faDownload, faFileImport, faFilePdf, faSearch, faShop } from "@fortawesome/free-solid-svg-icons";

export function ModelNfeEmited({viewSale,actionOpenSale,nfeEmited}) {
  
    let StyleClass = nfeEmited.cstat === 100 ? style.containerWrapClosed : style.containerWrap
    
    if(window.innerWidth > 1200){
        return(
            <>
                <div className={StyleClass}>
                    <div className={style.divId}>
                        <h2>{nfeEmited.getStatus()}</h2>
                    </div>
                    <Space left={2}/>
                    <div className={style.divId}>
                        <h2>{nfeEmited.getMod()}</h2>
                    </div>
                    <Space left={2}/>
                    <div className={style.date}>
                        <h2>{nfeEmited.dataEmite}</h2>
                    </div>
                    <Space left={2}/>
                    <div className={style.descrition}>
                        <h2>{nfeEmited.getClientName()}</h2>
                    </div>
                    <Space left={2}/>
                    <div className={style.descrition}>
                        <h2>{nfeEmited.chNFe}</h2>
                    </div>
                    <Space left={2}/>
                    <div className={style.divId}>
                        <h2>{nfeEmited.getTpAmb()}</h2>
                    </div>
                    <Space left={2}/>
                    <div className={style.date}>
                        <GetButtonIcon icon={faSearch} handleFunction={()=>nfeEmited.goLink()} height={40} width={40} color={"#21c8ff"}/>
                        {   viewSale?
                            <>
                                <Space left={5}/>
                                <GetButtonIcon icon={faShop} handleFunction={()=>actionOpenSale(nfeEmited.idSale)} height={40} width={40} color={"#2ec95f"}/>
                            </>:null
                        }
                        <Space left={5}/>
                        <GetButtonIcon icon={faDownload} handleFunction={()=>nfeEmited.goDownLoadXml()} height={40} width={40} color={"#007830"}/>
                        <Space left={5}/>
                        <GetButtonIcon icon={faFileImport} handleFunction={()=>nfeEmited.goXml()} height={40} width={40} color={"#c9a52e"}/>
                        <Space left={5}/>
                        <GetButtonIcon icon={faFilePdf} handleFunction={()=>nfeEmited.getPdf()} height={40} width={40}  color={"#ff0000"}/>
                    </div>
                </div>
            </>  
        )
    }

    return(
        <>
            <div className={StyleClass + ' ' + style.divCo}>
                <div className={style.divRow}>
                    <div className={style.divId}>
                        <h2>{nfeEmited.getStatus()}</h2>
                    </div>
                    <Space left={2}/>
                    <div className={style.divId}>
                        <h2>{nfeEmited.getMod()}</h2>
                    </div>
                    <Space left={2}/>
                    <div className={style.date}>
                        <h2>{nfeEmited.dataEmite}</h2>
                    </div>
                    <Space left={2}/>
                    <div className={style.descrition}>
                        <h2>{nfeEmited.getClientName()}</h2>
                    </div>
                </div>
                <Space top={5}/>
                <div className={style.divRow}>
                <Space left={2}/>
                <div className={style.descrition}>
                    <h2>{nfeEmited.chNFe}</h2>
                </div>
                <Space left={2}/>
                <div className={style.divId}>
                    <h2>{nfeEmited.getTpAmb()}</h2>
                </div>
                <Space left={2}/>
                </div>
                <Space top={5}/>
                <div className={style.divRow}>
                    <div className={style.descrition}>
                    <GetButtonIcon icon={faSearch} handleFunction={()=>nfeEmited.goLink()} height={40} width={40} color={"#21c8ff"}/>
                    {   viewSale?
                        <>
                            <Space left={5}/>
                            <GetButtonIcon icon={faShop} handleFunction={()=>actionOpenSale(nfeEmited.idSale)} height={40} width={40} color={"#2ec95f"}/>
                        </>:null
                    }
                    <Space left={5}/>
                    <GetButtonIcon icon={faDownload} handleFunction={()=>nfeEmited.goDownLoadXml()} height={40} width={40} color={"#007830"}/>
                    <Space left={5}/>
                    <GetButtonIcon icon={faFileImport} handleFunction={()=>nfeEmited.goXml()} height={40} width={40} color={"#c9a52e"}/>
                    <Space left={5}/>
                    <GetButtonIcon icon={faFilePdf} handleFunction={()=>nfeEmited.getPdf()} height={40} width={40}  color={"#ff0000"}/>
                    </div>
                </div>
            </div> 
        </>  
    )
}

export function ModelNfeEmitedHelperName() {
    return(
        <>
            <div className={style.containerWrapHelper}>
                <div className={style.divId}>
                    <h2>STATUS</h2>
                </div>
                <Space left={2}/>
                <div className={style.divId}>
                    <h2>TIPO</h2>
                </div>
                <Space left={2}/>
                <div className={style.date}>
                    <h2>DATA</h2>
                </div>
                <Space left={2}/>
                <div className={style.descrition}>
                    <h2>NOME CLIENTE</h2>
                </div>
                <Space left={2}/>
                <div className={style.descrition}>
                    <h2>CHAVE DE ACESSO</h2>
                </div>
                <Space left={2}/>
                <div className={style.divId}>
                    <h2>AMBIENTE</h2>
                </div>
                <Space left={2}/>
                <div className={style.date}>
                    <h2>ACÕES</h2>
                </div>
            </div>
        </>  
    )
}

