import React, { useState } from "react"
import style from "../../../style/ViewItemBj.module.css"
import {
    faArrowUpWideShort, faBarcode, faCircleCheck, faClose, faDollarSign, faFileAlt
    , faFilePdf, faHashtag, faLink, faLock, faMoneyBill, faMoneyBillTrendUp, faPassport, faSave
} from "@fortawesome/free-solid-svg-icons";
import { TitlePageResponsiveIcon } from "../../../layout/TitlePage";
import { GetButton, GetButtonIcon } from "../../../layout/ButtonsLayout";
import InputModels from "../../../Models/InputModels";
import { faIntercom } from "@fortawesome/free-brands-svg-icons";
import { GetSmallModels } from "../../../Models/GetModels";
import Space from "../../../layout/Space";
import ViewLastEdition from "../ViewLastEditon";
import ConfirmationPopup from "../../../Models/ConfirmationPopup";
import Alert from "../../../Models/Alert";
import Loader from "../../../layout/Loader";
import { setUpdateItem } from "../../../controllers/CloudItems";
import { useError } from "../../../AppContext";
import { myStoreDataMemory } from "../../../backEnd/Memory";
import { employeeData } from "../../../backEnd/InitController";
import GetAds from "../../../Models/GetAds";

const getFormData = (itemBj) => {
    return {
        amount: itemBj.amount,
        personalizedValue: itemBj.personalizedValue,
    }
}

const getFormNeed = () => {
    return {
        amount: false,
    }
}


function ViewItemBj({ itemBj, handleClose, handleRefresh }) {
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)

    const [employeeUser] = useState(employeeData())
    const [canViewHistory] = useState(employeeUser.employeePermission.permissionsItems[3])
    const [canEditeStock] = useState(employeeUser.employeePermission.permissionsItems[0])
    const [canViewBasicData] = useState(employeeUser.employeePermission.permissionsItems[1])
    const [canViewCoast] = useState(employeeUser.employeePermission.permissionsItems[2])
    const { showError } = useError()
    const [showAds , setShowAds] = useState(false)
    const [isVisible, setAlert] = useState(false)
    const [showLastVersion, setShowLastVersion] = useState(false)
    const [formData, setFormData] = useState(getFormData(itemBj))
    const [needData, setNeedData] = useState(getFormNeed)
    const [showConfirmSave, setShowConfirmSave] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        if (e !== '') {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleFindWebSite = (item) => {
        const url = 'https://www.brunasemijoias.com.br/produtos/?Busca=' + item.itemGlobal.name.substring(2, 6);
        window.open(url, '_blank');
    }

    const handleSave = async () => {
        setShowConfirmSave(false)
        setLoading(true)
        itemBj.amount = formData.amount
        itemBj.personalizedValue = formData.personalizedValue === "" ? 0 : parseFloat(formData.personalizedValue)
        var result = await setUpdateItem(itemBj, showError)
        if (result.status === 200) {
            handleRefresh()
            handleClose()
        }

        setLoading(false)
    }

    const handleSubmitSave = () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if (key in needData) {
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            }
        })

        if (allFieldsEmpty) {
            setAlert(true)
            return
        }

        setShowConfirmSave(true)
    }

    const handleImageLoad = () => {
        setImageLoaded(true);
    }

    const handleImageError = () => {
        setImageLoaded(true)
        setErrorOccurred(true)
    }

    return (
        <div className={style.overlay}>
            <div className={style.alert}>
                {!loading ? 
                <> <div className={style.title}>
                    <div className={style.imageContainer}>
                        {errorOccurred ? (
                            <img
                                src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif"
                                alt="Erro de carregamento"
                                className={style.loaded}
                            />
                        ) : (
                            <img src={itemBj.getImage()} alt={itemBj.itemGlobal.name} onLoad={handleImageLoad}
                                onError={handleImageError} className={!imageLoaded ? style.loading : style.loaded} />)}
                    </div>
                    <TitlePageResponsiveIcon title={itemBj.itemGlobal.name}
                             children={<GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose} />}   
                                path={itemBj.itemGlobal.databaseName} icon={faIntercom} /> 
                </div>
                {canViewBasicData?
                    <>
                        <div className={style.divRow}>
                            <div className={style.div}>
                                <GetSmallModels title={'Status'} value={itemBj.itemGlobal.InLineProduction ? 'Em Produção' : 'Fora de Linha'}
                                    subtitle={'É o Status Desse Modelo de Peça...'} icon={faCircleCheck} />
                                <GetSmallModels title={'ID da Peça no Servidor'} value={'100'}
                                    subtitle={'É a Identificação Dessa Peça no Servidor...'} icon={faPassport} />
                            </div>
                            <Space left={10} />
                            <div className={style.div}>
                                <GetSmallModels title={'Código de Barras'} value={itemBj.itemGlobal.codebar}
                                    subtitle={'É o Código de Barras Desse Modelo de Peça...'} icon={faBarcode} />
                                <GetSmallModels title={'Descrição'} value={''}
                                    subtitle={itemBj.itemGlobal.description} icon={faPassport} />
                            </div>
                        </div>
                        <div className={style.divRow}>
                            <div className={style.div}>
                                <GetSmallModels disable={!canViewCoast} title={'Fator/Pontuação'} value={itemBj.itemGlobal.factorItem}
                                    subtitle={'É o Fator de Custo...'} icon={faHashtag} />
                                <GetSmallModels disable={!canViewCoast} title={'Custo Unitário'} value={'R$: ' + itemBj.getValueCoastUnit().toFixed(2)}
                                    subtitle={itemBj.itemGlobal.factorItem + ' * ' + myStoreDataMemory.purchasingFactor + ' = '} icon={faDollarSign} />
                                <GetSmallModels title={'Valor Venda (Unidade)'} value={ itemBj.getValueSaleToString()}
                                    subtitle={itemBj.itemGlobal.factorItem + ' * ' + myStoreDataMemory.profitFactor + ' = '} icon={faMoneyBillTrendUp} />
                            </div>
                            <Space left={5} />
                            <div className={style.div}>
                                <InputModels disable={!canEditeStock} onChange={handleChange}
                                    validation={needData.amount} maxLength={10}
                                    value={formData.amount} name={'amount'}
                                    iconButton={faArrowUpWideShort} type={"number"} placeholder={"Quantidade..."} />
                                <InputModels disable={!canEditeStock} onChange={handleChange}
                                    maxLength={10} value={formData.personalizedValue} name={'personalizedValue'}
                                    iconButton={faMoneyBill} type={"number"} placeholder={"Valor Personalizado..."} />
                                <div className={style.buttonsAction}>
                                    {!canEditeStock ? null : <GetButton title={"SALVAR EDIÇÃO"} color='#189001' icon={faSave} handleFunction={handleSubmitSave} />}
                                    <Space left={5} top={5}/>
                                    {!canViewHistory ? null : <GetButton title={"RELATÓRIO DE EDIÇÕES"} color='#007bff' icon={faFileAlt} handleFunction={() => setShowLastVersion(true)} />}
                                </div>
                            </div>
                        </div>
                        <div className={style.buttonsAction}>
                            <GetButton title={"GERAR ANUNCIO DE DIVULGAÇÃO"} color='#007bff' icon={faFilePdf} handleFunction={()=>setShowAds(true)} />
                            <Space left={5} top={5}/>
                            <GetButton title={"PESQUISAR NO SITE DA BRUNA SEMIJOIAS"} color='#ff0000' icon={faLink} handleFunction={() => handleFindWebSite(itemBj)} />
                        </div>
                    </>:
                    <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                            subtitle={'Você Não Tem Permissão para Visualizar as Iformações dos Produtos'} />
                    }
                </> : <Loader titleFinding={'Trabalhando no Servidor...'}
                    subtitleFinding={'Aguarde...'} loading={loading} />}
                {showLastVersion ? <ViewLastEdition isGeneric={false}
                    last={itemBj}
                    handleClose={() => setShowLastVersion(false)} /> : null}
                 {showAds ? <GetAds name={itemBj.itemGlobal.name} imageLink={itemBj.getImage()} value={itemBj.getValueSaleUnit()} onClose={()=>setShowAds(false)}/> : null}
                {isVisible ? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} /> : null}
                {showConfirmSave ? <ConfirmationPopup title={'SALVAR EDIÇÃO DE PEÇA'}
                    message={'Tem Certeza que Deseja Salvar Edição Dessa Peça?'} onCancel={() => setShowConfirmSave(false)} onConfirm={handleSave} /> : null}
            </div>
        </div>
    )
}

export default ViewItemBj