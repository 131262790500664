import { downloadImage, getRandomNumber, uploadImages } from "../backEnd/ControllerImages"
import { sendRequestGetLocal, sendRequestPostLocal } from "../backEnd/requesition"
import BlockNote from "../objects/BlockNote"

const _routerUploadImageFromCloud = '/notes/uploadListImage'
const _routerGetFromCloud = '/notes/getNotes'
const _routerAddNoteInCloud = '/notes/addNotes'
const _routerUpdateNote = '/notes/updateNotes'
const _routerRemoveNote = '/notes/removeNotes'


export const getNotes = async (showError) => {
    var results = []
    const blockList = []
    try {
        results = await sendRequestGetLocal(_routerGetFromCloud)
        
        if(Array.isArray(results)){
            Object.values(results).forEach(data =>{   
                var tempBlock = new BlockNote()
                tempBlock.fromCloud(data)
                blockList.push(tempBlock)
            })
        }else throw new Error(results.response.data.sqlMessage) 

    } catch (error) {
        showError('ERROR AO BUSCAR BLOCOS DE NOTAS',error.message)
    }finally{
        return blockList
    }
}

/*async function getListImages(blockNote) {
    let list = []
    if (blockNote.images.length > 0) {
        let image = [];
        let count = 0;
        for (let element of blockNote.images) {
            if (element.loadPhotoNetwork) {
                let data = await downloadImage(element.linkImage, count.toString())
                image.push(data)
                count++
            } else {
                image.push(new File(element.linkImage))
            }
        }
        let code = getRandomNumber().toString()
        blockNote.codeImage = code
        list = await uploadImages(image, _routerUploadImageFromCloud, { 'idNotes': code })
    }
    return list;
}*/

export const setUpdateBlockNote= async (blockNote,showError) =>{
    try {  
        //let idNote = blockNote.idNotes;
       // let code = blockNote.codeImage;
       
        let results = await sendRequestPostLocal(_routerUpdateNote,blockNote.getMapFromCloud([])) 
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)

       /* let response = await sendRequestPostLocal(_routerRemoveNoteImage,{
            'codeImage': code,
            'idNotes': idNote,
        }) 
        if(response.status !== 200) throw new Error(response.response.data.sqlMessage)*/

        else return results
    
    } catch (error) {
        showError('ERROR AO ATUALIZAR NOTA',error.message)
        return {status:404}
    }
}

export const setNewBlockCode= async (blockNote,showError) =>{
    try {   
        var results = await sendRequestPostLocal(_routerAddNoteInCloud,blockNote.getMapFromCloud([]))
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO ADICIONAR NOVA NOTA/PEDIDO',error.message)
        return {status:404}
    }
}

export const setBlockNoteRemove= async (blockNote,showError) =>{
    try {   
        var results = await sendRequestPostLocal(_routerRemoveNote,blockNote.getMapFromCloud([]))
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO REMOVER NOTA',error.message)
        return {status:404}
    }
}