import React from 'react';
import style from '../style/GetNoticeMothPay.module.css'
import { faClose, faBell, faUser, faCalendar, faMoneyBill1Wave, faVialCircleCheck, faMessage } from '@fortawesome/free-solid-svg-icons'
import { TitlePageResponsiveIcon } from './TitlePage'
import { GetButton } from './ButtonsLayout'
import { GetSmallModels } from "../Models/GetModels"
import { employeeData } from '../backEnd/InitController';
import { mothPay } from '../backEnd/Memory';
import { faPix } from '@fortawesome/free-brands-svg-icons';

function GetNoticeMothPay({onClose }) {
    const handleClose = () => {
      onClose()
    } 

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
          <div className={style.div}>
            <TitlePageResponsiveIcon subtitle={'MENSALIDADE ESTÁ VENCIADA'} path={'Copie o Código Pix Abaixo...'} 
            icon={faBell}/>  
            <GetSmallModels title={"Olá"}
            value={employeeData().genericPerson.name} icon={faUser} />
            <GetSmallModels title={"Eu sou o Jarbas, Inteligência Artificial do Sistema..."}
            icon={faVialCircleCheck} />
             <GetSmallModels title={"Realizei todos os cálculos e percebi que sua mensalidade venceu."} />
             <GetSmallModels title={"Frase do Dia"}
            subtitle={mothPay.message} icon={faMessage} />
            <GetSmallModels title={"Valor do Sistema Integrado"}
            value={mothPay.valueService} icon={faMoneyBill1Wave} />
             <GetSmallModels title={"Data de Inicio do Serviço: "}
            value={mothPay.getDateStartToString()} icon={faCalendar} />
            <GetSmallModels title={"Código Pix"}
            value={mothPay.payPix} icon={faPix} />
            <div className={style.buttons}>
                <GetButton title={"OK, FECHAR"} color='#E4080A' icon={faClose} handleFunction={handleClose}/>   
            </div>
          </div>
        </div>
      </div>
    )
}

export default GetNoticeMothPay
