import React from "react"
import { faArrowRightToBracket, faFilePdf, faUserClock } from "@fortawesome/free-solid-svg-icons"
import style from '../style/GetSalesOtherFactors.module.css'
import { TitlePageResponsiveIcon } from "../layout/TitlePage"
import { GetButton, GetButtonIcon } from "../layout/ButtonsLayout"
import GetAmountList from "./GetAmountList"

function GetFutureReceivers({dataReceiver,handlerFunction,openPdf}){
    return(
        <div className={style.divGlobal}>
            <div className={style.divRow}>
                <TitlePageResponsiveIcon icon={faUserClock} title={dataReceiver.ownerSales._genericPerson.name}
                    children={
                        <GetButtonIcon icon={faFilePdf} color={"#FF0000"} handleFunction={()=>openPdf(dataReceiver)}/>
                    }
                    subtitle={' | Total Devendo: ' +dataReceiver.getValueTotalOpen()}
                    path={'Quantidade de Vendas em Aberto: ' + dataReceiver.amountTotalSalesOpen}
                />
            </div>
            <div className={style.div}>
                <GetAmountList
                 title={'Vendas com Dividas...'} amount={dataReceiver.amountTotalSalesOpen}/>
                {dataReceiver.saleOpen.map((sale,index) =>(
                    <div className={style.sale}>
                        <div>
                            <p className={style.nameSale}>{sale.getCodeSale()}</p>
                            <div className={style.divRow}>
                                <p className={style.valueSale}>{sale.controllerPayment.getValueNeedPay()}</p>
                                <p className={style.dataSale}>
                                    {'Data: ' + sale.detailsSale.getDateToString().slice(0,10)}
                                </p>         
                            </div>
                        </div>
                        <div>
                           { window.innerWidth > 1200?
                            <GetButton handleFunction={()=>handlerFunction(sale)}
                            title={'VISUALIZAR VENDA'} 
                            height={50} width={175} color='#007bff' 
                            icon={faArrowRightToBracket}/>:
                             <GetButtonIcon handleFunction={()=>handlerFunction(sale)} height={50} width={50} color='#007bff' 
                             icon={faArrowRightToBracket}/>
                           }
                        </div>
                    </div>
                ))}
            </div>        
        </div>
    )
}

export default GetFutureReceivers