import React, { useState } from 'react';
import style from '../../style/EditeEmployeeSimple.module.css'
import { faClose, faEdit, faSave, faSignature, faBuilding, faKey } from '@fortawesome/free-solid-svg-icons';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage';
import { GetButton, GetButtonIcon } from '../../layout/ButtonsLayout';
import InputModelsBlock from '../../layout/InputModelsBlock';
import Space from '../../layout/Space';
import InputPhoto from '../../Models/InputPhoto';
import Alert from '../../Models/Alert';
import ConfirmationPopup from '../../Models/ConfirmationPopup';
import Loader from '../../layout/Loader';
import { setEditeEmployeeSimple, setSaveEmployee, setUpdatePassword } from '../../controllers/CloudEmployee';
import { useError } from '../../AppContext';
import { Employee } from '../../objects/ClientsAndPerson/employee';
import { setStartMyStore } from '../../controllers/CloudMyStore';
import GetChangePassword from '../../Models/GetChangePassword';

const getFormData = (employee) => {
    return {
        name:employee? employee.genericPerson.name :'',
        function:employee? employee.function : '',
        keycode:employee? employee.keycode : '',
    }
}

const getFormNeed = () => {
    return {
        name:false,
        function:false,
        keycode:false,
    }
}

function EditeEmployeeSimple({employeeData,onClose,onSave}) {

    const [employeeUser] =  useState(employeeData ?? new Employee())
    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData(employeeUser))
    const [needData, setNeedData] = useState(getFormNeed)
    const [showConfirmSave,setShowConfirmSave] = useState(false)
    const [selectedImage] = useState(employeeUser.linkImage)
    const [selectedImageFromFile,setSelectedImageFromFile] = useState(null)
    const [showChangePassword, setShowChangePassword] = useState(false)
    const [loading,setLoading] = useState(false)
    const {showError} = useError()

    const handleClose = () => {
      onClose()
    } 

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmitSave= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setAlert(true)
            return
        }

        setShowConfirmSave(true)
       
    }

    const handleConfirmSave = async () =>{
        setShowConfirmSave(false)
        setLoading(true)
        let result

        if(employeeUser.idEmployee === 0){
            employeeUser.fromInitMaster(formData)
            result = await setSaveEmployee(selectedImageFromFile,employeeUser,showError)
            if(result.status === 200)
                await setStartMyStore(showError)
        }else{
            employeeUser.fromEditeSimple(formData)
            result = await setEditeEmployeeSimple(selectedImageFromFile,employeeUser,showError)
        }
       
 
        if(result.status === 200){
            onSave()
            handleClose()
        }

        setLoading(false)
    }

    const handleChangePassword = async(value)=>{

        setLoading(true)
        const result = await setUpdatePassword(value.lastPassword,value.password,employeeUser.idEmployee,showError)
        setLoading(false)

        if(result.status === 200){
            showError('SENHA TROCADA COM SUCESSO','Você Modificou a Senha de Acesso')
            return true
        }
        else return false
    }

    const handlerSlectedImageFromFile = (image) =>{
        setSelectedImageFromFile(image)
    }

    return (
      <div className={style.overlay}>
        <div className={style.alert}>
           {!loading ? <>
            <div className={style.title}>
                <TitlePageResponsiveIcon subtitle={"EDITAR PERFIL"} 
                children={<GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/>}
                path={"Complete os Campos Abaixo Para Editar o Perfil..."} icon={faEdit}/>  
           </div>
           <div className={style.div}>
                <InputPhoto initialPhoto={selectedImage} handlerFileSelected={handlerSlectedImageFromFile}/>
                <Space top={5}/>
                <InputModelsBlock legend={'NOME DO FUNCIONÁRIO'} 
                onChange={handleChange} 
                validation={needData.name}
                maxLength={25} value={formData.name} 
                name={'name'} 
                iconButton={faSignature}
                type={"text"} 
                placeholder={"Nome do Funcionário..."}/>
                <Space top={5}/>
                <InputModelsBlock legend={'CARGO/FUNÇÃO NA LOJA'} 
                onChange={handleChange} 
                validation={needData.function} 
                maxLength={25} 
                value={formData.function} 
                name={'function'} 
                iconButton={faBuilding} 
                type={"text"} 
                placeholder={"Cargo/Função ..."}/>  
                <Space top={5}/>
                {employeeUser.idEmployee === 0 ?
                    <InputModelsBlock legend={'SENHA PARA ENTRAR NO PERFIL'} 
                    onChange={handleChange} 
                    validation={needData.keycode} 
                    maxLength={25} 
                    value={formData.keycode} 
                    name={'keycode'} 
                    iconButton={faKey} 
                    type={"text"} 
                    placeholder={"Senha de Login ..."}/>:
                    <div className={style.div}>
                        <GetButton title={"TROCAR SENHA"} color='#FF0000' icon={faKey} 
                        handleFunction={()=>setShowChangePassword(true)}/> 
                    </div>
                }
           </div>
           <div className={style.div}>
                <GetButton title={employeeUser.idEmployee === 0 ? 'SALVAR PERFIL MASTER':
                "SALVAR EDIÇÃO DE PERFIL"} color='#007bff' icon={faSave} 
                handleFunction={handleSubmitSave}/> 
           </div>
           </>:
            <div className={style.loaderCenter}>
                <Loader titleFinding={'Salvando Dados no Servidor...'}
                    subtitleFinding={'Aguarde...'} loading={loading} />
            </div>}
            {showChangePassword ? <GetChangePassword handleCheck={handleChangePassword} handleClose={()=>setShowChangePassword(false)}/> : null}
            {showConfirmSave? <ConfirmationPopup title={'SALVAR EDIÇÃO DO PERFIL'} 
            message={'Tem Certeza que Deseja Salvar Edição do Seu Perfil'} 
            onCancel={()=>setShowConfirmSave(false)} onConfirm={handleConfirmSave}/>: null}   
            {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
      </div>
    )
}

export default EditeEmployeeSimple
