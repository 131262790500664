import React, { useState } from "react"
import style from "../style/SaleModel.module.css"
import { faCartShopping, faGift, faShopSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import style1 from "../style/ModelFinancial.module.css"
import Space from "../layout/Space"

function SaleModel({sale,onClick ,functionImage,inClient}){
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    
    const handleImageLoad = () => {
        setImageLoaded(true); 
    }

    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }

    var isSpecial = sale.detailsSale.isPresent || sale.cancelSale.isCancel
    var icon = sale.detailsSale.isPresent?  faGift: faCartShopping
    icon = sale.cancelSale.isCancel?  faShopSlash: icon

    return(
        <div className={style.containerWrap}>
            <div className={style.containerWrap1}>
                {isSpecial? 
                <div className={style.icon}>
                    <FontAwesomeIcon icon={icon} />
                </div>
                : <> <div className={style.imageContainer}>
                    {errorOccurred ? (
                                <img
                                src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                                alt="Erro de carregamento"
                                className={style.loaded}
                                />
                            ) : (
                            <img src={sale.tempEmployee.linkImage} alt={sale.tempEmployee.genericPerson.name} onLoad={handleImageLoad}
                                onError={handleImageError} onClick={functionImage} className={!imageLoaded ? style.loading : style.loaded}/> )}
                              
                    </div> 
                </>}
                <button className={style.container} onClick={onClick}>
                    <div className={style.containerNames}>
                       {window.innerWidth > 1200?
                        <>
                            <div className={style.div}>
                                <h6 className={style.status}>{sale.getTotalValueIncludeAllToString()}</h6>
                                <p>{'ID:' + sale.idSaleCloud + ' -  VENDEDOR: ' + sale.tempEmployee.genericPerson.name}</p>
                            </div>
                            <h5>{sale.detailsSale.getDateToString().slice(0,10)}</h5>
                            <h4>{sale.cancelSale.isCancel? 'CANCELADA':(sale.detailsSale.isPresent?'BRINDE':'VENDA')}</h4>
                            <h3>{!inClient ? sale.thisOwner._genericPerson.name :  sale.saleDiscount.discountPercent + "%"}</h3>
                            <h6 className={sale.detailsSale.isOpen? style.status + " " + style.desable :style.status}>
                                {sale.detailsSale.isOpen?  sale.controllerPayment.getTotalNeedingPaymentToString() : 'QUITADO'}
                            </h6>
                            <h6 className={!sale.hasNfeEmited()?  style.status + " " + style.desable : style.status}>
                                {sale.hasNfeEmited() ? 'EMITIDA': 'NÃO EMITIDA'}
                            </h6>
                        </>:
                        <>
                            <div className={style.div}>
                                <h6 className={style.status}>{sale.getTotalValueIncludeAllToString()}</h6>
                                <p>{'ID:' + sale.idSaleCloud + ' -  VENDEDOR: ' + sale.tempEmployee.genericPerson.name}</p>
                            </div>
                            <h6 className={sale.detailsSale.isOpen? style.status + " " + style.desable :style.status}>
                                {sale.detailsSale.isOpen?  sale.controllerPayment.getTotalNeedingPaymentToString() : 'QUITADO'}
                            </h6>
                            
                        </>
                       }
                    </div>
                </button>
            </div>
        </div>
    )
 
}


export function SaleModelHelperName() {
    return(
        <>
            <div className={style1.containerWrapHelper}>
                <div className={style1.descrition}>
                    <h2>VALOR VENDA</h2>
                </div>
                <Space left={2}/>
                <div className={style1.date}>
                    <h2>DATA</h2>
                </div>
                <Space left={2}/>
                <div className={style1.date}>
                    <h2>TIPO DA VENDA</h2>
                </div>
                <Space left={2}/>
                <div className={style1.date}>
                    <h2>% DESCONTO</h2>
                </div>
                <Space left={2}/>
                <div className={style1.date}>
                    <h2>STATUS</h2>
                </div>
                <Space left={2}/>
                <div className={style1.date}>
                    <h2>NFe/NFC-e</h2>
                </div>  
            </div>
        </>  
    )
}

export default SaleModel