import React,{useState} from "react";
import style from "../style/ProductModel.module.css"
import { employeeData } from "../backEnd/InitController";

function ProductModel({data,functionButton,functionImage}) {
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    const [canViewCoast] = useState(employeeData().employeePermission.permissionsItems[2])
    const [canShow] = useState(window.innerWidth > 1200)
    
    const handleImageLoad = () => {
        setImageLoaded(true); 
    }


    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }
    const timestamp = new Date().getTime();
    const imageUrlWithTimestamp = `${data.linkImage}?t=${timestamp}`;

    return(
        <div className={style.containerWrap}>
          <div className={data.status ? style.containerWrap1 : `${style.containerWrap1} ${style.desable}`}>
                {
                    data.linkImage !==""?
                    <div className={style.imageContainer}>
                                {errorOccurred ? (
                                    <img
                                    src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                                    alt="Erro de carregamento"
                                    className={style.loaded}
                                    />
                                ) : (
                                <img src={imageUrlWithTimestamp} alt={data.nameProduct} onLoad={handleImageLoad}
                                    onError={handleImageError} onClick={functionImage}
                                    className={!imageLoaded ? style.loading : style.loaded}/> )}
                    </div> :null
                }
                <div className={style.container} onClick={functionButton}>
                    <div className={style.dataValues}>
                        <div className={style.containerAmount}>
                            <p className={`${style.amountHas} ${data.amount > 0 ? '' : style.noHas}`}>
                                {data.amount}
                            </p>
                        </div>
                        <div className={style.containerNames}>
                            <h3>{data.nameProduct}</h3>
                            {canShow?<h4>{data.codebar}</h4>:null}
                            {canShow?<h4>{data.nameBrandTemp}</h4>:null}
                            <h5>R$:{parseFloat(data.getSaleValueUnit()).toFixed(2)}</h5>
                            {canShow?<h6>{canViewCoast?'R$:' + parseFloat(data.getCoastValueUnit()).toFixed(2):'****'}</h6>:null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ProductModelFromSale({data,functionImage}) {
    const [imageLoaded, setImageLoaded] = useState(false)
    const [errorOccurred, setErrorOccurred] = useState(false)
    
    const handleImageLoad = () => {
        setImageLoaded(true); 
    }


    const handleImageError = () => {
        setImageLoaded(true) 
        setErrorOccurred(true)
    }
    const timestamp = new Date().getTime();
    const imageUrlWithTimestamp = `${data.linkImage}?t=${timestamp}`;

    return(
        <div className={style.containerWrap}>
          <div className={data.status ? style.containerWrap1 : `${style.containerWrap1} ${style.desable}`}>
                <div className={style.imageContainer}>
                            {errorOccurred ? (
                                <img
                                src="https://cdn.neemo.com.br/uploads/settings_webdelivery/logo/1209/nao-perfil.gif" 
                                alt="Erro de carregamento"
                                className={style.loaded}
                                />
                            ) : (
                            <img src={imageUrlWithTimestamp} alt={data.nameProduct} onLoad={handleImageLoad}
                                onError={handleImageError} onClick={functionImage}
                                className={!imageLoaded ? style.loading : style.loaded}/> )}
                </div> 
                <div className={style.container}>
                    <div className={style.dataValues}>
                        <div className={style.containerAmount}>
                            <p className={`${style.amountHas} ${data.amount > 0 ? '' : style.noHas}`}>
                                {data.amount}
                            </p>
                        </div>
                        <div className={style.containerNames}>
                            <h3>{data.nameProduct}</h3>
                            {window.innerWidth > 1200? <h4>{data.codebar}</h4>:null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductModel