import React from "react";
import { Link } from "react-router-dom";
import logoApi from "../assets/icon-android1.png"
import styles from "../style/NavigatorBar.module.css";
import { faBell, faUser, faUsers} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { routerFromAdminController, routerFromAdminNoticesController } from "../routers/routerList";
const nameApp = 'BSemijoias Gerenciador'

function NavigatorBar(){
    return(
        <div className={styles.naviBar}>
            <div className={styles.logo}>
                <img src={logoApi} className={styles.containerIconeApp} alt="Logo BSemijoias Api" />
                <span className={styles.title}>{nameApp}</span>
            </div>
            <div className={styles.containerMenu}>
                <ul className={styles.menu}>
                    <li>
                        <Link to='/' className={styles.menuItem}>
                            Entrar
                            <FontAwesomeIcon  className={styles.menuTranstion} icon={faUser} />
                        </Link>
                    </li>
                    {/*<li>
                        <Link to='/about-more' className={styles.menuItem}>
                         Saber Mais
                         <FontAwesomeIcon className={styles.menuTranstion} icon={faBars} />
                        </Link>
                    </li>*/}
                </ul>
            </div>
        </div>
    );
}

export function NavigatorBarAdmin(){
    return(
        <div className={styles.naviBar}>
            <div className={styles.logo}>
                <img src={logoApi} className={styles.containerIconeApp} alt="Logo BSemijoias Api" />
                <span className={styles.title}>{'BSemijoias - ADMIN'}</span>
            </div>
            <div className={styles.containerMenu}>
                <ul className={styles.menu}>
                    <li>
                        <Link to={routerFromAdminController} className={styles.menuItem}>
                            USÚARIOS
                            <FontAwesomeIcon  className={styles.menuTranstion} icon={faUsers} />
                        </Link>
                    </li>
                    <li>
                        <Link to={routerFromAdminNoticesController} className={styles.menuItem}>
                            AVISOS
                            <FontAwesomeIcon  className={styles.menuTranstion} icon={faBell} />
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export function NavigatorBarSelectUser(){
    return(
        <div className={styles.naviBar}>
            <div className={styles.logo}>
                <img src={logoApi} className={styles.containerIconeApp} alt="Logo BSemijoias Api" />
                <span className={styles.title}>{nameApp}</span>
            </div>
        </div>
    );
}

export default NavigatorBar