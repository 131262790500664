import React from "react";
import InputModels from "../Models/InputModels";
import style from "../style/InputModelsBlock.module.css"

function InputModelsBlock({legend,iconButton, type, placeholder,validation,name,disable, onChange, value,maxLength}){
    return(
        <div className={style.div}>
            <h5>{legend}</h5>
            <div className={style.inputFilter}>            
                <InputModels 
            
                iconButton={iconButton} 
                type={type} 
                value={value}
                name={name}
                disable={disable}
                validation={validation}
                maxLength={maxLength}
                placeholder={placeholder}
                onChange={onChange}/>
            </div>
        </div>
    );
}

export default InputModelsBlock