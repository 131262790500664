import React, { useState } from "react"
import { TitlePageResponsiveIcon } from "../../../layout/TitlePage"
import { faLock, faSkullCrossbones, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import style from "../../../style/ClearStock.module.css"
import { GetButton } from "../../../layout/ButtonsLayout"
import GetPassword from "../../../Models/GetPassword"
import ConfirmationPopup from "../../../Models/ConfirmationPopup"
import { employeeData } from "../../../backEnd/InitController"
import { setClearStock } from "../../../controllers/CloudClearStock"
import Alert from "../../../Models/Alert"
import Loader from "../../../layout/Loader"
import { useError } from "../../../AppContext"
import { GetSmallModels } from "../../../Models/GetModels"

function ClearStock() {
    const [canEdite] = useState(employeeData().employeePermission.permissionsItems[0])
    const [showGetPassword, setShowGetPassword] = useState(false)
    const [showConfirmSave, setShowConfirm] = useState(false)

    const { showError } = useError()
    const [loading, setLoading] = useState(false)
    const [showAlertSave, setShowAlertSave] = useState(false)

    const handleCheckPassword = async (password) => {
        if (password === employeeData().keycode) {
            setShowConfirm(true)
            setShowGetPassword(false)
        } else showError("Senha Incorreta", "É Necessario a Senha Correta...")
    }

    const handleClear = async () => {
        setShowConfirm(false)
        setLoading(true)
        var result = await setClearStock(showError)
        setLoading(false)
        if (result.status === 200) {
            setShowAlertSave(true)
        }
    }

    return (
        <div>
            {!canEdite ?
                <div className={style.containerGlobal}>
                    <div className={style.container}>
                        <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                            subtitle={'Você Não Tem Permissão para Editar / Zerar o Estoque da Loja'} />
                    </div>
                </div>
                :
                <>
                    <div className={style.containerGlobal}>
                        <div className={style.container}>
                            {!loading ?
                                <> <div className={style.titleMenu}>
                                    <TitlePageResponsiveIcon subtitle={'Zerar Estoque Completo'}
                                       children={
                                        <GetButton title={'ZERAR ESTOQUE'}
                                            responsive={true}
                                            color='#FC0103' icon={faTrashCan} handleFunction={() => setShowGetPassword(true)} />
                                       } 
                                        path={window.innerWidth > 1200? 'Atenção: Zerar o Estoque Completo é Uma Operação sem Volta, Após Clicar no Botão Não Será Possivel Cancelar!': 'Cuidado, Isso Vai Zerar o Estoque'} icon={faSkullCrossbones} />
                                    
                                </div>
                                </>
                                : <Loader titleFinding={'Zerando o Seu Estoque no Servidor...'}
                                    subtitleFinding={'Aguarde...'} loading={loading} />}
                        </div>
                    </div>
                    {showAlertSave ? <Alert top={100} message={"Todos os Estoques Estão Zerados"} onClose={() => setShowAlertSave(false)} type={"success"} /> : null}
                    {showConfirmSave ? <ConfirmationPopup
                        title={'ATENÇÃO - ZERAR ESTOQUE'} message={'Tem Certeza que Deseja Zerar o Estoque da Loja?'} onCancel={() => setShowConfirm(false)}
                        onConfirm={handleClear} /> : null}
                    {showGetPassword ?
                        <GetPassword handleCheck={handleCheckPassword} titleButton={'ZERAR ESTOQUE AGORA'}
                            handleClose={() => setShowGetPassword(false)} /> : null}
                </>}
        </div>
    )
}

export default ClearStock