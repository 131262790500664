import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { routerFromMyStoreGlobal, routerFromMyStoreData,routerFromMyStoreEmployees,routerFromMyStoreActions,errorRouter, routerFromMyStoreAddEmployees } from "../../routers/routerList.js";
import style from "../../style/Controller.module.css"
import { NavBarGlobal, ObjectNavbarMenu } from "../NavbarGlobal.js";
import { faHouseUser,faServer,faBuildingUser,faUserPlus,faIdCard,faUsersRectangle } from "@fortawesome/free-solid-svg-icons";
import ActionsStore from "./ActionsStore.js";
import DataStore from "./DataStore.js";
import ListEmployee from "./ListEmployee.js";
import AddEmployee from "../Employees/AddEmployee.js";

function ControllerMyStore({isLoggedIn}){
    const location = useLocation();


    const menu1 = new ObjectNavbarMenu('Menu da Loja',faHouseUser)
    menu1.addMenu('Dados da Loja',faServer,routerFromMyStoreData)
    menu1.addMenu('Ações da Loja',faBuildingUser,routerFromMyStoreActions)
    
    const menu2 = new ObjectNavbarMenu('Funcionários',faIdCard)
    menu2.addMenu('Listar Funcionários',faUsersRectangle,routerFromMyStoreEmployees)
    menu2.addMenu('Cadastrar Funcionário',faUserPlus,routerFromMyStoreAddEmployees)

    const listRoutesToNav = [menu1,menu2];

    return isLoggedIn ? (
        <div className={style.containerGlobal}>
        <NavBarGlobal listObjectNavBarMenu={listRoutesToNav}/>
        <div className={style.containerData}>
            {(() => {
                switch(location.pathname){
                    case routerFromMyStoreGlobal: return <DataStore/>
                    case routerFromMyStoreData: return <DataStore/>
                    case routerFromMyStoreActions: return <ActionsStore/>
                    case routerFromMyStoreEmployees: return <ListEmployee/>
                    case routerFromMyStoreAddEmployees: return <AddEmployee/>
                    default: return <Navigate to={errorRouter} replace />
                }
            })()}
        </div>
    </div>
    ) : (
        <Navigate to="/login" replace />
    )
}

export default ControllerMyStore