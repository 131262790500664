class AccountingAllRepairs{
    constructor(){
        this.valueAllReceiverInFuture = 0
        this.valueReceived = 0

        this.accountingRepairs = [] // AccountingAllSalesReceiver
    }

    clear(){
        this.valueAllReceiverInFuture = 0;
        this.valueReceived = 0;
    }

  calculate(){
    this.valueAllReceiverInFuture = 0;
    this.valueReceived = 0;
    for (var element of this.accountingRepairs) {
        if(element.status === 1){
            this.valueReceived += parseFloat(element.value)
        }else  if(element.status === 0){
            this.valueAllReceiverInFuture += parseFloat(element.value)
        }
    }
  }

  getTotalReceived(){
    return this.valueReceived.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }
  getTotalSaleReceiverInFuture(){
    return this.valueAllReceiverInFuture.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }
}

export default AccountingAllRepairs