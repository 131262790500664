import { useEffect, useState } from "react";
import style from "../style/StateCountry.module.css"
import style1 from "../style/InputModelsBlock.module.css"
import Space from "./Space";
export const GenericDropDown = ({ data, legend, initialState, disable, handlerFuncion, name }) => {
    const [type, setType] = useState("");
  
    useEffect(() => {
      if (initialState) {
        const matchingItem = data.find(item => item.includes(initialState))
        if (matchingItem) {
          setType(matchingItem)
        }
      }
    }, [initialState, data])
  
    const handleChange = (event) => {
      setType(event.target.value)
      handlerFuncion(event)
    }
  
    return (
      <div className={style1.div}>
        <h5>{legend}</h5>
        <Space bottom={5}/>
        <div>
          <select
            disabled={disable}
            name={name}
            className={style.state}
            onChange={handleChange}
            value={type}
          >
            {data.map((status, index) => (
              <option key={index} value={status}>{status}</option>
            ))}
          </select>
        </div>
      </div>
    );
  };