import React, { useEffect, useState } from "react"
import style from "../../../style/ViewSaleController.module.css"
import { TitlePageResponsiveIcon } from "../../../layout/TitlePage"
import { GetButton, GetButtonIcon } from "../../../layout/ButtonsLayout"
import { faBalanceScale, faBookSkull, faCalendar, faCancel, faCheckDouble, faCircleInfo, faClose, faCommentsDollar, faCreditCard, faDatabase, faEdit, faFilePdf
    , faGift, faHandHoldingDollar, faInfo, faKey, faKeyboard, faLandmark, faListUl, faLock, faMoneyCheckDollar, faPlus, faRectangleXmark, faRemove, faSackDollar, faSave, faShopSlash, faTags, faTicket, faWarning } from "@fortawesome/free-solid-svg-icons"
import { GetSmallModels } from "../../../Models/GetModels"
import Space from "../../../layout/Space"
import { faPix, faProductHunt } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ViewList from "../ViewList"
import { employeeData } from "../../../backEnd/InitController"
import GetEmployee from "../../../Models/GetEmployee"
import InputModelsBlock from "../../../layout/InputModelsBlock"
import Alert from "../../../Models/Alert"
import ConfirmationPopup from "../../../Models/ConfirmationPopup"
import { getSaleWithID, setUpdateDescription, updateSaleCancel, updateSalePayments } from "../../../controllers/CloudSales"
import Loader from "../../../layout/Loader.js";
import Payment from "../../../objects/Sale/Payment.js"
import ControllerPayment from "../../../objects/Sale/ControllerPayment.js"
import { useError } from "../../../AppContext.js"
import { databaseSettings, listClientsMemory, listEmployeeMemory, listItemsMemory, listProductsMemory, updateAfterSale } from "../../../backEnd/Memory.js"
import ClientsModels from "../../../Models/ClientsModels.js"
import GetAmountList from "../../../Models/GetAmountList.js"
import { EditeSale } from "../EditeSale.js"
import GetPix from "../../../Models/GetPix.js"
import { GetSaleNFe } from "../../Nfe/GetSaleNFe.js"
import { ShowNfeEmited } from "../../Nfe/ShowNfeEmited.js"

function ViewSaleController({saleStart,handleClose,handleSave,canEdite,cantEditeDescription}){

   
    const [sale,setSale] = useState(saleStart)    
    const [employeeUser] = useState(employeeData())
    const [canCancel] = useState(employeeUser.employeePermission.permissionsSales[1])
    const [canViewOtherEmployeesSale] = useState(employeeUser.employeePermission.permissionsSales[4])
    const [canEditeOtherEmployeesSale] = useState(employeeUser.employeePermission.permissionsSales[3])
    const [isMySale, setIsMySale] = useState(false)
    
    const [valuePix,setValuePix] = useState('')
    const [showGetPix,setShowGetPix] = useState(false)
    const [index,setIndex] = useState(0)
    const [icon,setIcon] = useState(faInfo)
    const [labelTitleMenu,setLabelTitleMenu] = useState('DETALHES') 
    const [descriptionTitle,setDescriptionTitle] = useState('São Iformações de Detalhes') 
    const [isVisible, setIsVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const {showError} = useError()
    const [showEditeSale, setShowEditeSale] = useState(false)


    useEffect(() => {
        if (sale.tempEmployee.idEmployee === employeeUser.idEmployee) {
            setIsMySale(true);
        }
    }, []);

    const updateSaleController = async() => {
        setLoading(true)
        const data = await getSaleWithID(showError,sale.idSaleCloud,listProductsMemory,listItemsMemory,listEmployeeMemory,listClientsMemory)
        setSale(data)
        handleSave()
        setLoading(false)
    }

    const [formData, setFormData] = useState({
        description: sale.detailsSale.description,
        descriptionCancel:'',
        cancelConfirmation:'',
        valuePayment:'',
        descriptionPayment:'',
        dateCalender:new Date().toISOString().slice(0, 10)
    })

    const [needData, setNeedData] = useState({
        descriptionCancel:false,
        cancelConfirmation:false,
    })

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSaveDescription = async () =>{
        setLoading(true)
        sale.setUpdateDescription(formData.description)
        var result = await setUpdateDescription(sale,showError)
         
        if(result.status === 200){
            setIsVisible(true)
            handleSave()
        }
         

        setLoading(false)
    }

    const handleSaveAndClose = () =>{
        handleSave()
        handleClose()
    }

    const handleGoToCancelSale = () =>{
        setIcon(faCancel)
        setIndex(4)
        formData.cancelConfirmation = ''
        formData.descriptionCancel = ''
        setLabelTitleMenu('CANCELAR VENDA')
        setDescriptionTitle('Atenção Você Irá Cancelar a Venda!') 
    }

    const requestPdf = async () => { 
        setLoading(true)
        await sale.openPDFExtract(showError)
        setLoading(false)
    }

    const requestPdfPayment = async () => { 
        setLoading(true)
        await sale.openPDFPayments(showError)
        setLoading(false)
    }

    var title = sale.cancelSale.isCancel? 'VENDA CANCELADA' : 'VENDA'
    title = sale.detailsSale.isPresent ? 'BRINDE' : title
    
    if(!canViewOtherEmployeesSale && sale.tempEmployee.idEmployee !== employeeUser.idEmployee){
        return(
            <div className={style.overlay}>
                <div className={style.alertPayment}>
                    <div className={style.title}>
                        <TitlePageResponsiveIcon 
                        children={
                            <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                        } icon={faLock} title={'ACESSO NEGADO'}
                        path={'Você Não Tem Permissão para Visualizar a Venda de Outro Funcionário'} />
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className={style.overlay}>
                <div className={style.alert}>
                { !loading ? 
                <>
                    <div className={style.div}>
                        <div className={style.title}>
                                <TitlePageResponsiveIcon subtitle={labelTitleMenu} 
                                        children={
                                            <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                                        }
                                        path={descriptionTitle } 
                                        icon={icon}/>
                                <NavigationBarOptions 
                                setIndex ={setIndex}
                                setIcon = {setIcon}
                                index = {index}
                                setDescriptionTitle={setDescriptionTitle}
                                setLabelTitleMenu={setLabelTitleMenu}  
                                sale={sale}/>           
                        </div>
                    </div>
                    <Space top={50}/>
                        {index === 0 ?
                            <>
                                {sale.cancelSale.isCancel? 
                                <div className={style.div}>
                                    <GetSmallModels title={'STATUS DESSA VENDA: '}
                                    icon={faShopSlash}
                                    value={'VENDA CANCELADA'}
                                    />
                                </div> : null}
                                <Space top={50}/>
                                {sale.detailsSale.isPresent? 
                                <div className={style.div}>
                                    <GetSmallModels title={'É UM BRINDE'}
                                    icon={faGift}/>
                                </div> : null}
                                {sale.detailsSale.isOpen? 
                                <div className={style.div}>
                                    <GetSmallModels backgroundColor={'#ff0000'} title={'VENDA COM DIVIDA :' } value={sale.controllerPayment.getTotalNeedingPaymentToString()}
                                    icon={faHandHoldingDollar}/>
                                </div> : null}
                                <Space top={50}/>
                                <ViewFinalValue updateSaleController={updateSaleController} setValuePix={setValuePix} setShowGetPix={setShowGetPix} requestPdf={requestPdf} sale={sale}/>
                                <Space top={50}/>
                                <ViewBJ sale={sale}/>
                                <Space top={50}/>
                                <ViewGenericProducts sale={sale}/>
                                <Space top={50}/>
                                <ViewDiscount sale={sale}/>
                                <Space top={50}/>
                                <ViewExtraValues sale={sale}/>
                                <Space top={50}/>
                                <ViewBasicDetails sale={sale}/>
                                <Space top={50}/>
                                <ViewMotionCancel sale={sale}/>
                                <Space top={50}/>
                                <div className={style.divInRow}>
                                    <div className={style.divInput}>
                                        <GetSmallModels 
                                        title={'Forma de Pagamento: '}
                                        subtitle={'É a Forma de Pagamento Dessa Venda'}
                                        value={sale.detailsSale.payForm}/>
                                        {sale.cancelSale.isCancel || !canEdite? null:
                                        <div className={style.divInRow}>
                                            {canCancel? <div className={style.divInRow}>
                                                <GetButton title={"CANCELAR VENDA"} 
                                                color='#ff0000' 
                                                icon={faCancel} 
                                                handleFunction={handleGoToCancelSale}/> 
                                            </div>:null}
                                            {/*<Space left={5}/>
                                            <div className={style.divInRow}>
                                                <GetButton title={"EDITAR VENDA"}
                                                    color='#019503'
                                                    icon={faEdit} 
                                                    handleFunction={()=>{
                                                        //()=>setShowEditeSale(true)
                                                        showError('EDIÇÃO DE VENDA APENAS NA VERSÃO DE ANDROID','Como Editar uma Venda é uma Operação Complexa, Momentaneamente Essa Operação só está disponível no Android.')
                                                    }}/>
                                            </div>*/}
                                        </div>}
                                    </div>
                                    <Space left={5} top={5}/>
                                    <div className={style.divInput}>
                                        <InputModelsBlock disable={!(isMySale || canEditeOtherEmployeesSale)}
                                        legend={'DETALHES DA VENDA'}
                                        onChange={handleChange} maxLength={500} 
                                        value={formData.description} name={'description'} iconButton={faKeyboard} type={"text"} 
                                        placeholder={"Detalhar algo Sobre a Venda..."}/>
                                        {(cantEditeDescription?? true) && (isMySale || canEditeOtherEmployeesSale)?<div className={style.divInRow}>
                                            <GetButton title={"SALVAR EDIÇÃO DE DETALHES DA VENDA"} color='#007bff' icon={faSave} handleFunction={handleSaveDescription}/>
                                        </div>:null}
                                    </div>
                                </div> 
                            </> : null
                        }
                      
                        {index === 1? 
                        <ViewPayment
                         setValuePix ={setValuePix}
                         setShowGetPix={setShowGetPix}
                         requestPdfPayment={requestPdfPayment}
                         canEdite={canEdite && (isMySale || canEditeOtherEmployeesSale)}
                         formData={formData}
                         setLoading={setLoading}
                         showError={showError}
                         handleChange={handleChange}
                         handleSave={handleSaveAndClose}
                         sale={sale}/>:null}       
                        {index === 2? <ViewList
                                icon={faListUl}
                                title={'LISTA DE PRODUTOS DA ' + title} 
                                description={'É a Lista Relacionando os Produtos'}
                                listItems={[...sale.listGenericProduct.genericProducts]} isBJ={false}/>:null}
                        {index === 3? <ViewList
                                icon={faListUl}
                                title={'LISTA DE SEMIJOIAS DA ' + title} 
                                description={'É a Lista Relacionando a Bruna Semijoias'}
                                listItems={[...sale.itemsThisSale.listItems]} isBJ={true}/>:null}      
                        {index === 4 ? <ViewCancelSale 
                        setLoading={setLoading}
                        handleSave={handleSaveAndClose}
                        handleChange={handleChange}
                        formData={formData}
                        needData={needData}
                        showError={showError}
                        setNeedData={setNeedData}
                        sale={sale} />:null}
                       
                        </> : 
                    <div className={style.loaderCenter}>
                        <Loader titleFinding={'Trabalhando no Servidor...'}
                        subtitleFinding={'Aguarde...'} loading={loading} />
                    </div>}
                </div> 
            {showGetPix ? <GetPix valor={valuePix} onClose={()=>setShowGetPix(false)}/> : null}
            {showEditeSale? <EditeSale onClose={()=>setShowEditeSale()} saleEdite={sale}/> : null} 
            {isVisible? <Alert  message={"Detalhes da Venda Salvos com Sucesso!"} 
            top={'2%'}
            right={'2%'} type={'success'} />:null}
        </div>
    )
}

function ViewCancelSale({sale,handleSave,setLoading,showError,
    needData,setNeedData,formData,handleChange}){

    const [isVisible, setIsVisible] = useState(false)
    const [showConfirmSave,setShowConfirmSave] = useState(false)
   
    const handleConfirm = async () => {
        setLoading(true)

        var result = await updateSaleCancel(sale,formData.descriptionCancel,showError)
          
        if(result.status === 200){
            await updateAfterSale(showError)
            handleSave()
        }

        setLoading(false)
    }

    const handleSubmitSave = () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else {
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
                }
                    
            } 
        })
        
        if (allFieldsEmpty) {
            setIsVisible(true)
            return
        }

        if(formData.cancelConfirmation !== 'CANCELAR'){
            showError('ERRO DE CONFIRMAÇÃO','Você Precisa Digitar CANCELAR Corretamente')
            return
        }

        setShowConfirmSave(true)
    }

    return(
        <div className={style.div}>
            <div className={style.title}>
                <TitlePageResponsiveIcon 
                path={'Essa Venda Será Cancelada e o Estoque Restaurado' } 
                title={'CANCELAMENTO TOTAL DA VENDA'}
                children={
                    <GetButton responsive={true} title={"CANCELAR AGORA"} 
                    width={175}
                    height={55}
                    color='#FF0000' 
                    icon={faSave} handleFunction={handleSubmitSave}/> 
                }
                icon={faRectangleXmark}/>             
            </div>
            {sale.hasNfeEmited()? <GetSmallModels backgroundColor={'#FF0000'} 
            title={'NFe/NFC-e SERÁ CANCELADA AUTOMATICAMENTE'} icon={faWarning} subtitle={
                'Se a NFe foi emitida a mais de 7 dias, ela não Será cancelada (Regra do Sefaz)'
            }/>:null}
            <div className={style.divInRow}>
                <GetEmployee employee={employeeData()}/>   
            </div>
            <div className={style.divInRow}>
                <div className={style.divInputMax}>
                    <InputModelsBlock 
                    legend={'CONFIRMAÇÃO DO CANCELAMENTO'}
                    onChange={handleChange} maxLength={8} 
                    value={formData.cancelConfirmation} name={'cancelConfirmation'}
                    iconButton={faKey} type={"text"} 
                    validation={needData.cancelConfirmation}
                    placeholder={"Digite CANCELAR..."}/>
                    <Space top={5}/>
                    <InputModelsBlock 
                    legend={'MOTIVO DO CANCELAMENTO'}
                    onChange={handleChange} maxLength={1000} 
                    value={formData.descriptionCancel} name={'descriptionCancel'} 
                    validation={needData.descriptionCancel}
                    iconButton={faBookSkull} type={"descriptionCancel"} 
                    placeholder={"Descreva o Motivo do Cancelamento (Pelo Menos 15 Letras)"}/>
                </div>
            </div>
            {isVisible? <Alert  message={"Complete Todos os Campos em Vermelho"} 
            top={'2%'}
            right={'2%'}
            onClose={() =>  setIsVisible(false)} type={"error"} />:null}
            {showConfirmSave? <ConfirmationPopup 
            title={'CANCELAR TODA A VENDA'} 
            message={'Tem Certeza que Deseja Cancelar Essa Venda?'} 
            onCancel={()=>setShowConfirmSave(false)} 
            onConfirm={handleConfirm}/>: null} 
        </div>
    )
}

function NavigationBarOptions({sale,index,setIcon,setIndex,setLabelTitleMenu,setDescriptionTitle}){
    
    const handleChange = (index,icon,title,description)=>{
        setIcon(icon)
        setIndex(index)
        setLabelTitleMenu(title)
        setDescriptionTitle(description)
    }

    return(
        <div className={style.navibar}>
            <Space left={100}/>
                <div onClick={() =>handleChange(
                    0,
                    faInfo,
                    'DETALHES',
                    'São Iformações de Detalhes'
                )} className={index === 0?  style.selectionView + ' ' + style.selected : style.selectionView}>
                    <FontAwesomeIcon className={style.selectionViewIcon} 
                    icon={faInfo} />
                    <p>DETALHES</p>
                </div>
                {sale.detailsSale.isPresent ? null: 
                <div onClick={() => handleChange(
                    1,
                    faSackDollar,
                    'PAGAMENTOS',
                    'São os Detalhes de Pagamentos'
                )} className={index === 1?  style.selectionView + ' ' + style.selected : style.selectionView}>
                    <FontAwesomeIcon className={style.selectionViewIcon} 
                    icon={faSackDollar} />
                    <p>PAGAMENTOS</p>
                </div>}
                {sale.listGenericProduct.genericProducts.length === 0 ? null :
                <div onClick={() => handleChange(
                    2,
                    faProductHunt,
                    'PRODUTOS',
                    'É a Lista de Produtos'
                )} className={index === 2?  style.selectionView + ' ' + style.selected : style.selectionView}>
                    <FontAwesomeIcon className={style.selectionViewIcon} 
                    icon={faProductHunt} />
                    <p>PRODUTOS</p>
                </div>}
                {sale.itemsThisSale.listItems.length === 0 ? null :
                <div onClick={() => handleChange(
                    3,
                    faDatabase,
                    'SEMIJOIAS',
                    'É a Lista da Bruna Semijoias'
                )} className={index === 3?  style.selectionView + ' ' + style.selected : style.selectionView}>
                    <FontAwesomeIcon className={style.selectionViewIcon} 
                    icon={faDatabase} />
                    <p>SEMIJOIAS</p>
                </div>}
                <Space right={50}/>
        </div>
    )
}

function ViewFinalValue({sale,requestPdf,setShowGetPix,setValuePix,updateSaleController}){

    const [showViewNfeEmited,setShowViewNfeEmited] = useState(false)
    const [showNfe,setShowNfe] = useState(false)

    const handleSetPix = () =>{
        setShowGetPix(true)
        setValuePix(sale.controllerPayment.getValueNeedPay())
    }

    return(
        <div className={style.div}>
            <div className={style.divClient}>
                <ClientsModels onlyBasic={false} data={sale.thisOwner} />
            </div>
            <Space top={15}/>
            <div className={style.title}>
                <TitlePageResponsiveIcon subtitle={'VALORES FINAIS'} 
                children={
                    <div className={style.row}>
                        {
                            sale.hasNfeEmited() ? 
                            <GetButton responsive={true} title={'VER NOTA FISCAL EMITIDA'} color='#005FFF' 
                            width={215} height={65} icon={faTicket} handleFunction={()=>setShowViewNfeEmited(true)}/>
                            :<>
                                {
                                    sale.cancelSale.isCancel? null:
                                    <GetButton responsive={true} title={'EMITIR NOTA FISCAL'} color='#0a6300' 
                                    width={175} height={65} icon={faTicket} handleFunction={()=>setShowNfe(true)}/>
                                }
                            </>
                        }
                             <Space left={5}/>
                        <GetButtonIcon color='#FF0000' 
                            icon={faPix} handleFunction={handleSetPix}/>
                        <Space left={5}/>
                        <GetButtonIcon legend={'GERAR EXTRATO'} color='#0F66FD' 
                            icon={faFilePdf} handleFunction={requestPdf}/>
                    </div>
                }
                path={"É o Relatório Total de Todas as Operações"} 
                icon={faCheckDouble}/>            
              <GetEmployee employee={sale.tempEmployee}/>  
                
            </div>
            <div className={style.divInRow}>
                <GetSmallModels 
                title={'Valor Pago Pelo Cliente: '}
                backgroundColor={'#009f00'}
                subtitle={'É A Soma Total Final (Total + Extras - Descontos)'}
                value={sale.getTotalValueIncludeAllToString()}/>
                <Space left={5}/>
                <GetSmallModels 
                title={'Valor Bruto em Produtos: '}
                subtitle={databaseSettings.isCanUseBrunaBJ?'É A Soma Total das Peças (Bruna Semijoias + Genéricos)': 'É A Soma Total dos Produtos Genéricos'}
                value={sale.getValueInItems()}/>
            </div>
            <div className={style.divInRow}>
                <GetSmallModels 
                title={'Total de Produtos: '}
                subtitle={databaseSettings.isCanUseBrunaBJ? 'É A Soma Total do Produtos (Bruna Semijoias + Genéricos)': 'É A Soma Total dos Produtos'}
                value={sale.getTotalInItems()}/>
                <Space left={5}/>
                {databaseSettings.isCanUseBrunaBJ?
                    <GetSmallModels 
                    title={'Gramas Totais das Peças Bruna Semijoias: '}
                    subtitle={'É A Soma Total das Gramas das Peças da Bruna Semijoias'}
                    value={sale.getTotalGrams()}/>
                :null}
            </div>
            {showNfe? <GetSaleNFe sale={sale} onClose={()=>{
                setShowNfe(false)   
                updateSaleController()
                } 
            } />: null}
            {showViewNfeEmited? <ShowNfeEmited  
            onClose={()=>setShowViewNfeEmited(false) } idNfe={sale.nfe_IdSale} /> : null}
        </div>
    )
}

function ViewBJ({sale}){

    var labelTitle = sale.detailsSale.isPresent? 'Brinde': 'Vendidas'
    labelTitle = sale.cancelSale.isCancel ? 'Canceladas' : labelTitle

    return(
        <>{(sale.itemsThisSale.totalItems <= 0)? null :
            <div className={style.div}>
                <div className={style.title}>
                    <TitlePageResponsiveIcon subtitle={'DADOS DO ESTOQUE BRUNA SEMIJOIAS'} 
                    path={"É o Relatório de Peças " + labelTitle + " do Estoque da Bruna Semijoias"} 
                    icon={faMoneyCheckDollar}/>  
                </div>
                <div className={style.divInRow}>
                    <GetSmallModels 
                    title={'Total em Peças Bruna Semijoias: '}
                    subtitle={'É A Soma Total das Peças Bruna Semijoias'}
                    value={sale.itemsThisSale.getTotalMoneyToString()}/>
                    <Space left={5}/>
                    <GetSmallModels 
                    title={'Total de Peças Bruna Semijoias: '}
                    subtitle={'É o Total de Peças  ' + labelTitle + ' da Bruna Semijoias'}
                    value={sale.itemsThisSale.getTotalInItems()}/>
                </div>
                <div className={style.divInRow}>
                    <GetSmallModels 
                    title={'Total de Modelos: '}
                    subtitle={'É A Soma Total dos Modelos Bruna Semijoias'}
                    value={sale.itemsThisSale.getTotalInModels()}/>
                    <Space left={5}/>
                    <GetSmallModels 
                    title={'Gramas Totais das Peças Bruna Semijoias: '}
                    subtitle={'É A Soma Total das Gramas das Peças da Bruna Semijoias'}
                    value={sale.itemsThisSale.getTotalGrams()}/>
                </div>
            </div>
        }</>
    )
}

function ViewGenericProducts({sale}){

    var labelTitle = sale.detailsSale.isPresent? 'Brinde': 'Vendidos'
    labelTitle = sale.cancelSale.isCancel ? 'Cancelados' : labelTitle

    return(
        <>{(sale.listGenericProduct.totalCountGenericProducts <= 0)? null :
            <div className={style.div}>
                <div className={style.title}>
                    <TitlePageResponsiveIcon subtitle={'DADOS DO ESTOQUE DE PRODUTOS GENÉRICOS'} 
                    path={"É o Relatório de Produtos " + labelTitle + " do Estoque de Produtos"} 
                    icon={faProductHunt}/>  
                </div>
                <div className={style.divInRow}>
                    <GetSmallModels 
                    title={'Total Monetária em Produtos Genéricos: '}
                    subtitle={'É A Soma Total Monetária dos Produtos Genéricos'}
                    value={sale.listGenericProduct.getTotalMoneyToString()}/>
                    <Space left={5}/>
                    <GetSmallModels 
                    title={'Total de Peças em Produtos Genéricos: '}
                    subtitle={'É o Total dos Produtos  ' + labelTitle + ' de Produtos Genéricos'}
                    value={sale.listGenericProduct.getTotalInItems()}/>
                </div>
                <div className={style.divInRow}>
                    <GetSmallModels 
                    title={'Total de Modelos em Produtos Genéricos: '}
                    subtitle={'É A Soma Total dos Modelos de Produtos Genéricos'}
                    value={sale.listGenericProduct.getTotalInModels()}/>
                    <Space left={5}/> 
                </div>
            </div>
        }</>
      
    )
}

function ViewDiscount({sale}){
    return(
        <>{(!sale.saleDiscount.isHasDiscount)? null :
            <div className={style.div}>
                <div className={style.title}>
                    <TitlePageResponsiveIcon subtitle={'DADOS DO DESCONTO'} 
                    path={"Há Descontos Nessa Operação"} 
                    icon={faTags}/>  
                </div>
                <div className={style.divInRow}>
                    <GetSmallModels 
                    title={'Total desse Desconto: '}
                    subtitle={'É A Soma Total dos Descontos em Reais'}
                    value={sale.saleDiscount.getValueDiscountPercent()}/>
                    <Space left={5}/>
                    <GetSmallModels 
                    title={'Porcentagem Total do Desconto: '}
                    subtitle={'É a Porcentagem Total do Desconto'}
                    value={sale.saleDiscount.getDiscountPercent()}/>
                </div>
            </div>
        }</>
      
    )
}

function ViewExtraValues({sale}){
    return(
        <>{(!sale.saleDiscount.isHasDiscount)? null :
            <div className={style.div}>
                <div className={style.title}>
                    <TitlePageResponsiveIcon subtitle={'DADOS EM VALORES EXTRAS'} 
                    path={"Há Valor Extra Nessa Operação"} 
                    icon={faCommentsDollar}/>  
                </div>
                <div className={style.divInRow}>
                    <GetSmallModels 
                    title={'Valor Extra: '}
                    subtitle={'É o Valor Extra ou Vendas sem Registro'}
                    value={sale.getExtraValue()}/>
                </div>
            </div>
        }</>
      
    )
}

function ViewBasicDetails({sale}){
    return(
        <>{
            <div className={style.div}>
                <div className={style.title}>
                    <TitlePageResponsiveIcon subtitle={'DADOS BÁSICOS'} 
                    path={"Informações Básicas Sobre Essa Operação"} 
                    icon={faLandmark}/>  
                </div>
                <div className={style.divInRow}>
                    {databaseSettings.isCanUseBrunaBJ?<GetSmallModels 
                    title={'Fator de Multiplicação: '}
                    subtitle={'É o Fator de Venda das Peças da Bruna Semijoias'}
                    value={sale.detailsSale.factorCompany}/>:null}
                    <Space left={5}/>
                    <GetSmallModels 
                    title={'Data da Venda: '}
                    subtitle={'É a Data que a Venda Foi Realizada'}
                    value={sale.detailsSale.getDateToString()}/>
                </div>
                <div className={style.divInRow}>
                    <GetSmallModels 
                    title={'Data de Registro no Aplicativo: '}
                    subtitle={'É a Data de Registro da Venda no Aplicativo'}
                    value={sale.detailsSale.getDateRegisterToString()}/>
                    <Space left={5}/>
                    <GetSmallModels 
                    title={'Código da Venda: '}
                    subtitle={'É o Código da Venda no Servidor: '}
                    value={sale.idSaleCloud}/>
                </div>
            </div>
        }</>
      
    )
}

function ViewMotionCancel({sale}){
   
    return(
        <>{!sale.cancelSale.isCancel? null:
            <div className={style.div}>
                <div className={style.title}>
                    <TitlePageResponsiveIcon subtitle={'DADOS SOBRE O CANCELAMENTO'} 
                    path={"Informações Básicas Sobre o Cancelamento Dessa Venda..."} 
                    icon={faShopSlash}/>   
                </div>
                <div className={style.divInRow}>
                    <GetEmployee employee={sale.cancelSale.tempSallerCancel}/>
                    <Space left={5} top={5}/>
                    <GetSmallModels 
                    icon={faCalendar}
                    title={'Data do Cancelamento'}
                    subtitle={sale.getDateCancel()}/>
                </div>
                <div className={style.divInRow}>
                    <GetSmallModels 
                    title={'Motivo do Cancelamento'}
                    subtitle={sale.cancelSale.descriptionCancel}/>
                </div>
            </div>
        }</>
      
    )
}

function ViewPayment({setLoading,setValuePix,showError,handleSave,sale,formData,handleChange,canEdite,requestPdfPayment,setShowGetPix}){



    const [showConfirmSave,setShowConfirmSave] = useState(false)
    const [showPaymentAdd,setShowPaymentAdd] = useState(false)
    const [tempController] = useState(() => {
        var controller = new ControllerPayment()
        controller.fromOther(sale.controllerPayment)
        return controller
    })
    const [list,setList] = useState(tempController.paymentList)
    const [update,SetUpdate] = useState(false)
    
    const handleRemove = (payment) => {
        tempController.paymentList = tempController.paymentList.filter(ss => ss !== payment)
        setList(tempController.paymentList)
        tempController.calculate()
        updateScreen()
    }

    const updateScreen=() =>{
        SetUpdate(true)
        SetUpdate(false)
    }

    const handleAddNew = (payment) => {
        tempController.paymentList.push(payment)
        setList(tempController.paymentList)
        tempController.calculate()
        updateScreen()
    }

    const handleSavePayment = async() => {
        setLoading(true)
        var result = await updateSalePayments(sale,
            tempController,showError)
         
        if(result.status === 200){
            handleSave()
        }
        
        setLoading(false)
    }

    return(
        <div className={style.div}>
            <div className={style.divInRow}>
                <TitlePageResponsiveIcon 
                title={'LISTA DE PAGAMENTOS DESSA VENDA'}
                path={"É o Relatório de Pagamentos e Dividas"} 
                children={
                    <div className={style.row}>
                        <GetButton responsive={true} title={'GERAR COMPROVANTE'} handleFunction={requestPdfPayment} 
                        icon={faFilePdf}
                        color={"#0462E5"} />
                        <Space left={5}/>
                        {sale.cancelSale.isCancel || !canEdite?null:  
                        <GetButton responsive={true} title={'PAGAMENTOS'} 
                            handleFunction={() => setShowPaymentAdd(true)} 
                            icon={faPlus}
                            color={"#00830D"} />}
                    </div>
                }
                icon={faListUl}/> 
            </div>  

            <div className={style.divInRow}>
                <div className={style.divListPayment}>
                    {list.length === 0 ? 
                        <GetSmallModels title={"Não há Pagamentos"}
                        subtitle={"Cadastre um Novo Pagamento"}
                        icon={faCircleInfo} />
                    :null}
                    <GetAmountList amount={list.length} title={'Pagamentos Registrados...'}/>
                    {update?null:
                        list.map(
                            (payment) => {
                            return <ModelPayment 
                            setValuePix={setValuePix}
                            setShowGetPix={setShowGetPix}
                            canEdite={canEdite}
                            sale={sale}
                            handleRemove={()=>handleRemove(payment)}
                            payment={payment}/>}
                        )
                    }
                </div>
                <Space left={5}/>
                <div className={style.div}>
                    <TitlePageResponsiveIcon 
                    title={'DADOS DAS PARCELAS'}
                    path={"São os Valores Calculados!"} 
                    icon={faBalanceScale}/> 
                    <GetSmallModels 
                    title={'Total Pago: '}
                    subtitle={'É o Valor Monetário Pago'}
                    value={tempController.getTotalPaidToString()}/>
                    <GetSmallModels 
                    title={'Falta Pagar Para Quitar: '}
                    subtitle={'É o Valor Monetário que Falta Ser Pago'}
                    value={tempController.getTotalNeedingPaymentToString()}/>
                    <GetSmallModels 
                    title={'Status Dessa Venda: '}
                    backgroundColor={tempController.status.startsWith('DEVENDO')? '#FF0000':'#009F00'}
                    subtitle={'É a Situação Monetária da Venda'}
                    value={tempController.status}/>
                    {sale.cancelSale.isCancel || !canEdite?null:
                    <div className={style.div}>
                        <GetButton title={'SALVAR PAGAMENTOS'} 
                        handleFunction={()=>setShowConfirmSave(true)} 
                        icon={faSave}
                        height={55}
                        color={"#0462E5"} />
                    </div>}
                </div>
            </div>
            {showPaymentAdd?<AddPayment 
            handleAddNew={handleAddNew}
            formData={formData}
            handleChange={handleChange}
            handleClose={()=>setShowPaymentAdd(false)}/> : null}
            {showConfirmSave? <ConfirmationPopup 
            title={'SALVAR PAGAMENTOS'} 
            message={'Tem Certeza que Deseja Salvar os Pagamentos Dessa Venda?'} 
            onCancel={()=>setShowConfirmSave(false)} 
            onConfirm={handleSavePayment}/>: null} 
        </div>
    )
}

export function AddPayment({handleAddNew,handleClose,formData,handleChange}){
    const [needData] = useState({
        valuePayment:false,
    })

    const [isVisible, setIsVisible] = useState(false)

    const handleCloseView = () => {
        formData.valuePayment = ''
        formData.descriptionPayment = ''
        formData.dateCalender = new Date().toISOString().slice(0, 10)
        handleClose()
    }

    const handleAdd= () =>{
        var payment = new Payment()
        payment.fromNew(formData.valuePayment,formData.descriptionPayment,formData.dateCalender)
        handleAddNew(payment)
        handleCloseView()
    }

    const handleTryInsert = () =>{
        if(formData.valuePayment.length <= 0){
            needData.valuePayment = true
            setIsVisible(true)
            return
        }
       
        needData.valuePayment = false
        handleAdd()
    }
    
    return(
        <div className={style.overlay}>
            <div className={style.alertPayment}>
                <TitlePageResponsiveIcon 
                    subtitle={'CADASTRAR NOVO PAGAMENTO'} 
                    children={
                        <GetButtonIcon color='#E4080A' 
                        icon={faClose}
                        handleFunction={handleCloseView}/> 
                    }
                    path={'Complete os Campos Abaixo...'} 
                    icon={faMoneyCheckDollar}/>
                <div className={style.div}>
                    <InputModelsBlock
                    legend={'VALOR DA PARCELA'}
                    onChange={handleChange} maxLength={6} 
                    validation={needData.valuePayment}
                    value={formData.valuePayment} 
                    name={'valuePayment'} iconButton=
                    {faCreditCard} type={"number"} 
                    placeholder={"Valor Pago Nessa Parcela..."}/>
                    <Space top={5}/>
                    <div className={style.divInRow}>
                        <InputModelsBlock
                        legend={'DETALHAR ALGO SOBRE ESSA PARCELA'}
                        onChange={handleChange} 
                        maxLength={75} 
                        value={formData.descriptionPayment} 
                        name={'descriptionPayment'} 
                        iconButton={faKeyboard} type={"text"} 
                        placeholder={"Detalhar Algo..."}/>
                        <Space left={5} top={5}/>
                        <InputModelsBlock
                        legend={'DATA DESSA PARCELA'}
                        onChange={handleChange} 
                        maxLength={75} 
                        value={formData.dateCalender} 
                        name={'dateCalender'} 
                        iconButton={faCalendar} type={"date"} 
                        placeholder={"Data do Pagamento..."}/>
                    </div>
                    <div className={style.divInRow}>
                        <GetButton title={'ADICIONAR PAGAMENTO'} 
                            handleFunction={handleTryInsert}
                            icon={faPlus} color={'#0462E5'}
                        />
                    </div>
                </div>
            </div>
            {isVisible? <Alert  
            message={"Complete Todos os Campos em Vermelho"} 
            top={'2%'}
            right={'2%'}
            onClose={() =>  setIsVisible(false)} type={"error"} />:null}
        </div>
    )
}

function ModelPayment({sale,payment,handleRemove,canEdite,setShowGetPix,setValuePix}){
    
    const handleSetPix = () =>{
        setShowGetPix(true)
        setValuePix(payment.getValuePayment())
    }

    return(
        <div className={style.div}>  
            <GetEmployee employee={employeeData()}/>
            <Space top={5}/>
            <TitlePageResponsiveIcon
            children={
                <>
                    {sale.cancelSale.isCancel || !canEdite?null:
                   <div className={style.row}>
                        <GetButtonIcon title={'PIX'} color='#00830D' 
                          width={40} height={40}
                            icon={faPix} handleFunction={handleSetPix}/>
                        <Space left={5}/>
                        <GetButtonIcon icon={faRemove}
                        handleFunction={handleRemove} 
                        width={40} height={40}
                        color={'#ff0000'}/>
                   </div>
                   }
                </>
            }
            path={'Data do Pagamento: ' + payment.getDate()}
            subtitle={payment.description} 
            title={payment.getValueToString()}/>
        </div>
    )
}

export default ViewSaleController