import React, { useState, useEffect, useRef } from 'react';
import style from "../../style/ListClients.module.css"
import { GetSmallModels } from '../../Models/GetModels.js';
import { faMagnifyingGlass,faCircleInfo, faList, faLock} from '@fortawesome/free-solid-svg-icons';
import InputModels from "../../Models/InputModels.js"
import GetAmountList from '../../Models/GetAmountList.js';
import Space from '../../layout/Space.js';
import { TitlePageResponsiveIcon } from '../../layout/TitlePage.js';
import { useLoading } from '../../AppContext.js';
import { makeAccounting } from '../../objects/Accounting/MakeAccounting.js';
import RepairsModel from '../Clients/Repairs/RepairsModel.js';
import AddEditeRepairs from '../Clients/Repairs/AddEditeRepairs.js';
import GetNeedUpdate from './GetNeedUpdate.js';
import { employeeData } from '../../backEnd/InitController.js';

function AllRepairs() {

  const [showAddRepair,setShowAddRepair] = useState(false)

  const {setIsLoading}  = useLoading()
  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])

  const [CanView] = useState(employeeData().employeePermission.permissionsContabilite[2])
  const [filtered, setFiltered] = useState(makeAccounting.accountingAllRepairs.accountingRepairs)
  const [filter, setFilter] = useState('all')
  const [orderBy, setOrderBy] = useState('desc')

  const [RepairSelected, setRepairSelected] = useState(null)

  const [list] = useState(makeAccounting.accountingAllRepairs.accountingRepairs)
  const handleOpenViewRepair = (repair) =>{
    setRepairSelected(repair)
    setShowAddRepair(true)
  }

  useEffect(() => {
    handleChangeFilter()
  }, [filter, orderBy,list])

  const controllerFilter = () => {
    let data = []
    switch (filter) {
      case 'all':
        data = list
        break
      case 'inRepair':
        data = list.filter(item => item.status === 0)
        break
      case 'deliverid':
        data = list.filter(item => item.status === 1)
        break
      case 'cancel':
          data = list.filter(item => item.status === 2)
          break
      default:
        data = list
        break
    }

    let sorted = [...data]
    switch (orderBy) {
      case 'asc':
        sorted.sort((a, b) => a.title.localeCompare(b.title))
        break
      case 'desc':
        sorted.sort((a, b) => b.title.localeCompare(a.title))
        break
      case 'value':
        sorted.sort((a, b) => b.value.toString().localeCompare(a.value))
        break
      case 'status':
        sorted.sort((a, b) => b.status.toString().localeCompare(a.status))
        break
      default:
        break
    }
    return sorted;
  }

  const handleFilterChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }
  
  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()

    resetScroll()
    if(value!= null){
      const filtered = data.filter(
        repair => {
          const filterValue = repair.value.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterData= repair.date.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterTitle = repair.title.toLowerCase().includes(value.target.value.toLowerCase())
          return filterValue || filterData || filterTitle 
        }
      )
      setFiltered(filtered)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    if(container){
        container.scrollTop = 0
    }
    setLimit(50)
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  return (
    <div className={style.containerGeneral}> 
       <div className={style.containerGlobal}>  
        {CanView? <> {makeAccounting.isCalculated === true ? 
           <>
           <div className={style.titleMenu}>     
                  {window.innerWidth > 1200?<TitlePageResponsiveIcon subtitle={'Lista com Todos os Consertos No Período'}  
                  path={'São Todos os Consertos Entre a Data Inicial e Final da Contabilidade...'} icon={faList}/>:null} 
                  { window.innerWidth > 1200?
                    <>
                      <Space left={5}/>
                      <div className={style.buidWithText}>
                          <GetSmallModels title={'SÃO OS CONSERTOS DO DIA:'} subtitle={makeAccounting.getDateStart() + 'ATÉ: ' + makeAccounting.getDateFinish() + ' | HÁ RECEBER: ' + makeAccounting.accountingAllRepairs.getTotalSaleReceiverInFuture()} value={'RECEBIDO: ' + makeAccounting.accountingAllRepairs.getTotalReceived()}/>   
                      </div>
                    </>:null
                  }
              </div>
           <div className={style.buildList}>
             <div className={style.filter}>
                 <div className={style.inputFilter}>
                   <InputModels iconButton={faMagnifyingGlass} 
                   type={"text"} placeholder={"Buscar Conserto/Reparo..."}
                   onChange={handleChangeFilter}/>
                 </div>
                 <div className={style.containerDropDown}> 
                  <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                    <option value="all">TODOS</option>
                    <option value="inRepair">EM REPARO</option>
                    <option value="deliverid">REPARADO</option>
                    <option value="cancel">CANCELADOR</option>
                  </select>
                   <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                       <option value="asc">CRESCENTE</option>
                       <option value="desc">DESCRESCENTE</option>
                       <option value="value">VALOR</option>
                       <option value="status">STATUS</option>
                   </select>
                 </div>
             </div>
             <Space right={2} left={2} top={5} bottom={0}/> 
             {(filtered.length > 0 ) ?<div className={style.buildList}>
                <GetAmountList amount={filtered.length} title={"Cosertos/Reparos Totais Encontrados na Busca..."}/>
                <div className={style.labelData}>
                  <h5>Nome do Conserto/Reparo</h5>
                  <h5>Data</h5>
                  <h5>Status</h5>
                  <h5>Valor</h5>
                </div>
               <Space right={0} left={0} top={0} bottom={10}/> 
             </div> : null}
             <div ref={containerRef} className={window.innerWidth > 1200? style.containerList:style.containerList1} onScroll={checkScroll}>
                 <div>
                   {(filtered.length === 0 )?
                   <GetSmallModels title={"Não há Consertos/Reparos com Esse Filtro"}
                   subtitle={"Tente Trocar o Filtro..."}
                   icon={faCircleInfo} />:
                     filtered.slice(0, limit).map((repair, index) => (
                         <div key={index}>
                         <RepairsModel onClick={()=>handleOpenViewRepair(repair)} repair={repair} />
                         </div>
                     ))
                   }
                 </div>
             </div>
           </div>
           </> :
            <div className={style.divSapce}> 
              <GetNeedUpdate/>
            </div> }</>:
               <div className={style.divSapce}>
               <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar a Lista de Todas as Vendas'} />
               </div>}
        {showAddRepair? <AddEditeRepairs repairs={RepairSelected} onClose={()=>setShowAddRepair(false)}/> : null}
       </div>
    </div>
  )

}

export default AllRepairs