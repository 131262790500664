import React from "react"
import { Navigate, useLocation } from "react-router-dom";
import { NavBarGlobal, ObjectNavbarMenu } from "../NavbarGlobal";
import { errorRouter, routerFromLogin, routerFromSale } from "../../routers/routerList";
import style from "../../style/Controller.module.css"
import { faCartShopping, faShop } from "@fortawesome/free-solid-svg-icons";
import NewSaleScreen from "./NewSaleScreen";

function ControllerSale({isLoggedIn}){
    const location = useLocation()


    const menu1 = new ObjectNavbarMenu('Menu de Vendas',faShop)
    menu1.addMenu('Nova Venda',faCartShopping,routerFromSale)

    const listRoutesToNav = [menu1]

    return isLoggedIn ? (
        <div className={style.containerGlobal}>
        <NavBarGlobal listObjectNavBarMenu={listRoutesToNav}/>
        <div className={style.containerData}>
            {(() => {
                switch(location.pathname){
                    case routerFromSale: return <NewSaleScreen/>
                    default: return <Navigate to={errorRouter} replace />
                }
            })()}
        </div>
    </div>
    ) : (
        <Navigate to={routerFromLogin}replace />
    )
}

export default ControllerSale