import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import style  from "../style/GetFloatingButton.module.css";

function GetFloatingButton({color,margiRight,icon,handleFunction,margiBottom,height,width}) {
    const buttonStyle = {
        backgroundColor: color,
        right: margiRight,
        bottom: margiBottom,
        height:height,
        width:width
    }

    return(
        <button onClick={handleFunction} className={`${style.floatingButton} ${style.iconButton}`} style={buttonStyle}>
            <FontAwesomeIcon className={style.iconButPlus} icon={icon}/>
        </button>
    )
}

export function GetFloatingButtonWithLegend({legend,color,margiRight,icon,handleFunction,width,margiBottom,height}) {
    const buttonStyle = {
        backgroundColor: color,
        right: margiRight,
        bottom: margiBottom,
        height:height,
        width:width
    }

    return(
        <button onClick={handleFunction} className={`${style.floatingButtonWithLegend} ${style.iconButton}`} style={buttonStyle}>
            <h6>{legend}</h6>
            <FontAwesomeIcon className={style.iconButPlus} icon={icon}/>
        </button>
    )
}

export default GetFloatingButton