import React, { useEffect, useState } from "react"
import { TitlePageResponsiveIcon } from "../../layout/TitlePage"
import { faAddressCard, faAt, faAudioDescription, faBank, faBuildingFlag, faBuildingNgo, faCartShopping, faCashRegister, faCircleInfo, faDownload, faEnvelope
    , faFileShield, faGhost, faHashtag, faIcons, faLink, faLocationArrow, faLocationDot, faLock, faMapLocationDot,
    faPeopleArrows, faPhone, faPlus, faSave, faSignature, faSquareShareNodes, faTreeCity } from "@fortawesome/free-solid-svg-icons"
import style from "../../style/DataStore.module.css"
import GetFloatingButton, { GetFloatingButtonWithLegend } from "../../layout/GetFloatingButton"
import { GetSmallModels } from "../../Models/GetModels"
import InputModels from "../../Models/InputModels"
import StatesCountry from "../../layout/StatesCountry"
import InputPhoto from "../../Models/InputPhoto"
import Space from "../../layout/Space"
import { faFacebookF, faInstagram, faLinkedin, faPinterestP, faTiktok, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { GetButton } from "../../layout/ButtonsLayout"
import Loader from "../../layout/Loader"
import { setUpdateMyStore } from "../../controllers/CloudMyStore"
import EmailAndContact from "../../layout/EmailAndContact"
import MyStoreObject from "../../objects/MyStoreObject"
import BanksComponet from "../../layout/BanksComponet"
import GetNewBankPopop from "../../layout/GetNewBankPopop"
import bankObject from "../../objects/ClientsAndPerson/bankObject"
import ConfirmationPopup from "../../Models/ConfirmationPopup"
import Alert from "../../Models/Alert"
import QRCodeGenerator from "../../Models/QRCodeGenerator"
import { useError, useLoading } from "../../AppContext"
import { databaseSettings, initSystem, myStoreDataMemory, updateIfNull } from "../../backEnd/Memory"
import { employeeData } from "../../backEnd/InitController"
import { jwtDecode } from "jwt-decode"

const getFormData = (myStore) => {
    if (!myStore) {
        return {
            idStoreSettings: "",
            storeName: '',
            storeSortDescription: '',
            storeLargeDescription: '',
            profitFactor: 220,
            purchasingFactor: 106,
            iconLink: '',
            idAddress: '',
            state: '',
            city: '',
            address: '',
            cep: '',
            idGovernmentData: '',
            socialReason: '',
            fantasyName: '',
            cnpj: '',
            simplesNational: '',
            subscriptionState: '',
            idSocialNetwork: '',
            facebookLink: '',
            youtubeLink: '',
            instagramLink: '',
            tickTok: '',
            siteLink: '',
            twitterLink: '',
            linkedinLink: '',
            pinterestLink: '',
            number:'',
            email:'',
        };
    }

    return {
        idStoreSettings: myStore.idStoreSettings || "",
        storeName: myStore.storeName ||'',
        storeSortDescription: myStore.storeSortDescription|| '',
        storeLargeDescription: myStore.storeLargeDescription ||'',
        profitFactor: myStore.profitFactor || 220,
        purchasingFactor: myStore.purchasingFactor ||106,
        iconLink: myStore.iconLink || '',
        idAddress: myStore._myAddress.idAddress || '',
        state:myStore._myAddress.state || '',
        city: myStore._myAddress.city ||'',
        address: myStore._myAddress.address||'',
        cep: myStore._myAddress.cep ||'',
        idGovernmentData:myStore._myCompany.idGovernmentData  || '',
        socialReason: myStore._myCompany.socialReason || '',
        fantasyName:  myStore._myCompany.fantasyName ||'',
        cnpj:  myStore._myCompany.cnpj ||'',
        simplesNational: myStore._myCompany.simplesNational || '',
        subscriptionState:  myStore._myCompany.subscriptionState ||'',
        idSocialNetwork:myStore._socialMedia.idSocialNetwork ||'',
        facebookLink:  myStore._socialMedia.facebookLink ||'',
        youtubeLink: myStore._socialMedia.youtubeLink ||'',
        instagramLink: myStore._socialMedia.instagramLink ||'',
        tickTok: myStore._socialMedia.tickTok ||'',
        siteLink: myStore._socialMedia.siteLink ||'',
        twitterLink: myStore._socialMedia.twitterLink ||'',
        linkedinLink: myStore._socialMedia.linkedinLink ||'',
        pinterestLink: myStore._socialMedia.pinterestLink ||'',
        number:'',
        email:'',
    };
}


function DataStore(){

    const [dataBaseName] = useState(jwtDecode(localStorage.getItem('tokenHeader')).databasename)
    const [employeeUser] = useState(employeeData())
    const [canEdite] = useState(employeeUser.employeePermission.permissionsMyStore[0])
    const [canView] = useState(employeeUser.employeePermission.permissionsMyStore[1])

    const [showQRCodeDataBase,setshowQRCodeDataBase] = useState(false)
    const [showAlertSave,setShowAlertSave] = useState(false)
    const [result, setResult] = useState('')
    const [showConfirmSave,setShowConfirmSave] = useState(false)

    const [showNewBank,setShowNewBank] = useState(false)
    const [selectedImage,setSelectedImage] = useState([])
    const [selectedImageFromFile,setSelectedImageFromFile] = useState(null)
    const [phone,setPhone] = useState([])
    const [email,setEmail] = useState([])
    const [banks,setBanks] = useState([])
    
    const {showError} = useError()
    const { setIsLoading } = useLoading()

    const [loading,setLoading] = useState(false)
    const [formData, setFormData] = useState(getFormData(null))

    const [needData, setNeedData] = useState({
        number: false,
        email: false,
    })

    useEffect(() => {
        if(canView){
            async function fetchData() {
                setLoading(true)
                await updateIfNull(showError)  
                setFormData(getFormData(myStoreDataMemory))  
                setSelectedImage(myStoreDataMemory.iconLink)
                setPhone(myStoreDataMemory._phoneNumberList)
                setEmail(myStoreDataMemory._emailList)
                setBanks(myStoreDataMemory._listOfBanks)
                setLoading(false) 
            }
            fetchData()
        }
    }, [])

    const findDataStore = async() =>{
        setLoading(true)
        await initSystem(showError)
        setFormData(getFormData(myStoreDataMemory))
        setSelectedImage(myStoreDataMemory.iconLink)
        setBanks(myStoreDataMemory._listOfBanks)
        setEmail(myStoreDataMemory._emailList)
        setPhone(myStoreDataMemory._phoneNumberList)
        setSelectedImageFromFile(null)
        setLoading(false)
    }

    const handlerRemovePhone = async(phoneToRemove) =>{
        setPhone(data => data.filter(phone => phone !== phoneToRemove));
    }

    const handlerRemoveEmail = async(emailToRemove) =>{
        setEmail(data => data.filter(email => email !== emailToRemove));
    }

    const handlerRemoveBank = async(bankRemove) =>{
        setBanks(data => data.filter(bank => bank !== bankRemove));
    }

    const handlerAddPhone = async() =>{
        if(formData.number !== ""){
            await setPhone([...phone,formData.number])
            await setFormData({...formData, 'number': "" })
            await setNeedData({...needData, 'number': false})
        }else{
            await setNeedData({...needData, 'number': true})
        }
    }

    const handlerAddEmail = async() =>{
        if(formData.email !== ""){
            await setEmail([...email,formData.email])
            await setNeedData({...needData, 'email': false})
            await setFormData({...formData, 'email': ""})
        }else{
            await setNeedData({...needData, 'email': true})
        }
    }

    const handlerSlectedImageFromFile = (image) =>{
        setSelectedImageFromFile(image)
    }

    const handlerAddBank = async(data) =>{
        var bank = new bankObject()
        bank.fromCloud(data)
        setBanks([...banks,bank])
    }

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleConfirmSave = async() => {
        setShowConfirmSave(false)
        setLoading(true)
        setIsLoading(true)
        var newMyStore = new MyStoreObject()
        newMyStore.fromEdite(formData,banks,phone,email)
        var result = await setUpdateMyStore(selectedImageFromFile,newMyStore,showError)
        setResult(result.data)
        if(result.status === 200){
            await findDataStore()
            setShowAlertSave(true)
        }else{
            setLoading(false)
        }
        setIsLoading(false)
        
    }

    return(
        <div>
            <div className={style.containerGlobal}>
            <div className={style.title}>
                <TitlePageResponsiveIcon title={'Dados da Loja'} 
                    path={"Minha Loja  >  Menu da Loja  >  Dados da Loja"} 
                    children={canView && !loading?<GetButton responsive={true} title={'BUSCAR NO SERVIDOR'} 
                        color='#007bff' icon={faDownload} handleFunction={findDataStore}/>:null}/>
            </div>
            {canView? <>{ !loading?
            ( <>
                <div className={style.containerRow}>
                    <div className={style.containerIcone}>
                        <GetSmallModels title={"Logo Marca"} subtitle={"É o Icone da Sua Loja..."} icon={faIcons}/>
                        <div className={style.iconBrand}><InputPhoto disable={!canEdite} initialPhoto={selectedImage} handlerFileSelected={handlerSlectedImageFromFile}/></div>
                    </div>
                    <div className={style.containerBasic}>
                        <div className={style.containerBasicDatas}>
                            <InputModels disable={!canEdite} onChange={handleChange} value={formData.storeName} name={'storeName'} iconButton={faSignature} maxLength={100} type={"text"} placeholder={"Nome da Loja..."}/>
                            <InputModels disable={!canEdite} onChange={handleChange} value={formData.storeSortDescription} name={'storeSortDescription'} maxLength={200} iconButton={faAudioDescription} type={"text"} placeholder={"Descrição Curta da Loja..."}/>
                            <InputModels disable={!canEdite} onChange={handleChange} value={formData.storeLargeDescription} name={'storeLargeDescription'} maxLength={500} iconButton={faFileShield} type={"text"} placeholder={"Descrição Completa da Loja..."}/>
                        </div>
                        {databaseSettings.isCanUseBrunaBJ?
                        <>
                            <Space top={5} left={5}/> 
                            <div  className={style.containerBasicDatas}>
                                <InputModels disable={!canEdite} onChange={handleChange} value={formData.profitFactor} name={'profitFactor'} iconButton={faCashRegister} maxLength={10} type={"number"} placeholder={"Fator de Venda das Peças da Bruna Semijoias..."}/>
                                <InputModels disable={!canEdite} onChange={handleChange} value={formData.purchasingFactor} name={'purchasingFactor'} maxLength={10} iconButton={faCartShopping} type={"number"} placeholder={"Fator de Custo da Peças da Bruna Semijoias..."}/> 
                            </div>
                        </>:null
                        }
                    </div>
                </div>  
                <Space bottom={10} />
                <div className={style.container}>
                    <div className={style.containerDataDefault}>
                        <div className={style.containerData}>
                            <GetSmallModels title={"ENDEREÇO"} subtitle={"É o Endereço da Cliente..."} icon={faMapLocationDot}/>
                            <StatesCountry disable={!canEdite} initialState={formData.state.toUpperCase()} handlerFuncion={handleChange} name={'state'}/>
                            <Space bottom={10} />
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={45} value={formData.city} name={'city'} iconButton={faTreeCity} type={"text"} placeholder={"Cidade..."}/>
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={150} value={formData.address} name={'address'} iconButton={faLocationArrow} type={"text"} placeholder={"Endereço..."}/>  
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={15} value={formData.cep} name={'cep'} iconButton={faAddressCard} type={"text"} placeholder={"Cep..."}/>  
                        </div> 
                        <Space top={5} left={5}/> 
                        <div className={style.containerData}>
                            <GetSmallModels title={"DADOS GOVERNAMENTAIS"} subtitle={"Configure os Dados Governamentais..."} icon={faBuildingNgo}/>
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={100} value={formData.socialReason} name={'socialReason'} iconButton={faPeopleArrows} type={"text"} placeholder={"Razão Social..."}/>
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={150} value={formData.fantasyName} name={'fantasyName'} iconButton={faGhost} type={"text"} placeholder={"Nome Fantasia..."}/>  
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={20} value={formData.cnpj} name={'cnpj'} iconButton={faHashtag} type={"text"} placeholder={"CNPJ..."}/>  
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={25} value={formData.simplesNational} name={'simplesNational'} iconButton={faBuildingFlag} type={"text"} placeholder={"Simples Nascional"}/>    
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={45} value={formData.subscriptionState} name={'subscriptionState'} iconButton={faLocationDot} type={"text"} placeholder={"Inscrição Estadual..."}/>   
                        </div>    
                        <Space top={5} left={5}/> 
                        <div className={style.containerData}>
                            <GetSmallModels title={"MÍDIAS SOCIAIS"} subtitle={"Configure as Redes Sociais da Sua Loja..."} icon={faSquareShareNodes}/>
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={200} value={formData.facebookLink} name={'facebookLink'} iconButton={faFacebookF} type={"text"} placeholder={"@ do Facebook..."}/>
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={200} value={formData.youtubeLink} name={'youtubeLink'} iconButton={faYoutube} type={"text"} placeholder={"@ Youtube..."}/>  
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={200} value={formData.instagramLink} name={'instagramLink'} iconButton={faInstagram} type={"text"} placeholder={"@ Instagram..."}/>  
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={200} value={formData.tickTok} name={'tickTok'} iconButton={faTiktok} type={"text"} placeholder={"@ do TikTok..."}/>    
                        </div>  
                        <Space top={5} left={5}/> 
                        <div className={style.containerData}>
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={200} value={formData.siteLink} name={'siteLink'} iconButton={faLink} type={"text"} placeholder={"@ do Site..."}/>   
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={200} value={formData.twitterLink} name={'twitterLink'} iconButton={faTwitter} type={"text"} placeholder={"@ do Twitter..."}/>    
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={200} value={formData.linkedinLink} name={'linkedinLink'} iconButton={faLinkedin} type={"text"} placeholder={"@ do Linkedin..."}/>   
                            <InputModels disable={!canEdite} onChange={handleChange} maxLength={200} value={formData.pinterestLink} name={'pinterestLink'} iconButton={faPinterestP} type={"text"} placeholder={"@ do Pinterest..."}/>  
                        </div>          
                    </div>  
                </div>  
                <div className={style.container}>
                    <div className={style.containerDataDefault}>
                        <div className={style.containerData}>
                            <GetSmallModels title={"Telefones"} subtitle={"São os Telefones da Loja..."} icon={faMapLocationDot}/> 
                            {canEdite? <div className={style.listType}>
                                <div className={style.inputFildType}>
                                    <InputModels disable={!canEdite} validation={needData.number}
                                     onChange={handleChange} 
                                     value={formData.number} 
                                     name={'number'} 
                                     iconButton={faPhone} 
                                     type={"tel"} 
                                     placeholder={"Numero"}/>
                                </div>
                                <GetButton button title={'ADICIONAR'} 
                                    color='#007bff' icon={faPlus} handleFunction={handlerAddPhone}/>                           
                            </div>:null}
                            {(phone.length === 0)?
                                <GetSmallModels title={"Não há Contatos Cadastrados na Sua Loja..."}
                                subtitle={"Cadastre um Novo Contato!"}
                                icon={faCircleInfo} /> :
                                phone.map((data, index) => (
                                    <div key={index}>
                                        <EmailAndContact disable={!canEdite}
                                        functionButton={()=>handlerRemovePhone(data)} 
                                        title={data}/>
                                    </div>
                                ))
                            }
                        </div>  
                        <Space top={5} left={5}/> 
                        <div className={style.containerData}>
                            <GetSmallModels title={"E-mails"} subtitle={"São os Emails da Loja..."} icon={faEnvelope}/> 
                            {canEdite?<div className={style.listType}>
                                <div className={style.inputFildType}>
                                    <InputModels disable={!canEdite} validation={needData.email} 
                                    onChange={handleChange}  
                                    value={formData.email} 
                                    name={'email'} 
                                    iconButton={faAt} 
                                    type={"email"} 
                                    placeholder={"Email..."}/>
                                </div>
                                <GetButton button title={'ADICIONAR'} 
                                    color='#007bff' icon={faPlus} handleFunction={handlerAddEmail}/>                           
                            </div>:null}
                            {(email.length === 0)?
                                <GetSmallModels title={"Não há E-mails Cadastrados na Sua Loja..."}
                                subtitle={"Cadastre um Novo Email de Contato!"}
                                icon={faCircleInfo} />:
                                email.map((data, index) => (
                                    <div key={index}>
                                        <EmailAndContact disable={!canEdite}
                                        functionButton={()=>handlerRemoveEmail(data)} 
                                        title={data}/>
                                    </div>
                                ))
                            }
                        </div>  
                        <Space top={5} left={5}/> 
                        <div className={style.containerData}>
                            <GetSmallModels title={"Contas Bancarias"} subtitle={"São as Contas Bancarias da Sua Loja..."} icon={faBank}/> 
                            {canEdite? <div className={style.listType}>
                                <GetButton button title={'ADICIONAR MAIS BANCOS'} 
                                    color='#007bff' icon={faPlus} handleFunction={()=>setShowNewBank(true)}/>                           
                            </div>:null}
                            {(banks.length === 0)?
                                <>
                                    <Space top={5}/>
                                    <GetSmallModels title={"Não há Contas Bancarias Cadastradas na Sua Loja..."}
                                    subtitle={"Cadastre uma Nova Conta Bancaria"}
                                    icon={faCircleInfo} />
                                </>
                                :
                                banks.map((data, index) => (
                                    <div key={index}>
                                        <BanksComponet disable={!canEdite}
                                        functionButton={()=> handlerRemoveBank(data)} 
                                        bank={data}/>
                                    </div>
                                ))
                            }
                        </div>  
                        
                    </div>  
                </div>  
                {window.innerWidth <= 1200 ? null: <Space bottom={100} top={0}/>  }
            </>
            ):(<Loader titleFinding={'Buscando Dados da Loja no Servidor...'}
            subtitleFinding={'Aguarde...'} loading={loading} />)}
            </>: <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar os Dados da Loja'} />}
            </div>
            {canEdite && !loading?
            <>
            {window.innerWidth <= 1200 ?
                <>
                    {databaseSettings.isCanUseBrunaBJ?<GetFloatingButton icon={faLink} color='#ff0000' width={65} height={65} margiRight={'1%'} margiBottom={'1%'} handleFunction={()=>setshowQRCodeDataBase(true)} />:null}
                    <GetFloatingButton icon={faSave} color='#007bff'  width={65} height={65} margiRight={'1%'} margiBottom={databaseSettings.isCanUseBrunaBJ?75:'1%'} handleFunction={()=>setShowConfirmSave(true)} />     
                </>
            :
                <>
                    {databaseSettings.isCanUseBrunaBJ?<GetFloatingButtonWithLegend legend={'LICAR COM VENDEDOR'} icon={faLink} color='#ff0000' width={'13%'} margiRight={'1%'} margiBottom={'1%'} handleFunction={()=>setshowQRCodeDataBase(true)} />:null}
                    <GetFloatingButtonWithLegend legend={'SALVAR'} icon={faSave} color='#007bff' width={'8%'} margiRight={'1%'} margiBottom={databaseSettings.isCanUseBrunaBJ?'8.5%':"1%"} handleFunction={()=>setShowConfirmSave(true)} />     
                </>}
            </>:null}
            {showQRCodeDataBase? <QRCodeGenerator handleClose={()=>setshowQRCodeDataBase(false)} value={dataBaseName} />:null}
            {showAlertSave? <Alert top={100} message={result} onClose={() => setShowAlertSave(false)} type={"success"} />:null}
            {showConfirmSave? <ConfirmationPopup title={'SALVAR DADOS DA LOJA'} message={'Tem Certeza que Deseja Salvar Edição dos Dados da Loja'} onCancel={()=>setShowConfirmSave(false)} onConfirm={handleConfirmSave}/>: null} 
            {showNewBank? <GetNewBankPopop handlerAdd={handlerAddBank} onClose={()=>setShowNewBank(false)}/> : null} 
        </div>
    )
}

export default DataStore