class EmployeePermission{
    constructor(){
        this.idEmployeePermission = 0
        this.permissionsEmployers = [
          false,
          false,
          false,
        ]
        
        this.permissionsItems = [
          true,
          true,
          false,
          true,
        ]

        this.permissionsSales = [
          true,
          true,
          true,
          false,
          false
        ]

        this.permissionsMyStore= [
          false,
          true
        ]

        this.permissionsClients= [
          true,
          true,
          true,
        ]

        this.permissionsFinancial= [
          false,
          false,
        ]

        this.permissionsContabilite =[
          true,
          true,
          false,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          true,
        ]
    }

  fromEdite(idEmployeePermission,pItems,pContabilite,pClients,pEmployees,pMyStore,pSales,pFinancial){
    this.idEmployeePermission = idEmployeePermission


    var i= 0
    for(i= 0;i<pEmployees.length; i++){
        this.permissionsEmployers[i] = pEmployees[i]
    }

    for(i= 0;i<pItems.length;i++){
        this.permissionsItems[i] = pItems[i]
    }

    for(i= 0;i<pSales.length;i++){
        this.permissionsSales[i] = pSales[i]
    }

    for(i= 0; i<pMyStore.length;i++){
        this.permissionsMyStore[i] = pMyStore[i]
    }
   
    for(i= 0;i<pClients.length;i++){
        this.permissionsClients[i] = pClients[i]
    }
  
    for(i= 0;i<pContabilite.length;i++){
        this.permissionsContabilite[i] = pContabilite[i]
    }

    for(i= 0;i<pFinancial.length;i++){
      this.permissionsFinancial[i] = pFinancial[i]
  }
  }

  fromMaster(){
    var i= 0
    for(i= 0;i<this.permissionsEmployers.length; i++){
        this.permissionsEmployers[i] = true;
    }

    for(i= 0;i<this.permissionsFinancial.length; i++){
      this.permissionsFinancial[i] = true;
    }

    for(i= 0;i<this.permissionsItems.length;i++){
        this.permissionsItems[i] = true;
    }

    for(i= 0;i<this.permissionsSales.length;i++){
        this.permissionsSales[i] = true;
    }

    for(i= 0; i<this.permissionsMyStore.length;i++){
        this.permissionsMyStore[i] = true;
    }
   
    for(i= 0;i<this.permissionsClients.length;i++){
        this.permissionsClients[i] = true;
    }
  
    for(i= 0;i<this.permissionsContabilite.length;i++){
        this.permissionsContabilite[i] = true;
    }
  }

  fromCloud(data) {
    this.idEmployeePermission = data['idEmployeePermission']
    this.permissionsEmployers[0] = data['canInsertEmployee']===0? false: true
    this.permissionsEmployers[1] = data['canEditeEmployee'] ===0? false: true
    this.permissionsEmployers[2] = data['canViewEmployee']===0? false: true

    this.permissionsItems[0] = data['canEditeStock']===0? false: true
    this.permissionsItems[1] = data['canViewDataItem']===0? false: true
    this.permissionsItems[2] = data['canViewCoastValueItem']===0? false: true
    this.permissionsItems[3] = data['canViewTempItem']===0? false: true

    this.permissionsSales[0] = data['canMakeSale']===0? false: true
    this.permissionsSales[1] = data['canCancelSale']===0? false: true
    this.permissionsSales[2] = data['canEditeSale']===0? false: true
    this.permissionsSales[3] = data['canEditeAllSale']===0? false: true
    this.permissionsSales[4] = data['canViewAllSale']===0? false: true

    this.permissionsMyStore[0] = data['canEditeStore']===0? false: true
    this.permissionsMyStore[1] = data['canViewStore']===0? false: true

    this.permissionsClients[0] = data['canEditeClients']===0? false: true
    this.permissionsClients[1] = data['canViewClients']===0? false: true
    this.permissionsClients[2] = data['canInsertClients']===0? false: true

    this.permissionsContabilite[0]= data['canViewDetailsStock']===0? false: true
    this.permissionsContabilite[1]= data['canViewFinalMoth']===0? false: true
    this.permissionsContabilite[2]= data['canViewLiquidMoth']===0? false: true
    this.permissionsContabilite[3]= data['canViewGrossMoth']===0? false: true
    this.permissionsContabilite[4]= data['canViewTotalCancelMoth']===0? false: true
    this.permissionsContabilite[5]= data['canViewListItemsSale']===0? false: true
    this.permissionsContabilite[6]= data['canViewAllRepositions']===0? false: true
    this.permissionsContabilite[7]= data['canViewAllReceivers']===0? false: true
    this.permissionsContabilite[8]= data['canViewListSaleFactors']===0? false: true
    this.permissionsContabilite[9]= data['canViewListComission']===0? false: true
    this.permissionsContabilite[10]= data['canViewListFutureComission']===0? false: true
    this.permissionsContabilite[11]= data['canViewAllFinalMoth']===0? false: true
    this.permissionsContabilite[12]= data['canViewContability']===0? false: true
    this.permissionsContabilite[13]= data['canViewExtracts']===0? false: true

    this.permissionsFinancial[0] = data['canViewFinancial']===0? false: true
    this.permissionsFinancial[1] = data['canViewEditeFinancial']===0? false: true
  }

  getMapFromCloud(){
    return{
      'canEditeEmployee' : this.permissionsEmployers[0].toString(),
      'canInsertEmployee':this.permissionsEmployers[1].toString(),
      'canViewEmployee':this.permissionsEmployers[2].toString(),
      'canEditeStock':this.permissionsItems[0].toString(),
      'canViewDataItem':this.permissionsItems[1].toString(),
      'canViewCoastValueItem':this.permissionsItems[2].toString(),
      'canViewTempItem':this.permissionsItems[3].toString(),
      'canMakeSale':this.permissionsSales[0].toString(),
      'canCancelSale':this.permissionsSales[1].toString(),
      'canEditeSale':this.permissionsSales[2].toString(),
      'canEditeAllSale':this.permissionsSales[3].toString(),
      'canViewAllSale':this.permissionsSales[4].toString(),
      'canEditeStore':this.permissionsMyStore[0].toString(),
      'canViewStore':this.permissionsMyStore[1].toString(),
      'canEditeClients':this.permissionsClients[0].toString(),
      'canViewClients':this.permissionsClients[1].toString(),
      'canInsertClients':this.permissionsClients[2].toString(),
      'canViewDetailsStock':this.permissionsContabilite[0].toString(),
      'canViewFinalMoth':this.permissionsContabilite[1].toString(),
      'canViewLiquidMoth':this.permissionsContabilite[2].toString(),
      'canViewGrossMoth':this.permissionsContabilite[3].toString(),
      'canViewTotalCancelMoth':this.permissionsContabilite[4].toString(),
      'canViewListItemsSale':this.permissionsContabilite[5].toString(),
      'canViewAllRepositions':this.permissionsContabilite[6].toString(),
      'canViewAllReceivers':this.permissionsContabilite[7].toString(),
      'canViewListSaleFactors':this.permissionsContabilite[8].toString(),
      'canViewListComission':this.permissionsContabilite[9].toString(),
      'canViewListFutureComission':this.permissionsContabilite[10].toString(),
      'canViewAllFinalMoth':this.permissionsContabilite[11].toString(),
      'canViewContability':this.permissionsContabilite[12].toString(),
      'canViewExtracts':this.permissionsContabilite[13].toString(),

      'canViewFinancial':this.permissionsFinancial[0].toString(),
      'canViewEditeFinancial':this.permissionsFinancial[1].toString(),

      'idEmployeePermission':this.idEmployeePermission
    }
  }
}
export default EmployeePermission