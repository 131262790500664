import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import InputModelsBlock from "../../layout/InputModelsBlock";
import style from '../../style/GetNFeConfigureCompany.module.css'
import { faFileInvoiceDollar, faUpload, faCoins, faCommentsDollar, faShield, faCartFlatbedSuitcase, faCreditCard, faCaretDown, faSortUp, faAngleUp, faAngleDown, faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import Space from "../../layout/Space";
import Alert from "../../Models/Alert";
import { TitlePageResponsiveIcon } from "../../layout/TitlePage";
import { GetButtonIcon } from "../../layout/ButtonsLayout";
import { GetSmallModels } from "../../Models/GetModels";

const getFormData = (ICMSTot) => {
    return {
        vFrete:ICMSTot.vFrete,
        vSeg :ICMSTot.vSeg,
        vDesc :ICMSTot.vDesc,
        vII:ICMSTot.vII,
        vIPI:ICMSTot.vIPI,
        vIPIDevol:ICMSTot.vIPIDevol,
        vOutro :ICMSTot.vOutro,
    }
}


export const GetTotalTax = forwardRef(({initialExpand,nfe}, ref) =>  {

    const [updateScreen,SetUpdateScreen] = useState(true)
    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData(nfe.ICMSTot))
    const [expanded, setExpand] = useState(initialExpand?? true)

    useImperativeHandle(ref, () => ({
        handleSubmit
    }))

    const handleChange = (e) => {
        if(e !== ''){
            SetUpdateScreen(false)
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

  
    nfe.calculateTotal()
    useEffect(() => {
        const interval = setInterval(() => {
            SetUpdateScreen(false)
            nfe.calculateTotal()
            clearInterval(interval1)
        }, 10)

        const interval1 = setInterval(() => {
            SetUpdateScreen(true)
        }, 10)
  
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        nfe.calculateTotal()
        nfe.ICMSTot.fromEditeBasics(formData)
        nfe.calculateTotal()
        SetUpdateScreen(true)
    }, [formData])

    const handleSubmit= () => {
        let allFieldsEmpty = false

        if (allFieldsEmpty) {
            setAlert(true)
            return
        }

    }

    return (
        <div className={style.div}>
            <TitlePageResponsiveIcon 
                children={
                    <GetButtonIcon color='#094f00' height={40} width={40} icon={expanded? faAngleUp :  faAngleDown} handleFunction={()=>setExpand(!expanded)}/> 
                }
                subtitle={"IMPOSTOS TOTAIS"} path={"Agrupa todos os Impostos da Nota Fiscal Eletrônica (NF-e)"} icon={faFileInvoiceDollar}/> 
             <div className={expanded? style.visible : style.notVisible} data={updateScreen}>
                <GetSmallModels 
                icon={faCircleQuestion}
                title={'SE NÃO HÁ VALORES EXTRAS'} backgroundColor={"#005f00"} subtitle={'Deixe em Braco os Campos Abaixo...'}/>
                <div className={style.div}>
                    <div className={style.divInRow}>
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'VALOR TOTAL DOS PRODUTOS'} data={updateScreen} disable={true} onChange={handleChange} maxLength={10} value={nfe.ICMSTot.vProd} iconButton={faCreditCard} type={"number"} placeholder={"Valor total dos produtos..."}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'VALOR DO FRETE'} data={updateScreen}  onChange={handleChange}  maxLength={10} value={formData.vFrete} name={'vFrete'} iconButton={faCartFlatbedSuitcase} type={"number"} placeholder={"Valor total do frete..."}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'VALOR DO SEGURO'} data={updateScreen}  onChange={handleChange} maxLength={10} value={formData.vSeg} name={'vSeg'} iconButton={faShield} type={"number"} placeholder={"Valor total do seguro..."}/>  
                    </div>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'VALOR DO DESCONTO'} onChange={handleChange}  maxLength={10} value={formData.vDesc} name={'vDesc'} iconButton={faCommentsDollar} type={"number"} placeholder={"Valor total do desconto..."}/>  
                        <Space left={5} top={5}/>
                        <InputModelsBlock legend={'VALOR DO IMPOSTO DE IMPORTAÇÃO'} onChange={handleChange}  maxLength={10} value={formData.vII} name={'vII'} iconButton={faCoins} type={"number"} placeholder={"Valor total do Imposto de Importação..."}/>  
                        {/*<Space left={5} top={5}/>
                        <InputModelsBlock legend={'VALOR DO IPI'} onChange={handleChange}  maxLength={10} value={formData.vIPI} name={'vIPI'} iconButton={faMoneyBillWheat} type={"number"} placeholder={"Valor total do IPI..."}/>  */}
                    </div>
                    <Space left={5} top={5}/>
                    <div className={style.divInRow}>
                        {/*<Space left={5} top={5}/>
                        <InputModelsBlock legend={'VALOR DO IPI DEVOLVIDO'} onChange={handleChange}  maxLength={10} value={formData.vIPIDevol} name={'vIPIDevol'} iconButton={faTag} type={"number"} placeholder={"Valor do IPI devolvido..."}/>  
                        <Space left={5} top={5}/>*/}
                        <InputModelsBlock legend={'OUTRAS VALORES'} onChange={handleChange}  maxLength={10} value={formData.vOutro} name={'vOutro'} iconButton={faUpload} type={"number"} placeholder={"Valor total de outras despesas acessórias..."}/>  
                    </div>
                    <Space left={2} top={2}/> 
                    <GetSmallModels title={'VALOR DA BASE DE CÁLCULO DO ICMS'} data={updateScreen}  subtitle={'Somando as bases de cálculo do ICMS para todos os itens da nota.'} value={nfe.ICMSTot.vBC}/>
                    <Space left={2} top={2}/> 
                    <GetSmallModels title={'VALOR TOTAL DO ICMS'} data={updateScreen}  subtitle={'Somando os valores do ICMS para todos os itens da nota.'} value={nfe.ICMSTot.vICMS.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}/>
                    <Space left={2} top={2}/> 
                    <GetSmallModels title={'VALOR DO ICMS DESONERADO'} data={updateScreen}  subtitle={'Conforme benefícios fiscais aplicáveis.'} value={nfe.ICMSTot.vICMSDeson.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}/>
                    <Space left={2} top={2}/> 
                    <GetSmallModels title={'VALOR DO PIS'} data={updateScreen}  subtitle={'Somando os valores do PIS para todos os itens da nota.'} value={nfe.ICMSTot.vPIS.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}/>
                    <Space left={2} top={2}/> 
                    <GetSmallModels title={'VALOR DO CONFINS'} data={updateScreen}  subtitle={'somando os valores do CONFIS para todos os itens da nota.'} value={nfe.ICMSTot.vCOFINS.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}/>
                    <Space left={2} top={2}/> 
                    <GetSmallModels backgroundColor={"#ff0000"} title={'VALOR TOTAL DA NOTA'} data={updateScreen}  subtitle={'Somando todos os valores da nota, incluindo produtos, impostos, frete, seguro, e outras despesas, subtraídos os descontos.'} value={nfe.ICMSTot.vNF.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}/>
                </div>
            </div>
            {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
        </div>
    )
})