import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { errorRouter, routerFromLogin, routerFromFinancialGlobal, routerFromFinancialNotaFiscal } from "../../routers/routerList.js";
import style from "../../style/Controller.module.css"
import { NavBarGlobal, ObjectNavbarMenu } from "../NavbarGlobal.js";
import {faFilterCircleDollar, faMoneyBillTrendUp, faReceipt, faSquareNfi } from "@fortawesome/free-solid-svg-icons";
import { Financial } from "./Financial.js";
import ListNfe from "../Nfe/ListNfe.js";

function FinancialController({isLoggedIn}){
    const location = useLocation();

    const menu1 = new ObjectNavbarMenu('Financeiro',faFilterCircleDollar)
    menu1.addMenu('Controle Financeiro',faMoneyBillTrendUp,routerFromFinancialGlobal)

    const menu2 = new ObjectNavbarMenu('NFE/DANFE',faSquareNfi)
    menu2.addMenu('Nota Fiscal NF-e',faReceipt,routerFromFinancialNotaFiscal)

    const listRoutesToNav = [menu1,menu2]
    //const listRoutesToNav = [menu1]
   
    return isLoggedIn ? (
        <div className={style.containerGlobal}>
        <NavBarGlobal listObjectNavBarMenu={listRoutesToNav}/>
        <div className={style.containerData}>
            {(() => {
                switch(location.pathname){
                    case routerFromFinancialGlobal: return <Financial/>
                    case routerFromFinancialNotaFiscal: return <ListNfe/>
                    default: return <Navigate to={errorRouter} replace />
                }
            })()}
        </div>
    </div>
    ) : (
        <Navigate to={routerFromLogin} replace />
    )
}

export default FinancialController