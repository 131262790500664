import { Employee } from "../ClientsAndPerson/employee"
import { getPercentage } from "../../backEnd/ControllerGloba"
import { getRandomNumber } from "../../backEnd/ControllerImages"
import { employeeData } from "../../backEnd/InitController"
import { myStoreDataMemory } from "../../backEnd/Memory"
import { requestPDFs } from "../../controllers/CloudPdfs"
import ClientsObject from "../ClientsAndPerson/clientsObject"
import CancelSale from "./CancelSale"
import ControllerPayment from "./ControllerPayment"
import DetailsSale from "./DetailsSale"
import { LastVersionSale } from "./LastVersionSale"
import ListOfItems from "./ListOfItems"
import ListProducts from "./ListProducts"
import SaleDiscount from "./SaleDiscount"
import { NfeEmited } from "../NFe/NfeEmited"


class Sales{
    constructor(){
      this.idSaleCloud = 0
      this.idController = 0
    
      this.totalAmountItems = 0         //Total de Peças Global
      this.totalFactorItems= 0          //Fator Total Global
      this.totalValueIncludeAll = 0     //Valor Total Global
      this.totalValue = 0
      this.valueFixed = false
    
      this.isHasExtraValue = false
      this.extraValue = 0.0
    
      this.thisOwner = new ClientsObject()
    
      this.controllerPayment = new ControllerPayment()
      this.cancelSale = new CancelSale()
      this.detailsSale = new DetailsSale()
      this.saleDiscount = new SaleDiscount()
      this.itemsThisSale = new ListOfItems()
      this.listGenericProduct = new ListProducts()
      this.lastVersionSale = new LastVersionSale()

      this.nfe_IdSale = -1
      this.saleDiscount.calculateDiscount(this.getValueWithAll())
      this.tempEmployee = new Employee()
    }

    fromEditeSale(auxSale,saleEdite){
      this.tempEmployee = employeeData()

      this.totalAmountItems = auxSale.calculateAllFromSale.itemsAll

      this.totalValueIncludeAll = auxSale.calculateMoneyFromSale.moneyTotalAll
      this.totalValue = auxSale.calculateMoneyFromSale.moneyWithExtra
      this.valueFixed = (auxSale.fixed !== 0)

      this.isHasExtraValue = (auxSale.calculateMoneyFromSale.moneyExtra !== 0)
      this.extraValue = auxSale.calculateMoneyFromSale.moneyExtra

      this.detailsSale.fromNewSale(auxSale)
      this.saleDiscount.fromNewSale(auxSale)
      
      this.itemsThisSale.fromNewSale(auxSale,auxSale.client._genericPerson.name)
      this.listGenericProduct.fromNewSale(auxSale,auxSale.client._genericPerson.name)
      this.saleDiscount.calculateDiscount(this.getValueWithAll())

      this.totalFactorItems = this.itemsThisSale.totalGrams 

      const valueNeedPay = (this.totalValueIncludeAll - saleEdite.controllerPayment.totalPayment)

      if(valueNeedPay <=0){
        this.controllerPayment.statusSale = "QUITADO"
        this.detailsSale.isOpen = false
      }else{
        this.controllerPayment.statusSale = "DEVENDO"
        this.detailsSale.isOpen = true
      }
      this.controllerPayment.totalNeedingPayment = valueNeedPay
      
    }

    async openPDFExtract(showError){
      const body = {
        'pdfName' : 'Venda-' + this.getCodeSale(),
        'type':'pdfSale',
        'seller': this.tempEmployee.genericPerson.name,
        'client': this.thisOwner._genericPerson.name,
        'sale': this.getMapFromCloud(),
        'myStore': myStoreDataMemory.getMapFromCloud()
      }
      await requestPDFs(body,showError)
    }

    async openPDFPayments(showError){
      const body = {
        'pdfName' : 'Venda-' + this.getCodeSale(),
        'type':'pdfPayment',
        'employee': employeeData().genericPerson.name,
        'seller': this.tempEmployee.genericPerson.name,
        'client': this.thisOwner._genericPerson.name,
        'sale': this.getMapFromCloud(),
        'myStore': myStoreDataMemory.getMapFromCloud()
      }
      await requestPDFs(body,showError)
    }

    fromNewSale(auxSale){
      this.idSaleCloud = 0
      this.idController = getRandomNumber()
      this.tempEmployee = employeeData()
      this.thisOwner = auxSale.client

      this.totalAmountItems = auxSale.calculateAllFromSale.itemsAll

      this.totalValueIncludeAll = auxSale.calculateMoneyFromSale.moneyTotalAll
      this.totalValue = auxSale.calculateMoneyFromSale.moneyWithExtra
      this.valueFixed = (auxSale.fixed !== 0)

      this.isHasExtraValue = (auxSale.calculateMoneyFromSale.moneyExtra !== 0)
      this.extraValue = auxSale.calculateMoneyFromSale.moneyExtra

      this.detailsSale.fromNewSale(auxSale)
      this.saleDiscount.fromNewSale(auxSale)
      
      this.itemsThisSale.fromNewSale(auxSale,auxSale.client._genericPerson.name)
      this.listGenericProduct.fromNewSale(auxSale,auxSale.client._genericPerson.name)
      this.saleDiscount.calculateDiscount(this.getValueWithAll())

      this.totalFactorItems = this.itemsThisSale.totalGrams 


      if(auxSale.payForm !== 'PARCELADO')
        this.controllerPayment.fromInCash(this.totalValueIncludeAll)
      else
        this.controllerPayment = auxSale.controllerPayment

      this.detailsSale.isOpen = this.controllerPayment.isOpen()

      if(auxSale.typeSale === 'BRINDE')
        this.setIsPresent()      
    }
  
    copyFromOld(old){
      this.idSaleCloud = old.idSaleCloud
      this.idController = old.idController
      this.thisOwner = old.thisOwner
      this.nfe_IdSale = old.nfe_IdSale
      var controllerTemp = new ControllerPayment()
      this.controllerPayment = controllerTemp.fromOther(old.controllerPayment)
    }
  
    setCancel(descriptionCancel){
      this.cancelSale.isCancel = true
      this.cancelSale.idSelleCancel = employeeData().idEmployee
      this.cancelSale.descriptionCancel = descriptionCancel
      this.detailsSale.isOpen = false
      this.cancelSale.dateCancel = new Date()
      
      var codeSale = this.getCodeSale()
      this.itemsThisSale.addLastVersionCancel(codeSale)
      this.listGenericProduct.addLastVersionCancel(codeSale)
    }

    getDateCancel(){
      return this.cancelSale.dateCancel.replace('T',' ').slice(0,19)
    }
  
    getCodeSale() {
      return this.thisOwner._genericPerson.name + ' - VENDA ID:' + this.idSaleCloud
    }
  
    fromCloud(data, owner,listGenericFromDatabase,listItemFromCloud,employee,employeeCancel){
   
      if(employee === null)
        this.tempEmployee.genericPerson.name ="Funcionário Excluido do Sistema"
      
      if(owner === null)
        this.thisOwner._genericPerson.name ="Cliente Excluida do Sistema"
    
      this.nfe_IdSale = data.nfe_IdSale
      this.tempEmployee = employee || this.tempEmployee
      this.thisOwner = owner
      this.valueFixed = data.valueFixed === 1? true: false
      this.idSaleCloud = data.idSale
      this.totalValue = parseFloat(data.totalValue)
      this.totalValueIncludeAll = parseFloat(data.totalValueIncludeAll)
      this.totalFactorItems = parseFloat(data.totalFactorItems)
      this.totalAmountItems = data.totalAmountItems
      this.idController = data.idController
      this.isHasExtraValue =  data.isExtraValue === 1? true: false
      this.extraValue = parseFloat(data.extraValue)
   
      /*var lastVersionSaleTemp = new LastVersionSale()
      this.lastVersionSale = lastVersionSaleTemp.fromCloud(data,owner)*/

      this.itemsThisSale.fromCloud(data,listItemFromCloud)
      this.listGenericProduct.fromCloud(data,listGenericFromDatabase)
      this.controllerPayment.fromCloud(data)
      this.cancelSale.fromCloud(data,employeeCancel)
      this.detailsSale.fromCloud(data)
      this.saleDiscount.fromCloud(data)
      this.saleDiscount.calculateDiscount(this.getValueWithAll())
      
    }
  
    hasNfeEmited(){
      return this.nfe_IdSale !== -1 && this.nfe_IdSale !== undefined && this.nfe_IdSale !== null
    }

    getMapFromCloud(){
  
      /*var codeSale = this.getCodeSale()
      this.itemsThisSale.addLastVersionNewSale(codeSale)
      this.listGenericProduct.addLastVersionNewSale(codeSale)*/
  
      return{
        'idController':this.idController,
        'idClient':this.thisOwner.IdClient,
        'idSaleCloud':this.idSaleCloud,
        'totalAmountItems':this.totalAmountItems,
        'totalFactorItems':this.totalFactorItems,
        'totalValueIncludeAll':this.totalValueIncludeAll,
        'totalValue':this.totalValue,
        'valueFixed':this.valueFixed,
        'isHasExtraValue':this.isHasExtraValue,
        'extraValue':this.extraValue,
        'container-cancelSale': this.cancelSale.getMapFromCloud(),
        'container-controllerPayment': this.controllerPayment.getMapFromCloud(),
        'container-items': this.itemsThisSale.getMapFromCloud(),
        'container-generics': this.listGenericProduct.getMapFromCloud(),
        'container-details': this.detailsSale.getMapFromCloud(),
        'container-saleDiscount': this.saleDiscount.getMapFromCloud(),
        'container-lastVersion': this.lastVersionSale.getMapFromCloud()
      }
    }
    
    fromSales(Owner){
      this.thisOwner = Owner
      this.extraValue = 0
      this.totalAmountItems = 0
      this.totalFactorItems = 0
      this.totalValueIncludeAll = 0
      this.totalValue = 0
  
      this.valueFixed = false
      this.isHasExtraValue = false
  
      this.listGenericProduct.clear()
      this.lastVersionSale.clear()
      this.saleDiscount.clear()
      this.detailsSale.clear()
      this.cancelSale.clear()
      this.itemsThisSale.clear()

      this.controllerPayment = new ControllerPayment()
      this.saleDiscount.calculateDiscount(this.getValueWithAll())
    }
  
    setIsPresent(){
      this.isHasExtraValue = false
      this.saleDiscount.clear()
      this.extraValue = 0
    }
  
    setValuesToSale(payForm, details){
      this.detailsSale.payForm = payForm
      this.detailsSale.salleId = employeeData().idEmployee
      this.detailsSale.details = details

      this.itemsThisSale.listItems.sort((a, b) => a.itemGlobal.name.localeCompare(b.itemGlobal.name))
      this.listGenericProduct.genericProducts.sort((a, b) => a.nameProduct.localeCompare(b.nameProduct))
    }
  
    insertItems(newItem) {
      this.itemsThisSale.listItems.push(newItem)
    }

    insertItemGenericProducts(product) {
      this.listGenericProduct.genericProducts.push(product)
    }
  
    setConfigNewSale(newFactor, valueFinal, dateSale){
      this.detailsSale.dateSaleRegister = new Date().toISOString().replace('T', ' ').slice(0, 10)
      this.detailsSale.dateSale = dateSale
      this.alterValueFactorCompany(newFactor,valueFinal)
  
      if(this.idController === 0){
        this.idController = getRandomNumber()
      }
    }
  
    getValueInItems(){
      return  this.totalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getExtraValue(){
      return  this.extraValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
    getTotalGrams(){
      return parseFloat(this.totalFactorItems).toFixed(2) + " g"  
    }

    getTotalInItems(){
      return this.totalAmountItems + " Un" 
    }

    alterValueFactorCompany(newFactor, valueFinal){
  
      this.detailsSale.factorCompany = newFactor
      this.totalAmountItems= 0 // GLOBAL
      this.totalFactorItems = 0
      this.totalValue = 0
  
      this.calculateSale()
  
      this.totalAmountItems += this.itemsThisSale.totalItems
      this.totalFactorItems +=  this.itemsThisSale.totalGrams
  
      this.totalAmountItems += this.listGenericProduct.totalCountGenericProducts
      this.totalValue += this.listGenericProduct.totalMoneyInGenericProducts + this.itemsThisSale.totalMoney
  
      if(this.valueFinal !== 0) {
        this.valueFixed = true
        this.totalValue = valueFinal
      }else{
        this.valueFixed = false
      }
  
      this.calculate()
    }
  
    calculateFromCloud(){
      this.itemsThisSale.calculate(this.detailsSale.factorCompany)
      this.listGenericProduct.calculate()
    }

    calculateSale(){
      this.calculateFromCloud()
      this.calculate()
    }

    setUpdateDescription(newDescription){
      this.detailsSale.description = newDescription
    }
  
    calculate(){
      var sunAll = this.getValueWithAll()
      this.totalValueIncludeAll = (sunAll - getPercentage(sunAll, this.saleDiscount.discountPercent))
  
      if(this.saleDiscount.isHasDiscount){
        this.saleDiscount.calculateDiscount(sunAll)
      }
    }
  
    getValueWithAll() {
      return this.valueFixed ? this.totalValue: (this.totalValue + this.extraValue)
    }

    getValueAllToString(){
      return this.getValueWithAll().toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    getTotalValueIncludeAllToString(){
      return this.totalValueIncludeAll.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
}

export default Sales