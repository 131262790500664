import { employeeData } from "../backEnd/InitController";
import LastVersionItem from "./LastVersionItem";

class VersionProduct{
    constructor(){
        this.lastEdition = "";
        this.dateCreateInBase = '';
        this.lastSalleEdition = '';
        this.lastVersionBase = [];
    }

    fromCloud(data){
        this.dateCreateInBase = data.dateCreateInBase
        this.lastSalleEdition = data.nameLastSelleEdite
        this.lastEdition =  data.dateLastEdite
    }

    fromAdd(){
        const date = new Date().toISOString().replace('T', ' ').slice(0, 19)
        this.dateCreateInBase = date.toString();
        this.lastEdition = date.toString();
    }

    configureNewVersion(action){
        const date = new Date().toISOString().replace('T', ' ').slice(0, 19)
        this.lastEdition = date
        this.lastSalleEdition = employeeData().genericPerson.name;
        let lastVersionTemp = new LastVersionItem()
        lastVersionTemp.setAction(action)
        this.lastVersionBase.push(lastVersionTemp)
    }

    fromOther(other){
        this.lastEdition = other.lastEdition;
        this.lastSalleEdition = other.lastSalleEdition;
        this.dateCreateInBase = other.dateCreateInBase;
        this.lastVersionBase = [...other.lastVersionBase]
    }

    getMapFromCloud(){
        const date = new Date().toISOString().replace('T', ' ').slice(0, 19)
        return {
        'lastEdition': date,
        'dateCreateInBase': date,
        'lastSalleEdition':this.lastSalleEdition,
        'container-lastVersion':this.lastVersionBase.length >0 ? this.lastVersionBase[0].getMapFromCloud(): null,
        }
    }
}

export default VersionProduct