import Sales from "../Sale/Sales"

export class LastVersionSale{
    constructor(){
        this.isEdite = false
        this.lastVersionSales = []
    }

    getMapFromCloud(){
        return{
        'isEdite':this.isEdite
        }
    }

    fromCloud(data, owner){
        if(data.lastVersionList === null) {
            return
        }
        
        this.isEdite = data.isEdite === 1? true:false
        for (var element of data.lastVersionList) {
            var version = new LastVersionModel()
            version.fromCloud(element, owner)
            this.lastVersionSales.push(version)
        }
    }

    clear(){
        this.isEdite = false
        this.lastVersionSales.clear()
    }
}

class LastVersionModel{

    constructor(){
        this.dateEdition = ''
        this.lastVersionSale = new Sales()
    }
  
    fromCloud(data, owner){
      this.dateEdition = data.dateEdite
      var sale = new Sales()
      this.lastVersionSale = sale.fromCloud(data, owner)
    }
}