import React, { useState, useEffect, useRef } from 'react';
import style from "../../../style/ListClients.module.css"
import { GetSmallModels } from '../../../Models/GetModels.js';
import { faMagnifyingGlass,faCircleInfo, faList, faHammer, faDownload} from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../layout/Loader.js';
import InputModels from "../../../Models/InputModels.js"
import GetAmountList from '../../../Models/GetAmountList.js';
import Space from '../../../layout/Space.js';
import { TitlePageResponsiveIcon } from '../../../layout/TitlePage.js';
import GetExpandedImage from '../../../layout/GetExpandedImage.js';
import { useError, useLoading } from '../../../AppContext.js';
import GetFloatingButton, { GetFloatingButtonWithLegend } from '../../../layout/GetFloatingButton.js';
import AddEditeRepairs from './AddEditeRepairs.js';
import { getRepairs } from '../../../controllers/CloudRepairs.js';
import DatePickerComponent from '../../../layout/DatePickerComponent.js';
import { GetButton } from '../../../layout/ButtonsLayout.js';
import { updateIfNull } from '../../../backEnd/Memory.js';
import RepairsModel from './RepairsModel.js';
import { employeeData } from '../../../backEnd/InitController.js';

function ListOfRepairs({client}) {

  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 180)
    return currentDate.toISOString().slice(0,10)
  })

  const [canCreateRepair] = useState(employeeData().employeePermission.permissionsSales[0])
  const [showAddRepair,setShowAddRepair] = useState(false)

  const {setIsLoading}  = useLoading()
  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])

  const [filtered, setFiltered] = useState([])
  const [filter, setFilter] = useState('all')
  const [orderBy, setOrderBy] = useState('desc')

  const [RepairSelected, setRepairSelected] = useState(null)

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const {showError} = useError()

  useEffect(() => {
      async function fetchData() {
          setList([])
          setLoading(true)
          setIsLoading(true)
          await updateIfNull(showError) 
          await handleUpdateValues()    
          setIsLoading(false)  
      }
      fetchData()
  }, [])

  const handleOpenViewRepair = (repair) =>{
    setRepairSelected(repair)
    setShowAddRepair(true)
  }

  const handleUpdateValues = async() => {
    setLoading(true)
    setList([])
    var value = await getRepairs(client,selectedDate,showError)
    setList(value)
    setFilter('all')
    setOrderBy('desc')
    setLoading(false)
  }

  const handleFindDataBase = async () => {
    setIsLoading(true)
    await handleUpdateValues()
    setIsLoading(false)
  }

  useEffect(() => {
    handleChangeFilter()
  }, [filter, orderBy,list])

  const controllerFilter = () => {
    let data = []
    switch (filter) {
      case 'all':
        data = list
        break
      case 'inRepair':
        data = list.filter(item => item.status === 0)
        break
      case 'deliverid':
        data = list.filter(item => item.status === 1)
        break
      case 'cancel':
          data = list.filter(item => item.status === 2)
          break
      default:
        data = list
        break
    }

    let sorted = [...data]
    switch (orderBy) {
      case 'asc':
        sorted.sort((a, b) => a.title.localeCompare(b.title))
        break
      case 'desc':
        sorted.sort((a, b) => b.title.localeCompare(a.title))
        break
      case 'value':
        sorted.sort((a, b) => b.value.toString().localeCompare(a.value))
        break
      case 'status':
        sorted.sort((a, b) => b.status.toString().localeCompare(a.status))
        break
      default:
        break
    }
    return sorted;
  }

  const handleFilterChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }
  
  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()

    resetScroll()
    if(value!= null){
      const filtered = data.filter(
        repair => {
          const filterValue = repair.value.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterData= repair.date.toString().toLowerCase().includes(value.target.value.toLowerCase())
          const filterTitle = repair.title.toLowerCase().includes(value.target.value.toLowerCase())
          return filterValue || filterData || filterTitle 
        }
      )
      setFiltered(filtered)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    container.scrollTop = 0
    setLimit(50)
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  const setDateTime = async (value)=>{
    setSelectedDate(value)
    await handleUpdateValues()
  }

  return (
    <div className={style.containerGeneral}> 
       <div className={style.containerGlobal}>  
           <Space right={2} left={2} top={2} bottom={2}/> 
           <div className={style.titleMenu}>     
             {<TitlePageResponsiveIcon subtitle={window.innerWidth <= 1200 ? '':'Lista Com Todos os Consertos/Reparos'} 
             children={
              <div className={style.row}>
                <DatePickerComponent 
                      handlerFunction={setDateTime}
                      valueStart={selectedDate} label={window.innerWidth <= 1200 ? '':'Data Inicial da Busca'}/>
                <GetButton responsive={true} title={'BUSCAR NO SERVIDOR'} handleFunction={handleFindDataBase} icon={faDownload} color={'#007bff'}/>
              </div>
             }
             path={window.innerWidth <= 1200 ?'':'São Todos os Consertos de ' + client._genericPerson.name} icon={faList}/>}
            </div>
            
           <div className={style.buildList}>
             <div className={style.filter}>
                 <div className={style.inputFilter}>
                   <InputModels iconButton={faMagnifyingGlass} 
                   type={"text"} placeholder={"Buscar Conserto/Reparo..."}
                   onChange={handleChangeFilter}/>
                 </div>
                 <div className={style.containerDropDown}> 
                  <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                    <option value="all">TODOS</option>
                    <option value="inRepair">EM REPARO</option>
                    <option value="deliverid">REPARADO</option>
                    <option value="cancel">CANCELADOS</option>
                  </select>
                   <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                       <option value="asc">CRESCENTE</option>
                       <option value="desc">DESCRESCENTE</option>
                       <option value="value">VALOR</option>
                       <option value="status">STATUS</option>
                   </select>
                 </div>
             </div>
             <Space right={2} left={2} top={5} bottom={0}/> 
             {(filtered.length > 0 && !loading ) ?<div className={style.buildList}>
                <GetAmountList amount={filtered.length} title={"Cosertos/Reparos Totais Encontrados na Busca..."}/>
                <div className={style.labelData}>
                  <h5>Nome do Conserto/Reparo</h5>
                  <h5>Data</h5>
                  <h5>Status</h5>
                  <h5>Valor</h5>
                </div>
               <Space right={0} left={0} top={0} bottom={10}/> 
             </div> : null}
             <div ref={containerRef} className={window.innerWidth > 1200? style.containerList:style.containerList1} onScroll={checkScroll}>
             <div className={style.loaderCenter}>
                    <Loader titleFinding={'Buscando Consertos/Reparos no Servidor!'} 
                         subtitleFinding={'Aguarde...'} loading={loading}/>
                  </div>
                 <div>
                   {(filtered.length === 0 && !loading)?
                   <GetSmallModels title={"Não há Consertos/Reparos com Esse Filtro"}
                   subtitle={"Tente Trocar o Filtro..."}
                   icon={faCircleInfo} />:
                     filtered.slice(0, limit).map((repair, index) => (
                         <div key={index}>
                         <RepairsModel onClick={()=>handleOpenViewRepair(repair)} repair={repair} />
                         </div>
                     ))
                   }
                 </div>
             </div>
           </div>
          {canCreateRepair? <>
           { window.innerWidth <= 1200?
            <GetFloatingButton width={65} height={65} icon={faHammer} color='#007bff' margiRight={'1%'} margiBottom={'1%'} handleFunction={()=>handleOpenViewRepair(null)} />
            :
            <GetFloatingButtonWithLegend legend={'NOVO CONSERTO'} width={'10%'} icon={faHammer} margiBottom={'1%'} color='#007bff' margiRight={'1%'} handleFunction={()=>handleOpenViewRepair(null)} />
          
           }
            </>:null
          }

        {showAddRepair? <AddEditeRepairs repairs={RepairSelected} onSave={handleFindDataBase} client={client} onClose={()=>setShowAddRepair(false)}/> : null}
       </div>
    </div>
  )

}

export default ListOfRepairs