import React, { useState, useEffect,useRef } from 'react'
import style from "../../../style/ListClients.module.css"
import { GetSmallModels } from '../../../Models/GetModels'
import { faMagnifyingGlass,faCircleInfo, faListCheck, faLock, faCalendar} from '@fortawesome/free-solid-svg-icons'
import Loader from '../../../layout/Loader'
import InputModels from "../../../Models/InputModels.js"
import GetAmountList from '../../../Models/GetAmountList.js'
import { TitlePageResponsiveIcon } from '../../../layout/TitlePage.js'
import Space from '../../../layout/Space.js'
import DatePickerComponent from '../../../layout/DatePickerComponent.js'
import { useError } from '../../../AppContext.js'
import { updateIfNull } from '../../../backEnd/Memory.js'
import { employeeData } from '../../../backEnd/InitController.js'
import { getListPurchase } from '../../../controllers/CloudPurchase.js'
import ViewPurchase from '../../Sale/ShowSale/ViewPurchase.js'

function ListShopping() {
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 60)
    return currentDate.toISOString().slice(0,10)
  })

  const [showViewSale, setShowViewSale] = useState(false)
  const [saleSelected, setSaleSelected] = useState(null)

  const [canView] = useState(employeeData().employeePermission.permissionsItems[2])
  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])
  const [filtered, setFiltered] = useState([])
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const {showError} = useError()
  const [filter, setFilter] = useState('all')
  const [orderBy, setOrderBy] = useState('asc')

  const handleOpenView= (sale) =>{
    setSaleSelected(sale)
    setShowViewSale(true)
  }

  useEffect(() => {
      async function fetchData() { 
        setLoading(true)
        await updateIfNull(showError)
        await handleFindDataBase()  
        setLoading(false)
      }
      fetchData()
  }, [])

  useEffect(() => {
    handleChangeFilter()
  }, [filter, orderBy,list])

  const handleFindDataBase = async (valueData) => {
    setLoading(true)
    setList([])
    const value = await getListPurchase(valueData ?? selectedDate,showError)
    setList(value)
    setFilter('all')
    setOrderBy('asc')
    setLoading(false)
  }
  
  const controllerFilter = () => {
    let data = []
    switch (filter) {
    case 'all':
        data = list
        break
    case 'present':
        data = list.filter(item => item.isPresent)
        break
    case 'sale':
        data = list.filter(item => !item.isCanceled)
        break
    case 'cancelSale':
        data = list.filter(item => item.isCanceled)
        break
    default:
        data = list
        break
    }

    let sorted = [...data];

    switch (orderBy) {
        case 'asc':
            sorted.sort((a, b) => a.dateSale.localeCompare(b.dateSale))
            break
         case 'desc':
            sorted.sort((a, b) => b.dateSale.localeCompare(a.dateSale))
            break
        default:
            break
    }
    return sorted;
  }

  const handleFilterChange = async (event) => {
    setFilter(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }
  
  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()
    resetScroll()
    if(value!= null){
      const filtered= data.filter(
        repositons => {
          const filterName= repositons.totalValueIncludeAll.toString().toLowerCase().includes(value.target.value.toLowerCase())
          return filterName
        }
      )
      setFiltered(filtered)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    if(container) container.scrollTop = 0
    setLimit(50)
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  const setDateTime = async (value)=>{
    setSelectedDate(value)
    handleFindDataBase(value)
  }

  return (
    <div className={style.containerGeneral}>
            <div className={style.containerGlobal}>  
            {canView?
            <>       
            <div className={style.titleMenu}>     
                <div className={style.desable}> 
                  <TitlePageResponsiveIcon subtitle={'Lista de Compras da Marca Bruna Semijoias'} 
                  path={'São Todas as Compras da Bruna Semijoias...'} icon={faListCheck}/>
                </div>
                <Space right={2} top={2} bottom={2}/> 
                <TitlePageResponsiveIcon
                    title={window.innerWidth > 768?  'Data Incial da Busca no Servidor' : ""}
                    path={window.innerWidth > 768?  'Selecione uma Data -> '  : ''}
                     children={
                    <DatePickerComponent 
                    handlerFunction={setDateTime}
                    valueStart={selectedDate} label={'Data Inicial da Busca no Servidor'}/>
                  } icon={faCalendar}/>
            </div>
            <div className={style.buildList}>
              <div className={style.filter}>
                  <div className={style.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} type={"text"} 
                    placeholder={"Buscar Compra no Servidor..."}
                    onChange={handleChangeFilter}/>
                  </div>
                  <div className={style.containerDropDown}>
                    <select className={style.dropDown} value={filter} onChange={handleFilterChange}>
                        <option value="all">TODAS</option>
                        <option value="present">BRINDES</option>
                        <option value="sale">COMPRAS</option> 
                        <option value="cancelSale">CANCELADAS</option>    
                    </select>
                    <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                        <option value="asc">CRESCENTE</option>
                        <option value="desc">DESCRESCENTE</option>
                    </select>
                  </div>
              </div>
              <Space right={2} left={2} top={5} bottom={0}/> 
              {(filtered.length > 0 && !loading ) ?<div className={style.buildList}>
                <GetAmountList amount={filtered.length} title={"Compras Encontradas na Busca..."}/>
                <div className={style.labelData}> 
                  <h5>Data</h5>
                  <h5>Tipo</h5>
                  <h5>Valor</h5>
                </div>
                <Space right={0} left={0} top={0} bottom={10}/> 
              </div> : null}
              <div ref={containerRef} className={style.containerList} onScroll={checkScroll}>
                  <div className={style.loaderCenter}>
                    <Loader titleFinding={'Buscando no Servidor!'} 
                         subtitleFinding={'Aguarde...'} loading={loading}/>
                  </div>
                  <div>
                    {(filtered.length === 0 && !loading)?
                    <GetSmallModels title={"Não há Compras com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo} />:
                      filtered.slice(0, limit).map((data, index) => (
                          <div onClick={()=>handleOpenView(data)} key={index} className={style.divItemClick}>
                            <GetSmallModels subtitle={data.isCanceled? 'ESSA COMPRA FOI CANCELADA' : data.isPresent? 'BRINDE' : 'COMPRA' } title={data.getDate()} value={data.getTotalValueToString()} />
                          </div>
                    ))
                  }
                  </div>
              </div>
            </div></>:
               <div className={style.divSapce}>
               <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar Compras'} />
            </div>}   
       </div>
       {showViewSale ? <ViewPurchase handleSave={()=>{}} sale={saleSelected} handleClose={()=> setShowViewSale(false)}/> : null}
    </div>
  )
}

export default ListShopping