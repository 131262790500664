class ItemGlobal{
    constructor(){
        this.idGlobalItemBrunaSemijoias = 0
        this.name= ''
        this.description= ''
        this.codebar = ''
        this.idProduct = 0
        this.databaseName = ''
        this.factorItem = 0
        this.InLineProduction = true
        this.linkImage = ''
        this.linkImageLocal = ''
    }

    fromData(data){
        this.idGlobalItemBrunaSemijoias = data.idGlobalItemBrunaSemijoias
        this.name= data.name
        this.description= data.description
        this.codebar = data.codebar
        this.idProduct = data.idProduct
        this.databaseName = data.databaseName
        this.factorItem = data.factorItem
        this.InLineProduction = data.InLineProduction ===1?true:false
        this.linkImage = data.linkImage
        this.linkImageLocal = data.linkImageLocal
    }

    fromOther(other){
        this.idGlobalItemBrunaSemijoias = other.idGlobalItemBrunaSemijoias
        this.name= other.name
        this.description= other.description
        this.codebar = other.codebar
        this.idProduct = other.idProduct
        this.databaseName = other.databaseName
        this.factorItem = other.factorItem
        this.InLineProduction = other.InLineProduction
        this.linkImage = other.linkImage
        this.linkImageLocal = other.linkImageLocal
  }

    getMapFromCloud(){
        return{
          'linkImageLocal':this.linkImageLocal,
          'idGlobalItemBrunaSemijoias':this.idGlobalItemBrunaSemijoias,
          'name':this.name,
          'description':this.description,
          'codebar':this.codebar,
          'idProduct':this.idProduct,
          'databaseName':this.databaseName,
          'factorItem':this.factorItem,
          'InLineProduction':this.InLineProduction,
          'linkImage':this.linkImage,
        };
      }
}

export default ItemGlobal;