export class ICMSController{
    constructor(){}

    getICMSTotal(){}
    getICMStoString(){}
    fromNfeSettigs(data){}
    fromOther(data){}
    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){}
    setValueVBC(vBC){}
    getMapFromCloud(){}
}

function validateNumber(value) {
    const number = parseFloat(value)

    if (isNaN(number)) {
        return 0.00
    }

    return number
}

export class ICMS {
    constructor() {
        this.CST = '-1'
        this.CSOSN = '102'
        this.ICMS = new ICMSSN102()
        this.vBC = 0
    }

    createObjectFromCST_CSOSN(CST,CSOSN){
        let current = ''
        if(CST !== '-1'){
            current = CST
        }else{
            current = CSOSN
        }
       
        switch (current) {
            case '00': 
                this.ICMS = new ICMS00()
                break
            case '10':
                this.ICMS = new ICMS10() 
                break
            case '20':
                this.ICMS = new ICMS20()
                break
            case '30':
                this.ICMS = new ICMS30()
                break
            case '40':
            case '41':
            case '50':                
                this.ICMS = new ICMS40_41_50()
                break
            case '51':        
                this.ICMS = new ICMS51()
                break
            case '60':        
                this.ICMS = new ICMS60()
                break
            case '70':        
                this.ICMS = new ICMS70()
                break
            case '90':        
                this.ICMS = new ICMS90()
                break
            case '101':
                this.ICMS = new ICMSSN101()
                break
            case '102':
            case '103':
            case '300':
            case '400':
                this.ICMS = new ICMSSN102()
                break
            case '201':
                this.ICMS = new ICMSSN201()
                break
            case '202':
            case '203':
                this.ICMS = new ICMSSN201_CSOSN202_CSOSN203()
                break
            case '500':
                this.ICMS = new ICMSSN500()
                break
            case '900':
                this.ICMS = new ICMSSN900()
                break
          default:
            return null
        }

    }

    fromNfeSettigs(data){   
        this.CST = data.CST
        this.CSOSN = data.CSOSN
        this.createObjectFromCST_CSOSN(this.CST,this.CSOSN)
        this.ICMS.fromNfeSettigs(data)
    }

    fromOther(data){
        this.CST = data.CST
        this.CSOSN = data.CSOSN
        this.createObjectFromCST_CSOSN(this.CST.slice(0,2),this.CSOSN.slice(0,3))
        this.ICMS.fromOther(data.ICMS ?? data)
        this.calculateICMS(this.vBC,0,0,0,0)
    }

    getICMSTotal(){
        return this.ICMS.getICMSTotal()
    }

    setValueVBC(vBC){
        this.vBC = this.ICMS.isUsevBC? validateNumber(vBC) : 0.00
        this.ICMS.setValueVBC(this.vBC)
        this.calculateICMS()
    }

    calculateICMS(){
        this.ICMS.calculateICMS(this.vBC,0,0,0,0)
    }

    fromCloud(data){
        this.fromNfeSettigs(data)
    }

    getICMStoString(){
        return this.ICMS.getICMStoString()
    }

    fromEditeICMS(data,automaticMath){
        this.CST = data.CST
        this.CSOSN = data.CSOSN
        this.createObjectFromCST_CSOSN(this.CST.slice(0,2),this.CSOSN.slice(0,3))
        this.ICMS.fromOther(data)
        if(automaticMath)this.calculateICMS(this.vBC,0,0,0,0)
    }

    fromChange(cst,csosn){
        this.CST = cst
        this.CSOSN = csosn
        this.createObjectFromCST_CSOSN(this.CST.slice(0,2),this.CSOSN.slice(0,3))
    }

    getMapFromCloud(){

        return{
            CSOSN: this.CSOSN.slice(0,3),
            CST: this.CSOSN !== '-1'? '-1' : this.CST.slice(0,2),
            ICMS: this.ICMS.getMapFromCloud(),          
        }
    }
}

export class ICMSSN101 extends ICMSController{
    constructor(){
        super()
        this.orig = '0 – Nacional'
        this.pCredSN =  ''
        this.vCredICMSSN = ''
        this.isUsevBC = false
    }

    getICMSTotal(){
        return validateNumber(this.vCredICMSSN)
    }

    setValueVBC(vBC){}

    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.pCredSN = data.pCredSN
    }

    getICMStoString(){
        const icmsValue = 0.00; 
        return ' | ICMS = ' + icmsValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.pCredSN = data.pCredSN 
        this.vCredICMSSN = data.vCredICMSSN
    }

    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){
        this.vCredICMSSN = (vProd*validateNumber(this.pCredSN))/100
    }

    getMapFromCloud(){
        return{
            orig: this.orig.slice(0,1),
            CSOSN: this.CSOSN,
            pCredSN: this.pCredSN,
            vCredICMSSN: this.vCredICMSSN,
        }
    }

}

export class ICMSSN102 extends ICMSController{
    constructor(){
        super()
        this.isUsevBC = false
        this.orig = '0 – Nacional'
    }

    getICMSTotal(){
        return validateNumber(0)
    }

    setValueVBC(vBC){}

    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
    }

    getICMStoString(){
        const icmsValue = 0.00; 
        return ' | ICMS = ' + icmsValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){}

    getMapFromCloud(){
        return{
            orig: this.orig.slice(0,1),
            CSOSN: this.CSOSN, 
        }
    }

}

export class ICMSSN201 extends ICMSController{
    constructor(){
        super()
        this.isUsevBC = true
        this.orig = '0 – Nacional'
        this.modBCST = '0 – Preço tabelado ou máximo sugerido'
        this.pMVAST =  ''
        this.pRedBCST =  ''
        this.pCredSN =  ''
        this.vBCST =  ''
        this.pICMSST =  ''
        this.vICMSST =  ''
        this.vBCFCPST =  ''
        this.pFCPST =  ''
        this.vFCPST =  ''
        this.vCredICMSSN = ''
    }

    getICMSTotal(){
        return validateNumber(this.vICMSST)
    }

    setValueVBC(vBC){
        this.vBCST = vBC
    }

    getICMStoString(){
        return ' | vICMSST = ' + this.vICMSST.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBCST = data.modBCST? data.modBCST:   '0 – Preço tabelado ou máximo sugerido'
        this.pMVAST = data.pMVAST
        this.pCredSN = data.pCredSN
        this.pRedBCST = data.pRedBCST
        this.pICMSST = data.pICMSST
        this.pFCPST = data.pFCPST
        this.vCredICMSSN = data.vCredICMSSN
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBCST = data.modBCST? data.modBCST:   '0 – Preço tabelado ou máximo sugerido'
        this.pMVAST = data.pMVAST
        this.pCredSN = data.pCredSN
        this.pRedBCST = data.pRedBCST
        this.vBCST = data.vBCST
        this.pICMSST = data.pICMSST
        this.vICMSST = data.vICMSST
        this.vCredICMSSN = data.vCredICMSSN
        this.vBCFCPST = data.vBCFCPST
        this.pFCPST = data.pFCPST
        this.vFCPST = data.vFCPST
    }

    //CSOSN201
    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){
        this.calcule_vBCST(vProd,vFrete,vSeg,vOutro,vDesc)
        this.vICMSST = validateNumber(this.vBCST)  * (validateNumber(this.pICMSST)/100)
        this.vBCFCPST = this.vICMSST
        this.calculate_vFCPST()
    }

    calculate_vFCPST(){
        this.vFCPST = this.vBCFCPST * (validateNumber(this.pFCPST)/100)
    }

    //CSOSN201
    calcule_vBCST(vProd,vFrete,vSeg,vOutro,vDesc){
        this.vBCST = ((vProd + vFrete + vSeg + vOutro - vDesc) * (1+(validateNumber(this.pMVAST)/100))) * (1-(validateNumber(this.pRedBCST)/100))
    }

    getMapFromCloud(){
        return{
            orig: this.orig.slice(0,1),
            CSOSN:this.CSOSN ,
            modBCST:this.modBCST.slice(0,1),
            pMVAST:this.pMVAST,
            pRedBCST:this.pRedBCST,
            vBCST:this.vBCST,
            pCredSN:this.pCredSN,
            pICMSST:this.pICMSST,
            vICMSST:this.vICMSST,
            vBCFCPST:this.vBCFCPST,
            pFCPST:this.pFCPST,
            vFCPST:this.vFCPST,
            vCredICMSSN: this.vCredICMSSN
        }
    }

}

export class ICMSSN201_CSOSN202_CSOSN203 extends ICMSController{
    constructor(){
        super()
        this.isUsevBC = true
        this.orig = '0 – Nacional'
        this.modBCST = '0 – Preço tabelado ou máximo sugerido'
        this.pMVAST =  ''
        this.pRedBCST =  ''
        this.vBCST =  ''
        this.pICMSST =  ''
        this.vICMSST =  ''
    }

    getICMSTotal(){
        return validateNumber(this.vICMSST)
    }

    setValueVBC(vBC){
        this.vBCST = vBC
    }

    getICMStoString(){
        const icmsValue = 0.00; 
        return ' | ICMS = ' + icmsValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBCST = data.modBCST? data.modBCST:   '0 – Preço tabelado ou máximo sugerido'
        this.pMVAST = data.pMVAST
        this.pRedBCST = data.pRedBCST
        this.pICMSST = data.pICMSST
        this.pFCPST = data.pFCPST
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBCST = data.modBCST? data.modBCST:   '0 – Preço tabelado ou máximo sugerido'
        this.pMVAST = data.pMVAST
        this.pRedBCST = data.pRedBCST
        this.pICMSST = data.pICMSST
        this.vBCST = data.vBCST
        this.vICMSST = data.vICMSST
    }

    //CSOSN201
    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){
        this.calcule_vBCST(vProd,vFrete,vSeg,vOutro,vDesc)
        this.vICMSST = this.vBCST * (validateNumber(this.pICMSST)/100)
    }

    //CSOSN201
    calcule_vBCST(vProd,vFrete,vSeg,vOutro,vDesc){
        this.vBCST = ((vProd + vFrete + vSeg + vOutro - vDesc) * (1+(validateNumber(this.pMVAST)/100))) * (1-(validateNumber(this.pRedBCST)/100))
    }

    getMapFromCloud(){
        return{
            orig: this.orig.slice(0,1),
            CSOSN: this.CSOSN,
            modBCST: this.modBCST.slice(0,1),
            pMVAST: this.pMVAST,
            pRedBCST: this.pRedBCST,
            vBCST: this.vBCST,
            pICMSST: this.pICMSST,
            vICMSST: this.vICMSST,
        }
    }

}

export class ICMSSN500 extends ICMSController{
    constructor(){
        super()
        this.isUsevBC = true
        this.orig = '0 – Nacional'
        this.vBCSTRet  =  ''
        this.vICMSSTRet  =  ''
    }

    getICMSTotal(){
        return validateNumber(this.vICMSSTRet)
    }

    setValueVBC(vBC){}

    getICMStoString(){
        return ' | vICMSSTRet = ' +  this.vICMSSTRet.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    
    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.vBCSTRet = data.vBCSTRet
        this.vICMSSTRet = data.vICMSSTRet
    }

    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){}

    getMapFromCloud(){
        return{
            orig: this.orig.slice(0,1),
            CSOSN: this.CSOSN,
            vBCSTRet: this.vBCSTRet,
            vICMSSTRet: this.vICMSSTRet,
        }
    }

}

export class ICMSSN900 extends ICMSController{
    constructor(){
        super()
        this.isUsevBC = true
        this.orig = '0 – Nacional'
        this.modBC  = '0 - Margem Valor Agregado (%)'
        this.modBCST = '0 – Preço tabelado ou máximo sugerido'
        this.vBC  =  ''
        this.pRedBC  =  ''
        this.pICMS  =  ''
        this.vICMS  =  ''
        this.pMVAST  =  ''
        this.pRedBCST  = ''
        this.vBCST  =  ''
        this.pICMSST  =  ''
        this.vICMSST  =  ''
        this.vBCSTRet  =  ''
        this.vICMSSTRet  = ''
        this.pCredSN  =  ''
        this.vCredICMSSN  =  ''
    }

    getICMSTotal(){
        return validateNumber(this.vICMSST) + validateNumber(this.vICMS)
    }


    setValueVBC(vBC){
        this.vBC = vBC
        this.vBCST = vBC
    }

    getICMStoString(){
        return ' | ICMS = ' + this.vICMS.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBC = data.modBC? data.modBC : '0 - Margem Valor Agregado (%)'
        this.modBCST = data.modBCST? data.modBCST:   '0 – Preço tabelado ou máximo sugerido'
        this.pRedBC = data.pRedBC
        this.pICMS = data.pICMS
        this.pMVAST = data.pMVAST
        this.pRedBCST = data.pRedBCST
        this.pICMSST = data.pICMSST
        this.pCredSN = data.pCredSN
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBC = data.modBC? data.modBC : '0 - Margem Valor Agregado (%)'
        this.modBCST = data.modBCST? data.modBCST:   '0 – Preço tabelado ou máximo sugerido'
        this.pRedBC = data.pRedBC
        this.pICMS = data.pICMS
        this.pMVAST = data.pMVAST
        this.pRedBCST = data.pRedBCST
        this.pICMSST = data.pICMSST
        this.pCredSN = data.pCredSN

        this.vCredICMSSN = data.vCredICMSSN
        this.vICMSSTRet = data.vICMSSTRet
        this.vBCSTRet = data.vBCSTRet
        this.vICMSST = data.vICMSST
        this.vBCST = data.vBCST
        this.vICMS = data.vICMS
        this.vBC = data.vBC
    }

    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){
        this.vBC = (vProd + vFrete + vSeg + vOutro - vDesc) * (1-(validateNumber(this.pRedBC)/100))
        this.vICMS = this.vBC * (validateNumber(this.pICMS)/100)
        this.vBCST =  ((vProd + vFrete + vSeg + vOutro - vDesc) * (1+(validateNumber(this.pMVAST)/100))) * (1-(validateNumber(this.pRedBCST)/100))
        this.vICMSST = this.vBCST * (validateNumber(this.pICMSST)/100)
    }

    getMapFromCloud(){
        return{
            orig: this.orig.slice(0,1),
            CSOSN:this.CSOSN,
            modBCST:this.modBCST.slice(0,1),
            vBC:this.vBC,
            pRedBC:this.pRedBC,
            pICMS:this.pICMS,
            vICMS:this.vICMS,
            modBCST:this.modBCST,
            pMVAST:this.pMVAST,
            pRedBCST:this.pRedBCST,
            vBCST:this.vBCST,
            pICMSST:this.pICMSST ,
            vICMSST:this.vICMSST,
            vBCSTRet:this.vBCSTRet ,
            vICMSSTRet:this.vICMSSTRet ,
            pCredSN:this.pCredSN ,
            vCredICMSSN:this.vCredICMSSN ,
        }
    }

}

export class ICMS00 extends ICMSController{
    constructor(){
        super()
        this.isUsevBC = true
        this.orig = '0 – Nacional'
        this.CST = '00'
        this.modBC = '0 - Margem Valor Agregado (%)'
        this.vBC  =  ''
        this.pICMS  = ''
        this.vICMS  =  ''
    }

    getICMSTotal(){
        return validateNumber(this.vICMS) 
    }

    setValueVBC(vBC){
        this.vBC = vBC
    }

    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.CST = data.CST
        this.modBC = data.modBC? data.modBC:   '0 – Preço tabelado ou máximo sugerido'
        this.vBC  = data.vBC
        this.pICMS  =data.pICMS
        this.vICMS  = data.vICMS
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.CST = data.CST
        this.modBC = data.modBC? data.modBC:   '0 – Preço tabelado ou máximo sugerido'
        this.vBC  = data.vBC
        this.pICMS  =data.pICMS
        this.vICMS  = data.vICMS
    }

    getICMStoString(){
        return ' | ICMS = ' + this.vICMS.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){
        this.vBC = (vProd + vFrete + vSeg + vOutro - vDesc)
        this.vICMS = this.vBC * (validateNumber(this.pICMS)/100)
    }

    getMapFromCloud(){
        return{
            orig: this.orig.slice(0,1),
            CST: this.CST,
            modBC: this.modBC.slice(0,1),
            vBC: this.vBC,
            pICMS: this.pICMS,
            vICMS: this.vICMS,
        }
    }

}

export class ICMS10 extends ICMSController{
    constructor(){
        super()
        this.isUsevBC = true
        this.orig = '0 – Nacional'
        this.modBC = '0 - Margem Valor Agregado (%)'
        this.pICMSST  =  ''
        this.pICMS  =  ''
        this.modBCST  = '0 – Preço tabelado ou máximo sugerido'
        this.pMVAST  =  ''
        this.pRedBCST  =  ''

        this.vBC  =  ''
        this.vICMS  =  '' 
        this.vBCST  =  ''

        this.vICMSST  =  ''
    }

    getICMSTotal(){
        return validateNumber(this.vICMS) + validateNumber(this.vICMSST) 
    }

    setValueVBC(vBC){
        this.vBC = vBC
        this.vBCST = vBC
    }

    getICMStoString(){
        return ' | ICMS = ' + this.vICMS.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })  + ' | ICMSST = ' + this.vICMSST.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBC = data.modBC? data.modBC : '0 - Margem Valor Agregado (%)'
        this.modBCST = data.modBCST? data.modBCST:   '0 – Preço tabelado ou máximo sugerido'
        this.pICMSST = data.pICMSST
        this.pICMS = data.pICMS
        this.pMVAST = data.pMVAST
        this.pRedBCST = data.pRedBCST
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBC = data.modBC? data.modBC : '0 - Margem Valor Agregado (%)'
        this.modBCST = data.modBCST? data.modBCST:   '0 – Preço tabelado ou máximo sugerido'
        this.pICMSST = data.pICMSST
        this.pICMS = data.pICMS
        this.pMVAST = data.pMVAST
        this.pRedBCST = data.pRedBCST

        this.vBC  = data.vBC
        this.vICMS  = data.vICMS 
        this.vBCST  = data.vBCST

        this.vICMSST  = data.vICMSST
    }



    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){
        this.vBC = (vProd + vFrete + vSeg + vOutro - vDesc)
        this.vICMS = this.vBC * (validateNumber(this.pICMS)/100)
    }

    getMapFromCloud(){
        return{
            orig: this.orig.slice(0,1),
            CST: this.CST,
            modBCST: this.modBCST.slice(0,1),
            vBC: this.vBC,
            modBC:this.modBC.slice(0,1),
            pICMS: this.pICMS,
            vICMS: this.vICMS,
            pMVAST:this.pMVAST,
            pRedBCST:this.pRedBCST,
            vBCST: this.vBCST,
            pICMSST:this.pICMSST,
            vICMSST:this.vICMSST,
        }
    }

}

export class ICMS20 extends ICMSController{
    constructor(){
        super()
        this.orig = '0 – Nacional'
        this.modBC = '0 - Margem Valor Agregado (%)'
        this.pRedBC  =  ''
        this.pICMS  =  ''
        this.isUsevBC = true
        this.vBC  =  ''
        this.vICMS  =  ''
    }

    getICMSTotal(){
        return validateNumber(this.vICMS) 
    }

    setValueVBC(vBC){
        this.vBC = vBC
    }

    getICMStoString(){
        return ' | ICMS = ' + this.vICMS.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
    }
    
    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBC = data.modBC? data.modBC:   '0 – Preço tabelado ou máximo sugerido'
        this.pRedBC = data.pRedBC
        this.pICMS = data.pICMS
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBC = data.modBC? data.modBC:   '0 – Preço tabelado ou máximo sugerido'
        this.pRedBC = data.pRedBC
        this.pICMS = data.pICMS

        this.vBC = data.vBC
        this.vICMS = data.vICMS  
    }


    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){
        this.vBC = (vProd + vFrete + vSeg + vOutro - vDesc) * (1-(validateNumber(this.pRedBC)/100))
        this.vICMS = this.vBC * (validateNumber(this.pICMS)/100)
    }

    getMapFromCloud(){
        return{
            orig:this.orig.slice(0,1),
            modBC:this.modBC.slice(0,1),
            pRedBC:this.pRedBC,
            pICMS:this.pICMS,
            vBC:this.vBC,
            vICMS:this.vICMS
        }
    }

}

export class ICMS30 extends ICMSController{
    constructor(){
        super()
        this.orig = '0 – Nacional'
        this.modBCST = '0 - Margem Valor Agregado (%)'
        this.pMVAST  =  ''
        this.pRedBCST  =  ''
        this.pICMSST  =  ''
        this.isUsevBC = true
        this.vBCST  =  ''
        this.vICMSST  =  ''
    }

    getICMSTotal(){
        return validateNumber(this.vICMSST) 
    }

    setValueVBC(vBC){
        this.vBC = vBC
        this.vBCST = vBC
    }


    getICMStoString(){
        return ' | ICMSST = ' + this.vICMSST.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
    }

    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBCST = data.modBCST? data.modBCST:   '0 – Preço tabelado ou máximo sugerido'
        this.pMVAST = data.pMVAST
        this.pRedBCST = data.pRedBCST
        this.pICMSST = data.pICMSST
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBCST = data.modBCST? data.modBCST:   '0 – Preço tabelado ou máximo sugerido'
        this.pMVAST = data.pMVAST
        this.pRedBCST = data.pRedBCST
        this.pICMSST = data.pICMSST

        this.vBCST = data.vBCST
        this.vICMSST = data.vICMSST  
    }


    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){
        this.vBCST =  ((vProd + vFrete + vSeg + vOutro - vDesc) * (1+(validateNumber(this.pMVAST)/100))) * (1-(validateNumber(this.pRedBCST)/100))
        this.vICMSST =  this.vBCST * ( validateNumber(this.pICMSST)/100)
    }

    getMapFromCloud(){
        return{
            orig: this.orig.slice(0,1),
            CST: this.CST,
            modBCST: this.modBCST.slice(0,1),
            pMVAST:this.pMVAST,
            pRedBCST:this.pRedBCST,
            vBCST: this.vBCST,
            pICMSST:this.pICMSST,
            vICMSST:this.vICMSST,
        }
    }

}

export class ICMS40_41_50 extends ICMSController{
    constructor(){
        super()
        this.isUsevBC = false
        this.orig = '0 – Nacional'
        this.vICMS  =  ''
        this.motDesICMS  = '1 – Táx'
    }

    getICMSTotal(){
        return validateNumber(this.vICMS) 
    }

    getICMStoString(){
        return ' | ICMS = ' + this.vICMS.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
    }

    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.motDesICMS = data.motDesICMS ? data.motDesICMS : '1 - Táx'
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.vICMS = data.vICMS
        this.motDesICMS = data.motDesICMS ? data.motDesICMS : '1 - Táx'
    }

    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){}

    getMapFromCloud(){
        return{
            orig:this.orig.slice(0,1),
            CST:this.CST,
            vICMS:this.vICMS,
            motDesICMS:this.motDesICMS.slice(0,1),
        }
    }

}

export class ICMS51 extends ICMSController{
    constructor(){
        super()
        this.orig = '0 – Nacional'
        this.modBC  = '0 - Margem Valor Agregado (%)'
        this.pRedBC  =  ''
        this.pICMS  =  ''
        this.isUsevBC = true
        this.vBC  =  ''
        this.vICMS  = ''
    }

    getICMSTotal(){
        return validateNumber(this.vICMS) 
    }

    setValueVBC(vBC){
        this.vBC = vBC
    }

    getICMStoString(){
        return ' | ICMS = ' + this.vICMS.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
    }

    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBC = data.modBC? data.modBC : '0 - Margem Valor Agregado (%)'
        this.pRedBC = data.pRedBC
        this.pICMS = data.pICMS
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBC = data.modBC? data.modBC : '0 - Margem Valor Agregado (%)'
        this.pRedBC = data.pRedBC
        this.pICMS = data.pICMS

        this.vICMS = data.vICMS
        this.vBC = data.vBC  
    }

    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){
        this.vBC = (vProd + vFrete + vSeg + vOutro - vDesc) * (1-(validateNumber(this.pRedBC)/100))
        this.vICMS =  this.vBC * ( validateNumber(this.pICMS)/100)
    }

    getMapFromCloud(){
        return{
            orig:this.orig.slice(0,1),
            CST:this.CST,
            modBC:this.modBC.slice(0,1),
            pRedBC:this.pRedBC,
            vBC: this.vBC,
            pICMS: this.pICMS,
            vICMS:this.vICMS,
        }
    }

}

export class ICMS60 extends ICMSController{
    constructor(){
        super()
        this.isUsevBC = true
        this.orig = '0 – Nacional'
        this.vBCSTRet  =  ''
        this.vICMSSTRet  =  ''
    }

    getICMSTotal(){
        return validateNumber(this.vICMSSTRet) 
    }

    setValueVBC(vBC){}

    getICMStoString(){
        const icmsValue = 0.00; 
        return ' | ICMS = ' + icmsValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.vBCSTRet = data.vBCSTRet
        this.vICMSSTRet = data.vICMSSTRet
    }


    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){}

    getMapFromCloud(){
        return{
            orig: this.orig.slice(0,1),
            CST: this.CST = '60',
            vBCSTRet: this.vBCSTRet  = 0,
            vICMSSTRet: this.vICMSSTRet  = 0,
        }
    }

}

export class ICMS70 extends ICMSController{
    constructor(){
        super()
        this.orig = '0 – Nacional'
        this.modBC  = '0 - Margem Valor Agregado (%)'
        this.pRedBC  =   ''
        this.pICMSST  =  ''
        this.pICMS  =  ''
        this.modBCST  = '0 – Preço tabelado ou máximo sugerido'
        this.pMVAST =  ''
        this.pRedBCST  =  ''
        this.isUsevBC = true
        this.vBCST  =  ''
        this.vICMS  =  ''
        this.vBC  =  ''
        this.vICMSST  =  ''
    }

    getICMSTotal(){
        return validateNumber(this.vICMS)  + validateNumber(this.vICMSST) 
    }

    setValueVBC(vBC){
        this.vBC = vBC
    }

    getICMStoString(){
        return ' | ICMS = ' + this.vICMS.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })  + ' | ICMSST = ' + this.vICMSST.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBC = data.modBC? data.modBC : '0 - Margem Valor Agregado (%)'
        this.modBCST = data.modBCST? data.modBCST:   '0 – Preço tabelado ou máximo sugerido'

        this.pRedBC = data.pRedBC
        this.pICMSST = data.pICMSST
        this.pICMS = data.pICMS
        this.pMVAST = data.pMVAST
        this.pRedBCST = data.pRedBCST
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBC = data.modBC? data.modBC : '0 - Margem Valor Agregado (%)'
        this.modBCST = data.modBCST? data.modBCST:   '0 – Preço tabelado ou máximo sugerido'
        this.pRedBC = data.pRedBC
        this.pICMSST = data.pICMSST
        this.pICMS = data.pICMS

        this.pMVAST = data.pMVAST
        this.pRedBCST = data.pRedBCST

        this.vICMS = data.vICMS
        this.vBC = data.vBC
        this.vBCST = data.vBCST
        this.vICMSST = data.vICMSST 
    }

    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){
        this.vBC = (vProd + vFrete + vSeg + vOutro - vDesc) * (1-(validateNumber(this.pRedBC)/100))
        this.vBCST =  ((vProd + vFrete + vSeg + vOutro - vDesc) * (1+(validateNumber(this.pMVAST)/100))) * (1-(validateNumber(this.pRedBCST)/100))
        this.vICMS =  this.vBC * ( validateNumber(this.pICMS)/100)
    }

    getMapFromCloud(){
        return{
            orig:this.orig.slice(0,1),
            CST:this.CST = '70',
            modBC:this.modBC.slice(0,1),
            pRedBC:this.pRedBC,
            vBC:this.vBC,
            pICMS:this.pICMS,
            vICMS:this.vICMS,
            modBCST:this.modBCST.slice(0,1),
            pMVAST:this.pMVAST,
            pRedBCST:this.pRedBCST,
            vBCST:this.vBCST,
            pICMSST:this.pICMSST,
            vICMSST:this.vICMSST,
        }
    }

}

export class ICMS90 extends ICMSController{
    constructor(){
        super()
        this.orig = '0 – Nacional'
        this.modBC  = '0 - Margem Valor Agregado (%)'
        this.pRedBC  =  ''
        this.pICMSST  =  ''
        this.pICMS  =  ''
        this.modBCST  = '0 – Preço tabelado ou máximo sugerido'
        this.pMVAST =  ''
        this.pRedBCST  =  ''
        this.isUsevBC = true
        this.vICMS  =  0
        this.vBC  =  0
        this.vBCST  =  0
        this.vICMSST  =  0
    }

    getICMSTotal(){
        return validateNumber(this.vICMS)  + validateNumber(this.vICMSST) 
    }


    setValueVBC(vBC){
        this.vBC = vBC
        this.vBCST = vBC
    }

    getICMStoString(){
        return ' | ICMS = ' + validateNumber(this.vICMS).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })  + ' | ICMSST = ' + validateNumber(this.vICMSST).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    fromNfeSettigs(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBC = data.modBC
        this.pRedBC = data.pRedBC
        this.pICMSST = data.pICMSST
        this.pICMS = data.pICMS
        this.modBCST = data.modBCST
        this.pMVAST = data.pMVAST
        this.pRedBCST = data.pRedBCST
    }

    fromOther(data){
        this.orig = data.orig !== undefined ? data.orig : '0 – Nacional'
        this.modBC = data.modBC? data.modBC : '0 - Margem Valor Agregado (%)'
        this.modBCST = data.modBCST? data.modBCST:   '0 – Preço tabelado ou máximo sugerido'
        this.pRedBC = data.pRedBC
        this.pICMSST = data.pICMSST
        this.pICMS = data.pICMS
       
        this.pMVAST = data.pMVAST
        this.pRedBCST = data.pRedBCST

        this.vICMS = data.vICMS
        this.vBC = data.vBC
        this.vBCST = data.vBCST
        this.vICMSST = data.vICMSST 
    }

    calculateICMS(vProd,vFrete,vSeg,vOutro,vDesc){
        this.vBC = (vProd + vFrete + vSeg + vOutro - vDesc) * (1-(validateNumber(this.pRedBC)/100))
        this.vBCST =  ((vProd + vFrete + vSeg + vOutro - vDesc) * (1+(validateNumber(this.pMVAST)/100))) * (1-(validateNumber(this.pRedBCST)/100))
        this.vICMS =  this.vBC * ( validateNumber(this.pICMS)/100)
    }

    getMapFromCloud(){
        return{
            orig:this.orig.slice(0,1),
            CST:this.CST = '70',
            modBC:this.modBC.slice(0,1),
            pRedBC:this.pRedBC,
            vBC:this.vBC,
            pICMS:this.pICMS,
            vICMS:this.vICMS,
            modBCST:this.modBCST.slice(0,1),
            pMVAST:this.pMVAST,
            pRedBCST:this.pRedBCST,
            vBCST:this.vBCST,
            pICMSST:this.pICMSST,
            vICMSST:this.vICMSST,
        }
    }

}