import React from "react";
import style from "../style/Footer.module.css"
import logoMushroom from  '../assets/mushroom-angels-games-icone.png'

export function Footer(){
    return(
        <div className={style.containerFooter}>
            <div className="footer-content">
                <div className={style.menuFootLogo}>
                    <a href="https://www.mushroomangelsgames.com/"> <img src={logoMushroom} className={style.footerLogo} alt='Icone da Api B-Semijoias Clientes'></img></a>
                    <span className={style.nameMushroom}>Mushroom Angels Games</span>
                    <p className={style.copyRight}>Copyright © 2018-2024 - B-Semijoias</p>
                </div>
            </div>
        </div>
    );
}

export function FooterSmall(){
    return(
        <div className={style.containerFooterSmall}>
            <div className={style.menuFootLogoSmall}>
                <a className={style.nameMushroom} href="https://www.mushroomangelsgames.com/">
                Mushroom Angels Games</a>
                <p className={style.copyRight}>Copyright © 2018-2024 - B-Semijoias</p>
            </div>
        </div>
    );
}