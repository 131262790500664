import React, { useState, useEffect,useRef } from 'react'
import style from "../../style/ListClients.module.css"
import { GetSmallModels } from '../../Models/GetModels'
import { faMagnifyingGlass,faCircleInfo, faDownload, faListCheck, faFilePdf, faLock} from '@fortawesome/free-solid-svg-icons'
import Loader from '../../layout/Loader'
import InputModels from "../../Models/InputModels.js"
import  { TitlePageResponsiveIcon } from '../../layout/TitlePage.js'
import Space from '../../layout/Space.js'
import { GetButton } from '../../layout/ButtonsLayout.js'
import { useError } from '../../AppContext.js'
import { updateIfNull } from '../../backEnd/Memory.js'
import GetAmountList from '../../Models/GetAmountList.js'
import { getListExtracts } from '../../controllers/CloudPdfs.js'
import { employeeData } from '../../backEnd/InitController.js'


function ListExtractsSave() {

  const containerRef = useRef(null)
  const [limit, setLimit] = useState([50])
  const [filtered, setFiltered] = useState([])
  const [list, setList] = useState([])
  const [canView] = useState(employeeData().employeePermission.permissionsContabilite[13])
  const [loading, setLoading] = useState(false)
  const {showError} = useError()
  const [orderBy, setOrderBy] = useState('asc')

  const handleOpenExtract = (extract) => {
    window.open(extract.url, '_blank');
  };

  useEffect(() => {
    if(canView){
        async function fetchData() { 
          setLoading(true)
          await updateIfNull(showError)
          await handleFindDataBase() 
          setLoading(false)  
        }
        fetchData()
    }
  }, [])

  useEffect(() => {
    handleChangeFilter()
  }, [orderBy,list])

  const handleFindDataBase = async () => {
    setLoading(true)
    setList([])
    const value = await getListExtracts(showError)
    setList(value)
    setOrderBy('asc')
    setLoading(false)
  }
  
  const controllerFilter = () => { 
    let sorted = [...list];
    switch (orderBy) {
        case 'asc':
            sorted.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
            });
            break;
        case 'desc':
            sorted.sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
            });
            break;
        default:
            break;
    }

    return sorted;
};

  const handleOrderByChange = async (event) => {
    setOrderBy(event.target.value)
    handleChangeFilter()
    setFiltered(filtered)
  }

  const handleChangeFilter = (value) => {
    const data = controllerFilter()
    resetScroll()
    if(value!= null){
      const filtered= data.filter(
        extract => {
          const filterName= extract.name.toLowerCase().includes(value.target.value.toLowerCase())
          return filterName
        }
      )
      setFiltered(filtered)
    }else{
      setFiltered(data)
    }
  }

  const resetScroll =()=>{
    const container = containerRef.current
    if(container) container.scrollTop = 0
    setLimit(50)
  }

  const checkScroll= ()=> {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;

    if (scrollPosition + containerHeight >= scrollHeight) {   
      setLimit((limit + 50))
    }
  }

  return (
    <div className={style.containerGeneral}>
        <div className={style.containerGlobal}>  
            {canView? <>       
            <div className={style.titleMenu}>     
              <TitlePageResponsiveIcon subtitle={'Lista com Todos os Extratos Salvos no Servidor'} 
              children={
                <GetButton responsive={true} title={'BUSCAR NO SERVIDOR'} 
                  color='#007bff' icon={faDownload} handleFunction={handleFindDataBase}/>
              }
              path={'Todos os Extratos que Foram Salvos no Servidor...'} icon={faListCheck}/>
            </div>
            <div className={style.buildList}>
              <div className={style.filter}>
                  <div className={style.inputFilter}>
                    <InputModels iconButton={faMagnifyingGlass} type={"text"} 
                    placeholder={"Buscar Extrato por Nome..."}
                    onChange={handleChangeFilter}/>
                  </div>
                  <div className={style.containerDropDown}>               
                    <select className={style.dropDown} value={orderBy} onChange={handleOrderByChange}>
                        <option value="asc">CRESCENTE</option>
                        <option value="desc">DESCRESCENTE</option>
                    </select>
                  </div>
              </div>
              <Space right={2} left={2} top={5} bottom={0}/> 
              {(filtered.length > 0 && !loading ) ?<div className={style.buildList}>
                <GetAmountList amount={filtered.length} title={"Extratos Totais Encontrados na Busca..."}/>
                <div className={style.labelData}>
                  <h5>Nome</h5> 
                  <h5>Tamanho do Arquivo</h5>
                </div>
                <Space right={0} left={0} top={0} bottom={10}/> 
              </div> : null}
              <div ref={containerRef} className={style.containerList} onScroll={checkScroll}>
                  <div className={style.loaderCenter}>
                    <Loader titleFinding={'Buscando no Servidor!'} 
                         subtitleFinding={'Aguarde...'} loading={loading}/>
                  </div>
                  <div>
                    {(filtered.length === 0 && !loading)?
                    <GetSmallModels title={"Não há Extratos com Esse Filtro"}
                    subtitle={"Tente Trocar o Filtro..."}
                    icon={faCircleInfo} />:
                      filtered.slice(0, limit).map((data, index) => (
                          <div key={index}>
                            <div onClick={() => handleOpenExtract(data)} className={style.forceHover}>
                                <GetSmallModels icon={faFilePdf} 
                                subtitle={window.innerWidth < 768? data.date + '-' + data.sizeValue : data.date}
                                title={data.name} value={window.innerWidth < 768?  '':data.sizeValue}/>
                            </div>
                          </div>
                    ))
                  }
                  </div>
              </div>
            </div>
            </>:
               <div className={style.divSapce}>
               <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar a Lista de Extratos Gerais'} />
               </div>}
       </div>
    </div>
  )
}

export default ListExtractsSave