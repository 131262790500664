class CalculateBj{
    constructor(){
        this.grams = 0
        this.items = 0
        this.models = 0
    }
    
    clear(){
        this.grams = 0
        this.items = 0
        this.models = 0
    }

    calculate(list){
        this.clear()
        list.forEach(ss=>{
            this.grams += ss.getTotalGrams()
            this.items += ss.amount
            this.models ++
        })
    }
}

export default CalculateBj