
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { errorRouter, routerFromAccountingComissions, routerFromAccountingFutureReceivers, routerFromAccountingGlobal, routerFromAccountingGraphicItems, routerFromAccountingGraphicMoney, routerFromAccountingGraphicMoths, routerFromAccountingGraphicSales, routerFromAccountingItemSales, routerFromAccountingListAllRepairsPeriod, routerFromAccountingListSalesFactorPeriod, routerFromAccountingListSalesPeriod, routerFromAccountingMoth, routerFromAccountingMothExtract, routerFromAccountingPerformace, routerFromAccountingProducts, routerFromAccountingReceivers, routerFromLogin } from "../../routers/routerList.js";
import style from "../../style/Controller.module.css"
import { NavBarGlobal, ObjectNavbarMenu } from "../NavbarGlobal.js";
import { faCalendarCheck,faCalendarWeek,faDiagramNext,faCartArrowDown,faFileInvoiceDollar,
    faChartArea, faChartLine,faMoneyBill1Wave,faMoneyCheckDollar,faMoneyCheck,faMoneyBillTrendUp,faSackDollar,faCommentsDollar,faTableList, faCalendarPlus, faChartColumn, faChartBar, faScrewdriverWrench} from "@fortawesome/free-solid-svg-icons";
import ViewExtractMoth from "./ViewExtractMoth.js";
import ControllerMoth from "./ControllerMoth.js";
import ListOfAllSales from "./ListOfAllSales.js";
import ListSalesFactors from "./ListSalesFactors.js";
import ListOfReceivers from "./ListOfReceivers.js";
import ListOfFutureReceivers from "./ListOfFutureReceivers.js";
import ListofCommission from "./ListofCommission.js";
import { faProductHunt } from "@fortawesome/free-brands-svg-icons";
import ListBJSold from "./ListBJSold.js";
import ListProductsSold from "./ListProductsSold.js";
import Performance from "./Performance.js";
import GraphicItems, { GraphicCompare, GraphicSales } from "./AllGraphics.js";
import AllMothsScreem from "./AllMothsScreem.js";
import { findDatabase, myMoth } from "../../backEnd/Memory.js";
import AllRepairs from "./AllRepairs.js";

function ControllerAccounting({isLoggedIn}){
    const location = useLocation();

    const [settings, setSettings] = useState(null)
    const [listRoutesToNav, setListRoutesToNav] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const data = await findDatabase()
            setSettings(data) 

            const menu1 = new ObjectNavbarMenu('Controle do Mês',faCalendarCheck)
            menu1.addMenu('Gerenciar Mês',faCalendarWeek,routerFromAccountingMoth)
            menu1.addMenu('Extrato do Mês',faDiagramNext,routerFromAccountingMothExtract)
            menu1.addMenu('Comparação Mês a Mês',faCalendarPlus,routerFromAccountingGraphicMoths)

            const menu2 = new ObjectNavbarMenu('Vendas no Período',faCartArrowDown)
            menu2.addMenu('Todas as Vendas',faFileInvoiceDollar,routerFromAccountingListSalesPeriod)
            menu2.addMenu('Todos os Consertos',faScrewdriverWrench,routerFromAccountingListAllRepairsPeriod)
            if (data.isCanUseBrunaBJ) menu2.addMenu('Vendas por Fatores',faMoneyBill1Wave,routerFromAccountingListSalesFactorPeriod)
            menu2.addMenu('Gráficos Vendas/Fatores',faChartLine,routerFromAccountingGraphicSales)
            
            const menu3 = new ObjectNavbarMenu('Dados Monetários',faSackDollar)
            menu3.addMenu('Lista de Recebimentos',faMoneyCheckDollar,routerFromAccountingReceivers)
            menu3.addMenu('Futuros Recebimentos',faMoneyCheck,routerFromAccountingFutureReceivers)
            menu3.addMenu('Lista de Comissões',faMoneyBillTrendUp,routerFromAccountingComissions)
            menu3.addMenu('Gráficos Comparativos',faChartBar,routerFromAccountingGraphicMoney)
            
            const menu4 = new ObjectNavbarMenu(!data.isCanUseBrunaBJ?'Produtos Vendidos':'Peças Vendidas',faCommentsDollar)
            if (data.isCanUseBrunaBJ)menu4.addMenu('Bruna Semijoias Vendidas',faTableList,routerFromAccountingItemSales)
            menu4.addMenu('Produtos Genéricos Vendidos',faProductHunt,routerFromAccountingProducts)

            const menu5 = new ObjectNavbarMenu('Métricas de Vendas',faChartLine)
            menu5.addMenu('Desempenho',faChartArea,routerFromAccountingPerformace)

            setListRoutesToNav([menu1, menu2, menu3, menu4, menu5])
        }
        fetchData()
    }, [])

    return isLoggedIn ? (
        <div className={style.containerGlobal}>
            <NavBarGlobal listObjectNavBarMenu={listRoutesToNav}/>
            <div className={style.containerData}>
               {(()=>{
                    switch(location.pathname){
                    case routerFromAccountingGlobal: return <ControllerMoth />
                    case routerFromAccountingMoth: return <ControllerMoth />
                    case routerFromAccountingMothExtract: return  <ViewExtractMoth myMoth={myMoth}/>
                    case routerFromAccountingListSalesPeriod: return <ListOfAllSales/>
                    case routerFromAccountingListAllRepairsPeriod: return <AllRepairs/>
                    case routerFromAccountingListSalesFactorPeriod: return <ListSalesFactors/>
                    case routerFromAccountingReceivers: return <ListOfReceivers/>
                    case routerFromAccountingComissions: return <ListofCommission/>
                    case routerFromAccountingFutureReceivers: return <ListOfFutureReceivers/>
                    case routerFromAccountingItemSales: return <ListBJSold/>
                    case routerFromAccountingProducts: return <ListProductsSold/>
                    case routerFromAccountingPerformace: return <Performance/>

                    case routerFromAccountingGraphicMoths: return <AllMothsScreem/>
                    case routerFromAccountingGraphicSales: return <GraphicSales/>
                    case routerFromAccountingGraphicMoney: return <GraphicCompare/>
                    case routerFromAccountingGraphicItems: return <GraphicItems/>
                    default: return <Navigate to={errorRouter} replace />
                }
               })()}
            </div>
        </div>
    ) : (
        <Navigate to={routerFromLogin} replace />
    )
}

export default ControllerAccounting