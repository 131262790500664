import React from "react"
import style from '../style/GlobalLoading.module.css'
import logo from '../assets/icon-android1.png';
function GlobalLoading(){
    return(
        <div className={style.overlay}>
           <div className={style.center}>
                <div className={style.centerLogo}>
                    <div className={style.loadingIndicator}></div>
                    <img className={style.logo} src={logo} alt="Logo B Semijoias"/>
                </div>
                {/*<p className={style.title}>Carregando...</p>*/}
           </div>
        </div>
      )
}

export default GlobalLoading