class SocialMediaObject{
    constructor(){
        this.idSocialNetwork = 0
        this.facebookLink = ''
        this.youtubeLink= ''
        this.instagramLink= ''
        this.tickTok= ''
        this.siteLink= ''
        this.twitterLink= ''
        this.linkedinLink= ''
        this.pinterestLink= ''
    }
  
    fromCloud(data){
        this.idSocialNetwork = data.idSocialNetwork || ""
        this.facebookLink = data.facebookLink || ""
        this.instagramLink = data.instagramLink || ""
        this.tickTok = data.tickTok || ""
        this.youtubeLink = data.youtubeLink || ""
        this.siteLink = data.siteLink || ""
        this.twitterLink = data.twitterLink || ""
        this.linkedinLink = data.linkedinLink || ""
        this.pinterestLink = data.pinterestLink || ""
    }
  
    getMapFromCloud(){
      return{
        'idSocialNetwork':this.idSocialNetwork,
        'facebookLink':this.facebookLink,
        'youtubeLink':this.youtubeLink,
        'instagramLink':this.instagramLink,
        'tickTok':this.tickTok,
        'siteLink':this.siteLink,
        'twitterLink':this.twitterLink,
        'linkedinLink':this.linkedinLink,
        'pinterestLink':this.pinterestLink,
      }
    }
}

export default SocialMediaObject