import { sendRequestGetGlobal, sendRequestPostGlobal } from "../backEnd/requesition"
import { Users } from "../objects/Admin/Users"

const routerFromUsersGlobal = '/global/getUsers'
const routerFromUpdateUsers = '/global/updateUsers'

export const getListUsers = async (showError) => {
    var results = []
    const listUsers = []
    try {
        results = await sendRequestGetGlobal(routerFromUsersGlobal)
        if(Array.isArray(results)){
            Object.values(results).forEach(user =>{   
                if(user.isAdmin === 0){
                    
                    var users = new Users()
                    users.fromCloud(user)
                    listUsers.push(users)
                }
            })
        }else throw new Error(results.response.data.sqlMessage) 

    } catch (error) {
        showError('ERROR AO BUSCAR USÚARIOS',error.message)
    }finally{
        return listUsers
    }
}

export const setUpdateUser = async (user,showError) => {
    try {
        const results = await sendRequestPostGlobal(routerFromUpdateUsers,user.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO ATUALIZAR USÚARIO',error.message)
        return {status:404}
    }
}