import { sendRequestGetLocal, sendRequestPostLocal} from '../backEnd/requesition.js'
import { AlertsNotices } from '../objects/AlertsNotices.js'

const _routerGetListNoticesInCloud ='/notices/getNotices'
const _routerFromRemoveNoticesFromCloud = '/notices/removeNotices'
const _routerFromAddNoticesFromCloud = '/notices/addNotices'

export const setNewNotice = async (bellNotice,showError) =>{
    try {
        var results = await sendRequestPostLocal(_routerFromAddNoticesFromCloud,bellNotice.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {   
        showError('ERROR AO SALVAR NOTÍCIA',error.message)
        return {status:404}
    }
}

export const setRemoveNotice = async (bellNotice,showError) =>{
    try {       
        var results = await sendRequestPostLocal(_routerFromRemoveNoticesFromCloud,bellNotice.getMapFromCloud())
        if(results.status !== 200) throw new Error(results.response.data.sqlMessage)
        else return results
    } catch (error) {
        showError('ERROR AO REMOVER NOTÍCIA',error.message)
        return {status:404}
    }
}


export const getListNotice = async (showError) => {
    var results = []
    const notices = []
    try {
        results = await sendRequestGetLocal(_routerGetListNoticesInCloud)
        
        if(Array.isArray(results)){
            Object.values(results).forEach(data =>{   
                var noticeTemp = new AlertsNotices()
                noticeTemp.fromCloud(data,true)
                notices.push(noticeTemp)
            })
        }else throw new Error(results.response.data.sqlMessage) 

    } catch (error) {
        showError('ERROR AO BUSCAR NOTÍCIAS',error.message)
    }finally{
        return notices
    }
}