class bankObject{
    constructor(){
        this.idBank = 0;
        this.bankName =  ''
        this.account =  ''
        this.agency =  ''
        this.typeAccount = ''
        this.ownerAccount = ''
        this.keycodePix = ''
    }

    fromCloud(data){
      this.idBank = data.idBank??0
      this.bankName = data.bankName??''
      this.account = data.account??''
      this.agency = data.agency??''
      this.typeAccount = data.typeAccount??''
      this.ownerAccount = data.ownerAccount??''
      this.keycodePix = data.keycodePix??''
    }

    getMapFromCloud(){
        return{
          'idBank' :this.idBank,
          'account': this.account,
          'agency': this.agency,
          'bankName':  this.bankName.toUpperCase(),
          'ownerAccount': this.ownerAccount.toUpperCase(),
          'keycodePix': this.keycodePix,
          'typeAccount':this.typeAccount.toUpperCase(),
        };
      }
}

export default bankObject