import React, { useEffect, useState } from "react"
import style from "../../../style/NewProduct.module.css"
import { TitlePageResponsiveIcon } from "../../../layout/TitlePage"
import { GetButton, GetButtonIcon } from "../../../layout/ButtonsLayout"
import { faAdd, faAngleDown, faAngleUp, faBarcode, faCamera, faCartShopping, faCirclePlus, faClose, faFileLines, faIdCardClip, faPalette, faRuler, faRulerCombined, faRulerHorizontal, faRulerVertical, faSignature, faSpellCheck, faTable, faWarning, faWeightScale } from "@fortawesome/free-solid-svg-icons"
import InputModels from "../../../Models/InputModels"
import { faProductHunt, faStripeS } from "@fortawesome/free-brands-svg-icons"
import InputPhoto from "../../../Models/InputPhoto"
import Space from "../../../layout/Space"
import BrandDropdown from "../../../layout/BrandDropdown"
import { GetSmallModels } from "../../../Models/GetModels"
import { useNavigate } from "react-router-dom"
import { routerFromStockNewBrand } from "../../../routers/routerList"
import SemijoiasDropdown from "../../../layout/SemijoiasDropdown"
import Alert from "../../../Models/Alert"
import Loader from "../../../layout/Loader"
import GenericProduct from "../../../objects/Brands/Products/GenericProducts"
import { setSaveProduct } from "../../../controllers/CloudProducts"
import { useError } from "../../../AppContext"
import Barcode from "../../Barcode"
import InputModelsBlock from "../../../layout/InputModelsBlock"
import { listProductsMemory } from "../../../backEnd/Memory"

const getFormData = () => {
    return {
        nameProduct:'',
        codebar:'',
        descriptionProduct:'',
        valueToSale:'',
        valueCoast:'',
        color:'',
        number:'',
        NCM:'',
        unitMeasurement:'',
        category:'',
        height:'',
        width:'',
        thickness:'',
        weight:'',
    }
}

const getFormNeed = () => {
    return {
        nameProduct:false,
        valueToSale:false,
    }
}

function NewProduct({handleClose,brandsList,handleSave}){

    const navigate = useNavigate()
    const {showError} = useError()
    
    const [selectedImageFromFile,setSelectedImageFromFile] = useState(null)
    const [selectedBrand, setSelectedBrand] = useState(1)
    const [loading,setLoading] = useState(false)

    const [selectType,setSelectType] = useState(false)
    const [isNormalProduct,setNormalProduct] = useState(false)

    const [isVisible,setAlert] = useState(false)
    const [formData, setFormData] = useState(getFormData)
    const [needData, setNeedData] = useState(getFormNeed)

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    const generateBarcode = () =>{
        let codigo = '';
        for (let i = 0; i < 12; i++) {
            codigo += Math.floor(Math.random() * 10); 
        }
        const index = listProductsMemory.findIndex(ss => ss.codebar.toString() === codigo.toString())
        if(index === -1 ) setFormData({ ...formData, ['codebar']: codigo })
        else generateBarcode()
    }

    const handleSendSave = async()=>{
        setLoading(true)

        var newProduct = new GenericProduct()
        newProduct.fromNewProduct(formData,selectedBrand)

     
        var result = await setSaveProduct(selectedImageFromFile,newProduct,showError)

        if(result.status === 200){
            handleSave()
            handleClose()
        }else{
            setLoading(false)
        }
    }

    const handleSubmitSave= () => {
        let allFieldsEmpty = false

        Object.entries(formData).forEach(([key, value]) => {
            if(key in needData){
                if (value === '') {
                    setNeedData(prevState => ({ ...prevState, [key]: true }))
                    allFieldsEmpty = true
                }
                else 
                    setNeedData(prevState => ({ ...prevState, [key]: false }))
            } 
        })
        
        if (allFieldsEmpty) {
            setAlert(true)
            return
        }

        handleSendSave()
    }

    const handleChoiceType = (value) => {
        setSelectType(true)
        setNormalProduct(value)
    }

    const handlerSlectedImageFromFile = (image) =>{
        setSelectedImageFromFile(image)
    }

    const handleSelectBrand = (brandId) => {
        setSelectedBrand(brandId) 
    }

    var label = selectType? "Complete os Campos Abaixo Para Adcionar um Novo Produto" : "Escolha o Tipo de Produto que Deseja Cadastrar..."

    return(
        <div className={style.overlay}>
            <div className={selectType? style.alertAdd: style.alert}>
                {!loading? <>
                    <TitlePageResponsiveIcon subtitle={"CADASTRAR NOVO PRODUTO"} 
                        path={label} 
                        icon={faProductHunt}
                            children={
                                <GetButtonIcon color='#E4080A' icon={faClose} handleFunction={handleClose}/> 
                            }
                        />  
                {brandsList.length === 0? 
                    <>
                        <GetSmallModels title={'Não Há Marcas Cadastradas'}
                        subtitle={'É Necessário Cadastrar uma Marca Antes'} icon={faWarning}/>
                        <div className={style.buttonsSelect}>
                            <GetButton title={"CADASTRAR NOVA MARCA DO PRODUTO"} color='#007bff' icon={faAdd} handleFunction={()=>navigate(routerFromStockNewBrand)}/>
                        </div>
                    </>:null}
                {!selectType && brandsList.length >0 ? <GetLayoutSelectTypeProduct handlerSlectedImageFromFile={handlerSlectedImageFromFile} handleChoiceType={handleChoiceType}/>:null}
                {selectType? 
                ( isNormalProduct ? <GetLayoutNormalProduct generateBarcode={generateBarcode} setFormData={setFormData} brands={brandsList} handleSelectBrand={handleSelectBrand} handlerSlectedImageFromFile={handlerSlectedImageFromFile} handleChange={handleChange} needData={needData} formData={formData} handleSubmitSave={handleSubmitSave}/>:
                <GetLayoutSemijoias generateBarcode={generateBarcode} brands={brandsList} setFormData={setFormData} handleSelectBrand={handleSelectBrand} handlerSlectedImageFromFile={handlerSlectedImageFromFile} handleChange={handleChange} needData={needData} formData={formData} handleSubmitSave={handleSubmitSave} />)
                :null}
                {isVisible? <Alert message={"Complete Todos os Campos em Vermelho"} top={25} right={25} onClose={() => setAlert(false)} type={"error"} />:null}
                </>:
                <Loader titleFinding={'Salvando Dados no Servidor...'}
                subtitleFinding={'Aguarde...'} loading={loading} />}
            </div>
      </div>
    )
}

function GetLayoutSelectTypeProduct({handleChoiceType}){
    return (
        <div className={style.buttonsSelect}>
            <GetButton title={"NOVA SEMIJOIA"} color='#007bff' icon={faAdd} handleFunction={()=>handleChoiceType(false)}/>
            <Space left={5}/>
            <GetButton title={"NOVO PRODUTO"} color='#267F03' icon={faCirclePlus} handleFunction={()=>handleChoiceType(true)}/>
        </div>
    )
}

export function GetMeasurement({handleChange,formData}){
    const [expanded, setExpand] = useState(false)
    return (
        <div className={style.div}>
            <TitlePageResponsiveIcon 
                children={
                    <GetButtonIcon color='#094f00' height={40} width={40} icon={expanded? faAngleUp :  faAngleDown} handleFunction={()=>setExpand(!expanded)}/> 
                }
                subtitle={"MEDIDAS E CARACTERÍSTICAS"} path={"Configurar Medidas do Produto"} icon={faRulerCombined}/> 
                <div className={expanded? style.visible :   style.notVisible}>
                    <InputModelsBlock legend={'CATEGORIAS'}  onChange={handleChange}
                        maxLength={45} value={formData.category} 
                        name={'category'} iconButton={faTable} 
                        type={"text"} placeholder={"Categoria do Produto..."}/> 
                    <Space top={5}/>
                    <InputModelsBlock legend={'ALTURA'}  onChange={handleChange}
                        maxLength={10} value={formData.height} 
                        name={'height'} iconButton={faRulerVertical} 
                        type={"number"} placeholder={"Altura do Produto..."}/>    
                    <Space top={5}/>
                    <InputModelsBlock legend={'LARGURA'}  onChange={handleChange}
                        maxLength={10} value={formData.width} 
                        name={'width'} iconButton={faRulerHorizontal} 
                        type={"number"} placeholder={"Largura do Produto..."}/>    
                    <Space top={5}/>
                    <InputModelsBlock legend={'ESPESSURA'}  onChange={handleChange}
                        maxLength={10} value={formData.thickness} 
                        name={'thickness'} iconButton={faRuler} 
                        type={"number"} placeholder={"Espessura do Produto..."}/>
                         <Space top={5}/>
                    <InputModelsBlock legend={'PESO'}  onChange={handleChange}
                        maxLength={10} value={formData.weight} 
                        name={'weight'} iconButton={faWeightScale} 
                        type={"number"} placeholder={"Peso do Produto..."}/>     
                         <Space top={5}/>
                    <InputModelsBlock legend={'UNIDADE DE MEDIDA'}  onChange={handleChange}
                        maxLength={15} value={formData.unitMeasurement} 
                        name={'unitMeasurement'} iconButton={faRulerCombined} 
                        type={"text"} placeholder={"Ex (UN, LITRO, KG)..."}/>            
                </div>
        </div>
    )
}

function GetLayoutNormalProduct({brands,handlerSlectedImageFromFile,handleSelectBrand,
    handleChange,needData,formData,handleSubmitSave,setFormData,generateBarcode}){

    const [showReadbarCode,setShowReadbarCode] = useState(false)

    const onReadBarcode = (data)=>{
        setFormData(prevState => ({ ...prevState, 'codebar': data }))
    }    
        
    useEffect(()=>{
        handleSelectBrand(brands[0].idBrands)
    },[])

    return (
        <div className={style.divRow}>
            <div className={style.div}>
                <div className={style.image}>
                    <InputPhoto titleButton={'Selecionar Foto'} 
                    handlerFileSelected={handlerSlectedImageFromFile}/>
                </div>
                <Space top={10}/>
                <BrandDropdown onSelect={handleSelectBrand} brands={brands}/>
                <Space top={10}/>
                <InputModelsBlock legend={'NOME DO PRODUTO/IDENTIFICAÇÃO'} 
                onChange={handleChange} validation={needData.nameProduct} 
                maxLength={50} value={formData.nameProduct} 
                name={'nameProduct'} iconButton={faSignature} 
                type={"text"} placeholder={"Geralmente Código ou Identificação única..."}/>
                <Space top={5}/>
                <InputModelsBlock legend={'VALOR DE VENDA'} onChange={handleChange} 
                validation={needData.valueToSale} 
                maxLength={5} value={formData.valueToSale} 
                name={'valueToSale'} iconButton={faCartShopping} 
                type={"number"} placeholder={"Valor de Venda..."}/> 
                <Space top={5}/>
                <InputModelsBlock legend={'VALOR DE CUSTO'} onChange={handleChange} 
                maxLength={10} value={formData.valueCoast} 
                name={'valueCoast'} iconButton={faStripeS} 
                type={"number"} placeholder={"Valor de Custo..."}/>     
                <Space left={5} top={5}/>
                <InputModelsBlock 
                        legend={'NCM - PARA NOTA FISCAL NFe'} 
                        onChange={handleChange} 
                        validation={needData.NCM} 
                        maxLength={20} 
                        value={formData.NCM} 
                        name={'NCM'} 
                        iconButton={faIdCardClip} 
                        type={"number"}  
                        placeholder={"Nomenclatura Comum do Mercosul... "}  
                />   
            </div>
            <Space left={5}/>
            <div className={style.div}>           
                <InputModelsBlock legend={'DESCRIÇÃO SIMPLES'}  onChange={handleChange}
                 maxLength={250} value={formData.descriptionProduct} 
                 name={'descriptionProduct'} iconButton={faFileLines} 
                type={"text"} placeholder={"Descrição do Produto..."}/>    
                <Space left={5} top={10}/>
                <div className={style.divRowBarcode}>
                    <div className={style.inputBarcode}>
                        <InputModels legend={'CÓDIGO DE BARRAS'} onChange={handleChange} 
                        maxLength={15} value={formData.codebar} 
                        name={'codebar'} iconButton={faBarcode} 
                        type={"number"} placeholder={"Código de Barras..."}/>
                    </div>
                    <Space left={10}/>  
                    <GetButtonIcon icon={faBarcode} handleFunction={generateBarcode} color={"#00ff00"} width={50} height={50}/>                              
                   {window.innerWidth < 1200?
                        <>
                            <Space left={10}/>  
                            <GetButtonIcon icon={faCamera} handleFunction={()=>setShowReadbarCode(true)} width={40} height={40} color={"#ff0000"} />
                        </>:null
                    }
                </div> 
                <Space top={10}/>  
                <GetMeasurement formData={formData} setFormData={setFormData} needData={needData} handleChange={handleChange}/>
                <div className={style.divButton}>
                    <GetButton title={"ADCIONAR NOVO PRODUTO"} color='#007bff' icon={faAdd} handleFunction={handleSubmitSave}/> 
                </div>               
            </div>
            {showReadbarCode ? <Barcode onClose={()=>setShowReadbarCode(false)} onValidate={onReadBarcode}/>: null}
        </div>
    )
}

function GetLayoutSemijoias({brands,handlerSlectedImageFromFile,handleSelectBrand,
    handleChange,needData,formData,handleSubmitSave,setFormData,generateBarcode}){

    const [showReadbarCode,setShowReadbarCode] = useState(false)
    const [selectedDatabase, setSelectedDatabase] = useState('BB')
    var title = selectedDatabase

    const onReadBarcode = (data)=>{
        setFormData(prevState => ({ ...prevState, 'codebar': data }))
    }    
       
    useEffect(()=>{
        handleSelectBrand(brands[0].idBrands)
    },[])

    const handleSelectDatabase = (value) => {
        title = ''
        setSelectedDatabase(value.slice(0,2))
    }

    const handleSave = () => {
        formData.nameProduct = title
        handleSubmitSave()
        formData.nameProduct = title.slice(2,title.length)
    }
   
   
  
    title += formData.nameProduct
    title += formData.number !== "" ? ".N" + formData.number : ''
    title += formData.color!== "" ? "." + formData.color : ''
    title = title.toUpperCase()
    return (
        <>
            <div className={style.div}>
                <GetSmallModels icon={faSpellCheck} title={"Nome Final da Semijoia"} value={title}/>
            </div>
            <div className={style.divRow}>
                <div className={style.div}>
                    <div className={style.image}>
                        <InputPhoto titleButton={'Selecionar Foto'} 
                        handlerFileSelected={handlerSlectedImageFromFile}/>
                    </div>
                    <Space top={10}/>
                    <BrandDropdown onSelect={handleSelectBrand} brands={brands}/>
                    <Space top={5}/>
                    <SemijoiasDropdown onSelect={handleSelectDatabase}/>
                    <Space top={10}/>
                    <InputModels onChange={handleChange} validation={needData.nameProduct} 
                    maxLength={50} value={formData.nameProduct} 
                    name={'nameProduct'} iconButton={faSignature} 
                    type={"text"} placeholder={"Código Númerico da Semijoia..."}/>
                    <InputModels onChange={handleChange} 
                    validation={needData.valueToSale} 
                    maxLength={10} value={formData.valueToSale} 
                    name={'valueToSale'} iconButton={faCartShopping} 
                    type={"number"} placeholder={"Valor de Venda..."}/>
                    <InputModels onChange={handleChange} 
                    maxLength={10} value={formData.valueCoast} 
                    name={'valueCoast'} iconButton={faStripeS} 
                    type={"number"} placeholder={"Valor de Custo..."}/>    
                    <InputModels onChange={handleChange} 
                    maxLength={5} value={formData.color} 
                    name={'color'} iconButton={faPalette} 
                    type={"text"} placeholder={"Cor em Sigla Exemplo: (VR,AM,RX,RS) etc..."}/>  
                    <InputModels onChange={handleChange} 
                    maxLength={3} value={formData.number} 
                    name={'number'} iconButton={faRulerCombined} 
                    type={"text"} placeholder={"Tamanho Númerico..."}/>   
                </div>
                <Space left={5}/>
                <div className={style.div}>
                  
                    <InputModels onChange={handleChange}
                    maxLength={250} value={formData.descriptionProduct} 
                    name={'descriptionProduct'} iconButton={faFileLines} 
                    type={"text"} placeholder={"Descrição do Produto..."}/>    
                    <div className={style.divRowBarcode}>
                            <div className={style.inputBarcode}>
                                <InputModels onChange={handleChange} 
                                maxLength={15} value={formData.codebar} 
                                name={'codebar'} iconButton={faBarcode} 
                                type={"number"} placeholder={"Código de Barras..."}/>   
                            </div>
                            <Space left={10}/>  
                            <GetButtonIcon icon={faBarcode} handleFunction={generateBarcode} color={"#00ff00"} width={50} height={50}/>                              
                            {window.innerWidth < 1200?
                                <>
                                    <Space left={10}/>  
                                    <GetButtonIcon icon={faCamera} handleFunction={()=>setShowReadbarCode(true)} width={40} height={40} color={"#ff0000"} />
                                </>:null
                            }
                    </div>
                    <InputModelsBlock 
                        legend={'NCM - PARA NOTA FISCAL NFe'} 
                        onChange={handleChange} 
                        validation={needData.NCM} 
                        maxLength={20} 
                        value={formData.NCM} 
                        name={'NCM'} 
                        iconButton={faIdCardClip} 
                        type={"number"}  
                        placeholder={"Nomenclatura Comum do Mercosul... "}  
                      />  
                    <GetMeasurement formData={formData} setFormData={setFormData} needData={needData} handleChange={handleChange}/>
                    <div className={style.divButton}>
                        <GetButton title={"ADCIONAR NOVA SEMIJOIA"} color='#007bff' icon={faAdd} 
                        handleFunction={handleSave}/> 
                    </div>               
                </div>
                {showReadbarCode ? <Barcode onClose={()=>setShowReadbarCode(false)} onValidate={onReadBarcode}/>: null}
            </div>
        </>
    )
}


export default NewProduct