import React, { useState } from "react"
import { faBook, faBoxesPacking, faBrazilianRealSign, faCashRegister, faFileCirclePlus, faGift, faHandHoldingDollar, faLock, faShopSlash } from "@fortawesome/free-solid-svg-icons"
import style from "../../style/Performance.module.css"
import { makeAccounting } from "../../objects/Accounting/MakeAccounting"
import GetNeedUpdate from "./GetNeedUpdate"
import TitlePage, { TitlePageResponsiveIcon } from "../../layout/TitlePage"
import { GetSmallModels } from "../../Models/GetModels"
import Space from "../../layout/Space"
import { faProductHunt } from "@fortawesome/free-brands-svg-icons"
import { ChartsComparePerformace, ChartsCompareReceivers } from "./Charts/ChartsCompareReceivers"
import { employeeData } from "../../backEnd/InitController"
import { databaseSettings } from "../../backEnd/Memory"


function Performance(){
    const [canView] = useState(employeeData().employeePermission.permissionsContabilite[12])
    return(
        <div>
            {window.innerWidth > 1200?<TitlePage title={'Métricas de Vendas'} subtitle={"Desempenho"}
            path={"Estoque  >  Métricas de Vendas  >  Desempenho"}/>:null}
            <div className={style.containerGlobal}>
            {canView? 
            <>{makeAccounting.isCalculated? (
                   <>
                   <ChartsCompareReceivers/>
                    <div className={style.container}>
                        <div className={style.divInRow}>
                            <GetSmallModels icon={faBrazilianRealSign} title={'Valor Total Recebido no Período'}  subtitle={'Apenas Vendas'} value={makeAccounting.accountingAllReceiversThisMoth.getValueTotalPaid()}/>
                            <Space left={5}/>
                            <GetSmallModels icon={faHandHoldingDollar} title={'Valor Total para Receber no Futuro'}  subtitle={'Apenas Vendas'} value={makeAccounting.accountingControllerAllSalesReceiver.getTotalSaleReceiverInFuture()}/>
                        </div>
                    </div>
                    <div className={style.container}>
                        <div className={style.divInRow}>
                            <GetSmallModels icon={faBrazilianRealSign} title={'Valor Total Em Consertos Recebido no Período'} subtitle={'Apenas Consertos Entregues'} value={makeAccounting.accountingAllRepairs.getTotalReceived()}/>
                            <Space left={5}/>
                            <GetSmallModels icon={faHandHoldingDollar} title={'Valor Total Em Consertos para Receber no Futuro'}  subtitle={'Apenas Consertos que Não Foram Entregues'} value={makeAccounting.accountingAllRepairs.getTotalSaleReceiverInFuture()}/>
                        </div>
                    </div>
                    <div className={style.container}>
                        <div className={style.divInRow}>
                            <GetSmallModels icon={faBrazilianRealSign} title={'Valor Total Recebido'} subtitle={'Soma de (Venda + Consertos)'} value={makeAccounting.getAllValueReceived()}/>
                            <Space left={5}/>
                            <GetSmallModels icon={faHandHoldingDollar} title={'Valor Total Para Receber no Futuro'} subtitle={'Soma de (Venda + Consertos)'} value={makeAccounting.getAllValueFutureReceiver()}/>
                        </div>
                    </div>
                    <Space top={50}/>
                    <ChartsComparePerformace/>
                    <div className={style.div}>
                        <TitlePageResponsiveIcon subtitle={'RELATÓRIO DE VENDAS LÍQUIDAS'} 
                        path={'São Iformações Tratadas Sobre as Vendas...'} icon={faBoxesPacking}/>   
                        <div className={style.container}>
                            <div className={style.grid}>
                                <div className={style.titleMenu}>
                                    <TitlePageResponsiveIcon subtitle={'DADOS GERAIS'}
                                        path={'São Iformações Básicas e Gerais...'} icon={faBook} />
                                    <GetSmallModels title={'Somatória das Vendas Líquidas'} value={makeAccounting.accountingDataDetails.getAllValue()}/>
                                    {databaseSettings.isCanUseBrunaBJ?<GetSmallModels title={'Gramas Totais das Vendas Líquidas'} value={makeAccounting.accountingDataSales.allGrams.toFixed(2) + 'g'}/>:null}
                                    <GetSmallModels title={'Peças e Produtos Totais Líquidas'} value={makeAccounting.accountingDataSales.allItems + ' Un'} />
                                    <GetSmallModels title={'Total de Vendas Líquidas'} value={makeAccounting.accountingDataSales.allSales} />
                                </div>
                            </div>
                            <div className={style.grid}>
                                <div className={style.titleMenu}>
                                    <TitlePageResponsiveIcon subtitle={'DADOS DOS PRODUTOS GENÉRICOS'}
                                        path={'São Informações Sobre as Vendas Com Produtos Genéricos...'} icon={faProductHunt} />
                                    <GetSmallModels title={'Somatória das Vendas com Produtos Genéricos'} value={makeAccounting.accountingDataSales.itemsStockGenericProducts.getValueMoney()}/>
                                    <GetSmallModels title={'Produtos Totais Vendidos'} value={makeAccounting.accountingDataSales.itemsStockGenericProducts.amountItems + ' Un'} />
                                    <GetSmallModels title={'Total de Vendas com Produtos Genéricos'} value={makeAccounting.accountingDataSales.itemsStockGenericProducts.totalSaleCount} />
                                </div>
                            </div>
                        </div>
                        <Space top={5}/>
                        <div className={style.container}>
                            <div className={style.grid}>
                                <div className={style.titleMenu}>
                                    <TitlePageResponsiveIcon subtitle={'DADOS SOBRE BRINDES'}
                                        path={'São Iformações Sobre os Brindes...'} icon={faGift} />
                                    <GetSmallModels title={'Somatória dos Brindes'} value={makeAccounting.accountingDataSales.itemsPresent.getValueMoney()}/>
                                    {databaseSettings.isCanUseBrunaBJ?<GetSmallModels title={'Gramas Totais dos Brindes'} value={makeAccounting.accountingDataSales.itemsPresent.grams.toFixed(2) + 'g'}/>:null}
                                    <GetSmallModels title={'Peças e Produtos Totais dos Brindes'} value={makeAccounting.accountingDataSales.itemsPresent.amountItems + ' Un'} />
                                </div>
                            </div>
                            <div className={style.grid}>
                                <div className={style.titleMenu}>
                                    <TitlePageResponsiveIcon subtitle={'DADOS COMPLEMENTARES'}
                                        path={'São Informações Complementares...'} icon={faFileCirclePlus} />
                                    <GetSmallModels title={'Somatória das Vendas com Valores Extras'} value={makeAccounting.accountingDataDetails.getAllExtra()}/>
                                    <GetSmallModels title={'Somatória Total de Descontos'} value={makeAccounting.accountingDataDetails.getAllDiscount()} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Space top={50}/>
                    <div className={style.container}>
                        <div className={style.grid}>
                            <div className={style.titleMenu}>
                                <TitlePageResponsiveIcon subtitle={'RELATÓRIO DE VENDAS BRUTAS'}
                                    path={'São Iformações Brutas Sobre as Vendas...'} icon={faCashRegister} />
                                <GetSmallModels title={'Somatória das Vendas Brutas'} value={makeAccounting.accountingDataSales.getAllMoney()}/>
                                {databaseSettings.isCanUseBrunaBJ?<GetSmallModels title={'Gramas Totais das Vendas Brutas'} value={makeAccounting.accountingDataSales.allGrams.toFixed(2) + 'g'}/>:null}
                                <GetSmallModels title={'Peças e Produtos Totais Brutas'} value={makeAccounting.accountingDataSales.allItems + ' Un'} />
                                <GetSmallModels title={'Total de Vendas Brutas'} value={makeAccounting.accountingDataSales.allSales} />
                            </div>
                        </div>
                        <div className={style.grid}>
                            <div className={style.titleMenu}>
                                <TitlePageResponsiveIcon subtitle={'RELATÓRIO DE CANCELAMENTOS'}
                                    path={'São Informações Sobre as Vendas Canceladas...'} icon={faShopSlash} />
                                <GetSmallModels title={'Somatória das Vendas Canceladas'} value={makeAccounting.accountingDataSales.itemsCancelAllSales.getValueMoney()}/>
                                {databaseSettings.isCanUseBrunaBJ?<GetSmallModels title={'Gramas Totais das Vendas Canceladas'} value={makeAccounting.accountingDataSales.itemsCancelAllSales.grams.toFixed(2) + 'g'}/>:null}
                                <GetSmallModels title={'Peças e Produtos Totais Cancelados'} value={makeAccounting.accountingDataSales.itemsCancelAllSales.amountItems + ' Un'} />
                                <GetSmallModels title={'Total de Vendas Canceladas'} value={makeAccounting.accountingDataSales.itemsCancelAllSales.totalSaleCount} />
                            </div>
                        </div>
                    </div>
                   </>
                ) : (
                    <GetNeedUpdate/>
                )}</>:
               <div className={style.div}>
               <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Visualizar a o Desempenho Mónetario'} />
               </div>}
            </div>
        </div>
    )
}

export default Performance